import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import CardContainer from "../../components/commons/CardContainer";
import Button from "@material-ui/core/Button";
import MaterialTable from "material-table";
import {
  cancelledScansList,
  getArchivedLinks,
  unArchivedLinks,
  getArchivedDirectories,
  unArchivedDirectories
} from "../../store/actions/customScanPageListActions";
import CompletedScans from '../CustomScanComponents/CompletedScans'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ScanActions from "./ScanActions";
import NoDomain from "../../components/commons/NoDomain";
import loaderwave from "../../assets/loader.svg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1F3A93",
    },
    secondary: {
      main: "#1F3A93",
    },
  },
});

function ScanDeletion() {
  const domains = useSelector((state) => state.dashboard.domains);

  const [selectedArchivedLinks, setSelectedArchivedLinks] = React.useState([]);
  const [selectedArchivedDirectories, setSelectedArchivedDirectories] = React.useState([]);

  // eslint-disable-next-line

  const selectedDomain = useSelector((state) => state.dashboard.selectedDomain);
  const scanId = useSelector((state) => state.miscellaneous.selectedScanId);
  const selectedDomainId = useSelector(
    (state) => state.dashboard.selectedDomainId
  );

  const cancelledScans = useSelector(
    (state) => state.customScanList.cancelledScans.scans
  );
  const archivedLinks = useSelector(
    (state) => state.customScanList.archivedLinks
  );
  const archivedDirectories = useSelector(
    (state) => state.customScanList.archivedDirectories
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (domains && domains.length > 0 && selectedDomain) {
      const domain = domains.filter(
        (item) => item.domain === selectedDomain
      )[0];
      dispatch(cancelledScansList(domain.domainId));
    }
    // eslint-disable-next-line
  }, [selectedDomain, domains]);

  React.useEffect(() => {
    if (scanId) {
      dispatch(getArchivedLinks(scanId));
      dispatch(getArchivedDirectories(scanId));
    }
    // eslint-disable-next-line
  }, [scanId])


  function onArchiveLinkSelect(rows) {
    rows = rows.map((row) => row.link);
    setSelectedArchivedLinks(rows);
  }

  function onArchiveDirectorySelect(rows) {
    rows = rows.map((row) => row.link);
    setSelectedArchivedDirectories(rows);
  }

  const [delayed, setDelayed] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setDelayed(false)
    }, 1500);
  }, [])


  return (
    <>
      {!delayed ?
        <>
          {scanId ?
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
            >
              <ThemeProvider theme={theme}>
                <CardContainer title="Running Scans" >
                  <ScanActions />
                </CardContainer>
                <CardContainer title="Completed Scans">
                  <CompletedScans />
                </CardContainer>
                <CardContainer >
                  <MaterialTable
                    title="Cancelled Scans"
                    columns={[
                      { title: "Scan Date", field: "dateNow" },
                      { title: "Scan Type", field: "type" },
                    ]}
                    data={cancelledScans}
                    options={{
                      search: true,
                      paging: false,
                      sorting: false,
                      maxBodyHeight: "50vh",
                      
                    }}
                  />
                </CardContainer>
                <CardContainer>
                  <div className="archive-links-table">
                    <MaterialTable
                      title="Archived Links"
                      columns={[{ title: "Archived Links", field: "link" }]}
                      onSelectionChange={onArchiveLinkSelect}
                      data={archivedLinks}
                      options={{
                        search: true,
                        selection: true,
                        paging: false,
                        sorting: false,
                        maxBodyHeight: "50vh",
                        rowStyle: {
                          padding: 0,
                        },
                        cellStyle: {
                          padding: 0,
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          maxWidth: 100,
                        },
                      }}
                    />
                  </div>
                  <Box
                    style={{ marginTop: "1rem" }}
                    display="flex"
                    flexDirection="row-reverse"
                  >
                    <Button
                      onClick={() => {
                        dispatch(
                          unArchivedLinks(
                            scanId,
                            selectedDomainId,
                            selectedArchivedLinks
                          )
                        )
                        setSelectedArchivedLinks([])

                      }
                      }
                      variant="contained"
                      data-tut="Start_Scan"
                      color="primary"
                      disabled={selectedArchivedLinks.length < 1}
                      style={{ borderRadius: 20 }}
                    >
                      Unarchived
                    </Button>
                  </Box>
                </CardContainer>
                <CardContainer>
                  <div className="archive-links-table">
                    <MaterialTable
                      title="Archived Directories"
                      columns={[{ title: "Archived Directories", field: "link" }]}
                      onSelectionChange={onArchiveDirectorySelect}
                      data={archivedDirectories}
                      options={{
                        search: true,
                        selection: true,
                        paging: false,
                        sorting: false,
                        maxBodyHeight: "50vh",
                        rowStyle: {
                          padding: 0,
                        },
                        cellStyle: {
                          padding: 0,
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          maxWidth: 100,
                        },
                      }}
                    />
                  </div>
                  <Box
                    style={{ marginTop: "1rem" }}
                    display="flex"
                    flexDirection="row-reverse"
                  >
                    <Button
                      onClick={() => {
                        dispatch(
                          unArchivedDirectories(
                            scanId,
                            selectedDomainId,
                            selectedArchivedDirectories
                          )
                        )
                        setSelectedArchivedDirectories([])
                      }}
                      style={{ borderRadius: 20 }}
                      variant="contained"
                      data-tut="Start_Scan"
                      color="primary"
                      disabled={selectedArchivedDirectories.length < 1}
                    >
                      Unarchived
                    </Button>
                  </Box>
                </CardContainer>
              </ThemeProvider>
            </Box> :
            <NoDomain text="Please add scan to see the results." />
          }
        </>
        :
        <Box

          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          tabIndex="0"
        >
          <img src={loaderwave} alt="loader" />
        </Box>}
    </>
  );
}
export default React.memo(ScanDeletion);
