import React from "react";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MuiButton from '@material-ui/core/Button';
import Lottie from 'react-lottie';
import history from '../@history';
import animationData from "../assets/lottiefiles/19749-not-found.json";
import { grey } from "@material-ui/core/colors";
export default function NotFound() {
      const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
            }
      };
      return (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%">
                  <div className="lotties">
                        <Lottie options={defaultOptions}
                              height={400}
                              width={400}
                              isStopped={false}
                              isPaused={false}
                        />
                  </div>
                  <Box color={grey[600]} p={2}> <Typography variant="h6">Sorry, the page you visited does not exist.</Typography></Box>
                  <MuiButton variant="contained" color="primary" onClick={() => history.push("/")} >Back to Home</MuiButton>
            </Box>
      );
}