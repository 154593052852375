import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

import Auth0Service from "../services/auth0Service/authentikService";
import Accounts from './AdminComponents/UserAccounts';
import CustomCardContainerHead from "../components/commons/CustomCardContainerHead"


const useStyles = makeStyles({
  selectBox: {
    maxWidth: 300,
    width: 300,
  },
});

const styles = {
  textField: {
    width: '100%',
    margin: 10,
    maxWidth: 300,
  }
}

export default function CollapsibleTable() {
  const classes = useStyles();
  const [loadingAccounts, setLoadingAccounts] = React.useState(true)
  const [data, setData] = React.useState([])

  const [reportType, setReportType] = React.useState({
    accounts: true,
  });

  const dispatch = useDispatch();


  // React.useEffect(() => {
  //   getAccounts()
  // }, [])

  // function getAccounts() {
  //   dispatch(getAllAccounts()).then(data => {
  //     if (data) {
  //       setLoadingAccounts(false);
  //       setData(data.result)
  //       console.log(data.resul)
  //     } else {
  //       setLoadingAccounts(false)

  //     }
  //   }).catch(err => {
  //     setLoadingAccounts(false)
  //   })
  // }

  if (!Auth0Service.isAuthenticated()) {
    return window.location.href = "/authenticate"
  } else {

    return (
      <>
        {reportType.accounts &&
          <CustomCardContainerHead title={"Accounts"} titleTag="h1">
            <Accounts />
          </CustomCardContainerHead>
        }
      </>
    );
  }
}