import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LockIcon from "@material-ui/icons/Lock";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Header from "../components/commons/Header";
import Box from "@material-ui/core/Box";
import { useSelector, useDispatch } from "react-redux";
import AddDomainLinkDialog from "../components/dialogs/AddDomainLinkDialog";
import { openDomainModal } from "../store/actions/dashboardActions";
import OldDashboardPreload from "../pages/OldDashboardPreload";
import LogoutModal from "../components/dialogs/LogoutModal";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Cookies from "universal-cookie";
import { logoutUser } from "../auth/store/actions";
import logo from "../assets/logo.svg";

import DashboardIcon from "../assets/sidebarIcons/dashboard.svg";
import Button from "@material-ui/core/Button";

import ScansIcon from "../assets/sidebarIcons/scans.svg";
import ImageIcon from "../assets/sidebarIcons/photos.png";
import ScriptIcon from "../assets/sidebarIcons/script.png";
import PrivacyIcon from "../assets/sidebarIcons/insurance.png";
import SchemasIcon from "../assets/sidebarIcons/scheme.png";
import PluginIcon from "../assets/sidebarIcons/plug-in.png";
import WpSecurityIcon from "../assets/sidebarIcons/patch.png";
import PluginThemeIcon from "../assets/sidebarIcons/web-design.png";
import LogsIcon from "../assets/sidebarIcons/file.png";
import ContactIcon from "../assets/sidebarIcons/contact-mail.png";
import IssueTrackerIcon from "../assets/sidebarIcons/issue-tracker.svg";
import TechnologiesIcon from "../assets/sidebarIcons/technologies.svg";
import SettingsIcon from "../assets/sidebarIcons/settings.svg";
import domainIcon from "../assets/sidebarIcons/domain.png";
import LogoutIcon from "../assets/sidebarIcons/logout.svg";
import HelpAndSupportIcon from "../assets/sidebarIcons/help-support.svg";

import ChatIcon from "../assets/sidebarIcons/chat.svg";
import ScrumIcon from "../assets/sidebarIcons/scrum-board.svg";
import AdminIcon from "../assets/sidebarIcons/admin.png";
import AbrakadabraIcon from "../assets/sidebarIcons/magic.png"


import { Switch, Route } from "react-router-dom";

import { Link } from "react-router-dom";
import routes from "../routes";

import {
  handleToggleSettingList,
  handleToggleProjectManagementList,
  handleToggleAdminManagementList,
  handleToggleWebsiteManagementList,
  handleToggleIssueList,
  handleToggleAbrakadabraBox,
  handleToggleSchemaBox
} from "../store/actions";

import auth0Service from "../services/auth0Service/authentikService";
import mainLayoutStyles from "../Styles/jss/mainLayoutStyles";

import NotFound from "../pages/NotFound";
import DomainSelector from "../components/commons/DomainSelector";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import headerStyles from "../Styles/jss/headerStyles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { clearScanData } from "../store/actions/miscellaneousActions";
import Auth0Service from "../services/auth0Service/authentikService";
import history from "../@history";
import ErrorHandler from "../components/ErrorHandler";
import { cancelPreviousPageRequests } from "../utils/baseService";
import { DomainsLink, DomainModulesAdmin, DomainModulesLayout } from "../../src/store/actions/adminScansManagementActions"
import { toast } from "react-toastify";

const useStyles = makeStyles(mainLayoutStyles);
const useStyles2 = makeStyles(headerStyles);

let previousPath = "";
let currentPath = "";
function Layout(props) {
  const [activePath, setActivePath] = React.useState({
    dashboard: false,
    accessibilitySheet: false,
    privacySheet: false,
    securitySheet: false,
    performanceSheet: false,
    scanPage: false,
    scans: false,
    chat: false,
    scrum: false,
    settings: false,
    projectmanagement: false,
    helpSupport: false,
    bugTracker: false,
    analytics: false,
    violationReport: false,
    adminManagement: false,
    userManagement: false,
    websiteManagement: false,
    websiteManagementDemo: false,
    abrakadabraBox: false,
    schemaBox: false,
    admin: false,
    websiteManage: false,
    abrakadabra: false,
    copyright: false,
    imageInfo: false,
    accessibilityElements: false,
    privacy: false,
    skipToContent: false,
    accessibilityStatement: false,
    technologiesInfo: false,
    pluginInfo: false,
    pluginAndTheme: false,
    modifiedLogs: false,
    pluginModifiedLogs: false,
    contactInfo: false,
    footerInfo: false,
    summary: false,
    rescan: false,
    accessibilityAi: false,
    schemasAi: false,
    faqSchemaAi: false,
    productSchemaAi: false,
    newsSchemaAi: false,
    articleSchemaAi: false,
    blogSchemaAi: false,
    reviewSchemaAi: false,
    mapSchemaAi: false,
    videoSchemaAi: false,
    imagesSchemaAi: false,
    wpSecurity: false,

    accessibilityReport: false,
    securityReport: false,
    htmlCssReport: false,
    spellCheckReport: false,
    pagespeed: false,
    fileTypes: false,
    myProfile: false,
    financials: false,
    userProfile: false,
    users: false,
    addPayment: false,
    activityLogs: false,
    cve: false,
    checkList: false,
    technologies: false,
    onBoarding: false,
    general: false,
    performanceDashboard: false,
    payments: false,
    seo: false,
    accessibilityDashboard: false,
    securityDashboard: false,
    languageServices: false,
    issueTracker: false,
    issuesLists: false,
    boardsList: false,
    serviceDesksLists: false,
    milestoneLists: false,
    domains: false,
    ccpaRequest: false,
    addCcpaRequest: false,
    privacyInfo: false
  });

  const [showLoggingOut, setShowLoggingOut] = React.useState(false);
  const [modules, setModules] = React.useState({ accountSetup: false, settingDomains: false, settingProfile: false, settingBilling: false, settingPaymentMethod: false, settingUsers: false, settingActivities: false, general: false, accessibility: false, performance: false, webPrivacy: false, cybersec: false, aiImageCaption: false, chat: false, scrumboard: false, issueTracker: false, technologies: false, schemasAi: false, wpSecurity: false, plugin: false, pluginAndTheme: false, pluginModifiedLogs: false, contactUsPage: false , ccpaRequests: false, addCcpaRequests: false, privacyInformation: false });
  const settingList = useSelector(
    (state) => state.navigationReducer.settingList
  );

  const projectManagementList = useSelector(
    (state) => state.navigationReducer.projectManagementList
  );

  const adminManagementList = useSelector(
    (state) => state.navigationReducer.adminManagementList
  );

  const websiteManagementList = useSelector(
    (state) => state.navigationReducer.websiteManagementList
  );

  const abrakadabraList = useSelector(
    (state) => state.navigationReducer.abrakadabraBox
  );

  const schemaList = useSelector(
    (state) => state.navigationReducer.schemaBox
  );

  const issueList = useSelector((state) => state.navigationReducer.issueList);

  const selectedDomain = useSelector(
    (state) => state.miscellaneous.selectedDomain
  );

  const selectedScanId = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );

  const isDomainPackage = useSelector(
    (state) => state.dashboard.isDomainPackagePurchased
  );

  const emailVerified = useSelector(
    (state) => state.auth.user.data.email_verified
  );

  const user = useSelector(
    (state) => state.auth
  );
  const domains = useSelector((state) => state.dashboard.domains);

  const selectedDomainId = useSelector(
    (state) => state.dashboard.selectedDomainId
  );

  const cookies = new Cookies();
  const location = useLocation();

  function logoutSystem() {
    setShowLoggingOut(true);

    // Simulate message display duration using setTimeout
    dispatch(logoutUser());
    setTimeout(() => {
      cookies.remove("selectedDomain");
      cookies.remove("selectedDomainId");
      setShowLoggingOut(false);
    }, 3000);
  }

  React.useEffect(() => {
    console.log("here is my domain")
    console.log(selectedDomainId)
    // console.log(selectedDomain);
    getModules(selectedDomainId)
    // console.log("herewewewewew")
    // console.log(modules.general);

  }, [selectedDomainId]);


  React.useEffect(() => {
    if (
      location.pathname === "/dashboard" ||
      location.pathname === "/" ||
      location.pathname === "/" ||
      location.pathname === "/dashboard/accessibility" ||
      location.pathname === "/dashboard/performance" ||
      location.pathname === "/dashboard/security" ||
      location.pathname === "/dashboard/web-privacy" ||
      location.pathname === "/dashboard/seo" ||
      location.pathname === "/dashboard/language-services"
    ) {
      if (location.pathname === "/dashboard" || location.pathname === "/") {
        setActivePath({ dashboard: true, general: true, });
      } else if (location.pathname === "/dashboard/accessibility") {
        setActivePath({ dashboard: true, accessibilityDashboard: true });
      } else if (location.pathname === "/dashboard/performance") {
        setActivePath({ dashboard: true, performanceDashboard: true });
      } else if (location.pathname === "/dashboard/security") {
        setActivePath({ dashboard: true, securityDashboard: true });
      } else if (location.pathname === "/dashboard/web-privacy") {
        setActivePath({ dashboard: true, webPrivacy: true });
      } else if (location.pathname === "/dashboard/seo") {
        setActivePath({ dashboard: true, seo: true });
      } else if (location.pathname === "/dashboard/language-services") {
        setActivePath({ dashboard: true, languageServices: true });
      }
    } else if (location.pathname === "/account-setup") {
      setActivePath({ onBoarding: true });
    } else if (location.pathname === "/violation-report") {
      setActivePath({ violationReport: true });
    } else if (location.pathname === "/violation-report/accessibility") {
      setActivePath({ accessibilityReport: true });
    } else if (location.pathname === "/violation-report/security") {
      setActivePath({ securityReport: true });
    } else if (location.pathname === "/violation-report/html-css") {
      setActivePath({ htmlCssReport: true });
    } else if (location.pathname === "/violation-report/filetypes") {
      setActivePath({ fileTypes: true });
    } else if (location.pathname === "/violation-report/pagespeed") {
      setActivePath({ pagespeed: true });
    } else if (location.pathname === "/violation-report/spell-check") {
      setActivePath({ spellCheckReport: true });
    } else if (location.pathname === "/cve") {
      setActivePath({ cve: true });
    } else if (location.pathname === "/checklist") {
      setActivePath({ checkList: true });
    } else if (location.pathname === "/technologies") {
      setActivePath({ technologies: true });
    } else if (location.pathname === "/custom-scan-page-list") {
      setActivePath({ customScan: true });
    } else if (location.pathname === "/chat") {
      setActivePath({ chat: true });
    } else if (location.pathname === "/scrumboard") {
      setActivePath({ scrum: true });
    } else if (location.pathname === "/web-privacy") {
      setActivePath({ webPrivacy: true });
    } else if (location.pathname === "/scans") {
      setActivePath({ scans: true });
    } else if (
      location.pathname === "/my-profile" ||
      location.pathname === "/billing" ||
      location.pathname === "/users" ||
      location.pathname === "/activity-logs" ||
      location.pathname === "/payment-methods" ||
      location.pathname === "/dashboard/domains"
    ) {
      dispatch(handleToggleSettingList(true));
      if (location.pathname === "/my-profile") {
        setActivePath({ settings: true, myProfile: true });
      } else if (location.pathname === "/billing") {
        setActivePath({ settings: true, financials: true });
      } else if (location.pathname === "/activity-logs") {
        setActivePath({ settings: true, activityLogs: true });
      } else if (location.pathname === "/payment-methods") {
        setActivePath({ settings: true, payments: true });
      }
      else if (location.pathname === "/dashboard/domains") {
        setActivePath({ dashboard: false, domains: true });
      }
      else {
        setActivePath({ settings: true, users: true });
      }
    } else if (
      location.pathname === "/issue-tracker/issues" ||
      location.pathname === "/issue-tracker/milestones"
    ) {
      dispatch(handleToggleIssueList(true));
      if (location.pathname === "/issue-tracker/issues") {
        setActivePath({ issueTracker: true, issuesLists: true });
      } else {
        setActivePath({ issueTracker: true, milestonesLists: true });
      }
    } else if (location.pathname === "/issue-tracker") {
      setActivePath({ issueTracker: true });
    } else if (location.pathname === "/help-and-support") {
      setActivePath({ helpSupport: true });
    } else if (location.pathname === "/issue-tracker") {
      setActivePath({ bugTracker: true });
    }
    else if (location.pathname === "/issue-tracker/accessibility") {
      setActivePath({ accessibilitySheet: true });
    }
    else if (location.pathname === "/issue-tracker/security") {
      setActivePath({ securitySheet: true });
    }
    else if (location.pathname === "/issue-tracker/performance") {
      setActivePath({ performanceSheet: true });
    }
    else if (location.pathname === "/issue-tracker/privacy") {
      setActivePath({ privacySheet: true });
    }
    else if (location.pathname === "/admin/manage-scans") {
      dispatch(handleToggleAdminManagementList(true));
      if (location.pathname === "/admin/manage-scans") {
        setActivePath({ admin: true, adminManagement: true });
      }
    }
    else if (location.pathname === "/admin/manage-users") {
      dispatch(handleToggleAdminManagementList(true));
      if (location.pathname === "/admin/manage-users") {
        setActivePath({ admin: true, userManagement: true });
      }
    }
    else if (location.pathname === "/admin/manage-websites-production") {
      dispatch(handleToggleWebsiteManagementList(true));
      if (location.pathname === "/admin/manage-websites-production") {
        setActivePath({ websiteManage: true, websiteManagement: true });
      }
    }
    else if (location.pathname === "/admin/manage-websites-demo") {
      setActivePath({ websiteManage: true, websiteManagementDemo: true });
    }
    else if (location.pathname.split('/').includes("website-image-links")) {
      if (location.pathname.split('/').includes("website-image-links")) {
        setActivePath({ imageInfo: true });
      }
    }

    else if (
      location.pathname.split('/').includes("analytics") ||
      // location.pathname.split('/').includes("website-image-links") ||
      location.pathname.split('/').includes("privacy-page") ||
      location.pathname.split('/').includes("skip-to-content") ||
      location.pathname.split('/').includes("accessibility-statement") ||
      location.pathname.split('/').includes("technologies-info") ||
      // location.pathname.split('/').includes("plugin-info") ||
      // location.pathname.split('/').includes("plugin-theme-info") ||
      location.pathname.split('/').includes("modiefied-pages-log") ||
      // location.pathname.split('/').includes("plugin-modified-log") ||
      // location.pathname.split('/').includes("contact-info") ||
      location.pathname.split('/').includes("footer-info") ||
      location.pathname.split('/').includes("summary") ||
      location.pathname.split('/').includes("rescan-website") ||
      location.pathname.split('/').includes("website-element-fixes") ||
      location.pathname.split('/').includes("accessibility-issues")
      // location.pathname.split('/').includes("faq-schemas-ai") ||
      // location.pathname.split('/').includes("product-schemas-ai") ||
      // location.pathname.split('/').includes("news-schemas-ai") ||
      // location.pathname.split('/').includes("article-schemas-ai") ||
      // location.pathname.split('/').includes("blog-schemas-ai") ||
      // location.pathname.split('/').includes("review-schemas-ai") ||
      // location.pathname.split('/').includes("map-schemas-ai") ||
      // location.pathname.split('/').includes("video-schemas-ai") ||
      // location.pathname.split('/').includes("images-schemas-ai") ||
      // location.pathname.split('/').includes("wp-security")
    ) {
      dispatch(handleToggleAbrakadabraBox(true));
      if (location.pathname.split('/').includes("analytics")) {
        setActivePath({ copyright: true });
      }
      else if (location.pathname.split('/').includes("privacy-page")) {
        setActivePath({ privacy: true });
      } else if (location.pathname.split('/').includes("skip-to-content")) {
        setActivePath({ skipToContent: true });
      } else if (location.pathname.split('/').includes("accessibility-statement")) {
        setActivePath({ accessibilityStatement: true });
      } else if (location.pathname.split('/').includes("technologies-info")) {
        setActivePath({ technologiesInfo: true });
      } else if (location.pathname.split('/').includes("modiefied-pages-log")) {
        setActivePath({ modifiedLogs: true });
      } else if (location.pathname.split('/').includes("footer-info")) {
        setActivePath({ footerInfo: true });
      } else if (location.pathname.split('/').includes("summary")) {
        setActivePath({ summary: true });
      } else if (location.pathname.split('/').includes("rescan-website")) {
        setActivePath({ rescan: true });
      } else if (location.pathname.split('/').includes("website-element-fixes")) {
        setActivePath({ accessibilityElements: true });
      } else if (location.pathname.split('/').includes("accessibility-issues")) {
        setActivePath({ accessibilityAi: true });
      } else if (location.pathname.split('/').includes("product-schemas-ai")) {
        setActivePath({ schemasAi: true });
      }
      // else if (location.pathname.split('/').includes("faq-schemas-ai")) {
      //   setActivePath({ faqSchemaAi: true });
      // } else if (location.pathname.split('/').includes("news-schemas-ai")) {
      //   setActivePath({ newsSchemaAi: true });
      // } else if (location.pathname.split('/').includes("article-schemas-ai")) {
      //   setActivePath({ articleSchemaAi: true });
      // } else if (location.pathname.split('/').includes("blog-schemas-ai")) {
      //   setActivePath({ blogSchemaAi: true });
      // } else if (location.pathname.split('/').includes("review-schemas-ai")) {
      //   setActivePath({ reviewSchemaAi: true });
      // } else if (location.pathname.split('/').includes("map-schemas-ai")) {
      //   setActivePath({ mapSchemaAi: true });
      // } else if (location.pathname.split('/').includes("video-schemas-ai")) {
      //   setActivePath({ videoSchemaAi: true });
      // } else if (location.pathname.split('/').includes("images-schemas-ai")) {
      //   setActivePath({ imagesSchemaAi: true });
      // }
      // else if (location.pathname.split('/').includes("wp-security")) {
      //   setActivePath({ wpSecurity: true });
      // }
      // else if(location.pathname.split('/').includes("/admin/summary")){
      //   setActivePath({ abrakadabra: true });
      // }
    }

    else if (location.pathname.split('/').includes("plugin-info")) {
      setActivePath({ pluginInfo: true });
    }

    else if (location.pathname.split('/').includes("plugin-modified-log")) {
      setActivePath({ pluginModifiedLogs: true });
    }

    else if (location.pathname.split('/').includes("wp-security")) {
      setActivePath({ wpSecurity: true });
    }
    else if (location.pathname.split('/').includes("plugin-theme-info")) {
      setActivePath({ pluginAndTheme: true });
    }

    else if (location.pathname.split('/').includes("contact-info")) {
      setActivePath({ contactInfo: true });
    }
    else if (
      location.pathname.split('/').includes("faq-schemas-ai") ||
      location.pathname.split('/').includes("product-schemas-ai") ||
      location.pathname.split('/').includes("news-schemas-ai") ||
      location.pathname.split('/').includes("article-schemas-ai") ||
      location.pathname.split('/').includes("blog-schemas-ai") ||
      location.pathname.split('/').includes("review-schemas-ai") ||
      location.pathname.split('/').includes("map-schemas-ai") ||
      location.pathname.split('/').includes("video-schemas-ai") ||
      location.pathname.split('/').includes("images-schemas-ai")

    ) {
      dispatch(handleToggleSchemaBox(true));
      if (location.pathname.split('/').includes("faq-schemas-ai")) {
        setActivePath({ faqSchemaAi: true });
      } else if (location.pathname.split('/').includes("product-schemas-ai")) {
        setActivePath({ productSchemaAi: true });
      } else if (location.pathname.split('/').includes("news-schemas-ai")) {
        setActivePath({ newsSchemaAi: true });
      } else if (location.pathname.split('/').includes("article-schemas-ai")) {
        setActivePath({ articleSchemaAi: true });
      } else if (location.pathname.split('/').includes("blog-schemas-ai")) {
        setActivePath({ blogSchemaAi: true });
      } else if (location.pathname.split('/').includes("review-schemas-ai")) {
        setActivePath({ reviewSchemaAi: true });
      } else if (location.pathname.split('/').includes("map-schemas-ai")) {
        setActivePath({ mapSchemaAi: true });
      } else if (location.pathname.split('/').includes("video-schemas-ai")) {
        setActivePath({ videoSchemaAi: true });
      } else if (location.pathname.split('/').includes("images-schemas-ai")) {
        setActivePath({ imagesSchemaAi: true });
      }
    }

    else if (location.pathname.split('/').includes("cookie-script")) {
      setActivePath({ ccpaRequest: true });
    }

    else if (location.pathname.split('/').includes("add-request")) {
      setActivePath({ addCcpaRequest: true });
    }

    else if (location.pathname.split('/').includes("privacy-info")) {
      setActivePath({ privacyInfo: true });
    }
    // eslint-disable-next-line
  }, [location]);

  React.useEffect(() => {
    if (!previousPath && !currentPath) {
      previousPath = location.pathname;
      currentPath = location.pathname;
      console.log(user)
      console.log(user?.user?.role[0]);
    } else {
      previousPath = currentPath;
      currentPath = location.pathname;
      console.log(user?.user?.role[0]);
    }
    if (previousPath !== currentPath) {
      cancelPreviousPageRequests(previousPath);
    }
  }, [location.pathname]);

  function getModules(selectedDomain) {
    console.log("herererere")
    console.log(selectedDomain)
    dispatch(DomainModulesLayout({
      selectedDomain,
    })).then(data => {
      if (data) {
        // console.log("Data from getModules Layout:", data.result); // Debugging
        setModules(data.result);
      }
    });
  }

  // const { window } = props;
  const classes = useStyles();
  const classes2 = useStyles2();

  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const dispatch = useDispatch();
  const openModal = useSelector((state) => state.dashboard.openModal);
  const scanProgress = useSelector(
    (state) => state.onBoardDashboard.scanProgress
  );
  const [logout, setLogout] = React.useState(false);
  const [hide, setHide] = React.useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleCloseModal = () => {
    dispatch(openDomainModal());
  };

  React.useEffect(() => {
    const localValue = localStorage.getItem("iframeLoaded");
    if (localValue) {
      setHide(false);
      setTimeout(() => {
        setHide(true);
        localStorage.removeItem("iframeLoaded");
      }, 10000);
    }
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      const expiresAt = JSON.parse(localStorage.getItem("expires_at"));

      if (!logout && expiresAt) {
        const isExpired = new Date().getTime() > expiresAt;
        if (isExpired) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("id_token");
          localStorage.removeItem("expires_at");
          localStorage.removeItem("auth0.ssodata");
          // localStorage.removeItem("domain");
          setLogout(true);
        }
      }
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  });

  const [delayed, setDelayed] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setDelayed(false);
    }, 500);
  }, []);

  function moveToLogin() {
    return null;
  }

  function linkDomains() {
    Auth0Service.getUserEmail()
      .then(userData => {
        // console.log(userData); // This will log the user email or resolved data
        dispatch(DomainsLink(userData.email))
          .then((data) => {
            if (data) {
              // console.log(data.message);
              toast.success("domains link successfully")
            }
          })
          .catch((err) => {
            console.error('Error occurred:', err);
          });
      })
      .catch(error => {
        console.error('Error occurred:', error);
      });
  }

  function removeDomain() {
    dispatch(clearScanData());
    localStorage.removeItem("domain");
    localStorage.removeItem("scanId");
    history.push("/dashboard/?domain-removed=1");
  }
  const switchRoutes = (
    <Switch>
      {routes.map((prop, key) => {
        return (
          <Route
            exact={prop.exact}
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      })}
      <Route path="*" component={NotFound} />
    </Switch>
  );

  // console.log(activePath)
  const drawer = (
    <div style={{ padding: "0px 20px" }} className="drawer-links">
      <div className={classes.toolbar}>
        <Link
          // ?domain=${selectedDomain}&scanId=${selectedScanId}
          to={`/`}
          tabIndex="0"
        >
          <img
            width="144px"
            height="35px"
            src={logo}
            alt="Ally-Right"
            tabIndex="0"
          />
        </Link>
      </div>


      {/* {domains.length > 0 && ( */}
      {Auth0Service.isAuthenticated() && (
        <>
          <DomainSelector />
          <Divider />
        </>
      )}
      {/* )} */}
      {/* {domains.length === 0 && Auth0Service.isAuthenticated() && (
        <>
          <Box px={4} mt={2}>
            {" "}
            <Link
              //  to={`/account-setup?domain=${selectedDomain}&scanId=${selectedScanId}`}
              onClick={linkDomains}>
              Add website to link
            </Link>
          </Box>
        </>
      )} */}
      {/* 
      {Auth0Service.isAuthenticated() && domains.length === 0 && (
        <FormControl
          variant="outlined"
          className={classes2.formControl}
          data-tut="Select_Domain"
        >
          <Select
            className={classes2.selectDomain}
            value="Select Domain"
            IconComponent={(props) => <ExpandMoreIcon {...props} />}
          >
            <MenuItem
              className={classes2.segoeFont}
              value="Select Domain"
              style={{ textTransform: "lowercase" }}
            >
              ........
            </MenuItem>
            <MenuItem>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                p={1}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "rgb(15, 36, 113)",
                  }}
                  onClick={() => linkDomains()}
                >
                  Link Domains
                </Button>
              </Box>
            </MenuItem>
          </Select>
        </FormControl>
      )} */}

      {!Auth0Service.isAuthenticated() && selectedDomain && (
        <FormControl
          variant="outlined"
          className={classes2.formControl}
          data-tut="Select_Domain"
        >
          <Select
            className={classes2.selectDomain}
            value={selectedDomain}
            IconComponent={(props) => <ExpandMoreIcon {...props} />}
          >
            <MenuItem
              className={classes2.segoeFont}
              value={selectedDomain}
              style={{ textTransform: "lowercase" }}
            >
              {
                selectedDomain
                  .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
                  .split("/")[0]
              }
            </MenuItem>
            <MenuItem>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                p={1}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "rgb(226, 33, 30)",
                  }}
                  onClick={() => removeDomain()}
                >
                  Remove Domain
                </Button>
              </Box>
            </MenuItem>
          </Select>
        </FormControl>
      )}

      {!activePath.onBoarding && (
        <List role="list" component="div" style={{ paddingTop: 25 }}>
          <ListItem
            role="listitem"
            component={Link}
            to={`/dashboard?domain=${selectedDomain}&scanId=${selectedScanId}`}
            aria-label="Dasboard"
            className={clsx(
              activePath.dashboard ? classes.listItemActive : classes.listItem
            )}
          >
            <ListItemIcon className={classes.iconContainer}>
              <img src={DashboardIcon} width="18px" alt="Dashboard Icon" />
            </ListItemIcon>
            <ListItemText primary="Overview" />
          </ListItem>

          {!isDomainPackage &&
            auth0Service.isAuthenticated() &&
            selectedDomain && modules.accountSetup && (
              <ListItem
                role="listitem"
                component={
                  scanProgress.status === "completed" ? Link : "button"
                }
                // component={"button"}
                // onClick={() => {
                //   const token = auth0Service.getAccessToken();
                //   window.open(
                //     `${PAYMENTS_BASE_URL}/account-setup?domainId=${selectedDomainId}&token=${token}&baseurl=sadasdsad`, "_blank");
                // }}

                to={`/account-setup?domain=${selectedDomain}&scanId=${selectedScanId}`}
                aria-label="account-setup"
                className={clsx(
                  activePath.onBoarding
                    ? classes.listItemActive
                    : classes.listItem
                )}
                disabled={scanProgress.status !== "completed"}
              >
                <ListItemIcon className={classes.iconContainer}>
                  <img src={DashboardIcon} width="18px" alt="Dashboard Icon" />
                </ListItemIcon>
                <ListItemText primary="Account Setup" />
                {scanProgress.status !== "completed" && (
                  <LockIcon className={classes.lockIcon} />
                )}
              </ListItem>
            )}
          {isDomainPackage && (window.location.href.includes("demo.allyright.com") || window.location.hostname.includes("localhost")) &&
            auth0Service.isAuthenticated() &&
            selectedDomain && modules.accountSetup && (
              <ListItem
                role="listitem"
                component={
                  scanProgress.status === "completed" ? Link : "button"
                }
                // component={"button"}
                // onClick={() => {
                //   const token = auth0Service.getAccessToken();
                //   window.open(
                //     `${PAYMENTS_BASE_URL}/account-setup?domainId=${selectedDomainId}&token=${token}&baseurl=sadasdsad`, "_blank");
                // }}

                to={`/account-setup?domain=${selectedDomain}&scanId=${selectedScanId}`}
                aria-label="account-setup"
                className={clsx(
                  activePath.onBoarding
                    ? classes.listItemActive
                    : classes.listItem
                )}
                disabled={scanProgress.status !== "completed"}
              >
                <ListItemIcon className={classes.iconContainer}>
                  <img src={DashboardIcon} width="18px" alt="Dashboard Icon" />
                </ListItemIcon>
                <ListItemText primary="Account Setup" />
                {scanProgress.status !== "completed" && (
                  <LockIcon className={classes.lockIcon} />
                )}
              </ListItem>
            )}
          <Divider style={{ margin: "10px 0px" }} />


          {modules.aiImageCaption && selectedDomain && (
            <ListItem
              disabled={!Auth0Service.isAuthenticated()}
              role="listitem"
              onClick={() => moveToLogin()}
              component={Auth0Service.isAuthenticated() ? Link : "button"}
              to={
                auth0Service.isAuthenticated()
                  ? `/website-image-links?domain=${selectedDomain}&scanId=${selectedScanId}`
                  : undefined
              }
              // style={{ padding: "4px 4px", paddingLeft: "54px" }}
              className={clsx(
                activePath.imageInfo
                  ? classes.listItemNestedActive
                  : classes.listItem
              )}
            >
              <ListItemIcon className={classes.iconContainer}>
                <img src={ImageIcon} width="18px" alt="Dashboard Icon" />
              </ListItemIcon>
              <ListItemText
                style={{
                  fontSize: "15px",
                }}
                primary="Website Images"
              />
              {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
            </ListItem>

          )}
 {modules.ccpaRequests && selectedDomain && (
          <ListItem
          disabled={!Auth0Service.isAuthenticated()}
            role="listitem"
            onClick={() => moveToLogin()}
            component={Auth0Service.isAuthenticated() ? Link : "button"}
            to={ auth0Service.isAuthenticated()
                  ?`cookie-script?domain=${selectedDomain}&scanId=${selectedScanId}`
                : undefined
              }
            aria-label="Cookie Script"
            className={clsx(
              activePath.ccpaRequest ? classes.listItemActive : classes.listItem
            )}
          >
            <ListItemIcon className={classes.iconContainer}>
              <img src={ScriptIcon} width="18px" alt="Dashboard Icon" />
            </ListItemIcon>
            <ListItemText primary="Cookie Script" />
            {/* {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />} */}
          </ListItem>
            )}
 {/* {modules.addCcpaRequests && selectedDomain && (
<ListItem
          disabled={!Auth0Service.isAuthenticated()}
            role="listitem"
            onClick={() => moveToLogin()}
            component={Auth0Service.isAuthenticated() ? Link : "button"}
            to={auth0Service.isAuthenticated()
                  ?`ccpa/add-request?domain=${selectedDomain}&scanId=${selectedScanId}`
                : undefined
              }
            aria-label="CCPA Requests"
            className={clsx(
              activePath.addCcpaRequest ? classes.listItemActive : classes.listItem
            )}
          >
            <ListItemIcon className={classes.iconContainer}>
              <img src={DashboardIcon} width="18px" alt="Dashboard Icon" />
            </ListItemIcon>
            <ListItemText primary="Add CCPA" />
            {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
          </ListItem>
             )} */}
 {modules.privacyInformation && selectedDomain && (
<ListItem
          disabled={!Auth0Service.isAuthenticated()}
            role="listitem"
            onClick={() => moveToLogin()}
            component={Auth0Service.isAuthenticated() ? Link : "button"}
            to={auth0Service.isAuthenticated()
                  ?`privacy-info?domain=${selectedDomain}&scanId=${selectedScanId}`
                : undefined
              }
            aria-label="Privacy Policy"
            className={clsx(
              activePath.privacyInfo ? classes.listItemActive : classes.listItem
            )}
          >
            <ListItemIcon className={classes.iconContainer}>
              <img src={PrivacyIcon} width="18px" alt="Dashboard Icon" />
            </ListItemIcon>
            <ListItemText primary="Privacy Policy" />
            {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
          </ListItem>
            )}  
          {modules.schemasAi && selectedDomain && (
            <>
              <ListItem
                disabled={!Auth0Service.isAuthenticated()}
                component="button"
                role="listitem"
                aria-label="abrakadabra list with 2 items"
                className={clsx(
                  activePath.abrakadabra ? classes.listItemActive : classes.listItem
                )}
                onClick={() => {
                  if (schemaList) {
                    dispatch(handleToggleSchemaBox(false));
                  } else {
                    dispatch(handleToggleSchemaBox(true));
                  }
                }}
              >
                <ListItemIcon className={classes.iconContainer}>
                  <img src={SchemasIcon} width="18px" alt="Schemas Icon" />
                </ListItemIcon>
                <ListItemText primary="Schemas" />
                {schemaList ? <ExpandLess /> : <ExpandMore />}
                {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
              </ListItem>
              <Collapse in={schemaList} timeout="auto">
                <ListItem
                  disabled={!Auth0Service.isAuthenticated()}
                  role="listitem"
                  onClick={() => moveToLogin()}
                  component={Auth0Service.isAuthenticated() ? Link : "button"}
                  to={
                    auth0Service.isAuthenticated()
                      ? `/schemas/faq-schemas-ai?domain=${selectedDomain}`
                      : undefined
                  }
                  style={{ padding: "4px 4px", paddingLeft: "54px" }}
                  className={clsx(
                    activePath.faqSchemaAi
                      ? classes.listItemNestedActive
                      : classes.listItem
                  )}
                >
                  <ListItemText
                    style={{
                      fontSize: "15px",
                    }}
                    primary="Faqs Schemas AI"
                  />
                  {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
                </ListItem>
                <ListItem
                  disabled={!Auth0Service.isAuthenticated()}
                  role="listitem"
                  onClick={() => moveToLogin()}
                  component={Auth0Service.isAuthenticated() ? Link : "button"}
                  to={
                    auth0Service.isAuthenticated()
                      ? `/schemas/product-schemas-ai?domain=${selectedDomain}`
                      : undefined
                  }
                  style={{ padding: "4px 4px", paddingLeft: "54px" }}
                  className={clsx(
                    activePath.schemasAi
                      ? classes.listItemNestedActive
                      : classes.listItem
                  )}
                >
                  <ListItemText
                    style={{
                      fontSize: "15px",
                    }}
                    primary="Product Schemas AI"
                  />
                  {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
                </ListItem>
                <ListItem
                  disabled={!Auth0Service.isAuthenticated()}
                  role="listitem"
                  onClick={() => moveToLogin()}
                  component={Auth0Service.isAuthenticated() ? Link : "button"}
                  to={
                    auth0Service.isAuthenticated()
                      ? `/schemas/news-schemas-ai?domain=${selectedDomain}`
                      : undefined
                  }
                  style={{ padding: "4px 4px", paddingLeft: "54px" }}
                  className={clsx(
                    activePath.newsSchemaAi
                      ? classes.listItemNestedActive
                      : classes.listItem
                  )}
                >
                  <ListItemText
                    style={{
                      fontSize: "15px",
                    }}
                    primary="News Schemas AI"
                  />
                  {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
                </ListItem>
                <ListItem
                  disabled={!Auth0Service.isAuthenticated()}
                  role="listitem"
                  onClick={() => moveToLogin()}
                  component={Auth0Service.isAuthenticated() ? Link : "button"}
                  to={
                    auth0Service.isAuthenticated()
                      ? `/schemas/article-schemas-ai?domain=${selectedDomain}`
                      : undefined
                  }
                  style={{ padding: "4px 4px", paddingLeft: "54px" }}
                  className={clsx(
                    activePath.articleSchemaAi
                      ? classes.listItemNestedActive
                      : classes.listItem
                  )}
                >
                  <ListItemText
                    style={{
                      fontSize: "15px",
                    }}
                    primary="Articles Schemas AI"
                  />
                  {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
                </ListItem>
                <ListItem
                  disabled={!Auth0Service.isAuthenticated()}
                  role="listitem"
                  onClick={() => moveToLogin()}
                  component={Auth0Service.isAuthenticated() ? Link : "button"}
                  to={
                    auth0Service.isAuthenticated()
                      ? `/schemas/blog-schemas-ai?domain=${selectedDomain}`
                      : undefined
                  }
                  style={{ padding: "4px 4px", paddingLeft: "54px" }}
                  className={clsx(
                    activePath.blogSchemaAi
                      ? classes.listItemNestedActive
                      : classes.listItem
                  )}
                >
                  <ListItemText
                    style={{
                      fontSize: "15px",
                    }}
                    primary="Blogs Schemas AI"
                  />
                  {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
                </ListItem>
                <ListItem
                  disabled={!Auth0Service.isAuthenticated()}
                  role="listitem"
                  onClick={() => moveToLogin()}
                  component={Auth0Service.isAuthenticated() ? Link : "button"}
                  to={
                    auth0Service.isAuthenticated()
                      ? `/schemas/review-schemas-ai?domain=${selectedDomain}`
                      : undefined
                  }
                  style={{ padding: "4px 4px", paddingLeft: "54px" }}
                  className={clsx(
                    activePath.reviewSchemaAi
                      ? classes.listItemNestedActive
                      : classes.listItem
                  )}
                >
                  <ListItemText
                    style={{
                      fontSize: "15px",
                    }}
                    primary="Review Schemas AI"
                  />
                  {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
                </ListItem>
                <ListItem
                  disabled={!Auth0Service.isAuthenticated()}
                  role="listitem"
                  onClick={() => moveToLogin()}
                  component={Auth0Service.isAuthenticated() ? Link : "button"}
                  to={
                    auth0Service.isAuthenticated()
                      ? `/schemas/map-schemas-ai?domain=${selectedDomain}`
                      : undefined
                  }
                  style={{ padding: "4px 4px", paddingLeft: "54px" }}
                  className={clsx(
                    activePath.mapSchemaAi
                      ? classes.listItemNestedActive
                      : classes.listItem
                  )}
                >
                  <ListItemText
                    style={{
                      fontSize: "15px",
                    }}
                    primary="Map Schemas AI"
                  />
                  {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
                </ListItem>
                <ListItem
                  disabled={!Auth0Service.isAuthenticated()}
                  role="listitem"
                  onClick={() => moveToLogin()}
                  component={Auth0Service.isAuthenticated() ? Link : "button"}
                  to={
                    auth0Service.isAuthenticated()
                      ? `/schemas/video-schemas-ai?domain=${selectedDomain}`
                      : undefined
                  }
                  style={{ padding: "4px 4px", paddingLeft: "54px" }}
                  className={clsx(
                    activePath.videoSchemaAi
                      ? classes.listItemNestedActive
                      : classes.listItem
                  )}
                >
                  <ListItemText
                    style={{
                      fontSize: "15px",
                    }}
                    primary="Videos Schemas AI"
                  />
                  {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
                </ListItem>
                <ListItem
                  disabled={!Auth0Service.isAuthenticated()}
                  role="listitem"
                  onClick={() => moveToLogin()}
                  component={Auth0Service.isAuthenticated() ? Link : "button"}
                  to={
                    auth0Service.isAuthenticated()
                      ? `/schemas/images-schemas-ai?domain=${selectedDomain}`
                      : undefined
                  }
                  style={{ padding: "4px 4px", paddingLeft: "54px" }}
                  className={clsx(
                    activePath.imagesSchemaAi
                      ? classes.listItemNestedActive
                      : classes.listItem
                  )}
                >
                  <ListItemText
                    style={{
                      fontSize: "15px",
                    }}
                    primary="Images Schemas AI"
                  />
                  {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
                </ListItem>
              </Collapse>
            </>
          )}

          {modules.plugin && selectedDomain && (
            <ListItem
              disabled={!Auth0Service.isAuthenticated()}
              role="listitem"
              onClick={() => moveToLogin()}
              component={Auth0Service.isAuthenticated() ? Link : "button"}
              to={
                auth0Service.isAuthenticated()
                  ? `/wordpress/plugin-info?domain=${selectedDomain}`
                  : undefined
              }
              // style={{ padding: "4px 4px", paddingLeft: "54px" }}
              className={clsx(
                activePath.pluginInfo
                  ? classes.listItemNestedActive
                  : classes.listItem
              )}
            >
              <ListItemIcon className={classes.iconContainer}>
                <img src={PluginIcon} width="18px" alt="Abrakadabra Icon" />
              </ListItemIcon>
              <ListItemText
                style={{
                  fontSize: "15px",
                }}
                primary="Plugin"
              />
              {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
            </ListItem>
          )}
          {modules.wpSecurity && selectedDomain && (
            <ListItem
              disabled={!Auth0Service.isAuthenticated()}
              role="listitem"
              onClick={() => moveToLogin()}
              component={Auth0Service.isAuthenticated() ? Link : "button"}
              to={
                auth0Service.isAuthenticated()
                  ? `/security/wp-security?domain=${selectedDomain}`
                  : undefined
              }
              // style={{ padding: "4px 4px", paddingLeft: "54px" }}
              className={clsx(
                activePath.wpSecurity
                  ? classes.listItemNestedActive
                  : classes.listItem
              )}
            >
              <ListItemIcon className={classes.iconContainer}>
                <img src={WpSecurityIcon} width="18px" alt="Abrakadabra Icon" />
              </ListItemIcon>
              <ListItemText
                style={{
                  fontSize: "15px",
                }}
                primary="Wordpress Security Patches"
              />
              {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
            </ListItem>
          )}

          {modules.pluginAndTheme && selectedDomain && (
            <ListItem
              disabled={!Auth0Service.isAuthenticated()}
              role="listitem"
              onClick={() => moveToLogin()}
              component={Auth0Service.isAuthenticated() ? Link : "button"}
              to={
                auth0Service.isAuthenticated()
                  ? `/wordpress/plugin-theme-info?domain=${selectedDomain}`
                  : undefined
              }
              // style={{ padding: "4px 4px", paddingLeft: "54px" }}
              className={clsx(
                activePath.pluginAndTheme
                  ? classes.listItemNestedActive
                  : classes.listItem
              )}
            >
              <ListItemIcon className={classes.iconContainer}>
                <img src={PluginThemeIcon} width="18px" alt="Abrakadabra Icon" />
              </ListItemIcon>
              <ListItemText
                style={{
                  fontSize: "15px",
                }}
                primary="Plugins and Themes"
              />
              {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
            </ListItem>
           )}

          {modules.pluginModifiedLogs && selectedDomain && (
            <ListItem
              disabled={!Auth0Service.isAuthenticated()}
              role="listitem"
              onClick={() => moveToLogin()}
              component={Auth0Service.isAuthenticated() ? Link : "button"}
              to={
                auth0Service.isAuthenticated()
                  ? `/wordpress/plugin-modified-log?domain=${selectedDomain}`
                  : undefined
              }
              // style={{ padding: "4px 4px", paddingLeft: "54px" }}
              className={clsx(
                activePath.pluginModifiedLogs
                  ? classes.listItemNestedActive
                  : classes.listItem
              )}
            >
              <ListItemIcon className={classes.iconContainer}>
                <img src={LogsIcon} width="18px" alt="Abrakadabra Icon" />
              </ListItemIcon>
              <ListItemText
                style={{
                  fontSize: "15px",
                }}
                primary="Plugin Modified Logs"
              />
              {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
            </ListItem>
           )}
          {modules.contactUsPage && selectedDomain && (
          <ListItem
            disabled={!Auth0Service.isAuthenticated()}
            role="listitem"
            onClick={() => moveToLogin()}
            component={Auth0Service.isAuthenticated() ? Link : "button"}
            to={
              auth0Service.isAuthenticated()
                ? `/wordpress/contact-info?domain=${selectedDomain}`
                : undefined
            }
            // style={{ padding: "4px 4px", paddingLeft: "54px" }}
            className={clsx(
              activePath.contactInfo
                ? classes.listItemNestedActive
                : classes.listItem
            )}
          >
            <ListItemIcon className={classes.iconContainer}>
              <img src={ContactIcon} width="18px" alt="Abrakadabra Icon" />
            </ListItemIcon>
            <ListItemText
              style={{
                fontSize: "15px",
              }}
              primary="Contact Us Page"
            />
            {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
          </ListItem>
           )}
          {user?.user?.role && (user?.user?.data?.allowAbrakadabraBox === true) &&
            <ListItem
              disabled={!Auth0Service.isAuthenticated()}
              component="button"
              role="listitem"
              aria-label="abrakadabra list with 2 items"
              className={clsx(
                activePath.abrakadabra ? classes.listItemActive : classes.listItem
              )}
              onClick={() => {
                if (abrakadabraList) {
                  dispatch(handleToggleAbrakadabraBox(false));
                } else {
                  dispatch(handleToggleAbrakadabraBox(true));
                }
              }}
            >
              <ListItemIcon className={classes.iconContainer}>
                <img src={AbrakadabraIcon} width="18px" alt="Abrakadabra Icon" />
              </ListItemIcon>
              <ListItemText primary="AllyRight AI" />
              {abrakadabraList ? <ExpandLess /> : <ExpandMore />}
              {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
            </ListItem>
          }
          {user?.user?.role && (user?.user?.data?.allowAbrakadabraBox === true) && domains.length > 0 &&
            <Collapse in={abrakadabraList} timeout="auto">
              {/* <ListItem
                disabled={!Auth0Service.isAuthenticated()}
                role="listitem"
                onClick={() => moveToLogin()}
                component={Auth0Service.isAuthenticated() ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/allyright-ai/summary?domain=${selectedDomain}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.summary
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Summary"
                />
                {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
              </ListItem> */}
              <ListItem
                disabled={!Auth0Service.isAuthenticated()}
                role="listitem"
                onClick={() => moveToLogin()}
                component={Auth0Service.isAuthenticated() ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/allyright-ai/analytics?domain=${selectedDomain}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.copyright
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Dashboard"
                />
                {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
              </ListItem>

              <ListItem
                disabled={!Auth0Service.isAuthenticated()}
                role="listitem"
                onClick={() => moveToLogin()}
                component={Auth0Service.isAuthenticated() ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/allyright-ai/rescan-website?domain=${selectedDomain}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.rescan
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Scan Management"
                />
                {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
              </ListItem>
              {/* <ListItem
                disabled={!Auth0Service.isAuthenticated()}
                role="listitem"
                onClick={() => moveToLogin()}
                component={Auth0Service.isAuthenticated() ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/website-image-links?domain=${selectedDomain}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.imageInfo
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Website Images Links"
                />
                {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
              </ListItem> */}
              {/* <ListItem
                disabled={!Auth0Service.isAuthenticated()}
                role="listitem"
                onClick={() => moveToLogin()}
                component={Auth0Service.isAuthenticated() ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/allyright-ai/accessibility-issues?domain=${selectedDomain}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.accessibilityAi
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Accessibility AI"
                />
                {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
              </ListItem> */}
              {/* <ListItem
                disabled={!Auth0Service.isAuthenticated()}
                role="listitem"
                onClick={() => moveToLogin()}
                component={Auth0Service.isAuthenticated() ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/admin/website-element-fixes/NaJ:>%7D%5EDRVNv,RVR4$vpa%7DFdVPLCU?domain=${selectedDomain}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.accessibilityElements
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Website Elements"
                />
                {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
              </ListItem> */}

              {/* <ListItem
                disabled={!Auth0Service.isAuthenticated()}
                role="listitem"
                onClick={() => moveToLogin()}
                component={Auth0Service.isAuthenticated() ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/admin/footer-info/NaJ:>%7D%5EDRVNv,RVR4$vpa%7DFdVPLCU?domain=${selectedDomain}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.footerInfo
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Footer Info"
                />
                {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
              </ListItem> */}

              {/* <ListItem
                disabled={!Auth0Service.isAuthenticated()}
                role="listitem"
                onClick={() => moveToLogin()}
                component={Auth0Service.isAuthenticated() ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/admin/privacy-page/NaJ:>%7D%5EDRVNv,RVR4$vpa%7DFdVPLCU?domain=${selectedDomain}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.privacy
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Privacy Page"
                />
                {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
              </ListItem> */}

              {/* <ListItem
                disabled={!Auth0Service.isAuthenticated()}
                role="listitem"
                onClick={() => moveToLogin()}
                component={Auth0Service.isAuthenticated() ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/admin/skip-to-content/NaJ:>%7D%5EDRVNv,RVR4$vpa%7DFdVPLCU?domain=${selectedDomain}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.skipToContent
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Skip to Content"
                />
                {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
              </ListItem>
              <ListItem
                disabled={!Auth0Service.isAuthenticated()}
                role="listitem"
                onClick={() => moveToLogin()}
                component={Auth0Service.isAuthenticated() ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/admin/accessibility-statement/NaJ:>%7D%5EDRVNv,RVR4$vpa%7DFdVPLCU?domain=${selectedDomain}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.accessibilityStatement
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Accessibility Statement"
                />
                {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
              </ListItem> */}
              <ListItem
                disabled={!Auth0Service.isAuthenticated()}
                role="listitem"
                onClick={() => moveToLogin()}
                component={Auth0Service.isAuthenticated() ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/allyright-ai/technologies-info?domain=${selectedDomain}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.technologiesInfo
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Technologies"
                />
                {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
              </ListItem>

              <ListItem
                disabled={!Auth0Service.isAuthenticated()}
                role="listitem"
                onClick={() => moveToLogin()}
                component={Auth0Service.isAuthenticated() ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/allyright-ai/modiefied-pages-log?domain=${selectedDomain}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.modifiedLogs
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Modified Pages Logs"
                />
                {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
              </ListItem>
            </Collapse>
          }
          {/* 
        <ListItem
          role="listitem"
          component={isDomainPackage ? Link : "button"}
          to={`/violation-report/spell-check?domain=${selectedDomain}&scanId=${selectedScanId}`}
          aria-label="Grammar Report"
          className={clsx(
            activePath.spellCheckReport
              ? classes.listItemActive
              : classes.listItem
          )}
        >
          <ListItemIcon className={classes.iconContainer}>
            <FontAwesomeIcon className="sidebarIcons" style={{ fontSize: "1.6em" }} icon={faSpellCheck} />
          </ListItemIcon>
          <ListItemText primary="Grammar" />
        </ListItem> */}

          {/* <ListItem
            disabled={!auth0Service.isAuthenticated()}
            component={auth0Service.isAuthenticated() ? Link : "button"}
            style={{ cursor: "pointer" }}
            role="listitem"
            aria-label="Domains"
            className={clsx(
              activePath.domains ? classes.listItemActive : classes.listItem
            )}
            to={
              auth0Service.isAuthenticated()
                ? `/dashboard/domains?domain=${selectedDomain}&scanId=${selectedScanId}`
                : undefined
            }
          >
            <ListItemIcon className={classes.iconContainer}>
              <img width="20" src={domainIcon} alt="domain--v1" />
            </ListItemIcon>
            <ListItemText primary="Domains" />
            {!auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
          </ListItem> */}

          <ListItem
            disabled={!isDomainPackage}
            component={isDomainPackage ? Link : "button"}
            style={{ cursor: "pointer" }}
            role="listitem"
            aria-label="Scans"
            className={clsx(
              activePath.scans ? classes.listItemActive : classes.listItem
            )}
            to={
              auth0Service.isAuthenticated()
                ? `/scans?domain=${selectedDomain}&scanId=${selectedScanId}`
                : undefined
            }
          >
            <ListItemIcon className={classes.iconContainer}>
              <img src={ScansIcon} width="18px" alt="Security Icon" />
            </ListItemIcon>
            <ListItemText primary="Scans" />
            {!isDomainPackage && <LockIcon className={classes.lockIcon} />}
          </ListItem>
          {/* <ListItem
          disabled={!isDomainPackage}

          role="listitem"
          onClick={() => moveToLogin()}
          component={isDomainPackage ? Link : "button"}
          to={auth0Service.isAuthenticated() ? `/cve?domain=${selectedDomain}&scanId=${selectedScanId}` : undefined}
          aria-label="cve"
          className={clsx(
            activePath.cve ? classes.listItemActive : classes.listItem
          )}
        >
          <ListItemIcon className={classes.iconContainer}>
            <img src={CveIcon} width="18px" alt="cve Icon" />
          </ListItemIcon>
          <ListItemText primary="CVE" />
          {!isDomainPackage && <LockIcon className={classes.lockIcon} />}
        </ListItem> */}

          {modules.technologies && (
            <ListItem
              disabled={!isDomainPackage}
              role="listitem"
              onClick={() => moveToLogin()}
              component={isDomainPackage ? Link : "button"}
              to={
                auth0Service.isAuthenticated()
                  ? `/technologies?domain=${selectedDomain}&scanId=${selectedScanId}`
                  : undefined
              }
              aria-label="technologies"
              className={clsx(
                activePath.technologies
                  ? classes.listItemActive
                  : classes.listItem
              )}
            >
              <ListItemIcon className={classes.iconContainer}>
                <img src={TechnologiesIcon} width="18px" alt="technology Icon" />
              </ListItemIcon>
              <ListItemText primary="Technologies" />
              {!isDomainPackage && <LockIcon className={classes.lockIcon} />}
            </ListItem>
          )

          }
          <Divider style={{ margin: "10px 0px" }} />

          {/* <ListItem
          disabled={!isDomainPackage}
          role="listitem"
          onClick={() => moveToLogin()}
          component={isDomainPackage ? Link : "button"}
          to={auth0Service.isAuthenticated() ? `/checklist?domain=${selectedDomain}&scanId=${selectedScanId}` : undefined}
          className={clsx(
            activePath.checkList ? classes.listItemActive : classes.listItem
          )}
        >
          <ListItemIcon className={classes.iconContainer}>
            <img src={ChecklistIcon} width="18px" alt="Checklist Icon" />
          </ListItemIcon>
          <ListItemText primary="CheckList" />
          {!isDomainPackage && <LockIcon className={classes.lockIcon} />}
        </ListItem> */}
          {(modules.chat || modules.scrumboard || modules.issueTracker) && (
            <ListItem
              disabled={!Auth0Service.isAuthenticated()}
              component="button"
              style={{ cursor: "pointer" }}
              role="listitem"
              aria-label="project management list with 2 items"
              className={clsx(
                activePath.projectmanagement ? classes.listItemActive : classes.listItem
              )}
              onClick={() => {
                if (projectManagementList) {
                  dispatch(handleToggleProjectManagementList(false));
                } else {
                  dispatch(handleToggleProjectManagementList(true));
                }
              }}
            >
              <ListItemIcon className={classes.iconContainer}>
                <img src={SettingsIcon} width="18px" alt="Project Management Icon" />
              </ListItemIcon>
              <ListItemText primary="Project Management" />
              {projectManagementList ? <ExpandLess /> : <ExpandMore />}
              {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
            </ListItem>
          )
          }

          <Collapse in={projectManagementList} timeout="auto">
            {modules.issueTracker && (
              <ListItem
                disabled={!isDomainPackage}
                component="button"
                style={{ cursor: "pointer" }}
                role="listitem"
                aria-label="Issue list with 2 items"
                className={clsx(
                  activePath.issueTracker
                    ? classes.listItemActive
                    : classes.listItem
                )}
                onClick={() => {
                  if (issueList) {
                    dispatch(handleToggleIssueList(false));
                  } else {
                    dispatch(handleToggleIssueList(true));
                  }
                }}
              >
                <ListItemIcon className={classes.iconContainer}>
                  <img
                    src={IssueTrackerIcon}
                    width="18px"
                    alt="Issue Tracker Icon"
                  />
                </ListItemIcon>
                <ListItemText primary="Issue Tracker" />
                {issueList ? <ExpandLess /> : <ExpandMore />}
                {!isDomainPackage && <LockIcon className={classes.lockIcon} />}
              </ListItem>
            )
            }

            <Collapse in={issueList} timeout="auto">
              <ListItem
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                disabled={!isDomainPackage}
                role="listitem"
                onClick={() => moveToLogin()}
                component={isDomainPackage ? Link : "button"}
                to={`/issue-tracker/accessibility?domain=${selectedDomain}&scanId=${selectedScanId}`}
                aria-label="accessibility-sheet"
                className={clsx(
                  activePath.accessibilitySheet
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                {/* <ListItemIcon className={classes.iconContainer}>
              <img src={TechnologiesIcon} width="18px" alt="technology Icon" />
            </ListItemIcon> */}
                <ListItemText primary="Accessibility Sheets" />
                {!isDomainPackage && <LockIcon className={classes.lockIcon} />}
              </ListItem>
              <ListItem
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                disabled={!isDomainPackage}
                role="listitem"
                onClick={() => moveToLogin()}
                component={isDomainPackage ? Link : "button"}
                to={`/issue-tracker/privacy?domain=${selectedDomain}&scanId=${selectedScanId}`}
                aria-label="accessibility-sheet"
                className={clsx(
                  activePath.privacySheet
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                {/* <ListItemIcon className={classes.iconContainer}>
              <img src={TechnologiesIcon} width="18px" alt="technology Icon" />
            </ListItemIcon> */}
                <ListItemText primary="Privacy Sheets" />
                {!isDomainPackage && <LockIcon className={classes.lockIcon} />}
              </ListItem>
              <ListItem
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                disabled={!isDomainPackage}
                role="listitem"
                onClick={() => moveToLogin()}
                component={isDomainPackage ? Link : "button"}
                to={`/issue-tracker/security?domain=${selectedDomain}&scanId=${selectedScanId}`}
                aria-label="Privacy-sheet"
                className={clsx(
                  activePath.securitySheet
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                {/* <ListItemIcon className={classes.iconContainer}>
              <img src={TechnologiesIcon} width="18px" alt="technology Icon" />
            </ListItemIcon> */}
                <ListItemText primary="Security Sheets" />
                {!isDomainPackage && <LockIcon className={classes.lockIcon} />}
              </ListItem>
              <ListItem
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                disabled={!isDomainPackage}
                role="listitem"
                onClick={() => moveToLogin()}
                component={isDomainPackage ? Link : "button"}
                to={`/issue-tracker/performance?domain=${selectedDomain}&scanId=${selectedScanId}`}
                aria-label="performance-sheet"
                className={clsx(
                  activePath.performanceSheet
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                {/* <ListItemIcon className={classes.iconContainer}>
              <img src={TechnologiesIcon} width="18px" alt="technology Icon" />
            </ListItemIcon> */}
                <ListItemText primary="Performance Sheets" />
                {!isDomainPackage && <LockIcon className={classes.lockIcon} />}
              </ListItem>
              <ListItem
                disabled={!isDomainPackage}
                role="listitem"
                onClick={() => moveToLogin()}
                component={isDomainPackage ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/issue-tracker/issues?domain=${selectedDomain}&scanId=${selectedScanId}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.issuesLists
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Lists"
                />
                {!isDomainPackage && <LockIcon className={classes.lockIcon} />}
              </ListItem>

              <ListItem
                disabled={!isDomainPackage}
                role="listitem"
                onClick={() => moveToLogin()}
                component={isDomainPackage ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/issue-tracker/milestones?domain=${selectedDomain}&scanId=${selectedScanId}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.milestonesLists
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Milestones"
                />
                {!isDomainPackage && <LockIcon className={classes.lockIcon} />}
              </ListItem>
            </Collapse>

            {/* <ListItem
          disabled={!isDomainPackage}
          role="listitem"
          onClick={() => moveToLogin()}
          component={isDomainPackage ? Link : "button"}
          to={auth0Service.isAuthenticated() ? `/issue-tracker?domain=${selectedDomain}&scanId=${selectedScanId}` : undefined}
          aria-label="issue tracker"
          className={clsx(
            activePath.issueTracker ? classes.listItemActive : classes.listItem
          )}
        >
          <ListItemIcon className={classes.iconContainer}>
            <img src={IssueTrackerIcon} width="18px" alt="Issue Tracker Icon" />
          </ListItemIcon>
          <ListItemText primary="Issue Tracker" />
          {!isDomainPackage && <LockIcon className={classes.lockIcon} />}
        </ListItem> */}
            {modules.scrumboard && (
              <ListItem
                disabled={!isDomainPackage}
                role="listitem"
                onClick={() => moveToLogin()}
                component={isDomainPackage ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/scrumboard?domain=${selectedDomain}&scanId=${selectedScanId}`
                    : undefined
                }
                aria-label="Scrum Board"
                className={clsx(
                  activePath.scrum ? classes.listItemActive : classes.listItem
                )}
              >
                <ListItemIcon className={classes.iconContainer}>
                  <img src={ScrumIcon} width="18px" alt="Scrum Board Icon" />
                </ListItemIcon>
                <ListItemText primary="Scrum Board" />
                {!isDomainPackage && <LockIcon className={classes.lockIcon} />}
              </ListItem>)
            }

            {modules.chat && (
              <ListItem
                disabled={!isDomainPackage}
                role="listitem"
                onClick={() => moveToLogin()}
                component={isDomainPackage ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/chat?domain=${selectedDomain}&scanId=${selectedScanId}`
                    : undefined
                }
                aria-label="Chat"
                className={clsx(
                  activePath.chat ? classes.listItemActive : classes.listItem
                )}
              >
                <ListItemIcon className={classes.iconContainer}>
                  <img src={ChatIcon} width="18px" alt="chat Icon" />
                </ListItemIcon>
                <ListItemText primary="Chat" />
                {!isDomainPackage && <LockIcon className={classes.lockIcon} />}
              </ListItem>)
            }
          </Collapse>

          <Divider style={{ margin: "10px 0px" }} />
          {(modules.settingActivities || modules.settingBilling || modules.settingDomains || modules.settingPaymentMethod || modules.settingProfile || modules.settingUsers) &&
            (
              <ListItem
                disabled={!Auth0Service.isAuthenticated()}
                component="button"
                style={{ cursor: "pointer" }}
                role="listitem"
                aria-label="settings list with 2 items"
                className={clsx(
                  activePath.settings ? classes.listItemActive : classes.listItem
                )}
                onClick={() => {
                  if (settingList) {
                    dispatch(handleToggleSettingList(false));
                  } else {
                    dispatch(handleToggleSettingList(true));
                  }
                }}
              >
                <ListItemIcon className={classes.iconContainer}>
                  <img src={SettingsIcon} width="18px" alt="Issue Tracker Icon" />
                </ListItemIcon>
                <ListItemText primary="Settings" />
                {settingList ? <ExpandLess /> : <ExpandMore />}
                {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
              </ListItem>
            )
          }

          <Collapse in={settingList} timeout="auto">
            {modules.settingDomains && (
              <ListItem
                disabled={!Auth0Service.isAuthenticated()}
                role="listitem"
                onClick={() => moveToLogin()}
                component={Auth0Service.isAuthenticated() ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/dashboard/domains?domain=${selectedDomain}&scanId=${selectedScanId}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.domains
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Domains"
                />
                {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
              </ListItem>
            )
            }
            {modules.settingProfile && (
              <ListItem
                // disabled={!isDomainPackage}
                role="listitem"
                onClick={() => moveToLogin()}
                // component={isDomainPackage ? Link : "button"}
                component={Link}
                to={
                  auth0Service.isAuthenticated()
                    ? `/my-profile?domain=${selectedDomain}&scanId=${selectedScanId}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.myProfile
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="My Profile"
                />
                {/* {!isDomainPackage && <LockIcon className={classes.lockIcon} />} */}
              </ListItem>
            )}
            {modules.settingBilling && (
              <ListItem
                disabled={!isDomainPackage}
                role="listitem"
                component={isDomainPackage ? Link : "button"}
                // component={"button"}
                to={`/billing?domain=${selectedDomain}&scanId=${selectedScanId}`}
                // onClick={() => {
                //   const token = auth0Service.getAccessToken();
                //   window.open(
                //     `${PAYMENTS_BASE_URL}/billing?domainId=${selectedDomainId}&token=${token}&baseurl=asdasda&package=${JSON.stringify(domainPackage)}`, "_blank");
                // }}
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.financials
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Billing"
                />
                {!isDomainPackage && <LockIcon className={classes.lockIcon} />}
              </ListItem>
            )
            }
            {modules.settingPaymentMethod && (
              <ListItem
                // disabled={!isDomainPackage}
                role="listitem"
                onClick={() => moveToLogin()}
                // component={isDomainPackage ? Link : "button"}
                component={Link}
                to={
                  auth0Service.isAuthenticated()
                    ? `/payment-methods?domain=${selectedDomain}&scanId=${selectedScanId}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.payments
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Payment Methods"
                />
                {/* {!isDomainPackage && <LockIcon className={classes.lockIcon} />} */}
              </ListItem>
            )
            }
            {modules.settingUsers && (
              <ListItem
                disabled={!isDomainPackage}
                role="listitem"
                onClick={() => moveToLogin()}
                component={isDomainPackage ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/users?domain=${selectedDomain}&scanId=${selectedScanId}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.users
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Users"
                />
                {!isDomainPackage && <LockIcon className={classes.lockIcon} />}
              </ListItem>
            )
            }
            {modules.settingActivities && (
              <ListItem
                disabled={!isDomainPackage}
                role="listitem"
                onClick={() => moveToLogin()}
                component={isDomainPackage ? Link : "button"}
                to={
                  auth0Service.isAuthenticated()
                    ? `/activity-logs?domain=${selectedDomain}&scanId=${selectedScanId}`
                    : undefined
                }
                style={{ padding: "4px 4px", paddingLeft: "54px" }}
                className={clsx(
                  activePath.activityLogs
                    ? classes.listItemNestedActive
                    : classes.listItem
                )}
              >
                <ListItemText
                  style={{
                    fontSize: "15px",
                  }}
                  primary="Activities"
                />
                {!isDomainPackage && <LockIcon className={classes.lockIcon} />}
              </ListItem>
            )
            }
          </Collapse>
          <ListItem
            // disabled={!isDomainPackage}
            role="listitem"
            // onClick={() => moveToLogin()}
            // component={isDomainPackage ? Link : "button"}
            component={Link}
            to={
              auth0Service.isAuthenticated()
                ? `/help-and-support?domain=${selectedDomain}&scanId=${selectedScanId}`
                : `/help-and-support`
            }
            aria-label="Help and Support"
            className={clsx(
              activePath.helpSupport ? classes.listItemActive : classes.listItem
            )}
          >
            <ListItemIcon className={classes.iconContainer}>
              <img
                src={HelpAndSupportIcon}
                width="18px"
                alt="Help & Support icon"
              />
            </ListItemIcon>
            <ListItemText primary="Help & Support" />
            {/* {!isDomainPackage && <LockIcon className={classes.lockIcon} />} */}
          </ListItem>
          {user?.user?.role && (user?.user?.data?.allowAdminPanel === true) &&
            <ListItem
              disabled={!Auth0Service.isAuthenticated()}
              component="button"
              role="listitem"
              aria-label="admin list with 2 items"
              className={clsx(
                activePath.admin ? classes.listItemActive : classes.listItem
              )}
              onClick={() => {
                if (adminManagementList) {
                  dispatch(handleToggleAdminManagementList(false));
                } else {
                  dispatch(handleToggleAdminManagementList(true));
                }
              }}
            >
              <ListItemIcon className={classes.iconContainer}>
                <img src={AdminIcon} width="18px" alt="Issue Tracker Icon" />
              </ListItemIcon>
              <ListItemText primary="Admin" />
              {adminManagementList ? <ExpandLess /> : <ExpandMore />}
              {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
            </ListItem>
          }
          {user?.user?.role && (user?.user?.data?.allowAdminPanel === true) &&
            <>
              <Collapse in={adminManagementList} timeout="auto">
                <ListItem
                  role="listitem"
                  component={Link}
                  to={
                    auth0Service.isAuthenticated()
                      ? `/admin/manage-scans`
                      : undefined
                  }
                  onClick={() => moveToLogin()}
                  style={{ padding: "4px 4px", paddingLeft: "54px" }}
                  className={clsx(
                    activePath.adminManagement ? classes.listItemActive : classes.listItem
                  )}
                >
                  {/* <ListItemIcon className={classes.iconContainer}>
                <img
                  src={AdminIcon}
                  width="18px"
                  alt="Admin Management"
                />
              </ListItemIcon> */}
                  <ListItemText primary="Admin Management" />
                </ListItem>

                <ListItem
                  role="listitem"
                  component={Link}
                  to={
                    auth0Service.isAuthenticated()
                      ? `/admin/manage-users`
                      : undefined
                  }
                  onClick={() => moveToLogin()}
                  style={{ padding: "4px 4px", paddingLeft: "54px" }}
                  className={clsx(
                    activePath.userManagement ? classes.listItemActive : classes.listItem
                  )}
                >
                  {/* <ListItemIcon className={classes.iconContainer}>
                <img
                  src={AdminIcon}
                  width="18px"
                  alt="Admin Management"
                />
              </ListItemIcon> */}
                  <ListItemText primary="Accounts" />
                </ListItem>

                <ListItem
                  disabled={!Auth0Service.isAuthenticated()}
                  component="button"
                  role="listitem"
                  aria-label="admin list with 2 items"
                  className={clsx(
                    activePath.websiteManage ? classes.listItemActive : classes.listItem
                  )}
                  onClick={() => {
                    if (websiteManagementList) {
                      dispatch(handleToggleWebsiteManagementList(false));
                    } else {
                      dispatch(handleToggleWebsiteManagementList(true));
                    }
                  }}
                >
                  <ListItemIcon className={classes.iconContainer}>
                    {/* <img src={AdminIcon} width="18px" alt="Issue Tracker Icon" /> */}
                  </ListItemIcon>
                  <ListItemText primary="Websites" />
                  {websiteManagementList ? <ExpandLess /> : <ExpandMore />}
                  {!Auth0Service.isAuthenticated() && <LockIcon className={classes.lockIcon} />}
                </ListItem>

                <Collapse in={websiteManagementList} timeout="auto">
                  <ListItem
                    role="listitem"
                    component={Link}
                    to={
                      auth0Service.isAuthenticated()
                        ? `/admin/manage-websites-production`
                        : undefined
                    }
                    onClick={() => moveToLogin()}
                    style={{ padding: "4px 4px", paddingLeft: "54px" }}
                    className={clsx(
                      activePath.websiteManagement ? classes.listItemActive : classes.listItem
                    )}
                  >
                    <ListItemText primary="Production" />
                  </ListItem>

                  <ListItem
                    role="listitem"
                    component={Link}
                    to={
                      auth0Service.isAuthenticated()
                        ? `/admin/manage-websites-demo`
                        : undefined
                    }
                    onClick={() => moveToLogin()}
                    style={{ padding: "4px 4px", paddingLeft: "54px" }}
                    className={clsx(
                      activePath.websiteManagementDemo ? classes.listItemActive : classes.listItem
                    )}
                  >
                    <ListItemText primary="Demo" />
                  </ListItem>
                </Collapse>

              </Collapse>
            </>

          }

          <Divider style={{ margin: "10px 0px" }} />

          {showLoggingOut && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: '#FFF',
                zIndex: 999,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'black',
                fontSize: '24px',
              }}
            >
              LoggingOut...
            </div>
          )}

          {auth0Service.isAuthenticated() && (
            <ListItem
              style={{ cursor: "pointer" }}
              role="listitem"
              component={isDomainPackage ? Link : "button"}
              aria-label="Logout"
              className={classes.listItem}
              onClick={() => logoutSystem()}
            >
              <ListItemIcon className={classes.iconContainer}>
                <img src={LogoutIcon} width="18px" alt="logout" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          )}
        </List>
      )}
    </div>
  );

  // const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header
        generalTabs={activePath}
        ham={
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuOpenIcon />
          </IconButton>
        }
      />
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden mdUp implementation="css">
          <Drawer
            // container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
              root: classes.drawerRoot,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        {!isDomainPackage &&
          !activePath.onBoarding &&
          scanProgress.status === "completed" &&
          Auth0Service.isAuthenticated() && (domains.length > 0) && (
            <Box px={4} mt={2}>
              {" "}
              <h3 style={{ marginBottom: 0, display: "inline" }}>
                Upgrade to have full access to the analytics and reports.
              </h3>{" "}
              <Link
                to={`/account-setup?domain=${selectedDomain}&scanId=${selectedScanId}`}
              >
                Buy Now
              </Link>
            </Box>
          )}
        <Box
          py={
            activePath.scrum || activePath.chat || activePath.issueTracker
              ? 0
              : 2
          }
          px={
            activePath.scrum || activePath.chat || activePath.issueTracker
              ? 0
              : 2
          }
          pt={
            !emailVerified && (!activePath.scrum || !activePath)
              ? 5
              : activePath.scrum || activePath.chat || activePath.issueTracker
                ? 0
                : 2
          }
          height="100%"
        >
          <ErrorHandler>{!delayed && switchRoutes}</ErrorHandler>
        </Box>
      </main>
      <LogoutModal open={logout} />
      <AddDomainLinkDialog open={openModal} onClose={handleCloseModal} />
      <div
        style={{
          visibility: "hidden",
          height: "1px",
          display: hide ? "none" : "block",
        }}
      >
        <OldDashboardPreload />
      </div>
      {/* {!isPackagePurchased && <AppBar position="fixed" className={classes.bottomAppBar}>
        <Box display="flex" width="100%" justifyContent="flex-end" alignItems="center"> <p style={{ margin: 0, marginRight: 20, display: 'inline', color: "black", fontSize: 16, fontWeight: 600 }} > keep your website compliant, safe, fast, and with good grammar </p>
          <Button variant="contained" color="primary" component={isDomainPackage ? Link : "button"} to="/billing">Upgrade Package</Button>
        </Box>
      </AppBar>} */}
    </div>
  );
}

// Layout.propTypes = {
//   window: PropTypes.func,
// };

export default Layout;
