import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import Box from "@material-ui/core/Box";

const style = {
  cursor: "move",
};

export default function Card({
  id,
  text,
  index,
  moveCard,
  setIsDragging,
}) {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  React.useEffect(() => {
    setIsDragging(isDragging);
    // eslint-disable-next-line
  }, [isDragging]);
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Box my={4}
      ref={ref}
      style={{ ...style, opacity }}
      data-handler-id={handlerId}
    >
      {text}
    </Box>
  );
}
