export const SET_SCAN_DATA = 'SET_SCAN_DATA';
export const TABLE_ACTION_LOADING = 'TABLE_ACTION_LOADING';
export const SET_SCANS = 'SET_SCANS';



export const setScanData = (selectedDomain, selectedScanId, selectedScan,selectedScanManagerId) => async (dispatch) => {
  dispatch({
    type: SET_SCAN_DATA,
    payload: { selectedDomain, selectedScanId, selectedScan, selectedScanManagerId }
  });
}

export const clearScanData = () => async (dispatch) => {
  dispatch({
    type: SET_SCAN_DATA,
    payload: { selectedDomain: null, selectedScanId: null, selectedScan: null,selectedScanManagerId: null}
  });
}

export const setScans = (scans) => async (dispatch) => {
  dispatch({
    type: SET_SCANS,
    payload: scans
  });
}


export const tableAction = () => async (dispatch) => {
  dispatch({
    type: TABLE_ACTION_LOADING,
  });
}


