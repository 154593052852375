import React from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import SpellCheckTables from "../components/dnd/tables/spellcheck/Cards";

import SpellCheckTable from "../components/tables/spellCheckTables/SpellCheckTable";
import SpellCheckArchivedIssuesTable from "../components/tables/spellCheckTables/SpellCheckArchivedIssuesTable";
import SpellCheckCompletedIssuesTable from "../components/tables/spellCheckTables/SpellCheckCompletedIssuesTable";
import SpellCheckIgnoredIssuesTable from "../components/tables/spellCheckTables/SpellCheckIgnoredIssuesTable";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import SpellingGrammerCheckCard from "../components/analyticsCard/SpellingGrammerCheckCard";
import Grid from "@material-ui/core/Grid";
import loaderwave from "../assets/loader.svg";

export default function SpellCheckReport() {

  const selectedScanId = useSelector((state) => state.miscellaneous.selectedScanId);

  const isDomainPurchased = useSelector((state) => state.dashboard.isDomainPackagePurchased);

  const [reportType, setReportType] = React.useState({
    tables: false,
    analytics: true,
  });

  const backArray = ["spellCheck", "archived", "completed", "ignored"];

  const [tablesArray, setTablesArray] = React.useState([]);

  React.useEffect(() => {
    if (selectedScanId) {
      const preTables = [
        {
          text: <SpellCheckTable scanId={selectedScanId} />,
          id: "spellCheck",
        },
        {
          text: <SpellCheckArchivedIssuesTable scanId={selectedScanId} />,
          id: "archived",
        },
        {
          text: <SpellCheckCompletedIssuesTable scanId={selectedScanId} />,
          id: "completed",
        },
        {
          text: <SpellCheckIgnoredIssuesTable scanId={selectedScanId} />,
          id: "ignored",
        },
      ];
      const arrayo = [];
      if (backArray.length > 0 && selectedScanId)
        for (let i = 0; i < backArray.length; i++) {
          switch (backArray[i]) {
            case "spellCheck":
              arrayo.push(
                preTables.filter((box) => box.id === "spellCheck")[0]
              );
              break;
            case "archived":
              arrayo.push(preTables.filter((box) => box.id === "archived")[0]);
              break;
            case "completed":
              arrayo.push(preTables.filter((box) => box.id === "completed")[0]);
              break;
            default:
              arrayo.push(preTables.filter((box) => box.id === "ignored")[0]);
          }
        }
      setTablesArray([...arrayo]);
    }
    // eslint-disable-next-line
  }, [selectedScanId]);

  const [delayed, setDelayed] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setDelayed(false)
    }, 1500);
  }, [])

  if (!isDomainPurchased) {
    return(<Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        tabIndex="0"
      >
        <h3>Have no Access to this page</h3>
      </Box>);
  } else {
    return (
      <>
        {!delayed ?
          <>  <div>
            {selectedScanId && <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Box display="flex" flexWrap="wrap" alignItems="center">
                <ButtonGroup
                  size="small"
                  aria-label="Button group to switch between analytics and report"
                >
                  <Button
                    disableRipple={true}
                    style={{
                      backgroundColor: reportType.analytics ? "#21CA5A" : "#7E7E7E",
                      color: "#ffffff",
                    }}
                    onClick={() => setReportType({ analytics: true })}
                  >
                    Analytics
                  </Button>
                  <Button
                    disableRipple={true}
                    style={{
                      backgroundColor: reportType.tables ? "#21CA5A" : "#7E7E7E",
                      color: "#ffffff",
                    }}
                    onClick={() => setReportType({ tables: true })}
                  >
                    Report
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
            }
            {selectedScanId ? (
              <>
                {reportType.analytics && (
                  <>
                    <Grid item xs={12} md={8}>
                      <SpellingGrammerCheckCard scanId={selectedScanId} />
                    </Grid>
                  </>
                )}

                {reportType.tables && (
                  <>
                    {tablesArray.length > 0 && (
                      <DndProvider backend={HTML5Backend}>
                        <SpellCheckTables tables={tablesArray} />
                      </DndProvider>
                    )}
                  </>
                )}
              </>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                tabIndex="0"
              >
                <img src={loaderwave} alt="loader" />
              </Box>
            )}
          </div>
          </> :
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            tabIndex="0"
          >
            <img src={loaderwave} alt="loader" />
          </Box>
        }
      </>
    );
  }
}