import React, { Suspense } from "react";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import SubLoader from "../components/commons/SubLoader";
import ScanSelector from "../components/commons/ScanSelector";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const BasicScan = React.lazy(() => import("./BasicScan"));
const ScanSettings = React.lazy(() => import("./DomainSettings"));
const AdvancedScan = React.lazy(() => import("./AdvanceScan"));
const ScansData = React.lazy(() => import("./CustomScanComponents/ScansDeletion"));

export default function Scans() {
  const [tab, setTab] = React.useState({
    quickScan: false,
    scanSettings: true,
    advancedScan: false,
    scansData: false,
  });

  const CustomTab = withStyles((theme) => ({
    root: {
      minWidth: 40,
      opacity: 1,
      minHeight: "auto",
      borderRadius: "2px",
      padding: "0px 8px",
      border: "1px solid #1f3a93",
      color: "#1f3a93",
      fontWeight: 400,
      fontSize: "18px",
      borderRight: 0,
      "&:last-child": {
        borderRight: "1px solid #1f3a93",

      },
      "&:hover": {
        color: "#ffff!important",
        backgroundColor: "#1f3a93",
        opacity: 1,
      },
      "&$selected": {
        color: "white",
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: "#1f3a93",
      },
      "&:focus": {
        color: "white",
        backgroundColor: "#1f3a93",
      },
    },
    selected: {
      color: "white",
      backgroundColor: "#1f3a93",
      // display: 'none'
    },
  }))((props) => <Tab disableRipple {...props} />);

  return (
    <Suspense fallback={<SubLoader />}>
      <ScanSelector />
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        {/* Quick scan features moved to scan settings */}

        <CustomTab
          label="Scan"
          onClick={() => setTab({ scanSettings: true })}
          style={{
            backgroundColor: tab.scanSettings ? "#1f3a93" : "none",
            color: tab.scanSettings ? "#ffff" : "#1f3a93",
          }}
          tabIndex="0"
        />

        <CustomTab
          label="Advance scan"
          onClick={() => setTab({ advancedScan: true })}
          style={{
            backgroundColor: tab.advancedScan ? "#1f3a93" : "none",
            color: tab.advancedScan ? "#ffff" : "#1f3a93",
          }}
          tabIndex="0"
        />

        <CustomTab
          label="Scan Data"
          onClick={() => setTab({ scansData: true })}
          style={{
            backgroundColor: tab.scansData ? "#1f3a93" : "none",
            color: tab.scansData ? "#ffff" : "#1f3a93",
          }}
          tabIndex="0"
        />
      </Box>
      <Box mb={3}>
        {/* {tab.quickScan && <BasicScan />} */}
        {tab.scanSettings && <ScanSettings />}
        {tab.advancedScan && <AdvancedScan />}
        {tab.scansData && <ScansData />}
      </Box>
    </Suspense>
  );
}
