import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function CircularProgressRing() {

  return (
    <div style={{ marginLeft: 10 }}>
      <CircularProgress style={{ width: "20px", height: "20px", color: "rgb(31, 58, 147)" }} />
    </div>
  );
}
