import React from "react";


const styles = {

  statusIconOnline: {
    border: '1px solid white',
    height: 10,
    width: 10,
    backgroundColor: '#64e764',
    borderRadius: '50%',
    display: 'inline-block',
    boxShadow: '2px 3px 3px #A8A8A8',
    marginBottom: "11px"
  }
}

export default function OfflineIcon() {
  return (
    <div style={styles.statusIconOnline}></div>
  );
}