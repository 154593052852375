import {
  LOADING_AUDIO,
  GET_AUDIO_CARD_DETAILS,
  GET_AUDIOS_HISTORY,
  LOADING_AUDIOS_HISTORY
} from '../actions/audioCardActions';

const initialState = {
  data: null,
  loading: false,
  status: "pending",

  audiosHistory: null,
  audiosHistoryLoading: false,
  audiosHistoryStatus: null,
};

const audioCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUDIO_CARD_DETAILS:
      return {
        ...state,
        data: action.payload.result,
        status: action.payload.status,
        loading: false
      }
    case LOADING_AUDIO:
      return {
        ...state,
        loading: true
      }

    case GET_AUDIOS_HISTORY:
      return {
        ...state,
        audiosHistory: action.payload.result.graph,
        audiosHistoryStatus: action.payload.status,
        audiosHistoryLoading: false
      }
    case LOADING_AUDIOS_HISTORY:
      return {
        ...state,
        audiosHistoryLoading: true
      }
    default:
      return state;
  }
};

export default audioCardReducer; 