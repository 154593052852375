import { GET_CONTACTS } from '../actions/contactsActions';

const initialState = {
  data: [],
  loading: false
};

 const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACTS:
      return {
        ...state,
        data: action.payload
      }
    default:
      return state;
  }
};

export default contactsReducer;