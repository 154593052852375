 
import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { toast } from "react-toastify";

export const GET_NUMBER_OF_PAGES_WITH_DETAILS = 'GET_NUMBER_OF_PAGES_WITH_DETAILS';
export const LOADING_TAGS = 'LOADING_TAGS';

export const GET_HEADINGS_HISTORY = 'GET_HEADINGS_HISTORY';
export const LOADING_HEADINGS_HISTORY = 'LOADING_HEADINGS_HISTORY';
export const RESET_LOADING_HEADINGS_HISTORY = 'RESET_LOADING_HEADINGS_HISTORY';



export const getNumberOfPagesWithDetails = ( scanId, tag) => async (dispatch) => {
  dispatch({
    type: LOADING_TAGS,
  });
  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const params = {
      scanId,
      tag
    }
    
    
    const res = await axios.get(`${BASE_URL}/dashboard/headings-result`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_NUMBER_OF_PAGES_WITH_DETAILS,
        payload: res.data
      });

    }

  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}


export const getHeadingsHistory = (scanId, dateNowRange, tag) => async (dispatch) => {
  dispatch({
    type: RESET_LOADING_HEADINGS_HISTORY,
  });
  dispatch({
    type: LOADING_HEADINGS_HISTORY,
  });

  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const params = {
      dateNowRange,
      scanId,
      tag
    }

    const res = await axios.get(`${BASE_URL}/history/headings-history`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_HEADINGS_HISTORY,
        payload: res.data
      });

    }

  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}