const drawerWidth = 250;
const mainLayoutStyles = (theme) => ({
  root: {
    display: "flex",
    height: "100%",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    '& .MuiDrawer-paperAnchorDockedLeft': {
      border: 'none'
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },

  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    padding: 20,
    minHeight: 45,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    [theme.breakpoints.up("lg")]: {
      minHeight: 45,
      background: "inherit",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: "#FFFFFF",
    position: "relative",
  },
  drawerRoot: {
    height: "100%",
  },
  content: {
    flexGrow: 1,
    width: "calc(100% - 250px)",
    padding: "3.70rem 0px 0px 0px",
    [theme.breakpoints.down("md")]: {
      padding: "4rem 0px 15px 0px",
      width: "100%",
    },
  },
  listItem: {
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 10,
    border: 'none',
    outline: 'none',
    backgroundColor: 'inherit',
    color: "#000",
    // '& img': {
    //   filter: 'brightness(0)'
    // },

    "&:hover": {
      backgroundColor: "#b8c7ff",
    },
    "&:focus": {
      backgroundColor: "#b8c7ff",
    },
  },
  listItemNestedActive: {
    borderRadius: 10,
    backgroundColor: "#b8c7ff",
    color: "#000",
    "&:hover": {
      backgroundColor: "#b8c7ff",
    },
    "&:focus": {
      backgroundColor: "#b8c7ff",
    },
  },

  listItemActive: {
    borderRadius: 10,
    paddingTop: 4,
    paddingBottom: 4,
    border: 'none',
    outline: 'none',
    '& img': {
      filter: 'brightness(5)'
    },


    "&:hover": {
      backgroundColor: "#577CFF",
    },
    "&:focus": {
      backgroundColor: "#577CFF",
    },
    backgroundColor: "#1f3a93",
    color: "#ffffff",
    paddingLeft: 16,
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 9,
    },
  },
  iconContainer: {
    minWidth: 32
  },
  bottomAppBar: {
    backgroundColor: '#a4b3e5eb',
    display: 'flex',
    color: '#eee',

    alignItems: 'center',
    justifyContent: 'center',
    top: 'auto',
    bottom: 0,
    padding: 20
  },
  lockIcon: {
    color: '#000'
  }
});

export default mainLayoutStyles;
