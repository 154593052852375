import React, { useState } from 'react';
import { Button, TextField, Grid, Typography, Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { addMultipleDomains } from '../../store/actions/adminScansManagementActions';

function DomainList() {
	const [domains, setDomains] = useState([]);
	const [newDomain, setNewDomain] = useState('');
	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch()
	const handleAddDomain = () => {
		if (newDomain.trim() !== '') {
			setDomains([...domains, newDomain]);
			setNewDomain('');
		}
	};

	const handleRemoveDomain = (domain) => {
		const updatedDomains = domains.filter((d) => d !== domain);
		setDomains(updatedDomains);
	};

	const handleSubmit = () => {
		setLoading(true)
		dispatch(addMultipleDomains({ domains })).then(data => {
			if (data) {
				setDomains([])
			}
			setLoading(false)
		}).catch(err => {
			setLoading(false)
		})

	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			handleAddDomain();
		}
	};
	return (
		<Box p={2}>
			<Typography variant="h6" gutterBottom>
				Add Domains
			</Typography>
			<Grid container spacing={2} alignItems="center">
				<Grid item xs={8}>
					<TextField
						label="Domain"
						variant="filled"
						fullWidth
						value={newDomain}
						onKeyPress={handleKeyPress}
						onChange={(e) => setNewDomain(e.target.value)}
					/>
				</Grid>
				<Grid item xs={4}>
					<Button variant="contained" color="primary" onClick={handleAddDomain}>
						Add
					</Button>
				</Grid>
				{domains.length > 0 && (
					<Grid item xs={12}>
						<Typography variant="subtitle1">Domain List:</Typography>
						{domains.map((domain) => (
							<Box display="flex" alignItems="center" key={domain} my={2}>
								<Typography variant='subtitle2'>{domain}</Typography>
								<Button
									variant="contained"
									color="secondary"
									size="small"
									onClick={() => handleRemoveDomain(domain)}
									style={{ marginLeft: '8px' }}
								>
									Remove
								</Button>
							</Box>
						))}
					</Grid>
				)}
				<Grid item xs={12}>
					<Button
						variant="contained"
						color="primary"
						onClick={handleSubmit}
						disabled={domains.length === 0 || loading}
					>
						{loading ? "Loading..." : "Submit"}
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
}

export default DomainList;
