import {

  GET_TECHNOLOGIES,
  ADD_TECHNOLOGY_LOADING,
  ADD_TECHNOLOGY_SUCCESS,
  GET_DOMAIN_TECHNOLOGIES,
  GET_CVES_SUCCESS,
  LOADING_CVESSS,
  LOADING_CVESSS_FALSE
} from '../actions/technologiesActions';

const initialState = {
  domainTechnologies: [],
  cveTechnologies: [],

  status: null,
  addTechLoading: false,
  pages:0,
  page:0,
  cves:null,
  cvesLoading:false

};

const technologiesCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TECHNOLOGIES:
      return {
        ...state,
        cveTechnologies: action.payload.result,
        status: action.payload.status,
        loading: false
      }

      case GET_DOMAIN_TECHNOLOGIES:
      return {
        ...state,
        domainTechnologies: action?.payload?.result,
        status: action.payload.status,
        loading: false
      }
    case ADD_TECHNOLOGY_LOADING:
      return {
        ...state,
        addTechLoading: action.payload
      }
    case ADD_TECHNOLOGY_SUCCESS:
      return {
        ...state,
        addTechLoading: false

      }
      case LOADING_CVESSS:
      return {
        ...state,
        cvesLoading: true
      }
      case LOADING_CVESSS_FALSE:
        return {
          ...state,
          cvesLoading: false
        }
      case GET_CVES_SUCCESS:
        return {
          ...state,
          cves: action?.payload,

          cvesLoading: false
  
        }
    default:
      return state;
  }
};

export default technologiesCardReducer;