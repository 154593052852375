import {
  SPELLCHECK_TABLE,
  SPELLCHECK_TABLE_LOADING,
  SPELLCHECK_MAIN_LOADING,
  SPELLCHECK_MAIN,
  SPELLCHECK_HISTORY,
  SPELLCHECK_HISTORY_LOADING
} from '../actions/spellCheckActions';

const initialState = {
  spellCheckTable: null,
  spellCheckTableLoading: false,
  spellCheckTableStatus: "pending",

  spellCheckMain: null,
  spellCheckMainStatus: null,
  spellCheckMainLoading: false,

  spellCheckHistory: null,
  spellCheckHistoryLoading: false,
  spellCheckHistoryStatus: null,
};

const spellCheckReducer = (state = initialState, action) => {
  switch (action.type) {
    case SPELLCHECK_TABLE:
      return {
        ...state,
        spellCheckTable: action.payload.result.issues,
        spellCheckTableStatus: action.payload.status,
        spellCheckTableLoading: false
      }
    case SPELLCHECK_TABLE_LOADING:
      return {
        ...state,
        spellCheckTableLoading: true
      }
    case SPELLCHECK_MAIN:
      return {
        ...state,
        spellCheckMain: action.payload.result,
        spellCheckMainStatus: action.payload.status,
        spellCheckMainLoading: false,
      }
    case SPELLCHECK_MAIN_LOADING:
      return {
        ...state,
        spellCheckMainLoading: true,
      }
    case SPELLCHECK_HISTORY:
      return {
        ...state,
        spellCheckHistory: action.payload.result.graph,
        spellCheckHistoryLoading: false,
        spellCheckHistoryStatus: action.payload.status
      }
    case SPELLCHECK_HISTORY_LOADING:
      return {
        ...state,
        spellCheckHistoryLoading: true
      }
    default:
      return state;
  }
};

export default spellCheckReducer;