import React from "react";
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';

export default function GraphCard({ children, height }) {
  return (
    <Card style={{ boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.15)", padding: 14, overflow: "visible", borderRadius: 9, height: height ? height : '100%' }} >
      <Box height="100%">
        {children}
      </Box>
    </Card>
  );
}