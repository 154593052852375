import React from "react";
import {
  Box,
  Divider,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import {
  closeMilestone,
  getMilestones,
} from "../../../store/actions/issueTrackerActions";
import { getDate } from "../../../source/functions";

const useStyles = makeStyles((theme) => ({
  label: {
    padding: "5px",
    // background: "#CD853F",
    marginTop: "0px",
    fontSize: "12px",
    color: "white",
  },
  ml: {
    marginLeft: "5px",
  },
  mt: {
    marginTop: "5px",
  },
  font: {
    fontSize: "12px",
  },
  btn: {
    border: "1px solid gray",
    borderRadius: "0px",
    lineHeight: "2px",
    padding: "10px",
  },
  del: {
    background: "red",
    border: "0px",
    color: "white",
  },
}));
const Content = ({ open, setOpen, milestone }) => {
  const compareDate = (date) => {
    const current = new Date();
    const due = new Date(date);
    return current > due;
  };
  const { selectedDomainId } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  let { listId, milestoneId } = useParams();
  let history = useHistory();


  const classes = useStyles();
  return (
    <Box>
      <Divider />
      <Box display={"flex"} justifyContent={"space-between"} py={1} >
        <Box display={"flex"} alignItems="center">
          <Box
            className={classes.label}
            style={{
              background: compareDate(milestone.due_date)
                ? "#CD853F"
                : "#32CD32",
            }}
          >
            {compareDate(milestone.start_date)
              ? compareDate(milestone.due_date)
                ? "past due"
                : "Active"
              : "Active Soon"}
          </Box>
          <Box display={"flex"} style={{ padding: "5px" }}>
            <Typography
              onClick={() => history.push('/issue-tracker/milestones')}
              className={`${classes.ml} ${classes.font}`}
              style={{ fontWeight: "bold", cursor: 'pointer', textDecoration: 'underline' }}
            >
              Milestones
            </Typography>
            <Typography className={`${classes.ml} ${classes.font}`}>
              {getDate(milestone.start_date)} to {getDate(milestone.due_date)}
            </Typography>
          </Box>
        </Box>
        <Box display={"flex"}>
          <Button
            variant="outlined"
            className={`${classes.ml}`}
            onClick={() => {
              setOpen(!open);
            }}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            className={`${classes.ml}`}
            onClick={() => {
              dispatch(
                closeMilestone({
                  domainId: selectedDomainId,
                  id: milestoneId,
                  status: milestone.status === "closed" ? "open" : "close",
                })
              ).then((data) => {
                if (data) {
                  history.push('/issue-tracker/milestones')
                }
              });
            }}
          >
            {milestone.status === "closed" ? "Open" : "Close"} Milestone
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box py={2}>
        Title: <Typography variant="" style={{ fontWeight: "bold", padding: "5px" }}>
          {milestone.title}
        </Typography>
      </Box>
      <Divider style={{ marginTop: "5px" }} />
      <h5>Description:</h5>
      <Typography className={`${classes.font} ${classes.mt}`}>
        <div dangerouslySetInnerHTML={{ __html: milestone.description }} />
      </Typography>
    </Box>
  );
};

export default Content;
