import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
const useStyles = makeStyles((theme) => ({
  badge_font: {
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
      fontWeight: '500',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '15px',
      fontWeight: '500',
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '17px',
      fontWeight: '500',
    },
    fontSize: '15px',
    fontWeight: '500',
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '25px',
    padding: '10px 10px',
  
    fontSize: '20px',
    fontWeight: 'bold',
  },
  yellow: {
    background: 'white',
    color: '#ffa500',
  },

  green: {
    background: 'white',
    color: '#008000',
  },
  red: {
    background: 'white',
    color: '#ff0000',
  },
}));

const Arrow = ({ change, value }) => {

  const classes = useStyles();
  return (
    <>
      {(change && (value || value === 0)) && <Box mx={1}>
        {change === 'down' ? (
          <Box display='flex'>
            <Box
              component={Typography}
              className={` ${classes.badge} ${classes.badge_font} ${classes.red}
              `}
            >
              <TrendingDownIcon style={{ color: 'red' }} />
              
              <Typography variant='p' style={{ marginLeft: '5px', fontSize:13 }}>
                {value}%
              </Typography>

            </Box>
          </Box>
        ) : change === 'up' || change === 'high' ? (
          <Box display='flex'>
            <Box
              component={Typography}
              className={` ${classes.badge} ${classes.badge_font} ${classes.green}
              `}
            >
              <TrendingUpIcon style={{ color: 'green' }} />


              <Typography variant='p' style={{ marginLeft: '5px', fontSize:13 }}>
                {value}%
              </Typography>

            </Box>
          </Box>
        ) : (
          change === 'same' && (
            <Box display='flex'>
              <Box
                component={Typography}
                className={` ${classes.badge} ${classes.badge_font} ${classes.yellow}
              `}
              >
                <GraphicEqIcon />
              </Box>
            </Box>

          )
        )}
      </Box>
      }
    </>
  );
};

export default Arrow;
