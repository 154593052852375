import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";

import StepConnector from '@material-ui/core/StepConnector';
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#1f3a93',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#1f3a93',
    },
  },
  line: {
    borderColor: '#1f3a93',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#1f3a93',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#1f3a93',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#1f3a93',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {active === 1 ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};


function getSteps() {
  return ['Crawling', 'Scanning'];
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  scansProgressSubList: {
    margin: "8px 0",
    fontWeight: 600,
    fontFamily: "Segoe UI",
  },
}));

export default function ScanProgressBox() {
  const classes = useStyles();

  const scanProgress = useSelector((state) => state.dashboard.scanProgress);

  return (
    <div className={classes.root}>


      <>
        {scanProgress.map((progress, index) => {
          return (
            <Box
              width="100%"
              key={index}
              p={2}
              my={2}
              style={{ boxShadow: "rgba(31, 58, 147, 0.1) 0px 3px 25px" }}
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <h4>Domain:{progress.domain}</h4>
              </Box>
              <ProgressStepper stage={progress.stage} />

              <p
                className={classes.scansProgressSubList}
              >
                Resolution : {progress.resolution}{" "}
              </p>
              <p
                className={classes.scansProgressSubList}
              >
                Date : {scanDate(progress.dateNow)}{" "}
              </p>
              <p
                className={classes.scansProgressSubList}
              >
                Time : {scanTime(progress.dateNow)}{" "}
              </p>
              <p
                className={classes.scansProgressSubList}
              >
                Type : {progress.type}{" "}
              </p>
              <Grid container alignItems="center" spacing={5}>
                <Grid item xs={2}>
                  <p
                    style={{
                      margin: 0,
                      fontWeight: 600,
                      fontFamily: "Segoe UI",
                    }}
                  >
                    {progress.percentage}%
                  </p>
                </Grid>
                <Grid item xs={10}>
                  <span>
                    <BorderLinearProgressHigh
                      variant="determinate"
                      value={progress.percentage}
                    />
                  </span>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </>
    </div>
  );
}


function scanDate(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time = date + " " + month + " " + year;
  return time;
}


function scanTime(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp);
  var hour = a.getHours();
  var min = a.getMinutes();
  var time = hour + ":" + min;
  return time;
}

const BorderLinearProgressHigh = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 7.5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 7.5,
    backgroundColor: "rgb(58, 170, 53)",
  },
}))(LinearProgress);

function ProgressStepper({ stage }) {
  const steps = getSteps();
  return (
    <Stepper alternativeLabel activeStep={stage} connector={<QontoConnector />} className="progress-box">
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel style={{ marginTop: 0 }} StepIconComponent={QontoStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}