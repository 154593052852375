import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { toast } from 'react-toastify';

export const GET_FILES_ISSUES = 'GET_FILES_ISSUES';
export const GET_FILES_ISSUES_LOADING = 'GET_FILES_ISSUES_LOADING';

export const GET_NEW_FILES_ISSUES = 'GET_NEW_FILES_ISSUES';
export const GET_NEW_FILES_ISSUES_LOADING = 'GET_NEW_FILES_ISSUES_LOADING';

export const GET_ARCHIVED_FILES_ISSUES = 'GET_ARCHIVED_FILES_ISSUES';
export const GET_ARCHIVED_FILES_ISSUES_LOADING = 'GET_ARCHIVED_FILES_ISSUES_LOADING';

export const GET_COMPLETED_FILES_ISSUES = 'GET_COMPLETED_FILES_ISSUES';
export const GET_COMPLETED_FILES_ISSUES_LOADING = 'GET_COMPLETED_FILES_ISSUES_LOADING';

export const GET_IGNORED_FILES_ISSUES = 'GET_IGNORED_FILES_ISSUES';
export const GET_IGNORED_FILES_ISSUES_LOADING = 'GET_IGNORED_FILES_ISSUES_LOADING';

export const GET_PDF_CHECKER = 'GET_PDF_CHECKER';
export const GET_PDF_CHECKER_LOADING = 'GET_PDF_CHECKER_LOADING';




export const getFilesIssues = (scanId) => async (dispatch) => {
  dispatch({
    type: GET_FILES_ISSUES_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    }

    const res = await axios.get(`${BASE_URL}/dashboard/files-issues-table`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_FILES_ISSUES,
        payload: res.data
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.' );
    }
    dispatch({
      type: GET_FILES_ISSUES_LOADING,
    });
  }
}
export const getNewFilesIssues = (scanId) => async (dispatch) => {
  dispatch({
    type: GET_NEW_FILES_ISSUES_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    }

    const res = await axios.get(`${BASE_URL}/dashboard/new-files-issues`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_NEW_FILES_ISSUES,
        payload: res.data
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.' );
    }
    dispatch({
      type: GET_NEW_FILES_ISSUES_LOADING,
    });
  }
}

export const getFilesArchivedIssues = (scanId) => async (dispatch) => {
  dispatch({
    type: GET_ARCHIVED_FILES_ISSUES_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    }

    const res = await axios.get(`${BASE_URL}/dashboard/archived-files-issues`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_ARCHIVED_FILES_ISSUES,
        payload: res.data
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.' );
    }
  }
}
export const unArchiveFilesLinks = (postData, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/unarchive-files-issues`, {
      ...postData
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getFilesArchivedIssues(scanId))
      dispatch(getFilesIssues(scanId))
    }
  } catch (err) {
    toast.error(err.response.data.message)
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.' );
    }
  }
}
export const archiveFilesLinks = (archiveOptions, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/archive-files-issues`, {
      ...archiveOptions
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getFilesIssues(scanId));
      dispatch(getFilesArchivedIssues(scanId));
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.' );
    }
    toast.error(err.response.data.message)
  }
}


export const getFilesIgnoredIssues = (scanId) => async (dispatch) => {
  dispatch({
    type: GET_IGNORED_FILES_ISSUES_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    }

    const res = await axios.get(`${BASE_URL}/dashboard/ignored-files-issues`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_IGNORED_FILES_ISSUES,
        payload: res.data
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.' );
    }
  }
}
export const ignoreFilesLinks = (issues, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/ignore-files-issues`, {
      ...issues
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getFilesIssues(scanId));
      dispatch(getFilesIgnoredIssues(scanId));
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.' );
    }
    toast.error(err.response.data.message)
  }
}
export const unIgnoreFilesLinks = (postData, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/unignore-files-issues`, {
      ...postData
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getFilesIgnoredIssues(scanId))
      dispatch(getFilesIssues(scanId))
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.' );
    }
    toast.error(err.response.data.message)
  }
}


export const getFilesCompletedIssues = (scanId) => async (dispatch) => {
  dispatch({
    type: GET_COMPLETED_FILES_ISSUES_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const params = {
      scanId,
    }

    const res = await axios.get(`${BASE_URL}/dashboard/completed-files-issues`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_COMPLETED_FILES_ISSUES,
        payload: res.data
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.' );
    }
  }
}
export const completeFilesLinks = (issues, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/complete-files-issues`, {
      ...issues
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getFilesIssues(scanId));
      dispatch(getFilesCompletedIssues(scanId));
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.' );
    }
    toast.error(err.response.data.message)
  }
}
export const unCompleteFilesLinks = (postData, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/uncomplete-files-issues`, {
      ...postData
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getFilesCompletedIssues(scanId))
      dispatch(getFilesIssues(scanId))
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.' );
    }
    toast.error(err.response.data.message)
  }
}

export const getPdfChecker = (scanId) => async (dispatch) => {
  dispatch({
    type: GET_PDF_CHECKER_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    }

    const res = await axios.get(`${BASE_URL}/dashboard/pdf-files-table`, {
      params,
      ...options
    });

    if (res.data) {

      const files = res.data.result.pdfLinks.map(link =>{
        return({
          ...link,
          text : link.text? "Present" : "Not Present"
        })
      })

      dispatch({
        type: GET_PDF_CHECKER,
        payload: {files:files,status:res.data.status}
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.' );
    }
    dispatch({
      type: GET_PDF_CHECKER_LOADING,
    });
  }
}