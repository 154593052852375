import { GET_NOTIFICATIONS, LOADING_NOTIFICATIONS, FILTER_NOTIFICATIONS, FILTER_NOTIFICATIONS_LOADING,RESET_NOTIFICATIONS } from '../actions/notificationActions';

const initialState = {
  notifications: {
    data: null,
    loading: false,
    filter_loading: false,
    count: 0,
    hasMoreData: false,
  }
};

 const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: { ...state.notifications, data: action.payload.data, count: action.payload.count, loading: false, hasMoreData: action.payload.hasMoreData }
      }
    case FILTER_NOTIFICATIONS:
      return {
        ...state,
        notifications: { ...state.notifications, count: state.notifications.count - 1, filter_loading: false }
      }
    case FILTER_NOTIFICATIONS_LOADING:
      return {
        ...state,
        notifications: { ...state.notifications, filter_loading: !state.notifications.filter_loading }
      }
    case LOADING_NOTIFICATIONS:
      return {
        ...state,
        notifications: { ...state.notifications, loading: true }
      }
    case RESET_NOTIFICATIONS:
      return {
        ...state,
        notifications: { ...initialState.notifications }
      }
    default:
      return state;
  }
};
export default notificationReducer;