import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Switch } from '@material-ui/core';
import { Box, Button, Card, CircularProgress, TextField, Typography } from '@material-ui/core';
import { useDispatch } from "react-redux";
import { addFreeDomain, getAllUnsubDomains } from "../../store/actions/adminScansManagementActions";
import { getAllAccounts, updateAccount } from "../../store/actions/adminScansManagementActions";
import { useHistory } from "react-router-dom";

export default function UserAccounts() {

	const [loadingAccounts, setLoadingAccounts] = React.useState(true);
	const [data, setData] = React.useState([]);

	const dispatch = useDispatch();
	const history = useHistory();

	function getDomains() {
		dispatch(getAllAccounts()).then(data => {
			if (data) {
				setLoadingAccounts(false);
				setData(data.result);
				console.log(data)
			} else {
				setLoadingAccounts(false);
			}
		}).catch(err => {
			setLoadingAccounts(false);
		});
	}

	React.useEffect(() => {
		getDomains();
	}, []);


	const handleAdminAction = (userId, enable) => {
		// dispatch(updateAccount({ userId, isAdmin: enable }));
		try {
			dispatch(updateAccount({ userId, isAdmin: enable }));
			// Redirect to another location if enable is false and the response is successful
			if (!enable) {
				history.push("/"); // Redirect to another location
				window.location.reload()
			}
		} catch (error) {
			console.error('Error updating admin status:', error);
		}
	};
	const handleAbracadabraAction = (userId, enable) => {
		try {
			dispatch(updateAccount({ userId, hasAbracadabra: enable }));
			if (!enable) {
				history.push("/"); // Redirect to another location
				window.location.reload()
			}
		} catch (error) {
			console.error('Error updating abracadabra status:', error);
		}
	};

	const handleToggle = (userId, field) => (event) => {
		const enable = event.target.checked;
		try {
			dispatch(updateAccount({ userId, [field]: enable }));
			if (!enable) {
				history.push("/"); // Redirect to another location
				window.location.reload()
			}
		} catch (error) {
			console.error(`Error updating ${field} status:`, error);
		}
	};

	return (
		<div style={{ overflowX: "auto" }}>
			<TableContainer component={Paper}>

				<Table aria-label="simple table" style={{ tableLayout: 'fixed' }}>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Verified</TableCell>
							<TableCell>Created At</TableCell>
							<TableCell>Actions</TableCell>
							<TableCell></TableCell>
						</TableRow>
					</TableHead>
					{!loadingAccounts ? (
						<TableBody>
							{data.length > 0 ? data.map((row, index) => (
								<TableRow key={index}>
									<TableCell component="th" scope="row">
										{row.name}
									</TableCell>
									<TableCell style={{ wordBreak: 'break-all' }}>{row.email}</TableCell>
									<TableCell style={{ wordBreak: 'break-all' }}>{row.email_verified.toString()}</TableCell>
									<TableCell style={{ wordBreak: 'break-all' }}>{new Date(row.createdAt).toDateString()}</TableCell>
									<TableCell style={{ whiteSpace: "nowrap" }}>
										<Switch
											checked={row.allowAdminPanel}
											onChange={handleToggle(row._id, 'isAdmin')}
											name="adminSwitch"
											color="primary"
										/> {row.allowAdminPanel ? "Disable Admin" : "Enable Admin"}
										  &nbsp;
										  &nbsp;
										  &nbsp;
										  &nbsp;
										<Switch
											checked={row.allowAbrakadabraBox}
											onChange={handleToggle(row._id, 'hasAbracadabra')}
											name="abracadabraSwitch"
											color="primary"
										/> {row.allowAbrakadabraBox ? "Disable Abracadabra Box" : "Enable Abracadabra Box"}
									</TableCell>
									<TableCell></TableCell>
								</TableRow>
							)) :
								<TableRow><TableCell colSpan={5}>no records found</TableCell></TableRow>
							}
						</TableBody>
					) :
						<TableBody>
							<TableRow><TableCell align='center' colSpan={5}>
								<CircularProgress />
							</TableCell></TableRow>
						</TableBody>
					}
				</Table>

			</TableContainer>
		</div>
	);
}