import React from "react";
import Box from '@material-ui/core/Box';

export default function InternalServerError() {
      return (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100vh" p={4}>
                  <article>
                        <h1>We&rsquo;ll be back soon!</h1>
                        <div>
                              <p>Please accept our apologies for the inconvenience. At the moment, we are conducting some maintenance.</p>
                              <p>&mdash; The AllyRight Team </p>
                              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    Contact Us at &nbsp;<a href="mailto:support@allyright.com">support@allyright.com</a>
                              </div>
                        </div>
                  </article>
            </Box>
      );

}