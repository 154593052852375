const criteriaList = [
  "1.1.1",
  "1.2.1",
  "1.2.2",
  "1.2.3",
  "1.3.1",
  "1.3.1.A",
  "1.3.2",
  "1.3.3",
  "1.4.1",
  "1.4.2",
  "2.1.1",
  "2.1.2",
  "2.1.4",
  "2.2.1",
  "2.2.2",
  "2.3.1",
  "2.4.1",
  "2.4.2",
  "2.4.3",
  "2.4.4",
  "2.5.1",
  "2.5.2",
  "2.5.3",
  "2.5.4",
  "3.1.1",
  "3.2.1",
  "3.2.2",
  "3.3.1",
  "3.3.2",
  "4.1.1",
  "4.1.2",
  "1.2.4",
  "1.2.5",
  "1.3.4",
  "1.3.5",
  "1.4.3",
  "1.4.3.F24",
  "1.4.3.Contrast",
  "1.4.4",
  "1.4.5",
  "1.4.10",
  "1.4.11",
  "1.4.12",
  "1.4.13",
  "2.4.5",
  "2.4.6",
  "2.4.7",
  "3.1.2",
  "3.2.3",
  "3.2.4",
  "3.3.3",
  "3.3.4",
  "4.1.3",
  "1.2.6",
  "1.2.7",
  "1.2.8",
  "1.2.9",
  "1.3.1.AAA",
  "1.3.6",
  "1.4.6",
  "1.4.7",
  "1.4.8",
  "1.4.9",
  "2.2.3",
  "2.2.4",
  "2.2.5",
  "2.2.6",
  "2.3.2",
  "2.3.3",
  "2.4.8",
  "2.4.9",
  "2.5.5",
  "2.5.6",
  "3.1.3",
  "3.1.4",
  "3.1.5",
  "3.1.6",
  "3.2.5",
  "3.3.5",
  "3.3.6"
]

export default criteriaList