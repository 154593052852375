import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Auth0Service from "../services/auth0Service/authentikService";
import BasicScan from "./BasicScan";
import CustomCardContainer from "../components/commons/CustomCardContainer";
import {
  getDomainSettings,
  setDomainSettings,
} from "../store/actions/customScanPageListActions";
import Tooltip from "../components/commons/Tooltip";
import addDomainStyles from "../Styles/jss/addDomainStyles";
import Profilers from "./CustomScanComponents/Profilers";
import PayWall from "../components/commons/PayWall";
import loaderwave from "../assets/loader.svg";
import {  DomainModulesAdmin } from "../../src/store/actions/adminScansManagementActions"

const useStyles = makeStyles(addDomainStyles);

export default function DomainSettings() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const selectedDomainId = useSelector(
    (state) => state.dashboard.selectedDomainId
  );
  const isPackagePurchased = useSelector(
    (state) => state.dashboard.isDomainPackagePurchased
  );
  const [modules, setModules] = React.useState({ accessibility: false, performance: false, webPrivacy: false, cybersec: false, languagAndGrammer: false });
  const [loading, setLoading] = React.useState(false);
  const [scanValues, setScanValues] = React.useState({
    domainId: "",
    links: [],
    resolutions: [],
    scanOptions: {
      // browsers: [],
      resolutions: [],
      devices: [],
    },
    modules: {
      accessibility: false,
      cyberSecurity: false,
      seo: false,
      performance: false,
    },
    sitemapOptions: {
      fastCrawl: false,
      existingSitemap: false,
      existingSitemapUri: false,
      existingSitemapFile: false,
      timeoutPerDomain: 60000,
      dirLevel: 3,
    },
  });

  React.useEffect(() => {
    if (selectedDomainId) {
      getModules(selectedDomainId);
    }
  }, [selectedDomainId]);

  function getModules(selectedDomainId) {
    console.log("Fetching modules for domain:", selectedDomainId);
    dispatch(DomainModulesAdmin({ selectedDomainId }))
      .then((data) => {
        if (data) {
          console.log("Data from getModules:", data.result);
  
          // Define the specific keys to include
          const keysToInclude = ["accessibility", "cybersec", "performance", "seo", "languagAndGrammer"];
  
          // Filter and map only the modules with true values and specific keys
          const filteredModules = Object.fromEntries(
            Object.entries(data.result)
              .filter(([key, value]) => value === true && keysToInclude.includes(key))
              .map(([key, value]) => {
                // If key is 'cybersec', rename it to 'cybersecurity'
                if (key === "cybersec") {
                  key = "cybersecurity";
                }
                return [key, value];
              })
          );
  
          // Set the filtered modules
          setModules(filteredModules);
        }
      })
      .catch((error) => {
        console.error("Failed to fetch modules:", error);
      });
  }
  function handleResolutions(event, item) {
    if (event.target.checked) {
      setScanValues({
        ...scanValues,
        scanOptions: {
          ...scanValues.scanOptions,
          resolutions: [...scanValues.scanOptions.resolutions, item.resolution],
        },
      });
    } else {
      const filteredResolutions = scanValues.scanOptions.resolutions.filter(
        (resolution) => resolution !== item.resolution
      );
      setScanValues({
        ...scanValues,
        scanOptions: {
          ...scanValues.scanOptions,
          resolutions: filteredResolutions,
        },
      });
    }
  }

  const deviceDetermination = (value) => {
    if (
      value === "1920x1080" ||
      value === "3840 x 2160" ||
      value === "1536x864" ||
      value === "1280x720" ||
      value === "2732x2048" ||
      value === "1280 x 1024" ||
      value === "1366x768" ||
      value === "1440x900" ||
      value === "1600x900"
    ) {
      return `${value}(Desktop)`;
    } else {
      return `${value} (Tablet)`;
    }
  };

  // function handleDevices(event, item) {
  //   if (event.target.checked) {
  //     setScanValues({
  //       ...scanValues,
  //       scanOptions: { ...scanValues.scanOptions, devices: [...scanValues.scanOptions.devices, item] },
  //     });
  //   } else {
  //     const filteredDevices = scanValues.scanOptions.devices.filter(
  //       (device) => device !== item
  //     );
  //     setScanValues({
  //       ...scanValues,
  //       scanOptions: { ...scanValues.scanOptions, devices: filteredDevices },
  //     });
  //   }
  // }

  function handleBrowsers(event, item) {
    if (event.target.checked) {
      setScanValues({
        ...scanValues,
        scanOptions: {
          ...scanValues.scanOptions,
          browsers: [...scanValues.scanOptions.browsers, item],
        },
      });
    } else {
      const filteredBrowsers = scanValues.scanOptions.browsers.filter(
        (browser) => browser !== item
      );
      setScanValues({
        ...scanValues,
        scanOptions: { ...scanValues.scanOptions, browsers: filteredBrowsers },
      });
    }
  }

  const handleFastCrawl = (event) => {
    setScanValues({
      ...scanValues,
      sitemapOptions: {
        ...scanValues.sitemapOptions,
        fastCrawl: event.target.checked,
      },
    });
  };

  const handleDomainTimeout = (event) => {
    setScanValues({
      ...scanValues,
      sitemapOptions: {
        ...scanValues.sitemapOptions,
        timeoutPerDomain: event.target.value,
      },
    });
  };

  function handleDirLevel(event) {
    setScanValues({
      ...scanValues,
      sitemapOptions: {
        ...scanValues.sitemapOptions,
        dirLevel: event.target.value,
      },
    });
  }

  React.useEffect(() => {
    if (selectedDomainId) {
      dispatch(getDomainSettings(selectedDomainId)).then((data) => {
        if (data) {
          setScanValues({
            ...data.settings,
            modules: modules
          });
        }
      });
    }
    // eslint-disable-next-line
  }, [selectedDomainId, modules]);

  function setSettings() {
    setLoading(true);
    dispatch(setDomainSettings(scanValues, selectedDomainId)).then((data) => {
      if (data) {
        toast.success("Settings Saved");
        setLoading(false);
      }
    });
  }

  const [delayed, setDelayed] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setDelayed(false);
    }, 1500);
  }, []);

  if (!Auth0Service.isAuthenticated()) {
    return (window.location.href = "/authenticate");
  } else {
    return (
      <>
        {!delayed ? (
          <React.Fragment>
            {isPackagePurchased ? (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6}>
                    <CustomCardContainer title={"Sitemap Options"}>
                      <Box
                        px={1}
                        display="flex"
                        alignItems="center"
                        flexWrap="wrap"
                      >
                        <Tooltip title="For sites that aren't SPA or PWA, fast crawling is available.">
                          <FormControlLabel
                            style={{ marginTop: 15 }}
                            control={
                              <Checkbox
                                size="small"
                                disabled={true}
                                checked={!scanValues.sitemapOptions.fastCrawl}
                                onChange={(event) => handleFastCrawl(event)}
                                color="primary"
                                name="fast-crawl"
                              />
                            }
                            label="Fast Crawl"
                          />
                        </Tooltip>
                        <div className={classes.fieldsWrapper}>
                          <Tooltip title="Connection timeout - is a time period within which a connection between a client and a server must be established. Suppose that you navigate your browser (client) to some website (server). What happens is that your browser starts to listen for a response message from that server but this response may never arrive for various reasons (e.g. server is offline). So if there is still no response from the server after X seconds, your browser will 'give up' on waiting, otherwise it might get stuck waiting for eternity.">
                            <TextField
                              className={classes.sitemapOptionsTextFields}
                              value={scanValues.sitemapOptions.timeoutPerDomain}
                              defaultValue={
                                scanValues.sitemapOptions.timeoutPerDomain
                              }
                              id="time-per-domain"
                              label="Time out per Domain"
                              type="number"
                              inputProps={{ min: 40000, required: true }}
                              onChange={(event) => handleDomainTimeout(event)}
                            />
                          </Tooltip>
                          <Tooltip title="Each web page has a URL directory where the URLs are placed hierarchically. The individual files of the website are created in the directories. Individual files could be HTML files, images, videos, or PDF documents, for example.">
                            <TextField
                              className={classes.sitemapOptionsTextFields}
                              value={scanValues.sitemapOptions.dirLevel}
                              defaultvalue={scanValues.sitemapOptions.dirLevel}
                              id="directory-level"
                              label="Directory Level"
                              type="number"
                              inputProps={{ min: 1, required: true }}
                              onChange={(event) => handleDirLevel(event)}
                            />
                          </Tooltip>
                        </div>
                      </Box>
                    </CustomCardContainer>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <CustomCardContainer title={"Select Resolutions"}>
                      <Grid container>
                        {scanValues.resolutions.map((item, index) => {
                          const checked =
                            scanValues.scanOptions.resolutions.filter(
                              (value) => value === item.resolution
                            ).length > 0;

                          return (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              lg={6}
                              xl={6}
                              key={index}
                              style={{}}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    defaultChecked={checked}
                                    name={item.resolution}
                                    color="primary"
                                    onChange={(event) =>
                                      handleResolutions(event, item)
                                    }
                                  />
                                }
                                label={deviceDetermination(item.resolution)}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </CustomCardContainer>
                  </Grid>
                  {/* <Grid item xs={12} lg={6}>
                    <CustomCardContainer title={"Select Browsers"}>
                      <Grid container>
                        {scanValues.scanOptions.browsers.map((item, index) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              lg={4}
                              xl={2}
                              key={index}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled
                                    defaultChecked={true}
                                    name={item}
                                    color="primary"
                                    onChange={(event) =>
                                      handleBrowsers(event, item)
                                    }
                                  />
                                }
                                label={item}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </CustomCardContainer>
                  </Grid> */}
                </Grid>
                <Profilers
                  setScanValues={setScanValues}
                  scanValues={scanValues}
                />
                {/* <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  my={3}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={
                      scanValues.scanOptions.resolutions.length < 1 ||
                      loading ||
                      Object.values(scanValues.profilers).indexOf(true) === -1
                    }
                    onClick={() => setSettings()}
                  >
                    Set Settings{" "}
                    {loading && (
                      <CircularProgress size={20} style={{ marginLeft: 10 }} />
                    )}
                  </Button>
                </Box> */}
                <BasicScan options={scanValues} />
              </>
            ) : (
              <PayWall />
            )}
          </React.Fragment>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            tabIndex="0"
          >
            <img src={loaderwave} alt="loader" />
          </Box>
        )}
      </>
    );
  }
}
