import {
  GET_PAGESPEED_LOADING,
  GET_PAGESPEED,

  HTML_SUMMARY,
  HTML_SUMMARY_LOADING,

  CSS_SUMMARY,
  CSS_SUMMARY_LOADING,

  HTML_HISTORY,
  HTML_HISTORY_LOADING,

  CSS_HISTORY,
  CSS_HISTORY_LOADING,

  PERFORMANCE_VISUALIZATION,
  PERFORMANCE_VISUALIZATION_LOADING,

  PERFORMANCE_ANALYTICS_LOADING,
  PERFORMANCE_ANALYTICS,

  PERFORMANCE_STRUCTURE_LOADING,
  PERFORMANCE_STRUCTURE,

  PERFORMANCE_WATERFALL,
  PERFORMANCE_WATERFALL_LOADING
} from '../actions/performanceActions';

const initialState = {

  pagespeed_data: null,
  pagespeed_loading: false,
  pagespeed_status: "pending",

  html_data: null,
  html_loading: false,
  html_status: "pending",

  html_history_graph: null,
  html_history_loading: false,
  html_history_status: null,

  css_data: null,
  css_loading: false,
  css_status: "pending",

  css_history_graph: null,
  css_history_loading: false,
  css_history_status: null,

  performance_visualization: null,
  performance_visualization_loading: false,
  performance_visualization_status: "pending",

  performance_analytics: null,
  performance_analytics_loading: false,
  performance_analytics_status: "pending",

  performance_structure: null,
  performance_structure_loading: false,
  performance_structure_status: "pending",

  performance_waterfall: null,
  performance_waterfall_loading: false,
  performance_waterfall_status: "pending",

};

const performanceReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_PAGESPEED:
      return {
        ...state,
        pagespeed_data: action.payload.data,
        pagespeed_status: action.payload.status,
        pagespeed_loading: false
      }
    case GET_PAGESPEED_LOADING:
      return {
        ...state,
        pagespeed_loading: true
      }

    case HTML_SUMMARY:
      return {
        ...state,
        html_data: action.payload.result,
        html_status: action.payload.status,
        html_loading: false
      }
    case HTML_SUMMARY_LOADING:
      return {
        ...state,
        html_loading: true
      }

    case HTML_HISTORY:
      return {
        ...state,
        html_history_graph: action.payload.result.graph,
        html_history_status: action.payload.status,
        html_history_loading: false
      }
    case HTML_HISTORY_LOADING:
      return {
        ...state,
        html_history_loading: true
      }

    case CSS_SUMMARY:
      return {
        ...state,
        css_data: action.payload.result,
        css_status: action.payload.status,
        css_loading: false
      }
    case CSS_SUMMARY_LOADING:
      return {
        ...state,
        css_loading: true
      }

    case CSS_HISTORY:
      return {
        ...state,
        css_history_graph: action.payload.result.graph,
        css_history_status: action.payload.status,
        css_history_loading: false
      }
    case CSS_HISTORY_LOADING:
      return {
        ...state,
        css_history_loading: true
      }

    case PERFORMANCE_VISUALIZATION:
      return {
        ...state,
        performance_visualization: action.payload.result,
        performance_visualization_status: action.payload.status,
        performance_visualization_loading: false
      }
    case PERFORMANCE_VISUALIZATION_LOADING:
      return {
        ...state,
        performance_visualization_loading: true
      }

    case PERFORMANCE_ANALYTICS:
      return {
        ...state,
        performance_analytics: action.payload.result,
        performance_analytics_status: action.payload.status,
        performance_analytics_loading: false
      }
    case PERFORMANCE_ANALYTICS_LOADING:
      return {
        ...state,
        performance_analytics_loading: true
      }

    case PERFORMANCE_STRUCTURE:
      return {
        ...state,
        performance_structure: action.payload.result,
        performance_structure_status: action.payload.status,
        performance_structure_loading: false
      }
    case PERFORMANCE_STRUCTURE_LOADING:
      return {
        ...state,
        performance_structure_loading: true
      }

    case PERFORMANCE_WATERFALL:
      return {
        ...state,
        performance_waterfall: action.payload.result,
        performance_waterfall_status: action.payload.status,
        performance_waterfall_loading: false
      }
    case PERFORMANCE_WATERFALL_LOADING:
      return {
        ...state,
        performance_waterfall_loading: true
      }
    default:
      return state;
  }
};

export default performanceReducer; 