import React from "react";
import auth0Service from "../services/auth0Service/authentikService";
import { BASE_URL_OLD_DASHBOARD, BASE_URL } from "../source/constants";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";
import NoDomain from "../components/commons/NoDomain";

import oldDashUrl from "../utils/oldDashUrl";
export default function Chat() {
  const { selectedDomainId } = useSelector((state) => state.dashboard);
  const domains = useSelector((state) => state.dashboard.domains);
  const isDomainPurchased = useSelector(
    (state) => state.dashboard.isDomainPackagePurchased
  );

  const [domainId, setDomainId] = React.useState("");

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  function checkDomain() {
    const domain = query.get("domainId");
    let domainID = "";
    if (domain) {
      const domaino = domains.filter((domain) => domain.domain === domain);
      if (domaino.length > 0) {
        domainID = domaino[0].domainId;
        window.history.replaceState(null, null, `?domainId=${domainID}`);
      } else {
        domainID = selectedDomainId;
        window.history.replaceState(null, null, `?domainId=${domainID}`);
      }
    } else {
      domainID = selectedDomainId;

      window.history.replaceState(null, null, `?domainId=${domainID}`);
    }

    return domainID;
  }
  React.useEffect(() => {
    if (domains && domains.length > 0 && selectedDomainId) {
      let domId = checkDomain();
      setDomainId(domId);
    }
    // eslint-disable-next-line
  }, [domains, selectedDomainId]);

  function setIframe() {
    return {
      __html: iframe,
    };
  }

  // ${oldDashUrl()}

  const hostname = window.location.hostname;
  let checkenvironment = 'unknown';
  if (hostname.includes('dashboard.allyright.com')) {
    checkenvironment = 'production';
  } else if (hostname.includes('demo.allyright.com')) {
    checkenvironment = 'demo';
  } else if (hostname.includes('localhost')) {
    checkenvironment = 'local';
  }
  console.log("Environment" + checkenvironment)


  let iframe = ''
  const token = auth0Service.getAccessToken();
  if(checkenvironment === "local") {
    iframe = `<iframe src="${process.env.REACT_APP_BASE_URL_OLD_DASHBOARD_DEV}/callback#access_token=${token}&route=/apps/chat&burl=${encodeURI(
      BASE_URL
    )}&domainId=${domainId}&isPackagePurchased=true" width="100%" height="100%"></iframe>`;
  }

  if(checkenvironment === "demo") {
    iframe = `<iframe src="${process.env.REACT_APP_BASE_URL_OLD_DASHBOARD_DEV}/callback#access_token=${token}&route=/apps/chat&burl=${encodeURI(
      BASE_URL
    )}&domainId=${domainId}&isPackagePurchased=true" width="100%" height="100%"></iframe>`;
  }

  if(checkenvironment === "production") {
    iframe = `<iframe src="${process.env.REACT_APP_BASE_URL_OLD_DASHBOARD_PROD}/callback#access_token=${token}&route=/apps/chat&burl=${encodeURI(
      BASE_URL
    )}&domainId=${domainId}&isPackagePurchased=true" width="100%" height="100%"></iframe>`;
  }




  if (!isDomainPurchased) {
    return (window.location.pathname = "/dashboard");
  } else {
    return (
      <>
        <Box
          display='flex'
          height='100%'
          alignItems='center'
          justifyContent='center'
          width='100%'
        >
          {auth0Service.isAuthenticated() && domains.length > 0 ? (
            <Box m={-2} style={{ height: 'calc(100vh - 60px' }} width='100%'>
              {domainId && (
                <div
                  dangerouslySetInnerHTML={setIframe()}
                  style={{ height: "100%", width: "100%" }}
                ></div>
              )}
            </Box>
          ) : (
            <NoDomain text='Please add scan to see the results.' />
          )}
        </Box>
      </>
    );
  }
}
