import React, { useCallback } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Box } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";

import addDomainStyles from "../../Styles/jss/addDomainStyles";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import XMLParser from 'react-xml-parser';
import Tooltip from '../../components/commons/Tooltip';
import {
  getDomainEvaluation,
  generateSitemap
} from "../../store/actions/customScanPageListActions";

export default function SitemapOptions({ setActiveStep }) {

  const scanId = useSelector((state) => state.miscellaneous.selectedScanManagerId);

  const dispatch = useDispatch();

  const useStyles = makeStyles(addDomainStyles);
  const classes = useStyles();
  const [file, setFile] = React.useState(null);

  const [options, setOptions] = React.useState({
    scanId: "",
    sitemapOptions: {
      fastCrawl: false,
      existingSitemap: false,
      existingSitemapUri: false,
      existingSitemapFile: false,
      timeoutPerDomain: 60000,
      dirLevel: 3
    },
    existingSitemapFile: [],
    existingSitemapUrl: ""
  });


  React.useEffect(() => {
    if (scanId) dispatch(getDomainEvaluation(scanId)).then((data) => {
      if (data) {
        setOptions({ ...options, scanId: scanId, sitemapOptions: { ...options.sitemapOptions, fastCrawl: data.evaluation.pwa } });
      }
    });
    // eslint-disable-next-line
  }, [scanId]);


  const handleFastCrawl = (event) => {
    setOptions({ ...options, sitemapOptions: { ...options.sitemapOptions, fastCrawl: event.target.checked } });
  }

  const handleDomainTimeout = (event) => {
    setOptions({ ...options, sitemapOptions: { ...options.sitemapOptions, timeoutPerDomain: event.target.value } });
  }

  function handleDirLevel(event) {
    setOptions({ ...options, sitemapOptions: { ...options.sitemapOptions, dirLevel: event.target.value } });
  }

  function handleSitemapUrl(event) {
    setOptions({ ...options, existingSitemapUrl: event.target.value });
  }

  const onDrop = useCallback(acceptedFiles => {
    const reader = new FileReader();
    reader.readAsText(acceptedFiles[0]);
    reader.onload = () => {
      const binaryStr = reader.result;
      var xml = new XMLParser().parseFromString(binaryStr);
      if (xml.children[0].children.length > 0) {
        setFile(acceptedFiles[0]);
        const urls = xml.children[0].children.map(child => {
          return child.children[0].value;
        })
        setOptions({ ...options, existingSitemapFile: urls, sitemapOptions: { ...options.sitemapOptions, existingSitemapFile: true } })
        toast.success("Sitemap Uploaded");
      }
      else {
        toast.error("invalid sitemap XML");
      }
    };
    // eslint-disable-next-line
  }, [])

  function handleRemoveFile() {
    setFile(null);
    setOptions({ ...options, existingSitemapFile: [], sitemapOptions: { ...options.sitemapOptions, existingSitemapFile: false } })
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      ".xml",
    minSize: 0,
    maxSize: 5242880,
  })

  function handleSubmit() {
    dispatch(generateSitemap(options)).then(() => {
      setActiveStep(2);
    })
  }

  return (
    <>
      <Box px={4} pt={2} pb={4}>
        <Box component="form" display="flex" flexDirection="column" alignItems="center" onSubmit={(event) => event.preventDefault()}>
          <Box px={1} display="flex" alignItems="center" flexWrap="wrap">
            <Tooltip title="For sites that aren't SPA or PWA, fast crawling is available.">
              <FormControlLabel
                style={{ marginTop: 15 }}
                control={<Checkbox size="small" disabled={true} checked={!options.sitemapOptions.fastCrawl} onChange={(event) => handleFastCrawl(event)} color="primary" name="fast-crawl" />}
                label="Fast Crawl"
              />
            </Tooltip>
            <div className={classes.fieldsWrapper} >
              <Tooltip title="Connection timeout - is a time period within which a connection between a client and a server must be established. Suppose that you navigate your browser (client) to some website (server). What happens is that your browser starts to listen for a response message from that server but this response may never arrive for various reasons (e.g. server is offline). So if there is still no response from the server after X seconds, your browser will 'give up' on waiting, otherwise it might get stuck waiting for eternity.">
                <TextField
                  className={classes.sitemapOptionsTextFields}
                  value={options.sitemapOptions.timeoutPerDomain}
                  id="time-per-domain"
                  label="Time out per Domain"
                  type="number"
                  inputProps={{ min: 40000 }}
                  onChange={(event) => handleDomainTimeout(event)}
                />
              </Tooltip>
              <Tooltip title="Each web page has a URL directory where the URLs are placed hierarchically. The individual files of the website are created in the directories. Individual files could be HTML files, images, videos, or PDF documents, for example.">
                <TextField
                  className={classes.sitemapOptionsTextFields}
                  value={options.sitemapOptions.dirLevel}
                  id="directory-level"
                  label="Directory Level"
                  type="number"
                  inputProps={{ min: 1 }}
                  onChange={(event) => handleDirLevel(event)}
                />
              </Tooltip>
            </div>
          </Box>
          <Box px={1} >
            <Box display="flex" alignItems="center" flexWrap="wrap" >
              <fieldset className={classes.fieldsetWrapper}>
                <legend>Upload Sitemap (*.xml)</legend>
                {!file ? (
                  <div {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <Box
                      display="flex"
                      alignItems="center"
                    >
                      <CloudUploadOutlinedIcon
                        style={{
                          fontSize: "30px",
                          color: "rgb(31, 58, 147)",
                          marginRight: 10
                        }}
                      />
                      <Typography>Upload Here</Typography>
                    </Box>
                  </div>
                )
                  : (
                    <>
                      <Box display="flex" alignItems="center" justifyContent="center"> {file.name} <HighlightOffIcon className={classes.removeFileIcon} onClick={() => handleRemoveFile()} /></Box>
                    </>
                  )}
              </fieldset>
              <Box mx={2}>
                <Typography>OR</Typography>
              </Box>
              <fieldset className={classes.fieldsetWrapper}>
                <legend>Enter Sitemap URL</legend>
                <TextField
                  value={options.existingSitemapUrl}
                  disabled={options.sitemapOptions.existingSitemapFile}
                  className={classes.sitemapUrlField}
                  onChange={(event) => handleSitemapUrl(event)}
                  id="sitemap-url"
                  type="text"
                />
              </fieldset>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box my={2} display="flex" justifyContent="flex-end"><Button onClick={() => handleSubmit()} color="primary" variant="contained" style={{ borderRadius: 20 }} >Generate Sitemap</Button></Box>
    </>
  )
}