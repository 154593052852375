import React from "react";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Lottie from 'react-lottie';
import animationData from "../assets/lottiefiles/maintenance.json";
import { grey } from "@material-ui/core/colors";

export default function Maintenance() {
      const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
            }
      };

      return (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%">
                  <div className="lotties">
                        <Lottie options={defaultOptions}
                              height={300}
                              width={300}
                              isStopped={false}
                              isPaused={false}
                        />
                  </div>
                  <Box color={grey[600]} p={2}> <Typography variant="h6">Sorry, Our App is Under Maintenance</Typography></Box>
            </Box>
      );

}