import React from "react";
import { useTheme, styled } from "@material-ui/styles";
import {
  Drawer,
  List,
  ListItem,
  makeStyles,
  Divider,
  Box,
  Typography,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  page: {
    width: "calc(100% - 300px)",
    padding: "10px",
  },
  btn: {
    border: "2px solid black",
    borderStyle: "dashed",
    justifyContent: "center",
    margin: "auto",
  },

  iconBtn: {
    marginLeft: "10px",
    border: "2px solid gray",
    borderRadius: "0px",
    padding: "5px",
    width: "60px",
  },
  drawer: {
    width: 280,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 280,
      boxSizing: "border-box",
      height: "calc(100% - 64px)",
      top: 64,
      overflowY: "auto",

      [theme.breakpoints.down("sm")]: {
        top: 100,
      },
    },
  },

  barhaed: {
    display: "flex",
    justifyContent: "space-between",
  },
  btnf: {
    fontSize: " 14px",
  },
  chips: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    alignItems: "center",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: "10px -8px",
  },
}));

const SideList = (list) => {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar

    justifyContent: "flex-end",
  }));

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Drawer
        variant="persistent"
        anchor={"right"}
        open={open}
        // onClose={toggleDrawer(anchor, false)}
        className={classes.drawer}
      >
        <List>
          {list.list.length > 0 &&
            list.list.map((lst, index) => (
              <>
                <ListItem key={index} >
                  <h5 style={{ fontSize: 16, margin: 0 }}>{lst.label}</h5>
                </ListItem>
                <ListItem>{lst.data}</ListItem>
                <Divider />
              </>
            ))}
        </List>
      </Drawer>
    </>
  );
};

export default SideList;
