import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

export default function ArchiveDialog({ open, onClose, archive ,title1,title2}) {

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    archive();
  };


  return (
    <Dialog
      maxWidth="md"

      onClose={handleCancel}
      aria-labelledby="confirmation-dialog-title"
      open={open}
    >
      <>
        <DialogTitle id="confirm-purchase">Confirm {title1}</DialogTitle>
        <DialogContent dividers>
          You will be able to {title2} the exploit as well.
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleCancel()} color="primary">
            close
          </Button>
          <ProceedButton onClick={() => handleOk()} variant="contained">
            Proceed
          </ProceedButton>
        </DialogActions>
      </>
    </Dialog>
  );
}


const ProceedButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#1f3a93",
    color: "white",
    borderRadius: "20px",
    '&:hover': {
      backgroundColor: '#1f3a93',
      opacity: 0.9
    },
  },
}))(Button);

const CancelButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#E2211E",
    color: "white",
    borderRadius: "20px",
    '&:hover': {
      backgroundColor: '#E2211E',
      opacity: 0.9
    },
  },
}))(Button);

