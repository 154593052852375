import React from "react";
import { useSelector, useDispatch } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import headerStyles from "../../Styles/jss/headerStyles";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getSingleScanProgress } from "../../store/actions/onBoardingActions";
import ListSubheader from "@material-ui/core/ListSubheader";
import { setScanData } from "../../store/actions/miscellaneousActions";
import { dateFormatter } from "../../utils/dateFormatter";
import Switch from "../Switch/SwitchButton";
import { Alert } from "@material-ui/lab";
import { updateDomainLinks } from "../../store/actions/adminScansManagementActions"
import { Button, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(headerStyles);

export default function ScanSelector() {

  const [selectedScan, setSelectedScan] = React.useState("");
  const dispatch = useDispatch();
  const [scanStatus, setScanStatus] = React.useState(null);
  const [checkScanStatus, setCheckScanStatus] = React.useState(null);
  const [scan, setScan] = React.useState(null);
  const [demo, setDemo] = React.useState(false);
  const [environment, setEnvironment] = React.useState("production");
  const [numericValue, setNumericValue] = React.useState(1000000);


  const selectedScanId2 = useSelector((state) => state.miscellaneous.selectedScanId);
  const dom = useSelector((state) => state.miscellaneous);



  const domains = useSelector((state) => state.dashboard.domains);
  const selectedDomain = useSelector((state) => state.dashboard.selectedDomain);
  const scanProgress = useSelector((state) => state.onBoardDashboard.scanProgress);
  const scans = useSelector((state) => state.miscellaneous.scans);

  const classes = useStyles({ scanProgress });
  React.useEffect(() => {
    if (scanStatus && scanStatus === "pending" && selectedScanId2) {
      const interval = setInterval(() => {
        dispatch(getSingleScanProgress(selectedScanId2)).then((data) => {
          if (data) {
            setScanStatus(data.status);
          }
        });
      }, 5000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [scanStatus, selectedScanId2]);

  React.useEffect(() => {
    if (selectedScanId2) {
      setSelectedScan(selectedScanId2);
    }
  }, [selectedScanId2, scans]);

  function handleScanChange(event) {
    if (event.target.value !== undefined) {
      const domain = domains.filter(
        (item) => item.domain === selectedDomain
      )[0];
      var scan = domain.scans.filter(
        (item) => item.scanId === event.target.value
      )[0];
      if (scan.status === "pending" && domain.scans.length > 0) {
        scan = domain.scans
          .filter((i) => i.status === "completed")
          .sort(function (a, b) {
            return b.dateNow - a.dateNow;
          })[0];
      }
      dispatch(
        setScanData(
          domain.domain,
          scan.scanId,
          scan.scanId,
          domain.scanManagerId
        )
      );
      window.history.replaceState(
        null,
        null,
        `?domain=${selectedDomain}&scanId=${scan.scanId}`
      );
      dispatch(getSingleScanProgress(scan.scanId)).then((data) => {
        if (data) {
          setScanStatus(data.status);
        }
      });
      setSelectedScan(scan.scanId);
    }
  }

  const makeItems = (scans) => {
    const items = [];
    var i = 0;
    for (let scan of scans) {
      items.push(
        <ListSubheader key={scan.dateNow} disabled={true}>
          <span>{i + 1}:</span>
          &nbsp; {dateFormatter(new Date(scan.dateNow))}
        </ListSubheader>
      );
      for (let state of scan.scans) {
        items.push(
          <MenuItem
            key={state.scanId}
            value={state.scanId}
            name={state.resolution}
          >
            {state.device} ({state.resolution})
          </MenuItem>
        );
      }
      i++;
    }
    return items;
  };

  React.useEffect(() => {
    if (domains.length > 0) {
      if (dom && selectedScanId2) {
        console.log({ selectedDomain, selectedScanId2 })
        const domain = domains.filter(
          (item) => item.domain === dom.selectedDomain
        )[0];
        console.log(domain)
        var scan = domain.scans.filter(
          (item) => item.scanId === selectedScanId2
        )[0];
        setScan(scan);
        setDemo(scan.demoScan);
        setCheckScanStatus(scan.status);
        setEnvironment(scan.environment);
        // console.log(scan.status)
      }
    }
  }, [domains, selectedScanId2, dom])



  const handleUpdate = () => {
    // This function will be called when the submit button is clicked
    // You can use the numericValue state here to perform any action with the entered numeric value
    let changeProd = false
    dispatch(updateDomainLinks(selectedDomain, numericValue, changeProd));


    // Add your logic here to handle the submitted numeric value
  }
  return (
    <>
      {scans.length > 0 ? (
        <Box py={1}>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display='flex' alignItems='center' mb={1}>
              <FormControl
                disabled={scans.length < 0}
                className={classes.formControlScan}
                variant="outlined"
                data-tut="Select_Scan"
                tabIndex="0"
                aria-label="dashboard"
              >
                {selectedScan && (
                  <Select
                    className={classes.selectScan}
                    value={selectedScan}
                    onChange={(event) => handleScanChange(event)}
                    displayEmpty={false}
                    disabled={!selectedDomain}
                    IconComponent={(props) => <ExpandMoreIcon {...props} />}
                  >
                    {selectedDomain && makeItems(scans)}
                  </Select>
                )}

              </FormControl>
              <Switch />
            </Box>
            <div style={{ fontSize: 20, textAlign: "right" }}>
              Scan Status :{" "}
              {scanProgress && (
                <span className={classes.scanProgress}>
                  {" "}
                  {scanProgress.status}{" "}
                </span>
              )}
            </div>
          </Box>
          {/* <Alert severity="info">
            {scan && <span style={{ fontSize: 17}}>We are only scanning up to <b>{scan?.crawledLinks}</b> links. </span>}
          </Alert> */}
          {/* {demo && environment && checkScanStatus === "completed" ?  (
            <>
              <Alert severity="info">
                {scan && <span style={{ fontSize: 17 }}>Currently It is a demo scan to move to production click the button move to production </span>}
              </Alert>
              <Button color='primary' variant="contained" onClick={() => handleUpdate()} style={{marginTop: 10}}>Move to Production
              </Button>
            </>
          ) : null
          } */}
        </Box>
      ) : null}
    </>
  );
}
