import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import MuiTypography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import { CardContent, Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import loaderwave from "../../../assets/loader.svg";
import {
  getBillingStage,
  buyNowPackage,
} from "../../../store/actions/onBoardingActions";
import Cards from "./Cards";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import CardHeader from "@material-ui/core/CardHeader";
import history from "../../../@history/index";

import financialStyles from "../../../Styles/jss/financialStyles";
import NumericInput from "react-numeric-input";

const useStyles = makeStyles(financialStyles);

export default function Package({ domainId, setActiveStep }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tab, setTabValue] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [packages, setPackages] = React.useState(null);
  const [billingContent, setBillingContent] = React.useState(null);
  const [cards, setCards] = React.useState(null);

  const selectedDomain = useSelector(
    (state) => state.miscellaneous.selectedDomain
  );
  const selectedScanId = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );

  React.useEffect(() => {
    if (domainId) {
      dispatch(getBillingStage(domainId)).then((data) => {
        if (data) {
          setActiveStep(data.stage);
          if (
            data.evaluation.stripePackage &&
            data?.evaluation?.stripePackage?.resolutions
          ) {
            setPackages(data.evaluation.stripePackage);
            setBillingContent({
              ...data.evaluation,
              stripePackage: {
                resolutions: data?.evaluation?.stripePackage?.resolutions[0],
                rangeOfPages: data?.evaluation?.stripePackage?.rangeOfPages[0],
                rangeOfUsers: data?.evaluation?.stripePackage?.rangeOfUsers
                  ? data?.evaluation?.stripePackage?.rangeOfUsers[0]
                  : null,
                rangeOfAddons:
                  data?.evaluation?.stripePackage?.rangeOfAddons.length > 0
                    ? data?.evaluation?.stripePackage?.rangeOfAddons[0]
                    : [],
              },
            });
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [domainId]);

  React.useEffect(() => {
    if (packages) {
      setTabValue(packages.supportedTenures[0]);
    }
    // eslint-disable-next-line
  }, [packages]);

  const packagesTab = (event, newValue) => {
    setTabValue(newValue);
  };

  function handleUsers(valueAsNumber) {
    if (valueAsNumber) {
      setBillingContent({
        ...billingContent,
        stripePackage: {
          ...billingContent.stripePackage,
          rangeOfUsers: {
            ...billingContent.stripePackage.rangeOfUsers,
            userRange: valueAsNumber,
            price: valueAsNumber * 5,
          },
        },
      });
    }
  }

  function buyPackage() {
    setLoading(true);

    dispatch(
      buyNowPackage(
        {
          stripePackage: billingContent.stripePackage,
          domainId,
          cardId: cards.id,
          last4: cards.last4,
        },
        selectedDomain,
        selectedScanId
      )
    ).then((data) => {

      if (data) {
        setLoading(false);
      } else {
        setLoading(false);

      }
    });
  }

  return (
    <>
      {packages ? (
        <React.Fragment>
          <Box pt={5}>
            <Box display="flex" justifyContent="center" mb={3}>
              <Box className={classes.root}>
                {
                  packages && tab && (
                    // eslint-disable-next-line
                    <Tabs
                      data-tut="Select_tenure"
                      value={tab}
                      onChange={packagesTab}
                      indicatorColor="transparent"
                      textColor="primary"
                      centered
                    >
                      {packages.supportedTenures.map((tenure, index) => (
                        <CustomTab key={index} label={tenure} value={tenure} />
                      ))}
                    </Tabs>
                  )
                  // eslint-disable-next-line
                }
              </Box>
            </Box>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} md={5} justifyContent="center">
                {
                  // eslint-disable-next-line
                  packages &&
                  billingContent &&
                  packages.supportedTenures.map((tenure, index) => (
                    <TabPanelMain
                      value={tab || ""}
                      index={tenure}
                      key={index}
                    >
                      <Box>
                        <TabPanelMain value={tab || ""} index={tenure}>
                          <Card className={classes.packageCard}>
                            <CardHeader
                              title={"Selected Plan"}
                              titleTypographyProps={{ align: "center" }}
                              subheaderTypographyProps={{ align: "center" }}
                              className={classes.cardHeader}
                            />
                            <CardContent>
                              <div className={classes.cardPricing}>
                                <MuiTypography
                                  component="h2"
                                  variant="h3"
                                  className={`animate`}
                                  key={index - "12321312"}
                                  color="textPrimary"
                                >
                                  $
                                  {tab === "monthly"
                                    ? billingContent.monthlyTotal
                                    : billingContent.yearlyTotal}
                                </MuiTypography>
                                <MuiTypography
                                  variant="h6"
                                  color="textSecondary"
                                >
                                  /{tab}
                                </MuiTypography>
                              </div>
                              <Box
                                my={1}
                                display="flex"
                                alignItems="center"
                                flexDirection="column"
                              >
                                <p className={classes.listPackageMain}>
                                  Number of Pages
                                </p>
                                <p className={classes.listPackageDetail}>
                                  {packages.rangeOfPages.map(
                                    (pack, index) =>
                                      pack.tenure === tenure && (
                                        <p
                                          className={`${classes.listPackageDetail} animate`}
                                          key={index}
                                        >
                                          {`${pack.pageRange} (+$${pack.price})`}{" "}
                                        </p>
                                      )
                                  )}{" "}
                                </p>
                              </Box>

                              <Box
                                my={1}
                                display="flex"
                                alignItems="center"
                                flexDirection="column"
                              >
                                <p className={classes.listPackageMain}>
                                  Resolutions
                                </p>
                                <p style={{ margin: 0 }}>
                                  {" "}
                                  {packages.resolutions.map((pack, index) => {
                                    return (
                                      pack.tenure === tenure && (
                                        <>
                                          {pack.resolutions.map((reso, i) => {
                                            return (
                                              <p
                                                className={`${classes.listPackageDetail} animate`}
                                                key={i}
                                              >
                                                {" "}
                                                {reso.category +
                                                  `(${reso.resolution}) (+${reso.price}$)`}
                                              </p>
                                            );
                                          })}
                                        </>
                                      )
                                    );
                                  })}
                                </p>
                              </Box>
                              <Box
                                my={1}
                                display="flex"
                                alignItems="center"
                                flexDirection="column"
                              >
                                <p className={classes.listPackageMain}>
                                  Number of Users
                                </p>
                                <p className={classes.listPackageDetail}>
                                  {packages.rangeOfUsers.map(
                                    (pack, index) =>
                                      pack.tenure === tenure && (
                                        <p
                                          className={`${classes.listPackageDetail} animate`}
                                          key={index}
                                        >
                                          {`${pack.userRange} (+$${pack.price})`}{" "}
                                        </p>
                                      )
                                  )}{" "}
                                </p>
                              </Box>
                              {packages.rangeOfAddons.length > 0 && (
                                <Box
                                  my={1}
                                  display="flex"
                                  alignItems="center"
                                  flexDirection="column"
                                >
                                  <p className={classes.listPackageMain}>
                                    Addons
                                  </p>
                                  <p style={{ margin: 0 }}>
                                    {" "}
                                    {packages.rangeOfAddons.map(
                                      (pack, index) => {
                                        return (
                                          pack.tenure === tenure && (
                                            <>
                                              {Object.keys(pack.addOns).map(
                                                (addon, i) => {
                                                  return (
                                                    <p
                                                      className={`${classes.listPackageDetail} animate`}
                                                      key={i}
                                                    >
                                                      {" "}
                                                      {addon} (+
                                                      {
                                                        pack.addOns[addon]
                                                          .price
                                                      }
                                                      $){" "}
                                                    </p>
                                                  );
                                                }
                                              )}
                                            </>
                                          )
                                        );
                                      }
                                    )}
                                  </p>
                                </Box>
                              )}
                              {/* <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="center" mt={2} >
                                <MuiButton
                                  onClick={() => window.location.href = "/authenticate"}
                                  variant="contained" color="primary" style={{ borderRadius: "20px" }} >Buy Now {loadingBuyNow && <CircularProgress style={{ color: "black", marginLeft: "1rem", }} size={20} />}</MuiButton>
                              </Box> */}
                            </CardContent>
                          </Card>
                        </TabPanelMain>
                      </Box>
                    </TabPanelMain>
                  ))
                }
              </Grid>
            </Grid>
          </Box>

          <Cards
            domainId={domainId}
            billingContent={billingContent}
            setCards={setCards}
            cardss={cards}
          />
          {billingContent && cards && (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) =>
                    setBillingContent({
                      ...billingContent,
                      save: event.target.checked,
                    })
                  }
                  value={billingContent.save}
                />
              }
              label="Save this card for Billing"
            />
          )}
          <br />
          <br />
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.goBack()}
          >
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: "1rem" }}
            disabled={loading || !cards}
            onClick={() => buyPackage()}
          >
            Buy Now
            {loading && (
              <CircularProgress
                style={{ color: "black", marginLeft: "1rem" }}
                size={20}
              />
            )}
          </Button>
        </React.Fragment>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          {" "}
          <img src={loaderwave} alt="loader" />
        </Box>
      )}
    </>
  );
}

function TabPanelMain(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanelMain.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CustomTab = withStyles((theme) => ({
  root: {
    opacity: 1,
    color: "#1f3a93",
    borderRadius: 9,
    "&:hover": {
      color: "#1f3a93",
      opacity: 1,
    },
    "&$selected": {
      backgroundColor: "#1f3a93",
      color: "white",
      fontWeight: theme.typography.fontWeightMedium,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
    },
  },
  selected: {
    color: "#FFA412",
  },
}))((props) => <Tab {...props} />);
