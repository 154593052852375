import React, { Suspense, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";

import { withStyles } from "@material-ui/core/styles";
import loaderwave from "../assets/loader.svg";
import Grid from "@material-ui/core/Grid";
import ScanSelector from "../components/commons/ScanSelector";
import ModuleSummary from "../components/commons/ModuleSummary";
import PerformanceVisualization from "../components/PerformanceComponents/Structure/tabsContent/PerformanceVisualization";
import { Card, CircularProgress } from "@material-ui/core";
import Performance from "../components/PerformanceComponents/Structure/tabsContent/Performance";
import WaterFall from "../components/PerformanceComponents/Structure/tabsContent/WaterFall";
import Structure2 from "../components/PerformanceComponents/Structure/tabsContent/Structure";
import PerformanceSpredSheet from "./PerformanceSpreadSheet";
import Auth0Service from "../services/auth0Service/authentikService";

const PageSpeedCard = React.lazy(() =>
  import("../components/PerformanceComponents/pagespeed/PageSpeedCard")
);
const Structure = React.lazy(() =>
  import("../components/PerformanceComponents/Structure/Structure")
);

const HtmlHeadersCurrentAndHistory = React.lazy(() =>
  import("../components/PerformanceComponents/HtmlHeadersCurrentAndHistory")
);

const HTMLTables = React.lazy(() =>
  import("../components/tables/htmlCssTables/HTMLTables")
);

const CSSTables = React.lazy(() =>
  import("../components/tables/htmlCssTables/CSSTables")
);

const CSSCurrentAndHistory = React.lazy(() =>
  import("../components/PerformanceComponents/CSSCurrentAndHistory")
);

const HTMLCurrentAndHistory = React.lazy(() =>
  import("../components/PerformanceComponents/HTMLCurrentAndHistory")
);

const VideosCard = React.lazy(() =>
  import("../components/FileOptimizationComponents/VideosCurrentAndHistory")
);

const AudioCard = React.lazy(() =>
  import("../components/FileOptimizationComponents/AudioCurrentAndHistory")
);

const ImagesCard = React.lazy(() =>
  import("../components/FileOptimizationComponents/ImagesCurrentAndHistory")
);

const TotalFilesElementCard = React.lazy(() =>
  import(
    "../components/FileOptimizationComponents/TotalFilesElementCurrentAndHistory"
  )
);

//Tables
const FilesIssuesTable = React.lazy(() =>
  import("../components/tables/fileOptimizationTables/FilesIssuesTable")
);
const FilesArchivedIssuesTable = React.lazy(() =>
  import("../components/tables/fileOptimizationTables/FilesArchivedIssuesTable")
);
const FilesCompletedIssuesTable = React.lazy(() =>
  import(
    "../components/tables/fileOptimizationTables/FilesCompletedIssuesTable"
  )
);
const FilesIgnoredIssuesTable = React.lazy(() =>
  import("../components/tables/fileOptimizationTables/FilesIgnoredIssuesTable")
);
const NewFilesIssuesTable = React.lazy(() =>
  import("../components/tables/fileOptimizationTables/NewFilesIssuesTable")
);

// const FilesTables = React.lazy(() =>
//   import("../components/dnd/tables/fileOptimization/Cards")
// );

const PdfChecker = React.lazy(() =>
  import("../components/tables/fileOptimizationTables/PDFChecker")
);

export default function PageSpeedReport() {
  const selectedDomain = useSelector(
    (state) => state.miscellaneous.selectedDomain
  );
  const selectedScanId = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );
  const selectedScan = useSelector((state) => state.miscellaneous.selectedScan);
  const isPackagePurchased = useSelector(
    (state) => state.dashboard.isDomainPackagePurchased
  );

  const scanProgress = useSelector(
    (state) => state.onBoardDashboard.scanProgress
  );

  const tabsLoading = useSelector((state) => state.dashboard.tabsInfoLoading);

  // const scanProgress = useSelector(
  //   (state) => state.onBoardDashboard.scanProgress
  // );

  const [reportType, setReportType] = React.useState({
    structure: false,
    analytics: true,
    tables: false,
  });

  const performanceTabs = useSelector(
    (state) => state.dashboard.tabsInfo?.performance
  );

  // Performance Metrics start here


  const [boxesArray, setBoxesArray] = React.useState([]);

  const [performanceBackArray, setPerformanceBackArray] = React.useState([]);

  const [delayed, setDelayed] = React.useState(true);

  const [tableType, setTableType] = React.useState({
    html: true,
    css: false,
    unOPtimizedFiles: false,
    pdfChecker: false,
    waterfall: false,
    structure: false,
    others: false,
  });

  React.useEffect(() => {
    const PerformanceBackArray = [
      "pagespeedcard",
      "htmlheadercard",
      "htmlcard",
      "csscard",
      "totalFiles",
      "images",
      "videos",
      "audio",
    ];
    const array1 = JSON.parse(localStorage.getItem("performanceMainArray"));
    if (array1) {
      setPerformanceBackArray([...array1]);
    } else {
      setPerformanceBackArray([...PerformanceBackArray]);
      localStorage.setItem(
        "performanceMainArray",
        JSON.stringify(PerformanceBackArray)
      );
    }
  }, []);

  const [speed, setSpeed] = useState(true);
  const [header, setHeader] = useState(true);
  const [html, setHtml] = useState(true);
  const [css, setCss] = useState(true);
  const [files, setFiles] = useState(true);
  const [image, setImage] = useState(true);
  const [video, setVideo] = useState(true);
  const [audio, setAudio] = useState(true);

  //
  // React.useEffect(() => {
  //   console.log("Change happened");
  // }, [audio]);
  // React.useEffect(() => {
  //   console.log("Change happened");
  // }, [files]);
  React.useEffect(() => {
    if (selectedScanId && performanceBackArray.length > 0) {
      const preBoxes = [
        {
          text: <PageSpeedCard scanId={selectedScanId} check={setSpeed} />,
          id: "pagespeedcard",
          display: speed,
        },
        {
          text: (
            <HtmlHeadersCurrentAndHistory
              scanId={selectedScanId}
              check={setHeader}
            />
          ),
          id: "htmlheadercard",
          display: header,
        },
        {
          text: (
            <HTMLCurrentAndHistory scanId={selectedScanId} check={setHtml} />
          ),

          id: "htmlcard",
          display: html,
        },
        {
          text: <CSSCurrentAndHistory scanId={selectedScanId} check={setCss} />,
          id: "csscard",
          display: css,
        },
        {
          text: (
            <TotalFilesElementCard scanId={selectedScanId} check={setFiles} />
          ),
          id: "totalFiles",
          display: files,
        },
        {
          text: <ImagesCard scanId={selectedScanId} check={setImage} />,
          id: "images",
          display: image,
        },
        {
          text: <VideosCard scanId={selectedScanId} check={setVideo} />,
          id: "videos",
          display: video,
        },
        {
          text: <AudioCard scanId={selectedScanId} check={setAudio} />,
          id: "audio",
          display: audio,
        },
      ];
      // const arrayo = [];
      // for (let i = 0; i < performanceBackArray.length; i++) {
      //   switch (performanceBackArray[i]) {
      //     case "pagespeedcard":
      //       arrayo.push(
      //         preBoxes.filter((box) => box.id === "pagespeedcard")[0]
      //       );
      //       break;
      //     case "htmlheadercard":
      //       arrayo.push(
      //         preBoxes.filter((box) => box.id === "htmlheadercard")[0]
      //       );
      //       break;
      //     case "htmlcard":
      //       arrayo.push(preBoxes.filter((box) => box.id === "htmlcard")[0]);
      //       break;
      //     case "totalFiles":
      //       arrayo.push(preBoxes.filter((box) => box.id === "totalFiles")[0]);
      //       break;
      //     case "images":
      //       arrayo.push(preBoxes.filter((box) => box.id === "images")[0]);
      //       break;
      //     case "videos":
      //       arrayo.push(preBoxes.filter((box) => box.id === "videos")[0]);
      //       break;
      //     case "audios":
      //       arrayo.push(preBoxes.filter((box) => box.id === "audio")[0]);
      //       break;
      //     default:
      //       arrayo.push(preBoxes.filter((box) => box.id === "csscard")[0]);
      //   }
      // }
      setBoxesArray([...preBoxes]);
    }
    // eslint-disable-next-line
  }, [
    selectedDomain,
    selectedScanId,
    performanceBackArray,
    video,
    audio,
    files,
    image,
  ]);

  // React.useEffect(() => {
  //   if (selectedScanId && performanceBackArray.length > 0) {
  //     const preBoxes = [
  //       {
  //         text: <PageSpeedCard scanId={selectedScanId} />,
  //         id: "pagespeedcard",
  //         display: speed,
  //       },
  //       {
  //         text: <HtmlHeadersCurrentAndHistory scanId={selectedScanId} />,
  //         id: "htmlheadercard",
  //         display: header,
  //       },
  //       {
  //         text: <HTMLCurrentAndHistory scanId={selectedScanId} />,

  //         id: "htmlcard",
  //         display: html,
  //       },
  //       {
  //         text: <CSSCurrentAndHistory scanId={selectedScanId} />,
  //         id: "csscard",
  //         display: css,
  //       },
  //       {
  //         text: (
  //           <TotalFilesElementCard scanId={selectedScanId} check={setFiles} />
  //         ),
  //         id: "totalFiles",
  //         display: files,
  //       },
  //       {
  //         text: <ImagesCard scanId={selectedScanId} check={setImage} />,
  //         id: "images",
  //         display: image,
  //       },
  //       {
  //         text: <VideosCard scanId={selectedScanId} check={setVideo} />,
  //         id: "videos",
  //         display: video,
  //       },
  //       {
  //         text: <AudioCard scanId={selectedScanId} check={setAudio} />,
  //         id: "audio",
  //         display: audio,
  //       },
  //     ];

  //     setBoxesArray([...preBoxes]);
  //   }
  //   // eslint-disable-next-line
  // }, [video]);

  React.useEffect(() => {
    setTimeout(() => {
      setDelayed(false);
    }, 1500);
  }, []);

  const CustomTab = withStyles((theme) => ({
    root: {
      minWidth: 40,
      opacity: 1,
      minHeight: "auto",
      borderRadius: "2px",
      padding: "0px 8px",
      border: "1px solid #1f3a93",
      color: "#1f3a93",
      fontWeight: 400,
      fontSize: "18px",
      borderRight: 0,
      "&:last-child": {
        borderRight: "1px solid #1f3a93",
      },
      "&:hover": {
        color: "#ffff!important",
        backgroundColor: "#1f3a93",
        opacity: 1,
      },
      "&$selected": {
        color: "white",
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: "#1f3a93",
      },
      "&:focus": {
        color: "white",
        backgroundColor: "#1f3a93",
      },
    },
    selected: {
      color: "white",
      backgroundColor: "#1f3a93",
      // display: 'none'
    },
  }))((props) => <Tab disableRipple {...props} />);

  function ignorey(flag) { }

  function TableTabs() {
    return (
      <Box display="flex" alignItems="center" width='100%' style={{ padding: 17, background: '#fff' }}>
        {performanceTabs.html && (
          <CustomTab
            label="HTML"
            onClick={() => setTableType({ html: true })}
            style={{
              backgroundColor: tableType.html ? "#1f3a93" : "none",
              color: tableType.html ? "#ffff" : "#1f3a93",
            }}
            tabIndex="0"
          />
        )}

        {performanceTabs.css && (
          <CustomTab
            label="CSS"
            onClick={() => setTableType({ css: true })}
            style={{
              backgroundColor: tableType.css ? "#1f3a93" : "none",
              color: tableType.css ? "#ffff" : "#1f3a93",
            }}
            tabIndex="0"
          />
        )}

        {performanceTabs.unoptFiles && (
          <CustomTab
            label=" Unopt. Files"
            onClick={() => setTableType({ unOPtimizedFiles: true })}
            style={{
              backgroundColor: tableType.unOPtimizedFiles ? "#1f3a93" : "none",
              color: tableType.unOPtimizedFiles ? "#ffff" : "#1f3a93",
            }}
            tabIndex="0"
          />
        )}

        {performanceTabs.pdf && (
          <CustomTab
            label=" PDF Checker "
            onClick={() => setTableType({ pdfChecker: true })}
            style={{
              backgroundColor: tableType.pdfChecker ? "#1f3a93" : "none",
              color: tableType.pdfChecker ? "#ffff" : "#1f3a93",
            }}
            tabIndex="0"
          />
        )}
        {performanceTabs.waterfall && (
          <CustomTab
            label=" Waterfall "
            onClick={() => setTableType({ waterfall: true })}
            style={{
              backgroundColor: tableType.waterfall ? "#1f3a93" : "none",
              color: tableType.waterfall ? "#ffff" : "#1f3a93",
            }}
            tabIndex="0"
          />
        )}
        {!performanceTabs.structure && (
          <CustomTab
            label=" Structure "
            onClick={() => setTableType({ structure: true })}
            style={{
              backgroundColor: tableType.structure ? "#1f3a93" : "none",
              color: tableType.structure ? "#ffff" : "#1f3a93",
            }}
            tabIndex="0"
          />
        )}
        {isPackagePurchased && performanceTabs.others && (
          <CustomTab
            label="Others "
            onClick={() => setTableType({ others: true })}
            style={{
              backgroundColor: tableType.others ? "#1f3a93" : "none",
              color: tableType.others ? "#ffff" : "#1f3a93",
            }}
            tabIndex="0"
          />
        )}
        {/* </ButtonGroup> */}
      </Box>
    );
  }
  return (
    <>
      <Suspense
        fallback={
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="70vh"
            tabIndex="0"
          >
            {" "}
            <img src={loaderwave} alt="loader" />
          </Box>
        }
      >
        {tabsLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="70vh"
            tabIndex="0"
          >
            <img src={loaderwave} alt="loader" />
          </Box>
        ) : (
          <>
            <div>
              <ScanSelector />
              <ModuleSummary
                module="performance"
                title="Overall Performance Score"
              />
              {selectedScan && (
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                >
                  <Box display="flex" flexWrap="wrap" alignItems="center">
                    <CustomTab
                      label="Analytics"
                      onClick={() => setReportType({ analytics: true })}
                      style={{
                        backgroundColor: reportType.analytics
                          ? "#1f3a93"
                          : "none",
                        color: reportType.analytics ? "#ffff" : "#1f3a93",
                      }}
                      tabIndex="0"
                    />
                    {Auth0Service.isAuthenticated() && <CustomTab
                      label="Spread Sheet"
                      onClick={() => setReportType({ sheet: true })}
                      style={{
                        backgroundColor: reportType.sheet
                          ? "#1f3a93"
                          : "none",
                        color: reportType.sheet ? "#ffff" : "#1f3a93",
                      }}
                      tabIndex="0"
                    />}
                    {tabsLoading ? <CircularProgress style={{ marginLeft: 10 }} size={20} /> : <>
                      {Object.values(performanceTabs).some(item => item === true) &&
                        <CustomTab
                          label="Data tables"
                          onClick={() => setReportType({ tables: true })}
                          style={{
                            backgroundColor: reportType.tables ? "#1f3a93" : "none",
                            color: reportType.tables ? "#ffff" : "#1f3a93",
                          }}
                          tabIndex="0"
                        />
                      }</>
                    }


                  </Box>
                </Box>
              )}
              {selectedScan ? (
                <>
                  {reportType.analytics && (
                    <>
                      {/* start here */}
                      {boxesArray.length > 0 && (
                        // <DndProvider backend={HTML5Backend}>
                        //   <PerformanceCards boxes={boxesArray} />
                        // </DndProvider>
                        <Grid container spacing={2}>
                          {boxesArray.map((analyticsBox, index) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                md={6}
                                key={index}
                                style={{
                                  display: analyticsBox.display
                                    ? "block"
                                    : "none",
                                }}
                              >
                                {analyticsBox.text}
                              </Grid>
                            );
                          })}
                          <Grid
                            item
                            xs={12}
                          >
                            <Card style={{ boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.15)", padding: 14, overflow: "visible", borderRadius: 9 }} >
                              <PerformanceVisualization />
                            </Card>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                          >
                            <Card style={{ boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.15)", padding: 14, overflow: "visible", borderRadius: 9 }} >
                              <Performance />
                            </Card>
                          </Grid>
                        </Grid>
                      )}


                      {/* end here */}
                    </>
                  )}
                  <Grid container spacing={2}>

                    {reportType.tables && (
                      <>
                        {Object.values(performanceTabs).includes(true) && <TableTabs />}
                        {/* <TableTabs /> */}
                        {tableType.html && (
                          <>

                            <>
                              <HTMLTables
                                link={null}
                                scanId={selectedScanId}
                                setTables={ignorey}


                              />
                            </>

                          </>
                        )}

                        {tableType.css && (
                          <>

                            <>
                              <CSSTables
                                link={null}
                                scanId={selectedScanId}
                                setTables={ignorey}


                              />
                            </>

                          </>
                        )}

                        {tableType.unOPtimizedFiles && (
                          <>

                            <>
                              <FilesIssuesTable
                                scanId={selectedScanId}
                                setTables={ignorey}


                              />
                            </>

                          </>
                        )}
                        {tableType.pdfChecker && (
                          <>

                            <>
                              <PdfChecker
                                scanId={selectedScanId}
                                setTables={ignorey}


                              />
                            </>

                          </>
                        )}
                        {tableType.waterfall && (
                          <>

                            <>
                              <WaterFall />
                            </>

                          </>
                        )}
                        {tableType.structure && (
                          <>

                            <>
                              <Structure2 />
                            </>

                          </>
                        )}
                        {tableType.others && (
                          <>

                            <>
                              <FilesArchivedIssuesTable
                                scanId={selectedScanId}
                                setTables={ignorey}


                              />
                              <FilesCompletedIssuesTable
                                scanId={selectedScanId}
                                setTables={ignorey}

                              />
                              <FilesIgnoredIssuesTable
                                scanId={selectedScanId}
                                setTables={ignorey}

                              />
                              <NewFilesIssuesTable
                                scanId={selectedScanId}
                                setTables={ignorey}

                              />
                            </>

                          </>
                        )}

                        {!Object.values(performanceTabs).includes(true) && <Box display='flex' width='100%' justifyContent='center' p={3} margin='auto'>
                          <h3 align="center"> {scanProgress.status !== "completed" && "Currently, the scan is running. If an issue is discovered, the data will automatically populate here."}</h3>
                        </Box>}
                      </>

                    )}
                  </Grid>
                  {reportType.sheet && (
                    <>

                      <PerformanceSpredSheet />
                    </>
                  )}
                </>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="70vh"
                  tabIndex="0"
                >
                  <img src={loaderwave} alt="loader" />
                </Box>
              )}
            </div>
          </>
        )}
      </Suspense>
    </>
  );
}
