import axios from 'axios';
import jwtDecode from 'jwt-decode';
import logo from '../../assets/logo.svg';
import { BASE_URL } from '../../source/constants';

const getConfig = localStorage.getItem("authConfig");
const configValue = JSON.parse(getConfig);
const AUTH_CONFIG = {
  client_id: 'fhOmcVTodFHuGIC9g6hJFbZGl0ncDXBsve9vfMxs',
  redirect_uri: `https://dashboard.allyright.com/authenticate`,
  authUrl: 'https://authentik.allyright.com/application/o/authorize/',
  callbackUrl: `${BASE_URL}/authentik/callback`,
  userInfoUrl: 'https://authentik.allyright.com/application/o/userinfo/',
};
const getDomain = localStorage.getItem("domain");

class AuthentikService {
  getQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get("website");
    if (paramValue) {
      localStorage.setItem("domain", paramValue);
    }
    return paramValue;
  };

  getUserQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get("user");
    return paramValue;
  };

  init(success) {
    if (!AUTH_CONFIG || !AUTH_CONFIG.value || Object.entries(AUTH_CONFIG).length === 0) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('Missing Authentik configuration');
      }
      success(false);
      return;
    }
    success(true);
  }

  login = () => {
    const clientId = AUTH_CONFIG.client_id;
    const redirectUri = AUTH_CONFIG.redirect_uri;
    window.location.href = `${AUTH_CONFIG.authUrl}/?client_id=${clientId}&response_type=code&scope=openid email profile&redirect_uri=${redirectUri}`;
  };

  register = async (registrationData) => {
    try {
      const response = await axios.post(`${BASE_URL}/authentik/register`, registrationData);
      return { success: true, message: 'Registration successful, please login' };
    } catch (error) {
      console.warn('Registration failed', error);
      return { success: false, message: 'Registration failed' };
    }
  };

  handleCallback = async (code) => {
    try {
      console.log("here in callback")
      const response = await axios.post(`${BASE_URL}/authentik/callback`, {
        code,
      });
      console.log("Response from authentik", response.data)
      console.log(response.data)
      this.setSession(response.data);
      return {result: true, tokenData: response.data};
    } catch (error) {
      console.warn('Authentication failed', error);
      return false;
    }
  };

  setSession = (authResult) => {
    if (authResult && authResult.access_token && authResult.user.sub) {
      const expiresAt = JSON.stringify(authResult.expires_in * 1000 + new Date().getTime());
      localStorage.setItem('access_token', authResult.access_token);
      localStorage.setItem('id_token', authResult.user.sub);
      localStorage.setItem('expires_at', expiresAt);
    }
  };

  // logout = () => {

  //   localStorage.removeItem('access_token');
  //   localStorage.removeItem('id_token');
  //   localStorage.removeItem('expires_at');
  //   localStorage.removeItem('domain');
  //   localStorage.removeItem('selectedDomain');
  // };


  logout = async () => {
    try {
      // // // Get the access token from local storage
      // const accessToken = localStorage.getItem('access_token');

      // // // If there is an access token, send it to the backend for revocation
      // if (accessToken) {
      //   await axios.post(`${BASE_URL}/authentik/logout`, {
      //     accessToken: accessToken
      //   });
      //   console.log('Token revoked successfully via backend');
      // }

      // Clear the application's local storage
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('expires_at');
      localStorage.removeItem('domain');
      localStorage.removeItem('selectedDomain');

      // Optionally redirect to a login page or home page
      window.location.href = "https://authentik.allyright.com/application/o/allyright/end-session/";
      // window.location.href = '/authenticate'; // Or any other page you want to redirect to
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  isAuthenticated = () => {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  };

  getUserData = () => {
    return new Promise((resolve, reject) => {
      const tokenData = this.getTokenData();
      const { sub: userId } = tokenData;

      axios.get(AUTH_CONFIG.userInfoUrl, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.getAccessToken()}`
        }
      }).then(response => {
        resolve(response.data);
      }).catch(error => {
        console.warn('Cannot retrieve user data', error);
        reject(error);
      });
    });
  };

  getAccessToken = () => {
    return localStorage.getItem('access_token');
  };

  getIdToken = () => {
    return localStorage.getItem('id_token');
  };

  getTokenData = () => {
    const token = this.getIdToken();
    const decoded = jwtDecode(token);
    if (!decoded) {
      return null;
    }
    return decoded;
  };
}

const instance = new AuthentikService();

export default instance;
