import {
  GET_ALL_SCANS,
  GET_ALL_SCANS_LOADING,
  GET_ALL_DOMAINS,

} from "../actions/adminScansManagementActions";

const initialState = {
  data: [],
  loading: false,
  allDomains: [],

};

const adminScansManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SCANS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_ALL_SCANS_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case GET_ALL_DOMAINS:
      return {
        ...state,
        allDomains: action.payload.result
      };
    case GET_ALL_SCANS_LOADING:
      return {
        ...state,
        breachedInfoLoading: !state.breachedInfoLoading
      };

    default:
      return state;
  }
};
export default adminScansManagementReducer;