import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ListSubheader from '@material-ui/core/ListSubheader';
import CheckListItem from './CheckListItem';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    maxHeight: 400,
    height: '100%',
    overflow: 'auto',
    borderRadius: 10
  },
  listHeader: {
    backgroundColor: '#1f3a93',
    color: 'white'
  }
}));

export default function CheckList({ list, mainCheckItems,setMainCheckItems }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Paper elevation={3} style={{ borderRadius: 10 }} >
        <List subheader={<ListSubheader className={classes.listHeader}>{list.name}<ListItemSecondaryAction onClick={() => setOpen(!open)} style={{ borderRadius: 10, }} >
          <IconButton edge="end" aria-label="comments">
            <ErrorOutlineIcon style={{ color: 'white' }} />
          </IconButton>
        </ListItemSecondaryAction></ListSubheader>} className={classes.root}>
          <Collapse in={open} timeout="auto" unmountOnExit> <Box p={2}>{list.description}</Box></Collapse>
          {list.checkItems.map((item, index) => {
            return (
              <CheckListItem checkListId = {list._id} item={item} index={index} mainCheckItems={mainCheckItems}
                setMainCheckItems={setMainCheckItems} />
            );
          })}
        </List>

      </Paper>
    </Grid>
  );
}




