const headerStyles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    // zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 10
  },
  hide: {
    display: "none",
  },
  skipContent: {
    border: "1px solid 	#DCDCDC",
    padding: "1px 5px",
    fontSize: 15,
    fontWeight: 450,
    userSelect: "none",
    cursor: "pointer",
  },
  maxContent: {
    width: 28,
    height: 28,
    background: "#E2211E",
    borderRadius: 9,
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 19,
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  minContent: {
    width: 28,
    height: 28,
    background: "#1f3a93",
    borderRadius: 9,
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 19,
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 5,
    cursor: "pointer",
  },
  grow: {
    flexGrow: 1,
  },
  icons: {
    fontSize: "2.2em",
    color: "#1F3A93",
    margin: "0px 7px",
  },
  borderLeftRight: {
    borderLeft: "1px solid #DCDCDC",
    borderRight: "1px solid #DCDCDC",
  },
  avatar: {
    margin: "0px 8px",
  },
  img: {
    marginRight: 15,
    cursor: "pointer",
  },
  menuIconBox: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  menuIconBox2: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  menuIcon: {
    fontSize: "2.2em",
    color: "#1F3A93",
  },
  chatIcon: {
    fontSize: "2.2em",
    color: "white",
  },
  dropDownList: {
    minWidth: "245px",
    padding: "0px",
    maxHeight: "410px",
    overflowY: "auto",
  },
  chatIconCircle: {
    border: "1px solid #DCDCDC",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  listItem: {
    paddingTop: "15px",
    paddingBottom: "15px",
    cursor: "default",
  },
  listItemTitle: {
    marginBottom: "5px",
    fontSize: "14px",
    color: "#1F3A93",
    display: "block",
  },
  barMenu: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: {
    display: "block",
    boxShadow: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  sectionDesktop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "0px",
    flexWrap: "wrap",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      paddingRight: "50px",
      alignItems: "center",

    },
  },
  user: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  root3: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: "25px",
    maxWidth: 460,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  formControl: {
    width: 220,
    maxWidth: 300,
    marginBottom: theme.spacing(1),
    fontFamily: ` 'Segoe UI', 'sans-serif'`,
    fontWeight: 600,
    fontSize: 14,
    '& fieldset': {
      outline: 'none',
      border: 'none'
    }

  },
  formControlScan: {
    minWidth: 235,
    // marginBottom: theme.spacing(1),
    fontFamily: ` 'Segoe UI', 'sans-serif'`,
    fontWeight: 'bold',
    fontSize: 20,
    '& fieldset': {
      outline: 'none',
      border: 'none'
    }

  },
  primaryColor: {
    color: "#1F3A93",
  },
  secondaryColor: {
    color: "#96291c",
  },
  marginZero: {
    margin: 0,
  },
  addSiteButton: {
    margin: 0,
    color: "#96291c",
    fontWeight: "600",
  },
  rightDrawer: {
    width: 320,
    maxWidth: 320,
    height: "100%",
    margin: "0px 30px",
  },
  drawerRight: {
    zIndex: "9999999999",
  },
  segoeFont: {
    fontFamily: ` 'Segoe UI', 'sans-serif'`,
    fontWeight: 600,
    fontSize: 14,
  },
  selectDomain: {
    fontFamily: ` 'Segoe UI', 'sans-serif'`,
    fontWeight: 600,
    fontSize: 14,
    marginLeft: -10,
    color: '#577CFF',
    border: '1px solid #577CFF',
    '& .MuiSelect-select': {
      padding: '8px 32px 8px 15px'

    }
  },

  selectScan: {
    fontFamily: ` 'Segoe UI', 'sans-serif'`,
    textTransform: 'capitalize',
    '& .MuiSelect-select': {
      padding: '8px 32px 8px 0px',
      fontWeight: 600,
      fontSize: 20,
    }
  },
  lowerCase: {
    textTransform: "lowerCase"
  },
  scansProgressSubList: {
    margin: "8px 0",
    fontWeight: 600,
    fontFamily: "Segoe UI",
  },
  emailVerifyBar: {
    width: '100%',
    position: "absolute",
    left: 250,
    top: 60,
    [theme.breakpoints.down("md")]: {
      left: 0,
    },
  },
  generalTabs: {
    margin: 0,
    marginLeft: 60,
    marginRight: '50px',
    padding: 0,
    display: 'flex',
    justifyContent: 'start',
    listStyle: 'none',
    backgroundImage:
      `linear-gradient(to right, white, white),
      linear-gradient(to right, white, white),
      linear-gradient(to right, rgba(0,0,0,.25), rgba(255,255,255,0)),
      linear-gradient(to left, rgba(0,0,0,.25), rgba(255,255,255,0))`,
    backgroundPosition: `left center, right center, left center, right center`,
    backgroundRepeat: `no-repeat`,
    backgroundColor: `white`,
    backgroundSize: `20px 100%, 20px 100%, 10px 100%, 10px 100%`,
    backgroundAttachment: `local, local, scroll, scroll`,
    overflowX: "auto",
    "& li": {
      padding: 0
    },
    "& li:first-child": { // Add this selector to target the first list item
      textDecoration: 'none',
      padding: '15px 20px',
      display: 'inline-block',
      color: 'black'
      // Adjust styling for the first list item here
    },
    "& li > a": {
      textDecoration: 'none',
      padding: '15px 20px',
      display: 'inline-block',
      color: 'black'
    }
  },
  activeTab: {
    borderBottom: '3px solid black',
    marginRight: '20px',
  },
  scanProgress: {
    color: (props) => props.scanProgress && props.scanProgress.status === 'completed' ? '#065B14' : '#ff5f00',
    textTransform: 'capitalize',
  }
});

export default headerStyles;
