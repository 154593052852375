import React from 'react';
import Box from '@material-ui/core/Box';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';


export default function Resolutions({ billingContent, setBillingResolutions, billingResolutions }) {

  const [resolutions, setResolutions] = React.useState(null);

  React.useEffect(() => {
    if (billingContent) {
      setResolutions(billingContent.resolutions[0]?.categories);
    }
  }, [billingContent])

  const handleResolutions = (event, reso) => {
    const isEnabled = event.target.checked;
    if (isEnabled) {
      setBillingResolutions([...billingResolutions, reso])
    } else {
      const filteredResolutions = billingResolutions.filter(resolution => resolution.resolution !== reso.resolution);
      setBillingResolutions(filteredResolutions)
    }
  };

  return (
    <form >
      <Box p={2} overflow="auto" >
        <FormControl component="fieldset">
          <FormGroup style={{ display: "block" }} >
            {resolutions && resolutions.map(pack => {
              return (
                <>
                  <h3>{pack.name}</h3>
                  <table className="resolutions-table">
                    <tbody>
                      {pack.resolutions.map((res, index) => {
                        return (
                          <tr>
                            <td> <FormControlLabel
                              control={<CustomCheckbox
                                onChange={(event) => handleResolutions(event, res)}
                                value={pack.resolution} />}
                              label={`${res.resolution} (+${res.price}$)`}
                            /> </td>
                            {res.devices.map((device) => {
                              return <td>{device}</td>
                            })
                            }
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </>
              )
            }
            )}
          </FormGroup>
        </FormControl>
      </Box>
    </form>
  )
}

const CustomCheckbox = withStyles({
  root: {
    '&$checked': {
      color: "#2a31a3",
    },
  },
  checked: {},
})((props) => <Checkbox  {...props} />);