import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { Typography } from '@material-ui/core';
import Loader from "../../components/commons/Loader";
import CustomCardContainerHead from "../../components/commons/CustomCardContainerHead";
import { useDispatch } from "react-redux";
import { getThemes, getPlugins, getWpVersion, createWorpressOptimization, getPluginStatus,getPluginsWp, getCrawlerStatus, getPluginStatusWp, getThemesWp, getWordpressVersionWp } from "../../store/actions/abracadabraActions";
import Pagination from "../../../src/components/pagination/Pagination"; // Import the Pagination component
import { toast } from "react-toastify";


export default function DomainImages({ domain, selectedScanId }) {
    const [loadingLinks, setLoadingLinks] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [plugindata, setPluginData] = React.useState([]);
    const [wpVersion, setWpVersion] = React.useState("");
    const [currentPage1, setCurrentPage1] = React.useState(1);
    const [currentPage2, setCurrentPage2] = React.useState(1);
    const [showProgress, setShowProgress] = useState(false);
    const [showProgress2, setShowProgress2] = useState(false);
    const [pluginInstalled, setPluginInstalled] = useState(true);
    const [loading, setLoading] = useState(true);
    const [scanStatus, setScanStatus] = useState('');
    const [showPluginInfo, setShowPluginInfo] = useState(false);
    const dispatch = useDispatch();

    // Simulating a process with setTimeout
    React.useEffect(() => {
        // Simulate a process taking 3 seconds to complete
        const process = setTimeout(() => {
            // Once the process is completed
            setLoading(false); // Hide the loader
            // Additional actions or state changes upon success can go here
        }, 4000);

        // Clear the timeout if component unmounts or if process succeeds before 3 seconds
        return () => clearTimeout(process);
    }, []);

    React.useEffect(() => {
        getPluginStatus2();
    }, [pluginInstalled]);

    // React.useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         getCrawlStatus();
    //     }, 5000); // 5 seconds in milliseconds

    //     // Clear interval on component unmount
    //     return () => clearInterval(intervalId);
    // }, [showPluginInfo]);

    async function getPluginStatus2() {
        try {
            const response = await dispatch(getPluginStatusWp(selectedScanId));
            console.log(response);
            if(response.error) {
                setPluginInstalled(true);
                setLoadingLinks(false);
            }
            if (response && response.response === 200) {
                setPluginInstalled(true);
                setLoadingLinks(false);
                // console.log(response.response.result);
            } else {
                setPluginInstalled(false);
                setLoadingLinks(false);
                // toast.error("Failed to retrieve contact details.");
            }
        } catch (error) {
            setPluginInstalled(false);
            setLoadingLinks(false);
        }
    }

    function getThemesDetails() {
        dispatch(getThemesWp(selectedScanId))
            .then(data => {
                if (data) {
                    if (data.response === "Request failed with status code 404") {
                        // Simulate a request
                        setTimeout(() => {
                            setShowProgress(true);

                            // Hide the progress indicator after 3 seconds
                            setTimeout(() => {
                                setShowProgress(false);
                            }, 3000);
                        }, 1000);
                    } else {
                        setLoadingLinks(false);
                        setData(data.response);
                    }

                } else {
                    setLoadingLinks(false);
                }
            })
            .catch(err => {
                setLoadingLinks(false);
            });
    }


    function WorpressOptimization() {
        dispatch(createWorpressOptimization(domain))
            .then(data => {
                if (data) {
                    // Simulate a request
                    setTimeout(() => {
                        setShowProgress(true);

                        // Hide the progress indicator after 3 seconds
                        setTimeout(() => {
                            setShowProgress(false);
                        }, 3000);
                    }, 1000);
                    toast.success(data.response.message)
                } else {
                    setLoadingLinks(false);
                }
            })
            .catch(err => {
                setLoadingLinks(false);
            });
    }

    function getWpVersionDetail() {
        dispatch(getWordpressVersionWp(selectedScanId))
            .then(data => {
                if (data) {
                    setLoadingLinks(false);
                    setWpVersion(data.response.version);
                } else {
                    setLoadingLinks(false);
                }
            })
            .catch(err => {
                setLoadingLinks(false);
            });
    }

    function getPluginDetails() {
        dispatch(getPluginsWp(selectedScanId))
            .then(data => {
                if (data) {
                    if (data.response === "Request failed with status code 404") {
                        // Simulate a request
                        setTimeout(() => {
                            setShowProgress2(true);

                            // Hide the progress indicator after 3 seconds
                            setTimeout(() => {
                                setShowProgress2(false);
                            }, 3000);
                        }, 1000);
                    } else {
                        setLoadingLinks(false);
                        if (data.response !== "Request failed with status code 500") {
                            setPluginData(data.response);
                        }
                    }
                } else {
                    setLoadingLinks(false);
                }
            })
            .catch(err => {
                setLoadingLinks(false);
            });
    }


    async function getCrawlStatus() {
        try {
            const response = await dispatch(getCrawlerStatus(domain));
            console.log(response.response.result);
            if (response && response.response.result === "completed") {
                setLoadingLinks(false);
                setShowPluginInfo(true);
                setScanStatus(response.response.result);
                // console.log(response.response.result);
            } else {
                setShowPluginInfo(true);
                setLoadingLinks(false);
                setScanStatus(response.response.result);
                // toast.error("Failed to retrieve contact details.");
            }
        } catch (error) {
            setLoadingLinks(false);
            setShowPluginInfo(false);
            // toast.error("No task found or still running");
        }
    }


    React.useEffect(() => {
        setLoadingLinks(true);
        getThemesDetails();
        getPluginDetails();
        getWpVersionDetail();
    }, []);

    // Pagination for the first table (themes)
    const itemsPerPage1 = 10; // Number of items to display per page
    const totalPages1 = Math.ceil(data.length / itemsPerPage1);
    const indexOfLastItem1 = currentPage1 * itemsPerPage1;
    const indexOfFirstItem1 = indexOfLastItem1 - itemsPerPage1;
    const currentItems1 = data.slice(indexOfFirstItem1, indexOfLastItem1);

    // Pagination for the second table (plugins)
    const itemsPerPage2 = 10; // Number of items to display per page
    const totalPages2 = Math.ceil(plugindata.length / itemsPerPage2);
    const indexOfLastItem2 = currentPage2 * itemsPerPage2;
    const indexOfFirstItem2 = indexOfLastItem2 - itemsPerPage2;
    const currentItems2 = plugindata.slice(indexOfFirstItem2, indexOfLastItem2);

    const handlePageChange1 = (newPage) => {
        setCurrentPage1(newPage);
    };

    const handlePageChange2 = (newPage) => {
        setCurrentPage2(newPage);
    };


    const downloadFileInNewTab = () => {
        // Set the relative path to the file in the public folder
        const fileURL = '/plugins/AllyRight.zip';

        // Open a new tab and start the download
        window.open(fileURL);
    };


    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    {pluginInstalled ? (
                        <>
                            {/* {showPluginInfo && scanStatus === "completed" ? ( */}
                                <>
                                    <CustomCardContainerHead title={"Wordpress Version"} titleTag="h1">
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center">
                                                {!loadingLinks && wpVersion ? (
                                                    <Grid item xs={3}>
                                                        <h2>Version: {wpVersion}</h2>
                                                        {/* <Grid item xs={3}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={WorpressOptimization}
                                                            >
                                                                Optimize
                                                            </Button>
                                                        </Grid> */}
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={3}>
                                                        <h2></h2>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </CustomCardContainerHead>

                                    {/* Pagination for the first table (themes) */}
                                    <CustomCardContainerHead title="Theme Details" titleTag="h1">
                                        {loadingLinks && !data ? (
                                            <Loader />
                                        ) : (
                                            <>
                                                <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                                                    <Table aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Name</TableCell>
                                                                <TableCell>Status</TableCell>
                                                                <TableCell>Version</TableCell>
                                                                <TableCell>Update Available</TableCell>
                                                                <TableCell>Auto Update</TableCell>
                                                                <TableCell>Author</TableCell>
                                                                <TableCell>Author URI</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {Array.isArray(currentItems1) && currentItems1.length > 0 ? (
                                                                currentItems1.map((item, index) => (
                                                                    <TableRow key={index}>
                                                                        {Object.values(item).map((value, index) => (
                                                                            <TableCell key={index}>{value.toString()}</TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                ))
                                                            ) : (
                                                                <TableRow>
                                                                    {showProgress ? (
                                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                                                                            <CircularProgress />
                                                                        </div>
                                                                    ) : (
                                                                        <TableCell colSpan={7}>No data available</TableCell>
                                                                    )}
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <Grid item xs={12}>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={3}>
                                                            <h4>To fetch the latest themes from the website, click the update button</h4>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={getThemesDetails}
                                                            >
                                                                Refresh
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* Pagination controls for the first table (themes) */}
                                                {!loadingLinks && data ? (
                                                    <Pagination totalPages={totalPages1} currentPage={currentPage1} onPageChange={handlePageChange1} />
                                                ) : null}
                                            </>
                                        )}
                                    </CustomCardContainerHead>




                                    {/* Pagination for the second table (plugins) */}

                                    <CustomCardContainerHead title={"Plugin Details"} titleTag="h1">
                                        {loadingLinks && !plugindata ? (
                                            <Loader />
                                        ) : (
                                            <>
                                                <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                                                    <Table aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Name</TableCell>
                                                                <TableCell>Status</TableCell>
                                                                <TableCell>Version</TableCell>
                                                                <TableCell>Update Available</TableCell>
                                                                <TableCell>Auto Update</TableCell>
                                                                <TableCell>Author</TableCell>
                                                                <TableCell>Author URI</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {Array.isArray(currentItems2) && currentItems2.length > 0 ? (
                                                                currentItems2.map((item, index) => (
                                                                    <TableRow key={index}>
                                                                        {Object.values(item).map((value, index) => (
                                                                            <TableCell key={index}>{value.toString()}</TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                ))
                                                            ) : (
                                                                <TableRow>
                                                                    {showProgress2 ? (
                                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                                                                            <CircularProgress />
                                                                        </div>
                                                                    ) : (
                                                                        <TableCell colSpan={7}>No data available</TableCell>
                                                                    )}
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <Grid item xs={12}>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={3}>
                                                            <h4>To fetch the latest plugins from the website, click the update button</h4>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={getPluginDetails}
                                                            >
                                                                Refresh
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* Pagination controls for the second table (plugins) */}
                                                {!loadingLinks && plugindata ? (
                                                    <Pagination totalPages={totalPages2} currentPage={currentPage2} onPageChange={handlePageChange2} />
                                                ) : null}
                                            </>
                                        )}

                                    </CustomCardContainerHead>

                                </>
                            {/* ) : (scanStatus === "pending" ? (
                                <CustomCardContainerHead title="Plugin And Themes" titleTag="h1">
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 20, marginBottom: 20 }} >
                                        <Typography variant="h4" style={{ fontSize: '24px' }}>
                                            Scan is running you can view the Plugin And Themes details after the scan is finished
                                        </Typography>
                                    </div>
                                </CustomCardContainerHead>
                            ) : <Loader />)} */}
                        </>

                    ) : (
                        <CustomCardContainerHead title="Install Plugin" titleTag="h1">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 20, marginBottom: 20 }} >
                                <Typography variant="h4" style={{ fontSize: '24px' }}>
                                    Plugin not installed please first install plugin on website
                                </Typography>
                            </div>
                            <Button
                                style={{ display: 'block', margin: 'auto', fontSize: '18px', backgroundColor: 'rgb(31, 58, 147)' }}
                                variant="contained"
                                color="primary"
                                onClick={() => getPluginStatus2()}
                            >
                                Recheck
                            </Button>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50, marginBottom: 20 }} >
                                <Typography variant="h4" style={{ fontSize: '24px' }}>
                                    Follow the following steps to install plugin on website:
                                </Typography>
                            </div>
                            <div style={{ marginTop: 20, textAlign: 'center' }}>
                                <Typography variant="body1">
                                    {/* Step 1 */}
                                    1. Download the plugin file from the download button below
                                </Typography>
                                <Typography variant="body1">
                                    {/* Step 2 */}
                                    2. Go to the wordpress dashboard and go to the plugin section
                                </Typography>
                                <Typography variant="body1">
                                    {/* Step 3 */}
                                    3. Click on the button upload plugin
                                </Typography>
                                <Typography variant="body1">
                                    {/* Step 4 */}
                                    4. Select the downloaded file from your download folder
                                </Typography>
                                <Typography variant="body1">
                                    {/* Step 5 */}
                                    5. Click on install plugin
                                </Typography>
                                <Typography variant="body1">
                                    {/* Step 6 */}
                                    6. Click on activate plugin
                                </Typography>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50, marginBottom: 20 }} >
                                <Typography variant="h4" style={{ fontSize: '24px' }}>
                                    Click below button to download plugin
                                </Typography>
                            </div>
                            <Button
                                style={{ display: 'block', margin: 'auto', fontSize: '18px', backgroundColor: 'rgb(31, 58, 147)' }}
                                variant="contained"
                                color="primary"
                                onClick={() => downloadFileInNewTab()}
                            >
                                Download
                            </Button>
                        </CustomCardContainerHead>
                    )}
                </>
            )}
        </>
    );
}
