import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import loaderwave from "../assets/loader.svg";
import ArchivedBreachedDataTable from "../components/tables/securitytables/ArchivedBreachedDataTables";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { ignoreAccessibilityLinks } from "../store/actions/accessibilityTablesActions";

const SqlDetection = React.lazy(() =>
  import("../components/tables/securitytables/SqlDetection")
);
const XSSDetection = React.lazy(() =>
  import("../components/tables/securitytables/XSSDetection")
);
const LFIDetection = React.lazy(() =>
  import("../components/tables/securitytables/LFIDetection")
);

const SecurityArchivedIssuesTable = React.lazy(() =>
  import("../components/tables/securitytables/SecurityArchivedIssuesTable")
);
const SecurityCompletedIssuesTable = React.lazy(() =>
  import("../components/tables/securitytables/SecurityCompletedIssuesTable")
);
const SecurityIgnoredIssuesTable = React.lazy(() =>
  import("../components/tables/securitytables/SecurityIgnoredIssuesTable")
);

const SqlNewIssues = React.lazy(() =>
  import("../components/tables/securitytables/SqlNewIssues")
);
const XssNewIssues = React.lazy(() =>
  import("../components/tables/securitytables/XSSNewIssues")
);
const LFINewIssues = React.lazy(() =>
  import("../components/tables/securitytables/LFINewIssues")
);

const BreachedInfoTable = React.lazy(() =>
  import("../components/tables/securitytables/BreachedDataTables")
);

export default function SecurityReport() {
  const selectedScanId = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );

  const selectedScan = useSelector((state) => state.miscellaneous.selectedScan);
  const domainId = useSelector((state) => state.dashboard.selectedDomainId);
  const securityTabs = useSelector((state) => state.dashboard.tabsInfo?.cyber);
  const tabsLoading = useSelector((state) => state.dashboard.tabsInfoLoading);

  const scanProgress = useSelector((state) => state.onBoardDashboard.scanProgress);

  const isPackagePurchased = useSelector(
    (state) => state.dashboard.isDomainPackagePurchased
  );

  const [delayed, setDelayed] = React.useState(true);
  const [reportType, setReportType] = React.useState({
    sql: true,
    xss: false,
    lfi: false,
    breachedInfo: false,
    // exploits: false,
    others: false,
  });

  const [tables, setTables] = React.useState({
    sql: true,
    xss: true,
    lfi: true,
    // exploits: true,
    securityArchived: true,
    securityCompleted: true,
    securityIgnored: true,
    sqlNew: true,
    xssNew: true,
    lfiNew: true,
    breachedInfo: true,
  });

  React.useEffect(() => {
    if (selectedScanId) {
      setTables({
        sql: true,
        xss: true,
        lfi: true,
        // exploits: true,
        securityArchived: true,
        securityCompleted: true,
        securityIgnored: true,
        sqlNew: true,
        xssNew: true,
        lfiNew: true,
        breachedInfo: true,
      });
    }
  }, [selectedScanId, reportType.others]);

  React.useEffect(() => {
    setTimeout(() => {
      setDelayed(false);
    }, 2500);
  }, []);



  const CustomTab = withStyles((theme) => ({
    root: {
      minWidth: 40,
      opacity: 1,
      minHeight: "auto",
      borderRadius: "2px",
      padding: "0px 8px",
      border: "1px solid #1f3a93",
      color: "#1f3a93",
      fontWeight: 400,
      fontSize: "18px",
      borderRight: 0,
      "&:last-child": {
        borderRight: "1px solid #1f3a93",

      },
      "&:hover": {
        color: "#ffff!important",
        backgroundColor: "#1f3a93",
        opacity: 1,
      },
      "&$selected": {
        color: "white",
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: "#1f3a93",
      },
      "&:focus": {
        color: "white",
        backgroundColor: "#1f3a93",
      },
    },
    selected: {
      color: "white",
      backgroundColor: "#1f3a93",
      // display: 'none'
    },
  }))((props) => <Tab disableRipple {...props} />);


  function TableTabs() {
    return (
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        mb={2}
      >

        {securityTabs.sql && <CustomTab
          label="SQL"
          onClick={() => setReportType({ sql: true })}
          style={{
            backgroundColor: reportType.sql ? "#1f3a93" : "none",
            color: reportType.sql ? "#ffff" : "#1f3a93",
          }}
          tabIndex="0"
        />}

        {securityTabs.xss && <CustomTab
          label="XSS"
          onClick={() => setReportType({ xss: true })}
          style={{
            backgroundColor: reportType.xss ? "#1f3a93" : "none",
            color: reportType.xss ? "#ffff" : "#1f3a93",
          }}
          tabIndex="0"
        />}

        {securityTabs.lfi && <CustomTab
          label="LFI"
          onClick={() => setReportType({ lfi: true })}
          style={{
            backgroundColor: reportType.lfi ? "#1f3a93" : "none",
            color: reportType.lfi ? "#ffff" : "#1f3a93",
          }}
          tabIndex="0"
        />}

        {securityTabs.breachedInfo && <CustomTab
          label="Breached Info"
          onClick={() => setReportType({ breachedInfo: true })}
          style={{
            backgroundColor: reportType.breachedInfo
              ? "#1f3a93"
              : "none",
            color: reportType.breachedInfo ? "#ffff" : "#1f3a93",
          }}
          tabIndex="0"
        />}
        {isPackagePurchased && securityTabs.others && (
          <CustomTab
            label="others"
            onClick={() => setReportType({ others: true })}
            style={{
              backgroundColor: reportType.others ? "#1f3a93" : "none",
              color: reportType.others ? "#ffff" : "#1f3a93",
            }}
            tabIndex="0"
          />
        )}
        {/* </ButtonGroup> */}
      </Box>
    )
  }

  function ignorey(flag) {

  }

  return (
    <>
      <Suspense
        fallback={
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            tabIndex="0"
          >
            {" "}
            <img src={loaderwave} alt="loader" />
          </Box>
        }
      >
        {tabsLoading ?
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="70vh"
            tabIndex="0"
          >
            <img src={loaderwave} alt="loader" />
          </Box> :
          <>
            {selectedScan ? (
              <>
                {Object.values(securityTabs).includes(true) && <TableTabs />}
                <Box mb={3} data-tut="Security_Tables">

                  <>
                    {reportType.sql && securityTabs.sql && (
                      <>
                        {tables.sql || tables.sqlNew ? (
                          <>
                            <SqlDetection
                              link={null}
                              scanId={selectedScanId}
                              setTables={ignorey}
                              tables={tables}
                            />
                            <SqlNewIssues
                              link={null}
                              scanId={selectedScanId}
                              setTables={ignorey}
                              tables={tables}
                            />
                          </>
                        ) : (
                          <h3 align="center"> {scanProgress.status !== "completed" && "Currently, the scan is running. If an issue is discovered, the data will automatically populate here."}</h3>
                        )}
                      </>
                    )}
                    {reportType.xss && securityTabs.xss && (
                      <>
                        {tables.xss || tables.xssNew ? (
                          <>
                            <XSSDetection
                              link={null}
                              scanId={selectedScanId}
                              setTables={ignorey}
                              tables={tables}
                            />
                            <XssNewIssues
                              link={null}
                              scanId={selectedScanId}
                              setTables={ignorey}
                              tables={tables}
                            />
                          </>
                        ) : (
                          <h3 align="center"> {scanProgress.status !== "completed" && "Currently, the scan is running. If an issue is discovered, the data will automatically populate here."}</h3>
                        )}
                      </>
                    )}
                    {reportType.lfi && securityTabs.lfi && (
                      <>
                        {tables.lfi || tables.lfiNew ? (
                          <>
                            <LFIDetection
                              link={null}
                              scanId={selectedScanId}
                              setTables={ignorey}
                              tables={tables}
                            />
                            <LFINewIssues
                              link={null}
                              scanId={selectedScanId}
                              setTables={ignorey}
                              tables={tables}
                            />
                          </>
                        ) : (
                          <h3 align="center"> {scanProgress.status !== "completed" && "Currently, the scan is running. If an issue is discovered, the data will automatically populate here."}</h3>
                        )}
                      </>
                    )}
                    {reportType.breachedInfo && securityTabs.breachedInfo && (
                      <>
                        {tables.breachedInfo ? (
                          <>
                            <BreachedInfoTable domainId={domainId} />
                            <ArchivedBreachedDataTable domainId={domainId} />
                          </>
                        ) : (
                          <h3 align="center"> {scanProgress.status !== "completed" && "Currently, the scan is running. If an issue is discovered, the data will automatically populate here."}</h3>
                        )}
                      </>
                    )}
                    {reportType.others && securityTabs.others && (
                      <>
                        {tables.securityArchived ||
                          tables.securityCompleted ||
                          tables.securityIgnored ? (
                          <>
                            <SecurityArchivedIssuesTable
                              scanId={selectedScanId}
                              setTables={ignorey}
                              tables={tables}
                            />
                            <SecurityCompletedIssuesTable
                              scanId={selectedScanId}
                              setTables={ignorey}
                              tables={tables}
                            />
                            <SecurityIgnoredIssuesTable
                              scanId={selectedScanId}
                              setTables={ignorey}
                              tables={tables}
                            />
                          </>
                        ) : (
                          <h3 align="center"> {scanProgress.status !== "completed" && "Currently, the scan is running. If an issue is discovered, the data will automatically populate here."}</h3>
                        )}
                      </>
                    )}
                  </>
                  {!Object.values(securityTabs).includes(true) && <Box>
                    <h3 align="center"> {scanProgress.status !== "completed" && "Currently, the scan is running. If an issue is discovered, the data will automatically populate here."}</h3>
                  </Box>}
                </Box>
              </>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="70vh"
                tabIndex="0"
              >
                <img src={loaderwave} alt="loader" />
              </Box>
            )}
          </>
        }
      </Suspense>
    </>
  );
}
