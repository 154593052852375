import React from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import componentStyles from '../styles/stepper';

const useStyles = makeStyles(componentStyles);

const InfoBox = ({ item }) => {
    console.log('item: ', item);
    const classes = useStyles();

    return (
        <Card
            sx={{
                border: '1px solid #ced3d9',
                width: '80%',
                height: '250px'
            }}
        >
            <CardContent>
                <Box display="flex" alignItems="center">
                    <Box className={`${classes.centerItem} ${classes.infoIcon}`} sx={{ p: 0.5 }}>
                        {item.icon}
                    </Box>
                    <Typography className={classes.heading2} sx={{ ml: 2 }}>
                        {item.title}
                    </Typography>
                </Box>
                <Box mt={3}>
                    <Typography className={classes.text2}>{item.description}</Typography>
                </Box>
                {item.subDescription && (
                    <Box mt={1} sx={{ background: '#e2e9f1', padding: '10px' }}>
                        <Typography className={classes.text4}>{item.subDescription}</Typography>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default InfoBox;
