import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Slide } from "react-toastify";
import "react-circular-progressbar/dist/styles.css";
import "./Styles/css/App.css";
import axios from "axios";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { BASE_URL } from "./source/constants";
import { BrowserRouter } from "react-router-dom";
import MainApp from "./MainApp";
import CssBaseline from "@material-ui/core/CssBaseline";
import Cookies from "universal-cookie";
import InternalServerError from "./pages/InternalServerError";
import Loader from "./components/commons/Loader";
import { Link } from "react-router-dom";

import Logo from "./assets/logo.svg";


export default function App() {
  const [isConfigLoaded, setIsConfigLoaded] = React.useState(false);
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(true);

  //To get intial device width
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  const [openModal, setModal] = useState(false);

  //To check wheither device is allowed or not
  const checkingDeviceStatus = () => {
    if (deviceWidth < 768) {
      setModal(true);
    } else {
      setModal(false);
    }
  };

  //Re-render component on device width change
  window.addEventListener("resize", () => {
    setDeviceWidth(window.innerWidth);
    checkingDeviceStatus();
  });
  // console.log(`Width is ${deviceNotAllowed()}`);

  useEffect(() => {
    checkingDeviceStatus();
  }, []);

  const cookies = new Cookies();

  async function authConfig() {
    setLoading(true);
    try {
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      // eslint-disable-next-line
      let res = await axios.get(
        `${BASE_URL}/dashboard/source-config`,
        options
      );

      if (res.status === 200) {
        console.log(res.data);
        console.log(typeof res.data);

        function isEmptyObject(obj) {
          for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
              return false;
            }
          }
          return true;
        }


        if (!res.data || isEmptyObject(res.data)) {
          window.location.href = "/under-maintenance";
        } else {
          if (res.data) {
            if (!res.data.config) {
              window.location.href = "/under-maintenance";
            } else {
              var expiryTime = new Date().getTime() + 1000 * 60 * 60;
              const item = {
                value: res.data.config,
                expiry: expiryTime,
              };
              localStorage.setItem("authConfig", JSON.stringify(item));
              const getConfig = localStorage.getItem("authConfig");

              if (getConfig) {
                window.location.reload();
              }
            }
          }
        }
      } else {
        window.location.href = "/under-maintenance";
      }
    } catch (err) {
      setLoading(false);
      localStorage.removeItem("access_token");
      localStorage.removeItem("id_token");
      localStorage.removeItem("expires_at");
      localStorage.removeItem("auth0.ssodata");
      localStorage.removeItem("authConfig");
      cookies.remove("selectedDomain");
      cookies.remove("selectedDomainId");
    }
  }

  async function maintenance() {
    setLoading(true);
    try {
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      // eslint-disable-next-line
      const res = await axios.get(`${BASE_URL}/dashboard/maintenance`, options);
      if (res.status === 200) {
        // Check if the response is JSON
        if (res.headers['content-type'] && res.headers['content-type'].includes('application/json')) {
          // Assuming res.data should be a valid JSON if the content-type is 'application/json'
          // You can add more specific checks if needed
          if (!res.data || (typeof res.data === 'string' && res.data.trim() === '')) {
            window.location.href = "/under-maintenance";
          }
        } else {
          // Handle the case when the response is not JSON
          console.error("Response is not in JSON format");
          window.location.href = "/under-maintenance";
        }
      } else {
        window.location.href = "/under-maintenance";
      }

      // if (!res.data || (typeof res.data === 'string' && res.data.trim() === '')) {
      //   window.location.href = "/under-maintenance";
      // }

    } catch (err) {
      if (err.message === "Request failed with status code 503") {
        localStorage.removeItem("access_token");
        localStorage.removeItem("id_token");
        localStorage.removeItem("expires_at");
        localStorage.removeItem("auth0.ssodata");
        localStorage.removeItem("authConfig");
        localStorage.removeItem("scanId");

        cookies.remove("selectedDomain");
        cookies.remove("selectedDomainId");
        // window.location.href = "/under-maintenance";
      } else {
        localStorage.removeItem("access_token");
        localStorage.removeItem("id_token");
        localStorage.removeItem("expires_at");
        localStorage.removeItem("auth0.ssodata");
        localStorage.removeItem("authConfig");
        localStorage.removeItem("scanId");

        cookies.remove("selectedDomain");
        cookies.remove("selectedDomainId");
        // window.location.href = "/under-maintenance";
      }
    }
  }

  function checkUndefinedScanId() {
    const scanId = localStorage.getItem("scanId");
    const domain = localStorage.getItem("domain");

    if (scanId === "undefined" || domain === "undefined") {
      localStorage.removeItem("scanId");
      localStorage.removeItem("domain");
    }
  }

  React.useEffect(() => {
    if (
      window.location.pathname !== "/under-maintenance" 
      // &&
      // window.location.pathname !== "/not-found"
    ) {
      maintenance();
    }
    // checkUndefinedScanId();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   let interval;
  //   if (!isConfigLoaded && !loading) {
  //     interval = setInterval(() => {
  //       authConfig();
  //     }, 6000);
  //   }

  //   return () => clearInterval(interval);
  // }, [isConfigLoaded, loading]);

  React.useEffect(() => {
    const getConfig = localStorage.getItem("authConfig");
    const currentTime = new Date();
    if (getConfig) {
      const config = JSON.parse(getConfig);
      if (currentTime.getTime() > config.expiry) {
        authConfig();
      } else {
        setIsConfigLoaded(true);
        setLoading(false);
      }
    } else {
      authConfig();
    }
    // eslint-disable-next-line
  }, []);

  const THEME = createTheme({
    root: {
      color: "#000 !important",
    },
    typography: {
      fontFamily: "Segoe UI",
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      wordBreak: "break-all",
    },
    palette: {
      primary: {
        main: "#1F3A93",
        light: "#1f3a9336",
      },
      secondary: {
        main: "#1F3A93",
      },
      white: {
        main: "#ffffff",
      },
    },
    overrides: {
      MuiFormControlLabel: {
        label: {
          textTransform: "capitalize",
        },
      },

      MuiListItem: {
        root: {
          "&$disabled": {
            opacity: 1,
          },
        },
      },
      MuiInput: {
        input: {
          "&::placeholder": {
            color: "#181818",
            opacity: 1,
          },
          color: "black",
          MuiOutlinedInput: {
            padding: "10.5px 12px",
          },
        },
      },
      MuiCircularProgress: {
        colorPrimary: {
          color: "#1f3a93",
        },
      },
      MuiButton: {
        root: {
          borderRadius: 20,
        },
        containedPrimary: {
          backgroundColor: "#1f3a93",
        },
        containedSecondary: {
          backgroundColor: "#E2211E",
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          "&$checked": {
            color: "#1f3a93",
          },
        },
      },
      MuiInputLabel: {
        root: {
          color: "black",
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={THEME}>
      <BrowserRouter>
        {deviceWidth < 768 ? (
          <div
            style={{
              background: "#fff",
              display: "flex",
              padding: "30px",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <div
              style={{
                height: "20vh",
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "space-evenly",
                paddingTop: "30px",
                paddingBottom: "30px",
              }}
            >
              <Link
                to={`/`}
                tabIndex="0"
              >
                <img
                  src={Logo}
                  style={{
                    width: "200px",
                    height: "200px",
                  }}
                />
              </Link>
              <h4>Please switch to a larger screen. We currently do not support mobile view.</h4>
            </div>
          </div>
        ) : !loading ? (
          <>
            {isConfigLoaded ? (
              <>
                <CssBaseline />
                <MainApp />
              </>
            ) : (
              <InternalServerError />
            )}
          </>
        ) : (
          <Loader />
        )}
        <ToastContainer
          role="alert"
          limit={4}
          closeButton={true}
          autoClose={2000}
          position="top-right"
          transition={Slide}
          containerId="app"
          hideProgressBar={true}
        ></ToastContainer>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}
