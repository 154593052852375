import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ScanSelector from "../components/commons/ScanSelector";
import {
  getTechnologies,
  addDomainTechnology,
  deleteTechnology,
} from "../store/actions/technologiesActions";
import loaderwave from "../assets/loader.svg";
import ReactCreateable from "../components/commons/ReactCreateable";
import CVEExploitsWrap from "../components/tables/securitytables/CVEExploitsWrap";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { toast } from "react-toastify";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  chipsSpacing: {
    margin: "3px 6px 3px 0px",
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [addTech, setAddTech] = React.useState({
    name: "",
    version: "",
    website: "",
    icon: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState({
    inputValue: "",
    value: [],
  });
  const classes = useRowStyles();
  const dispatch = useDispatch();

  const selectedScanId = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );

  function handleCloseDialog() {
    setOpenDialog(false);
  }

  function addTechnology(e) {
    e.preventDefault();
    setLoading(true);

    const cValues =
      value.value && value.value.length > 0
        ? value.value.map((v) => {
          return { name: v.value };
        })
        : [];

    dispatch(deleteTechnology(row.name, selectedScanId)).then((data) => {
      if (data) {
        toast.success("Technology Deleted!");
        dispatch(
          addDomainTechnology({
            ...addTech,
            scanId: selectedScanId,
            categories: [...cValues],
          })
        ).then((data) => {
          if (data) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      }
    });
  }

  const styles = {
    textField: {
      width: "100%",
      margin: 10,
      maxWidth: 300,
    },
  };

  return (
    <React.Fragment style={{ overFlow: "hidden" }}>
      <div className={classes.root} hover>
        <div component="th" scope="row" style={{ width: "100%" }}>
          <Box display="flex" alignItems="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <KeyboardArrowUpIcon style={{ border: 0 }} />
              ) : (
                <KeyboardArrowDownIcon style={{ border: 0 }} />
              )}
            </IconButton>
            {row.icon ? (
              <img
                style={{ margin: "0px 10px" }}
                alt="icon"
                src={`data:image/png;base64,${row.icon}`}
                width="20px"
                height="20px"
              />
            ) : (
              <img
                style={{ margin: "0px 10px" }}
                alt="icon"
                width="20px"
                height="20px"
                src="https://img.icons8.com/external-kiranshastry-lineal-color-kiranshastry/64/000000/external-image-interface-kiranshastry-lineal-color-kiranshastry.png"
              />
            )}
            {row.name}
            <IconButton
              aria-label="edit technology"
              size="small"
              style={{ fontSize: "18px", color: "#1f3a93", marginLeft: "10px" }}
              onClick={() => {
                setOpen(false);
                setOpenDialog(true);
                setAddTech({
                  name: row.name,
                  version: row.version,
                  website: row.website,
                  icon: row.icon,
                });
                setValue({
                  value: row.categories.map((cat) => {
                    let values = null;
                    if (cat) {
                      values = { value: cat.name, label: cat.name };
                    }
                    return values;
                  }),
                });
              }}
            >
              edit
            </IconButton>
            <IconButton
              aria-label="delete technology"
              size="small"
              style={{ fontSize: "18px", color: "#e3211f" }}
              onClick={() => {
                setOpen(false);
                dispatch(deleteTechnology(row.name, selectedScanId));
              }}
            >
              {/* <DeleteForeverIcon style={{ color: "#e3211f" }} /> */}
              delete
            </IconButton>
          </Box>
        </div>
      </div>
      <div>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box mb={2}>
              {row.categories &&
                row.categories.map((category) => {
                  return category ? (
                    <Chip
                      className={classes.chipsSpacing}
                      label={category.name}
                      size="small"
                    />
                  ) : null;
                })}
              {row.website && (
                <Chip
                  component="a"
                  href={row.website}
                  target="_blank"
                  color="primary"

                  size="small"
                  label={`Visit ${row.website
                      .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
                      .split("/")[0]
                    }`}
                  className={classes.chipsSpacing}
                />
              )}{" "}
            </Box>
            <Box margin={1}>
              <Table
                size="small"
                aria-label="purchases"
              // style={{ tableLayout: "fixed" }}
              >
                <TableBody>
                  <TableRow key={row.name}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        fontWeight: "bold",
                        width: "140px",
                        fontSize: 14,
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                  </TableRow>

                  <TableRow key={row.version}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        fontWeight: "bold",
                        width: "140px",
                        fontSize: 14,
                      }}
                    >
                      Version
                    </TableCell>
                    <TableCell>
                      {row.version ? row.version : "Not Available"}
                    </TableCell>
                  </TableRow>
                  <TableRow key={row.confidence}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        fontWeight: "bold",
                        width: "140px",
                        fontSize: 14,
                      }}
                    >
                      Confidence
                    </TableCell>
                    <TableCell>
                      {row.confidence ? row.confidence : "Not Available"}
                    </TableCell>
                  </TableRow>
                  <TableRow key={row.baseSeverity}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        fontWeight: "bold",
                        width: "140px",
                        fontSize: 14,
                      }}
                    >
                      Base Severity
                    </TableCell>
                    <TableCell>{row.baseSeverity}</TableCell>
                  </TableRow>
                  <TableRow key={row.exploitabilitySeverity}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        fontWeight: "bold",
                        width: "185px",
                        fontSize: 14,
                      }}
                    >
                      Exploitability Severity
                    </TableCell>
                    <TableCell>{row.exploitabilitySeverity}</TableCell>
                  </TableRow>
                  <TableRow key={row.impactSeverity}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{
                        fontWeight: "bold",
                        width: "185px",
                        fontSize: 14,
                      }}
                    >
                      Impact Severity
                    </TableCell>
                    <TableCell>{row.impactSeverity}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </div>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Edit Technology</DialogTitle>
        <DialogContent>
          <form onSubmit={addTechnology}>
            <Box
              width="100%"
              display="inline-flex"
              alignItems="center"
              flexWrap="wrap"
            >
              <TextField
                id="version"
                style={styles.textField}
                required={true}
                value={addTech.name}
                onChange={(event) =>
                  setAddTech({ ...addTech, name: event.target.value })
                }
                type="text"
                label="Name"

              />
              <TextField
                id="version"
                style={styles.textField}
                value={addTech.version}
                onChange={(event) =>
                  setAddTech({ ...addTech, version: event.target.value })
                }
                type="text"
                label="version"

              />
              <TextField
                id="website-link"
                style={styles.textField}
                value={addTech.website}
                required={true}
                onChange={(event) =>
                  setAddTech({ ...addTech, website: event.target.value })
                }
                type="url"
                label="Website Link"

              />
              <TextField
                id="icon-link"
                style={styles.textField}
                value={addTech.icon}
                required={true}
                onChange={(event) =>
                  setAddTech({ ...addTech, icon: event.target.value })
                }
                label="Icon Link"

              />
              <ReactCreateable value={value} setValue={setValue} />
            </Box>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              style={{ margin: 10, borderRadius: 20 }}
            >
              {" "}
              Add
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function CollapsibleTable() {
  const dispatch = useDispatch();
  const selectedScanId = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );
  const isDomainPurchased = useSelector(
    (state) => state.dashboard.isDomainPackagePurchased
  );

  const data = useSelector((state) => state.technologies.domainTechnologies);
  const status = useSelector((state) => state.technologies.status);

  const [tableData, setTableData] = React.useState([]);
  const [addTech, setAddTech] = React.useState({
    name: "",
    version: "",
    website: "",
    icon: "",
  });

  const [loading, setLoading] = React.useState(false);

  const [delayed, setDelayed] = React.useState(true);

  const [value, setValue] = React.useState({
    inputValue: "",
    value: [],
  });

  const styles = {
    textField: {
      width: "100%",
      margin: 10,
      maxWidth: 300,
    },
  };

  React.useEffect(() => {
    if (selectedScanId) {
      dispatch(getTechnologies(selectedScanId, false));
    }
    // eslint-disable-next-line
  }, [selectedScanId]);

  React.useEffect(() => {
    if (selectedScanId && status === "pending") {
      const interval = setInterval(() => {
        dispatch(getTechnologies(selectedScanId, false));
      }, 5000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [selectedScanId, status]);

  React.useEffect(() => {
    if (data && data.length > 0) {
      setTableData(data);
    }
  }, [data]);

  React.useEffect(() => {
    setTimeout(() => {
      setDelayed(false);
    }, 1000);
  }, []);

  function addTechnology(e) {
    e.preventDefault();
    setLoading(true);

    const cValues = value.value.map((v) => {
      return { name: v.value };
    });
    dispatch(
      addDomainTechnology({
        ...addTech,
        scanId: selectedScanId,
        categories: [...cValues],
      })
    ).then((data) => {
      if (data) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }

  if (!isDomainPurchased) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        tabIndex="0"
      >
        <h3>Have no Access to this page</h3>
      </Box>
    );
  } else {
    return (
      <>
        {!delayed ? (
          <>
            <ScanSelector />
            <fieldset style={{ borderRadius: 9, marginBottom: "10px" }}>
              <legend
                style={{
                  fontFamily: "Segoe UI",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                {" "}
                Add Technology
              </legend>
              <form onSubmit={addTechnology}>
                <Box
                  width="100%"
                  display="inline-flex"
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <TextField
                    id="version"
                    style={styles.textField}
                    required={true}
                    onChange={(event) =>
                      setAddTech({ ...addTech, name: event.target.value })
                    }
                    type="text"
                    label="Name"

                  />
                  <TextField
                    id="version"
                    style={styles.textField}
                    onChange={(event) =>
                      setAddTech({ ...addTech, version: event.target.value })
                    }
                    type="text"
                    label="version"

                  />
                  <TextField
                    id="website-link"
                    style={styles.textField}
                    required={true}
                    onChange={(event) =>
                      setAddTech({ ...addTech, website: event.target.value })
                    }
                    type="url"
                    label="Website Link"

                  />
                  <TextField
                    id="icon-link"
                    style={styles.textField}
                    required={true}
                    onChange={(event) =>
                      setAddTech({ ...addTech, icon: event.target.value })
                    }
                    label="Icon Link"

                  />
                  <ReactCreateable value={value} setValue={setValue} />

                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    style={{ margin: 10, borderRadius: 20 }}
                  >
                    {" "}
                    Add
                  </Button>
                </Box>
              </form>
            </fieldset>

            <TableContainer component={Paper} style={{ marginBottom: "20px" }}>
              <Table
                aria-label="collapsible table"
                style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        width: "100%",
                        borderRight: "2px solid #1f3a93",
                      }}
                    >
                      Technologies
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.length > 0 ? (
                    <>
                      {" "}
                      <Grid
                        container
                        spacing={3}
                        style={{
                          maxHeight: "100%",
                          position: "sticky",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {tableData.map((row) => {
                          // return <Row key={row.name} row={row} />;
                          return (
                            <Grid item xs={12} lg={4} sm={6} md={6}>
                              <Row key={row.name} row={row} />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </>
                  ) : (
                    <TableRow>
                      <TableCell align="center">
                        <i>No Technologies Found</i>
                      </TableCell>{" "}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            tabIndex="0"
          >
            {" "}
            <img src={loaderwave} alt="loader" />
          </Box>
        )}
        <CVEExploitsWrap />
      </>
    );
  }
}
