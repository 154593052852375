import auth0Service from "../../services/auth0Service/authentikService";
import axios from "axios";
import { BASE_URL } from "../../source/constants";
import { toast } from "react-toastify";

export const GET_ALL_SHEET_STATISTICS = "GET_ALL_SHEET_STATISTICS";
export const GET_ALL_SHEET_STATISTICS_LOADING =
  "GET_ALL_SHEET_STATISTICS_LOADING";

export const GET_SHEET_STATISTICS = "GET_SHEET_STATISTICS";
export const GET_SHEET_STATISTICS_LOADING = "GET_SHEET_STATISTICS_LOADING";

export const GET_ACCESSIBILITY_SHEET = "GET_ACCESSIBILITY_SHEET";
export const GET_PERFORMANCE_SHEET = "GET_PERFORMANCE_SHEET";
export const GET_PRIVACY_SHEET = "GET_PRIVACY_SHEET";
export const GET_SECURITY_SHEET = "GET_SECURITY_SHEET";

export const ADD_ACCESSIBILITY_SHEET_DATA = "ADD_ACCESSIBILITY_SHEET_DATA";
export const ADD_PERFORMANCE_SHEET_DATA = "ADD_PERFORMANCE_SHEET_DATA";
export const ADD_PRIVACY_SHEET_DATA = "ADD_PRIVACY_SHEET_DATA";
export const ADD_SECURITY_SHEET_DATA = "ADD_SECURITY_SHEET_DATA";

export const GET_ACCESSIBILITY_SHEET_LOADING =
  "GET_ACCESSIBILITY_SHEET_LOADING";
export const GET_PERFORMANCE_SHEET_LOADING = "GET_PERFORMANCE_SHEET_LOADING";
export const GET_PRIVACY_SHEET_LOADING = "GET_PRIVACY_SHEET_LOADING";
export const GET_SECURITY_SHEET_LOADING = "GET_SECURITY_SHEET_LOADING";

export const ADD_ACCESSIBILITY_SHEET_DATA_LOADING =
  "ADD_ACCESSIBILITY_SHEET_DATA_LOADING";
export const ADD_PERFORMANCE_SHEET_DATA_LOADING =
  "ADD_PERFORMANCE_SHEET_DATA_LOADING";
export const ADD_PRIVACY_SHEET_DATA_LOADING = "ADD_PRIVACY_SHEET_DATA_LOADING";
export const ADD_SECURITY_SHEET_DATA_LOADING =
  "ADD_SECURITY_SHEET_DATA_LOADING";

export const getAllSheetsStats = (domainId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_SHEET_STATISTICS_LOADING,
    });
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      domainId,
    };
    const res = await axios.get(
      `${BASE_URL}/issue-tracker-23/issue-tracker-analytics`,
      {
        params,
        ...options,
      }
    );

    if (res.data) {
      dispatch({
        type: GET_ALL_SHEET_STATISTICS,
        payload: res.data.result,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    dispatch({
      type: GET_ALL_SHEET_STATISTICS_LOADING,
    });
  }
};

export const getAccessibilitySheet = (domainId, type) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ACCESSIBILITY_SHEET_LOADING,
    });
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      domainId,
      type,
    };
    const res = await axios.get(`${BASE_URL}/issue-tracker-23/issue-tracker`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_SHEET_STATISTICS,
        payload: res.data.result[0].analytics,
      });
      dispatch({
        type: GET_ACCESSIBILITY_SHEET,
        payload: res.data.result[0],
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    dispatch({
      type: GET_ACCESSIBILITY_SHEET_LOADING,
    });
  }
};
export const getPerformanceSheet = (domainId, type) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    dispatch({
      type: GET_PERFORMANCE_SHEET_LOADING,
    });
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      domainId,
      type,
    };
    const res = await axios.get(`${BASE_URL}/issue-tracker-23/issue-tracker`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_SHEET_STATISTICS,
        payload: res.data.result[0].analytics,
      });
      dispatch({
        type: GET_PERFORMANCE_SHEET,
        payload: res.data.result[0],
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    dispatch({
      type: GET_PERFORMANCE_SHEET_LOADING,
    });
  }
};
export const getPrivacySheet = (domainId, type) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    dispatch({
      type: GET_PRIVACY_SHEET_LOADING,
    });
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      domainId,
      type,
    };
    const res = await axios.get(`${BASE_URL}/issue-tracker-23/issue-tracker`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_SHEET_STATISTICS,
        payload: res.data.result[0].analytics,
      });
      dispatch({
        type: GET_PRIVACY_SHEET,
        payload: res.data.result[0],
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    dispatch({
      type: GET_PRIVACY_SHEET_LOADING,
    });
  }
};
export const getSecuritySheet = (domainId, type) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    dispatch({
      type: GET_SECURITY_SHEET_LOADING,
    });
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      domainId,
      type,
    };
    const res = await axios.get(`${BASE_URL}/issue-tracker-23/issue-tracker`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_SHEET_STATISTICS,
        payload: res.data.result[0].analytics,
      });
      dispatch({
        type: GET_SECURITY_SHEET,
        payload: res.data.result[0],
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    dispatch({
      type: GET_SECURITY_SHEET_LOADING,
    });
  }
};

export const addSheetData = (postData, scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    switch (postData.tableData.type) {
      case "security":
        dispatch({
          type: ADD_SECURITY_SHEET_DATA_LOADING,
        });
        break;
      case "accessibility":
        dispatch({
          type: ADD_ACCESSIBILITY_SHEET_DATA_LOADING,
        });
        break;
      case "privacy":
        dispatch({
          type: ADD_PRIVACY_SHEET_DATA_LOADING,
        });
        break;
      case "performance":
        dispatch({
          type: ADD_PERFORMANCE_SHEET_DATA_LOADING,
        });
        break;
    }
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(
      `${BASE_URL}/issue-tracker-23/issue-tracker`,
      {
        ...postData,
      },
      options
    );

    if (res.status === 200) {
      switch (postData.tableData.type) {
        case "security":
          dispatch({
            type: ADD_SECURITY_SHEET_DATA_LOADING,
          });
          break;
        case "accessibility":
          dispatch({
            type: ADD_ACCESSIBILITY_SHEET_DATA_LOADING,
          });
          break;
        case "privacy":
          dispatch({
            type: ADD_PRIVACY_SHEET_DATA_LOADING,
          });
          break;
        case "performance":
          dispatch({
            type: ADD_PERFORMANCE_SHEET_DATA_LOADING,
          });
          break;
      }
      toast.success(res.data.message);
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    // toast.error(err.response.data.message);
    console.log("error:: ", err);
    switch (postData.tableData.type) {
      case "security":
        dispatch({
          type: ADD_SECURITY_SHEET_DATA_LOADING,
        });
        break;
      case "accessibility":
        dispatch({
          type: ADD_ACCESSIBILITY_SHEET_DATA_LOADING,
        });
        break;
      case "privacy":
        dispatch({
          type: ADD_PRIVACY_SHEET_DATA_LOADING,
        });
        break;
      case "performance":
        dispatch({
          type: ADD_PERFORMANCE_SHEET_DATA_LOADING,
        });
        break;
    }
  }
};

export const addAccessibilitySheetData =
  (postData, scanId) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      dispatch({
        type: ADD_ACCESSIBILITY_SHEET_DATA_LOADING,
      });
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      const res = await axios.post(
        `${BASE_URL}/`,
        {
          ...postData,
        },
        options
      );

      if (res.status === 200) {
        dispatch({
          type: ADD_ACCESSIBILITY_SHEET_DATA_LOADING,
        });
        toast.success(res.data.message);
        //   dispatch(getFilesCompletedIssues(scanId))
        //   dispatch(getFilesIssues(scanId))
      }
    } catch (err) {
      if (!err.response) {
        toast.warn("Warning ⚠️ bad internet connection.");
      }
      toast.error(err.response.data.message);
      dispatch({
        type: ADD_ACCESSIBILITY_SHEET_DATA_LOADING,
      });
    }
  };

export const addPerformanceSheetData =
  (postData, scanId) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      dispatch({
        type: ADD_PERFORMANCE_SHEET_DATA_LOADING,
      });
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      const res = await axios.post(
        `${BASE_URL}/`,
        {
          ...postData,
        },
        options
      );

      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch({
          type: ADD_PERFORMANCE_SHEET_DATA_LOADING,
        });
        //   dispatch(getFilesCompletedIssues(scanId))
        //   dispatch(getFilesIssues(scanId))
      }
    } catch (err) {
      if (!err.response) {
        toast.warn("Warning ⚠️ bad internet connection.");
      }
      toast.error(err.response.data.message);
      dispatch({
        type: ADD_PERFORMANCE_SHEET_DATA_LOADING,
      });
    }
  };

export const addPrivacySheetData = (postData, scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    dispatch({
      type: ADD_PRIVACY_SHEET_DATA_LOADING,
    });
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(
      `${BASE_URL}/`,
      {
        ...postData,
      },
      options
    );

    if (res.status === 200) {
      toast.success(res.data.message);
      //   dispatch(getFilesCompletedIssues(scanId))
      //   dispatch(getFilesIssues(scanId))
      dispatch({
        type: ADD_PRIVACY_SHEET_DATA_LOADING,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    toast.error(err.response.data.message);
    dispatch({
      type: ADD_PRIVACY_SHEET_DATA_LOADING,
    });
  }
};

export const addSecuritySheetData = (postData, scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    dispatch({
      type: ADD_SECURITY_SHEET_DATA_LOADING,
    });

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(
      `${BASE_URL}/`,
      {
        ...postData,
      },
      options
    );

    if (res.status === 200) {
      toast.success(res.data.message);
      //   dispatch(getFilesCompletedIssues(scanId))
      //   dispatch(getFilesIssues(scanId))
      dispatch({
        type: ADD_SECURITY_SHEET_DATA_LOADING,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    toast.error(err.response.data.message);
    dispatch({
      type: ADD_SECURITY_SHEET_DATA_LOADING,
    });
  }
};
