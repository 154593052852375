import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Box, Chip, Divider, Typography } from "@material-ui/core";
// import { Help } from "@material-ui/icons";
import { useParams } from "react-router-dom";

import Sidebar from "./SideList";
import IssueComponent from "./IssueComponent";
import Comments from "./Comments";
import {
  getIssue,
  updateIssue,
} from "../../../store/actions/issueTrackerActions";
import Modal from "../EditModal/Modal";
import IssueForm from "../forms/IssueForm";
import { getDate } from "../../../source/functions";
import SubLoader from "../../../components/commons/SubLoader";

const useStyles = makeStyles((theme) => ({
  page: {
    width: "calc(100% - 300px)",
    padding: "10px",
  },
  btn: {
    border: "2px solid black",
    borderStyle: "dashed",
    justifyContent: "center",
    margin: "auto",
  },

  iconBtn: {
    marginLeft: "10px",
    border: "2px solid gray",
    borderRadius: "0px",
    padding: "5px",
    width: "60px",
  },
  drawer: {
    width: 280,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 280,
      boxSizing: "border-box",
    },
  },

  barhaed: {
    display: "flex",
    justifyContent: "space-between",
  },
  btnf: {
    fontSize: " 14px",
  },
}));

const Sidelist = (issue) => {
  let date = getDate(issue.due_date);
  return [
    {
      label: "Assigne",
      data: (
        <Box>
          <Typography>{issue.username}</Typography>
        </Box>
      ),
    },
    {
      label: "Mile Stone",
      data: (
        <Box>
          <Typography>{issue.milestone ? issue.milestone : "None"}</Typography>
        </Box>
      ),
    },
    // {
    //   label: "Time Tracking",
    //   // btn: (
    //   //   <IconButton style={{ fontSize: "14px" }}>
    //   //     <Help />
    //   //   </IconButton>
    //   // ),
    //  data: (
    //     <Box>
    //       <Typography>{issue.username}</Typography>
    //     </Box>
    //   ),
    // },
    {
      label: "Due date",
      data: (
        <Box>
          <Typography>
            {date}
          </Typography>
        </Box>
      ),
    },
    {
      label: "Label",
      data: (
        <Box>
          {issue.labels && issue.labels.length > 0 ? (
            issue.labels.map((label) => {
              return <Chip style={{ background: label.color, color: 'white', marginRight: 5 }} label={label.name} />
            })
          ) : (
            <>None</>
          )}
        </Box>
      ),
    },
  ];
};

const IssueView = () => {
  const issue = useSelector((state) => state.issueTracker.issue);
  const { selectedDomainId } = useSelector((state) => state.dashboard);
  const [open, setOpen] = React.useState(false);

  let { issueId } = useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (issueId && selectedDomainId) {
      dispatch(getIssue(selectedDomainId, issueId));
    }
  }, [issueId, selectedDomainId]);

  const classes = useStyles();
  return (
    <Box>
      {issue ? (
        <>
          <Sidebar list={Sidelist(issue)} />
          <Box className={classes.page}>
            <IssueComponent title={issue.title} issue={issue} open={open} setOpen={setOpen} />
            <Divider />
            <Box mt={3}>
              <Typography style={{ fontWeight: "bold" }}>Comments</Typography>
              <Comments
                comments={issue.comments}
                issueId={issueId}
                domainId={selectedDomainId}
              />
            </Box>
          </Box>
        </>
      ) :
        <SubLoader />
      }
      <Modal open={open} setOpen={setOpen} title={"Issue"}>
        <IssueForm btnTitle={"Update"} issue={issue} fun={updateIssue} />
      </Modal>
    </Box>
  );
};

export default IssueView;
