import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Button, Card, CircularProgress } from '@material-ui/core';
import { useDispatch } from "react-redux"
import { getAllFreeDomains, deleteFreeDomain } from "../store/actions/adminScansManagementActions";

export default function FreeDomains() {

    const [loadingDomains, setLoadingDomains] = React.useState(true)
    const [data, setData] = React.useState([])

    const [addDomainId, setAddDomainId] = React.useState(null)
    const [removeLoading, setRemoveLoading] = React.useState(false)

    const dispatch = useDispatch();

    function getDomains() {
        setLoadingDomains(true)
        dispatch(getAllFreeDomains()).then(data => {
            if (data) {
                setLoadingDomains(false);
                setData(data.result)
            } else {
                setLoadingDomains(false)

            }
        }).catch(err => {
            setLoadingDomains(false)
        })
    }

    React.useEffect(() => {
        getDomains()
    }, [])

    function handleRemoveFree(id) {
        setAddDomainId(id)
        setRemoveLoading(true)
        dispatch(deleteFreeDomain({
            domainId: id,
        })).then(data => {
            if (data) {
                setRemoveLoading(false);
                getDomains()
                setAddDomainId(null)
            } else {
                setRemoveLoading(false)
                setAddDomainId(null)

            }
        }).catch(err => {
            setRemoveLoading(false)
            setAddDomainId(null)
        })
    }

    return (
        <>
            <TableContainer component={Paper}>

                <Table aria-label="simple table" style={{ tableLayout: 'fixed' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Domain</TableCell>
                            <TableCell >Full Domain</TableCell>
                            <TableCell >ID</TableCell>
                            <TableCell >Action</TableCell>

                        </TableRow>
                    </TableHead>
                    {!loadingDomains ? <TableBody>
                        {data.length > 0 ? data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row.domain}
                                </TableCell>
                                <TableCell style={{ wordBreak: 'break-all' }} >{row.fullDomain}</TableCell>
                                <TableCell style={{ wordBreak: 'break-all' }} >{row._id}</TableCell>
                                <TableCell style={{ wordBreak: 'break-all' }} ><Button
                                    onClick={() => handleRemoveFree(row._id)} disabled={removeLoading}
                                    color='secondary' variant='contained'>Remove
                                    {row._id === addDomainId && <CircularProgress style={{ marginLeft: 5 }} size={14} />}
                                </Button></TableCell>
                            </TableRow>
                        )) :
                            <TableRow><TableCell colSpan={4}>no records found</TableCell></TableRow>
                        }
                    </TableBody> :
                        <TableBody>
                            <TableRow><TableCell align='center' colSpan={4}>
                                <CircularProgress />
                            </TableCell></TableRow>
                        </TableBody>
                    }
                </Table>
            </TableContainer>
        </>
    )
}