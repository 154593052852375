import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getTechnologies } from "../../../store/actions/technologiesActions";

import ArchivedCveExploits from "./ArchivedCveExploits";
import CveExploits from "./CveExploits";
import DismissedCveExploits from "./DismissedCveExploits";

export default function CVEExploitsWrap() {
  const dispatch = useDispatch();
  const scanId = useSelector((state) => state.miscellaneous.selectedScanId);

  const [expTechs, setExpTechs] = React.useState([]);

  const [archivedPresent, setArchivePresence] = useState(true);
  const [dismissedPresent, setDismissedPresence] = useState(true);

  React.useEffect(() => {
    if (scanId) {
      dispatch(getTechnologies(scanId, true)).then((data) => {
        if (data) {
          setExpTechs(data.result);
        }
      });
    }
  }, [scanId]);

  return (
    <>
      {expTechs &&
        expTechs.length > 0 &&
        expTechs.map((tech) => {
          return <CveExploits scanId={scanId} tech={tech} />;
        })}
      {archivedPresent && (
        <ArchivedCveExploits
          scanId={scanId}
          tech="archived"
          changer={setArchivePresence}
        />
      )}

      {dismissedPresent && (
        <DismissedCveExploits
          scanId={scanId}
          tech="dismissed"
          changer={setDismissedPresence}
        />
      )}
    </>
  );
}
