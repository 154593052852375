
import auth0Service from "../../services/auth0Service/authentikService";
import axios from "axios";
import { BASE_URL } from "../../source/constants";
import { toast } from "react-toastify";
import history from "../../@history";
import Cookies from "universal-cookie";

import { REMAINING_WEBSITES } from "../../auth/store/actions/user.actions";

export const ADD_DOMAIN = "ADD_DOMAIN";
export const AFTER_ADD_DOMAIN = "AFTER_ADD_DOMAIN";
export const ADD_DOMAIN_LOADING = "ADD_DOMAIN_LOADING";
export const ADD_DOMAIN_SUCCESS = "ADD_DOMAIN_SUCCESS";
export const GET_DOMAINS = "GET_DOMAINS";
export const GET_DOMAINS_LOADING = "GET_DOMAINS_LOADING";

export const GET_DOMAINS_LINKS = "GET_DOMAINS_LINKS";
export const SET_DOMAIN = "SET_DOMAIN";
export const SET_UNIFIED = "SET_UNIFIED";
export const GET_DOMAIN_SUMMARY = "GET_DOMAIN_SUMMARY";
export const GET_DOMAIN_SUMMARY_LOADING = "GET_DOMAIN_SUMMARY_LOADING";
export const RESET_DOMAIN_SUMMARY = "RESET_DOMAIN_SUMMARY";


export const GET_DOMAINPAGES_SUMMARY = "GET_DOMAINPAGES_SUMMARY";
export const RESET_DOMAINPAGES_SUMMARY = "RESET_DOMAINPAGES_SUMMARY";

export const GET_PAGES_BEING_ANALYZED = "GET_PAGES_BEING_ANALYZED";
export const GET_PAGES_BEING_ANALYZED_LOADING =
  "GET_PAGES_BEING_ANALYZED_LOADING";

export const GET_DOMAIN_GRADES = "GET_DOMAIN_GRADES";
export const RESET_GRADES = "RESET_GRADES";

export const GET_ACCESSIBILITY_TABLE_MAIN = "GET_ACCESSIBILITY_TABLE_MAIN";
export const RESET_ACCESSIBILITY_TABLE_MAIN = "RESET_ACCESSIBILITY_TABLE_MAIN";

export const GET_ACCESSIBILITY_TABLE_ONE = "GET_ACCESSIBILITY_TABLE_ONE";
export const GET_ACCESSIBILITY_TABLE_ONE_RESET =
  "GET_ACCESSIBILITY_TABLE_ONE_RESET";

export const GET_ACCESSIBILITY_TABLE_TWO = "GET_ACCESSIBILITY_TABLE_TWO";
export const GET_TOP_TEN_CRITERIA_TABLE = "GET_TOP_TEN_CRITERIA_TABLE";
export const GET_TOP_TEN_CRITERIA_TABLE_RESET =
  "GET_TOP_TEN_CRITERIA_TABLE_RESET";

export const GET_TOP_TEN_VOILATION_TABLE = "GET_TOP_TEN_VOILATION_TABLE";
export const GET_TOP_TEN_VOILATION_TABLE_RESET =
  "GET_TOP_TEN_VOILATION_TABLE_RESET";

export const GET_PRIORITY_ACCESSIBILITY_GRAPH =
  "GET_PRIORITY_ACCESSIBILITY_GRAPH";
export const PRIORITY_ACCESSIBILITY_BAR_GRAPH_LOADING =
  "PRIORITY_ACCESSIBILITY_BAR_GRAPH_LOADING";

export const HTML_CSS_TABLE_LOADING = "HTML_CSS_TABLE_LOADING";
export const GET_HTML_CSS_TABLE = "GET_HTML_CSS_TABLE";
export const GET_HTML_CSS_TABLE_RESET = "GET_HTML_CSS_TABLE_RESET";

export const HTML_TABLE_LOADING = "HTML_TABLE_LOADING";
export const GET_HTML_TABLE = "GET_HTML_TABLE";
export const GET_HTML_TABLE_RESET = "GET_HTML_TABLE_RESET";

export const CSS_TABLE_LOADING = "CSS_TABLE_LOADING";
export const GET_CSS_TABLE = "GET_CSS_TABLE";
export const GET_CSS_TABLE_RESET = "GET_CSS_TABLE_RESET";

export const NEW_HTML_TABLE_LOADING = "NEW_HTML_TABLE_LOADING";
export const GET_NEW_HTML_TABLE = "GET_NEW_HTML_TABLE";

export const NEW_CSS_TABLE_LOADING = "NEW_CSS_TABLE_LOADING";
export const GET_NEW_CSS_TABLE = "GET_NEW_CSS_TABLE";

export const GET_TABS_INFO = "GET_TABS_INFO";
export const SET_TABS_INFO_LOADING = "SET_TABS_INFO_LOADING";

export const TOUR = "TOUR";
export const OPEN_DOMAIN_MODAL = "OPEN_DOMAIN_MODAL";
export const SET_SCAN = "SET_SCAN";
export const SET_CODE_SUMMARY = "SET_CODE_SUMMARY";

export const SET_SCAN_PROGRESS = "SET_SCAN_PROGRESS";

export const CONFIG_LOADED = "CONFIG_LOADED";

export const SCREENSHOT = "SCREENSHOT";

export const LOGOUT_MODAL = "LOGOUT_MODAL";


const cookies = new Cookies();

export const addDomain = (domain, email, demo) => async (dispatch) => {
  dispatch({
    type: ADD_DOMAIN_LOADING,
  });

  try {
    const token = auth0Service.getAccessToken();
    console.log(token);

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(
      `${BASE_URL}/sitemap/init`,
      {
        domain: domain,
        email: email,
        demo
      },        options,
      {

        timeout: 60000,
      }
    );

    console.log(res.data);

    if (res.status === 200) {

      if (res.data.message.message === "scan already scheduled") {
        toast.warn("Domain is aleardy added");

        dispatch({
          type: ADD_DOMAIN_LOADING,
        });

      } else {
        dispatch({
          type: ADD_DOMAIN_LOADING,
        });

        dispatch({
          type: REMAINING_WEBSITES,
        });
        let time = new Date();
        time.setTime(time.getTime() + 120 * 60 * 1000);
        cookies.set("selectedDomain", domain, {
          path: "/",
          expires: time,
        });
        toast.success("Your Domain was added Successfully");
        history.push({
          pathname: `/dashboard`,
          search: `?domain=${res.data.domain}&scanId=${res.data.scanId}`,
        });
        dispatch({
          type: ADD_DOMAIN_SUCCESS,
          payload: res.data.domain,
        });
      }
      return res.data
    }
  } catch (err) {
    console.log(err)
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    dispatch({
      type: ADD_DOMAIN_LOADING,
    });

    toast.error(err.response.data.message);
  }
};

// export const addDomain = (addDomainOptions) => async (dispatch) => {
//   dispatch({
//     type: ADD_DOMAIN_LOADING,
//   });

//   try {
//     const token = auth0Service.getAccessToken();

//     const options = {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };
//     if (token) {
//       options.headers = {
//         ...options.headers,
//         Authorization: `Bearer ${token}`,
//       };
//     }

//     const res = await axios.post(
//       `${BASE_URL}/sitemap/init`,
//       {
//         ...addDomainOptions
//       },
//       options
//     );

//     if (res.status === 200) {
//       if (res.data.message.message === "scan already scheduled") {
//         toast.warn("Domain is aleardy added");

//         dispatch({
//           type: ADD_DOMAIN_LOADING,
//         });
//       } else {
//         dispatch({
//           type: ADD_DOMAIN_LOADING,
//         });

//         dispatch({
//           type: REMAINING_WEBSITES,
//         });
//         toast.success("Your Domain was added Successfully");
//         dispatch({
//           type: ADD_DOMAIN_SUCCESS,
//           payload: res.data.domain,
//         });
//       }
//     }
//   } catch (err) {
//     if (!err.response) {
//       toast.warn('Warning ⚠️ bad internet connection.');
//     }
//     dispatch({
//       type: ADD_DOMAIN_LOADING,
//     });

//     toast.error(err.response.data.message);
//   }
// };

export const setUnified = (flag) => async (dispatch) => {

  dispatch({
    type: SET_UNIFIED,
    payload: flag
  })
};

export const getDomains = () => async (dispatch) => {
  dispatch({
    type: GET_DOMAINS_LOADING
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.get(`${BASE_URL}/dashboard/domains`, options);

    if (res.data) {

      dispatch({
        type: GET_DOMAINS,
        payload: res.data.data,
      })
      dispatch({
        type: GET_DOMAINS_LOADING
      })
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};

export const getDomainLinkss = (domainId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      domainId,
    };
    const res = await axios.get(`${BASE_URL}/dashboard/domain-links`, {
      params,
      ...options,
    });

    if (res.data) {
      ;
      dispatch({
        type: GET_DOMAINS_LINKS,
        payload: res.data.links,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};

export const setDomain = (selectedDomain, selectedDomainId, isPackagePurchased, selectedPackage) => async (
  dispatch
) => {
  console.log({ selectedDomain, selectedDomainId, isPackagePurchased, selectedPackage })
  dispatch({
    type: SET_DOMAIN,
    payload: { selectedDomain, selectedDomainId, isPackagePurchased, selectedPackage },
  });
};

export const getDomainSummary = (scanId, unified) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    dispatch({
      type: RESET_DOMAIN_SUMMARY,
    });
    dispatch({
      type: GET_DOMAIN_SUMMARY_LOADING,
    });

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
      unified
    };

    const res = await axios.get(`${BASE_URL}/dashboard/domain-summary`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_DOMAIN_SUMMARY,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};

export const getDomainPagesSummary = (scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    // dispatch({
    //   type: RESET_DOMAINPAGES_SUMMARY,
    // });

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    };
    const res = await axios.get(`${BASE_URL}/dashboard/domain-pages-summary`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_DOMAINPAGES_SUMMARY,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }

};

export const getScreenShot = (scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();



    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    };
    const res = await axios.get(`${BASE_URL}/dashboard/domain-screenshot`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: SCREENSHOT,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    else if (err.response.data.message === "Scan Not Found") {
      // localStorage.removeItem("scanId");
      // localStorage.removeItem("domain");
      // window.history.replaceState({}, "", "/");
      // dispatch(setScanData("", "", ""));
    }
  }
};

export const getPagesBeingAnalyzed = (scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    dispatch({
      type: GET_PAGES_BEING_ANALYZED_LOADING,
    });

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    };
    const res = await axios.get(`${BASE_URL}/dashboard/links-analyzed`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_PAGES_BEING_ANALYZED,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};

export const getDomainGrades = (scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    };
    dispatch({
      type: RESET_GRADES,
    });

    const res = await axios.get(`${BASE_URL}/dashboard/domain-scores`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_DOMAIN_GRADES,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};

export const getTableAccessibilityTableMain = (scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
      detailed: true,
    };

    dispatch({
      type: RESET_ACCESSIBILITY_TABLE_MAIN,
    });

    const res = await axios.get(`${BASE_URL}/dashboard/accessibility-main`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_ACCESSIBILITY_TABLE_MAIN,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};

export const getTableAccessibilityTableOne = (scanId, page, links) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
      detailed: true,
      page,
      limit: 7,
      urls: links,
    };
    const res = await axios.get(`${BASE_URL}/dashboard/accessibility-table1`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_ACCESSIBILITY_TABLE_ONE,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};

export const getTableAccessibilityTableTwo = (links) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      urls: links,
      detailed: true,
    };
    const res = await axios.get(`${BASE_URL}/dashboard/accessibility-table2`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_ACCESSIBILITY_TABLE_TWO,
        payload: res.data.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};

export const getTopTenCriteriaTable = (scanId, links) => async (dispatch) => {
  dispatch({
    type: GET_TOP_TEN_CRITERIA_TABLE_RESET,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
      links,
    };
    const res = await axios.get(`${BASE_URL}/dashboard/top-ten-criterias`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_TOP_TEN_CRITERIA_TABLE,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};

export const getTopTenVoilationTable = (scanId, links) => async (dispatch) => {
  dispatch({
    type: GET_TOP_TEN_VOILATION_TABLE_RESET,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
      links,
    };
    const res = await axios.get(`${BASE_URL}/dashboard/top-ten-voilations`, {
      params,
      ...options,
    });

    if (res.data.data.summaries) {
      dispatch({
        type: GET_TOP_TEN_VOILATION_TABLE,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};

export const getHtmlCssTable = (dateNowRange, scanId, links) => async (
  dispatch
) => {
  dispatch({
    type: HTML_CSS_TABLE_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
      dateNowRange,

      // urls: links
    };
    const res = await axios.get(`${BASE_URL}/dashboard/html-css-table`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_HTML_CSS_TABLE,
        payload: res.data,
      });
      dispatch({
        type: HTML_CSS_TABLE_LOADING,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    dispatch({
      type: GET_HTML_CSS_TABLE,
      payload: { graphData: null },
    });
    dispatch({
      type: HTML_CSS_TABLE_LOADING,
    });
  }
};

export const getHtmlTable = (scanId, links) => async (dispatch) => {
  dispatch({
    type: HTML_TABLE_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
      urls: links,
    };
    const res = await axios.get(`${BASE_URL}/dashboard/html-table`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_HTML_TABLE,
        payload: res.data,
      });
      // dispatch({
      //   type: HTML_TABLE_LOADING,
      // });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    dispatch({
      type: GET_HTML_TABLE,
      payload: { graphData: null },
    });
    dispatch({
      type: HTML_TABLE_LOADING,
    });
  }
};

export const getNewHtmlTable = (scanId, links) => async (dispatch) => {
  dispatch({
    type: NEW_HTML_TABLE_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
      urls: links,
    };
    const res = await axios.get(`${BASE_URL}/dashboard/new-html-issues`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_NEW_HTML_TABLE,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    dispatch({
      type: GET_NEW_HTML_TABLE,
      payload: { graphData: null },
    });

  }
};

export const getCssTable = (scanId, links) => async (dispatch) => {
  dispatch({
    type: CSS_TABLE_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
      urls: links,
    };
    const res = await axios.get(`${BASE_URL}/dashboard/css-table`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_CSS_TABLE,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    dispatch({
      type: GET_CSS_TABLE,
      payload: { graphData: null },
    });
    dispatch({
      type: CSS_TABLE_LOADING,
    });
  }
};

export const getNewCssTable = (scanId, links) => async (dispatch) => {
  dispatch({
    type: NEW_CSS_TABLE_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
      urls: links,
    };
    const res = await axios.get(`${BASE_URL}/dashboard/new-css-issues`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_NEW_CSS_TABLE,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    dispatch({
      type: GET_NEW_CSS_TABLE,
      payload: { graphData: null },
    });

  }
};

export const getpriorityAccessiblityGraph = (scanId, dateNowRange) => async (
  dispatch
) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
      dateNowRange,
    };
    dispatch({
      type: PRIORITY_ACCESSIBILITY_BAR_GRAPH_LOADING,
    });
    const res = await axios.get(
      `${BASE_URL}/dashboard/accessibility-priority-chart`,
      {
        params,
        ...options,
      }
    );

    if (res.data) {
      dispatch({
        type: GET_PRIORITY_ACCESSIBILITY_GRAPH,
        payload: res.data,
      });
      dispatch({
        type: PRIORITY_ACCESSIBILITY_BAR_GRAPH_LOADING,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};
export const afterAddDomainAdded = () => (dispatch) => {
  dispatch({
    type: AFTER_ADD_DOMAIN,
  });
};
export const openDomainModal = () => (dispatch) => {
  dispatch({
    type: OPEN_DOMAIN_MODAL,
  });
};

export const setScanId = (scanId) => (dispatch) => {
  dispatch({
    type: SET_SCAN,
    payload: scanId,
  });
};

export const tour = (tour) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    // eslint-disable-next-line
    const res = await axios.post(
      `${BASE_URL}/auth/tour`,
      {
        tour,
      },
      options
    );
    if (Object.keys(tour).length === 4) {
      toast.success("Tour Resetted");
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};

export const codeSummary = (scanId, issueCode) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
      issueCode,
    };

    // eslint-disable-next-line
    const res = await axios.get(`${BASE_URL}/dashboard/issue-links`, {
      params,
      ...options,
    });
    if (res.data) {
      dispatch({
        type: SET_CODE_SUMMARY,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    window.location.href = "/not-found";
  }
};

export const getScanProgress = () => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    // eslint-disable-next-line
    const res = await axios.get(`${BASE_URL}/dashboard/scans-progress`, {

      ...options,
    });
    if (res.data) {
      dispatch({
        type: SET_SCAN_PROGRESS,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }

  }
};

export const logoutModal = (domainId) => async (dispatch) => {
  dispatch({
    type: LOGOUT_MODAL,
    payload: true,
  });
};

export const getTabsInfo = (scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    dispatch({
      type: SET_TABS_INFO_LOADING,
    });

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    };
    const res = await axios.get(`${BASE_URL}/dashboard/domain-tabs-summary`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_TABS_INFO,
        payload: res.data.result,
      });
    }
  } catch (err) {
    dispatch({
      type: SET_TABS_INFO_LOADING,
    });
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};

export const getAdminReports = (data) => async () => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const params = {
      ...data
    }
    const res = await axios.get(`${BASE_URL}/report/get-admin-reports`, {
      params,
      ...options,
    });

    if (res.data) {
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};


export const postReports = (data) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(
      `${BASE_URL}/report/add-report`,
      {
        ...data
      },
      options
    );


    if (res.data) {
      toast.success(res.data.message)
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};



export const deleteReport = (data) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const params = {
      ...data
    }
    const res = await axios.delete(`${BASE_URL}/report/delete-report`, {
      params,
      ...options
    });


    if (res.data) {
      toast.success(res.data.message)
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};

export const getUserReports = (data) => async () => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const params = {
      ...data
    }
    const res = await axios.get(`${BASE_URL}/report/get-reports`, {
      params,
      ...options,
    });

    if (res.data) {
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};