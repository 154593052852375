import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router';
import { Box, Stepper, Step, StepLabel, Button, Card, CardContent, CircularProgress } from '@mui/material';

import Recidency from './Residency/Index';
import Requestor from './Requestor/index';
import RequestType from './RequestType/Index';
import Interactions from './Interactions/Index';
import Identification from './Identification/Index';
import Submit from './Submit/Index';

import { useTheme } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';
import componentStyles from './styles/stepper';
import Information from './Identification/Information';

const useStyles = makeStyles(componentStyles);

const steps = ['Residency', 'Requestor', 'Request type', 'Interaction', 'Identification', 'Submit'];

export default function Index() {
    const theme = useTheme();

    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const classes = useStyles();

    const [information, setInformation] = React.useState({
        california: '',
        zipcode: '',
        requestor: '',
        requestType: '',
        location: '',
        interactions: [],
        identification: {
            firstName: '',
            lastName: '',
            email: '',
            confirmationEmail: '',
            phoneNumber: ''
        },
        emergencyContactNumber: '',
        dateOfBirth: '',
        signature: ''
    });
    const [error, setError] = React.useState({
        residence: false,
        requestor: false,
        requestType: false,
        interactions: false,
        identification: false,
        signatureError: false
    });

    const [requestor, setRequestor] = React.useState('');
    const [residenceError, setResidenceError] = React.useState('');
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleAddDomain = () => {
        if (activeStep === steps.length - 1) {
            setActiveStep(0);
        }
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', margin: '30px 20px 10px 20px' }}>
            <Box sx={{ width: '100%' }}>
                <Stepper
                    activeStep={activeStep}
                    sx={{
                        padding: 0
                    }}
                    alternativeLabel
                >
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};

                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>

                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {activeStep === 0 && (
                            <Box mt={3} p={2} mx={5}>
                                    <Recidency
                                        setPlace={setInformation}
                                        place={information}
                                        error={residenceError}
                                        setError={setResidenceError}
                                    />
                            </Box>
                        )}
                        {activeStep === 1 && (
                            <Box mt={3} p={2} mx={5}>
                                <Requestor />
                            </Box>
                        )}
                        {activeStep === 2 && (
                            <Box mt={3} p={2} mx={5}>
                                <RequestType data={information} setData={setInformation} />
                            </Box>
                        )}
                        {activeStep === 3 && (
                            <Box mt={3} p={2} mx={5}>
                                <Interactions data={information} setData={setInformation} />
                            </Box>
                        )}
                        {activeStep === 4 && (
                            <Box mt={3} p={2} mx={5}>
                                <Identification data={information} setData={setInformation} />
                            </Box>
                        )}
                        {activeStep === 5 && (
                            <Box mt={3} p={2} mx={5}>
                                <Submit information={information} setInformation={setInformation} />
                            </Box>
                        )}

                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'center', alignItems: 'center' }}>
                            <Button color="inherit" variant="contained" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                                Back
                            </Button>

                            <Button variant="contained" onClick={handleNext}>
                                Next
                            </Button>
                        </Box>
                    </React.Fragment>
                )}
            </Box>
        </div>
    );
}
