import {
  GET_ISSUE_TRACKER_TABLE,
  GET_ISSUE_TRACKER_TABLES,
  GET_ISSUE_TRACKER_TABLES_IDS,
  DELETE_ISSUE_TABLE,
  UPDATE_ISSUE_TABLE,
  LOADING_ISSUES_TABLES,
  OPEN_TRACKER_MODAL,
  SAVING_ISSUE_TRACKER_TABLE,
  ADD_ISSUE_LOADING,
  ADD_MILESTONE_LOADING,
  GET_ISSUES_LISTS,
  GET_ISSUES,
  GET_ISSUE,
  GET_MILESTONE,

  GET_MILESTONES_LISTS,
  GET_MILESTONES,
  GET_MEMBERS,
  GET_LABELS,
  ADD_LABEL_LOADING,

  UPDATE_ISSUE_LOADING,
  UPDATE_MILESTONE_LOADING,
  ADD_COMMENT_LOADING,
  UPDATE_COMMENT_LOADING,
  REMOVE_COMMENT_LOADING
} from '../actions/issueTrackerActions';

const initialState = {
  getTables: null,
  getTable: null,
  loading: false,
  openModal: false,
  savingTableLoading: false,
  addIssueLoading: false,
  addIssueSuccess: false,
  tablesId: [],
  issuesLists: [],
  issues: [],
  currentIssue: null,

  milestone: null,
  milestonesLists: [],
  milestones: [],

  addMilestoneLoading: false,

  members: [],

  addLabelLoading: false,
  labels: [],

  updateLabelLoading: false,
  updateLabelLoading: false,

  addCommentLoading: false,
  removeCommentLoading: false,
  editCommentLoading: false,

  issue: null,
  tableData: {
    type: null,
    tableId: null,
    domainId: null,
  }
};

const issueTrackerReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case GET_MILESTONE:
      return { ...state, milestone: action.payload }
    case GET_ISSUES:
      return {
        ...state,
        issues: action.payload,
      }
    case GET_ISSUE_TRACKER_TABLES:
      return {
        ...state,
        getTables: action.payload
      }
    case GET_ISSUE_TRACKER_TABLES_IDS:
      return {
        ...state,
        tablesId: action.payload
      }
    case SAVING_ISSUE_TRACKER_TABLE:
      return {
        ...state,
        savingTableLoading: !state.savingTableLoading
      }
    case GET_ISSUE_TRACKER_TABLE:
      return {
        ...state,
        getTable: action.payload
      }
    case DELETE_ISSUE_TABLE:
      return {
        ...state,
      }
    case UPDATE_ISSUE_TABLE:
      return {
        ...state,
      }
    case LOADING_ISSUES_TABLES:
      return {
        ...state,
        loading: !state.loading
      }
    case OPEN_TRACKER_MODAL:
      return {
        ...state,
        openModal: !state.openModal,
        tableData: action.payload
      }

      
        case ADD_ISSUE_LOADING:
      return {
        ...state,
        addIssueLoading: !state.addIssueLoading,
      }
    case ADD_MILESTONE_LOADING:
      return {
        ...state,
        addMilestoneLoading: !state.addMilestoneLoading,
      }
    case GET_ISSUES_LISTS:
      return {
        ...state,
        issuesLists: action.payload,
      }
    case GET_ISSUES:
      return {
        ...state,
        issues: action.payload,
      }
    case GET_MILESTONES_LISTS:
      return {
        ...state,
        milestonesLists: action.payload,
      }
    case GET_MILESTONES:
      return {
        ...state,
        milestones: action.payload,
      }
    case GET_MEMBERS:
      return {
        ...state,
        members: action.payload,
      }
    case GET_LABELS:
      return {
        ...state,
        labels: action.payload,
      }
    case ADD_LABEL_LOADING:
      return {
        ...state,
        addLabelLoading: !state.addLabelLoading,
      }

    case UPDATE_ISSUE_LOADING:
      return {
        ...state,
        updateIssueLoading: !state.updateIssueLoading,
      }
    case UPDATE_MILESTONE_LOADING:
      return {
        ...state,
        updateMilestoneLoading: !state.updateMilestoneLoading,
      }
    case GET_ISSUE:
      return {
        ...state,
        issue: action.payload,
      }
    case ADD_COMMENT_LOADING:
      return {
        ...state,
        addCommentLoading: !state.addCommentLoading,
      }
    case UPDATE_COMMENT_LOADING:
      return {
        ...state,
        editCommentLoading: !state.editCommentLoading,
      }
    case REMOVE_COMMENT_LOADING:
      return {
        ...state,
        removeCommentLoading: !state.removeCommentLoading,
      }
    default:
      return state;
  }
};

export default issueTrackerReducer;