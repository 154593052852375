import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";

import Auth0Service from "../services/auth0Service/authentikService";
import { getDomains } from '../store/actions/dashboardActions';
import loaderwave from "../assets/loader.svg";

export default function DomainsWrapper({ children }) {
  const dispatch = useDispatch();
  const domainsLoading = useSelector(state => state.dashboard.domainsLoading);
  React.useEffect(() => {
    if (Auth0Service.isAuthenticated()) {
      dispatch(getDomains());
    }
    // eslint-disable-next-line
  }, [])
  return (
    domainsLoading ? <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      tabIndex="0"
    >
      <img src={loaderwave} alt="loader" />
    </Box>
      : <>{children}</>
  )
}