import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ReplayIcon from '@material-ui/icons/Replay';
import { CircularProgress, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';

import { dateFormatter } from "../../utils/dateFormatter";
import { getAllCompletedScans, DomainModulesAdmin } from "../../store/actions/adminScansManagementActions";
import { startBasicScan } from "../../store/actions/customScanPageListActions";

const useRowStyles = makeStyles({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
});

// function Row(props) {
// 	const { row } = props;
// 	const [open, setOpen] = React.useState(false);
// 	const [modules, setModules] = React.useState({ accessibility: false, performance: false, webPrivacy: false, cybersec: false, languagAndGrammer: false });
// 	const classes = useRowStyles();

// 	const dispatch = useDispatch();

// 	async function rescanDomain(domainId, resolution, device, browser) {
// 		try {
// 			// Await the result of the asynchronous getModules function
// 			await getModules(domainId);
			
// 			// Prepare the data object
// 			let data = {
// 				domainId: domainId,
// 				modules: [modules],
// 				scanOptions: {
// 					browsers: [browser],
// 					devices: [device],
// 					resolutions: [resolution]
// 				},
// 				sitemapOptions: {
// 					dirLevel: 3,
// 					existingSitemap: false,
// 					existingSitemapFile: false,
// 					existingSitemapUri: false,
// 					fastCrawl: false,
// 					timeoutPerDomain: 60000
// 				}
// 			};
			
// 			await dispatch(startBasicScan(data));
// 			// Log the inputs and data for debugging
// 			console.log(domainId);
// 			console.log(resolution);
// 			console.log(device);
// 			console.log(data);
// 		} catch (error) {
// 			// Handle any errors that might occur during the async operation
// 			// console.error('Error while rescanning domain:', error);
// 		}
// 	}
// 	function getModules(selectedDomainId) {
// 		console.log("Fetching modules for domain:", selectedDomainId);
// 		dispatch(DomainModulesAdmin({ selectedDomainId }))
// 		  .then((data) => {
// 			if (data) {
// 			  console.log("Data from getModules:", data.result);
	  
// 			  // Define the specific keys to include
// 			  const keysToInclude = ["accessibility", "cybersec", "performance", "seo", "languagAndGrammer"];
	  
// 			  // Filter and map only the modules with true values and specific keys
// 			  const filteredModules = Object.fromEntries(
// 				Object.entries(data.result)
// 				  .filter(([key, value]) => value === true && keysToInclude.includes(key))
// 				  .map(([key, value]) => {
// 					// If key is 'cybersec', rename it to 'cybersecurity'
// 					if (key === "cybersec") {
// 					  key = "cybersecurity";
// 					}
// 					return [key, value];
// 				  })
// 			  );
	  
// 			  // Set the filtered modules
// 			  setModules(filteredModules);
// 			}
// 		  })
// 		  .catch((error) => {
// 			console.error("Failed to fetch modules:", error);
// 		  });
// 	  }

// 	return (
// 		<React.Fragment>
// 			<TableRow className={classes.root} onClick={() => setOpen(!open)} hover>
// 				<TableCell component="th" scope="row" style={{ width: "100%" }}>
// 					<Box display="flex" alignItems="center">
// 						<IconButton
// 							aria-label="expand row"
// 							size="small"
// 							onClick={() => setOpen(!open)}
// 						>
// 							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
// 						</IconButton>
// 						<Typography>{row.domain} - {dateFormatter(new Date(row.dateNow))}</Typography>
// 					</Box>
// 				</TableCell>
// 			</TableRow>
// 			<TableRow>
// 				<TableCell style={{ paddingBottom: 0, paddingTop: 0, overflow: "auto" }} colSpan={6} >
// 					<Collapse in={open} timeout="auto" unmountOnExit>
// 						<Box margin={1} >
// 							<Table size="small" aria-label="purchases" style={{ tableLayout: "fixed" }}>
// 								<TableHead>
// 									<TableRow>
// 										<TableCell style={{ width: 250 }}>Scan Id</TableCell>
// 										<TableCell style={{ width: 150 }}>Date</TableCell>
// 										<TableCell style={{ width: 150 }}>Status</TableCell>
// 										<TableCell style={{ width: 120 }}>Browser</TableCell>
// 										<TableCell style={{ width: 100 }}>Device</TableCell>
// 										<TableCell style={{ width: 120 }}>Resolution</TableCell>
// 										<TableCell style={{ width: 230 }}>Actions</TableCell>
// 									</TableRow>
// 								</TableHead>
// 								<TableBody>
// 									{row.scans.map((scan) => (
// 										<TableRow key={scan?.scanId}>
// 											<TableCell scope="row" style={{ cursor: 'pointer' }} onClick={() => window.open(`/dashboard/?domain=${row.domain}&scanId=${scan?.scanId}`, "_blank")}>
// 												{scan?.scanId}
// 											</TableCell>
// 											<TableCell scope="row">
// 												{dateFormatter(new Date(scan?.dateNow))}
// 											</TableCell>
// 											<TableCell>{scan?.status}</TableCell>
// 											<TableCell>{scan?.browser}</TableCell>
// 											<TableCell>{scan?.device}</TableCell>
// 											<TableCell>
// 												{scan?.resolution}
// 											</TableCell>
// 											<TableCell>
// 												{/* <Tooltip title="Delete Scan">
// 													<IconButton onClick={() => dispatch(deleteScan(scan?.scanId, keyCode))}>
// 														<DeleteIcon />
// 													</IconButton>
// 												</Tooltip> */}
// 												<TableCell title="Rescan">
// 													{/* dispatch(reScan(scan?.scanId, keyCode)) */}
// 													<IconButton onClick={() => rescanDomain(scan?.domainId, scan?.resolution, scan?.device, scan?.browser)}>
// 														<ReplayIcon />
// 													</IconButton>
// 												</TableCell>
// 												{/* {scan?.status === "stopped" && <Tooltip title="Rescan with old Sitemap">
// 													<IconButton onClick={() => dispatch(scanAction(scan?.scanId, "rescan", keyCode))}>
// 														<StopIcon />
// 													</IconButton>
// 												</Tooltip>} */}
// 												{/* {scan?.status !== "stopped" && <Tooltip title="Stop Scan">
// 													<IconButton onClick={() => dispatch(scanAction(scan?.scanId, "stop", keyCode))}>
// 														<StopIcon />
// 													</IconButton>
// 												</Tooltip>}
// 												{(scan?.status !== "resumed" && scan?.status !== "stopped" && scan?.status !== "paused") && <Tooltip title="Pause Scan">
// 													<IconButton onClick={() => dispatch(scanAction(scan?.scanId, "pause", keyCode))}>
// 														<PauseIcon />
// 													</IconButton>
// 												</Tooltip>}
// 												{(scan?.status !== "stopped" && scan?.status !== "pending") && <Tooltip title="Resume Scan">
// 													<IconButton onClick={() => dispatch(scanAction(scan?.scanId, "resume", keyCode))}>
// 														<PlayArrowIcon />
// 													</IconButton>
// 												</Tooltip>} */}
// 											</TableCell>
// 										</TableRow>
// 									))}
// 								</TableBody>
// 							</Table>
// 						</Box>
// 					</Collapse>
// 				</TableCell>
// 			</TableRow>
// 		</React.Fragment>
// 	);
// }
function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [modules, setModules] = React.useState({ accessibility: false, performance: false, webPrivacy: false, cybersec: false, languagAndGrammer: false });
    const classes = useRowStyles();
    const dispatch = useDispatch();

    // State for the confirmation dialog
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedScan, setSelectedScan] = useState(null);

    const handleDialogOpen = (scan) => {
        setSelectedScan(scan);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedScan(null);
    };

    const confirmRescan = () => {
        if (selectedScan) {
            rescanDomain(selectedScan.domainId, selectedScan.resolution, selectedScan.device, selectedScan.browser);
        }
        handleDialogClose();
    };

    async function rescanDomain(domainId, resolution, device, browser) {
        try {
            await getModules(domainId);
            let data = {
                domainId: domainId,
                modules: [modules],
                scanOptions: {
                    browsers: [browser],
                    devices: [device],
                    resolutions: [resolution]
                },
                sitemapOptions: {
                    dirLevel: 3,
                    existingSitemap: false,
                    existingSitemapFile: false,
                    existingSitemapUri: false,
                    fastCrawl: false,
                    timeoutPerDomain: 60000
                }
            };
            await dispatch(startBasicScan(data));
            console.log(domainId);
            console.log(resolution);
            console.log(device);
            console.log(data);
        } catch (error) {
            console.error('Error while rescanning domain:', error);
        }
    }

    function getModules(selectedDomainId) {
        console.log("Fetching modules for domain:", selectedDomainId);
        dispatch(DomainModulesAdmin({ selectedDomainId }))
            .then((data) => {
                if (data) {
                    console.log("Data from getModules:", data.result);

                    const keysToInclude = ["accessibility", "cybersec", "performance", "seo", "languagAndGrammer"];
                    const filteredModules = Object.fromEntries(
                        Object.entries(data.result)
                            .filter(([key, value]) => value === true && keysToInclude.includes(key))
                            .map(([key, value]) => {
                                if (key === "cybersec") {
                                    key = "cybersecurity";
                                }
                                return [key, value];
                            })
                    );
                    setModules(filteredModules);
                }
            })
            .catch((error) => {
                console.error("Failed to fetch modules:", error);
            });
    }

    return (
        <React.Fragment>
            <TableRow className={classes.root} onClick={() => setOpen(!open)} hover>
                <TableCell component="th" scope="row" style={{ width: "100%" }}>
                    <Box display="flex" alignItems="center">
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                        <Typography>{row.domain} - {dateFormatter(new Date(row.dateNow))}</Typography>
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, overflow: "auto" }} colSpan={6} >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1} >
                            <Table size="small" aria-label="purchases" style={{ tableLayout: "fixed" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: 250 }}>Scan Id</TableCell>
                                        <TableCell style={{ width: 150 }}>Date</TableCell>
                                        <TableCell style={{ width: 150 }}>Status</TableCell>
                                        <TableCell style={{ width: 120 }}>Browser</TableCell>
                                        <TableCell style={{ width: 100 }}>Device</TableCell>
                                        <TableCell style={{ width: 120 }}>Resolution</TableCell>
                                        <TableCell style={{ width: 230 }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.scans.map((scan) => (
                                        <TableRow key={scan?.scanId}>
                                            <TableCell scope="row" style={{ cursor: 'pointer' }} onClick={() => window.open(`/dashboard/?domain=${row.domain}&scanId=${scan?.scanId}`, "_blank")}>
                                                {scan?.scanId}
                                            </TableCell>
                                            <TableCell scope="row">
                                                {dateFormatter(new Date(scan?.dateNow))}
                                            </TableCell>
                                            <TableCell>{scan?.status}</TableCell>
                                            <TableCell>{scan?.browser}</TableCell>
                                            <TableCell>{scan?.device}</TableCell>
                                            <TableCell>{scan?.resolution}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => handleDialogOpen(scan)}>
                                                    <ReplayIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

            {/* Confirmation Dialog */}
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Rescan"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to rescan this domain?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        No
                    </Button>
                    <Button onClick={confirmRescan} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}



export default function CompletedScans() {
	// const [key, setKey] = React.useState('')


	const [loadingCompletedScans, setLoadingCompletedScans] = React.useState(true)
	const [data, setData] = React.useState([])


	const dispatch = useDispatch();
	function getDomains() {
		dispatch(getAllCompletedScans()).then(data => {
			if (data) {
				setLoadingCompletedScans(false);
				setData(data.result.scans);
				console.log("completed scans")
				console.log(data.result);
			} else {
				setLoadingCompletedScans(false)

			}
		}).catch(err => {
			setLoadingCompletedScans(false)
		})
	}

	React.useEffect(() => {
		getDomains()
	}, [])


	return (
		<>
			{!loadingCompletedScans ? <>
				<Grid container spacing={2}>
					{data.length > 0 ? <> {data.map((row) => (
						<Grid item md={6}>
							<Table aria-label="collapsible table" style={{ tableLayout: "fixed" }}>
								{/* <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      width: "100%",
                      background: '#fff'
                    }}
                  >Scans</TableCell>
                </TableRow>
              </TableHead> */}
								<TableBody>
									<Row key={row.scanManagerId} row={row} />
								</TableBody>
							</Table>
						</Grid>
					))}</> :
						<Box textAlign='center' width='100%' mt={4}>
							<i>No Completed Scans</i>
						</Box>
					}

				</Grid>
			</>
				:
				<Box width="100%" display="flex" justifyContent="center" alignItems="center">
					<CircularProgress />
				</Box>}
		</>
	);
}


