const securityStatCardStyles = {
  cardMain: {
    padding: "10px",
    height: "300px",
    background: "#FFFFFF",
    border: "1px solid #FFFFFF",
    boxSizing: "border-box",
    boxShadow: "rgb(0 0 0 / 21%) 0px 4px 14px",
    borderRadius: 10,
  },
  cardSub: {
    padding: "10px 0",
    marginTop: "10px",
  },
  title: {
    fontFamily: "Segoe UI",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 600,
    letterSpacing: "0em",
    textAlign: "left",
    color: "#484848",
  },
  vulFound: {
    fontFamily: "Segoe UI",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 600,
    letterSpacing: "0em",
    textAlign: "right",
    color: "#585858",
  },
  p0: {
    padding: 0,
  },
  subTitle: {
    fontFamily: "Segoe UI",
    color: "#000",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 600,
    letterSpacing: "0em",
    textAlign: "left",
    lineHeight: 2,
    margin: 0,
  },
  barPercentage: {
    fontFamily: "Segoe UI",
    fontSize: 19,
    fontStyle: "normal",
    fontWeight: 600,
    letterSpacing: "0em",
    textAlign: "left",
    color: "#1f3a93",
    marginRight: 4,
  },
  vulPages: {
    fontFamily: "Segoe UI",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 600,
    letterSpacing: "0em",
    textAlign: "left",
    color: "#585858",
  },
  infoIcon: {
    width: 19,
    height: 19,
  },
};

export default securityStatCardStyles;
