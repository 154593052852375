import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { getDomainTechnologies } from "../../store/actions/abracadabraActions"
import loaderwave from "../../assets/loader.svg";
import CVEExploitsWrap from "../../components/tables/securitytables/CVEExploitsWrap";

const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
    chipsSpacing: {
        margin: "3px 6px 3px 0px",
    },
});

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment style={{ overFlow: "hidden" }}>
            <div className={classes.root} hover>
                <div component="th" scope="row" style={{ width: "100%" }}>
                    <Box display="flex" alignItems="center">
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? (
                                <KeyboardArrowUpIcon style={{ border: 0 }} />
                            ) : (
                                <KeyboardArrowDownIcon style={{ border: 0 }} />
                            )}
                        </IconButton>
                        {row.icon ? (
                            <img
                                style={{ margin: "0px 10px" }}
                                alt="icon"
                                src={`data:image/png;base64,${row.icon}`}
                                width="20px"
                                height="20px"
                            />
                        ) : (
                            <img
                                style={{ margin: "0px 10px" }}
                                alt="icon"
                                width="20px"
                                height="20px"
                                src="https://img.icons8.com/external-kiranshastry-lineal-color-kiranshastry/64/000000/external-image-interface-kiranshastry-lineal-color-kiranshastry.png"
                            />
                        )}
                        {row.name}
                    </Box>
                </div>
            </div>
            <div>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0, border: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box mb={2}>
                            {row.categories &&
                                row.categories.map((category) => {
                                    return category ? (
                                        <Chip
                                            className={classes.chipsSpacing}
                                            label={category.name}
                                            size="small"
                                        />
                                    ) : null;
                                })}
                            {row.website && (
                                <Chip
                                    component="a"
                                    href={row.website}
                                    target="_blank"
                                    color="primary"

                                    size="small"
                                    label={`Visit ${row.website
                                        .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
                                        .split("/")[0]
                                        }`}
                                    className={classes.chipsSpacing}
                                />
                            )}{" "}
                        </Box>
                        <Box margin={1}>
                            <Table
                                size="small"
                                aria-label="purchases"
                            // style={{ tableLayout: "fixed" }}
                            >
                                <TableBody>
                                    <TableRow key={row.name}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                                fontWeight: "bold",
                                                width: "140px",
                                                fontSize: 14,
                                            }}
                                        >
                                            Name
                                        </TableCell>
                                        <TableCell>{row.name}</TableCell>
                                    </TableRow>

                                    <TableRow key={row.version}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                                fontWeight: "bold",
                                                width: "140px",
                                                fontSize: 14,
                                            }}
                                        >
                                            Version
                                        </TableCell>
                                        <TableCell>
                                            {row.version ? row.version : "Not Available"}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={row.confidence}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                                fontWeight: "bold",
                                                width: "140px",
                                                fontSize: 14,
                                            }}
                                        >
                                            Confidence
                                        </TableCell>
                                        <TableCell>
                                            {row.confidence ? row.confidence : "Not Available"}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={row.baseSeverity}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                                fontWeight: "bold",
                                                width: "140px",
                                                fontSize: 14,
                                            }}
                                        >
                                            Base Severity
                                        </TableCell>
                                        <TableCell>{row.baseSeverity}</TableCell>
                                    </TableRow>
                                    <TableRow key={row.exploitabilitySeverity}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                                fontWeight: "bold",
                                                width: "185px",
                                                fontSize: 14,
                                            }}
                                        >
                                            Exploitability Severity
                                        </TableCell>
                                        <TableCell>{row.exploitabilitySeverity}</TableCell>
                                    </TableRow>
                                    <TableRow key={row.impactSeverity}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={{
                                                fontWeight: "bold",
                                                width: "185px",
                                                fontSize: 14,
                                            }}
                                        >
                                            Impact Severity
                                        </TableCell>
                                        <TableCell>{row.impactSeverity}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </div>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            })
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};

export default function CollapsibleTable() {
    const dispatch = useDispatch();
    const currentURL = window.location.href;
    // Create a URL object
    const urlObject = new URL(currentURL);
    let domainParam = urlObject.searchParams.get('domain');
    if (domainParam.includes("www")) {
        domainParam = domainParam.split('www.')[1];
    }

    const [tableData, setTableData] = React.useState([]);
    const [delayed, setDelayed] = React.useState(true);

    React.useEffect(() => {
        if (domainParam) {
            dispatch(getDomainTechnologies(domainParam))
                .then(data => {
                    if (data && Object.keys(data.response.result.technologies).length > 0) {
                        setTableData(data.response.result.technologies.technologies);
                    } else {
                        setTableData([]);
                    }
                })
                .catch(err => {
                });
        }
    }, [domainParam]);

    React.useEffect(() => {
        setTimeout(() => {
            setDelayed(false);
        }, 1000);
    }, []);

    return (
        <>
            {!delayed ? (
                <>
                    <TableContainer component={Paper} style={{ marginBottom: "20px" }}>
                        <Table
                            aria-label="collapsible table"
                            style={{ tableLayout: "fixed" }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        style={{
                                            width: "100%",
                                            borderRight: "2px solid #1f3a93",
                                        }}
                                    >
                                        Technologies
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.length > 0 ? (
                                    <>
                                        {" "}
                                        <Grid
                                            container
                                            spacing={3}
                                            style={{
                                                maxHeight: "100%",
                                                position: "sticky",
                                                marginTop: "10px",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            {tableData.map((row) => {
                                                // return <Row key={row.name} row={row} />;
                                                return (
                                                    <Grid item xs={12} lg={4} sm={6} md={6}>
                                                        <Row key={row.name} row={row} />
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </>
                                ) : (
                                    <TableRow>
                                        <TableCell align="center">
                                            <i>No Technologies Found</i>
                                        </TableCell>{" "}
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                    tabIndex="0"
                >
                    {" "}
                    <img src={loaderwave} alt="loader" />
                </Box>
            )}
            <CVEExploitsWrap />
        </>
    );
}
