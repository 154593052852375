const websitesAndUsersDowngradeStyles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 500,
  },
  heading: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: '100%',
    textAlign: "center",
    fontSize: "4rem",
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      fontSize: "2rem"
    },
  },
  newTotalPrice: {
    fontSize: "2rem",
    [theme.breakpoints.down('md')]: {
      fontSize: "1.8rem"
    },
  },
  listPackage: {
    [theme.breakpoints.down('md')]: {
      fontSize: "0.9rem"
    },
  },
  listPackageMain: {
    [theme.breakpoints.down('md')]: {
      fontSize: "0.8rem"
    },
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  addSiteButton: {
    margin: 0,
    color: "#96291c",
    fontWeight: "600"
  },
  wesbitesTable: {
    maxWidth: 400,
  },
});

export default websitesAndUsersDowngradeStyles;
