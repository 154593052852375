import React from "react";
import NumericLabel from 'react-pretty-numbers';

export default function ShortenNumber({children}) {

  var option = {
    'justification': 'C',
    'locales': 'en-AU',
    'currency': false,
    'currencyIndicator': 'AUD',
    'percentage': false,
    'precision': 0,
    'wholenumber': null,
    'commafy': true,
    'shortFormat': true,
    'shortFormatMinValue': 1000,
    'shortFormatPrecision': 2,
    'title': true,
    'cssClass': ['textLeft']
  };

  return (
  
      <NumericLabel params={option}>{children}</NumericLabel>

  );
}