import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { changeDate } from '../../utils/changeDate';
import { Grid } from '@material-ui/core';
import cveModalStyles from '../../Styles/jss/cveModalStyles'
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles(cveModalStyles);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


export default function CveModal({ open, handleClose, cveData }) {
  const classes = useStyles();

  return (
    <div>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" maxWidth={'md'} open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <>
            <h2 style={{margin:"0"}}>
              {cveData?.cve?.CVE_data_meta?.ID}
            </h2>
          </>

        </DialogTitle>
        <DialogContent dividers>
       { cveData  &&  <Typography gutterBottom>
            <>
              <div>
                <h3 className={classes.h3Style}>
                  Description:
                </h3>
                <p className={classes.pstyle}>
                  {cveData ? cveData.cve.description.description_data[0].value : ""}
                </p>
              </div>
              <div>
                <h3 className={classes.h3Style}>
                  References:
                </h3>
                <p className={classes.pstyle}>
                  <a target="_blank" rel="noreferrer" className={classes.refstyle} href={cveData.cve ? cveData.cve?.references?.reference_data[0]?.url : ""}>
                  {cveData.cve ? cveData.cve?.references?.reference_data[0]?.url : ""}
                  </a>
                </p>
              </div>
              <div>
                <h3 className={classes.h3Style}>
                  Details
                </h3>
                <div>
                  <div>
                    <span className={classes.spanStyle}>Source: </span>
                    <span className={classes.refstyle}>
                      {cveData.impact? cveData.cve.data_format : ""}
                    </span>
                  </div>
                  <div>
                    <span className={classes.spanStyle}>Published: </span>
                    {cveData.impact? changeDate(cveData.publishedDate) : ""}
                  </div>
                  <div>
                    <span className={classes.spanStyle}>Updated: </span>
                    {cveData.impact? changeDate(cveData.lastModifiedDate) : ""}
                  </div>
                  <div>
                    <span className={classes.spanStyle}>Type: </span>
                    <span className={classes.refstyle} >
                    {cveData.impact? cveData.cve.problemtype.problemtype_data[0].description[0].value : ""}
                    </span>
                  </div>
                </div>
                <div style={{marginTop:20}}>
                  <h3 className={classes.h3Style}>
                    Risk Information
                  </h3>
                  <div>

                    <Grid container spacing={1}>

                      <Grid item xs={6} spacing={2}>
                        <h3 className={classes.refstyle}>CVSS v2.0</h3>
                        <div>
                          <div>
                            <span className={classes.spanStyle}>Base Score: </span>
                            <span>
                            {cveData.impact? cveData.impact.baseMetricV2?.cvssV2?.baseScore : ""}
                            </span>
                          </div>
                          <div>
                            <span className={classes.spanStyle}>Vector: </span>
                            <span style={{wordBreak: 'break-all'}}>
                            {cveData.impact? cveData.impact.baseMetricV2?.cvssV2?.vectorString : ""}
                            </span>
                          </div>
                          <div>
                            <span className={classes.spanStyle}>Impact Score: </span>
                            <span>
                            {cveData.impact? cveData.impact.baseMetricV2?.impactScore : ""}
                            </span>
                          </div>
                          <div>
                            <span className={classes.spanStyle}>Exploitability Score: </span>
                            <span>
                            {cveData.impact? cveData.impact.baseMetricV2?.exploitabilityScore : ""}
                            </span>
                          </div>
                          <div>
                            <span className={classes.spanStyle}>Severity: </span>
                            <span>
                            {cveData.impact? cveData.impact.baseMetricV2?.severity : ""}
                            </span>
                          </div>
                        </div>
                      </Grid>

                     { cveData.impact.baseMetricV3 &&  <Grid item xs={6} spacing={2}>
                        <h3 className={classes.refstyle}>CVSS v3.0</h3>
                        <div>
                          <div>
                            <span className={classes.spanStyle}>Base Score: </span>
                            <span>
                            {cveData.impact? cveData.impact.baseMetricV3?.cvssV3?.baseScore : ""}
                            </span>
                          </div>
                          <div>
                            <span className={classes.spanStyle}>Vector: </span>
                            <span>
                            {cveData.impact? cveData.impact.baseMetricV3?.cvssV3?.vectorString : ""}
                            </span>
                          </div>
                          <div>
                            <span className={classes.spanStyle}>Impact Score: </span>
                            <span>
                            {cveData.impact? cveData.impact.baseMetricV3?.impactScore : ""}
                            </span>
                          </div>
                          <div>
                            <span className={classes.spanStyle}>Exploitability Score: </span>
                            <span>
                            {cveData.impact? cveData.impact.baseMetricV3?.exploitabilityScore : ""}
                            </span>
                          </div>
                          <div>
                            <span className={classes.spanStyle}>Severity: </span>
                            <span>
                            {cveData.impact? cveData.impact.baseMetricV3?.cvssV3?.baseSeverity : ""}
                            </span>
                          </div>
                        </div>
                      </Grid>}

                    </Grid>
                  </div>
                </div>
              </div>
            </>

          </Typography>}

        </DialogContent>

      </Dialog>
    </div>
  );
}
