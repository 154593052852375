import React from 'react';
import { useDispatch } from "react-redux";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { postBillingStage } from "../../../store/actions/onBoardingActions";

import Pages from './Pages';
import Extensions from './Extension';

import Resolutions from './Resolutions';
import Users from './Users';
import Addons from './Addons';
import CustomCardContainer from '../../../components/commons/CustomCardContainer'

export default function Manage({ data, setActiveStep, domainId }) {
  const dispatch = useDispatch();

  const [billingContent, setBillingContent] = React.useState(null);
  const [billingResolutions, setBillingResolutions] = React.useState([]);


  React.useEffect(() => {
    if (data) {
      setBillingContent(data);
    }
  }, [data])



  function nextStep() {
    if (billingResolutions.length < 1) {
      toast.error("Please select atleast one resolution")
    } else {
      const postData = { ...billingContent, resolutions: billingResolutions }
      dispatch(postBillingStage(domainId, postData, 1)).then(data => {
        if (data) {
          setActiveStep(1);
        }
      })
    }

  }

  return (
    <Box>
      {billingContent && billingContent.sitemapGenerated ?
        <>
          <Box mb={4}>
            <CustomCardContainer title="Pages" >
              <Pages pages={billingContent} />
            </CustomCardContainer>
          </Box>
          <Box mb={4}>
            <CustomCardContainer title="Extensions" >
              <Extensions extensions={billingContent} />
            </CustomCardContainer>
          </Box>
          <Box mb={4}>
            <CustomCardContainer title="Resolutions" >
              <Resolutions billingContent={billingContent} billingResolutions={billingResolutions} setBillingResolutions={setBillingResolutions} />
            </CustomCardContainer>
          </Box>
          <Box mb={4}>
            <CustomCardContainer title="Users" >
              <Users billingContent={billingContent} setBillingContent={setBillingContent} />
            </CustomCardContainer>
          </Box>
          <Box mb={4}>
            <CustomCardContainer title="Addons" >
              <Addons billingContent={billingContent} setBillingContent={setBillingContent} />
            </CustomCardContainer>
          </Box>
          <Button color="primary" variant="contained" onClick={() => nextStep()} >Next</Button>
        </> :
        <Box width="100%" textAlign="center"> <h3>Sitemap is being Generating.... <br />
          Please be right here in few minutes
        </h3> </Box>
      }
    </Box>
  )
}