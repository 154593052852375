import React from 'react';
import NumericInput from 'react-numeric-input';

export default function Users({ billingContent, setBillingContent }) {

  function handleUsers(valueAsNumber) {
    if (valueAsNumber) {
      setBillingContent({ ...billingContent, rangeOfUsers: { ...billingContent.rangeOfUsers, userRange: valueAsNumber, price: valueAsNumber * 5 } });
    }
  }

  return (
    <div>
      {billingContent &&
        <>
          <NumericInput mobile="on" strict inputMode="numeric" defaultValue={3} min={3} onChange={handleUsers} style={{
            input: {
              maxWidth: "80px"
            }
          }} />
          <br />
          <br />
          Price per User: $5
          <br />
          <br />
          Price : ${billingContent?.rangeOfUsers?.price}
          <br />
        </>
      }
    </div>
  )
}