import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { Box } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import Hidden from "@material-ui/core/Hidden";
import { useSelector, useDispatch } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import { getPerformanceStructure } from "../../../../store/actions/performanceActions";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Button from "@material-ui/core/Button";
import Auth0Service from "../../../../services/auth0Service/authentikService";
import "../structure.css";
import SubLoader from "../../../commons/SubLoader";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 10,
    },
  },
});

function issueSeverityColor(severity) {
  if (severity === "low") {
    return "rgba(54,169,39,0.85)";
  } else if (severity === "medium") {
    return "rgba(153,193,68,0.85)";
  } else {
    return "#E2211E";
  }
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const tdClasses = useStyles();

  return (
    <React.Fragment>
      <TableRow hover className={classes.root} onClick={() => setOpen(!open)}>
        <TableCell
          hover
          component="th"
          scope="row"
          className={tdClasses.tdSeverity}
          style={{ backgroundColor: issueSeverityColor(row.performance) }}
        >
          <Hidden smDown>
            <span
              style={{ textTransform: "capitalize", fontFamily: "Segoe UI" }}
            >
              {row.performance}
            </span>
          </Hidden>
        </TableCell>
        <TableCell hover align="left">
          <span className={tdClasses.tdTitle}>{row.title}</span>
        </TableCell>
        <Hidden smDown>
          {" "}
          <TableCell
            hover
            align="left"
            style={{ fontWeight: 600, fontFamily: "Segoe UI" }}
          >
            {row.displayValue}
          </TableCell>
        </Hidden>
        {/* <TableCell hover className={tdClasses.tdCollapseButton} >
          <IconButton aria-label="expand row" size="small" >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell> */}
      </TableRow>
      {/* <TableRow>
        <TableCell classes={{ root: tdClasses.cellPaddZero }} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="subtitle1" gutterBottom component="p">
                {row.auditDsc}
              </Typography>
              <Table aria-label="purchases" size="small" style={{ maxWidth: 1280 }}>
                <TableHead>
                  <TableRow>
                    <TableCell classes={{ sizeSmall: tdClasses.cellPaddSmall }} className={tdClasses.innerTh}>URL</TableCell>
                    <TableCell classes={{ sizeSmall: tdClasses.cellPaddSmall }} className={tdClasses.transeferSizeTh} >TRANSFER SIZE</TableCell>
                    <TableCell classes={{ sizeSmall: tdClasses.cellPaddSmall }} className={tdClasses.potentialSavingsTh} align="left">PORTENTIAL SAVINGS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.link}>
                      <TableCell scope="row" className={tdClasses.urlTh}>
                        <a href={historyRow.link} style={{
                          color: "#3c9adc",
                          fontSize: 12,
                          maxWidth: 350,
                          minWidth: 45,
                          display: "block"

                        }} target="_blank" rel="nofollow noopener noreferrer">{historyRow.link}</a>
                      </TableCell>
                      <TableCell style={{ color: "#646464", fontWeight: 600 }}  >{historyRow.transferSize}</TableCell>
                      <TableCell style={{ color: "#646464", fontWeight: 600 }} align="left">{historyRow.potentialSavings}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </React.Fragment>
  );
}

export default function Structure2() {
  const tdClasses = useStyles();

  const [tableData, setTableData] = React.useState({
    data: null,
    direction: "desc",
  });

  const dispatch = useDispatch();
  const scanId = useSelector((state) => state.miscellaneous.selectedScanId);

  const data = useSelector((state) => state.performance.performance_structure);
  const status = useSelector(
    (state) => state.performance.performance_structure_status
  );
  const loading = useSelector(
    (state) => state.performance.performance_structure_loading
  );

  React.useEffect(() => {
    if (scanId) {
      dispatch(getPerformanceStructure(scanId));
    }
    // eslint-disable-next-line
  }, [scanId]);

  React.useEffect(() => {
    if (data) {
      var animPriority = { high: 1, medium: 2, low: 3 };
      setTableData({
        ...tableData,
        data: data.sort(
          (a, b) => animPriority[a.performance] - animPriority[b.performance]
        ),
      });
    }
    // eslint-disable-next-line
  }, [data]);

  React.useEffect(() => {
    if (status === "running" || status === "pending") {
      const interval = setInterval(() => {
        if (scanId) {
          dispatch(getPerformanceStructure(scanId));
        }
      }, 15000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [status]);

  var animPriority = { high: 1, medium: 2, low: 3 };

  function sortData() {
    if (tableData.direction === "desc") {
      const soro = tableData.data.sort(
        (a, b) => animPriority[b.performance] - animPriority[a.performance]
      );
      setTableData({ direction: "asc", data: soro });
    } else {
      const soro = tableData.data.sort(
        (a, b) => animPriority[a.performance] - animPriority[b.performance]
      );
      setTableData({ direction: "desc", data: soro });
    }
  }
  const isPackagePurchased = useSelector(
    (state) => state.dashboard.isDomainPackagePurchased
  );

  return (
    <>
      {tableData.data && !loading ? (
        <TableContainer
          style={{
            maxWidth: 1280,
            maxHeight: 600,
            overflowY: "scroll",
            boxShadow:
              "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
          }}
        >
          <Table stickyHeader aria-label="collapsible table" bordered="true">
            <TableHead>
              <TableRow>
                <TableCell className={tdClasses.tdSeverityTh}>
                  {" "}
                  <Hidden smDown>
                    <TableSortLabel
                      active={true}
                      direction={tableData.direction}
                      onClick={() => sortData()}
                      style={{
                        color: "#000",
                        fontWeight: 600,
                        fontFamily: "Segoe UI",
                        fontSize: "20px",
                      }}
                    >
                      IMPACT
                    </TableSortLabel>{" "}
                  </Hidden>
                </TableCell>
                <TableCell
                  style={{
                    color: "#000",
                    fontWeight: 600,
                    fontFamily: "Segoe UI",
                    fontSize: "20px",
                  }}
                  align="left"
                >
                  AUDIT
                </TableCell>
                <Hidden smDown>
                  {" "}
                  <TableCell
                    style={{ width: 350 }}
                    align="left"
                  ></TableCell>{" "}
                </Hidden>
                {/* <TableCell style={{ width: 38 }} /> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.data.map((row) => (
                <Row key={row.title} row={row} />
              ))}
              {!isPackagePurchased && (
                <TableRow>
                  <TableCell
                    colspan={3}
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      textAlign: "center",
                      padding: 0,
                    }}
                  >
                    <div
                      style={{
                        margin: "auto",
                        width: "100%",
                        height: 200,
                        backgroundColor: "#1f3a93",
                        padding: 10,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                      }}
                    >
                      <div className="ps-stic2">
                        <h4>
                          To see complete data, please subscribe to our Package.
                        </h4>
                        <Button
                          component="a"
                          style={{ borderRadius: "20px" }}
                          href={`${
                            Auth0Service.isAuthenticated()
                              ? "/billing"
                              : "/authenticate"
                          }`}
                          color="white"
                          variant="contained"
                        >
                          Subscribe
                        </Button>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            height: "500px",
            width: "100%",
          }}
        >
          <SubLoader />
        </Box>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  tdSeverity: {
    color: "#ffffff",
    fontSize: 16,
    fontWeight: 400,
    textAlign: "center",
  },
  tdTitle: {
    fontWeight: "bold",
    color: "#000",
    marginLeft: 6,
    fontFamily: "Segoe UI",
    textDecoration: "none",
    fontSize: 18,
    display: "block",
    fontWeight: 500,
  },
  tdRules: {
    fontWeight: "bold",
    color: "#1f3a93",
    marginLeft: 6,
    fontFamily: "Segoe UI",
    textDecoration: "none",
  },
  tdCollapseButton: {
    backgroundColor: "#e2e2e2",
  },

  cellPaddZero: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  cellPaddSmall: {
    [theme.breakpoints.down("md")]: {
      padding: "6px 2px 6px 16px",
    },
  },

  urlTh: {
    borderBottom: 0,
    display: "list-item",
    listStyleType: "disc",
    listStylePosition: "outside",
    wordBreak: "break-all",
    marginLeft: 25,
    paddingLeft: 0,
  },

  transeferSizeTh: {
    color: "#a4a3a3",
    fontSize: 13,
    width: 150,
    [theme.breakpoints.down("md")]: {
      width: 80,
    },
  },
  potentialSavingsTh: {
    color: "#a4a3a3",
    fontSize: 13,
    [theme.breakpoints.down("md")]: {
      width: 80,
    },
  },

  tdSeverityTh: {
    width: 100,
    color: "rgb(142 138 138)",
    fontFamily: "Segoe UI",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      width: 10,
    },
  },
}));
