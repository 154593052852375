const userPageStyles = (theme) => ({
  formControl: {
    width: 220,
    maxWidth: 300,
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
    },
  },
  keysFormControl: {
    width: 300,
    maxWidth: 300,
    marginTop: "15px",
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },

  mainBox: {
    padding: "0px",
  },
  table: {
    minWidth: 650,
  },
});

export default userPageStyles;
