import React from 'react';
import Box from '@material-ui/core/Box';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { CircularProgress } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import CveTable from "./CveTable";
import { getCveTechnologies, getTechnologies, addTechnology, deleteTechnology, getCVES } from "../../store/actions/technologiesActions";
import Auth0Service from "../../services/auth0Service/authentikService";
import loaderwave from "../../assets/loader.svg";
import cveMainStyles from '../../Styles/jss/cveMainStyles';
import ScanSelector from "../../components/commons/ScanSelector";

function MainApp() {
  const useStyles = makeStyles(cveMainStyles);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [techData, setTechData] = React.useState([]);
  const [domainTechs, setDomainTech] = React.useState([]);

  const [addTech, setAddTech] = React.useState({ name: "", version: "" });

  const selectedScanId = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );

  const techs = useSelector((state) => state.technologies.cveTechnologies);
  const domainTechnologies = useSelector((state) => state.technologies.domainTechnologies);

  const addLoading = useSelector((state) => state.technologies.addTechLoading);
  const cveLoading = useSelector((state) => state.technologies.cvesLoading);
  const isDomainPurchased = useSelector((state) => state.dashboard.isDomainPackagePurchased);

  const cves = useSelector(
    (state) => state.technologies.cves
  );

  const handleDelete = (chipToDelete) => () => {
    dispatch(deleteTechnology(chipToDelete, selectedScanId));
  };

  const handleAdd = (chipAdd, chipAddIndex) => () => {
    dispatch(addTechnology({
      name: chipAdd.name ? chipAdd.name.toLowerCase() : chipAdd.name,
      version: chipAdd.version ? chipAdd.version.toLowerCase() : chipAdd.version,
    }, selectedScanId));
  };

  React.useEffect(() => {
    if (selectedScanId) {
      dispatch(getTechnologies(selectedScanId));
    } else {
      dispatch(getTechnologies());
    }
    // eslint-disable-next-line
  }, [selectedScanId]);

  React.useEffect(() => {
    if (techs.length > 0) {
      dispatch(getCVES(techs));
      setTechData(techs);
    }
    // eslint-disable-next-line
  }, [techs]);

  React.useEffect(() => {
    if (domainTechnologies && domainTechnologies.length > 0) {
      const filtered = domainTechnologies.filter(a => false === techData.some(b => a.name === b.name));
      setDomainTech(filtered)
    }
  }, [domainTechnologies, techData]);


  React.useEffect(() => {
    if (Auth0Service.isAuthenticated()) {
      dispatch(getCveTechnologies(selectedScanId));
    } else {
      window.location.pathname = '/authenticate'
    }
    // eslint-disable-next-line
  }, [selectedScanId]);

  function handleAddTechnology(event) {
    event.preventDefault();
    dispatch(addTechnology(addTech, selectedScanId));
  }

  const [delayed, setDelayed] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setDelayed(false)
    }, 4000);
  }, [])

  if (!isDomainPurchased) {
    return window.location.pathname = "/dashboard"
  }
  else {
    return (
      <>
        {!delayed ?
          <>
            <ScanSelector />
            <Box alignItems="center">
              <Typography variant="h6" style={{ fontFamiliy: "Segoe UI" }}>Technologies</Typography>
              {techData.length > 0 ? <ul className={classes.chips}>
                {techData.map((data, index) => {
                  let icon;
                  return (
                    <li key={data.key}>
                      <TechChip
                        variant="outlined"
                        clickable
                        icon={icon}
                        label={`${data.name} : ${data.version ? data.version : 'N/A'}`}
                        onDelete={handleDelete(data)}
                        onClick={handleDelete(data)}

                        className={classes.chip}
                      />
                    </li>
                  );
                })}
              </ul> :
                <div className={classes.noTechnology}> <Typography variant="subtitle2"> No Technology Found - Please add your tech stack and/or infrastructure
                </Typography></div>
              }
              <fieldset style={{ borderRadius: 9, marginBottom: "10px" }}>
                <legend style={{ fontFamily: "Segoe UI", fontSize: "16px", fontWeight: 600 }}> Add Technology</legend>
                <form onSubmit={handleAddTechnology}>
                  <Box width="100%" display="felx" alignItems="center" >
                    <TextField id="version" required={true} onChange={(event) => setAddTech({ ...addTech, name: event.target.value })} type="text" label="Name" variant="outlined" className={classes.root} />
                    <TextField id="version" onChange={(event) => setAddTech({ ...addTech, version: event.target.value })} type="text" className={classes.root} label="version" variant="outlined" />
                    <Button variant="contained" type="submit" color="primary" size="lg" disabled={addLoading} style={{ margin: 10, borderRadius: 20 }} > Add</Button>
                  </Box>
                </form>
              </fieldset>
              {domainTechs.length > 0 && <fieldset style={{ borderRadius: 9, marginBottom: "10px" }}>
                <legend style={{ fontFamily: "Segoe UI", fontSize: "16px", fontWeight: 600 }}>Suggestions</legend>
                <ul className={classes.chips}>
                  {domainTechs.filter(domainTech => !techData.includes(domainTech)).map((data, index) => {
                    let icon;
                    return (
                      <li key={data.key}>
                        <SuggestChip
                          variant="outlined"
                          clickable
                          deleteIcon={<DoneIcon />}
                          icon={icon}
                          label={`${data.name} : ${data.version ? data.version : 'N/A'}`}
                          onDelete={handleAdd(data, index)}
                          onClick={handleAdd(data, index)}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })}
                </ul>
              </fieldset>
              }

              {!cveLoading ?
                <CveTable data={cves} techs={techs} /> :
                <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>}
            </Box>
          </> :
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            tabIndex="0"
          >
            <img src={loaderwave} alt="loader" />
          </Box>
        }
      </>
    );
  }
}
export default MainApp;


const TechChip = withStyles({
  root: {
    border: "1px solid #e2211e",
    color: 'black',

  },
  label: {
    textTransform: 'capitalize',
    fontWeight: "bold",
    fontSize: 14,
    color: "#0eb145"
  },
  deleteIcon: {
    color: "#e2211e"
  }
})(Chip);

const SuggestChip = withStyles({
  root: {
    border: "1px solid #e2211e",
    color: 'black'
  },
  label: {
    textTransform: 'capitalize',
    fontWeight: "bold",
    fontSize: 14,

    color: "#0eb145"
  },
  deleteIcon: {
    color: "#0eb145"
  }
})(Chip);