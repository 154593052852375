import auth0Service from "../../services/auth0Service/authentikService";
import { getTableAccessibilityTableOne, getTopTenVoilationTable, getTopTenCriteriaTable } from "./dashboardActions";
import axios from "axios";
import { BASE_URL } from "../../source/constants";
import { toast } from "react-toastify";

export const GET_ARCHIVED_ACCESSIBILITY_ISSUES =
  "GET_ARCHIVED_ACCESSIBILITY_ISSUES";
export const GET_ARCHIVED_ACCESSIBILITY_ISSUES_LOADING =
  "GET_ARCHIVED_ACCESSIBILITY_ISSUES_LOADING";

export const GET_NEW_ACCESSIBILITY_ISSUES = "GET_NEW_ACCESSIBILITY_ISSUES";
export const GET_NEW_ACCESSIBILITY_ISSUES_LOADING =
  "GET_NEW_ACCESSIBILITY_ISSUES_LOADING";

export const GET_COMPLETED_ACCESSIBILITY_ISSUES =
  "GET_COMPLETED_ACCESSIBILITY_ISSUES";
export const GET_COMPLETED_ACCESSIBILITY_ISSUES_LOADING =
  "GET_COMPLETED_ACCESSIBILITY_ISSUES_LOADING";

export const GET_IGNORED_ACCESSIBILITY_ISSUES =
  "GET_IGNORED_ACCESSIBILITY_ISSUES";
export const GET_IGNORED_ACCESSIBILITY_ISSUES_LOADING =
  "GET_IGNORED_ACCESSIBILITY_ISSUES_LOADING";

export const GET_SECTION_508_TABLE = "GET_SECTION_508_TABLE";


export const getSection508Table = (scanId, page) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
      page,
      limit: 7
    };
    const res = await axios.get(`${BASE_URL}/dashboard/accessibility-section508-table`, {
      params,
      ...options,
    });

    if (res.data) {

      dispatch({
        type: GET_SECTION_508_TABLE,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};


export const getAccessibilityArchivedDetails = (scanId) => async (dispatch) => {
  dispatch({
    type: GET_ARCHIVED_ACCESSIBILITY_ISSUES_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const params = {
      scanId,
    };

    const res = await axios.get(
      `${BASE_URL}/dashboard/archived-accessibility-issues`,
      {
        params,
        ...options,
      }
    );

    if (res.data) {
      dispatch({
        type: GET_ARCHIVED_ACCESSIBILITY_ISSUES,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};

export const getAccessibilityNewDetails = (scanId,page) => async (dispatch) => {
  dispatch({
    type: GET_NEW_ACCESSIBILITY_ISSUES_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const params = {
      scanId,page,limit:7
    };

    const res = await axios.get(
      `${BASE_URL}/dashboard/new-accessibility-issues`,
      {
        params,
        ...options,
      }
    );

    if (res.data) {
      dispatch({
        type: GET_NEW_ACCESSIBILITY_ISSUES,
        payload: res.data,
      });
    }
  } catch (err) {
    // if (!err.response) {
    //   toast.warn('Warning ⚠️ bad internet connection.');
    // }
  }
};

export const archiveAccessibilityLinks = (
  archiveOptions,
  scanId
) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${BASE_URL}/dashboard/archive-accessibility-issues`,
      {
        ...archiveOptions,
      },
      options
    );

    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch(getTableAccessibilityTableOne(scanId,1, []));
      dispatch(getTopTenVoilationTable(scanId, []));
      dispatch(getTopTenCriteriaTable(scanId, []));
      dispatch(getAccessibilityArchivedDetails(scanId));
    }
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export const fixAccessibilityIssues = (
  issues
) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${BASE_URL}/dashboard/fix-accessibility-issues-wp`,
      {
        ...issues,
      },
      options
    );

    if (res.status === 200) {
      // toast.success(res.data.message);
      // dispatch(getTableAccessibilityTableOne(scanId,1, []));
      // dispatch(getTopTenVoilationTable(scanId, []));
      // dispatch(getTopTenCriteriaTable(scanId, []));
      // dispatch(getAccessibilityArchivedDetails(scanId));
    }
  } catch (err) {
    toast.error(err.response.data.message);
  }
};
export const unArchiveAccessibilityLinks = (postData, scanId) => async (
  dispatch
) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${BASE_URL}/dashboard/unarchive-accessibility-issues`,
      {
        ...postData,
      },
      options
    );

    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch(getAccessibilityArchivedDetails(scanId));
      dispatch(getTableAccessibilityTableOne(scanId,1, []));
      dispatch(getTopTenVoilationTable(scanId, []));
      dispatch(getTopTenCriteriaTable(scanId, []));
    }
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export const getAccessibilityCompletedDetails = (scanId) => async (
  dispatch
) => {
  dispatch({
    type: GET_COMPLETED_ACCESSIBILITY_ISSUES_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const params = {
      scanId,
    };

    const res = await axios.get(
      `${BASE_URL}/dashboard/completed-accessibility-issues`,
      {
        params,
        ...options,
      }
    );

    if (res.data) {
      dispatch({
        type: GET_COMPLETED_ACCESSIBILITY_ISSUES,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};
export const unCompleteAccessibilityLinks = (postData, scanId) => async (
  dispatch
) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${BASE_URL}/dashboard/uncomplete-accessibility-issues`,
      {
        ...postData,
      },
      options
    );

    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch(getAccessibilityCompletedDetails(scanId));
      dispatch(getTableAccessibilityTableOne(scanId,1, []));
      dispatch(getTopTenVoilationTable(scanId, []));
      dispatch(getTopTenCriteriaTable(scanId, []));
    }
  } catch (err) {
    toast.error(err.response.data.message);
  }
};
export const completeAccessibilityLinks = (
  issues,
  scanId
) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${BASE_URL}/dashboard/complete-accessibility-issues`,
      {
        ...issues,
      },
      options
    );

    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch(getTableAccessibilityTableOne(scanId,1, []));
      dispatch(getTopTenVoilationTable(scanId, []));
      dispatch(getTopTenCriteriaTable(scanId, []));
      dispatch(getAccessibilityCompletedDetails(scanId));
    }
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export const getAccessibilityIgnoredDetails = (scanId) => async (dispatch) => {
  dispatch({
    type: GET_IGNORED_ACCESSIBILITY_ISSUES_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const params = {
      scanId,
    };

    const res = await axios.get(
      `${BASE_URL}/dashboard/ignored-accessibility-issues`,
      {
        params,
        ...options,
      }
    );

    if (res.data) {
      dispatch({
        type: GET_IGNORED_ACCESSIBILITY_ISSUES,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};
export const ignoreAccessibilityLinks = (
  issues,
  scanId
) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${BASE_URL}/dashboard/ignore-accessibility-issues`,
      {
        ...issues,
      },
      options
    );

    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch(getTableAccessibilityTableOne(scanId,1, []));
      dispatch(getTopTenVoilationTable(scanId, []));
      dispatch(getTopTenCriteriaTable(scanId, []));
      dispatch(getAccessibilityIgnoredDetails(scanId));
    }
  } catch (err) {
    toast.error(err.response.data.message);
  }
};
export const unIgnoreAccessibilityLinks = (
  postData,
  scanId
) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${BASE_URL}/dashboard/unignore-accessibility-issues`,
      {
        ...postData,
      },
      options
    );

    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch(getAccessibilityIgnoredDetails(scanId));
      dispatch(getTableAccessibilityTableOne(scanId,1, []));
      dispatch(getTopTenVoilationTable(scanId, []));
      dispatch(getTopTenCriteriaTable(scanId, []));
    }
  } catch (err) {
    toast.error(err.response.data.message);
  }
};
