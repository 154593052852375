import {
  ADD_DOMAIN_ONBOARD_LOADING,
  ADD_DOMAIN_ONBOARD_SUCCESS,
  GET_ONBOARD_DOMAIN_DATA,
  GET_SCAN_PROGRESS,
  SEND_REPORT_LOADING,
  ADD_DOMAIN_FORBIDDEN,
  CLEAR_FORBIDDEN_ERROR
} from "../actions/onBoardingActions";

const initialState = {
  status: null,
  addDomainOnBoardLoading: false,
  addDomainonBoardSuccess: "",
  getDomainPages: null,
  data: null,
  progress: 0,
  next: false,
  forbiddenError: null,
  pages: [],
  sendReportLoading: false,
  scanProgress: {
    status: "completed",
    message: "",
    percentage: 0,
  },
};

const onBoardDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_DOMAIN_ONBOARD_LOADING:
      return {
        ...state,
        addDomainOnBoardLoading: !state.addDomainOnBoardLoading,
      };
    case ADD_DOMAIN_ONBOARD_SUCCESS:
      return {
        ...state,
        addDomainonBoardSuccess: action.payload,
      };
    case GET_ONBOARD_DOMAIN_DATA:
      return {
        ...state,
        status: action.payload.status,
        data: action.payload.data,
        progress: action.payload.percentage,
        pages: action.payload.pages,
        next: action.payload.next,
      };
    case ADD_DOMAIN_FORBIDDEN:
      return {
        ...state,
        forbiddenError: action.payload,
      };
    case CLEAR_FORBIDDEN_ERROR:
      return {
        ...state,
        forbiddenError: null,
      };
    case GET_SCAN_PROGRESS:
      return {
        ...state,
        scanProgress: action.payload,
      };
    case SEND_REPORT_LOADING:
      return {
        ...state,
        sendReportLoading: !state.sendReportLoading,
      };
    default:
      return state;
  }
};
export default onBoardDashboardReducer;