import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "100%",
    boxShadow: 'rgba(31, 58, 147, 0.1) 0px 3px 25px',
    margin: "10px 0px",
    borderRadius: '9px'
  },

}));

const CardContainer = ({ children, title }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      {title &&
        <Box px={3} pt={2}> <h4 style={{margin:0,fontSize:20, fontWeight:400}} >{title}</h4> </Box>
      }
      <CardContent>
        {children}
      </CardContent>
    </Card>
  );

}

export default CardContainer;