
const nFormatter = (num, minValue) => {

  if (!num || !+num || typeof +num !== 'number') {
    return num;
  }

  num = +num;

  minValue = minValue || 0;
  let si = [
    { value: 1E18, symbol: "E" },
    { value: 1E15, symbol: "P" },
    { value: 1E12, symbol: "T" },
    { value: 1E9, symbol: "G" },
    { value: 1E6, symbol: "M" },
    { value: 1E3, symbol: "k" }
  ], i;

  if (typeof num === 'number' && num >= minValue) {
    for (i = 0; i < si.length; i++) {
      if (num >= si[i].value) {
        // return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;

        return `${(num / si[i].value).toFixed(0)}${(si[i].symbol)}`;
      }
    }
  }
  //return num.replace(rx, "$1");
  return num;
};


export {
  nFormatter
}