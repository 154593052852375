import {
  SSL,
  SSL_LOADING,
  OPEN_DB_LOADING,
  OPEN_DB,
  XSS,
  XSS_LOADING,
  SQLI,
  SQLI_LOADING,
  SUBDOMAIN_TAKEOVER,
  SUBDOMAIN_TAKEOVER_LOADING,
  CORS,
  CORS_LOADING,
  DDOS,
  DDOS_LOADING,
  LFI,
  LFI_LOADING,
  SQLI_RESULTS,
  XSS_RESULTS,
  LFI_RESULTS,
  SECURITY_MAIN,
  SECURITY_MAIN_LOADING,
  SECURITY_MAIN_TOP5,
  SECURITY_MAIN_TOP5_LOADING,
  SQLI_RESET,
  XSS_RESET,
  XSS_RESULTS_LOADING,
  SQLI_RESULTS_LOADING,
  LFI_RESULTS_LOADING,
  GET_SECURITY_HISTORY,
  LOADING_SECURITY_HISTORY,
  SQLI_NEW_RESULTS,
  XSS_NEW_RESULTS,
  LFI_NEW_RESULTS,
  SQLI_NEW_RESULTS_LOADING,
  XSS_NEW_RESULTS_LOADING,
  LFI_NEW_RESULTS_LOADING,
  CVES,
  CVES_LOADING,
  BREACHED,
  BREACHED_LOADING
} from '../actions/securityActions';

const initialState = {

  sqliData: null,
  sqliDataLoading: false,
  sqliDataStatus: "pending",

  xssData: null,
  xssDataLoading: false,
  xssDataStatus: "pending",

  lfiData: null,
  lfiDataLoading: false,
  lfiDataStatus: "pending",

  sqliNewData: null,
  sqliNewDataLoading: false,
  sqliNewDataStatus: "pending",

  xssNewData: null,
  xssNewDataLoading: false,
  xssNewDataStatus: "pending",

  lfiNewData: null,
  lfiNewDataLoading: false,
  lfiNewDataStatus: "pending",

  sqli: null,
  sqliStatus: null,
  sqliLoading: true,

  xss: null,
  xssStatus: null,
  xssLoading: true,

  ssl: null,
  sslStatus: null,
  sslLoading: true,

  openDB: null,
  openDBStatus: null,
  openDBLoading: true,

  subDomainTakeOver: null,
  subDomainTakeOverStatus: null,
  subDomainTakeOverLoading: true,

  cors: null,
  corsStatus: null,
  corsLoading: true,


  cve: null,
  cveStatus: null,
  cveLoading: true,


  breached: null,
  breachedStatus: null,
  breachedLoading: true,

  ddos: null,
  ddosStatus: null,
  ddosLoading: true,

  lfi: null,
  lfiStatus: null,
  lfiLoading: true,

  securityMain: null,
  securityMainStatus: null,
  securityMainLoading: true,

  securityMainTop5: null,
  securityMainTop5Status: null,
  securityMainTop5Loading: true,

  sqliCount: 0,

  httpsLoading: false,
  httpsData: null,

  xssCount: 0,

  securityHistory: null,
  securityHistoryLoading: false,
  securityHistoryStatus: null,
};

const securityReducer = (state = initialState, action) => {
  switch (action.type) {
    case SECURITY_MAIN_LOADING:
      return {
        ...state,
        securityMainLoading: true,
      }

    case SECURITY_MAIN:
      return {
        ...state,
        securityMain: action.payload.result,
        securityMainStatus: action.payload.status,
        securityMainLoading: false,
      }

    case SECURITY_MAIN_TOP5_LOADING:
      return {
        ...state,
        securityMainTop5Loading: true,
      }

    case SECURITY_MAIN_TOP5:
      return {
        ...state,
        securityMainTop5: action.payload.result,
        securityMainTop5Status: action.payload.status,
        securityMainTop5Loading: false,
      }

    case SQLI_LOADING:
      return {
        ...state,
        sqliLoading: true,
      }
    case SQLI_RESET:
      return {
        ...state,
        sqli: { ...initialState.sqli },
      }
    case XSS_RESET:
      return {
        ...state,
        xss: { ...initialState.xss },
      }
    case SQLI:
      return {
        ...state,
        sqli: action.payload.result,
        sqliStatus: action.payload.status,
        sqliLoading: false,
      }

    case OPEN_DB_LOADING:
      return {
        ...state,
        openDBLoading: true,
      }

    case OPEN_DB:
      return {
        ...state,
        openDB: action.payload.result,
        openDBStatus: action.payload.status,
        openDBLoading: false,
      }

    case SSL_LOADING:
      return {
        ...state,
        sslLoading: true,
      }

    case SSL:
      return {
        ...state,
        ssl: action.payload.result,
        sslStatus: action.payload.status,
        sslLoading: false,
      }

    case XSS_LOADING:
      return {
        ...state,
        xssLoading: true,
      }

    case XSS:
      return {
        ...state,
        xss: action.payload.result,
        xssStatus: action.payload.status,
        xssLoading: false,
      }

    case SUBDOMAIN_TAKEOVER_LOADING:
      return {
        ...state,
        subDomainTakeOverLoading: true,
      }

    case SUBDOMAIN_TAKEOVER:
      return {
        ...state,
        subDomainTakeOver: action.payload.result,
        subDomainTakeOverStatus: action.payload.status,
        subDomainTakeOverLoading: false,
      }

    case CORS_LOADING:
      return {
        ...state,
        corsLoading: true,
      }

    case CORS:
      return {
        ...state,
        cors: action.payload.result,
        corsStatus: action.payload.status,
        corsLoading: false,
      }

    case CVES_LOADING:
      return {
        ...state,
        cveLoading: true,
      }

    case CVES:
      return {
        ...state,
        cve: action.payload.result,
        cveStatus: action.payload.status,
        cveLoading: false,
      }

    case BREACHED_LOADING:
      return {
        ...state,
        breachedLoading: true,
      }

    case BREACHED:
      return {
        ...state,
        breached: action.payload.result,
        breachedStatus: action.payload.status,
        breachedLoading: false,
      }

    case DDOS_LOADING:
      return {
        ...state,
        ddosLoading: true,
      }

    case DDOS:
      return {
        ...state,
        ddos: action.payload.result,
        ddosStatus: action.payload.status,
        ddosLoading: false,
      }

    case LFI_LOADING:
      return {
        ...state,
        lfiLoading: true,
      }

    case LFI:
      return {
        ...state,
        lfi: action.payload.result,
        lfiStatus: action.payload.status,
        lfiLoading: false,
      }


    case SQLI_RESULTS:
      return {
        ...state,
        sqliData: action.payload.result.issues,
        sqliDataStatus: action.payload.status,
        sqliDataLoading: false
      }
    case XSS_RESULTS:
      return {
        ...state,
        xssData: action.payload.result.issues,
        xssDataStatus: action.payload.status,
        xssDataLoading: false
      }
    case LFI_RESULTS:
      return {
        ...state,
        lfiData: action.payload.result.issues,
        lfiDataStatus: action.payload.status,
        lfiDataLoading: false
      }

    case SQLI_RESULTS_LOADING:
      return {
        ...state,

        sqliDataLoading: true
      }
    case XSS_RESULTS_LOADING:
      return {
        ...state,

        xssDataLoading: true
      }
    case LFI_RESULTS_LOADING:
      return {
        ...state,

        lfiDataLoading: true
      }



    case SQLI_NEW_RESULTS:
      return {
        ...state,
        sqliNewData: action.payload.result.issues,
        sqliNewDataStatus: action.payload.status,
        sqliNewDataLoading: false
      }
    case XSS_NEW_RESULTS:
      return {
        ...state,
        xssNewData: action.payload.result.issues,
        xssNewDataStatus: action.payload.status,
        xssNewDataLoading: false
      }
    case LFI_NEW_RESULTS:
      return {
        ...state,
        lfiNewData: action.payload.result.issues,
        lfiNewDataStatus: action.payload.status,
        lfiNewDataLoading: false
      }

    case SQLI_NEW_RESULTS_LOADING:
      return {
        ...state,

        sqliNewDataLoading: true
      }
    case XSS_NEW_RESULTS_LOADING:
      return {
        ...state,

        xssNewDataLoading: true
      }
    case LFI_NEW_RESULTS_LOADING:
      return {
        ...state,

        lfiNewDataLoading: true
      }

    case GET_SECURITY_HISTORY:
      return {
        ...state,
        securityHistory: action.payload.result.graph,
        securityHistoryStatus: action.payload.status,
        securityHistoryLoading: false
      }
    case LOADING_SECURITY_HISTORY:
      return {
        ...state,
        securityHistoryLoading: true
      }

    default:
      return state;
  }
};


export default securityReducer;