import React from "react";

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import AccessibilityBar from "../../barchart/AccessibilityBar";
import { useSelector, useDispatch } from 'react-redux';
import { getSection508History } from '../../../store/actions/accessibilityCardActions';
import allIcon from "../../../assets/new-accessibility-icons/all.png";

import SubLoader from '../../commons/SubLoader'




export default function Section508History({ setLoader, dateRange, scanId }) {

  const [value, setValue] = React.useState(0);

  const dispatch = useDispatch();

  const data = useSelector(state => state.accessibilityCard.section508HistoryData);
  const status = useSelector(state => state.accessibilityCard.section508HistoryStatus);
  const loading = useSelector(state => state.accessibilityCard.section508HistoryLoading);



  React.useEffect(() => {
    if (dateRange && scanId) {
      dispatch(getSection508History(dateRange, scanId));
    }
    // eslint-disable-next-line
  }, [scanId, dateRange]);


  React.useEffect(() => {
    if (status === "pending" || status === "running") {
      setLoader(true)
      const interval1 = setInterval(() => {
        dispatch(getSection508History(dateRange, scanId));
      }, 15000);

      return () => clearInterval(interval1);
    } else {
      setLoader(false)
    }
    // eslint-disable-next-line
  }, [scanId, status])

  return (
    <>
      {loading ? <Box display="flex" alignItems="center" justifyContent="center" style={{ height: "474px", maxHeight: "474px", boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.15)" }}> <SubLoader /> </Box> :
        <>
          {data ?
            <React.Fragment>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={3}>
                  <Box display="flex" alignItems="center" justifyContent="center">
                    {value === 0 && <img src={allIcon} alt="accessibility-icon" />}
                  </Box>
                </Grid>
              </Grid>
              <AccessibilityBar data={data.graph} />
            </React.Fragment> :
            <div>found no data</div>}
        </>}
    </>

  );
}

