import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiTabs from '@material-ui/core/Tabs';

const AntTabs = withStyles({
  indicator: {
    backgroundColor: '#1890ff',
  },
})(MuiTabs);


const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    flexGrow: 1,

  },
}));

export default function Tabs(props) {
  const classes = useStyles();


  return (
  
      <div className={classes.demo1}>
        <AntTabs {...props} >
          {props.children}
        </AntTabs>
      </div>
    
  );
}
