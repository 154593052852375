import React, { useState, useEffect } from "react";
import Loader from "../../components/commons/Loader";
import { Add } from "@material-ui/icons";
// import { MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
// import "react-data-grid/lib/styles.css";
import DataGrid from "react-data-grid";
import { Editors } from "react-data-grid-addons";

// import DataGrid from "react-data-grid";

import {
  Typography,
  Box,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getPrivacySheet,
  addSheetData,
} from "../../store/actions/sheets.action";
import StatisticsCard from "./StatisticsCard";
import "./app.css";
import { Data } from "react-data-grid-addons";
import "../../Styles/css/IssueTrackerTables.css"


const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  width: 150,
  editable: true,
};

const Sheet = () => {
  const dispatch = useDispatch();
  const domainId = useSelector((state) => state.dashboard.selectedDomainId);
  const {
    addPrivacySheetLoading,
    getPrivacySheetLoading,
    privacySheet,
    sheetStatics,
    sheetStaticsLoading,
  } = useSelector((state) => state.sheets);

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const addRow = () => {
    setRows([
      ...rows,
      {
        title: "",
        link: "",
        resolved: false,
        description: "",
        priority: "",
        typeOfIssue: "",
        severity: "",
        dueDate: "",
        status: "",
      },
    ]);
  };
  useEffect(() => {
    dispatch(getPrivacySheet(domainId, "privacy"));
    // dispatch(getSheetStatistics());
  }, []);
  const { DropDownEditor } = Editors;
  const priorityTypes = [
    { id: "aaa", value: "aaa" },
    { id: "aa", value: "aa" },
    { id: "a", value: "a" },
  ];
  const severityTypes = [
    { id: "high", value: "High" },
    { id: "medium", value: "Medium" },
    { id: "low", value: "Low" },
  ];
  const priorityEditor = <DropDownEditor options={priorityTypes} />;
  const severityEditor = <DropDownEditor options={severityTypes} />;


  useEffect(() => {
    if (privacySheet)
      if (privacySheet.rows.length > 0) {
        const values = privacySheet.rows.map((row, i) => {
          if (row["resolved"] === false) {
            row["resolved"] = "no";
          } else if (row["resolved"] === true) {
            row["resolved"] = "yes";
          }
          return { id: i, ...row };
        });
        setRows([...values, ...rows]);
      }
  }, [privacySheet]);

  React.useEffect(() => {
    if (privacySheet && privacySheet.columns) {
      const colSetting = privacySheet.columns.map((col, i) => {
        let colName = "";
        if (col === "typeOfIssue") {
          colName = "Type of issue";
        } else if (col === "dueDate") {
          colName = "Due date";
        } else {
          colName = col;
        }
        if (col === "severity") {
          return { id: i, key: col, name: col, editor: severityEditor };
        } else if (col === "priority") {
          return { id: i, key: col, name: col, editor: priorityEditor };
        } else {

          return { id: i, key: col, name: colName };
        }
      });
      console.log("colSetting:: ", colSetting);
      setColumns([...columns, ...colSetting]);
    }
  }, [privacySheet]);

  const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    console.log("fromRow:: ", fromRow);
    console.log("toRow:: ", toRow);

    setRows((state) => {
      console.log("state rows:: ", state);
      const newRows = state.slice();
      console.log("newRows:: ", newRows);

      for (let i = fromRow; i <= toRow; i++) {
        newRows[i] = { ...newRows[i], ...updated };
      }
      console.log("new rows:: ", newRows);
      return newRows;
    });
  };

  return (
    <>
      {getPrivacySheetLoading ? (
        <Loader />
      ) : (
        <div
          style={{
            display: "",
          }}
        >
          <Box display="flex" p={1}>
            <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
              Privacy Sheet
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent="center" p={1}>
            <StatisticsCard
              sheetStatics={sheetStatics}
              loading={sheetStaticsLoading}
              title="Web Privacy"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: 1,
            }}
          >
            <Tooltip title="add row" arrow placement="top-start">
              <IconButton
                style={{
                  background: "#1f3a93",
                  color: "white",
                }}
                onClick={addRow}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            style={{
              // display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              padding: "20px",
            }}
          >
            {privacySheet &&
              privacySheet.columns &&
              privacySheet.columns.length > 0 && (
                <Box mt={2}>
                  <DataGrid
                    ed
                    columns={columns.map((c) => ({
                      ...c,
                      ...defaultColumnProperties,
                    }))}
                    rowGetter={(i) => rows[i]}
                    rowsCount={rows.length}
                    onGridRowsUpdated={onGridRowsUpdated}
                    enableCellSelect={true}
                  />
                </Box>
              )}
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              disabled={addPrivacySheetLoading}
              onClick={(e) => {
                dispatch(
                  addSheetData({
                    domainId,
                    tableData: {
                      name: "privacy",
                      title: "privacy",
                      type: "privacy",
                      rows,
                    },
                  })
                );
              }}
            >
              Save Data
              {addPrivacySheetLoading && (
                <CircularProgress size={12} sx={{ ml: 1, color: "blue" }} />
              )}
            </Button>
          </Box>
        </div>
      )}
    </>
  );
};

export default Sheet;
