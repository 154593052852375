import { useSelector } from "react-redux";

import IssueTrackerTablePerformance from "../components/commons/IssueTrackerTablePerformance";


export default function PerformanceSpredSheet() {
    const selectedDomainId = useSelector(state => state.dashboard.selectedDomainId);

    return (
        <>
            <IssueTrackerTablePerformance title="Performance Issue Sheet" domainId={selectedDomainId} />

        </>
    )
}