import * as Actions from '../actions';

const initialState = {
	role: [],
	data: {
		name: '',
		email: '',
		image: '',
		designation: '',
		socialLogin: '',
		email_verified: '',
		returning_customer: false,
		permissions:{
			addScan: false,
			cancelScan: false,
			inviteUser: false,
			removeUser: false,
			blockUser: false,
			userRole: false,
			editUser: false,
			addDomain: false,
			deleteDomain: false,
			changeSubscription: false,
			addIssueTable: false,
			editIssueTable: false,
			deleteIssueTable: false,
			addBoard: false,
			removeBoard: false,
			addList: false,
			removeList: false,
			addCard: false,
			editCard: false,
			removeCard: false,
			chat: false,
			helpAndSupport: false,
		},
		tour: {
			dashboardPage: false,
			customScanPage: false,
			financialPage: false,
			violationReportPage: false,
		}
	},
	zapierApiKey:'',
	orgName: '',
	isPackageSelected: false,
	isPackagePurchased: false,
	isDomainAdded: false,
	remainingWebsites: 0,
	selectedPackage: null,
	loading: false,
};

const user = (state = initialState, action) => {
	switch (action.type) {
		case Actions.SET_USER_DATA: {
			return {
				...initialState,
				...action.payload
			};
		}
		case Actions.REMOVE_USER_DATA: {
			return {
				...initialState
			};
		}
		case Actions.USER_LOGGED_OUT: {
			return initialState;
		}
		case Actions.REMAINING_WEBSITES: {
			return {
				...state,
				remainingWebsites: state.remainingWebsites - 1
			}
		}
		case Actions.LOADING_UPDATE: {
			return {
				...state,
				loading: !state.loading
			}
		}
		case Actions.USER_PASS_CHANGED:
		case Actions.USER_UPDATED_SUCCESS:
		default: {
			return state;
		}
	}
};

export default user;
