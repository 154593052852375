import React, { useState } from 'react';

const JsonViewer = ({ jsonData }) => {
  const [open, setOpen] = useState(false);

  const renderTable = (data) => {
    if (typeof data === 'object' && data !== null) {
      return (
        <table style={{ borderCollapse: 'collapse', marginTop: '5px' }}>
          <tbody>
            {Object.keys(data).map((key, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid black', padding: '5px', fontWeight: 'bold' }}>{key}</td>
                <td style={{ border: '1px solid black', padding: '5px' }}>
                  <JsonViewer jsonData={data[key]} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
    return <span>{String(data)}</span>;
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div style={{ display: 'inline-block', border: '1px solid #000', padding: '3px' }}>
      <div
        style={{
          display: 'inline-block',
          width: '20px',
          height: '20px',
          border: '1px solid #000',
          borderRadius: '3px',
          textAlign: 'center',
          cursor: 'pointer',
          lineHeight: '20px', // Match the height for vertical alignment
          verticalAlign: 'middle', // Center vertically
          marginRight: '5px',
          fontSize: '18px'
        }}
        onClick={toggleOpen}
      >
        {open ? '\u2212' /* Unicode character for minus sign */ : '\u002B' /* Unicode character for plus sign */}
      </div>
      {open && renderTable(jsonData)}
    </div>
  );
};

export default JsonViewer;