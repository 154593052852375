import React from 'react';
import { Box } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
const Page = ({
  count,
  handleChangePage,
  page,
}) => {

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" py={2}>
        <Pagination
          count={count}
          color='primary'
          variant='outlined'
          defaultPage={1}
          siblingCount={0}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
    </>
  );
};

export default Page;
