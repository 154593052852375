import React from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const styles = {
  cardMain: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.12)',
    height: "100%",
    padding: "12px 10px",
    marginTop: "10px",
  },
  boxContainer: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.12)',
    boxSizing: 'border-box',
    borderRadius: 9,
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
    height: "100%"
  },
  top5: {
    fontFamily: "Segoe UI",
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 16,
    // marginRight: "20px",
    marginBottom: 5,
    // marginTop: 9
  },
  infoIcon: {
    width: 15,
    height: 15,
  },
  pType: {
    marginRight: 20,
    width: 46.6
  },
  top5Subheading: {
    fontFamily: "Segoe UI",
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    marginRight: "20px",
    marginBottom: 5,
    marginTop: 9
  },
}

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  table: {
    minWidth: 280,
    overflow: "auto"
  },
  IconButton: {
    marginLeft: 20,
    color: "black",
    padding: 0
  }
}));

// const data = [
//   {
//     columns: '250px',
//     title: 'Web Page Link',
//     rows: 'wplinks',
//   },
//   {
//     columns: '200px',
//     title: 'Disability Type',
//     rows: 'disabilitytype',
//   },
//   {
//     columns: '150px',
//     title: 'Violation',
//     rows: 'voilation',
//   },
//   {
//     columns: '150px',
//     title: 'Criteria',
//     rows: 'criteria',
//   },
//   {
//     columns: '300px',
//     title: 'Short Explanation',
//     rows: 'shortexp',
//   },
// ]



function Top5RepeadtedMistakes({ columns, rows }) {
  const classes = useStyles();
  // const shortenText = (text) => {
  //   const shortText = ` ${text.substring(0, 15)}`
  //   return shortText;
  // };

  return (
    <Box mt={-1} height="100%">
      <Card style={styles.cardMain}>
        <Box display="flex" flexWrap="wrap">
          <Box width="100%" alignItems="center" display="flex" justifyContent="space-between" flexWrap="wrap">
            <div>
              <span style={styles.top5} tabIndex="0">Most Frequent Repeated Mistakes</span>
            </div>
          </Box>
        </Box>

        {rows.length > 0 ?
          <TableContainer tabIndex="0" style={{ minHeight: "195px" }}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((td, index) => {
                    return (
                      <THead key={index} style={{ width: td.width }}>{td.column}</THead>
                    )
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((td, index) => {
                  return (
                    <TableRow key={index} >
                      {/* <TData component="th" scope="row"><ShortenNumber>{td.count}</ShortenNumber></TData> */}
                      <TDType><Box display="flex" alignItems="center" ><span style={{ display: "flex", justifyContent: "space-between", minWidth: "135px" }} >{td.count}
                      </span>
                      </Box></TDType>
                      <TDPriority><span style={{ display: "flex", justifyContent: "space-between", minWidth: "135px" }} >{td.word}
                      </span>
                      </TDPriority>
                    </TableRow>
                  )
                })
                }
              </TableBody>
            </Table>
          </TableContainer>
          :
          <Box style={{ height: "195px" }} display="flex" justifyContent="center" alignItems="center">
            <span>No Mistakes found</span>
          </Box>
        }
      </Card>
    </Box >
  );
}
export default React.memo(Top5RepeadtedMistakes)

const THead = withStyles((theme) => ({
  head: {
    fontSize: 16,
    fontFamily: 'Segoe UI',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0em',
    textAlign: 'left',
    padding: 5,
  },
}))(TableCell);

// const TData = withStyles((theme) => ({
//   body: {
//     fontFamily: 'Segoe UI',
//     fontStyle: 'normal',
//     fontWeight: 'normal',
//     fontSize: 13,
//     padding: "0px",
//     letterSpacing: '0.01em',
//     color: '#212225',
//     textAlign: 'left',
//     border: "none"

//   },
// }))(TableCell);

const TDType = withStyles((theme) => ({
  body: {
    fontFamily: 'Segoe UI',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 13,
    padding: "0px",
    letterSpacing: '0.01em',
    color: '#212225',
    textAlign: 'left',
    border: "none"

  },
}))(TableCell);

const TDPriority = withStyles((theme) => ({
  body: {
    fontFamily: 'Segoe UI',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 13,
    padding: "7px 7px",
    letterSpacing: '0.01em',
    color: '#212225',
    textAlign: 'left',
    border: "none",
    // display: "flex",
    // justifyContent: "flex-start",
    // alignItems: "center",
  },
}))(TableCell);


