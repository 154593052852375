import {
  GET_ARCHIVED_SECURITY_ISSUES,
  GET_ARCHIVED_SECURITY_ISSUES_LOADING,
  GET_COMPLETED_SECURITY_ISSUES,
  GET_COMPLETED_SECURITY_ISSUES_LOADING,
  GET_IGNORED_SECURITY_ISSUES,
  GET_IGNORED_SECURITY_ISSUES_LOADING,
  GET_CVE_EXPLOITS_TABLE,
  GET_CVE_EXPLOITS_TABLE_ARCHIVED,
  GET_CVE_EXPLOITS_TABLE_DISMISSED,
  GET_BREACHED_INFO,
  GET_ARCHIVED_BREACHED_INFO
} from '../actions/securityTablesActions';

const initialState = {
  securityArchivedData: [],
  securityArchivedDataLoading: false,

  cveExploitsTable: null,
  cveExploitsTableArchived: null,
  cveExploitsTableDismissed: null,

  cveExploitsTableStatus: "pending",

  breachedInfo: [],
  breachedInfoCols: [],
  breachedInfoPages: 0,
  breachedInfoCount: 0,
  breachedInfoLoading: false,

  archivedBreachedInfo: [],
  archivedBreachedInfoCols: [],
  archivedBreachedInfoPages: 0,
  archivedBreachedInfoCount: 0,
  archivedBreachedInfoLoading: false,
};

const securityTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARCHIVED_SECURITY_ISSUES:
      return {
        ...state,
        securityArchivedData: action.payload.result.issues,
        loading: false
      }
    case GET_ARCHIVED_SECURITY_ISSUES_LOADING:
      return {
        ...state,
        securityArchivedDataLoading: true
      }
    case GET_COMPLETED_SECURITY_ISSUES:
      return {
        ...state,
        securityCompletedData: action.payload.result.issues,
        loading: false
      }
    case GET_COMPLETED_SECURITY_ISSUES_LOADING:
      return {
        ...state,
        securityCompletedDataLoading: true
      }
    case GET_IGNORED_SECURITY_ISSUES:
      return {
        ...state,
        securityIgnoredData: action.payload.result.issues,
        loading: false
      }
    case GET_IGNORED_SECURITY_ISSUES_LOADING:
      return {
        ...state,
        securityIgnoredDataLoading: true
      }
    case GET_CVE_EXPLOITS_TABLE:
      return {
        ...state,
        cveExploitsTable: action.payload,
        cveExploitsTableOnePages: action.payload.pages,

        cveExploitsTableOneCount: action.payload.count,
        cveExploitsTableStatus: action.payload.status,
      };

    case GET_CVE_EXPLOITS_TABLE_ARCHIVED:
      return {
        ...state,
        cveExploitsTableArchived: action.payload,
      };
    case GET_CVE_EXPLOITS_TABLE_DISMISSED:
      return {
        ...state,
        cveExploitsTableDismissed: action.payload,
      };
    case GET_BREACHED_INFO:
      return {
        ...state,
        breachedInfo: action.payload.result.data,
        breachedInfoCols: action.payload.result.columns,
        breachedInfoPages: action.payload.pages,
        breachedInfoCount: action.payload.count,
        breachedInfoLoading: false
      };

      case GET_ARCHIVED_BREACHED_INFO:
      return {
        ...state,
        archivedBreachedInfo: action.payload.result.data,
        archivedBreachedInfoCols: action.payload.result.columns,
        archivedBreachedInfoPages: action.payload.pages,
        archivedBreachedInfoCount: action.payload.count,
        archivedBreachedInfoLoading: false
      };
    default:
      return state;
  }
};

export default securityTableReducer;