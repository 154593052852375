const myProfileStyles = (theme) => ({
  root: {
    width: "100%",
    marginTop: "0rem",
    [theme.breakpoints.up("sm")]: {
      // marginTop: "4rem",
    },
  },
  heading: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: "100%",
    textAlign: "center",
    fontSize: "3rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      fontSize: "1.5rem",
    },
  },
  inputsBox: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),

    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
});

export default myProfileStyles;
