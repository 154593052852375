const componentStyles = (theme) => ({
    heading1: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: 'black'
    },
    heading2: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: 'black'
    },
    heading3: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: 'black'
    },
    heading4: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: 'black'
    },
    text1: {
        fontSize: '18px',
        color: 'black',
        fontWeight: 400,
        textAlign: 'justify'
    },
    text2: {
        fontSize: '16px',
        color: 'black',
        fontWeight: 400,
        textAlign: 'justify'
    },
    text3: {
        fontSize: '14px',
        color: 'black',
        fontWeight: 300,
        textAlign: 'justify'
    },
    text4: {
        fontSize: '12px',
        color: 'black',
        fontWeight: 300,
        textAlign: 'justify'
    },
    centerItem: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },

    listItem: {
        border: '1px solid #ced3d9',
        marginTop: '10px',
        borderRadius: '10px',
        '& .selected': {
            background: '#e2e9f1'
        }
    },

    infoIcon: {
        border: '1px solid #0b4688 !important',
        background: '#e2e9f1',
        borderRadius: '50%'
    },

    formHeading: {
        fontSize: '20px',
        fontWeight: 'bold'
    },

    CardContainer: {
        flex: 1
    },
    cardsWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'start'
    },
    IconButton: { padding: '10px', width: '100%', display: 'flex', justifyContent: 'start', transition: 'ease 0.5s' },
    textBox: {
        fontSize: '18px',
        fontWeight: 400
    },
    submitFormWidth: {
        width: '40%',
        // [theme.breakpoints.down('md')]: {
        //     width: '60%'
        // },
        // [theme.breakpoints.down('sm')]: {
        //     width: '100%'
        // }
    }
});

export default componentStyles;
