import {
  INVITE_USER,
  GET_USERS,
  LOADING_USERS,
  CURRENT_USER_ACTION_LOADING,
  LOADING_WEBSITES,
  GET_WEBSITES,
  LOADING_INVITE,
  LOADING_INVITED_USERS,
  LOADING_INVITED_USERS_DELETED,
  GET_INVITED_USERS,
  SET_REMAINING_USERS,
  SET_REMAINING_WEBSITES,
  REMAINING_USERS_AFTER_ADDED,
  REMAINING_USERS_AFTER_DELETED,
  REMAINING_WEBSITES_AFTER_DELETED
} from '../actions/inviteUsersActions';

const initialState = {
  users: null,
  websites: null,
  invitedUsers: null,
  loadingUsers: false,
  loadingWebsites: false,
  loadingInvite: false,
  loadingInvitedUsers: false,
  loadingInvitedUsersDeleted: false,
  currentUserActionLoading: false,
  remainingUsers: 0,
  remainingWebsites: 0,
};

const inviteUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case INVITE_USER:
      return {
        ...state
      }
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      }
    case GET_WEBSITES:
      return {
        ...state,
        websites: action.payload,
      }
    case SET_REMAINING_USERS:
      return {
        ...state,
        remainingUsers: action.payload,
      }
    case SET_REMAINING_WEBSITES:
      return {
        ...state,
        remainingWebsites: action.payload,
      }
    case REMAINING_USERS_AFTER_ADDED:
      return {
        ...state,
        remainingUsers: state.remainingUsers - 1,
      }
    case REMAINING_USERS_AFTER_DELETED:
      return {
        ...state,
        remainingUsers: state.remainingUsers + 1,
      }
    case REMAINING_WEBSITES_AFTER_DELETED:
      return {
        ...state,
        remainingWebsites: state.remainingWebsites + 1,
      }
    case LOADING_USERS:
      return {
        ...state,
        loadingUsers: !state.loadingUsers,
      }
    case CURRENT_USER_ACTION_LOADING:
      return {
        ...state,
        currentUserActionLoading: !state.currentUserActionLoading,
      }
    case LOADING_WEBSITES:
      return {
        ...state,
        loadingWebsites: !state.loadingWebsites,
      }
    case LOADING_INVITED_USERS:
      return {
        ...state,
        loadingInvitedUsers: !state.loadingInvitedUsers,
      }
    case LOADING_INVITED_USERS_DELETED:
      return {
        ...state,
        loadingInvitedUsersDeleted: !state.loadingInvitedUsersDeleted,
      }
    case GET_INVITED_USERS:
      return {
        ...state,
        invitedUsers: action.payload,
      }
    case LOADING_INVITE:
      return {
        ...state,
        loadingInvite: !state.loadingInvite,
      }
    default:
      return state;
  }
};
export default inviteUsersReducer;