import {
  GET_PACKAGES,
  GET_STRIPE_CARDS,
  GET_TRANSACTIONS,
  SUBSCRIBE_PACKAGE,
  CANCEL_SUBSCRIPTION,

} from '../actions/packageSelectionActions';

const initialState = {
  packages: {
    data: null,
    loading: true
  },
  stripeCards: null,
  transactions: null,
  loadingSubscribe: false,
  loadingUnSubscribe: false,
};

 const packageSelectionReducer =  (state = initialState, action) => {
  switch (action.type) {
    case GET_PACKAGES:
      return {
        ...state,
        packages: { data: action.payload, loading: false }
      }
    case GET_STRIPE_CARDS:
      return {
        ...state,
        stripeCards: action.payload
      }
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload
      }
    case SUBSCRIBE_PACKAGE:
      return {
        ...state,
        loadingSubscribe: !state.loadingSubscribe
      }
    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        loadingUnSubscribe: !state.loadingUnSubscribe
      }
   
    default:
      return state;
  }
};

export default packageSelectionReducer;