import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomCardContainer from "../../components/commons/CustomCardContainer";

export default function Profilers({ setScanValues, scanValues }) {
  function handleModules(event) {
    setScanValues({
      ...scanValues,
      modules: {
        ...scanValues.modules,
        [event.target.name]: event.target.checked,
      },
    });
  }

  return (
    <CustomCardContainer title={"Select Modules"}>
      <Grid container>
        {Object.keys(scanValues.modules).map((item, index) => {
          return (
            <Grid item xs={12} sm={6} md={3} lg={3} xl={2} key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={scanValues.modules[item]}
                    disabled={item === "spellCheckerScanner"}
                    name={item}
                    color="primary"
                    onChange={(event) => handleModules(event)}
                  />
                }
                label={
                  item === "pagemetricsScanner"
                    ? "Performance Scanner"
                    : item
                      .replace(/([A-Z])/g, " $1")
                      .trim()
                      .toUpperCase()
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </CustomCardContainer>
  );
}
