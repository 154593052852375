import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
 
import loaderwave from "../assets/loader.svg";
// import Fade from 'react-reveal/Fade';
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import NoDomain from "../components/commons/NoDomain";
const VideosCard = React.lazy(() =>
  import("../components/FileOptimizationComponents/VideosCurrentAndHistory")
);

const AudioCard = React.lazy(() =>
  import("../components/FileOptimizationComponents/AudioCurrentAndHistory")
);

const ImagesCard = React.lazy(() =>
  import("../components/FileOptimizationComponents/ImagesCurrentAndHistory")
);

const TotalFilesElementCard = React.lazy(() =>
  import(
    "../components/FileOptimizationComponents/TotalFilesElementCurrentAndHistory"
  )
);

const FilesCards = React.lazy(() =>
  import("../components/dnd/fileOptimization/Cards")
);

//Tables
const FilesIssuesTable = React.lazy(() =>
  import("../components/tables/fileOptimizationTables/FilesIssuesTable")
);
const FilesArchivedIssuesTable = React.lazy(() =>
  import("../components/tables/fileOptimizationTables/FilesArchivedIssuesTable")
);
const FilesCompletedIssuesTable = React.lazy(() =>
  import(
    "../components/tables/fileOptimizationTables/FilesCompletedIssuesTable"
  )
);
const FilesIgnoredIssuesTable = React.lazy(() =>
  import("../components/tables/fileOptimizationTables/FilesIgnoredIssuesTable")
);
const NewFilesIssuesTable = React.lazy(() =>
  import("../components/tables/fileOptimizationTables/NewFilesIssuesTable")
);

const FilesTables = React.lazy(() =>
  import("../components/dnd/tables/fileOptimization/Cards")
);

const PdfChecker = React.lazy(() =>
  import("../components/tables/fileOptimizationTables/PDFChecker")
);


export default function FileTypesReport() {
  const selectedDomain = useSelector(
    (state) => state.miscellaneous.selectedDomain
  );
  const selectedScanId = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );
  const selectedScan = useSelector((state) => state.miscellaneous.selectedScan);

  const [reportType, setReportType] = React.useState({
    tables: false,
    analytics: true,
  });

  // const { isPackagePurchased } = useSelector((state) => state.dashboard.isDomainPackagePurchased);

  // React.useEffect(() => {
  //   if (!isPackagePurchased) {
  //     history.push("/");
  //   }
  //   // eslint-disable-next-line
  // }, []);

  const backTablesArray = ["unOptimized", "pdfChecker", "archived", "completed", "ignored"];

  const [tablesArray, setTablesArray] = React.useState([]);

  React.useEffect(() => {
    if (selectedScanId) {
      const preTables = [
        {
          text: <FilesIssuesTable scanId={selectedScanId} />,
          id: "unOptimized",
        },
        {
          text: <PdfChecker scanId={selectedScanId} />,
          id: "pdfChecker",
        },
        {
          text: <FilesArchivedIssuesTable scanId={selectedScanId} />,
          id: "archived",
        },
        {
          text: <FilesCompletedIssuesTable scanId={selectedScanId} />,
          id: "completed",
        },
        {
          text: <FilesIgnoredIssuesTable scanId={selectedScanId} />,
          id: "ignored",
        },
        {
          text: <NewFilesIssuesTable scanId={selectedScanId} />,
          id: "newIssues",
        },

      ];
      const arrayo = [];
      if (
        backTablesArray.length > 0 &&
        selectedDomain &&
        selectedScan &&
        selectedScanId
      )
        for (let i = 0; i < backTablesArray.length; i++) {
          switch (backTablesArray[i]) {
            case "unOptimized":
              arrayo.push(
                preTables.filter((box) => box.id === "unOptimized")[0]
              );
              break;
            case "pdfChecker":
              arrayo.push(
                preTables.filter((box) => box.id === "pdfChecker")[0]
              );
              break;
            case "archived":
              arrayo.push(preTables.filter((box) => box.id === "archived")[0]);
              break;
            case "completed":
              arrayo.push(preTables.filter((box) => box.id === "completed")[0]);
              break;
            case "ignored":
              arrayo.push(preTables.filter((box) => box.id === "ignored")[0]);
              break;
            default:
              arrayo.push(preTables.filter((box) => box.id === "newIssues")[0]);
          }
        }
      setTablesArray([...arrayo]);
    }
    // eslint-disable-next-line
  }, [selectedScanId]);

  const [cardsArray, setCardsArray] = React.useState([]);
  const [
    filesOptimizationBackArray,
    setFilesOptimizationBackArray,
  ] = React.useState([]);

  React.useEffect(() => {
    const filesCardsArray = ["totalFiles", "images", "videos", "audio"];
    const array1 = JSON.parse(localStorage.getItem("fileOptimizationArray"));
    if (array1) {
      setFilesOptimizationBackArray([...array1]);
    } else {
      setFilesOptimizationBackArray([...filesCardsArray]);
      localStorage.setItem(
        "fileOptimizationArray",
        JSON.stringify(filesCardsArray)
      );
    }
  }, []);

  React.useEffect(() => {
    if (selectedScanId && filesOptimizationBackArray.length > 0) {
      const preCards = [
        {
          text: <TotalFilesElementCard scanId={selectedScanId} />,
          id: "totalFiles",
        },
        {
          text: <ImagesCard scanId={selectedScanId} />,
          id: "images",
        },
        {
          text: <VideosCard scanId={selectedScanId} />,
          id: "videos",
        },
        {
          text: <AudioCard scanId={selectedScanId} />,
          id: "audio",
        },
      ];
      const arrayo = [];
      for (let i = 0; i < filesOptimizationBackArray.length; i++) {
        switch (filesOptimizationBackArray[i]) {
          case "totalFiles":
            arrayo.push(preCards.filter((box) => box.id === "totalFiles")[0]);
            break;
          case "images":
            arrayo.push(preCards.filter((box) => box.id === "images")[0]);
            break;
          case "videos":
            arrayo.push(preCards.filter((box) => box.id === "videos")[0]);
            break;
          default:
            arrayo.push(preCards.filter((box) => box.id === "audio")[0]);
        }
      }
      setCardsArray([...arrayo]);
    }
    // eslint-disable-next-line
  }, [selectedScanId, filesOptimizationBackArray]);


  const [delayed, setDelayed] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setDelayed(false)
    }, 1500);
  }, [])

  return (
    <>
      <Suspense
        fallback={
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            tabIndex="0"
          >
            {" "}
            <img src={loaderwave} alt="loader" />
          </Box>
        }
      >
        {!delayed ?
          <>
            <div >
              {selectedScan ? (
                <>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box display="flex" flexWrap="wrap" alignItems="center">
                      <ButtonGroup
                        size="small"
                        aria-label="Button group to switch between analytics and report"
                      >
                        <Button
                          disableRipple={true}
                          style={{
                            backgroundColor: reportType.analytics
                              ? "#21CA5A"
                              : "#7E7E7E",
                            color: "#ffffff",
                          }}
                          onClick={() => setReportType({ analytics: true })}
                        >
                          Analytics
                    </Button>
                        <Button
                          disableRipple={true}
                          style={{
                            backgroundColor: reportType.tables
                              ? "#21CA5A"
                              : "#7E7E7E",
                            color: "#ffffff",
                          }}
                          onClick={() => setReportType({ tables: true })}
                        >
                          Report
                    </Button>
                      </ButtonGroup>
                    </Box>
                  </Box>
                  <Grid container spacing={2} style={{ marginTop: "25px" }}>
                    {reportType.analytics && (
                      <>
                        {cardsArray.length > 0 && (
                          <DndProvider backend={HTML5Backend}>
                            <FilesCards boxes={cardsArray} />
                          </DndProvider>
                        )}
                      </>
                    )}
                    {reportType.tables && (
                      <>
                        {tablesArray.length > 0 && (
                          <DndProvider backend={HTML5Backend}>
                            <FilesTables tables={tablesArray} />
                          </DndProvider>
                        )}
                      </>
                    )}
                  </Grid>
                </>
              ) : (
                <Box display="flex" justifyContent="center" alignitems="center" height="100%" >
                  <NoDomain text="Please add scan to see the results." />
                </Box>
              )}
            </div>
          </> :
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            tabIndex="0"
          >
            <img src={loaderwave} alt="loader" />
          </Box>
        }
      </Suspense>
    </>
  );
}
