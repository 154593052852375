
export function getDate(date) {
   var mL = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  if (date) {
    let dat = new Date(date);
    const day = dat.getDate().toString();
    const month =mL[ (dat.getMonth() + 1) ];
    const year = dat.getFullYear().toString();

    return `${month} ${day} , ${year}`;
  }
  return null;
}
