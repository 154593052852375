import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";

import { Cancel } from "@material-ui/icons";


export default function TransitionsModal({ open, setOpen, title, children }) {

  const handleClose = (event) => {
    event.preventDefault();
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={open}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography variant="h6">Edit {title}</Typography>
          <IconButton style={{ padding: 0, margin: 0 }} onClick={handleClose}>
            <Cancel style={{ color: "red" }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
