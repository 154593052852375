import React, { useState, useEffect } from "react";
import TableContainer from '@material-ui/core/TableContainer';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useFadedShadowStyles } from "@mui-treasury/styles/shadow/faded";
import { useGutterBorderedGridStyles } from "@mui-treasury/styles/grid/gutterBordered";
import Paper from '@material-ui/core/Paper';
import CustomCardContainer from "../../components/commons/CustomCardContainer";
import CustomCardContainerHead from "../../components/commons/CustomCardContainerHead";
import { useDispatch } from "react-redux"
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import {
    updatePages, getCrawlerStatus, getPrivacyPolicy, getAccessibilityStatement, getDomainCopyrightYear, getDomainSkipToContent, getDomainPrivacyPage, createSkiptoContent, createHighContrast, createPages, createWpLogin, checkHighContrastInfo, checkSkipToContentInfo, getPluginStatus, checkFileAccessInfo,
    checkWpVersionStatusInfo, createFileAccess, createWpVersionVisibility, createPingback, checkPingbackInfo, createXmlRpc, checkXmlRpcInfo, deleteInactiveUsers, disableInactivePluginTheme,
    SecurityKeyEncryprion, FileEdit, checkDeleteInactiveUserInfo, checkInactivePluginThemeInfo, checkSecurityEncryptionInfo, checkFileEditingInfo, checkWpSecurityPatches
} from "../../store/actions/abracadabraActions";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast, ToastContainer } from "react-toastify";
import Loader from '../../components/commons/Loader';

const useStyles = makeStyles(({ palette }) => ({
    card: {
        borderRadius: 12,
        textAlign: "center",
        marginLeft: 10,
    },
    cardContent: {
        paddingBottom: 0,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
    },
    heading: {
        fontSize: 20,
        fontWeight: "bold",
        letterSpacing: "0.5px",
        margin: 0,
    },
    subheader: {
        fontSize: 18,
        color: "#000",
        // marginBottom: "0.875em",
        // minHeight: 36,
        fontWeight: 400,
    },
    statLabel: {
        fontSize: 12,
        color: palette.grey[500],
        fontWeight: 500,
        fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        margin: 0,
    },
    statValue: {
        fontSize: 20,
        fontWeight: "bold",
        margin: 0,
        letterSpacing: "1px",
    },
    button: {
        marginLeft: 10,
        marginTop: 30 // Adjust the margin as needed
    },
    loadingPrimary: { color: "red" }, // Change color here
    loadingSecondary: { color: "green" },
    labelStyles: {
        fontSize: '16px', // Adjust the font size as needed
    }

}));
export default function WpSecurity({ domain, selectedScanId }) {
    const [data, setData] = React.useState();
    const [skipToContentData, setSkipToContentData] = React.useState();
    const [privacyData, setPrivacyData] = React.useState();
    const [accessibilityStatementData, setAccessibilityStatementData] = React.useState();
    const [loadingLinks, setLoadingLinks] = React.useState(true);
    const [hideWpVersionEnabled, setHideWpVersionEnabled] = useState(false);
    const [pingbackEnabled, setPingbackEnabled] = useState(false);
    const [pingback, setPingback] = useState("enable");
    const [xmlRpcEnabled, setXmlRpcEnabled] = useState(false);
    const [xmlRpc, setxmlRpc] = useState("enable");
    const [hideWpVersion, setHideWpVersion] = useState("enable");
    const [fileAccess, setFileAccess] = useState("enable");
    const [fileAccessEnabled, setFileAccessEnabled] = useState(false);
    const [copyrightYearEnabled, setCopyrightYearEnabled] = useState(false);
    const [deleteInactiveUserEnabled, setDeleteInactiveUserEnabled] = useState(false);
    const [deleteInactiveUser, setDeleteInactiveUser] = useState("enable");
    const [disableInactivePluginThemesEnabled, setDisableInactivePluginThemesEnabled] = useState(false);
    const [disableInactivePluginThemes, setDisableInactivePluginThemes] = useState("enable");
    const [securityKeyEnabled, setSecurtyKeyEnabled] = useState(false);
    const [securityKey, setSecurityKey] = useState("enable");
    const [fileEditEnabled, setFileEditEnabled] = useState(false);
    const [fileEdit, setFileEdit] = useState("enable");
    const [copyrightYear, setCopyrightYear] = useState("enable");
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(null);
    const [accessibilityhtmlContent, setAccessibilityHtmlContent] = useState('');
    const [privacyhtmlContent, setPrivacyHtmlContent] = useState('');
    const [wpLoginValue, setWpLoginValue] = useState('/wp-login.php');
    const [wpAdminValue, setWpAdminValue] = useState('/wp-admin');
    const [accessibilityPageId, setAccessibilityPageId] = useState(null);
    const [consentChecked, setConsentChecked] = useState(false);
    const [consentCheckedPrivacy, setConsentCheckedPrivacy] = useState(false);
    const [pluginInstalled, setPluginInstalled] = useState(true);
    const [privacyPageId, setPrivacyPageId] = useState(null);
    const [loadingcheck, setLoadingCheck] = useState(true);
    const [scanStatus, setScanStatus] = useState('');
    const [showPluginInfo, setShowPluginInfo] = useState(false);





    const dispatch = useDispatch();

    // // Simulating a process with setTimeout
    // useEffect(() => {
    //     // Simulate a process taking 3 seconds to complete
    //     const process = setTimeout(() => {
    //         // Once the process is completed
    //         setLoadingCheck(false); // Hide the loader
    //         // Additional actions or state changes upon success can go here
    //     }, 4000);

    //     // Clear the timeout if component unmounts or if process succeeds before 3 seconds
    //     return () => clearTimeout(process);
    // }, []);

    // React.useEffect(() => {
    //     getPluginStatus2();
    // }, []);

    // React.useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         getCrawlStatus();
    //     }, 5000); // 5 seconds in milliseconds

    //     // Clear interval on component unmount
    //     return () => clearInterval(intervalId);
    // }, [showPluginInfo]);



    const handleHideWpVersionToggle = () => {
        // Call your API or perform any necessary action here with the new value
        setHideWpVersionEnabled(!hideWpVersionEnabled);
        setHideWpVersion((prevHideWpVer) => {
            const newHideWpVer = prevHideWpVer === "enable" ? "disable" : "enable";
            // console.log(newSkipToContent);
            return newHideWpVer;
        });
    };

    const handlePingbackToggle = () => {
        // Call your API or perform any necessary action here with the new value
        setPingbackEnabled(!pingbackEnabled);
        setPingback((prevPingback) => {
            const newPingback = prevPingback === "enable" ? "disable" : "enable";
            // console.log(newSkipToContent);
            return newPingback;
        });
    };

    function handlePingback(action) {
        handlePingbackToggle()
        if (action === "enable") {
            action = "open"
        } else {
            action = "closed"
        }
        dispatch(createPingback(selectedScanId, domain, action))
            .then(data => {
                if (data) {
                    setLoadingButton(null)
                    console.log(data)
                    toast.success(`Pingback ${data.response}`);
                    // setData(data.result);
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }
    React.useEffect(() => {
        // getDomains();
        // checkHideWpVersion(domain);
        // checkFileAccess(domain);
        // checkPingbackStatus(domain);
        // checkXmlRpcStatus(domain);
        // checkDeleteInactiveUserStatus(domain);
        // checkDisableInactivePluginThemesStatus(domain);
        // checkSecurityKeyStatus(domain);
        // checkFileEditStatus(domain);
        checkSecurityStatus(selectedScanId);
    }, [domain, selectedScanId]);

    function checkSecurityStatus(selectedScanId) {
        handlePingbackToggle()
        console.log("here scan Id")
        console.log(selectedScanId);
        dispatch(checkWpSecurityPatches(selectedScanId))
            .then(data => {
                if (data) {
                    if (data.response.pingback_status) {
                        // console.log(data.response.result.skipToContentEnabled)
                        if (data.response.pingback_status.pingback === "enabled") {
                            setPingbackEnabled(true)
                        } else {
                            setPingbackEnabled(false)
                        }
                    }

                    if (data.response.xmlrpc_status) {
                        // console.log(data.response.result.skipToContentEnabled)
                        if (data.response.xmlrpc_status.xmlrpc === "enabled") {
                            setXmlRpcEnabled(true)
                        } else {
                            setXmlRpcEnabled(false)
                        }
                    }

                    if (data.response.delete_inactive_users) {
                        // console.log(data.response.result.skipToContentEnabled)
                        if (data.response.delete_inactive_users.inactive_users_status === "enabled") {
                            setDeleteInactiveUserEnabled(true)
                        } else {
                            setDeleteInactiveUserEnabled(false)
                        }
                    }

                    if (data.response.security_key_encryption_status) {
                        // console.log(data.response.result.skipToContentEnabled)
                        if (data.response.security_key_encryption_status.security_key_encryption === "enabled") {
                            setSecurtyKeyEnabled(true)
                        } else {
                            setSecurtyKeyEnabled(false)
                        }
                    }

                    if (data.response.file_editing_status) {
                        // console.log(data.response.result.skipToContentEnabled)
                        if (data.response.file_editing_status.file_editing === "enabled") {
                            setFileEditEnabled(true)
                        } else {
                            setFileEditEnabled(false)
                        }
                    }

                    if (data.response.disable_unused_plugin_themes_status) {
                        // console.log(data.response.result.skipToContentEnabled)
                        if (data.response.disable_unused_plugin_themes_status.unused_plugin_themes_status === "enabled") {
                            setDisableInactivePluginThemesEnabled(true)
                        } else {
                            setDisableInactivePluginThemesEnabled(false)
                        }
                    }

                    if (data.response.wordpress_version_visibility) {
                        // console.log(data.response.result.skipToContentEnabled)
                        if (data.response.wordpress_version_visibility.version_visibility === "enabled") {
                            setHideWpVersionEnabled(true)
                        } else {
                            setHideWpVersionEnabled(false)
                        }
                    }

                    if (data.response.file_access_restriction) {
                        if (data.response.file_access_restriction.file_access === "enabled") {
                            setFileAccessEnabled(true)
                        } else {
                            setFileAccessEnabled(false)
                        }
                    }
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }


    function checkPingbackStatus(domain) {
        handlePingbackToggle()
        dispatch(checkPingbackInfo(domain))
            .then(data => {
                if (data) {
                    if (data.response.result.pingback_status) {
                        // console.log(data.response.result.skipToContentEnabled)
                        if (data.response.result.pingback_status === "enabled") {
                            setPingbackEnabled(true)
                        } else {
                            setPingbackEnabled(false)
                        }
                    }
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }


    const handleXmlRpcToggle = () => {
        // Call your API or perform any necessary action here with the new value
        setXmlRpcEnabled(!xmlRpcEnabled);
        setxmlRpc((prevXmlRpc) => {
            const newXmlRpc = prevXmlRpc === "enable" ? "disable" : "enable";
            // console.log(newSkipToContent);
            return newXmlRpc;
        });
    };

    function handleXmlRpc(action) {
        handleXmlRpcToggle()
        if (action === "enable") {
            action = true
        } else {
            action = false
        }
        dispatch(createXmlRpc(selectedScanId, domain, action))
            .then(data => {
                if (data) {
                    setLoadingButton(null)
                    // console.log(data.response.response.message)
                    toast.success(data.response);
                    // setData(data.result);
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }

    function checkXmlRpcStatus(domain) {
        handleXmlRpcToggle()
        dispatch(checkXmlRpcInfo(domain))
            .then(data => {
                if (data) {
                    if (data.response.result.xmlrpc_status) {
                        // console.log(data.response.result.skipToContentEnabled)
                        if (data.response.result.xmlrpc_status === "enabled") {
                            setXmlRpcEnabled(true)
                        } else {
                            setXmlRpcEnabled(false)
                        }
                    }
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }


    const handleDeleteInactiveUserToggle = () => {
        // Call your API or perform any necessary action here with the new value
        setDeleteInactiveUserEnabled(!deleteInactiveUserEnabled);
        setDeleteInactiveUser((prevDeleteInactiveUser) => {
            const newDeleteInactiveUser = prevDeleteInactiveUser === "enable" ? "disable" : "enable";
            // console.log(newSkipToContent);
            return newDeleteInactiveUser;
        });
    };

    function handleDeleteInactiveUser(action) {
        handleDeleteInactiveUserToggle()
        dispatch(deleteInactiveUsers(selectedScanId, domain, action))
            .then(data => {
                if (data) {
                    setLoadingButton(null)
                    // console.log(data.response.response.message)
                    toast.success(data.response);
                    // setData(data.result);
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }

    function checkDeleteInactiveUserStatus(domain) {
        handleDeleteInactiveUserToggle()
        dispatch(checkDeleteInactiveUserInfo(domain))
            .then(data => {
                if (data) {
                    if (data.response.result.delete_inactive_users) {
                        // console.log(data.response.result.skipToContentEnabled)
                        if (data.response.result.delete_inactive_users === "enabled") {
                            setDeleteInactiveUserEnabled(true)
                        } else {
                            setDeleteInactiveUserEnabled(false)
                        }
                    }
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });

    }

    const handleSecurityKeyToggle = () => {
        // Call your API or perform any necessary action here with the new value
        setSecurtyKeyEnabled(!securityKeyEnabled);
        setSecurityKey((prevSecurityKey) => {
            const newSecurityKey = prevSecurityKey === "enable" ? "disable" : "enable";
            // console.log(newSkipToContent);
            return newSecurityKey;
        });
    };

    function handleSecurityKey(action) {
        handleSecurityKeyToggle()
        dispatch(SecurityKeyEncryprion(selectedScanId, domain, action))
            .then(data => {
                if (data) {
                    setLoadingButton(null)
                    // console.log(data.response.response.message)
                    toast.success(data.response);
                    // setData(data.result);
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }

    function checkSecurityKeyStatus(domain) {
        handleSecurityKeyToggle()
        dispatch(checkSecurityEncryptionInfo(domain))
            .then(data => {
                if (data) {
                    if (data.response.result.security_key_encryption_status) {
                        // console.log(data.response.result.skipToContentEnabled)
                        if (data.response.result.security_key_encryption_status === "enabled") {
                            setSecurtyKeyEnabled(true)
                        } else {
                            setSecurtyKeyEnabled(false)
                        }
                    }
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });

    }

    const handleFileEditToggle = () => {
        // Call your API or perform any necessary action here with the new value
        setFileEditEnabled(!fileEditEnabled);
        setFileEdit((prevFileEdit) => {
            const newFileEdit = prevFileEdit === "enable" ? "disable" : "enable";
            // console.log(newSkipToContent);
            return newFileEdit;
        });
    };

    function handleFileEdit(action) {
        handleFileEditToggle()
        dispatch(FileEdit(selectedScanId, domain, action))
            .then(data => {
                if (data) {
                    setLoadingButton(null)
                    // console.log(data.response.response.message)
                    toast.success(data.response);
                    // setData(data.result);
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }

    function checkFileEditStatus(domain) {
        handleFileEditToggle()
        dispatch(checkFileEditingInfo(domain))
            .then(data => {
                if (data) {
                    if (data.response.result.file_editing_status) {
                        // console.log(data.response.result.skipToContentEnabled)
                        if (data.response.result.file_editing_status === "enabled") {
                            setFileEditEnabled(true)
                        } else {
                            setFileEditEnabled(false)
                        }
                    }
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });

    }

    const handleDisableInactivePluginThemesToggle = () => {
        // Call your API or perform any necessary action here with the new value
        setDisableInactivePluginThemesEnabled(!disableInactivePluginThemesEnabled);
        setDisableInactivePluginThemes((prevDisableInactivePluginThemes) => {
            const newDisableInactivePluginThemes = prevDisableInactivePluginThemes === "enable" ? "disable" : "enable";
            // console.log(newSkipToContent);
            return newDisableInactivePluginThemes;
        });
    };

    function handleDisableInactivePluginThemes(action) {
        dispatch(disableInactivePluginTheme(selectedScanId, domain, action))
            .then(data => {
                if (data) {
                    setLoadingButton(null)
                    // console.log(data.response.response.message)
                    toast.success(data.response);
                    // setData(data.result);
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }

    function checkDisableInactivePluginThemesStatus(domain) {
        handleDisableInactivePluginThemesToggle()
        dispatch(checkInactivePluginThemeInfo(domain))
            .then(data => {
                if (data) {
                    if (data.response.result.disable_unused_plugin_themes_status) {
                        // console.log(data.response.result.skipToContentEnabled)
                        if (data.response.result.disable_unused_plugin_themes_status === "enabled") {
                            setDisableInactivePluginThemesEnabled(true)
                        } else {
                            setDisableInactivePluginThemesEnabled(false)
                        }
                    }
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }


    // console.log(`Skip To Content: ${skipToContent}`)
    const handleFileAccessToggle = () => {
        // Call your API or perform any necessary action here with the new value
        setFileAccessEnabled(!fileAccessEnabled);
        setFileAccess((prevFileAccess) => {
            const newFileAccess = prevFileAccess === "enable" ? "disable" : "enable";
            // console.log(newSkipToContent);
            return newFileAccess;
        });
    };
    // console.log(`High Contrst: ${highContrast}`)

    async function getPluginStatus2() {
        try {
            const response = await dispatch(getPluginStatus(domain));
            console.log(response.response.response);
            if (response && response.response.response === 200) {
                setPluginInstalled(true);
                setLoadingLinks(false);
                // console.log(response.response.result);
            } else {
                setPluginInstalled(false);
                setLoadingLinks(false);
                // toast.error("Failed to retrieve contact details.");
            }
        } catch (error) {
            setPluginInstalled(false);
            setLoadingLinks(false);
        }
    }

    async function getCrawlStatus() {
        try {
            const response = await dispatch(getCrawlerStatus(domain));
            console.log(response.response.result);
            if (response && response.response.result === "completed") {
                setLoadingLinks(false);
                setShowPluginInfo(true);
                setScanStatus(response.response.result);
                // console.log(response.response.result);
            } else {
                setShowPluginInfo(true);
                setLoadingLinks(false);
                setScanStatus(response.response.result);
                // toast.error("Failed to retrieve contact details.");
            }
        } catch (error) {
            setLoadingLinks(false);
            setShowPluginInfo(false);
            // toast.error("No task found or still running");
        }
    }


    function handHideWpVersion(action) {
        handleHideWpVersionToggle()
        if (action === "enable") {
            action = true
        } else {
            action = false
        }
        dispatch(createWpVersionVisibility(selectedScanId, domain, action))
            .then(data => {
                if (data) {
                    setLoadingButton(null)
                    // console.log(data.response.response.message)
                    toast.success(data.response);
                    // setData(data.result);
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }

    function checkHideWpVersion(domain) {
        handleHideWpVersionToggle()
        dispatch(checkWpVersionStatusInfo(domain))
            .then(data => {
                if (data) {
                    if (data.response.result.wordpress_version_visibility) {
                        // console.log(data.response.result.skipToContentEnabled)
                        if (data.response.result.wordpress_version_visibility === "enabled") {
                            setHideWpVersionEnabled(true)
                        } else {
                            setHideWpVersionEnabled(false)
                        }
                    }
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }

    function checkFileAccess(domain) {
        handleFileAccessToggle()
        dispatch(checkFileAccessInfo(domain))
            .then(data => {
                if (data) {
                    console.log(data.response.result.file_access_restriction)
                    if (data.response.result.file_access_restriction === "enabled") {
                        setFileAccessEnabled(true)
                    } else {
                        setFileAccessEnabled(false)
                    }
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }



    function handleFileAccess(action) {
        handleFileAccessToggle()
        if (action === "enable") {
            action = true
        } else {
            action = false
        }
        dispatch(createFileAccess(selectedScanId, domain, action))
            .then(data => {
                if (data) {
                    setLoadingButton(null)
                    // console.log(data.response.response.message)
                    toast.success(data.response);
                    // setData(data.result);
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }




    const downloadFileInNewTab = () => {
        // Set the relative path to the file in the public folder
        const fileURL = '/plugins/AllyRight.zip';

        // Open a new tab and start the download
        window.open(fileURL);
    };

    return (
        <>
            {/* {loadingcheck ? (
                <Loader />
            ) : ( */}
            {/* <> */}

            <TableContainer component={Paper}>
                <CustomCardContainer title={"Wordpress Security Patches"}>
                    <Grid container spacing={3}>
                        {/* Files access restrict */}
                        <Grid item xs={12}>
                            <Grid container alignItems="center">
                                <Grid item xs={3}>
                                    <h2>Security Measure</h2>
                                </Grid>
                                <Grid item xs={7}>
                                    <h2>Description</h2>
                                </Grid>
                                <Grid item xs={2}>
                                    <h2>Status</h2>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider style={{ margin: '10px 0' }} />
                    {/* <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Security Measure</TableCell>
                                                        <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Description</TableCell>
                                                        <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Status</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                            </Table> */}
                    <TableBody>
                        <Box mb={2}>
                            <Grid container spacing={3}>
                                {/* Files access restrict */}
                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={3}>
                                            <h3>Limit Access to Files</h3>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <h4 style={{ color: 'green' }}> Prohibit PHP scripts execution in the wp-includes directory for ironclad Protection!</h4>
                                            {/* {fileAccessEnabled ? (
                                                                    ) : (
                                                                        <h3 style={{ color: 'green' }}><b style={{ color: 'black' }}>Note: </b> Toggle to enable limit access to files</h3>

                                                                    )
                                                                    } */}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                control={<Switch color="primary" checked={fileAccessEnabled} onChange={() => {
                                                    // Call handleFileAccess with the appropriate action
                                                    const action = fileAccessEnabled ? 'disable' : 'enable';
                                                    handleFileAccess(action);

                                                    // Toggle the highContrastEnabled state
                                                    setFileAccessEnabled(!fileAccessEnabled);
                                                }}
                                                />
                                                }
                                                label={fileAccessEnabled ? "enabled" : "disabled"}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handleFileAccess("enable")}
                                                                        disabled={fileAccessEnabled}
                                                                    >
                                                                        Enable
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handleFileAccess("disable")}
                                                                        disabled={!fileAccessEnabled}
                                                                    >
                                                                        Disable
                                                                    </Button>
                                                                </Grid> */}
                                    </Grid>
                                </Grid>
                                {/* Hide Wordpress Version */}
                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={3}>
                                            <h3>Hide Wordpress Version</h3>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <h4 style={{ color: 'green' }}>Strengthen your website's security by removing WordPress version information from various sources, thwarting potential exploits and enhancing protection against vulnerabilities.</h4>
                                            {/* {hideWpVersionEnabled ? (
                                                                    ) : (
                                                                        <h3 style={{ color: 'green' }}><b style={{ color: 'black' }}>Note: </b> Toggle to enable wordpress version</h3>

                                                                    )
                                                                    } */}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                control={<Switch color="primary" checked={hideWpVersionEnabled} onChange={() => {
                                                    // Call handleHighContrast with the appropriate action
                                                    const action = hideWpVersionEnabled ? 'disable' : 'enable';
                                                    handHideWpVersion(action);

                                                    // Toggle the Hide wp version state
                                                    setHideWpVersionEnabled(!hideWpVersionEnabled);
                                                }}
                                                />
                                                }
                                                label={hideWpVersionEnabled ? "enabled" : "disabled"}
                                            />
                                        </Grid>
                                        {/* 
                                                                <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handHideWpVersion("enable")}
                                                                        disabled={hideWpVersionEnabled}
                                                                    >
                                                                        Enable
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handHideWpVersion("disable")}
                                                                        disabled={!hideWpVersionEnabled}
                                                                    >
                                                                        Disable
                                                                    </Button>
                                                                </Grid> */}
                                    </Grid>
                                </Grid>

                                {/* Ping back status */}
                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={3}>
                                            <h3>Pingback Status</h3>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <h4 style={{ color: 'green' }}>Safeguard your site by deactivating pingbacks, closing the door on potential spam and hacker exploits associated with this WordPress feature</h4>
                                            {/* {pingbackEnabled ? (
                                                                    ) : (
                                                                        <h3 style={{ color: 'green' }}><b style={{ color: 'black' }}>Note: </b> Toggle to enable pingback on website</h3>

                                                                    )
                                                                    } */}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                control={<Switch color="primary" checked={pingbackEnabled} onChange={() => {
                                                    // Call handleHighContrast with the appropriate action
                                                    const action = pingbackEnabled ? 'disable' : 'enable';
                                                    handlePingback(action);

                                                    // Toggle the Hide wp version state
                                                    setPingbackEnabled(!pingbackEnabled);
                                                }}
                                                />
                                                }
                                                label={pingbackEnabled ? "enabled" : "disabled"}
                                            />
                                        </Grid>
                                        {/* 
                                                                <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handlePingback("enable")}
                                                                        disabled={pingbackEnabled}
                                                                    >
                                                                        Enable
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handlePingback("disable")}
                                                                        disabled={!pingbackEnabled}
                                                                    >
                                                                        Disable
                                                                    </Button>
                                                                </Grid> */}
                                    </Grid>
                                </Grid>

                                {/* XmlRpc Status  */}
                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={3}>
                                            <h3>XML-RPC Status</h3>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <h4 style={{ color: 'green' }}>Boost your site's security by disabling XML-RPC, thwarting potential hacker exploits and enhancing protection against brute-force attacks on WordPress functions</h4>
                                            {/* {xmlRpcEnabled ? (
                                                                    ) : (
                                                                        <h3 style={{ color: 'green' }}><b style={{ color: 'black' }}>Note: </b> Toggle to enable xml-rpc on website</h3>

                                                                    )
                                                                    } */}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                control={<Switch color="primary" checked={xmlRpcEnabled} onChange={() => {
                                                    // Call handleHighContrast with the appropriate action
                                                    const action = xmlRpcEnabled ? 'disable' : 'enable';
                                                    handleXmlRpc(action);

                                                    // Toggle the Hide wp version state
                                                    setXmlRpcEnabled(!xmlRpcEnabled);
                                                }}
                                                />
                                                }
                                                label={xmlRpcEnabled ? "enabled" : "disabled"}
                                            />
                                        </Grid>

                                        {/* <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handleXmlRpc("enable")}
                                                                        disabled={xmlRpcEnabled}
                                                                    >
                                                                        Enable
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handleXmlRpc("disable")}
                                                                        disabled={!xmlRpcEnabled}
                                                                    >
                                                                        Disable
                                                                    </Button>
                                                                </Grid> */}
                                    </Grid>
                                </Grid>

                                {/* Delete Unused user wp */}
                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={3}>
                                            <h3>Delete Inactive Users</h3>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <h4 style={{ color: 'green' }}>Safeguard your site by removing inactive users, minimizing the risk of unauthorized access or misuse and ensuring a more secure online environment</h4>
                                            {/* {deleteInactiveUserEnabled ? (
                                                                    ) : (
                                                                        <h3 style={{ color: 'green' }}><b style={{ color: 'black' }}>Note: </b> Toggle to stop delete inactive users</h3>

                                                                    )
                                                                    } */}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                control={<Switch color="primary" checked={deleteInactiveUserEnabled} onChange={() => {
                                                    // Call handleHighContrast with the appropriate action
                                                    const action = deleteInactiveUserEnabled ? 'disable' : 'enable';
                                                    handleDeleteInactiveUser(action);

                                                    // Toggle the Hide wp version state
                                                    setDeleteInactiveUserEnabled(!deleteInactiveUserEnabled);
                                                }}
                                                />
                                                }
                                                label={deleteInactiveUserEnabled ? "enabled" : "disabled"}
                                            />
                                        </Grid>

                                        {/* <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handleDeleteInactiveUser("enable")}
                                                                        disabled={deleteInactiveUserEnabled}
                                                                    >
                                                                        Enable
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handleDeleteInactiveUser("disable")}
                                                                        disabled={!deleteInactiveUserEnabled}
                                                                    >
                                                                        Disable
                                                                    </Button>
                                                                </Grid> */}
                                    </Grid>
                                </Grid>

                                {/* Disable Unused plugin and themes */}
                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={3}>
                                            <h3>Disable Unused Plugin and Themes</h3>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <h4 style={{ color: 'green' }}>Turn off themes and plugins you don't use to protect your website better. This helps reduce the chance of problems and makes your site work faster</h4>
                                            {/* {disableInactivePluginThemesEnabled ? (
                                                                    ) : (
                                                                        <h3 style={{ color: 'green' }}><b style={{ color: 'black' }}>Note: </b> Toggle to enable unused plugin and themes</h3>

                                                                    )
                                                                    } */}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                control={<Switch color="primary" checked={disableInactivePluginThemesEnabled} onChange={() => {
                                                    // Call handleHighContrast with the appropriate action
                                                    const action = disableInactivePluginThemesEnabled ? 'disable' : 'enable';
                                                    handleDisableInactivePluginThemes(action);

                                                    // Toggle the Hide wp version state
                                                    setDisableInactivePluginThemesEnabled(!disableInactivePluginThemesEnabled);
                                                }}
                                                />
                                                }
                                                label={disableInactivePluginThemesEnabled ? "enabled" : "disabled"}
                                            />
                                        </Grid>
                                        {/* 
                                                                <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handleDisableInactivePluginThemes("enable")}
                                                                        disabled={deleteInactiveUserEnabled}
                                                                    >
                                                                        Enable
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handleDisableInactivePluginThemes("disable")}
                                                                        disabled={!deleteInactiveUserEnabled}
                                                                    >
                                                                        Disable
                                                                    </Button>
                                                                </Grid> */}
                                    </Grid>
                                </Grid>

                                {/* Security key encryption */}
                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={3}>
                                            <h3>Security Key Encryption</h3>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <h4 style={{ color: 'green' }}>Create random security keys to protect your site from attackers and keep user information safe in cookies.</h4>
                                            {/* {securityKeyEnabled ? (
                                                                    ) : (
                                                                        <h3 style={{ color: 'green' }}><b style={{ color: 'black' }}>Note: </b> Toggle to enable security key encryption in cookies</h3>

                                                                    )
                                                                    } */}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                control={<Switch color="primary" checked={securityKeyEnabled} onChange={() => {
                                                    // Call handleHighContrast with the appropriate action
                                                    const action = securityKeyEnabled ? 'disable' : 'enable';
                                                    handleSecurityKey(action);

                                                    // Toggle the Hide wp version state
                                                    setSecurtyKeyEnabled(!securityKeyEnabled);
                                                }}
                                                />
                                                }
                                                label={securityKeyEnabled ? "enabled" : "disabled"}
                                            />
                                        </Grid>

                                        {/* <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handleSecurityKey("enable")}
                                                                        disabled={deleteInactiveUserEnabled}
                                                                    >
                                                                        Enable
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handleSecurityKey("disable")}
                                                                        disabled={!securityKeyEnabled}
                                                                    >
                                                                        Disable
                                                                    </Button>
                                                                </Grid> */}
                                    </Grid>
                                </Grid>

                                {/* File Edit Wp */}
                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={3}>
                                            <h3>File Editing</h3>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <h4 style={{ color: 'green' }}>Stop users from editing files directly on your WordPress dashboard to reduce the risk of unauthorized access and enhance site protection.</h4>
                                            {/* {fileEditEnabled ? (
                                                                    ) : (
                                                                        <h3 style={{ color: 'green' }}><b style={{ color: 'black' }}>Note: </b> Toggle to enable file editing on website</h3>

                                                                    )
                                                                    } */}
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControlLabel
                                                control={<Switch color="primary" checked={fileEditEnabled} onChange={() => {
                                                    // Call handleHighContrast with the appropriate action
                                                    const action = fileEditEnabled ? 'disable' : 'enable';
                                                    handleFileEdit(action);

                                                    // Toggle the Hide wp version state
                                                    setFileEditEnabled(!fileEditEnabled);
                                                }}
                                                />
                                                }
                                                label={fileEditEnabled ? "enabled" : "disabled"}
                                            />
                                        </Grid>

                                        {/* <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handleFileEdit("enable")}
                                                                        disabled={fileEditEnabled}
                                                                    >
                                                                        Enable
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => handleFileEdit("disable")}
                                                                        disabled={!fileEditEnabled}
                                                                    >
                                                                        Disable
                                                                    </Button>
                                                                </Grid> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </TableBody>
                </CustomCardContainer>
            </TableContainer>
            {/* </> */}
            {/* )} */}
        </>
    );
}

const LoadingButton = ({ label, loading, onClick }) => {
    const styles = useStyles();

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={onClick}
            className={styles.button}
        >
            {loading ? (
                <CircularProgress
                    size={20}
                    className={`${styles.loadingProgress} ${styles.loadingPrimary}`}
                />
            ) : (
                label
            )}
        </Button>
    );
};
