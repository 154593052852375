import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import { getDomainChecklists, submitDomainChecklist } from '../store/actions/checklistActions'
import CheckList from './checklistComponents/CheckList';

import loaderwave from "../assets/loader.svg";
import Auth0Service from "../services/auth0Service/authentikService";

export default function CheckLists() {
  const dispatch = useDispatch();

  const lists = useSelector((state) => state.checklists.domain_lists);
  const type = useSelector((state) => state.checklists.domain_type);

  const loading = useSelector((state) => state.checklists.domain_loading);
  const domainId = useSelector((state) => state.dashboard.selectedDomainId);

  const [mainCheckItems, setMainCheckItems] = React.useState([]);

  
  React.useEffect(() => {
    if (domainId) {
      dispatch(getDomainChecklists(domainId));
    }
    // eslint-disable-next-line
  }, [domainId])

  React.useEffect(() => {
    if (lists) {
      setMainCheckItems(lists)
    }
    // eslint-disable-next-line
  }, [lists])

  if (!Auth0Service.isAuthenticated()) {
    return window.location.replace('/authenticate');
  } else {
    return (
      <>
        {!loading ?
          <Grid container spacing={3}>
            {lists.length > 0 ? <>
              {lists.map(list => {
                return <CheckList list={list} mainCheckItems={mainCheckItems}
                  setMainCheckItems={setMainCheckItems} type={type} />
              })}
              {type === "main" && <Box width="100%" textAlign="left" px={5}>
                <Button variant="contained" color="primary" disabled={!mainCheckItems.some(val => {
                  return val.checkItems.some(val => {
                    return val.checked === true
                  })
                })}
                  onClick={() => dispatch(submitDomainChecklist(mainCheckItems,domainId))}
                >Submit</Button>
              </Box>
              }
            </> :
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                width="100%"
              ><h2>No list Found</h2></Box>}
          </Grid> :
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            tabIndex="0"
          >
            <img src={loaderwave} alt="loader" />
          </Box>
        }
      </>
    );
  }

}

