 
import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { toast } from "react-toastify";

export const SPELLCHECK_TABLE = 'SPELLCHECK_TABLE';
export const SPELLCHECK_TABLE_LOADING = 'SPELLCHECK_TABLE_LOADING';

export const SPELLCHECK_HISTORY = 'SPELLCHECK_HISTORY';
export const SPELLCHECK_HISTORY_LOADING = 'SPELLCHECK_HISTORY_LOADING';

export const SPELLCHECK_MAIN_LOADING = 'SPELLCHECK_MAIN_LOADING';
export const SPELLCHECK_MAIN = 'SPELLCHECK_MAIN';


export const getSpellCheckTable = (scanId) => async (dispatch) => {
  dispatch({
    type: SPELLCHECK_TABLE_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();

const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const params = {
      scanId,
    }

    const res = await axios.get(`${BASE_URL}/dashboard/spellchecker-result`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: SPELLCHECK_TABLE,
        payload: res.data
      });
    }
  } catch (err) {

    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}

export const getSpellCheckMain = (scanId) => async (dispatch) => {
  dispatch({
    type: SPELLCHECK_MAIN_LOADING
  });
  try {
    const token = auth0Service.getAccessToken();

const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const params = {
      scanId,
    }

    const res = await axios.get(`${BASE_URL}/dashboard/spellchecker-main`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: SPELLCHECK_MAIN,
        payload: res.data
      });
    }
  } catch (err) {

    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}

export const getGrammarHistory = (scanId, dateNowRange) => async (dispatch) => {
  dispatch({
    type: SPELLCHECK_HISTORY_LOADING,
  });

  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId, dateNowRange
    }

    const res = await axios.get(`${BASE_URL}/history/spellchecker-history`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: SPELLCHECK_HISTORY,
        payload: res.data
      });
    }
  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}
