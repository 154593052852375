import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    CircularProgress,
    Box,
    Button,
    Card
} from '@mui/material';
import { getDomainScripts, generateCookieScript } from "../../store/actions/cookieActions";
import { toast } from "react-toastify";

const RequestTable = ({ domain }) => {
    const columns = [
        { id: 1, label: 'Domain', width: 150 },
        { id: 2, label: 'Config', width: 200 },
        { id: 3, label: 'Script', width: 150 },
        { id: 4, label: 'Organization', width: 200 },
    ];

    const [page, setPage] = React.useState(1);
    const dispatch = useDispatch();
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [requests, setRequests] = React.useState([]);
    const [requestsLoading, setRequestsLoading] = React.useState(false);

    React.useEffect(() => {
        setRequestsLoading(true);
        getScripts(domain);
    }, [domain]);

    const handleGenerateScript = async (domain) => {
        try {
            const response = await dispatch(generateCookieScript(domain
            ));
            if (response) {
              console.log(response);
              toast.success(response.message)
            } else {
            //   toast.warning("AI Suggestion not available at this time")
            console.log('no response');
            }
          } catch (err) {
            console.log("here 2")
          }
        }

    const getScripts = async (domain) => {
        try {
            // let domain = 'https://basicwebsiteexample.com';
            const response = await dispatch(getDomainScripts(domain));
            if (response && response.data) {
                console.log(response.data); // Log data to check the response structure
                setRequests([response.data]); // Set requests to an array to map over it
                setRequestsLoading(false);
            } else {
                console.log('no response');
                setRequestsLoading(false);
            }
        } catch (err) {
            console.log("Error fetching scripts:", err);
            setRequestsLoading(false);
        }
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: '20px' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align="center" style={{ width: column.width }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {requestsLoading ? (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    <CircularProgress size={32} />
                                </TableCell>
                            </TableRow>
                        ) : requests.length > 0 ? (
                            requests.map((request, index) => (
                                <TableRow key={index}>
                                    <TableCell>{request.domain}</TableCell>
                                    <TableCell>{request.config}</TableCell>
                                    <TableCell>{request.script}</TableCell>
                                    <TableCell>{request.organizationId}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">
                                    No Scripts Found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {requests.length === 0  &&
            <Box style={{ width: '100%', height: '100%' }}>
            <Button
                sx={{ mt: 2 }}
                variant="contained"
                onClick={() => {
                    handleGenerateScript(domain)
                }}
            >
                Generate Policy
            </Button>
    </Box>
            }
        </Paper>
    );
};

export default RequestTable;
