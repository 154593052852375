import {
  LOADING_FILES,
  GET_FILES_ELEMENTS_CARD_DETAILS,
  GET_FILES_HISTORY,
  LOADING_FILES_HISTORY
} from '../actions/totalFilesElementsCardActions';

const initialState = {
  data: null,
  loading: false,
  status: "pending",


  filesHistory: null,
  filesHistoryLoading: false,
  filesHistoryStatus: null,
};

const totalFilesElementsCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FILES_ELEMENTS_CARD_DETAILS:
      return {
        ...state,
        data: action.payload.result,
        status: action.payload.status,
        loading: false

      }
    case LOADING_FILES:
      return {
        ...state,
        loading: !state.loading
      }
    case GET_FILES_HISTORY:
      return {
        ...state,
        filesHistory: action.payload.result.graph,
        filesHistoryStatus: action.payload.status,
        filesHistoryLoading: false
      }
    case LOADING_FILES_HISTORY:
      return {
        ...state,
        filesHistoryLoading: true
      }
    default:
      return state;
  }
};

export default totalFilesElementsCardReducer;