import React from "react";
import Box from "@material-ui/core/Box";
import ReactTable from "../../commons/ReactTable";
import LaunchIcon from "@material-ui/icons/Launch";
import tablesWrapperStyles from "../../../Styles/jss/tables/tablesWrapperStyles";
import loader from "../../../assets/loader.svg";
const styles = {
  ...tablesWrapperStyles,
};

const cssWarningsColumns = [
  {
    width: "100px",
    Header: "Link",
    accessor: "link",
  },
  {
    width: "350px",
    Header: "Type",
    accessor: "message",
  },
];

function CssWarningsTable({ data }) {
  const [tablData, setTblData] = React.useState(null);

  React.useEffect(() => {
    function getCssWarnings(data) {
      const tblCssWarnings = [];
      for (let i = 0; i < data.length; i++) {
        const link = data[i].link;
        const shortLink = new URL(data[i].link);
        const message = data[i].message;
        tblCssWarnings.push({
          link: (
            <div style={styles.textCenter}>
              <LaunchIcon
                style={{ fontSize: "1.2rem" }}
                onClick={() => window.open(`${link}`, "_blank")}
              />
              <span
                style={styles.wplinks}
                className="primarycolor"
                aria-label={`${link}`}
                onClick={() =>
                  window.open(
                    `/violation-reports/link?${new URLSearchParams({
                      link: link,
                    }).toString()}`
                  )
                }
              >
                {shortLink.pathname.length > 40
                  ? `...${shortLink.pathname.substring(20, 45)}...`
                  : shortLink.pathname}
              </span>
            </div>
          ),
          message: (
            <Box>
              <div style={{ fontWeight: 500 }} aria-label={`${message}`}>
                {message}
              </div>
            </Box>
          ),
        });
      }
      return tblCssWarnings;
    }
    let dataTbl1 = null;
    if (data) {
      dataTbl1 = getCssWarnings(data.cssWarningsTable);
      setTblData(dataTbl1);
    }
  }, [data]);

  return (
    <>
      {tablData && tablData.length > 0 ? (
        <ReactTable tableColumns={cssWarningsColumns} data={tablData} />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "200px", maxHeight: "200px" }}
        >
          {" "}
          {/* <h4>We found no CSS Warning on your site</h4> */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            {" "}
            <img src={loader} alt="loader" />
          </Box>
        </Box>
      )}
    </>
  );
}

export default React.memo(CssWarningsTable);
