 
import auth0Service from "../../services/auth0Service/authentikService";
import axios from "axios";
import { BASE_URL } from "../../source/constants";
import { toast } from "react-toastify";

export const GET_ACTIVITY_LOGS = "GET_ACTIVITY_LOGS";

export const getActivityLogs = (userId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const params = {
      userId,
    };
    const res = await axios.get(`${BASE_URL}/actions/user-action`, {
      params,
      ...options,
    });
    if (res.data) {
      dispatch({
        type: GET_ACTIVITY_LOGS,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.' );
    }

  }
};
