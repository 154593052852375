import {
  LOADING_IMAGES,
  GET_IMAGES_CARD_DETAILS,
  GET_IMAGES_HISTORY,
  LOADING_IMAGES_HISTORY
} from '../actions/imagesCardActions';

const initialState = {
  data: null,
  loading: false,
  status: null,

  imagesHistory:null,
  imagesHistoryLoading:false,
  imagesHistoryStatus:null,
};

 const imagesCardReducer =(state = initialState, action) => {
  switch (action.type) {
    case GET_IMAGES_CARD_DETAILS:
      return {
        ...state,
        data: action.payload.result,
        status: action.payload.status,
        loading: false
      }
    case LOADING_IMAGES:
      return {
        ...state,
        loading: true
      }

      case GET_IMAGES_HISTORY:
      return {
        ...state,
        imagesHistory: action.payload.result.graph,
        imagesHistoryStatus: action.payload.status,
        imagesHistoryLoading: false
      }
    case LOADING_IMAGES_HISTORY:
      return {
        ...state,
        imagesHistoryLoading: true
      }
    default:
      return state;
  }
};

export default imagesCardReducer;