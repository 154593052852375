 
import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { toast } from 'react-toastify';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const LOADING_NOTIFICATIONS = 'LOADING_NOTIFICATIONS';
export const FILTER_NOTIFICATIONS = 'FILTER_NOTIFICATIONS';
export const FILTER_NOTIFICATIONS_LOADING = 'FILTER_NOTIFICATIONS_LOADING';
export const DELETE_ALL_NOTIFICATIONS = 'DELETE_ALL_NOTIFICATIONS';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';


export const getNotifications = (page) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const params = {
      page
    }

    const res = await axios.get(`${BASE_URL}/auth/notifications`, {
      params,
      ...options
    });

    if (res.data.data) {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: res.data
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.' );
    }
  }
}

export const hideNotification = (id) => async (dispatch) => {

  dispatch({
    type: FILTER_NOTIFICATIONS_LOADING,
  });

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    // eslint-disable-next-line
    const res = await axios.post(`${BASE_URL}/auth/notifications`, {
      id
    }, options);

    dispatch({
      type: FILTER_NOTIFICATIONS,
      payload: id
    });

  } catch (err) {
    dispatch({
      type: FILTER_NOTIFICATIONS_LOADING,
    });
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}

export const deleteAllNotifications = () => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    // eslint-disable-next-line
    const res = await axios.delete(`${BASE_URL}/auth/notifications`, options);
    if (res.data) {
      dispatch({
        type: RESET_NOTIFICATIONS,
      });
      toast.succuss("Notifications Deleted")
    }

  } catch (err) {
    // if (!err.response) {
    //     toast.warn('Warning ⚠️ bad internet connection.' );
    //   }
  }
}