import React, { useState, useEffect } from "react";
import ModuleSummary from "../../components/commons/ModuleSummary";
import Loader from "../../components/commons/Loader";
import { Add } from "@material-ui/icons";
import Spreadsheet from "react-spreadsheet";
import DataGrid from "react-data-grid";
import {
  Typography,
  Box,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import "../../Styles/css/IssueTrackerTables.css"
import {
  getSecuritySheet,
  addSheetData,
} from "../../store/actions/sheets.action";
import StatisticsCard from "./StatisticsCard";
import "./app.css";
import { Editors } from "react-data-grid-addons";

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  width: 150,
  editable: true,
};

const Sheet = () => {
  const dispatch = useDispatch();
  const selectedDomainId = useSelector(
    (state) => state.dashboard.selectedDomainId
  );
  const {
    addSecuritySheetLoading,
    getSecuritySheetLoading,
    securitySheet,
    sheetStatics,
    sheetStaticsLoading,
  } = useSelector((state) => state.sheets);

  const [data, setData] = useState([
    [{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }],
  ]);

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const addRow = () => {
    setRows([
      ...rows,
      {
        title: "",
        link: "",
        resolved: false,
        description: "",
        priority: "",
        typeOfIssue: "",
        severity: "",
        dueDate: "",
        status: "",
      },
    ]);
  };
  const { DropDownEditor } = Editors;
 const priorityTypes = [
    { id: "aaa", value: "aaa" },
    { id: "aa", value: "aa" },
    { id: "a", value: "a" },
  ];
  const severityTypes = [
    { id: "high", value: "High" },
    { id: "medium", value: "Medium" },
    { id: "low", value: "Low" },
  ];
  const priorityEditor = <DropDownEditor options={priorityTypes} />;
  const severityEditor = <DropDownEditor options={severityTypes} />;


  useEffect(() => {
    if (securitySheet)
      if (securitySheet.rows.length > 0) {
        const values = securitySheet.rows.map((row) => {
          const values = Object.values(row);
          const allVal = values.map((val) => {
            let temp;
            if (val === false) {
              temp = "no";
            } else temp = val;
            return { value: val };
          });
          return allVal;
        });

        setData([...data, ...values]);
      }
  }, [securitySheet]);

  useEffect(() => {
    // dispatch(getSheetStatistics())
    dispatch(getSecuritySheet(selectedDomainId, "security"));
  }, []);

  useEffect(() => {
    if (securitySheet)
      if (securitySheet.rows.length > 0) {
        const values = securitySheet.rows.map((row, i) => {
          if (row["resolved"] === false) {
            row["resolved"] = "no";
          } else if (row["resolved"] === true) {
            row["resolved"] = "yes";
          }

          return { id: i, ...row };
        });
        setRows([...values, ...rows]);
      }
  }, [securitySheet]);

  React.useEffect(() => {
    console.log("Rows updated:: ", rows);
  }, [rows]);

  React.useEffect(() => {
    if (securitySheet && securitySheet.columns) {
      const colSetting = securitySheet.columns.map((col, i) => {
        let colName = "";
        if (col === "typeOfIssue") {
          colName = "Type of issue";
        }
       else if (col === "dueDate") {
          colName = "Due date";
        }
         else if (col === "vulnType") {
          colName = "Vulnerability Type";
        }
         else {
          colName = col;
        }
         if (col === "severity") {
          return { id: i, key: col, name: col, editor: severityEditor };
        } else if (col === "priority") {
          return { id: i, key: col, name: col, editor: priorityEditor };
        } else return { id: i, key: col, name: colName };
      });
      console.log("colSetting:: ", colSetting);
      setColumns([...columns, ...colSetting]);
    }
  }, [securitySheet]);

  const onGridRowsUpdated = ({fromRow, toRow, updated}) => {
    console.log("fromRow:: ", fromRow);
    console.log("toRow:: ", toRow);
    console.log("updated:: ", updated);

    setRows((state) => {
      console.log("state rows:: ", state);
      const newRows = state.slice();
      console.log("newRows:: ", newRows);

      for (let i = fromRow; i <= toRow; i++) {
        newRows[i] = { ...newRows[i], ...updated };
      }
      console.log("new rows:: ", newRows);
      return newRows;
    });
  };

  return (
    <>
      {getSecuritySheetLoading ? (
        <Loader />
      ) : (
        <div
          style={{
            display: "",
          }}
        >
          <Box p={1}>
            <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
              Security Sheet
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" p={1}>
            <StatisticsCard
              sheetStatics={sheetStatics}
              loading={sheetStaticsLoading}
              title="Cyber Security"
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Tooltip title="add row" arrow placement="top-start">
              <IconButton
                style={{
                  background: "#1f3a93",
                  color: "white",
                }}
                onClick={addRow}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            style={{
              padding: "20px",
            }}
          >
            {securitySheet &&
              securitySheet.columns &&
              securitySheet.columns.length > 0 && (
                <Box mt={2}>
                  <DataGrid
                    ed
                    columns={columns.map((c) => ({
                      ...c,
                      ...defaultColumnProperties,
                    }))}
                    rowGetter={(i) => rows[i]}
                    rowsCount={rows.length}
                    onGridRowsUpdated={onGridRowsUpdated}
                    enableCellSelect={true}
                  />
                </Box>
              )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mt: 1,
            }}
          >
            <Button
              variant="contained"
              disabled={addSecuritySheetLoading}
              onClick={(e) => {
                dispatch(
                  addSheetData({
                    domainId: selectedDomainId,
                    tableData: {
                      name: "security",
                      title: "security",
                      type: "security",
                      rows,
                    },
                  })
                );
              }}
            >
              Save Data
              {addSecuritySheetLoading && (
                <CircularProgress size={12} sx={{ ml: 1, color: "blue" }} />
              )}
            </Button>
          </Box>
        </div>
      )}
    </>
  );
};

export default Sheet;
