import React from 'react';
import { Typography, Box, TextField, FormHelperText } from '@mui/material';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { makeStyles } from '@mui/styles';
import marginStyles from '../styles/margin';
import componentStyles from '../styles/stepper';

const useStyles = makeStyles(componentStyles);
const useMarginStyles = makeStyles(marginStyles);

const Index = ({ information, setInformation }) => {
    const classes = useStyles();
    const margin = useMarginStyles();
    const [error, setError] = React.useState(null);
    const [data, setData] = React.useState({
        contact: '',
        dateOfBirth: dayjs(new Date()),
        signature: ''
    });

    const handleChange = (e) => {
        const { value, name } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleDateChange = (date) => {
        setData({ ...data, dateOfBirth: date });
    };

    return (
        <div>
            <Typography className={classes.heading1}>5. Complete the required identification questions about yourself.</Typography>
            <Box mt={2}>
                <Typography className={classes.heading2}>Reliable Data Fields</Typography>
                <Typography className={`${classes.text2} ${margin['mt-10']}`}>
                    You may complete one or more of the following fields to increase the chances that we can successfully verify your identity.
                </Typography>

                <Box mt={3}>
                    <Box>
                        {/* <Typography className={classes.heading3}>Date of birth</Typography> */}
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                                className={`${margin['mt-10']} ${classes.submitFormWidth}`}
                                name="dateOfBirth"
                                inputFormat="MM/DD/YYYY"
                                value={data.dateOfBirth}
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider> */}
                    </Box>

                    <Box mt={2} className={classes.submitFormWidth}>
                        <Typography className={classes.heading3}>Emergency Contact</Typography>
                        <TextField
                            name="contact"
                            placeholder="Enter emergency contact number"
                            inputProps={{ maxLength: 11 }}
                            value={data.contact}
                            onChange={handleChange}
                            className={margin['mt-10']}
                            fullWidth
                        />
                    </Box>
                </Box>

                <Box mt={4}>
                    <Typography className={classes.heading2}>Sign Request</Typography>
                    <Typography className={`${classes.text3} ${margin['mt-5']}`}>
                        By clicking the "Finish" button and providing your electronic signature in the space below, you confirm that you
                        have read and understood our Privacy Policy and you are the employee or applicant whose personal information is the
                        subject of this request.
                    </Typography>
                    <Box mt={2} className={classes.submitFormWidth}>
                        <Typography className={classes.heading3}>Electronic Signature*</Typography>
                        <TextField
                            error={error ? true : false}
                            name="signature"
                            placeholder="Type your full name"
                            onChange={handleChange}
                            value={data.signature}
                            className={margin['mt-10']}
                            fullWidth
                        />
                        <FormHelperText sx={{ color: 'red', marginTop: '5px' }}>{error}</FormHelperText>
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default Index;
