import React from "react";
import hearing from "../../../../assets/disabilityicons/hearing.svg";
import Box from "@material-ui/core/Box";

export default function Hearing() {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      mr={1.5}
      style={{ fontSize: 13, fontWeight: 500, marginRight: 12, width: 60 }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ border: "2px solid black", borderRadius: "50%", padding: 6 }}
      >
        <img
          src={hearing}
          alt="Hearing Disability"
          height="22px"
          width="22px"
        />
      </Box>
      <span style={{ fontSize: "17.5px", fontWeight: 500 }}>Hearing</span>
    </Box>
  );
}
