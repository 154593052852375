import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import RemoveIcon from "@material-ui/icons/KeyboardArrowUp";
import AddIcon from "@material-ui/icons/KeyboardArrowDown";
import MiniBarChart from "../BarChart";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import LinearProgress from "@material-ui/core/LinearProgress";
import TablePagination from "@material-ui/core/TablePagination";
import { useSelector, useDispatch } from "react-redux";
import { getPerformanceWaterfall } from "../../../../store/actions/performanceActions";
import Auth0Service from "../../../../services/auth0Service/authentikService";
import "../structure.css";
import SubLoader from "../../../commons/SubLoader";

const useRowStyles = makeStyles({
  root: {
    cursor: "pointer",
    "& > *": {
      borderBottom: "unset",
      padding: 10,
    },
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [tab, setTab] = React.useState(0);

  const classes = useRowStyles();
  const tdClasses = useStyles();
  const handleChangeTabs = (event, newValue) => {
    setTab(newValue);
  };

  const selectedDomain = useSelector(
    (state) => state.miscellaneous.selectedDomain
  );

  let link = new URL(row.request_url);
  link = link.pathname;

  return (
    <React.Fragment>
      <TableRow hover className={classes.root} onClick={() => setOpen(!open)}>
        <StyledTableCell
          hover
          scope="row"
          style={{ position: "relative", width: "20%" }}
        >
          <Box display="flex" alignItems="center">
            <IconButton aria-label="expand row" size="small">
              {open ? (
                <RemoveIcon
                  style={{
                    fontSize: 18,
                    color: "#1f3a93",
                    fontWeight: 500,
                    marginRight: "10px",
                  }}
                />
              ) : (
                <AddIcon
                  style={{
                    fontSize: 18,
                    color: "#1f3a93",
                    marginRight: "10px",
                  }}
                />
              )}
            </IconButton>
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: "17.5px",
                fontWeight: 500,
                color:
                  row.response_status > 400 && row.response_status < 500
                    ? "red"
                    : "black",
              }}
              className={tdClasses.fileName}
            >
              {" "}
              {link}
            </span>
          </Box>
        </StyledTableCell>

        <StyledTableCell hover align="center">
          <span
            style={{
              color:
                row.response_status > 400 && row.response_status < 500
                  ? "red"
                  : "#fffffff",
            }}
          >
            {row.response_status}
          </span>
        </StyledTableCell>
        <StyledTableCell hover align="center">
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color:
                row.response_status > 400 && row.response_status < 500
                  ? "red"
                  : "black",
            }}
          >
            {selectedDomain}
          </div>
        </StyledTableCell>
        <StyledTableCell hover align="center">
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "17.5px",
              color:
                row.response_status > 400 && row.response_status < 500
                  ? "red"
                  : "black",
            }}
          >{`${row.response_size} kb`}</div>
        </StyledTableCell>
        <StyledTableCell hover align="center">
          <MiniBarChart phases={row.performance.phases} margin={20} />
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell
          classes={{ root: tdClasses.cellPaddZero }}
          style={{ paddingBottom: 0, paddingTop: 0, color: "#000" }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <a
                href={row.request_url}
                style={{
                  color: "#1f3a93",
                  fontSize: "15px",
                  wordWrap: "break-word",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {row.request_url}
              </a>
              <Tabs
                value={tab}
                onChange={handleChangeTabs}
                indicatorColor="primary"
                textColor="primary"
                aria-label="full width tabs example"
              >
                <Tab label="Headers" />
                <Tab label="Response" />
              </Tabs>
              <TabPanel value={tab} index={0}>
                <div className={tdClasses.headersHeading}>Response Headers</div>
                <Table
                  size="small"
                  style={{ maxWidth: 1280, fontFamily: "Segoe UI" }}
                >
                  <TableBody>
                    {Object.keys(row.response_headers).map((item, index) => {
                      if (item !== "set-cookie") {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              classes={{ sizeSmall: tdClasses.sizeSmall2 }}
                              style={{
                                color: "#000",
                                fontWeight: 600,
                                fontFamily: "Segoe UI",
                                fontSize: 15,
                                width: "200px",
                              }}
                              align="right"
                            >
                              {item}
                            </TableCell>
                            <TableCell
                              classes={{ tdClasses: classes.sizeSmall2 }}
                              style={{
                                color: "#000",
                                fontWeight: 400,
                                fontFamily: "Segoe UI",
                                fontSize: 15,
                                width: "1080px",
                                wordBreak: "break-all",
                              }}
                              align="left"
                            >
                              {row.response_headers[item]}
                            </TableCell>
                          </TableRow>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </TableBody>
                </Table>
                <div className={tdClasses.headersHeading}>Request Headers</div>
                <Table size="small" style={{ maxWidth: 1280 }}>
                  <TableBody>
                    {Object.keys(row.request_headers).map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            classes={{ sizeSmall: tdClasses.sizeSmall2 }}
                            style={{
                              color: "black",
                              fontWeight: 600,
                              fontFamily: "Segoe UI",
                              fontSize: 18,
                              width: "200px",
                            }}
                            align="right"
                          >
                            {item}
                          </TableCell>
                          <TableCell
                            classes={{ tdClasses: classes.sizeSmall2 }}
                            style={{
                              color: "#000",
                              fontWeight: 400,
                              fontFamily: "Segoe UI",
                              fontSize: 18,
                              width: "1080px",
                            }}
                            align="left"
                          >
                            {row.request_headers[item]}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                {row.response_body}
              </TabPanel>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function WaterFall() {
  const [categoryTab, setCategoryTab] = React.useState(0);
  const [waterfallArray, setWaterFallArray] = React.useState([]);

  const dispatch = useDispatch();
  const scanId = useSelector((state) => state.miscellaneous.selectedScanId);

  const data = useSelector((state) => state.performance.performance_waterfall);
  const status = useSelector(
    (state) => state.performance.performance_waterfall_status
  );
  const loading = useSelector(
    (state) => state.performance.performance_waterfall_loading
  );

  const isPackagePurchased = useSelector(
    (state) => state.dashboard.isDomainPackagePurchased
  );

  React.useEffect(() => {
    if (scanId) {
      dispatch(getPerformanceWaterfall(scanId));
    }
    // eslint-disable-next-line
  }, [scanId]);

  React.useEffect(() => {
    if (data) {
      setWaterFallArray(data);
    }
  }, [data]);

  React.useEffect(() => {
    if (status === "running" || status === "pending") {
      const interval = setInterval(() => {
        if (scanId) {
          dispatch(getPerformanceWaterfall(scanId));
        }
      }, 15000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [status]);

  const handleChangeTabs = (event, newValue) => {
    setPage(0);
    setCategoryTab(newValue);
    let tabo = [];
    if (newValue === 0) {
      setWaterFallArray(data);
    } else if (newValue === 1) {
      tabo = data.filter((item) => item.request_type === "document");
      setWaterFallArray(tabo);
    } else if (newValue === 2) {
      tabo = data.filter((item) => item.request_type === "stylesheet");
      setWaterFallArray(tabo);
    } else if (newValue === 3) {
      tabo = data.filter((item) => item.request_type === "image");
      setWaterFallArray(tabo);
    } else if (newValue === 4) {
      tabo = data.filter((item) => item.request_type === "media");
      setWaterFallArray(tabo);
    } else if (newValue === 5) {
      tabo = data.filter((item) => item.request_type === "font");
      setWaterFallArray(tabo);
    } else if (newValue === 6) {
      tabo = data.filter((item) => item.request_type === "script");
      setWaterFallArray(tabo);
    } else if (newValue === 7) {
      tabo = data.filter((item) => item.request_type === "texttrack");
      setWaterFallArray(tabo);
    } else if (newValue === 8) {
      tabo = data.filter((item) => item.request_type === "xhr");
      setWaterFallArray(tabo);
    } else if (newValue === 9) {
      tabo = data.filter((item) => item.request_type === "fetch");
      setWaterFallArray(tabo);
    } else if (newValue === 10) {
      tabo = data.filter((item) => item.request_type === "eventresource");
      setWaterFallArray(tabo);
    } else if (newValue === 11) {
      tabo = data.filter((item) => item.request_type === "websocket");
      setWaterFallArray(tabo);
    } else if (newValue === 12) {
      tabo = data.filter((item) => item.request_type === "manifest");
      setWaterFallArray(tabo);
    } else if (newValue === 13) {
      tabo = data.filter((item) => item.request_type === "manifest");
      setWaterFallArray(tabo);
    } else {
      setWaterFallArray([]);
    }
  };
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      {data && !loading ? (
        <TableContainer
          component={Paper}
          style={{ maxWidth: 1280, padding: 17 }}
        >
          <CustomTabs
            value={categoryTab}
            onChange={handleChangeTabs}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            style={{
              marginBottom: 30,
            }}
          >
            <CustomTab label="All" />
            <CustomTab label="Document" />
            <CustomTab label="Stylesheet" />
            <CustomTab label="Image" />
            <CustomTab label="Media" />
            <CustomTab label="Font" />
            <CustomTab label="Script" />
            <CustomTab label="Texttrack" />
            <CustomTab label="XHR" />
            <CustomTab label="fetch" />
            <CustomTab label="Eventsource" />
            <CustomTab label="websocket" />
            <CustomTab label="Manifest" />
            <CustomTab
              label="Others"
              style={{ borderRight: "1px solid #1f3a93" }}
            />
          </CustomTabs>
          <Table
            aria-label="collapsible table"
            size="small"
            style={{
              tableLayout: "fixed",
              overflow: "auto",
              minWidth: 800,
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    width: "20%",
                    fontSize: 15,
                    fontFamily: "Segoe UI",
                    color: "#1f3a93",
                  }}
                  align="center"
                >
                  URL
                </TableCell>
                <TableCell
                  style={{
                    width: 55,
                    fontSize: 15,
                    fontFamily: "Segoe UI",
                    color: "#1f3a93",
                  }}
                  align="center"
                >
                  Status
                </TableCell>
                <TableCell
                  style={{
                    width: 120,
                    fontSize: 15,
                    fontFamily: "Segoe UI",
                    color: "#1f3a93",
                  }}
                  align="center"
                >
                  Domain
                </TableCell>
                <TableCell
                  style={{
                    width: 70,
                    fontSize: 15,
                    fontFamily: "Segoe UI",
                    color: "#1f3a93",
                  }}
                  align="center"
                >
                  Size
                </TableCell>
                <TableCell
                  style={{
                    width: "80%",
                    fontSize: 15,
                    fontFamily: "Segoe UI",
                    color: "#1f3a93",
                  }}
                  align="center"
                >
                  Timeline
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {waterfallArray.length > 0 &&
                waterfallArray
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => <Row key={index} row={row} />)}
              {!isPackagePurchased && (
                <TableRow>
                  <TableCell
                    colspan={5}
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      textAlign: "center",
                      padding: 0,
                    }}
                  >
                    <div
                      style={{
                        margin: "auto",
                        width: "100%",
                        height: 200,
                        backgroundColor: "#1f3a93",
                        padding: 10,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                      }}
                    >
                      <div className="ps-stic2">
                        <h4>
                          To see complete data, please subscribe to our Package.
                        </h4>
                        <Button
                          component="a"
                          style={{ borderRadius: "20px" }}
                          href={`${
                            Auth0Service.isAuthenticated()
                              ? "/billing"
                              : "/authenticate"
                          }`}
                          color="white"
                          variant="contained"
                        >
                          Subscribe
                        </Button>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={waterfallArray.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableContainer>
      ) : (
        // <LinearProgress />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            height: "500px",
            width: "100%",
          }}
        >
          <SubLoader />
        </Box>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  tdSeverity: {
    color: "#ffffff",
    fontSize: 17,
    fontWeight: 400,
    textAlign: "center",
  },
  headersHeading: {
    fontWeight: "bold",
    margin: "17px 0px",
    fontSize: 20,
    fontFamily: "Segoe UI",
  },
  tdTitle: {
    fontWeight: "bold",
    color: "#256fa2",
    marginLeft: 6,
    fontFamily: "Lato",
    textDecoration: "none",
    fontSize: 16,
    display: "block",
  },
  tdRules: {
    fontWeight: "bold",
    color: "#256fa2",
    marginLeft: 6,
    fontFamily: "Lato",
    textDecoration: "none",
  },
  tdCollapseButton: {
    backgroundColor: "#e2e2e2",
  },

  cellPaddZero: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  cellPaddSmall: {
    [theme.breakpoints.down("md")]: {
      padding: "6px 2px 6px 16px",
    },
  },

  urlTh: {
    borderBottom: 0,
    display: "list-item",
    listStyleType: "disc",
    listStylePosition: "outside",
    wordBreak: "break-all",
    marginLeft: 25,
    paddingLeft: 0,
  },

  transeferSizeTh: {
    color: "#a4a3a3",
    fontSize: 13,
    width: 150,
    [theme.breakpoints.down("md")]: {
      width: 80,
    },
  },
  potentialSavingsTh: {
    color: "#a4a3a3",
    fontSize: 13,
    [theme.breakpoints.down("md")]: {
      width: 80,
    },
  },

  tdSeverityTh: {
    width: 100,
    color: "rgb(142 138 138)",
    fontWeight: 800,
    [theme.breakpoints.down("md")]: {
      width: 10,
    },
  },

  fileName: {
    fontSize: 12,
    fontWeight: "bold",
    letterSpacing: 0.2,
  },

  link: {
    textDecoration: "underline",
    cursor: "pointer",
    color: "#00a5b5",
  },
  sizeSmall2: {
    padding: "0px 3px 1px 0px",
    whiteSpace: "word-wrap",
    fontFamily: "Segoe UI",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "0 3px",
    verticalAlign: "middle",
    borderBottom: "1px solid #efefef",
    whiteSpace: "nowrap",
    textOverflow: "clip",
    // overflow: 'hidden',
  },
}))(TableCell);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <code>{children}</code>
        </Box>
      )}
    </div>
  );
}

const CustomTabs = withStyles({
  root: {
    borderBottom: "none",
  },
  indicator: {
    // backgroundColor: 'white',
    display: "none",
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    fontFamily: "Segoe UI",
    minWidth: 40,
    opacity: 1,
    minHeight: "auto",
    borderRadius: "2px",
    padding: "0px 8px",
    border: "1px solid #1f3a93",
    borderRight: "none",
    color: "#1f3a93",
    fontWeight: 400,
    fontSize: "17px",
    margin: "0px",
    marginTop: 8,
    "&:hover": {
      color: "white",
      backgroundColor: "#1f3a93",
      opacity: 1,
    },
    "&$selected": {
      color: "white",
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: "#1f3a93",
    },
    "&:focus": {
      color: "white",
      backgroundColor: "#1f3a93",
    },
  },
  selected: {
    color: "white",
    backgroundColor: "#1f3a93",
    // display: 'none'
  },
}))((props) => <Tab disableRipple {...props} />);
