import React from 'react';
import {
    Radio,
    Box,
    Typography,
    FormControlLabel,
    TextField,
    Divider,
    Button,
    RadioGroup,
    FormControl,
    FormHelperText
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { zipcodes } from './zipcodes';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Header from './Header';
import componentStyles from '../styles/stepper';

const useStyles = makeStyles(componentStyles);

const Residency = ({ setPlace, place,error, setError }) => {
    const classes = useStyles();
   
    const handleChange = (e) => {
        const { value } = e.target;
        setPlace({
            california: value
        });
    };
    React.useEffect(() => {
        console.log('Change');
        console.log('place.zipcode: ', place.zipcode);
        console.log('zipcodes.includes(place.zipcode: ', zipcodes.includes(place.zipcode));

        if (place.zipcode)
            if (place.zipcode.length < 5 || !zipcodes.includes(place.zipcode)) {
                setError('Enter a valid 5 digit zipcode');
            } else {
                setError(null);
            }
    }, [place.zipcode]);
    return (
        <>
            <Header />

            <Box style={{ marginTop: '25px' }}>
                <Typography variant="text" className={classes.heading3}>
                    Are you a California resident?
                </Typography>
                <Box>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="california"
                            onChange={handleChange}
                            value={place.california}
                            row
                        >
                            <FormControlLabel
                                className={classes.text3}
                                value="yes"
                                sx={{
                                    '& .css-102lq7w-MuiTypography-root': {
                                        fontSize: '16px'
                                    }
                                }}
                                control={<Radio />}
                                label="yes"
                            />
                            <FormControlLabel
                                className={classes.text3}
                                value="no"
                                sx={{
                                    '& .css-102lq7w-MuiTypography-root': {
                                        fontSize: '16px'
                                    }
                                }}
                                control={<Radio />}
                                label="no"
                            />
                        </RadioGroup>
                        {place.california && place.california !== 'yes' && (
                            <FormHelperText error id="standard-weight-helper-text-email-login">
                                You must be a california resident
                            </FormHelperText>
                        )}
                    </FormControl>
                </Box>
            </Box>
            <Box style={{ marginTop: '25px' }}>
                <Typography component="h2" className={classes.heading3}>
                    Zip Code <span style={{ color: 'red' }}>*</span>
                </Typography>

                <FormControl>
                    <TextField
                        variant="outlined"
                        placeholder="Zip Code"
                        value={place.zipcode}
                        name="zipcode"
                        inputProps={{ maxLength: 5 }}
                        onChange={(e) => {
                            setPlace({
                                ...place,
                                zipcode: e.target.value
                            });
                        }}
                        style={{
                            width: '450px',
                            borderRadius: '10px',
                            marginTop: '10px'
                        }}
                    />
                    {error && (
                        <FormHelperText error id="standard-weight-helper-text-email-login">
                            {error}
                        </FormHelperText>
                    )}
                </FormControl>
            </Box>
        </>
    );
};

export default Residency;
