import {
  SET_SCAN_DATA, SET_SCANS
} from '../actions/miscellaneousActions';

const initialState = {
  selectedDomain: '',
  selectedScanId: '',
  selectedScan: '',
  selectedScanManagerId: '',
  scans: []
};

const miscellaneousReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCAN_DATA:
      return {
        ...state,
        selectedScanId: action.payload.selectedScanId,
        selectedScan: action.payload.selectedScan,
        selectedDomain: action.payload.selectedDomain,
        selectedScanManagerId: action.payload.selectedScanManagerId
      }
    case SET_SCANS:
      return {
        ...state,
        scans: action.payload
      }
    default:
      return state;
  }
};
export default miscellaneousReducer;