import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { CopyToClipboard } from "react-copy-to-clipboard";
import IconButton from "@material-ui/core/IconButton";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { CircularProgress, Grid } from '@material-ui/core';

import { dateFormatter } from "../../utils/dateFormatter";
import { getCompletedScans } from "../../store/actions/customScanPageListActions";
import history from '../../@history';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {

  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root} hover  >
        <TableCell component="th" scope="row" style={{ width: "100%" }}>
          <Box display="flex" alignItems="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Typography style={{ cursor: "pointer" }} onClick={() => history.push(`/dashboard?domain=${row.domain}&scanId=${row.scanId}`)} >{row.scanId} - {dateFormatter(new Date(row.dateNow))}</Typography>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, overflow: "auto" }} colSpan={6} >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <p style={{ fontFamily: "Segoe UI" }}>Date</p>
                  <p>{dateFormatter(new Date(row.dateNow))}</p>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <p style={{ fontFamily: "Segoe UI" }}>Browser</p>
                  <p>{row.browser}</p>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <p style={{ fontFamily: "Segoe UI" }}>Device</p>
                  <p>{row.device}</p>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <p style={{ fontFamily: "Segoe UI" }}>Resolution</p>
                  <p>{row.resolution}</p>
                </Grid>
              </Grid>
              <Box display="flex" alignItems="center" flexWrap="wrap">
                <h3 style={{ marginBottom: 0 }}>Badges</h3>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <p style={{ fontFamily: "Segoe UI" }}>
                      Transparent
                      {row.badges && row.badges.transparent && (
                        <CopyToClipboard text={row.badges.transparent}>
                          <IconButton style={{ padding: 6 }}>
                            <FileCopyOutlinedIcon />
                          </IconButton>
                        </CopyToClipboard>
                      )}
                    </p>
                    {row.badges && row.badges.transparent && (
                      <img src={row.badges.transparent} style={{ maxWidth: 230, height: 'auto' }} alt="transparent-badge" />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <p style={{ fontFamily: "Segoe UI" }}>
                      Black
                      {row.badges && row.badges.black && (
                        <CopyToClipboard text={row.badges.black}>
                          <IconButton style={{ padding: 6 }}>
                            <FileCopyOutlinedIcon />
                          </IconButton>
                        </CopyToClipboard>
                      )}
                    </p>
                    {row.badges && row.badges.black && (
                      <img src={row.badges.black} style={{ maxWidth: 230, height: 'auto' }} alt="black-badge" />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <p style={{ fontFamily: "Segoe UI" }}>
                      White
                      {row.badges && row.badges.white && (
                        <CopyToClipboard text={row.badges.white}>
                          <IconButton style={{ padding: 6 }}>
                            <FileCopyOutlinedIcon />
                          </IconButton>
                        </CopyToClipboard>
                      )}
                    </p>
                    {row.badges && row.badges.white && (
                      <img src={row.badges.white} style={{ maxWidth: 230, height: 'auto' }} alt="white-badge" />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CompletedScan() {

  const dispatch = useDispatch();

  const data = useSelector((state) => state.customScanList.completedScans);
  const loading = useSelector((state) => state.customScanList.completedScansLoading);
  const domainId = useSelector((state) => state.dashboard.selectedDomainId);

  React.useEffect(() => {
    if (domainId) {
      dispatch(getCompletedScans(domainId));
    }
    // eslint-disable-next-line
  }, [domainId])

  return (

    <Table aria-label="collapsible table" style={{ tableLayout: "fixed" }} >
      <TableHead>
        <TableRow>
          <TableCell
            style={{
              width: "100%",
            }}
          >Completed Scans</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!loading ? <>
          {data.length > 0 ? <> {data.map((row,) => (
            <Row key={row.scanId} row={row} />
          ))}</> :
            <TableRow>
              <TableCell align="center">No records to display</TableCell>
            </TableRow>
          }
        </> :
          <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>}
      </TableBody>
    </Table>
  );
}

