import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import GraphCard from "../commons/GraphCard";
import DateRangePicker from "../commons/DateRangePicker";
import CircularProgressRing from "../commons/CircularProgressRing";
import { useSelector } from "react-redux";
import SecurityCurrentCard from "./analyticsCard/security-boxes/SecurityBoxes";
import CyberSecCardOne from "./historyCards/CyberSecCardOne";
import Auth0Service from "../../services/auth0Service/authentikService";

function SecurityCurrentAndHistory({ scanId }) {
  const [time, setTime] = React.useState({
    current: true,
    overtime: false,
  });

  const domains = useSelector((state) => state.dashboard.domains);
  const selectedDomain = useSelector(
    (state) => state.miscellaneous.selectedDomain
  );
  const { isPackagePurchased } = useSelector((state) => state.auth.user);

  const [dateRange, setDateRange] = React.useState(null);
  const [loader, setLoader] = React.useState(false);

  return (
    <React.Fragment>
      <GraphCard height="auto">
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Box display="flex">
            <h2
              style={{
                margin: 0,
                fontSize: 18,
                lineHeight: 1,
                fontWeight: 500,
              }}
            >
              {time.current ? "" : "History"}
            </h2>{" "}
            {loader && <CircularProgressRing />}
          </Box>

          <div>
            <ButtonGroup
              size="small"
              aria-label="Button group to switch between current and overtime"
            >
            </ButtonGroup>
            {time.overtime && <DateRangePicker setDateRange={setDateRange} />}
          </div>
        </Box>
        {time.current ? (
          <SecurityCurrentCard setLoader={setLoader} scanId={scanId} />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <CyberSecCardOne
                setLoader={setLoader}
                scanId={scanId}
                dateRange={dateRange}
              />
            </Grid>
          </Grid>
        )}
      </GraphCard>
    </React.Fragment>
  );
}
export default React.memo(SecurityCurrentAndHistory);
