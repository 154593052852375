// const BASE_URL = 'https://allyrightapp.com/api/v2';
// const BASE_URL = 'http://45.79.22.43:3000/api/v2';
// const BASE_URL = 'http://45.33.14.183:3000/api/v2';
//const BASE_URL = "http://backend.staging.allyrightapp.com:3000/api/v2";
let BASE_URL = "";
if (process.env.REACT_APP_ENVIRONM === "prod") {
  BASE_URL = process.env.REACT_APP_BASE_URL_PROD;
} else {
  BASE_URL = process.env.REACT_APP_BASE_URL_DEV;
}

const PAYMENTS_BASE_URL = "///";
// const PAYMENTS_BASE_URL = "http://localhost:3005";

// const BASE_URL = "/api/v2";
// const BASE_URL = 'http://backend.staging.allyrightapp.com:3500/api/v2';

// const BASE_URL_OLD_DASHBOARD = 'OLD_FRONTEND_URL_REPLACE';
// const BASE_URL_OLD_DASHBOARD = "https://olddash.allyrightapp.com";
let BASE_URL_OLD_DASHBOARD = "";

if (process.env.REACT_APP_ENVIRONM === "prod") {
  BASE_URL_OLD_DASHBOARD = process.env.REACT_APP_BASE_URL_OLD_DASHBOARD_PROD;
} else {
  BASE_URL_OLD_DASHBOARD = process.env.REACT_APP_BASE_URL_OLD_DASHBOARD_DEV;
}

console.log("BASE_URL:" + BASE_URL);
console.log("BASE_URL_OLD_DASH:" + BASE_URL_OLD_DASHBOARD);

export { BASE_URL, BASE_URL_OLD_DASHBOARD, PAYMENTS_BASE_URL};
