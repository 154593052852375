import React from "react";
import { useSelector } from "react-redux";
import AccessibilityReport from "../../pages/AccessibilityReport";
import ScanSelector from "../commons/ScanSelector";
import Accessibility from "./AccessibilityCurrentAndHistory";
import Box from "@material-ui/core/Box";
import ModuleSummary from "../commons/ModuleSummary";
import Section508CurrentAndHistory from "./Section508CurrentAndHistory";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@material-ui/core";
import AccessibilityIssueSheet from "./AccessibilityIssueSheet";
import Auth0Service from "../../services/auth0Service/authentikService";

export default function AccessibilityDashboard() {
  const selectedScanId = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );

  const [reportType, setReportType] = React.useState({
    report: false,
    section508: false,
    analytics: true,
  });

  const accessibilityTabs = useSelector((state) => state.dashboard.tabsInfo?.accessibility);
  const tabsLoading = useSelector((state) => state.dashboard.tabsInfoLoading);

  const CustomTab = withStyles((theme) => ({
    root: {
      minWidth: 40,
      opacity: 1,
      minHeight: "auto",
      borderRadius: "2px",
      padding: "0px 8px",
      border: "1px solid #1f3a93",
      borderRight: 0,

      color: "#1f3a93",
      fontWeight: 400,
      fontSize: "18px",
      "&:last-child": {
        borderRight: "1px solid #1f3a93",
      },
      "&:hover": {
        color: "#ffff!important",
        backgroundColor: "#1f3a93",
        opacity: 1,
      },
      "&$selected": {
        color: "white",
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: "#1f3a93",
      },
      "&:focus": {
        color: "white",
        backgroundColor: "#1f3a93",
      },
    },
    selected: {
      color: "white",
      backgroundColor: "#1f3a93",
      // display: 'none'
    },
  }))((props) => <Tab disableRipple {...props} />);

  return (
    <>
      <ScanSelector />
      <ModuleSummary
        module="accessibility"
        title="Overall Accessibility Score"
      />
      <Box display="flex" flexWrap="wrap" alignItems="center" mb={2}>
        <CustomTab
          label="WCAG 2.2 Analytics"
          onClick={() => setReportType({ analytics: true })}
          style={{
            backgroundColor: reportType.analytics ? "#1f3a93" : "none",
            color: reportType.analytics ? "#ffff" : "#1f3a93",
          }}
          tabIndex="0"
        />
{/* 
        <CustomTab
          label={`Section 508 Analytics`}
          onClick={() => setReportType({ section508: true })}
          tabIndex="0"
          style={{
            backgroundColor: reportType.section508 ? "#1f3a93" : "none",
            color: reportType.section508 ? "#ffff" : "#1f3a93",
          }}
        /> */}
        {/* {Auth0Service.isAuthenticated() && <CustomTab
          label={`Spread Sheet`}
          onClick={() => setReportType({ spreadSheet: true })}
          tabIndex="0"
          style={{
            backgroundColor: reportType.spreadSheet ? "#1f3a93" : "none",
            color: reportType.spreadSheet ? "#ffff" : "#1f3a93",
          }}
        />} */}
        {tabsLoading ? <CircularProgress style={{ marginLeft: 10 }} size={20} /> : <>
          {Object.values(accessibilityTabs).some(item => item === true) && <CustomTab
            label="Data tables"
            onClick={() => setReportType({ report: true })}
            tabIndex="0"
            style={{
              backgroundColor: reportType.report ? "#1f3a93" : "none",
              color: reportType.report ? "#ffff" : "#1f3a93",
            }}
          />}
        </>
        }
      </Box>

      {reportType.analytics && (
        <Accessibility scanId={selectedScanId} overTime={true} />
      )}
      {/* {reportType.spreadSheet && (
        <AccessibilityIssueSheet scanId={selectedScanId} overTime={true} />
      )} */}
      {reportType.section508 && (
        <Section508CurrentAndHistory scanId={selectedScanId} overTime={true} />
      )}
      {reportType.report && <AccessibilityReport />}
    </>
  );
}
