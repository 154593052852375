import {
  GET_SEO_TABLE
} from '../actions/seoTablesActions';

const initialState = {
  seoMain: null,
  seoMainStatus: "pending",
  seoMainPages: null,
  seoMainCount: null,
};

const seoTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SEO_TABLE:
      return {
        ...state,
        seoMain: action.payload.result,
        seoMainPages: action.payload.pages,
        seoMainCount: action.payload.count,
        seoMainStatus: action.payload.status,
      };
    default:
      return state;
  }
};

export default seoTablesReducer;