import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Button, Card, CircularProgress, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import { useDispatch } from "react-redux"
import { addFreeDomain, domainFreeDuration, getAllUnsubDomains } from "../store/actions/adminScansManagementActions";

export default function UnsubDomains() {

    const [loadingDomains, setLoadingDomains] = React.useState(true)
    const [data, setData] = React.useState([])

    const [addDomainId, setAddDomainId] = React.useState(null)
    const [addLoading, setAddLoading] = React.useState(false)
    const [domainFreeLoading, setDomainFreeLoading] = React.useState(false)
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [days, setDays] = React.useState(0)






    const dispatch = useDispatch();
    function getDomains() {
        dispatch(getAllUnsubDomains()).then(data => {
            if (data) {
                setLoadingDomains(false);
                setData(data.result)
            } else {
                setLoadingDomains(false)

            }
        }).catch(err => {
            setLoadingDomains(false)
        })
    }

    React.useEffect(() => {
        getDomains()
    }, [])

    function handleAddToFree(id) {
        setAddDomainId(id)
        setAddLoading(true)
        dispatch(addFreeDomain({
            domainId: id,
        })).then(data => {
            if (data) {
                setAddLoading(false)
                setAddDomainId(null)
                getDomains()
            } else {
                setAddLoading(false)
                setAddDomainId(null)

            }
        }).catch(err => {
            setAddLoading(false)
            setAddDomainId(null)
        })
    }


    function handleDurationFree(e) {
        e.preventDefault();
        setDomainFreeLoading(true)
        dispatch(domainFreeDuration({
            days,
            domainId: dialogOpen,
        })).then(data => {
            if (data) {
                setDomainFreeLoading(false)
                setDialogOpen(false)
                getDomains()
            } else {
                setDomainFreeLoading(false)
            }
        }).catch(err => {
            setDomainFreeLoading(false)

        })
    }


    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="simple table" style={{ tableLayout: 'fixed' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Domain</TableCell>
                            <TableCell >Full Domain</TableCell>
                            <TableCell >ID</TableCell>
                            <TableCell style={{ width: 400 }} >Action</TableCell>
                        </TableRow>
                    </TableHead>
                    {!loadingDomains ? <TableBody>
                        {data.length > 0 ? data.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row.domain}
                                </TableCell>
                                <TableCell style={{ wordBreak: 'break-all' }} >{row.fullDomain}</TableCell>
                                <TableCell style={{ wordBreak: 'break-all' }} >{row._id}</TableCell>
                                <TableCell style={{ wordBreak: 'break-all' }} >
                                    <Box display='flex' alignItems='center' style={{ gap: 10 }}>
                                        <Button
                                            onClick={() => handleAddToFree(row._id)} disabled={addLoading}
                                            color='primary' variant='contained'>Add to Free Domains
                                            {row._id === addDomainId && <CircularProgress style={{ marginLeft: 5 }} size={14} />}
                                        </Button>
                                        <Button variant="contained" onClick={() => setDialogOpen(row._id)}>
                                            Free Duration
                                        </Button>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )) :
                            <TableRow><TableCell colSpan={4}>no records found</TableCell></TableRow>
                        }
                    </TableBody> :
                        <TableBody>
                            <TableRow><TableCell align='center' colSpan={4}>
                                <CircularProgress />
                            </TableCell></TableRow>
                        </TableBody>
                    }
                </Table>
                <Dialog open={Boolean(dialogOpen)} onClose={() => setDialogOpen(false)}>
                    <DialogTitle>Domain Free Duration</DialogTitle>
                    <DialogContent>
                        <Box display='flex' style={{ gap: 14 }} mb={2} alignItems='center' component='form' onSubmit={handleDurationFree}>
                            <TextField variant="filled" min={1} type='number' label='Days' fullWidth onChange={(e) => setDays(e.target.value)} />
                            <Button variant="contained" color="primary" type="submit" disabled={domainFreeLoading} >Submit   {domainFreeLoading && <CircularProgress style={{ marginLeft: 5 }} size={14} />}</Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            </TableContainer>
        </>
    )
}