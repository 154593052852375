const tablesWrapperStyles ={
  marginRight: {
    marginRight: 9,
  },
    wplinks: {
    textDecoration: "none",fontWeight:500
  },
  textCenter: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  filename: {
    color: "black",fontWeight:500
  },
};

export default tablesWrapperStyles;