export const SHOW_ALERT = "SHOW_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const showAlert = (message) => async (dispatch) => {
  dispatch({
    type: SHOW_ALERT,
    payload: message
  });
}

export const removeAlert = () => async (dispatch) => {
  dispatch({
    type: REMOVE_ALERT
  });
}