import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from 'recharts';

export default function MiniBarChart({ phases }) {
  const [dataset, setDataset] = React.useState([]);
  React.useEffect(() => {
    if (phases) {
      setDataset([
        {
          // name: 'timings',
          Blocking: phases.request,
          DNSLookUp: phases.dns,
          Connecting: phases.downlaod,
          Waiting: phases.wait,
          Total: phases.total,
        },
      ]);
    }
  }, [phases]);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <ResponsiveContainer width={"100%"} height={70}>
        <BarChart
          layout='vertical'
          width={500}
          height={50}
          data={dataset}
        >
          <CartesianGrid strokeDasharray='3 3' />

          <XAxis
            type='number'
            unit='ms'
            ticks={[1, 5, 50, 100, 150, 200, 250, 500, 700, 800, 1000]}
            domain={[0, 1000]}
          />
          <YAxis type='category' />
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey='Blocking' stackId='a' fill='#E2211E' />
          <Bar dataKey='DNSLookUp' stackId='a' fill='#57b0bf' />
          <Bar dataKey='Connecting' stackId='a' fill='#b0dc59' />
          <Bar dataKey='Waiting' stackId='a' fill='#218B34' />
          <Bar dataKey='Total' stackId='a' fill='#FFA412' />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};