
const defaultState = {
  openSideMenu: false,
  listCollapse: false,
  settingList: false,
  issueList: false,
  adminManagementList: false,
  websiteManagementList: false,

  abrakadabraBox: false,
  schemaBox: false,

  toggleMenu: false,
  openMessages: false,
  openNotifications: false,
  openChats: false,
  monthSelector: false
}

const navigationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "OPEN_SIDE_MENU":
      return {
        openSideMenu: true,
      }
    case "CLOSE_SIDE_MENU":
      return {
        openSideMenu: false,
      }
    case "TOGGLE_LIST_ITEM":
      return {
        ...state,
        listCollapse: !state.listCollapse,
        openSideMenu: true,
      }
    case "TOGGLE_PROJECT_MANAGEMENT_LIST":
      return {
        ...state,
        projectManagementList: action.payload,
        openSideMenu: true,
      }
    case "TOGGLE_SETTING_LIST":
      return {
        ...state,
        settingList: action.payload,
        openSideMenu: true,
      }
    case "TOGGLE_ADMIN_MANAGEMENT_LIST":
      return {
        ...state,
        adminManagementList: action.payload,
        openSideMenu: true,
      }
    case "TOGGLE_WEBSITE_MANAGEMENT_LIST":
      return {
        ...state,
        websiteManagementList: action.payload,
        openSideMenu: true,
      }
    case "TOGGLE_ABRAKADABRA_LIST":
      return {
        ...state,
        abrakadabraBox: action.payload,
        openSideMenu: true,
      }
    case "TOGGLE_SCHEMA_LIST":
      return {
        ...state,
        schemaBox: action.payload,
        openSideMenu: true,
      }
    case "TOGGLE_ISSUE_LIST":
      return {
        ...state,
        issueList: action.payload,
        openSideMenu: true,
      }
    case "TOGGLE_MENU_BAR":
      return {
        ...state,
        toggleMenu: !state.toggleMenu,
      }
    case "TOGGLE_MESSAGES_MENU":
      return {
        ...state,
        openMessages: !state.openMessages,
      }
    case "TOGGLE_NOTIFICATIONS_MENU":
      return {
        ...state,
        openNotifications: !state.openNotifications,
      }
    case "TOGGLE_CHATS_MENU":
      return {
        ...state,
        openChats: !state.openChats,
      }
    default: return state
  }
}

export default navigationReducer;