import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { toast } from "react-toastify";
import CustomCardContainer from "../../components/commons/CustomCardContainer";
import UniqueSitemap from "./sitemaps/UniqueSitemap";
import { useSelector, useDispatch } from "react-redux";
import Profilers from "./Profilers";
import { startScan } from "../../store/actions/customScanPageListActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import {  DomainModulesAdmin } from "../../../src/store/actions/adminScansManagementActions"

export default function ScansOptions() {
  const dispatch = useDispatch();

  const scanId = useSelector(
    (state) => state.miscellaneous.selectedScanManagerId
  );
  const domainName = useSelector((state) => state.miscellaneous.selectedDomain);
  const selectedDomainId = useSelector(
    (state) => state.dashboard.selectedDomainId
  );

  const scanOptions = {
    browsers: ["chrome"],
    resolutions: ["2732x2048", "1920x1080", "1125x2436"],
    devices: ["desktop"],
  };

  const [loading, setLoading] = React.useState(false);
  const [modules, setModules] = React.useState({ accessibility: false, performance: false, webPrivacy: false, cybersec: false, languagAndGrammer: false });
  const [scanValues, setScanValues] = React.useState({
    scanId: "",
    links: [],
    scanOptions: {
      browsers: [],
      resolutions: [],
      devices: ["desktop"],
    },
    modules: {
      accessibility: false,
      cyberSecurity: false,
      seo: false,
      performance: false,
    },
  });
  // eslint-disable-next-line
  const [links] = React.useState({
    allLinks: [],
    uniqueLinks: [],
  });

  React.useEffect(() => {
    if (selectedDomainId) {
      getModules(selectedDomainId);
    }
  }, [selectedDomainId]);

  function getModules(selectedDomainId) {
    console.log("Fetching modules for domain:", selectedDomainId);
    dispatch(DomainModulesAdmin({ selectedDomainId }))
      .then((data) => {
        if (data) {
          console.log("Data from getModules:", data.result);
  
          // Define the specific keys to include
          const keysToInclude = ["accessibility", "cybersec", "performance", "seo", "languagAndGrammer"];
  
          // Filter and map only the modules with true values and specific keys
          const filteredModules = Object.fromEntries(
            Object.entries(data.result)
              .filter(([key, value]) => value === true && keysToInclude.includes(key))
              .map(([key, value]) => {
                // If key is 'cybersec', rename it to 'cybersecurity'
                if (key === "cybersec") {
                  key = "cyberSecurity";
                }
                return [key, value];
              })
          );
  
          // Set the filtered modules
          setModules(filteredModules);
          setScanValues({
            ...scanValues,
            scanId: scanId,
            modules: filteredModules
          });
        }
      })
      .catch((error) => {
        console.error("Failed to fetch modules:", error);
      });
  }

  const deviceDetermination = (value) => {
    if (
      value === "1920x1080" ||
      value === "3840 x 2160" ||
      value === "1536x864" ||
      value === "1280x720" ||
      value === "2732x2048" ||
      value === "1280 x 1024" ||
      value === "1366x768" ||
      value === "1440x900" ||
      value === "1600x900"
    ) {
      return `${value}(Desktop)`;
    } else {
      return `${value}(Tablet)`;
    }
  };
  function handleResolutions(event, item) {
    if (event.target.checked) {
      setScanValues({
        ...scanValues,
        scanOptions: {
          ...scanValues.scanOptions,
          resolutions: [...scanValues.scanOptions.resolutions, item],
        },
      });
    } else {
      const filteredResolutions = scanValues.scanOptions.resolutions.filter(
        (resolution) => resolution !== item
      );
      setScanValues({
        ...scanValues,
        scanOptions: {
          ...scanValues.scanOptions,
          resolutions: filteredResolutions,
        },
      });
    }
  }

  function handleBrowsers(event, item) {
    if (event.target.checked) {
      setScanValues({
        ...scanValues,
        scanOptions: {
          ...scanValues.scanOptions,
          browsers: [...scanValues.scanOptions.browsers, item],
        },
      });
    } else {
      const filteredBrowsers = scanValues.scanOptions.browsers.filter(
        (browser) => browser !== item
      );
      setScanValues({
        ...scanValues,
        scanOptions: { ...scanValues.scanOptions, browsers: filteredBrowsers },
      });
    }
  }

  React.useEffect(() => {
    if (scanId) {
      setScanValues({ ...scanValues, scanId: scanId });
    }
    // eslint-disable-next-line
  }, [scanId]);

  function commenceScan() {
    setLoading(true);
    dispatch(startScan(domainName, scanValues)).then((data) => {
      if (data) {
        toast.success("Your scan has Started");
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <CustomCardContainer title={"Select Resolutions"}>
            <Grid container>
              {scanOptions.resolutions.map((item, index) => {
                return (
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={6} key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={item}
                          color="primary"
                          onChange={(event) => handleResolutions(event, item)}
                        />
                      }
                      label={deviceDetermination(item)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </CustomCardContainer>
        </Grid>
        {/* <Grid item xs={12} lg={6}>
          <CustomCardContainer title={"Select Browsers"}>
            <Grid container>
              {scanOptions.browsers.map((item, index) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={4} xl={2} key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={item}
                          color="primary"
                          onChange={(event) => handleBrowsers(event, item)}
                        />
                      }
                      label={item}
                    />
                  </Grid>
                );
              })}

              <Grid item xs={12} sm={6} md={4} lg={4} xl={2} >
                <FormControlLabel
                  control={
                    <Checkbox
                      name={"Edge"}
                      color="primary"
                      disabled={true}
                    />
                  }
                  label={<div>Edge<p style={{ fontSize: 10, margin: 0 }}>(Coming Soon)</p></div>}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2} >
                <FormControlLabel
                  control={
                    <Checkbox
                      name={"Safari"}
                      color="primary"
                      disabled={true}
                    />
                  }
                  label={<div>Safari<p style={{ fontSize: 10, margin: 0 }}>(Coming Soon)</p></div>}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={2} >
                <FormControlLabel
                  control={
                    <Checkbox
                      name={"Firfox"}
                      color="primary"
                      disabled={true}
                    />
                  }
                  label={<div>Firefox  <p style={{ fontSize: 10, margin: 0 }}>(Coming Soon)</p></div>}
                />
              </Grid>
            </Grid>
          </CustomCardContainer>
        </Grid> */}
      </Grid>
      <Profilers setScanValues={setScanValues} scanValues={scanValues} />
      <CustomCardContainer title={"Unique Sitemap Links"}>
        <UniqueSitemap
          setScanValues={setScanValues}
          links={links.uniqueLinks}
          scanValues={scanValues}
        />
      </CustomCardContainer>
      {/* <CustomCardContainer links={links.allLinks} title={"All Sitemap Links"}>
        <Sitemap />
      </CustomCardContainer> */}
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          color="primary"
          variant="contained"
          disabled={
            Object.values(scanValues.modules).indexOf(true) === -1 ||
            scanValues.scanOptions.devices.length < 1 ||
            scanValues.scanOptions.resolutions.length < 1 ||
            scanValues.links.length < 1 ||
            loading
          }
          onClick={() => commenceScan()}
        >
          Start Scan{" "}
          {loading && <CircularProgress size={20} style={{ marginLeft: 10 }} />}
        </Button>
      </Box>
    </React.Fragment>
  );
}
