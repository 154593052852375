import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Auth0Service from "../services/auth0Service/authentikService";

import { getAllDomains, addBreachedInfo, DomainModules, enableDisableModules } from "../store/actions/adminScansManagementActions";
import { useParams } from "react-router-dom";
import AdminScans from '../components/tables/adminTables/ScansTables';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';

import Delete from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { v4 as uuidv4 } from 'uuid';
import AdminReportsUpload from './AdminReportsUpload';
import FreeDomains from './FreeDomains';
import UnsubDomains from './UnSubDomains';
import Accounts from './AdminComponents/Accounts';
import CompletedScans from './AdminComponents/CompletedScans';
import AddDomains from './AdminComponents/AddDomains';
import AdminCybersec from './AdminComponents/AdminCybersec'
import { getAllAccounts } from "../store/actions/adminScansManagementActions";


const useStyles = makeStyles({
  selectBox: {
    maxWidth: 300,
    width: 300,
  },
});

const styles = {
  textField: {
    width: '100%',
    margin: 10,
    maxWidth: 300,
  }
}

export default function CollapsibleTable() {
  const classes = useStyles();
  const allDomains = useSelector((state) => state.adminScansManagementReducer.allDomains);

  const [key, setKey] = React.useState('');
  const [domain, setDomain] = React.useState("");
  const [user, setUser] = React.useState("");

  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [row, setRow] = React.useState({
    key: '',
    value: ''
  });

  const [loadingAccounts, setLoadingAccounts] = React.useState(true)
  const [data, setData] = React.useState([])

  const [reportType, setReportType] = React.useState({
    accounts: true,
    cybersecurity: false,
    scans: false,
    breachedInformation: false,
    reports: false
  });

  const [cards, setCards] = useState([
    { title: 'General', toggleEnabled: false },
    { title: 'Accessibility', toggleEnabled: false },
    { title: 'Performance', toggleEnabled: false },
    { title: 'Web Privacy', toggleEnabled: false },
    { title: 'Seo', toggleEnabled: false },
    { title: 'Cybersec', toggleEnabled: false },
    { title: 'Ai Image Caption', toggleEnabled: false },
    { title: 'Schemas Ai', toggleEnabled: false },
    { title: 'Chat', toggleEnabled: false },
    { title: 'Scrumboard', toggleEnabled: false },
    { title: 'Issue Tracker', toggleEnabled: false },
    { title: 'Technologies', toggleEnabled: false },
    { title: 'Account Setup', toggleEnabled: false },
    { title: 'Setting Domains', toggleEnabled: false },
    { title: 'Setting Profile', toggleEnabled: false },
    { title: 'Setting Billing', toggleEnabled: false },
    { title: 'Setting Payment Method', toggleEnabled: false },
    { title: 'Setting Users', toggleEnabled: false },
    { title: 'Setting Activities', toggleEnabled: false },
    { title: 'Wp Security', toggleEnabled: false },
    { title: 'Plugin', toggleEnabled: false },
    { title: 'Plugin And Theme', toggleEnabled: false },
    { title: 'Plugin Modified Logs', toggleEnabled: false },
    { title: 'Contact Us Page', toggleEnabled: false },
    { title: 'CCPA Requests', toggleEnabled: false },
    // { title: 'Add CCPA Requests', toggleEnabled: false },
    { title: 'Privacy Information', toggleEnabled: false },
  ]);

  const dispatch = useDispatch();
  // const { keyCode } = useParams();

  // React.useEffect(() => {
  //   // if (keyCode) {
  //   // setKey(keyCode);
  //   dispatch(getAllDomains());
  //   // }
  //   // keyCode
  // }, [])

  useEffect(() => {
    dispatch(getAllDomains());
  }, [dispatch]);

  useEffect(() => {
    if (user && allDomains.length > 0) {
      const filteredDomains = allDomains.filter(d => d.userEmail === user);
      if (filteredDomains.length > 0) {
        setDomain(filteredDomains[0]._id);
        getModules(filteredDomains[0]._id);
      }
    }

    // dispatch(getAllDomains())
    //   .then(() => {
    //     // Check if there are domains available
    //     if (allDomains.length > 0) {
    //       // Set the first domain as the default
    //       setDomain(allDomains[0]._id);
    //       // Ensure to fetch modules for the default domain
    //       getModules(allDomains[0]._id);
    //     }
    //   });
  }, [user, allDomains]);

  // useEffect(() => {
  //   dispatch(getAllDomains())
  //     .then(() => {
  //       // Check if there are domains available
  //       if (allDomains.length > 0) {
  //         console.log(allDomains)
  //         // Set the first domain as the default
  //         setDomain(allDomains[0]._id);
  //         // Ensure to fetch modules for the default domain
  //         getModules(allDomains[0]._id);
  //       }
  //     });
  // }, [dispatch]);

  React.useEffect(() => {
    getAccounts()
  }, [])

  function getAccounts() {
    dispatch(getAllAccounts()).then(data => {
      if (data) {
        setLoadingAccounts(false);
        setData(data.result)
        console.log(data.resul)
      } else {
        setLoadingAccounts(false)

      }
    }).catch(err => {
      setLoadingAccounts(false)
    })
  }

  const deleteKeyValue = (i) => {
    const filtedSources = rows.filter((s, index) => index !== i);
    setRows([...filtedSources])
  }

  function addRow() {
    setRows([...rows, {
      [row.key]: row.value
    }])
    setRow({
      key: '',
      value: ''
    })
  }

  // const handleToggle = (index) => {
  //   const updatedCards = [...cards];
  //   updatedCards[index].toggleEnabled = !updatedCards[index].toggleEnabled;
  //   setCards(updatedCards);
  // };

  // const handleToggle = (index) => {
  //   const updatedCards = [...cards];
  //   updatedCards[index].toggleEnabled = !updatedCards[index].toggleEnabled;
  //   setCards(updatedCards);

  //   // Update the reportType state for cybersec based on the toggled card
  //   setReportType((prevReportType) => ({
  //     ...prevReportType,
  //     cybersec: updatedCards.some((card) => card.title === 'Cyber Security' && card.toggleEnabled),
  //   }));
  // };
  const handleToggle = async (index) => {
    const updatedCards = [...cards];
    updatedCards[index].toggleEnabled = !updatedCards[index].toggleEnabled;
    setCards(updatedCards);

    let moduleName = updatedCards[index].title.toLowerCase().replace(/\s+/g, '');
    const moduleValue = updatedCards[index].toggleEnabled;
    if (moduleName === "webprivacy") {
      moduleName = "webPrivacy"
    }
    if (moduleName === "cybersec") {
      moduleName = "cybersec"
    }
    if(moduleName === "aiimagecaption") {
      moduleName = "aiImageCaption"
    }
    if(moduleName === "schemasai") {
      moduleName = "schemasAi"
    }
    if(moduleName === "issuetracker") {
      moduleName = "issueTracker"
    }
    if(moduleName === "accountsetup") {
      moduleName = "accountSetup"
    }
    if(moduleName === "settingdomains") {
      moduleName = "settingDomains"
    }
    if(moduleName === "settingprofile") {
      moduleName = "settingProfile"
    }
    if(moduleName === "settingbilling") {
      moduleName = "settingBilling"
    }
    if(moduleName === "settingpaymentmethod") {
      moduleName = "settingPaymentMethod"
    }
    if(moduleName === "settingusers") {
      moduleName = "settingUsers"
    }
    if(moduleName === "settingactivities") {
      moduleName = "settingActivities"
    }
    if(moduleName === "wpsecurity") {
      moduleName = "wpSecurity"
    }
    if(moduleName === "pluginandtheme") {
      moduleName = "pluginAndTheme"
    }
    if(moduleName === "pluginmodifiedlogs") {
      moduleName = "pluginModifiedLogs"
    }
    if(moduleName === "contactuspage") {
      moduleName = "contactUsPage"
    }
    if(moduleName === "ccparequests") {
      moduleName = "ccpaRequests"
    }
    // if(moduleName === "addccparequests") {
    //   moduleName = "addCcpaRequests"
    // }
    if(moduleName === "privacyinformation") {
      moduleName = "privacyInformation"
    }

    console.log(domain)
    let data = {
      domainId: domain,
      moduleName,
      moduleValue
    }

    try {
      await dispatch(enableDisableModules(data));
      window.location.reload();
    } catch (error) {
      console.error("Error updating module:", error);
      // Handle error, e.g., show an error message to the user
    }
  };

  function addBreach(e) {
    e.preventDefault();
    const object = Object.assign({}, ...rows);
    dispatch(addBreachedInfo({
      domainId: domain,
      key,
      breachedData: { ...object, uuid: uuidv4() }
    })).then(data => {
      if (data) {
        setRows([]);
        setRow({
          key: '',
          value: ''
        })
      }
    })
  }

  useEffect(() => {
    if (domain) {
      getModules(domain);
    }
  }, [domain]);

  function getModules(selectedDomain) {
    console.log("herererere")
    console.log(selectedDomain)
    dispatch(DomainModules({
      selectedDomain,
    })).then(data => {
      if (data) {
        console.log("Data from getModules:", data.result); // Debugging
        const updatedCards = cards.map(card => {
          const titleKey = card.title.charAt(0).toLowerCase() + card.title.slice(1).replace(/\s+/g, '');

          if (titleKey in data.result) {
            console.log("Updating card:", card.title, "with toggleEnabled:", data.result[titleKey]); // Debugging
            // if (titleKey === 'cybersecurity') titleKey = 'cybersec';
            // if (titleKey in data.result) {
            //   return { ...card, toggleEnabled: data.result[titleKey] };
            // }
            return { ...card, toggleEnabled: data.result[titleKey] };
          }
          return card;
        });
        console.log("Updated cards:", updatedCards); // Debugging
        setCards(updatedCards);


      }
    });
  }

  if (!Auth0Service.isAuthenticated()) {
    return window.location.href = "/authenticate"
  } else {

    return (
      <>
        <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent='flex-end' mb={2} mx={1}>
          <ButtonGroup
            size="small"
            aria-label="Button group to switch between analytics and report"
          >
            <Button
              style={{
                backgroundColor: reportType.accounts
                  ? "#21CA5A"
                  : "#7E7E7E",
                color: "#ffffff",
              }}
              onClick={() => setReportType({ accounts: true })}

            >
              Accounts
            </Button>
            <Button

              style={{
                backgroundColor: reportType.completedScans
                  ? "#21CA5A"
                  : "#7E7E7E",
                color: "#ffffff",
              }}
              onClick={() => setReportType({ completedScans: true })}

            >
              Completed Scans
            </Button>
            <Button

              style={{
                backgroundColor: reportType.scans
                  ? "#21CA5A"
                  : "#7E7E7E",
                color: "#ffffff",
              }}
              onClick={() => setReportType({ scans: true })}

            >
              Other Scans
            </Button>
            <Button

              style={{
                backgroundColor: reportType.breachedInformation
                  ? "#21CA5A"
                  : "#7E7E7E",
                color: "#ffffff",
              }}
              onClick={() => setReportType({ breachedInformation: true })}

            >
              Breached Information
            </Button>

            <Button

              style={{
                backgroundColor: reportType.reports
                  ? "#21CA5A"
                  : "#7E7E7E",
                color: "#ffffff",
              }}
              onClick={() => setReportType({ reports: true })}

            >
              CyberSec Reports
            </Button>
            <Button

              style={{
                backgroundColor: reportType.freeDomains
                  ? "#21CA5A"
                  : "#7E7E7E",
                color: "#ffffff",
              }}
              onClick={() => setReportType({ freeDomains: true })}

            >
              Free Domains
            </Button>
            <Button

              style={{
                backgroundColor: reportType.unsubDomains
                  ? "#21CA5A"
                  : "#7E7E7E",
                color: "#ffffff",
              }}
              onClick={() => setReportType({ unsubDomains: true })}

            >
              Unsubscribed Domains
            </Button>
            <Button

              style={{
                backgroundColor: reportType.addDomains
                  ? "#21CA5A"
                  : "#7E7E7E",
                color: "#ffffff",
              }}
              onClick={() => setReportType({ addDomains: true })}

            >
              Add Domains
            </Button>
            <Button

              style={{
                backgroundColor: reportType.cybersecurity
                  ? "#21CA5A"
                  : "#7E7E7E",
                color: "#ffffff",
              }}
              onClick={() => setReportType({ cybersecurity: true })}

            >
              Modules
            </Button>
          </ButtonGroup>
        </Box>
        {reportType.scans &&
          <AdminScans />
        }
        {reportType.completedScans &&
          <CompletedScans />
        }
        {reportType.accounts &&
          <Accounts />
        }
        {reportType.cybersecurity &&
          <>

            <label htmlFor='select-domain'>Select User:</label>
            <br />
            <Select
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} />
              )}
              variant="outlined"
              labelId="select-domain"
              id="select-domain"

              value={user}
              className={classes.selectBox}
              onChange={(event) => {
                const selectedUser = event.target.value;
                setUser(selectedUser);
                console.log("her slected user" + selectedUser); // Ensure that the correct domain is logged
              }}
            >
              {data.map(domain => {
                return <MenuItem value={domain.email}>{`${domain.name}-${domain.email}`}</MenuItem>
              })}
            </Select>
            <br />
            <br />
            <label htmlFor='select-domain'>Select Domain:</label>
            <br />
            <Select
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} />
              )}
              variant="outlined"
              labelId="select-domain"
              id="select-domain"

              value={domain}
              className={classes.selectBox}
              onChange={(event) => {
                const selectedDomain = event.target.value;
                setDomain(selectedDomain);
                console.log(selectedDomain); // Ensure that the correct domain is logged
                getModules(selectedDomain);
              }}
            >
              {allDomains
                .filter(domain => domain.userEmail === user)
                .map(filteredDomain => (
                  <MenuItem key={filteredDomain._id} value={filteredDomain._id}>{filteredDomain.domain}</MenuItem>
                ))
              }
              {/* {allDomains.map(domain => {
                return <MenuItem value={domain._id}>{domain.domain}</MenuItem>
              })} */}
            </Select>

            <Grid container spacing={2} style={{ marginTop: 10 }}>
              {cards.map((card, index) => (
                <Grid key={index} item xs={12} sm={4}>
                  {/* <AdminCybersec
                    title={card.title}
                    initialToggleState={card.toggleEnabled}
                    onToggle={() => handleToggle(index)}
                  /> */}
                  <AdminCybersec
                    title={card.title}
                    initialToggleState={card.toggleEnabled}
                    onToggle={() => handleToggle(index)} // Pass index to identify the card
                  />
                </Grid>
              ))}
            </Grid>
          </>
        }
        {
          reportType.breachedInformation &&
          <Box component="form" onSubmit={addBreach}>
            <label htmlFor='select-domain'>Select Domain:</label>
            <br />
            <Select
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} />
              )}
              variant="outlined"
              labelId="select-domain"
              id="select-domain"

              value={domain}
              className={classes.selectBox}
              onChange={(event) => {
                setDomain(event.target.value)
              }}
            >
              {allDomains.map(domain => {
                return <MenuItem value={domain._id}>{domain.domain}</MenuItem>
              })}
            </Select>

            <Box my={4}>
              <fieldset style={{ borderRadius: 9, marginBottom: "10px" }}>
                <legend style={{ fontFamily: "Segoe UI", fontSize: "16px", fontWeight: 600 }}> Add Breach Info</legend>
                <Box my={3}>
                  {
                    rows.map((r, i) => {
                      return (
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={3}>
                            <TextField id="version" style={{ width: '100%' }} type="text" label="Column Name" variant="outlined" name='key' value={Object.keys(r)} disabled={true} />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <TextField id="version" style={{ width: '100%' }} type="text" label="Value" variant="outlined" name='value' value={Object.values(r)} disabled={true} />
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <IconButton onClick={() => deleteKeyValue(i)} style={{ color: "#e3211f" }}>
                              <Delete />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField id="version" style={{ width: '100%' }} type="text" label="Column Name" variant="outlined" name='key' onChange={(event) => setRow({ ...row, key: event.target.value })} value={row.key} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField id="version" style={{ width: '100%' }} type="text" label="Value" variant="outlined" name='value' onChange={(event) => setRow({ ...row, value: event.target.value })} value={row.value} />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <IconButton onClick={() => addRow()} >
                      <AddCircleOutlineIcon style={{ color: "#0dad0b" }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </fieldset>
            </Box>
            <Button variant="contained" color="primary" type='submit'>add</Button >

          </Box>
        }

        {reportType.reports &&
          <AdminReportsUpload />
        }

        {reportType.freeDomains &&
          <FreeDomains />
        }

        {reportType.unsubDomains &&
          <UnsubDomains />
        }

        {reportType.addDomains &&
          <AddDomains />
        }
      </>
    );
  }
}