import React from "react";
import {
  useStripe,
  CardElement,
  useElements
} from "@stripe/react-stripe-js";
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { addStripeCard } from "../../store/actions/addStripeCardActions";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const CheckoutForm = ({ domainId }) => {

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  let history = useHistory();

  const loading = useSelector(state => state.addStripeCard.loading);

  const handleSubmit = async (event) => {

    event.preventDefault();
    
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);

    const { error, token } = await stripe.createToken(card);

    if (error) {
      toast.error(error.message)
    } else {
      dispatch(addStripeCard(token.id, domainId))
    }
  }

  return (
    <div>
      <Box m={2}>
        <CardElement />
        <Box display="flex" justifyContent="flex-end" alignItems="center" my={1.5} ml={1}>
          <span role="button" style={{ color: "red", cursor: "pointer", marginRight: "10px" }} classes={{ disabled: 'btn-disabled' }} onClick={() => history.push('/billing')}>Cancel</span>   <AddPayment onClick={handleSubmit} disabled={!stripe || loading} shape="round" size={"large"}>{!loading && "Add"}{loading && <CircularProgress style={{ color: "white", marginLeft: "0.5rem" }} size={20} />}</AddPayment>
        </Box>
      </Box>
    </div>
  );
};
export default CheckoutForm;

const AddPayment = withStyles((theme) => ({
  root: {
    borderRadius: 25,
    padding: "8px 33px",
    backgroundColor: 'rgb(31, 58, 147)',
    color: 'white',
    display: "flex",
    alignItems: "center",
    '&:hover': {
      backgroundColor: '#0069d9',
      borderColor: '#0062cc',
      boxShadow: 'none',
    },
    disabled: {
      color: "white"
    }

  },
}))(Button);