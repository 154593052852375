import {
  HELP_AND_SUPPORT_LOADING,
  HELP_AND_SUPPORT
} from '../actions/helpAndSupportActions';

const initialState = {
  loading: false
};

 const helpAndSupportReducer =  (state = initialState, action) => {
  switch (action.type) {
    case HELP_AND_SUPPORT_LOADING:
      return {
        ...state,
        loading: !state.loading
      }
    case HELP_AND_SUPPORT:
    default:
      return state;
  }
};

export default helpAndSupportReducer;