import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import cx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useFadedShadowStyles } from "@mui-treasury/styles/shadow/faded";
import { useGutterBorderedGridStyles } from "@mui-treasury/styles/grid/gutterBordered";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CustomTooltip from "../../components/commons/Tooltip";
import { InfoOutlined } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ReadMore from "../../components/commons/ReadMore";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, CircularProgress } from '@material-ui/core';
import { useDispatch } from "react-redux"
import { getDomainSkipToContent } from "../../store/actions/abracadabraActions";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: 12,
    textAlign: "center",
    height: 280,
    width: 280,
    marginLeft: 10,
  },
  cardContent: {
    paddingBottom: 0,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  heading: {
    fontSize: 20,
    fontWeight: "bold",
    letterSpacing: "0.5px",
    margin: 0,
  },
  subheader: {
    fontSize: 18,
    color: "#000",
    // marginBottom: "0.875em",
    // minHeight: 36,
    fontWeight: 400,
  },
  statLabel: {
    fontSize: 12,
    color: palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  statValue: {
    fontSize: 20,
    fontWeight: "bold",
    margin: 0,
    letterSpacing: "1px",
  },
}));
export default function SkipToContent({ domain }) {
  const [loadingLinks, setLoadingLinks] = React.useState(true);
  const [data, setData] = React.useState();
  const dispatch = useDispatch();

  function getDomains() {
    dispatch(getDomainSkipToContent(domain))
      .then(data => {
        if (data) {
          setLoadingLinks(false);
          setData(data.response.result);
          // console.log(data.result);
        } else {
          setLoadingLinks(false);
        }
      })
      .catch(err => {
        setLoadingLinks(false);
      });
  }

  React.useEffect(() => {
    setLoadingLinks(true);
    getDomains();
  }, []);

  return (
    <>
      {!loadingLinks && data ? (
        <TableContainer component={Paper}>
          <Box p={2}>
            <Box mb={5}>
              <Grid container spacing={2}>
                {/* Wrap your cards in a Grid container */}
                <Grid item xs={3}>
                  <CatValueCard
                    values={{
                      name: 'Skip To Content Present',
                      value: data.skipToContentPresent,
                      description: 'This indicates whether the copyright year is present',
                    }}
                    title="Skip To Content Present"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </TableContainer>
      ) : null}
    </>
  );
}

function CatValueCard({ values, title }) {
  //This function put right description
  const descriptionSorter = () => {
    if (title === "Skip To Content Present") {
      if (values.value) {
        return "Present in website";
      } else {
        return "Not present in website";
      }
    } else if (title === "Policies") {
      if (values.name === "Privacy Policy") {
        if (values.value) {
          return "Present in cookie dialog";
        } else {
          return "Not present in cookie dialog";
        }
      } else {
        if (values.value) {
          return " Website is compliant with this policy";
        } else {
          return " Website is not compliant with this policy";
        }
      }
    } else {
      if (values.name === "Footer") {
        if (values.value) {
          return "Found in the website";
        } else {
          return "Not found in the website";
        }
      } else if (values.name === "Footer Version") {
        if (values.value) {
          return "Version up-to-date ";
        } else {
          return "Version not up-to-date";
        }
      } else {
        if (values.value) {
          return "Present in the footer";
        } else {
          return "Not present in the footer";
        }
      }
    }
  };
  const styles = useStyles();
  const shadowStyles = useFadedShadowStyles();
  const borderedGridStyles = useGutterBorderedGridStyles({
    borderColor: "rgba(0, 0, 0, 0.08)",
    height: "50%",
  });
  return (
    <Card className={cx(styles.card, shadowStyles.root)}>
      <CardContent className={cx(styles.cardContent)}>
        {/* {values.info && (
            <Box display="flex" justifyContent="flex-end">
              <CustomTooltip title={values.info}>
                {" "}
                <InfoOutlined style={{ width: "0.9em", height: "0.9em" }} />{" "}
              </CustomTooltip>
            </Box>
          )} */}
        <Box display='contents'>
          <h3 className={styles.heading} style={{ flex: "1", height: "40px" }}>
            {values.name}
          </h3>
        </Box>

        <Box display={"flex"}>
          <Box p={2} flex="1" className={borderedGridStyles.item}>
            <p className={styles.statValue}>
              {values.value ? (
                <CheckCircleIcon
                  style={{ color: "#21ca5a", width: 70, height: 70 }}
                />
              ) : (
                <CancelIcon
                  style={{ color: "#E2211E", width: 70, height: 70 }}
                />
              )}
            </p>
          </Box>
        </Box>
        <Box className={styles.subheader}>
          <ReadMore lines={2}>{descriptionSorter()}</ReadMore>
          {/* {values.value ? (
              <p>{values.description}</p>
            ) : (
              // <ReadMore lines={2}>Not present in the cookie dialog</ReadMore>
              <p>{values.description}</p>
            )} */}
        </Box>
      </CardContent>
    </Card>
  );
}
