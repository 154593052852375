import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

function CardWithToggle({ title, initialToggleState, onToggle }) {
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState(initialToggleState);

  // const handleToggle = () => {
  //   setIsChecked(!isChecked);
  // };

  const handleToggle = () => {
    onToggle();
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>
      </CardContent>
      <CardContent>
        <Switch
          checked={initialToggleState}
          onChange={handleToggle}
          color="primary"
        />
      </CardContent>
    </Card>
  );
}

export default CardWithToggle;
