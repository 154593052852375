import React, { useState, useEffect } from "react";
import TableContainer from '@material-ui/core/TableContainer';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useFadedShadowStyles } from "@mui-treasury/styles/shadow/faded";
import { useGutterBorderedGridStyles } from "@mui-treasury/styles/grid/gutterBordered";
import Paper from '@material-ui/core/Paper';
import CustomCardContainer from "../../components/commons/CustomCardContainer";
import CustomCardContainerHead from "../../components/commons/CustomCardContainerHead";
import { useDispatch } from "react-redux"
import Switch from '@material-ui/core/Switch';
import CircularProgress from "@material-ui/core/CircularProgress";
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import { updatePages, getCrawlerStatus, getPrivacyPolicy, getAccessibilityStatement, getDomainCopyrightYear, getDomainSkipToContent, getDomainPrivacyPage, createSkiptoContent, createHighContrast, createCopyrightYear, createPages, createWpLogin, checkHighContrastInfo, checkSkipToContentInfo, getPluginStatus, checkSkipToContentInfoWp, checkHighContrastInfoWp, createHighContrastWp, createSkiptoContentWp, getAccessibilityStatementWp, getPluginStatusWp, createPagesWp, updatePagesWp } from "../../store/actions/abracadabraActions";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { faClosedCaptioning } from "@fortawesome/free-regular-svg-icons";
import Loader from '../../components/commons/Loader';

const useStyles = makeStyles(({ palette }) => ({
    card: {
        borderRadius: 12,
        textAlign: "center",
        marginLeft: 10,
    },
    cardContent: {
        paddingBottom: 0,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
    },
    heading: {
        fontSize: 20,
        fontWeight: "bold",
        letterSpacing: "0.5px",
        margin: 0,
    },
    subheader: {
        fontSize: 18,
        color: "#000",
        // marginBottom: "0.875em",
        // minHeight: 36,
        fontWeight: 400,
    },
    statLabel: {
        fontSize: 12,
        color: palette.grey[500],
        fontWeight: 500,
        fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        margin: 0,
    },
    statValue: {
        fontSize: 20,
        fontWeight: "bold",
        margin: 0,
        letterSpacing: "1px",
    },
    button: {
        marginLeft: 10,
        marginTop: 30 // Adjust the margin as needed
    },
    loadingPrimary: { color: "red" }, // Change color here
    loadingSecondary: { color: "green" },
    labelStyles: {
        fontSize: '16px', // Adjust the font size as needed
    }

}));
export default function PrivacyPage({ domain, selectedScanId }) {
    const [data, setData] = React.useState();
    const [skipToContentData, setSkipToContentData] = React.useState();
    const [privacyData, setPrivacyData] = React.useState();
    const [accessibilityStatementData, setAccessibilityStatementData] = React.useState();
    const [loadingLinks, setLoadingLinks] = React.useState(true);
    const [skipToContentEnabled, setSkipToContentEnabled] = useState(false);
    const [skipToContent, setSkipToContent] = useState("enable");
    const [highContrast, setHighContrast] = useState("enable");
    const [highContrastEnabled, setHighContrastEnabled] = useState(false);
    const [copyrightYearEnabled, setCopyrightYearEnabled] = useState(false);
    const [copyrightYear, setCopyrightYear] = useState("enable");
    const [loading, setLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(null);
    const [accessibilityhtmlContent, setAccessibilityHtmlContent] = useState('');
    const [privacyhtmlContent, setPrivacyHtmlContent] = useState('');
    const [wpLoginValue, setWpLoginValue] = useState('/wp-login.php');
    const [wpAdminValue, setWpAdminValue] = useState('/wp-admin');
    const [accessibilityPageId, setAccessibilityPageId] = useState(null);
    const [consentChecked, setConsentChecked] = useState(false);
    const [consentCheckedPrivacy, setConsentCheckedPrivacy] = useState(false);
    const [pluginInstalled, setPluginInstalled] = useState(true);
    const [privacyPageId, setPrivacyPageId] = useState(null);
    const [loadingcheck, setLoadingCheck] = useState(true);
    const [scanStatus, setScanStatus] = useState('');
    const [showPluginInfo, setShowPluginInfo] = useState(false);





    const dispatch = useDispatch();

    // Simulating a process with setTimeout
    useEffect(() => {
        // Simulate a process taking 3 seconds to complete
        const process = setTimeout(() => {
            // Once the process is completed
            setLoadingCheck(false); // Hide the loader
            // Additional actions or state changes upon success can go here
        }, 4000);

        // Clear the timeout if component unmounts or if process succeeds before 3 seconds
        return () => clearTimeout(process);
    }, []);

    React.useEffect(() => {
        getPluginStatus2();
    }, []);

    // React.useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         getCrawlStatus();
    //     }, 5000); // 5 seconds in milliseconds

    //     // Clear interval on component unmount
    //     return () => clearInterval(intervalId);
    // }, [showPluginInfo]);



    const handleSkipToContentToggle = () => {
        // Call your API or perform any necessary action here with the new value
        setSkipToContentEnabled(!skipToContentEnabled);
        setSkipToContent((prevSkipToContent) => {
            const newSkipToContent = prevSkipToContent === "enable" ? "disable" : "enable";
            // console.log(newSkipToContent);
            return newSkipToContent;
        });
    };
    // console.log(`Skip To Content: ${skipToContent}`)
    const handleHighContrastToggle = () => {
        // Call your API or perform any necessary action here with the new value
        setHighContrastEnabled(!highContrastEnabled);
        setHighContrast((prevHighContrast) => {
            const newHighContrast = prevHighContrast === "enable" ? "disable" : "enable";
            // console.log(newSkipToContent);
            return newHighContrast;
        });
    };
    // console.log(`High Contrst: ${highContrast}`)

    const handleCopyrightYearToggle = () => {
        // Call your API or perform any necessary action here with the new value
        setCopyrightYearEnabled(!copyrightYearEnabled);
        setCopyrightYear((prevCopyright) => {
            const newCopyright = prevCopyright === "enable" ? "disable" : "enable";
            // console.log(newSkipToContent);
            return newCopyright;
        });
    };
    // console.log(`Copyright: ${copyrightYear}`)

    async function getPluginStatus2() {
        try {
            const response = await dispatch(getPluginStatusWp(selectedScanId));
            console.log(response.response);
            if (response && response.response === 200) {
                setPluginInstalled(true);
                setLoadingLinks(false);
                // console.log(response.response.result);
            } else {
                setPluginInstalled(false);
                setLoadingLinks(false);
                // toast.error("Failed to retrieve contact details.");
            }
        } catch (error) {
            setPluginInstalled(false);
            setLoadingLinks(false);
        }
    }

    async function getCrawlStatus() {
        try {
            const response = await dispatch(getCrawlerStatus(domain));
            console.log(response.response.result);
            if (response && response.response.result === "completed") {
                setLoadingLinks(false);
                setShowPluginInfo(true);
                setScanStatus(response.response.result);
                // console.log(response.response.result);
            } else {
                setShowPluginInfo(true);
                setLoadingLinks(false);
                setScanStatus(response.response.result);
                // toast.error("Failed to retrieve contact details.");
            }
        } catch (error) {
            setLoadingLinks(false);
            setShowPluginInfo(false);
            // toast.error("No task found or still running");
        }
    }


    function handSkipToContent(action) {
        handleSkipToContentToggle()
        dispatch(createSkiptoContentWp(selectedScanId, action))
            .then(data => {
                if (data) {
                    setLoadingButton(null)
                    // console.log(data.response.response.message)
                    toast.success(data.message);
                    // setData(data.result);
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }

    function checkSkipToContent(selectedScanId) {
        handleSkipToContentToggle()
        dispatch(checkSkipToContentInfoWp(selectedScanId))
            .then(data => {
                if (data) {
                    //check if skipToContentEnabled parameter in data.result
                    if (data.result.skipToContentEnabled) {
                        // console.log(data.response.result.skipToContentEnabled)
                        if (data.result.skipToContentEnabled === "enable") {
                            setSkipToContentEnabled(true)
                        } else {
                            setSkipToContentEnabled(false)
                        }
                    } else {
                        setSkipToContentEnabled(false)
                    }
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }

    function checkHighContrast(selectedScanId) {
        handleHighContrastToggle()
        dispatch(checkHighContrastInfoWp(selectedScanId))
            .then(data => {
                if (data) {
                    // console.log(data.response.result.highContrastEnable)
                    if (data.result.highContrastEnable === "enable") {
                        setHighContrastEnabled(true)
                    } else {
                        setHighContrastEnabled(false)
                    }
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }



    function handleHighContrast(action) {
        handleHighContrastToggle()
        dispatch(createHighContrastWp(selectedScanId, action))
            .then(data => {
                if (data) {
                    setLoadingButton(null)
                    // console.log(data.response.response.message)
                    toast.success(data.message);
                    // setData(data.result);
                } else {
                    setLoadingButton(null)
                }
            })
            .catch(err => {
                setLoadingButton(null)
            });
    }

    // function handleCopyrightYear(action) {
    //     handleCopyrightYearToggle()
    //     dispatch(createCopyrightYear(domain, action))
    //         .then(data => {
    //             if (data) {
    //                 setLoadingButton(null)
    //                 // setData(data.result);
    //             } else {
    //                 setLoadingButton(null)
    //             }
    //         })
    //         .catch(err => {
    //             setLoadingButton(null)
    //         });
    // }

    function getDomains() {
        // dispatch(getDomainCopyrightYear(domain))
        //     .then(data => {
        //         if (data) {
        //             setData(data.response.result);
        //         } else {
        //             setLoadingLinks(false);
        //         }
        //     })
        //     .catch(err => {
        //         setLoadingLinks(false);
        //     });

        // dispatch(getDomainSkipToContent(domain))
        //     .then(data => {
        //         if (data) {
        //             if (data.response.reponse.result.skipToContentPresent === "enable") {
        //                 setSkipToContentData(true)
        //             } else {
        //                 setSkipToContent(false);
        //             }
        //             // setSkipToContentData(data.response.result.skipToContentPresent);
        //             // console.log();

        //             console.log(skipToContentData)
        //         } else {
        //             setLoadingLinks(false);
        //         }
        //     })
        //     .catch(err => {
        //         setLoadingLinks(false);
        //     });

        // dispatch(getDomainPrivacyPage(domain))
        //     .then(data => {
        //         if (data) {
        //             setPrivacyData(data.response.result);
        //         } else {
        //             setLoadingLinks(false);
        //         }
        //     })
        //     .catch(err => {
        //         setLoadingLinks(false);
        //     });
        dispatch(getAccessibilityStatementWp(selectedScanId))
            .then(data => {
                if (data) {
                    if (data.result.accessibilityStatement.page_id) {
                        setAccessibilityHtmlContent(data.result.accessibilityStatement.data);
                        setAccessibilityPageId(data.result.accessibilityStatement.page_id)
                    }
                    if (data.result.privacyPolicy.page_id) {
                        setPrivacyHtmlContent(data.result.privacyPolicy.data);
                        setPrivacyPageId(data.result.privacyPolicy.page_id)
                    }
                } else {
                    setLoadingLinks(false);
                }
            })
            .catch(err => {
                setLoadingLinks(false);
            });

        // dispatch(getPrivacyPolicy(domain))
        //     .then(data => {
        //         if (data) {
        //             if (data.response.result.privacyPolicy.page_id) {
        //                 setPrivacyHtmlContent(data.response.result.privacyPolicy.data);
        //                 setPrivacyPageId(data.response.result.privacyPolicy.page_id)
        //             }

        //         } else {
        //             setLoadingLinks(false);
        //         }
        //     })
        //     .catch(err => {
        //         setLoadingLinks(false);
        //     });
    }

    React.useEffect(() => {
        getDomains();
        checkSkipToContent(selectedScanId);
        checkHighContrast(selectedScanId);
    }, [selectedScanId]);


    useEffect(() => {
        // Load HTML from a file (replace 'your-html-file.html' with the actual file path)
        fetch('/accessibility-statement.html')
            .then((response) => response.text())
            .then((html) => {
                const bodyContent = html.match(/<body[^>]*>([\s\S]*)<\/body>/i)[1];
                setAccessibilityHtmlContent(bodyContent);
            })
            .catch((error) => {
                console.error('Error loading HTML:', error);
            });
    }, []);

    useEffect(() => {
        // Load HTML from a file (replace 'your-html-file.html' with the actual file path)
        fetch('/privacy-page.html')
            .then((response) => response.text())
            .then((html) => {
                const bodyContent = html.match(/<body[^>]*>([\s\S]*)<\/body>/i)[1];
                setPrivacyHtmlContent(bodyContent);
            })
            .catch((error) => {
                console.error('Error loading HTML:', error);
            });
    }, []);

    const handleAccessibilityHtmlEditorChange = (content) => {
        setAccessibilityHtmlContent(content);
        // console.log(accessibilityhtmlContent);
    };

    const handlePrivacyHtmlEditorChange = (content) => {
        setPrivacyHtmlContent(content);
        // console.log(privacyhtmlContent);
    };

    const handleWpLoginChange = (event) => {
        setWpLoginValue(event.target.value);
    };

    const handleWpAdminChange = (event) => {
        setWpAdminValue(event.target.value);
    };

    const handleButtonClick = (buttonLabel) => {
        setLoadingButton(buttonLabel);
        console.log(loadingButton)
        let currentType;
        if (buttonLabel === "Create Accessibility Statement") {
            if (!consentChecked) {
                toast.warning("Please acknowledge that your lawyers have agreed with the accessibility statement.")
            } else {
                currentType = "accessibility";
                dispatch(createPagesWp(selectedScanId, currentType, accessibilityhtmlContent))
                    .then(data => {
                        if (data) {
                            setLoadingButton(null)
                            // getDomains();
                            toast.success(data.response.status)
                            // setData(data.result);
                        } else {
                            setLoadingButton(null)
                        }
                    })
                    .catch(err => {
                        setLoadingButton(null)
                        toast.error("Page already exist")
                    });
            }

        } else if (buttonLabel === "Update Accessibility Statement") {
            if (!consentChecked) {
                toast.warning("Please acknowledge that your lawyers have agreed with the accessibility statement.")
            } else {
                currentType = "accessibility"
                dispatch(updatePagesWp(selectedScanId, currentType, accessibilityhtmlContent, accessibilityPageId))
                    .then(data => {
                        if (data) {
                            // getDomains();
                            setLoadingButton(null)
                            toast.success(data.message)
                            // setData(data.result);
                        } else {
                            setLoadingButton(null)
                        }
                    })
                    .catch(err => {
                        setLoadingButton(null)
                    });
            }

        } else if (buttonLabel === "Update Privacy Policy") {
            if (!consentCheckedPrivacy) {
                toast.warning("Please acknowledge that your lawyers have agreed with the privacy policy.")
            } else {
                currentType = "privacy"
                dispatch(updatePagesWp(selectedScanId, currentType, privacyhtmlContent, privacyPageId))
                    .then(data => {
                        if (data) {
                            // getDomains()
                            setLoadingButton(null);
                            toast.success(data.response.status)
                            // setData(data.result);
                        } else {
                            setLoadingButton(null)
                        }
                    })
                    .catch(err => {
                        setLoadingButton(null)
                    });
            }

        } else if (buttonLabel === "Create Privacy Policy") {
            if (!consentCheckedPrivacy) {
                toast.warning("Please acknowledge that your lawyers have agreed with the privacy policy.")
            } else {
                currentType = "privacy"
                dispatch(createPagesWp(selectedScanId, currentType, privacyhtmlContent))
                    .then(data => {
                        if (data) {
                            setLoadingButton(null)
                            toast.success(data.response.status)
                            // setData(data.result);
                        } else {
                            setLoadingButton(null)
                        }
                    })
                    .catch(err => {
                        setLoadingButton(null)
                        toast.error("Page already exist")
                    });
            }

        } else if (buttonLabel === "Update") {
            let updateAction = "update"
            dispatch(createWpLogin(domain, updateAction, wpLoginValue, wpAdminValue))
                .then(data => {
                    if (data) {
                        setLoadingButton(null)
                        // setData(data.result);
                    } else {
                        setLoadingButton(null)
                    }
                })
                .catch(err => {
                    setLoadingButton(null)
                });

        } else if (buttonLabel === "Set Default") {
            let updateAction = "set-default"
            let wploginValue = "set-default"
            let wpAdminvalue = "set-default"
            setWpLoginValue("set-default")
            setWpAdminValue("set-default")
            dispatch(createWpLogin(domain, updateAction, wploginValue, wpAdminvalue))
                .then(data => {
                    if (data) {
                        setLoadingButton(null)
                        // setData(data.result);
                    } else {
                        setLoadingButton(null)
                    }
                })
                .catch(err => {
                    setLoadingButton(null)
                });

        }

        // Perform your asynchronous action here
        // When the action is complete, reset loadingButton state to null.
        setTimeout(() => {
            setLoadingButton(null);
        }, 2000); // Replace with your actual async action
    };

    const styles = useStyles();
    const shadowStyles = useFadedShadowStyles();
    const borderedGridStyles = useGutterBorderedGridStyles({
        borderColor: "rgba(0, 0, 0, 0.08)",
        height: "50%",
    });


    const downloadFileInNewTab = () => {
        // Set the relative path to the file in the public folder
        const fileURL = '/plugins/AllyRight.zip';

        // Open a new tab and start the download
        window.open(fileURL);
    };

    return (
        <>
            {loadingcheck ? (
                <Loader />
            ) : (
                <>
                    {pluginInstalled ? (
                        <>
                            {/* {showPluginInfo && scanStatus === "completed" ? ( */}
                                <>
                                    {/* {!loadingLinks && data ? ( */}
                                    <TableContainer component={Paper}>
                                        <CustomCardContainerHead title={"Plugin Controls"} titleTag="h1">
                                            <Box p={2}>
                                                <CustomCardContainer title={"Accessibility Statement"}>
                                                    <Box mb={2}>
                                                        <div>
                                                            {/* <h2>Accessibility Statement</h2> */}
                                                            <ReactQuill
                                                                value={accessibilityhtmlContent}
                                                                readOnly={false} // Set to true to make it read-only
                                                                onChange={handleAccessibilityHtmlEditorChange}
                                                                style={{ maxHeight: '300px', overflowY: 'auto' }}
                                                            />
                                                            {/* Display the captured HTML content */}
                                                            {/* <div>
    <h3>Captured HTML Content:</h3>
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
</div> */}
                                                        </div>

                                                        <FormControlLabel style={{ marginBottom: "20px", marginTop: "30px" }}
                                                            control={
                                                                <Checkbox
                                                                    checked={consentChecked}
                                                                    onChange={(e) => setConsentChecked(e.target.checked)}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Our lawyers have revised this accessibility statement and agree with it"
                                                        />

                                                        <Grid item xs={12}>
                                                            <Grid container alignItems="center">
                                                                <Grid item xs={3}>
                                                                    <h3>Send to legal department for approval</h3>
                                                                </Grid>

                                                                <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                    >
                                                                        Send
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={3}>
                                                            {/* Skip to Content */}
                                                            <Grid item xs={6} container alignItems="center">
                                                                <LoadingButton
                                                                    label="Create Accessibility Statement"
                                                                    loading={loadingButton === "Create Accessibility Statement"}
                                                                    disabled={!consentChecked}
                                                                    onClick={() => handleButtonClick("Create Accessibility Statement")}
                                                                />
                                                                <LoadingButton
                                                                    label="Update Accessibility Statement"
                                                                    loading={loadingButton === "Update Accessibility Statement"}
                                                                    disabled={!consentChecked}
                                                                    onClick={() => handleButtonClick("Update Accessibility Statement")}
                                                                />
                                                                {privacyData ? (
                                                                    <Grid item xs={8}>
                                                                        {privacyData.accessibilityStatementPresent ? (
                                                                            <h3 style={{ color: 'green' }}><b style={{ color: 'black' }}>Note: </b> Accessibility Statement is already present on the website</h3>

                                                                        ) : <h3 style={{ color: 'red' }}><b style={{ color: 'black' }}>Note: </b>Accessibility Statement is not present on the website</h3>}
                                                                    </Grid>
                                                                ) : null}
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </CustomCardContainer>
                                            </Box>


                                            <Box p={2}>
                                                <CustomCardContainer title={"Privacy Policy"}>
                                                    <Box mb={2}>
                                                        <div>
                                                            {/* <h2>Accessibility Statement</h2> */}
                                                            <ReactQuill
                                                                value={privacyhtmlContent}
                                                                readOnly={false} // Set to true to make it read-only
                                                                onChange={handlePrivacyHtmlEditorChange}
                                                                style={{ maxHeight: '300px', overflowY: 'auto' }}
                                                            />
                                                            {/* Display the captured HTML content */}
                                                            {/* <div>
    <h3>Captured HTML Content:</h3>
    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
</div> */}
                                                        </div>

                                                        <FormControlLabel style={{ marginBottom: "20px", marginTop: "30px" }}
                                                            control={
                                                                <Checkbox
                                                                    checked={consentCheckedPrivacy}
                                                                    onChange={(e) => setConsentCheckedPrivacy(e.target.checked)}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Our lawyers have revised this privacy policy and agree with it."
                                                        />
                                                        <Grid item xs={12}>
                                                            <Grid container alignItems="center">
                                                                <Grid item xs={3}>
                                                                    <h3>Send to legal department for approval</h3>
                                                                </Grid>

                                                                <Grid item xs={1}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                    >
                                                                        Send
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={3}>
                                                            {/* Skip to Content */}
                                                            <Grid item xs={6} container alignItems="center">
                                                                <LoadingButton
                                                                    label="Create Privacy Policy"
                                                                    loading={loadingButton === "Create Privacy Policy"}
                                                                    disabled={!consentCheckedPrivacy}
                                                                    onClick={() => handleButtonClick("Create Privacy Policy")}
                                                                />
                                                                <LoadingButton
                                                                    label="Update Privacy Policy"
                                                                    loading={loadingButton === "Update Privacy Policy"}
                                                                    disabled={!consentCheckedPrivacy}
                                                                    onClick={() => handleButtonClick("Update Privacy Policy")}
                                                                />
                                                                {privacyData ? (
                                                                    <Grid item xs={8}>
                                                                        {privacyData.privacyPagePresent ? (
                                                                            <h3 style={{ color: 'green' }}><b style={{ color: 'black' }}>Note: </b> Privacy Policy is already present on the website</h3>

                                                                        ) : <h3 style={{ color: 'red' }}><b style={{ color: 'black' }}>Note: </b>Privacy Policy is not present on the website</h3>}
                                                                    </Grid>
                                                                ) : null}
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </CustomCardContainer>
                                            </Box>

                                            {/* <Box p={2}>
<CustomCardContainer title={"Update Wordpress Admin URL"}>
<Box mb={2}>
<Grid container spacing={1}>
    <Grid item xs={7}>
        <TextField
            label="wp-login.php"
            variant="outlined"
            fullWidth
            value={wpLoginValue}
            onChange={handleWpLoginChange}
            InputLabelProps={{
                shrink: true,
            }}
            style={{ marginTop: '10px', marginBottom: '10px' }}
        />
    </Grid>
    <Grid item xs={7}>
        <TextField
            label="wp-admin"
            variant="outlined"
            fullWidth
            value={wpAdminValue}
            onChange={handleWpAdminChange}
            InputLabelProps={{
                shrink: true,
                className: 'custom-label',
                style: useStyles.labelStyles,
            }}
            style={{ marginTop: '10px', marginBottom: '10px' }}
        />
    </Grid>
    {/* Skip to Content */}
                                            {/* </Box> */}


                                            <Box p={2}>
                                                <CustomCardContainer title={"Additional Options"}>

                                                    <Box mb={2}>
                                                        <Grid container spacing={3}>
                                                            {/* High Contrast */}
                                                            <Grid item xs={12}>
                                                                <Grid container alignItems="center">
                                                                    <Grid item xs={3}>
                                                                        <h3>High Contrast</h3>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <FormControlLabel
                                                                            control={<Switch color="primary" checked={highContrastEnabled} onChange={() => {
                                                                                // Call handleHighContrast with the appropriate action
                                                                                const action = highContrastEnabled ? 'disable' : 'enable';
                                                                                handleHighContrast(action);

                                                                                // Toggle the highContrastEnabled state
                                                                                setHighContrastEnabled(!highContrastEnabled);
                                                                            }}
                                                                            />
                                                                            }
                                                                            label={highContrastEnabled ? "enabled" : "disabled"}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => handleHighContrast("enable")}
                                                                            disabled={highContrastEnabled}
                                                                        >
                                                                            Enable
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => handleHighContrast("disable")}
                                                                            disabled={!highContrastEnabled}
                                                                        >
                                                                            Disable
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {/* Skip to Content */}
                                                            <Grid item xs={12}>
                                                                <Grid container alignItems="center">
                                                                    <Grid item xs={3}>
                                                                        <h3>Skip to Content</h3>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        <FormControlLabel
                                                                            control={<Switch color="primary" checked={skipToContentEnabled} onChange={() => {
                                                                                // Call handleHighContrast with the appropriate action
                                                                                const action = skipToContentEnabled ? 'disable' : 'enable';
                                                                                handSkipToContent(action);

                                                                                // Toggle the setSkipToContentEnabled state
                                                                                setSkipToContentEnabled(!skipToContentEnabled);
                                                                            }}
                                                                            />
                                                                            }
                                                                            label={skipToContentEnabled ? "enabled" : "disabled"}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={1}>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => handSkipToContent("enable")}
                                                                            disabled={skipToContentEnabled}
                                                                        >
                                                                            Enable
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => handSkipToContent("disable")}
                                                                            disabled={!skipToContentEnabled}
                                                                        >
                                                                            Disable
                                                                        </Button>
                                                                    </Grid>
                                                                    {/* {skipToContentData ? ( */}
                                                                    <Grid item xs={5}>
                                                                        {skipToContentEnabled ? (
                                                                            <h3 style={{ color: 'green' }}><b style={{ color: 'black' }}>Note: </b> Skip To Content is present on the website</h3>

                                                                        ) : <h3 style={{ color: 'red' }}><b style={{ color: 'black' }}>Note: </b>Skip To Content is not present on the website</h3>}
                                                                    </Grid>
                                                                    {/* ) : null} */}
                                                                </Grid>
                                                            </Grid>
                                                            {/* Current Copyright Year */}
                                                            <Grid item xs={12}>
                                                                <Grid container alignItems="center">
                                                                    <Grid item xs={3}>
                                                                        {/* <h3>Current Copyright Year</h3> */}
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        {/* <FormControlLabel
                    control={<Switch color="primary" checked={skipToContentEnabled} onChange={handleSkipToContentToggle} />}
                    label={skipToContentEnabled ? "enable" : "disable"}
                /> */}
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleCopyrightYear("enable")}
                >
                    Enable
                </Button> */}
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleCopyrightYear("disable")}
                >
                    Disable
                </Button> */}
                                                                    </Grid>
                                                                    {data ? (
                                                                        <Grid item xs={5}>
                                                                            {data.copyrightYearPresent ? (
                                                                                data.copyrightCurrentYear ? (
                                                                                    <h3 style={{ color: 'green' }}><b style={{ color: 'black' }}>Note: </b> Copyright Year is already present on the website</h3>
                                                                                ) : (
                                                                                    <h3 style={{ color: 'orange' }}><b style={{ color: 'black' }}>Note: </b>Copyright Year is present but outdated on the website</h3>
                                                                                )
                                                                            ) : <h3 style={{ color: 'red' }}><b style={{ color: 'black' }}>Note: </b>Copyright Year is not present on the website</h3>}
                                                                        </Grid>
                                                                    ) : null}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </CustomCardContainer>
                                            </Box>

                                        </CustomCardContainerHead>
                                    </TableContainer>
                                    {/* ) : null}s */}
                                </>
                            {/* ) :  (scanStatus === "pending" ? (
                                <CustomCardContainerHead title="Plugin Controls" titleTag="h1">
                                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 20, marginBottom: 20 }} >
                                    <Typography variant="h4" style={{ fontSize: '24px' }}>
                                      Scan is running you can view the Plugin Controls after the scan is finished
                                    </Typography>
                                  </div>
                                </CustomCardContainerHead>
                              ) : <Loader />)} */}
                        </>

                    ) : (
                        <CustomCardContainerHead title="Install Plugin" titleTag="h1">
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 20, marginBottom: 20 }} >
                                <Typography variant="h4" style={{ fontSize: '24px' }}>
                                    Plugin not installed please first install plugin on website
                                </Typography>
                            </div>
                            <Button
                                style={{ display: 'block', margin: 'auto', fontSize: '18px', backgroundColor: 'rgb(31, 58, 147)' }}
                                variant="contained"
                                color="primary"
                                onClick={() => getPluginStatus2()}
                            >
                                Recheck
                            </Button>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50, marginBottom: 20 }} >
                                <Typography variant="h4" style={{ fontSize: '24px' }}>
                                    Follow the following steps to install plugin on website:
                                </Typography>
                            </div>
                            <div style={{ marginTop: 20, textAlign: 'center' }}>
                                <Typography variant="body1">
                                    {/* Step 1 */}
                                    1. Download the plugin file from the download button below
                                </Typography>
                                <Typography variant="body1">
                                    {/* Step 2 */}
                                    2. Go to the wordpress dashboard and go to the plugin section
                                </Typography>
                                <Typography variant="body1">
                                    {/* Step 3 */}
                                    3. Click on the button upload plugin
                                </Typography>
                                <Typography variant="body1">
                                    {/* Step 4 */}
                                    4. Select the downloaded file from your download folder
                                </Typography>
                                <Typography variant="body1">
                                    {/* Step 5 */}
                                    5. Click on install plugin
                                </Typography>
                                <Typography variant="body1">
                                    {/* Step 6 */}
                                    6. Click on activate plugin
                                </Typography>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50, marginBottom: 20 }} >
                                <Typography variant="h4" style={{ fontSize: '24px' }}>
                                    Click below button to download plugin
                                </Typography>
                            </div>
                            <Button
                                style={{ display: 'block', margin: 'auto', fontSize: '18px', backgroundColor: 'rgb(31, 58, 147)' }}
                                variant="contained"
                                color="primary"
                                onClick={() => downloadFileInNewTab()}
                            >
                                Download
                            </Button>
                        </CustomCardContainerHead>
                    )}
                </>
            )}
        </>
    );
}

const LoadingButton = ({ label, loading, onClick }) => {
    const styles = useStyles();

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={onClick}
            className={styles.button}
        >
            {loading ? (
                <CircularProgress
                    size={20}
                    className={`${styles.loadingProgress} ${styles.loadingPrimary}`}
                />
            ) : (
                label
            )}
        </Button>
    );
};
