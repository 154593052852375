// import { authRoles } from "./auth";
import HomePage from "./pages/HomePage";
import NotFound from "./pages/NotFound";
import Maintenance from "./pages/Maintenance";
import MyProfile from "./pages/MyProfile";
import React from "react";
// import _ from './@lodash';
import { Redirect } from "react-router-dom";
// import Login from './components/views/Login';
// import Register from './components/views/Register';
import CustomScanPageList from "./pages/CustomScanPageList";
import Financials from "./pages/Financials";
// import Callback from "./pages/Callback";
import PaymentMethodsList from "./pages/PaymentMethodsList";
import AddPayment from "./pages/AddPayment";
import HelpAndSupport from "./pages/HelpAndSupport";
// import ScanPage from './pages/ScanPage';
import Chat from "./pages/Chat";
import ScrumBoard from "./pages/ScrumBoard";
import IssueTracker from "./pages/IssueTracker";
import CodeSummary from "./pages/CodeSummary";
import AccessibilityReport from "./pages/AccessibilityReport";
import SecurityReport from "./pages/SecurityReport";
import SpellCheckReport from "./pages/SpellCheckReport";
import FileTypesReport from "./pages/FileTypesReport";
import PageSpeedReport from "./pages/PageSpeedReport";
import LinkSelectionViolationReport from "./pages/LinkSelectionViolationReport";
import AddIssue from "./pages/AddIssue";
import UserPage from "./pages/UserPage";
import ActivityLogs from "./pages/ActivityLogs";
import Technologies from "./pages/Technologies";
// import CookieChecker from "./pages/CookieChecker";
import CVE from "./pages/cve/MainApp";
import Teams from "./pages/Teams";
import AdminScansManagement from "./pages/AdminScansManagement";


// import Authenticate from "./components/views/Authenticate";

function setRoutes(config, defaultAuth) {
  let routes = [...config.routes];

  routes = routes.map((route) => {
    let auth =
      config.auth || config.auth === null ? config.auth : defaultAuth || null;
    auth = route.auth || route.auth === null ? route.auth : auth;

    return {
      ...route,
      auth,
    };
  });

  return [...routes];
}

function generateRoutesFromConfigs(configs, defaultAuth) {
  let allRoutes = [];
  configs.forEach((config) => {
    allRoutes = [...allRoutes, ...setRoutes(config, defaultAuth)];
  });
  return allRoutes;
}

const DashboardAppConfig = {
  routes: [
    {
      path: "/dashboard",
      component: HomePage,
    },
    {
      path: "/my-profile",
      component: MyProfile,
    },
    {
      path: "/custom-scan-page-list",
      component: CustomScanPageList,
    },
    {
      path: "/billing",
      component: Financials,
    },
    {
      path: "/payment-method-list",
      component: PaymentMethodsList,
    },
    {
      path: "/add-payment",
      component: AddPayment,
    },
    {
      path: "/help-and-support",
      component: HelpAndSupport,
    },
    {
      path: "/scan-page",
      component: CustomScanPageList,
    },
    {
      path: "/chat",
      component: Chat,
    },
    {
      path: "/scrumboard",
      component: ScrumBoard,
    },
    {
      path: "/violation-report/accessibility",
      component: AccessibilityReport,
    },
    {
      path: "/violation-report/security",
      component: SecurityReport,
    },
    {
      path: "/violation-report/spell-check",
      component: SpellCheckReport,
    },
    {
      path: "/violation-report/filetypes",
      component: FileTypesReport,
    },
    {
      path: "/violation-report/pagespeed",
      component: PageSpeedReport,
    },
    {
      path: "/violation-reports/link",
      component: LinkSelectionViolationReport,
    },
    {
      path: "/issue-tracker",
      component: IssueTracker,
    },
    {
      path: "/issue-tracker/add-new-issue",
      component: AddIssue,
    },
    {
      path: "/under-maintenance",
      component: Maintenance,
    },
    {
      path: "/code-summary",
      component: CodeSummary,
    },
    {
      path: "/users",
      component: UserPage,
    },
    {
      path: "/activity-logs",
      component: ActivityLogs,
    },
    {
      path: "/technologies",
      component: Technologies,
    },
    // {
    //   path: "/cookie-checker",

    //   component: CookieChecker,
    // },
    {
      path: "/cve",
      component: CVE,
    },
    {
      path: "/admin/manage-scans",
      component: AdminScansManagement,
    },
    {
      path: "/manage-teams",
      component: Teams,
    },
    {
      path: "/not-found",
      component: NotFound,
    },
    // {
    //   path: '*',
    //   auth: authRoles.user,
    //   component: NotFound
    // },
  ],
};

// const authConfig = {
//   routes: [
//     {
//       path: '/login',
//       auth: authRoles.onlyGuest,
//       component: Login
//     },
//     {
//       path: '/register',
//       auth: authRoles.onlyGuest,
//       component: Register
//     },
//     {
//       path: '/callback',
//       auth: authRoles.onlyGuest,
//       component: Callback
//     },
//   ]
// }

const routeConfigs = [DashboardAppConfig];

const routes = [
  ...generateRoutesFromConfigs(routeConfigs, []),
  // {
  //   path: "/",
  //   exact: true,
  //   auth: authRoles.user,
  //   component: () => <Redirect to={HomePage} />,
  // },
  {
    // auth: authRoles.onlyGuest,
    component: () => <Redirect to={NotFound} />,
  },
  // {
  //   auth: authRoles.onlyGuest,
  //   component: () => <Redirect to={App} />
  // }
];

export default routes;
