const addDomainStyles = (theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    height: 66,
    width: "100%",
    maxWidth: 560,
    borderRadius: "50px",
    boxShadow: '4px 4px 9px rgba(0, 0, 0, 0.15)',
  },
  input: {
    marginLeft: theme.spacing(2),
    fontWeight: 600,
    fontSize: 18,
    flex: 1,
    '& ::placeholder': {
      fontWeight: 600,
      fontSize: 20
    }
  },
  form: {
    padding: 10,
    backgroundColor: "#e8e6e6",
    maxWidth: 1000,
    width: "100%",
    borderRadius: 10,
    margin: "auto",
    marginTop: '3rem'

  },
  checkButton: {
    fontSize: 18,
    lineHeight: 1.4,
    minHeight: 44,
    borderRadius: 30,
    maxWidth: 158,
    width: '100%',
    padding: '10px 16px',
    fontWeight: 'bold',
    backgroundColor: '#E59EFE',
    color: '#951888',
  },
  noCreditCard: {
    color: '#0C9F5F',
    margin: '10px',

  },
  formText: {
    marginTop: 0,
    marginBottom: 10,
    fontWeight: 600,
  },
  advanceOptions: {
    padding: '0px 8px',
    cursor: "pointer",
    userSelect: "none"
  },
  advanceOptoinsText: {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
  },
  fieldsWrapper: {
    // width: '100%'
  },
  sitemapOptionsTextFields: {
    maxWidth: 200,
    width: '100%',
    margin: "5px 10px 10px 0px"
  },
  uploadSitemapWrapper: {
    padding: 12,
    border: '1px dashed black',
    borderRadius: 10,
    width: 'max-content'
  },
  sitemapUrlField: {
    maxWidth: 300,
    width: '100%',
  },
  fieldsetWrapper: {
    borderRadius: 10,
    marginTop: 15
  },
  removeFileIcon: {
    color: "#e3211f", marginLeft: 10, cursor: "pointer"
  },
  mainText: {
    marginBottom: 30
  },
  ts: {
    margin: 0,
    fontWeight: 600,
    fontSize: 15,
    color: '#577CFF'
  },
  ts2: {
    margin: '0px 0px 10px 10px',
    fontWeight: 600,
    fontSize: 20,
    color: '#951888'
  },
  tl: {
    margin: 0,
    lineHeight: 1.3,
    background: 'linear-gradient(180deg, #577CFF 12.94%, #951888 100%)',
    fontSize: 48,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },

});

export default addDomainStyles;
