
import axios from 'axios';
import { toast } from 'react-toastify';

export const CVE_DATABASE = 'CVE_DATABASE';
export const CVE_DATABASE_ID = 'CVE_DATABASE_ID'


function clean(obj) {
  if (obj.hasOwnProperty("impact.baseMetricV2.severity")) {
    if ((obj["impact.baseMetricV2.severity"]["$in"]) && (obj["impact.baseMetricV2.severity"]["$in"]).length < 1) {
      delete obj["impact.baseMetricV2.severity"]
    }
  }
  if (obj.hasOwnProperty("impact.baseMetricV3.severity")) {
    if ((obj["impact.baseMetricV3.severity"]["$in"]) && (obj["impact.baseMetricV3.severity"]["$in"]).length < 1) {
      delete obj["impact.baseMetricV3.severity"]
    }
  }
  if (obj.hasOwnProperty("cve.CVE_data_meta.ID")) {
    if (obj["cve.CVE_data_meta.ID"]['$regex'] && obj["cve.CVE_data_meta.ID"]['$regex'] === '') {
      delete obj["cve.CVE_data_meta.ID"]
    }
  }
  return obj
}



export const getData = (page, values, relevance) => async (dispatch) => {
  let params = null
  if (values) {
    // console.log(values["cve.CVE_data_meta.ID"]['$regex'])

    // console.log("Before :", values)

    values = clean(values);

    // console.log("After :", values)

    params = {
      values: { ...values },
      // relevance
    }

  } else {

    params = null

  }


  try {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      }
    };

    const res = await axios.get(`http://localhost:7000/api/v1/cves?page=${page}`, {
      params,
      ...options
    });

    if (res.data) {
      //  
      dispatch({
        type: CVE_DATABASE,
        payload: res.data
      });
    }

  } catch (err) {

    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.' );
    }
  }

}
/// relevance ///
export const getRelevance = (page, relevance) => async (dispatch) => {
  let params = null
  // console.log("Relevance :", relevance)


  if (relevance) {

    params = {
      relevance: { ...relevance }
    }

  } else {

    params = null

  }


  try {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      }
    };

    const res = await axios.get(`http://localhost:7000/api/v1/cves?page=${page}`, {
      params,
      ...options
    });

    if (res.data) {
      //  
      dispatch({
        type: CVE_DATABASE,
        payload: res.data
      });
    }

  } catch (err) {

     if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.' );
    }
  }

}
/// relevance ///




export const getDataByID = (cve) => async (dispatch) => {

  try {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      }
    };

    const res = await axios.get(`http://localhost:7000/api/v1/cve?cve=${cve} `, {
      ...options
    });

    if (res.data) {
      //  
      dispatch({
        type: CVE_DATABASE_ID,
        payload: res.data
      });
    }

  } catch (err) {

     if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.' );
    }
  }

}