import {
  GET_ARCHIVED_ACCESSIBILITY_ISSUES,
  GET_ARCHIVED_ACCESSIBILITY_ISSUES_LOADING,
  GET_COMPLETED_ACCESSIBILITY_ISSUES,
  GET_COMPLETED_ACCESSIBILITY_ISSUES_LOADING,
  GET_IGNORED_ACCESSIBILITY_ISSUES,
  GET_IGNORED_ACCESSIBILITY_ISSUES_LOADING,
  GET_NEW_ACCESSIBILITY_ISSUES,
  GET_SECTION_508_TABLE
} from '../actions/accessibilityTablesActions';

const initialState = {
  accessibilityArchivedData: [],
  accessibilityArchivedDataLoading: false,

  accessibilityCompletedData: [],
  accessibilityCompletedDataLoading: false,

  accessibilityIgnoredData: [],
  accessibilityIgnoredDataLoading: false,

  newAccessibilityIssues: null,
  newAccessibilityIssuesStatus: "pending",

  section508Table: null,
  section508TableStatus: "pending",
  section508TablePages: null,
  section508TableCount: null,
};

const accessibilityTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARCHIVED_ACCESSIBILITY_ISSUES:
      return {
        ...state,
        accessibilityArchivedData: action.payload.result.issues,
        loading: false
      }
    case GET_ARCHIVED_ACCESSIBILITY_ISSUES_LOADING:
      return {
        ...state,
        accessibilityArchivedDataLoading: true
      }
    case GET_COMPLETED_ACCESSIBILITY_ISSUES:
      return {
        ...state,
        accessibilityCompletedData: action.payload.result.issues,
        loading: false
      }
    case GET_COMPLETED_ACCESSIBILITY_ISSUES_LOADING:
      return {
        ...state,
        accessibilityCompletedDataLoading: true
      }
    case GET_IGNORED_ACCESSIBILITY_ISSUES:
      return {
        ...state,
        accessibilityIgnoredData: action.payload.result.issues,
        loading: false
      }
    case GET_IGNORED_ACCESSIBILITY_ISSUES_LOADING:
      return {
        ...state,
        accessibilityIgnoredDataLoading: true
      }
    case GET_NEW_ACCESSIBILITY_ISSUES:
      return {
        ...state,
        newAccessibilityIssues: action.payload.result.issues,
        newAccessibilityIssuesStatus: action.payload.status
      };
    case GET_SECTION_508_TABLE:
      return {
        ...state,
        section508Table: action.payload.result,
        section508TablePages: action.payload.pages,
        section508TableCount: action.payload.count,
        section508TableStatus: action.payload.status,
      };
    default:
      return state;
  }
};

export default accessibilityTablesReducer;