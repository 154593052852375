import React from 'react';
import CreatableSelect from 'react-select/creatable';
export default function ReactCreateable({ value, setValue }) {
  const handleChange = (
    value,
  ) => {
    setValue({ inputValue: '', value });
  };

  const components = {
    DropdownIndicator: null,
  };

  const handleInputChange = (inputValue) => {
    setValue({ ...value, inputValue: inputValue })
  };
  const handleKeyDown = (event) => {
    if (!event.target.value) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':

        const cValues = value.value.map(v => v.value);
        if (!cValues.includes(event.target.value)) {
          setValue({
            inputValue: '',
            value: [...value.value, { value: event.target.value, label: event.target.value }],
          });
        }
        event.preventDefault();
    }
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'red' : 'blue',
      padding: 20,
    }),
    control: () => ({
      width: '100%',
      minWidth:240,
      maxWidth:500,
      overflowY: 'auto',
      maxHeight: 56,
      background: 'transparent',
      display: 'flex',
      alignItems: 'flex-start',
    }),
    container: () => ({
      height: 56,
      border: '1px solid #00000033',
      margin: 10,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  }
  return (
    <CreatableSelect
      required={true}
      styles={customStyles}
      components={components}
      inputValue={value.inputValue}
      // isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder="Press Enter to add Multiple Categories"
      value={value.value}
    />
  )
}