import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getSpellCheckTable } from '../../../store/actions/spellCheckActions';
import { archiveSpellCheckLinks, completeSpellCheckLinks, ignoreSpellCheckLinks } from '../../../store/actions/spellCheckTablesActions';
import TablesCard from "../../commons/TablesCard";
import DescriptionIcon from '@material-ui/icons/Description';
import Box from '@material-ui/core/Box';
import ReactTable from "../../commons/ReactTable";
import ReactTableWithoutPaging from "../../commons/ReactTableWithoutPaging";
import LaunchIcon from '@material-ui/icons/Launch';
import tablesWrapperStyles from "../../../Styles/jss/tables/tablesWrapperStyles";

const styles = {
  ...tablesWrapperStyles
};

export default function SpellCheckTable({ scanId }) {

  const [tblData, setTblData] = React.useState(null);

  const dispatch = useDispatch();
  const data = useSelector(state => state.spellCheck.spellCheckTable);
  const status = useSelector(state => state.spellCheck.spellCheckTableStatus);
  const loading = useSelector(state => state.spellCheck.spellCheckTableLoading);

  const spellCheckColumns = [
    {
      width: '150px',
      Header: 'Link',
      accessor: 'link',
    },
    {
      width: '200px',
      Header: 'Link Title',
      accessor: 'linkTitle',
    },
    {
      width: '100px',
      Header: 'Offset',
      accessor: 'offset',
    },
    {
      width: '100px',
      Header: 'Type',
      accessor: 'type',
    },
    {
      width: '100px',
      Header: 'Token',
      accessor: 'token',
    },
    {
      width: '150px',
      Header: 'Suggestions',
      accessor: 'suggestions',
    },
  ]

  const suggestionsColumns = [
    {
      width: '80px',
      Header: 'Suggestion',
      accessor: 'suggestion',
    },
    {
      width: '80px',
      Header: 'Score',
      accessor: 'score',
    },

  ]

  React.useEffect(() => {
    if (scanId) {
      dispatch(getSpellCheckTable(scanId));
    } else {
      dispatch(getSpellCheckTable(scanId));

    }
    // eslint-disable-next-line
  }, [scanId])


  React.useEffect(() => {
    if (status === "pending") {
      const interval = setInterval(() => {
        if (scanId) {
          dispatch(getSpellCheckTable(scanId));
        } else {
          dispatch(getSpellCheckTable(scanId));
        }
      }, 15000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [status]);

  React.useEffect(() => {

    function spellCheckParsedData(data) {
      const tblDataSpellCheck = [];

      for (let i = 0; i < data.length; i++) {
        const link = data[i].link;
        const shortLink = new URL(data[i].link);
        const linkTitle = data[i].linkTitle;
        const offset = data[i].offset;
        const type = data[i].type;
        const suggestions = data[i].suggestions;
        const token = data[i].token;
        tblDataSpellCheck.push({
          link: <div style={styles.textCenter} >
            <LaunchIcon style={{ fontSize: '1.2rem' }}
              onClick={() => window.open(`${link}`, '_blank')}
            />
            <span style={styles.wplinks} aria-label={`${link}`} className="primarycolor"
              onClick={() => window.open(
                `/violation-reports/link?${new URLSearchParams({ link: link }).toString()}`
              )}
            >{(shortLink.pathname.length > 40) ? `...${shortLink.pathname.substring(20, 45)}...` : shortLink.pathname}</span>
          </div>,
          linkTitle: <div aria-label={`link title is ${linkTitle}`} >{linkTitle}</div>,
          offset: <div aria-label={`offset is ${linkTitle}`}>{offset} </div>,
          type: <div aria-label={`type is ${linkTitle}`}>{type} </div>,
          suggestions: <div aria-label="suggestions" style={{ width: "100%" }}>
            <ReactTableWithoutPaging tableColumns={suggestionsColumns} data={suggestions} />
          </div>,
          token: token,
          issue: {
            ...data[i]
          }
        });
      }
      return tblDataSpellCheck;
    }
    let dataTbl1 = null;
    if (data) {
      dataTbl1 = spellCheckParsedData(data);
      setTblData(dataTbl1);
    }
    // eslint-disable-next-line
  }, [data]);

  function archivedActions(selectedRowIds, options) {
    const issues = selectedRowIds.map((item) => {
      return item.original.issue
    })
    const readyData = {
      scanId: scanId,
      archiveOptions: {
        ...options
      },
      issues
    }
    dispatch(archiveSpellCheckLinks(readyData, scanId));
  }


  function completeAction(selectedRowIds) {
    const issues = selectedRowIds.map((item) => {
      return item.original.issue
    })
    const readyData = {
      scanId: scanId,
      issues
    }
    dispatch(completeSpellCheckLinks(readyData, scanId));
  }


  function ignoreAction(selectedRowIds) {
    const issues = selectedRowIds.map((item) => {
      return item.original.issue
    })
    const readyData = {
      scanId: scanId,
      issues
    }
    dispatch(ignoreSpellCheckLinks(readyData, scanId));
  }
  return (
    <TablesCard icon={<DescriptionIcon />} title="Spell Checker" status={status}>
      <>
        {tblData && tblData.length > 0 && !loading ?
          <ReactTable tableColumns={spellCheckColumns} data={tblData} selection={true} optionType={true} archivedActions={archivedActions} completeAction={completeAction} ignoreAction={ignoreAction} />
          : <Box display="flex" justifyContent="center" alignItems="center" style={{ minHeight: "200px", maxHeight: "200px", }}><h4 tabIndex="0">Please wait while we load your data</h4></Box>
        }
      </>
    </TablesCard>
  );
}
