import * as Actions from '../actions/accessibilityCardActions';

const initialState = {
  data: null,
  loading: false,
  status: null,

  historyData: null,
  accessibilityHistoryLoading: false,
  accessibilityHistoryStatus: null,


  section508Data: null,
  section508Loading: false,
  section508Status: null,

  section508HistoryData: null,
  section508HistoryLoading: false,
  section508HiStoryStatus: null,
};

const accessibilityCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_ACCESSIBILITY_CARD_DETAILS:
      return {
        ...state,
        data: action.payload.result,
        status: action.payload.status,
        loading: false
      }
    case Actions.LOADING_ACCESSIBILITY:
      return {
        ...state,
        loading: true
      }
    case Actions.GET_ACCESSIBILITY_HISTORY:
      return {
        ...state,
        historyData: action.payload.result,
        accessibilityHistoryStatus: action.payload.status,
        accessibilityHistoryLoading: false
      }
    case Actions.LOADING_ACCESSIBILITY_HISTORY:
      return {
        ...state,
        accessibilityHistoryLoading: true
      }

      case Actions.GET_SECTION_508_CARD_DETAILS:
        return {
          ...state,
          section508Data: action.payload.result,
          section508Status: action.payload.status,
          section508Loading: false
        }
      case Actions.LOADING_SECTION_508:
        return {
          ...state,
          section508Loading: true
        }
      case Actions.GET_SECTION_508_HISTORY:
        return {
          ...state,
          section508HistoryData: action.payload.result,
          section508HiStoryStatus: action.payload.status,
          section508HistoryLoading: false
        }
      case Actions.LOADING_SECTION_508_HISTORY:
        return {
          ...state,
          section508HistoryLoading: true
        }
    default:
      return state;
  }
};

export default accessibilityCardReducer;