const homepageStyles = (theme) => ({
  root: {
    padding: "0px",
    height: "100%",
    paddingTop: "4rem",
    [theme.breakpoints.up("md")]: {
      padding: "0px",
    },
  },
  formControl: {
    width: 220,
    maxWidth: 300,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
    },
  },
  primaryColor: {
    color: "#1F3A93",
  },
  secondaryColor: {
    color: "#96291c",
  },
  marginZero: {
    margin: 0,
  },
  addSiteButton: {
    margin: 0,
    color: "#96291c",
    fontWeight: "600",
  },
  refreshButton: {
    marginTop: "16px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  mainTitle: {
    display: "flex",
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 36,
    marginTop: 23,
    marginBottom: 12,
  },

  root2: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    height: 60,
    width: "100%",
    borderRadius: "25px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  form: {
    padding: 10,
    backgroundColor: "#e8e6e6",
    maxWidth: 1000,
    width: "100%",
    borderRadius: 10,
    margin: "auto",
  },

  formText: {
    marginTop: 0,
    marginBottom: 10,
    fontWeight: 600,
    fontSize: 18,
  },
  scanProgress: {
    width: "100%",
    margin: "auto",
    maxWidth: 850,
    borderRadius: 10,
    background: "#c2cef7",
  },
  root3: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100%",
    margin: "auto",
    maxWidth: 900,
  }
});

export default homepageStyles;
