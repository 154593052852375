import React, { useState } from 'react';
import { Typography, Box, Button, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import componentStyles from '../styles/stepper';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Person';

const useStyles = makeStyles(componentStyles);
const Requestor = ({requstor}) => {
    const classes = useStyles();

    const styles = {
        cardContainer1: {
            padding: '20px'
        },
        cardContainer2: {
            padding: '20px',
            display: 'flex',
            justifyContent: 'start',
            flexDirection: 'column'
        },
        cardContent2: {
            padding: '35px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            border: '1px solid #000'
        },
        cardContent1: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start'
        },
        cardButtonGroup: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '0 35px',
            height: '110px',
            justifyContent: 'space-between'
        },
        IconButton: { padding: '10px', width: '100%', display: 'flex', justifyContent: 'start' },
        checkBoxContent: { marginTop: '20px' }
    };

    const [consumer, setConsumer] = useState(true);
    const [employ, setEmploy] = useState(false);
    const [check, setCheck] = useState(false);

    const handleConsumer = () => {
        setConsumer(true);
        setEmploy(false);
    };
    const handleEmploy = () => {
        setConsumer(false);
        setEmploy(true);
    };
    return (
        <>
            <Box>
                <Box style={{ marginLeft: '20px' }}>
                    <Typography className={classes.formHeading}>2. Select Requestor Type.</Typography>
                </Box>
                <Box className={classes.cardsWrapper}>
                    <Box className={classes.CardContainer} style={styles.cardContainer1}>
                        <Box style={styles.cardContent1}>
                            <Box style={styles.cardButtonGroup}>
                                <Button
                                    variant="outlined"
                                    startIcon={<PersonIcon sx={{ color: consumer ? '#fff' : '#4A5568' }} />}
                                    className={classes.IconButton}
                                    style={{ backgroundColor: consumer ? '#2196f3' : '#fff' }}
                                    onClick={() => {
                                        handleConsumer();
                                    }}
                                >
                                    <Typography className={classes.formHeading} sx={{ color: consumer ? '#fff' : '#4A5568' }}>
                                        Consumer
                                    </Typography>
                                </Button>
                                <Button
                                    variant="outlined"
                                    startIcon={<WorkIcon sx={{ color: employ ? '#fff' : '#4A5568' }} />}
                                    className={classes.IconButton}
                                    style={{ backgroundColor: employ ? '#2196f3' : '#fff' }}
                                    onClick={() => {
                                        handleEmploy();
                                    }}
                                >
                                    <Typography className={classes.formHeading} sx={{ color: employ ? '#fff' : '#4A5568' }}>
                                        Employee or Applicant
                                    </Typography>
                                </Button>
                            </Box>
                            <Box style={styles.checkBoxContent}>
                                <Box>
                                    <Typography className={classes.formHeading}>2b. Are you an authorized agent?</Typography>
                                </Box>
                                <Box style={{ display: 'flex', marginTop: '5px' }}>
                                    <Checkbox onChange={() => setCheck(!check)} />
                                    <Typography variant="text" className={classes.heading3}>
                                        I am an authorized agent submitting on behalf of another individual
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={classes.CardContainer} style={styles.cardContainer2}>
                        <Box style={styles.cardContent2}>
                            {consumer && (
                                <>
                                    <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                        <PersonIcon />
                                        <Typography className={classes.formHeading} style={{ marginLeft: '5px' }}>
                                            Consumer
                                        </Typography>
                                    </Box>
                                    <Box className={classes.textBox}>
                                        Select this option if you've interacted with our websites or provided personal information to our
                                        company.
                                    </Box>
                                </>
                            )}
                            {employ && (
                                <>
                                    <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                                        <WorkIcon />
                                        <Typography className={classes.formHeading} style={{ marginLeft: '5px' }}>
                                            Employee or Applicant
                                        </Typography>
                                    </Box>
                                    <Box className={classes.textBox}>
                                        Select this option if you're submitting a request in the context of employment. For example, as an
                                        employee or job applicant.
                                    </Box>
                                </>
                            )}
                            {check && (
                                <Box style={{ backgroundColor: '#D3D3D3', padding: '10px', marginTop: '10px' }}>
                                    <Box className={classes.textBox}>
                                        You have indicated that you are an authorized agent. Only select this option if you are submitting
                                        on behalf of another individual and are authorized to do so.
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Requestor;
