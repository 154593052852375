import navigationReducer from "./navigationReducer";
import auth from "../../auth/store/reducers";
import dashboard from "./dashboardReducer";
import addStripeCard from "./addStripeCardReducer";
import packageSelection from "./packageSelectionReducer";
import changePackage from "./changePackageReducer";
import downgradePackage from "./downgradePackageReducer";

import notifications from "./notificationReducer";
import contacts from "./contactsReducer";
import customScanList from "./customScanListReducer";
import security from "./securityReducer";
import securityTable from "./securityTableReducer";
import scansActions from "./scansActionsReducer";
import helpAndSupport from "./helpAndSupportReducer";
import performance from "./performanceReducer";
import imagesCard from "./imagesCardReducer";
import videosCard from "./videosCardReducer";
import audioCard from "./audioCardReducer";
import totalFilesElements from "./totalFilesElementsCardReducer";
import numberOfPagesWith from "./numberOfPagesWithReducer";
import accessibilityCard from "./accessibilityCardReducer";
import accessibilityTables from "./accessibilityTablesReducer";
import filesTables from "./filesTablesReducer";
import spellCheckTable from "./spellCheckTableReducer";
import issueTracker from "./issueTrackerReducer";
import spellCheck from "./spellCheckReducer";
import inviteUsers from "./inviteUsersReducer";
import alert from "./alertsReducer";
import onBoardDashboard from "./onBoardDashboardReducer";
import activityLogs from "./activityLogsReducer";
import technologies from "./technologiesCardReducer";
import cveDatabase from "./cveDatabaseReducer";
import adminScansManagementReducer from "./adminScansManagementReducer";
import checklists from "./checklistReducer";
import webPrivacy from "./webPrivacyReducer";
import seoTables from "./seoTablesReducer";
import sheets from "./sheetsReducer";

import miscellaneous from "./miscellaneousReducer";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  auth,
  dashboard,
  notifications,
  navigationReducer,
  packageSelection,
  addStripeCard,
  customScanList,
  alert,
  security,
  securityTable,
  scansActions,
  contacts,
  helpAndSupport,
  performance,
  imagesCard,
  videosCard,
  audioCard,
  totalFilesElements,
  filesTables,
  numberOfPagesWith,
  accessibilityCard,
  accessibilityTables,
  issueTracker,
  spellCheck,
  spellCheckTable,
  inviteUsers,
  onBoardDashboard,
  changePackage,
  downgradePackage,
  activityLogs,
  miscellaneous,
  technologies,
  cveDatabase,
  adminScansManagementReducer,
  checklists,
  webPrivacy,
  seoTables,
  sheets,
});

export default rootReducer;
