import React, { useState, useEffect } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import cx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useFadedShadowStyles } from "@mui-treasury/styles/shadow/faded";
import { useGutterBorderedGridStyles } from "@mui-treasury/styles/grid/gutterBordered";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CustomTooltip from "../../components/commons/Tooltip";
import { InfoOutlined } from "@material-ui/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ReadMore from "../../components/commons/ReadMore";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import CustomCardContainerHead from "../../components/commons/CustomCardContainerHead";
import { useDispatch } from "react-redux"
import { getDomainAbraAnalytics, getPluginStatus, getCrawlerStatus } from "../../store/actions/abracadabraActions";
import { useParams } from "react-router-dom";
import Loader from '../../components/commons/Loader';

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: 12,
    textAlign: "center",
    height: 280,
    width: 350,
  },
  cardContent: {
    paddingBottom: 0,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  heading: {
    fontSize: 20,
    fontWeight: "bold",
    letterSpacing: "0.5px",
    margin: 0,
  },
  subheader: {
    fontSize: 18,
    color: "#000",
    // marginBottom: "0.875em",
    // minHeight: 36,
    fontWeight: 400,
  },
  statLabel: {
    fontSize: 12,
    color: palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  statValue: {
    fontSize: 20,
    fontWeight: "bold",
    margin: 0,
    letterSpacing: "1px",
  },
}));
export default function CopyrightYear({ domain }) {
  const [loadingLinks, setLoadingLinks] = React.useState(true);
  const [pluginInstalled, setPluginInstalled] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = React.useState();
  const [scanStatus, setScanStatus] = useState('');
  const [showAnalytics, setShowAnalytics] = useState(false);
  const dispatch = useDispatch();

  // Simulating a process with setTimeout
  useEffect(() => {
    // Simulate a process taking 3 seconds to complete
    const process = setTimeout(() => {
      // Once the process is completed
      setLoading(false); // Hide the loader
      // Additional actions or state changes upon success can go here
    }, 4000);

    // Clear the timeout if component unmounts or if process succeeds before 3 seconds
    return () => clearTimeout(process);
  }, []);

  function getDomains() {
    dispatch(getDomainAbraAnalytics(domain))
      .then(data => {
        if (data) {
          setLoadingLinks(false);
          setData(data.response.result);
          // console.log(data.response.result);
        } else {
          setLoadingLinks(false);
        }
      })
      .catch(err => {
        setLoadingLinks(false);
      });
  }

  React.useEffect(() => {
    getPluginStatus2();
  }, []);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      getCrawlStatus();
    }, 5000); // 5 seconds in milliseconds

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [showAnalytics]); 


  React.useEffect(() => {
    setLoadingLinks(true);
    getDomains();
  }, []);



  async function getPluginStatus2() {
    try {
      const response = await dispatch(getPluginStatus(domain));
      console.log(response.response.response);
      if (response && response.response.response === 200) {
        setPluginInstalled(true);
        setLoadingLinks(false);
        // console.log(response.response.result);
      } else {
        setPluginInstalled(false);
        setLoadingLinks(false);
        // toast.error("Failed to retrieve contact details.");
      }
    } catch (error) {
      setPluginInstalled(false);
      setLoadingLinks(false);
    }
  }

  async function getCrawlStatus() {
    try {
      const response = await dispatch(getCrawlerStatus(domain));
      console.log(response.response.result);
      if (response && response.response.result === "completed") {
        setLoadingLinks(false);
        setShowAnalytics(true);
        setScanStatus(response.response.result);
        // console.log(response.response.result);
      } else {
        setShowAnalytics(true);
        setLoadingLinks(false);
        setScanStatus(response.response.result);
        // toast.error("Failed to retrieve contact details.");
      }
    } catch (error) {
      setLoadingLinks(false);
      setShowAnalytics(false);
      // toast.error("No task found or still running");
    }
  }

  const downloadFileInNewTab = () => {
    // Set the relative path to the file in the public folder
    const fileURL = '/plugins/AllyRight.zip';

    // Open a new tab and start the download
    window.open(fileURL);
  };


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {pluginInstalled && !loadingLinks ? (
            <>
              {!loadingLinks && data && showAnalytics && scanStatus === "completed" ? (
                <CustomCardContainerHead title={"Analytics"} titleTag="h1">
                  <TableContainer component={Paper}>
                    <Box p={2}>
                      <Box mb={10}>
                        <Grid container spacing={2}>
                          {/* Wrap your cards in a Grid container */}
                          <Grid item xs={3} md={4}>
                            <CatValueCard
                              values={{
                                name: 'Copyright Year',
                                value: data.copyrightYearPresent,
                                description: 'This indicates whether the copyright year is present',
                              }}
                              title="Copyright Year"
                            />
                          </Grid>
                          <Grid item xs={3} md={4}>
                            <CatValueCard
                              values={{
                                name: 'Copyright Current Year',
                                value: data.copyrightCurrentYear,
                                description: 'This indicates whether the copyright current year is present',
                              }}
                              title="Copyright Current Year"
                            />
                          </Grid>
                          <Grid item xs={3} md={4}>
                            <CatValueCard
                              values={{
                                name: 'Accessibility Statement',
                                value: data.accessibilityStatementPresent,
                                description: 'This indicates whether the copyright current year is present',
                              }}
                              title="Accessibility Statement Present"
                            />
                          </Grid>
                          <Grid item xs={3} md={4}>
                            <CatValueCard
                              values={{
                                name: 'Privacy Page',
                                value: data.privacyPagePresent,
                                description: 'This indicates whether the copyright current year is present',
                              }}
                              title="Privacy Page"
                            />
                          </Grid>
                          <Grid item xs={3} md={4}>
                            <CatValueCard
                              values={{
                                name: 'Skip To Content',
                                value: data.skipToContentPresent,
                                description: 'This indicates whether the copyright current year is present',
                              }}
                              title="Skip To Content"
                            />
                          </Grid>
                        </Grid>
                        {/* </Grid> */}
                      </Box>
                    </Box>
                  </TableContainer>
                </CustomCardContainerHead>
              ) : (scanStatus === "pending" ? (
                <CustomCardContainerHead title="Analytics" titleTag="h1">
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 20, marginBottom: 20 }} >
                    <Typography variant="h4" style={{ fontSize: '24px' }}>
                      Scan is running you can view the Analytics after the scan is finished
                    </Typography>
                  </div>
                </CustomCardContainerHead>
              ) : <Loader />)}
            </>
          ) : (
            <CustomCardContainerHead title="Install Plugin" titleTag="h1">
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 20, marginBottom: 20 }} >
                <Typography variant="h4" style={{ fontSize: '24px' }}>
                  Plugin not installed please first install plugin on website
                </Typography>
              </div>
              <Button
                style={{ display: 'block', margin: 'auto', fontSize: '18px', backgroundColor: 'rgb(31, 58, 147)' }}
                variant="contained"
                color="primary"
                onClick={() => getPluginStatus2()}
              >
                Recheck
              </Button>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50, marginBottom: 20 }} >
                <Typography variant="h4" style={{ fontSize: '24px' }}>
                  Follow the following steps to install plugin on website:
                </Typography>
              </div>
              <div style={{ marginTop: 20, textAlign: 'center' }}>
                <Typography variant="body1">
                  {/* Step 1 */}
                  1. Download the plugin file from the download button below
                </Typography>
                <Typography variant="body1">
                  {/* Step 2 */}
                  2. Go to the wordpress dashboard and go to the plugin section
                </Typography>
                <Typography variant="body1">
                  {/* Step 3 */}
                  3. Click on the button upload plugin
                </Typography>
                <Typography variant="body1">
                  {/* Step 4 */}
                  4. Select the downloaded file from your download folder
                </Typography>
                <Typography variant="body1">
                  {/* Step 5 */}
                  5. Click on install plugin
                </Typography>
                <Typography variant="body1">
                  {/* Step 6 */}
                  6. Click on activate plugin
                </Typography>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50, marginBottom: 20 }} >
                <Typography variant="h4" style={{ fontSize: '24px' }}>
                  Click below button to download plugin
                </Typography>
              </div>
              <Button
                style={{ display: 'block', margin: 'auto', fontSize: '18px', backgroundColor: 'rgb(31, 58, 147)' }}
                variant="contained"
                color="primary"
                onClick={() => downloadFileInNewTab()}
              >
                Download
              </Button>
            </CustomCardContainerHead>
          )}
        </>
      )}
    </>
  );
}

function CatValueCard({ values, title }) {
  //This function put right description
  const descriptionSorter = () => {
    if (title === "Copyright Current Year") {
      if (values.value) {
        return "Present in footer";
      } else {
        return "Not present in footer";
      }
    } else if (title === "Policies") {
      if (values.name === "Privacy Policy") {
        if (values.value) {
          return "Present in cookie dialog";
        } else {
          return "Not present in cookie dialog";
        }
      } else {
        if (values.value) {
          return " Website is compliant with this policy";
        } else {
          return " Website is not compliant with this policy";
        }
      }
    } else {
      if (values.name === "Footer") {
        if (values.value) {
          return "Found in the website";
        } else {
          return "Not found in the website";
        }
      } else if (values.name === "Footer Version") {
        if (values.value) {
          return "Version up-to-date ";
        } else {
          return "Version not up-to-date";
        }
      } else {
        if (values.value) {
          return "Present in the footer";
        } else {
          return "Not present in the footer";
        }
      }
    }
  };
  const styles = useStyles();
  const shadowStyles = useFadedShadowStyles();
  const borderedGridStyles = useGutterBorderedGridStyles({
    borderColor: "rgba(0, 0, 0, 0.08)",
    height: "50%",
  });
  return (
    <Card className={cx(styles.card, shadowStyles.root)}>
      <CardContent className={cx(styles.cardContent)}>
        {/* {values.info && (
            <Box display="flex" justifyContent="flex-end">
              <CustomTooltip title={values.info}>
                {" "}
                <InfoOutlined style={{ width: "0.9em", height: "0.9em" }} />{" "}
              </CustomTooltip>
            </Box>
          )} */}
        <Box display='contents'>
          <h3 className={styles.heading} style={{ flex: "1", height: "40px" }}>
            {values.name}
          </h3>
        </Box>

        <Box display={"flex"}>
          <Box p={2} flex="1" className={borderedGridStyles.item}>
            <p className={styles.statValue}>
              {values.value ? (
                <CheckCircleIcon
                  style={{ color: "#21ca5a", width: 70, height: 70 }}
                />
              ) : (
                <CancelIcon
                  style={{ color: "#E2211E", width: 70, height: 70 }}
                />
              )}
            </p>
          </Box>
        </Box>
        <Box className={styles.subheader}>
          <ReadMore lines={2}>{descriptionSorter()}</ReadMore>
          {/* {values.value ? (
              <p>{values.description}</p>
            ) : (
              // <ReadMore lines={2}>Not present in the cookie dialog</ReadMore>
              <p>{values.description}</p>
            )} */}
        </Box>
      </CardContent>
    </Card>
  );
}
