const cveModalStyles = (theme) => ({
  h3Style: {
    paddingBottom: "10px",
    borderBottom: '1px solid #dee2e6!important',
    marginBottom: '.5rem',
    fontWeight: 700,
    lineHeight: 1.2,
    marginTop: 0,
  },
  pstyle: {
    fontSize: '1.05rem',
    fontWeight: 400,
    lineHeight: 1.5,
  },
  refstyle: {
    textDecoration: 'none',
    backgroundColor: 'transparent',
    color: "#00a5b5",
    '&:hover': {
      color: "#005f69",
    }
  },
  spanStyle: {
    color: '#425363',
    fontWeight: 'bolder',
    fontSize: 18,
  },
});

export default cveModalStyles;
