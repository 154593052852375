import React from 'react';
import { makeStyles, withStyles, lighten, } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
// import TablePagination from '@material-ui/core/TablePagination';
import CveModal from './CveModal';
import { useDispatch } from "react-redux";
import clsx from 'clsx';
import Chip from '@material-ui/core/Chip';
import cveTableStyles from '../../Styles/jss/cveTableStyles';
import { getCveExploitsTable, exploitsTableAction } from "../../store/actions/securityTablesActions";
import Pagination from './Pagination';
import Checkbox from '@material-ui/core/Checkbox';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import ArchiveIcon from "@material-ui/icons/Archive";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ArchiveDialog from '../../components/dialogs/ArchiveDialog';
import { toast } from 'react-toastify';

const useStyles = makeStyles(cveTableStyles);

const CustomButton = withStyles((theme) => ({
  root: {
    margin: "0px 8px 8px 0px",
    borderRadius: 20,
  },
  startIcon: {
    marginRight: 9,
  },
}))(Button);

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 3 100%',
  },
}));

export default function CVEExploitTable({ archive, dismiss, data, scanId, setPage, pages, tech, setRecieveUpdatedData }) {

  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const dispatch = useDispatch();
  const isSelected = (code) => {
    return selected.indexOf(code) !== -1;
  };
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialog2, setOpenDialog2] = React.useState(false);


  const [cveData, setCveData] = React.useState('');
  const [getData, setData] = React.useState('');


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function cveClick(cve) {
    handleClickOpen();
    setCveData(cve)
  }

  React.useEffect(() => {
    if (data) {
      setData(data)
    }
  }, [data])

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (tech === "archived") {
      dispatch(getCveExploitsTable(scanId, newPage, tech, true, false)).then(data => {
        if (data) {
          setData(data);
        }
      })
    }
    else if (tech === "dismissed") {
      dispatch(getCveExploitsTable(scanId, newPage, tech, false, true)).then(data => {
        if (data) {
          setData(data);
        }
      })
    } else {
      dispatch(getCveExploitsTable(scanId, newPage, tech, false, false)).then(data => {
        if (data) {
          setData(data);
        }
      })
    }
  };

  const handleClick = (event, code) => {
    const selectedIndex = selected.indexOf(code);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, code);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getData?.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    function archiveExploit() {
      setRecieveUpdatedData(false)
      dispatch(exploitsTableAction(scanId, selected, true, false, "archival-tech-cves")).then(data => {
        if (data) {
          toast.success("Archived Successfully!")
          setSelected([])
          setRecieveUpdatedData(true)
        }
      })
    }

    function dismissExploit() {
      setRecieveUpdatedData(false)

      dispatch(exploitsTableAction(scanId, selected, false, true, "dismissal-tech-cves")).then(data => {
        if (data) {
          toast.success("Dismissed Successfully!")

          setSelected([])
          setRecieveUpdatedData(true)
        }
      })
    }


    function unarchiveExploit() {

      dispatch(exploitsTableAction(scanId, selected, false, false, "archival-tech-cves")).then(data => {
        if (data) {
          toast.success("Unarchived Successfully!")

          setSelected([])

        }
      })
    }

    function undismissExploit() {

      dispatch(exploitsTableAction(scanId, selected, false, false, "dismissal-tech-cves")).then(data => {
        if (data) {
          toast.success("Undismissed Successfully!")

          setSelected([])

        }
      })
    }

    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">

          </Typography>
        )}

        {numSelected > 0 && (
          <>
            {!archive && !dismiss ? <>
              <CustomButton
                variant="contained"
                color="primary"
                startIcon={<ArchiveIcon />}
                onClick={() => setOpenDialog(true)}
              >
                Archive
              </CustomButton>
              <CustomButton
                variant="contained"
                color="primary"
                startIcon={<NotInterestedIcon />}
                onClick={() => setOpenDialog2(true)}
              >
                Dismiss
              </CustomButton>
            </>
              : (!archive && dismiss) ?
                <>
                  <CustomButton
                    variant="contained"
                    color="primary"
                    startIcon={<ArchiveIcon />}

                    onClick={() => undismissExploit()}
                  >Undismiss</CustomButton>
                </> :

                <CustomButton
                  variant="contained"
                  color="primary"
                  startIcon={<ArchiveIcon />}
                  onClick={() => unarchiveExploit()}
                >Unarchive</CustomButton>
            }
          </>
        )}
        <ArchiveDialog open={openDialog} onClose={() => setOpenDialog(false)} archive={archiveExploit} title1={"Archive"} title2={"Unarchive"} />
        <ArchiveDialog open={openDialog2} onClose={() => setOpenDialog2(false)} archive={dismissExploit} title1={"Dismiss"} title2={"Undismissed"} />

      </Toolbar>
    );
  };
  return (
    <>
      <Paper className={classes.root}>
        {getData && getData?.length > 0 ?
          <>
            {selected.length > 0 && <EnhancedTableToolbar numSelected={selected.length} />}
            <TableContainer className={classes.container}>
              <Table aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={selected.length > 0 && selected.length < getData?.length}
                        checked={getData?.length > 0 && selected.length === getData?.length}
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                    <TableCell className={classes.tHead} style={{ width: "145px" }}>
                      Technology
                    </TableCell>
                    <TableCell className={classes.tHead} style={{ width: "145px" }}>
                      Vesion
                    </TableCell>
                    <TableCell className={classes.tHead} style={{ width: "145px" }}>
                      CVE ID
                    </TableCell>
                    <TableCell className={classes.tHead} style={{ width: "600px" }}>
                      Description
                    </TableCell>
                    <TableCell className={classes.tHead} style={{ width: "200px" }}>
                      Severity
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {getData?.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                          />
                        </TableCell>
                        <TableCell>
                          <span className={classes.techTitle}>{row.technology}</span>

                        </TableCell>
                        <TableCell>
                          {row.version ? row.version : "Not Available"}

                        </TableCell>
                        <TableCell
                          role="checkbox" key={row?.cve.cve?.CVE_data_meta?.ID}>
                          <span onClick={() => {
                            cveClick(row.cve)
                          }} className={classes.buttonColor} style={{ cursor: "pointer" }}>
                            {row?.cve.cve?.CVE_data_meta?.ID}
                          </span>
                        </TableCell>
                        <TableCell>
                          {row.cve.cve.description.description_data[0].value}
                        </TableCell>
                        <TableCell>
                          {(row.cve.impact.baseMetricV3) ?
                            <>
                              <Chip
                                className={classes.chip}

                                style={{
                                  color: "#ffffff",
                                  backgroundColor: (row.cve.impact.baseMetricV3.cvssV3.baseSeverity && row.cve.impact.baseMetricV3.cvssV3.baseSeverity === "HIGH") ? "#f87b1e" : (row.cve.impact.baseMetricV3.cvssV3.baseSeverity && row.cve.impact.baseMetricV3.cvssV3.baseSeverity === "MEDIUM") ? "rgb(255 160 18 / 75%)" : (row.cve.impact.baseMetricV3.cvssV3.baseSeverity && row.cve.impact.baseMetricV3.cvssV3.baseSeverity === "LOW") ? "#21CA5A" : (row.cve.impact.baseMetricV3.cvssV3.baseSeverity && row.cve.impact.baseMetricV3.cvssV3.baseSeverity === "CRITICAL") ? "#E2211E" : (row.cve.impact.baseMetricV3.cvssV3.baseSeverity === undefined && row.cve.impact.baseMetricV3.cvssV3.baseSeverity === '') ? "#777779" : "white"
                                }}
                                size="small"
                                label={row.cve.impact.baseMetricV3.cvssV3.baseSeverity}
                              />
                            </>
                            :
                            <>
                              <Chip
                                className={classes.chip}
                                style={{
                                  color: "#ffffff",
                                  backgroundColor: "#777779"
                                }}
                                size="small"
                                label={"NO SCORE"}
                              />
                            </>
                          }
                        </TableCell>

                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Pagination /> */}
            <Pagination
              rowsPerPageOptions={[5, 10, 15]}
              count={pages}
              page={getData ? getData.page : 0}
              handleChangePage={handleChangePage}
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
            />
          </> : <Box width="100%" display="flex" justifyContent="center" alignItems="center" p={2}> <span style={{ fontFamily: "Segoe UI", fontSize: 18 }} >No CVE's Found</span></Box>
        }
      </Paper>

      <CveModal cveData={cveData} open={open} handleClose={handleClose} />
    </>

  );
}
