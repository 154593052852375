const accessibilityAnalyticsStyles = (theme) => ({
  mainTitle: {
    display: "flex",
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 36,
    marginTop: 23,
    marginBottom: 12,
  },
  priorities: {
    fontSize: 14,
  },
  gridPadd: {
    padding: "9px",
  },
  legendTitle: {
    marginLeft: "0.4rem",
  },
  borderRight: {
    borderRight: "1px solid #e3e7eb",
  },
  averageViolations: {
    marginLeft: "0rem",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "1rem",
      justifyContent: "flex-start",
    },
  },
  boxContainer: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: 9,
    display: "flex",
    justifyContent: "space-between",
    // alignItems: "center",
    padding: "15px 10px",
    height: "100%",
  },
  boxload: {
    height: "508px",
    [theme.breakpoints.down("sm")]: {
      height: "415px",
    },
  },
  section508Value: {
    fontSize: 24,
    background: "linear-gradient(180deg, #577CFF 12.94%, #951888 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
});

const accessibilityAnalyticsInlineStyles = {
  cardMain: {
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    height: "100%",
    padding: "12px 10px",
    marginTop: "10px",
  },
  boxContainer2: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: 9,
    display: "flex",
    justifyContent: "space-between",
    // alignItems: "center",
    padding: "14px",
    height: "100%",
  },
  siteStatus: {
    fontFamily: "Segoe UI",
    fontSize: 16,
    textTransform: "capitalize",
    marginBottom: "15px",
  },
  subTitle: {
    fontFamily: "Segoe UI",
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: "0em",
    lineHeight: 2,
  },
  barPercentage: {
    fontFamily: "Segoe UI",
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: "0em",
    color: "#1f3a93",
    marginRight: 4,
  },
  vulPages: {
    fontFamily: "Segoe UI",
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: "0em",
    color: "#1f3a93",
    display: "flex",
    alignItems: "center",
  },
  vulPagesTitle: {
    fontFamily: "Segoe UI",
    fontSize: 20,
    textTransform: "capitalize",
    margin: 0,
    fontWeight: 500,
  },
  avgperPageTitle: {
    fontFamily: "Segoe UI",
    fontSize: 20,
    marginBottom: "15px",
    textAlign: "center",
    margin: 0,
    fontWeight: 500,
    lineHeight: 1.2,
  },
  scoreTitle: {
    fontFamily: "Segoe UI",
    fontSize: 20,
    marginTop: "15px",
    textAlign: "center",
    margin: 0,
    fontWeight: 500,
    lineHeight: 1.2,
  },
  totalViolationsTitle: {
    fontFamily: "Segoe UI",
    fontSize: 20,
    marginBottom: "15px",
    textAlign: "center",
    fontWeight: 500,
    lineHeight: 1.2,
  },
  ViolationsByPriorityTitle: {
    fontFamily: "Segoe UI",
    fontSize: 20,
    marginBottom: "10px",
    textAlign: "center",
    fontWeight: 500,
    lineHeight: 1.2,
  },
  avgperPageValue: {
    fontFamily: "Segoe UI",
    fontSize: 64,
    textAlign: "right",
    color: "#1f3a93",
  },
  avgChangeValue: {
    fontFamily: "Segoe UI",
    fontSize: 14,
  },
  pieCahrtValue: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 21,
    textAlign: "right",
  },
  priorityValue: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 20,
    minWidth: 38,
    color: "#1f3a93",
  },
  boxContainer: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: 9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "14px",
    height: "100%",
  },
  section508: {
    fontSize: 20,
    margin: 0,
    fontWeight: 500,
  },
};

export { accessibilityAnalyticsStyles, accessibilityAnalyticsInlineStyles };
