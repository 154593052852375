import {
  GET_SCANS,
} from '../actions/scanActions';

const initialState = {
  scans: []
};

const scansActionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SCANS:
      return {
        ...state,
        scans: action.payload
      }
    default:
      return state;
  }
};

export default scansActionsReducer;