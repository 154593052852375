import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

import { withStyles } from '@material-ui/core/styles';

import { useHistory } from "react-router-dom";
import Box from '@material-ui/core/Box';
import MuiCard from '@material-ui/core/Card';
import MuiTypography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MuiButton from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { CardContent, Checkbox } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import loaderwave from "../../../assets/loader.svg";

import CardHeader from '@material-ui/core/CardHeader';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { applyCustomPackage, getCustomPackages } from '../../../store/actions/changePackageActions';
import financialStyles from '../../../Styles/jss/financialStyles';



const useStyles = makeStyles(financialStyles);

export default function Financials() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [tab, setTabValue] = React.useState(null);
  const [packageChangeTab, setPackageChangeTab] = React.useState(0);

  const [applyLoading, setApplyLoading] = React.useState(false);

  const domainId = useSelector((state) => state.dashboard.selectedDomainId);

  const selectedDomain = useSelector(state => state.miscellaneous.selectedDomain);
  const selectedScanId = useSelector(state => state.miscellaneous.selectedScanId);
  const [pageRange, setPageRange] = React.useState(null);

  const [newTotalPrice, setNewTotalPrice] = React.useState(null);
  const [resolutions, setResolutions] = React.useState([]);
  const [tenure, setTenure] = React.useState(null);

  const [packages, setPackages] = React.useState(null);
  const [loading, setLoading] = React.useState(false);


  const { selectedPackage } = useSelector(state => state.auth.user.data);

  React.useEffect(() => {
    setLoading(true)
    dispatch(getCustomPackages()).then(data => {
      if (data) {
        setPackages(data.data);
        setLoading(false)
      }
    });
    // eslint-disable-next-line
  }, []);


  React.useEffect(() => {
    if (packages) {
      setPageRange(packages.rangeOfPages.filter(item => item.tenure === packages.supportedTenures[0])[0])
      setResolutions([packages.resolutions.filter(item => item.tenure === packages.supportedTenures[0])[0]]);
      setTenure(packages.supportedTenures[0]);
      setTabValue(packages.supportedTenures[0]);
    }
    // eslint-disable-next-line
  }, [packages, selectedPackage]);

  React.useEffect(() => {
    if (pageRange || resolutions) {
      setNewTotalPrice(parseInt(pageRange?.price) + parseInt(resolutions.reduce((total, item) => parseInt(item.price) + total, 0)));
    }
    // eslint-disable-next-line
  }, [pageRange, resolutions]);

  const packagesTab = (event, newValue) => {
    setTabValue(newValue);
    setPageRange(packages.rangeOfPages.filter(item => item.tenure === newValue)[0]);

    setResolutions([packages.resolutions.filter(item => item.tenure === newValue)[0]]);
    setTenure(newValue);
  };

  const changePackagesPanel = (event, value) => {
    setPackageChangeTab(value)
  }

  const handleNumberOfPages = (pack) => {
    setPageRange(pack);
  }

  const applyPackage = () => {
    setApplyLoading(true);
    dispatch(applyCustomPackage({
      domainId, options: {
        resolutions, rangeOfPages: pageRange, tenure
      }
    }, selectedDomain,
      selectedScanId)).then(data => {
        if (data) {
          setApplyLoading(false);
        } else {
          setApplyLoading(false);
        }
      })
  }

  function handleResolution(resolutionItem) {
    const isChecked = resolutions.find(item => item.resolution === resolutionItem.resolution)
    if (isChecked) {
      setResolutions(resolutions.filter(item => item.resolution !== resolutionItem.resolution))
    } else {
      setResolutions([...resolutions, resolutionItem])

    }

  }

  return (
    <>

      {!loading ?
        <MuiCard >
          <Box p={2}>
            <Box display="flex" justifyContent="center" mb={3}>
              <Box className={classes.root}>

                {packages && tab &&
                  // eslint-disable-next-line
                  <Tabs
                    data-tut="Select_tenure"
                    value={tab}
                    onChange={packagesTab}
                    textColor="primary"
                    centered
                    indicatorColor="transparent"

                  >
                    {packages.supportedTenures.map((tenure, index) => (
                      <CustomTab key={index} label={tenure} value={tenure} />
                    ))}
                  </Tabs>
                  // eslint-disable-next-line
                }
              </Box>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
                {pageRange && tenure && resolutions ?
                  <Box width='100%'>
                    <MuiCard className={classes.packageCard}>

                      <CardHeader
                        title={"Custom Package"}
                        titleTypographyProps={{ align: "center" }}
                        subheaderTypographyProps={{ align: "center" }}
                        className={classes.cardHeader}
                      />
                      <CardContent style={{ paddingBottom: "0px" }}>
                        <div className={classes.cardPricing}>
                          <MuiTypography
                            component="h2"
                            variant="h4"
                            color="textPrimary"
                          >
                            ${newTotalPrice}
                          </MuiTypography>
                          <MuiTypography variant="h6" >
                            /{tenure}
                          </MuiTypography>
                        </div>
                        <ul style={{ listStyle: "none", padding: 0 }}>
                          <MuiTypography component="li" variant="h6" align="center" key={pageRange.pageRange}> Pages ${pageRange.price} </MuiTypography>
                          <MuiTypography component="li" variant="h6" align="center" >
                            Resolutions ${resolutions.reduce((total, item) => parseInt(item.price) + total, 0)}
                          </MuiTypography>
                        </ul>
                      </CardContent>
                    </MuiCard>
                  </Box> :
                  <></>
                }
              </Grid>
              <Grid item md={8}>
                {

                  packages && pageRange && resolutions &&
                  <>
                    <Box p={2} >
                      <Card style={{ height: "100%", boxShadow: "0px 3px 25px #1F3A931A", borderRadius: "2%" }}>
                        <CardContent>
                          <Tabs
                            data-tut="Select_Change"
                            value={packageChangeTab}
                            onChange={changePackagesPanel}
                            indicatorColor="transparent"

                            textColor="primary"
                            centered
                          >
                            <CustomTab label="Pages" value={0} />
                            <CustomTab label="Resolutions" value={1} />
                          </Tabs>
                          <TabPanel value={packageChangeTab} index={0}>
                            <Box my={2}>
                              <FormControl component="fieldset">
                                <RadioGroup aria-label={`pageRange${tenure}`} name={`pageRange${tenure}`}
                                  value={pageRange?.pageRange}
                                  style={{ display: "block" }}>
                                  <Grid container spacing={2}>
                                    {packages?.rangeOfPages.map((pack, index) => {
                                      const isChecked = pageRange.pageRange === pack.pageRange
                                      return (pack.tenure === tenure &&
                                        <Grid item xs={12} md={4} key={index} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                          <FormControlLabel value={pack.pageRange} onChange={() => handleNumberOfPages(pack)} control={<CustomRadio checked={isChecked} />} label={`${pack.pageRange} (+$${pack.price})`} />
                                        </Grid>)
                                    }
                                    )}
                                    <Grid item xs={12} md={12}>
                                      <span style={{ fontSize: "1rem", lineHeight: "1.5" }} >200,000-3,000,000 (Contact Us For Pricing)</span>
                                    </Grid>
                                  </Grid>
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </TabPanel>
                          <TabPanel value={packageChangeTab} index={1}>
                            <Box my={2}>
                              <FormControl component="fieldset">

                                <Grid container spacing={2} >
                                  {packages?.resolutions.map((pack, index) => {
                                    const isChecked = resolutions.find(item => item.resolution === pack.resolution)
                                    return (
                                      pack.tenure === tenure &&
                                      <Grid item xs={12} md={4} key={index} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <FormControlLabel onChange={() => handleResolution(pack)} value={pack.resolution} control={<Checkbox checked={isChecked} />} label={`${pack.resolution} (+$${pack.price})`} />
                                      </Grid>)
                                  }
                                  )}
                                </Grid>

                              </FormControl>
                            </Box>
                          </TabPanel>
                        </CardContent>
                      </Card>
                    </Box>
                  </>
                }
              </Grid>

            </Grid>
          </Box>

          <Box display="flex" justifyContent="center" my={3} alignItems="center" >
            <Box display="flex" alignItems="center" flexWrap="wrap" ><MuiButton data-tut="Apply_Package" style={{ marginRight: "1rem" }} onClick={() => applyPackage()} disabled={applyLoading} variant="contained" color="primary">Apply Package {applyLoading && <CircularProgress style={{ color: "black", marginLeft: "1rem" }} size={20} />}</MuiButton>
            </Box>
          </Box>

        </MuiCard> : <Box display="flex" justifyContent="center" alignItems="center" height="100%"> <img src={loaderwave} alt="loader" /></Box>
      }
    </>
  );
}

function TabPanelMain(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanelMain.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
const CustomTab = withStyles((theme) => ({
  root: {
    opacity: 1,
    color: "#1f3a93",
    borderRadius: 9,
    "&:hover": {
      color: "#1f3a93",
      opacity: 1,
    },
    "&$selected": {
      backgroundColor: "#1f3a93",
      color: "white",
      fontWeight: theme.typography.fontWeightMedium,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
    },
  },
  selected: {
    color: "#FFA412",
  },
}))((props) => <Tab {...props} />);


const CustomRadio = withStyles({
  root: {
    '&$checked': {
      color: "#2a31a3",
    },
  },
  checked: {},
})((props) => <Radio {...props} />);