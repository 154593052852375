import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

import Auth0Service from "../../services/auth0Service/authentikService";

// import { getAllImagesLinks } from "../store/actions/abracadabraActions";
import { useParams } from "react-router-dom";
// import PluginLogs from './AdminComponents/PluginLogs';
// import { makeStyles } from '@mui/styles';

import RequestTable from './RequestsTable.jsx';
// import { useDispatch } from 'react-redux';
import { Box, Button, CardContent, Card, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';



const useStyles = makeStyles({
  selectBox: {
    maxWidth: 300,
    width: 300,
  },
});

const styles = {
  textField: {
    width: '100%',
    margin: 10,
    maxWidth: 300,
  }
}

const componentStyles = (theme) => ({
    centerItem: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    title: {
        fontSize: '16px',
        fontWeight: 'bold'
    },
    paper: {
        width: 'fit-content',
        paddingTop: '15px',
        paddingBottom: '10px',
        margin: ' auto'
    }
});

const useStyles2 = makeStyles(componentStyles);

export default function CollapsibleTable() {
  const [domain, setDomain] = React.useState('');
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  // const { keyCode } = useParams();
  const currentURL = window.location.href;
  // Create a URL object
  const urlObject = new URL(currentURL);
  let domainParam = urlObject.searchParams.get('domain');
  if (domainParam.includes("www")) {
    domainParam = domainParam.split('www.')[1];
  }
  const selectedScanId = useSelector(state => state.miscellaneous.selectedScanId);
  console.log(selectedScanId);
// console.log(domainParam)
  React.useEffect(() => {
    // if (keyCode) {
    //   setKey(keyCode);
      setDomain(domainParam);
      // dispatch(getAllImagesLinks(domainParam));

    // }
  }, [domainParam])

  // if (!Auth0Service.isAuthenticated()) {
  //   // return window.location.href = "/authenticate"
  // } else {

    return (
      <>
                <RequestTable domain={domain} />

      </>
    );
}

// }