import React from "react";
import { Box, Typography, makeStyles, styled } from "@material-ui/core";


import { useParams } from "react-router-dom";
import Modal from "../EditModal/Modal";
import MilestoneForm from "../forms/MilestoneForm";
import Sidebar from "../IssueView/SideList";
import Content from "./Content";
import { useSelector, useDispatch } from "react-redux";
import {
  updateMilestone,
  getMilestone,
} from "../../../store/actions/issueTrackerActions";
import { getDate } from "../../../source/functions";

import LinearProgress from "../../../components/commons/LinearProgress";
import SubLoader from "../../../components/commons/SubLoader";

const getsideList = (milestone) => {
  if (milestone)
    return [
      {
        label: "Status",
        data: (
          <LinearProgress progress={milestone.progress} />
        ),
      },
      {
        label: "Start date",
        // btn: <IconButton style={{ fontSize: "14px" }}>Edit</IconButton>,
        data: (
          <Box>
            <Typography style={{ fontSize: "14px" }}>
              {getDate(milestone.start_date)}
            </Typography>
          </Box>
        ),
      },
      {
        label: "Due date",
        // btn: <IconButton style={{ fontSize: "14px" }}>Edit</IconButton>,
        data: (
          <Box>
            <Typography style={{ fontSize: "14px" }}>
              {getDate(milestone.due_date)}
            </Typography>
          </Box>
        ),
      },
      {
        label: "Issue",
        // btn: <IconButton style={{ fontSize: "14px" }}>New Issue</IconButton>,
        data: (
          <>
            <Box display="flex">
              <Typography style={{ fontSize: "12px", fontWeight: "bold" }}>
                Open: 0
              </Typography>
              <Typography
                style={{
                  marginLeft: "7px",
                  fontSize: "12px",

                }}
              >
                Close: 0
              </Typography>
            </Box>
          </>
        ),
      },
    ];
};

const useStyles = makeStyles((theme) => ({
  page: {
    width: "calc(100% - 300px)",
    padding: "10px",
  },
}));

const ViewMilestone = () => {
  const classes = useStyles();
  const { selectedDomainId } = useSelector((state) => state.dashboard);
  const [open, setOpen] = React.useState(false);
  let { milestoneId } = useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (milestoneId && selectedDomainId) {
      dispatch(getMilestone(selectedDomainId, milestoneId));
    }
  }, [milestoneId, selectedDomainId]);

  const milestone = useSelector((state) => state.issueTracker.milestone);

  return (
    <>
      {milestone ? (
        <>
          <Sidebar list={getsideList(milestone)} />
          <Box className={classes.page}>
            <Content
              title={"hghhuh"}
              open={open}
              setOpen={setOpen}
              milestone={milestone}
            />
          </Box>
        </>
      ) : (
        <SubLoader />
      )}
      <Modal open={open} setOpen={setOpen} title={"Milestone"}>
        <MilestoneForm
          btnTitle={"Update"}
          milestone={milestone}
          fun={updateMilestone}
        />
      </Modal>
    </>
  );
};

export default ViewMilestone;
