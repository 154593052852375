import React from "react";
import Box from '@material-ui/core/Box';
import GrammarGraph from "../../linegraphs/GrammarGraph";
import { useSelector, useDispatch } from 'react-redux';
import { getGrammarHistory } from '../../../store/actions/spellCheckActions';
import SubLoader from "../../commons/SubLoader";
import CircularProgress from '@material-ui/core/CircularProgress';

export default function SpellCheckCard({ dateRange, scanId, setLoader }) {

  const graph = useSelector(state => state.spellCheck.spellCheckHistory);
  const status = useSelector(state => state.spellCheck.spellCheckHistoryStatus);
  const loading = useSelector(state => state.spellCheck.spellCheckHistoryLoading);


  React.useEffect(() => {
    if (dateRange && scanId) {
      dispatch(getGrammarHistory(scanId, dateRange));
    }
    // eslint-disable-next-line
  }, [scanId, dateRange]);

  React.useEffect(() => {
    if (status === "pending" || status === "running") {
      setLoader(true)
      const interval1 = setInterval(() => {
        dispatch(getGrammarHistory(scanId, dateRange));
      }, 15000);
      return () => clearInterval(interval1);
    } else {
      setLoader(false)
    }
    // eslint-disable-next-line
  }, [scanId, status])

  const dispatch = useDispatch();

  return (
    <>
      {loading ? <Box display="flex" alignItems="center" justifyContent="center" style={{ minHeight: "415px", maxHeight: "415px", boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.15)" }}> <SubLoader /> </Box>
        :
        <>
          {graph && graph.length > 0 ?
            <>
              <GrammarGraph data={graph} />
            </>
            :
            <Box display="flex" justifyContent="center" alignItems="center" style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 12px', padding: 14, minHeight: "415px", maxHeight: "415px" }} >
              <Box display="flex" alignItems="center">
                <h4>We found no Grammatical History on your site</h4> {(status === "pending" || status === "running") && <CircularProgress style={{ color: "black", marginLeft: "1rem", width: "20px", height: "20px" }} />}
              </Box>
            </Box>
          }
        </>
      }
    </>
  );
}
