import React from "react";
import ReactDataGrid from "react-data-grid";
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Toolbar, Data } from "react-data-grid-addons";
import { useSelector } from 'react-redux';
import ReactPaginate from "react-paginate";

import "../../Styles/css/IssueTrackerTables.css";
import { BASE_URL } from '../../source/constants';
import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import TrackVisibility from 'react-on-screen';
import SubLoader from "./SubLoader";
import SendIcon from '@material-ui/icons/Send';
import { Editors } from 'react-data-grid-addons';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import issueTrackerTypesTableStyles from '../../Styles/jss/tables/issueTrackerTypesTableStyles'

const defaultColumnProperties = {
  filterable: true,
  resizable: true,
  width: 200
};

const { DropDownEditor } = Editors;

const issueTypes = [
  { id: "open", value: "Open" },
  { id: "inProgress", value: "In Progress" },
  { id: "resolved", value: "Resolved" },
  { id: "closed", value: "Closed" },
  { id: "cannotReproduced", value: "Cannot Reproduce" },
  { id: "reOpened", value: "Reopened" },
  { id: "inReviewed", value: "In Review" },
  { id: "done", value: "Done" },
];

const standardTypes = [
  { id: "wcag", value: "WCAG" },
  { id: "section508", value: "Section 508" },
  { id: "bestPractices", value: "Best Practices" },
];

const browserTypes = [
  { id: "chrome", value: "Chrome" },
  { id: "edge", value: "Edge" },
  { id: "firefox", value: "Firefox" },
  { id: "safari", value: "Safari" },
  { id: "opera", value: "Opera" },
  { id: "brave", value: "Brave" },
  { id: "samsungInternet", value: "Samsung Internet" },
  { id: "other", value: "Other" },
];

const priorityTypes = [
  { id: "highest", value: "Highest" },
  { id: "high", value: "High" },
  { id: "medium", value: "Medium" },
  { id: "low", value: "Low" },
  { id: "lowest", value: "Lowest" },
];

const HTMlFormatter = ({ value }) => {
  return <div dangerouslySetInnerHTML={{ __html: value }} />;
};


const IssueTypeEditor = <DropDownEditor options={issueTypes} />;
const StandardsEditor = <DropDownEditor options={standardTypes} />;
const BrowsersEditor = <DropDownEditor options={browserTypes} />;
const priorityEditor = <DropDownEditor options={priorityTypes} />;


const useStyles = makeStyles(issueTrackerTypesTableStyles);

const selectors = Data.Selectors;

export default function IssueTrackerTable({ tableId, title, type, domainId, selectedDomain, selectedScanId, loading }) {
  const [destruct, setDestruct] = React.useState(false);



  const [rows, setRows] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [pages, setPages] = React.useState(1);


  const [value, setValue] = React.useState(title);
  const [showEditField, setShowEditField] = React.useState(false);
  const [filters, setFilters] = React.useState({});
  const [selectedIndexes, setSelectedIndexes] = React.useState([]);
  const [customColumns, setCustomColumns] = React.useState([]);

  const [showColumnField, setShowColumnField] = React.useState(false);
  const selectedDomainId = useSelector(state => state.dashboard.selectedDomainId);

  // const openModal = useSelector(state => state.issueTracker.openModal);

  async function saveTable(rows, domainId) {
    const toastUid = uuidv4();
    toast.info("Saving...", { autoClose: false, closeButton: false, toastId: toastUid })
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        }
      };
      const tableData = {
        rows,
      }

      const res = await axios.post(`${BASE_URL}/issues/issue-tracker-update`, {
        ...tableData
      },
        options
      );

      if (res.data) {

        toast.update(toastUid, { render: () => "Saved", type: toast.TYPE.SUCCESS, closeButton: true, autoClose: 1000 });
        getTablesId(domainId, page);
      }
    } catch (err) {
      toast.update(toastUid, { render: () => "An Error Occurred", type: toast.TYPE.ERROR, closeButton: true, autoClose: 1000 });
    }
  }

  async function getTablesId(domainId, page) {
    const token = auth0Service.getAccessToken();

    const params = {
      domainId,
      page,
      limit: 9
    }

    try {
      const options = {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        }
      };

      const res = await axios.get(`${BASE_URL}/issues/issue-tracker-accessibility`, {
        params,
        ...options
      });

      if (res.data) {
        setRows(res.data.result.rows);
        setPages(res.data.pages)
        const modifiedColumns = res.data.result.columns.map((column) => {
          if (Object.keys(column).includes("editor") && Object.values(column).includes("standard")) {

            return { ...column, editor: StandardsEditor }
          }
          else if (Object.keys(column).includes("editor") && Object.values(column).includes("priorityEditor")) {
            return { ...column, editor: priorityEditor }
          }
          else if (Object.keys(column).includes("editor") && Object.values(column).includes("BrowsersEditor")) {
            return { ...column, editor: BrowsersEditor }
          }
          else if (Object.keys(column).includes("editor") && Object.values(column).includes("IssueTypeEditor")) {
            return { ...column, editor: IssueTypeEditor }
          }
          else if (Object.keys(column).includes("formatter") && Object.values(column).includes("HTMLFormatter")) {
            return { ...column, formatter: HTMlFormatter }
          }
          else { return column }
        })
        setCustomColumns([...modifiedColumns]);
      }
    } catch (err) {
      if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.');
      }
    }
  }


  const handleFilterChange = filter => filters => {
    const newFilters = { ...filters };
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    return newFilters;
  };

  function getRows(rows, filters) {
    return selectors.getRows({ rows, filters });
  }

  const filteredRows = getRows(rows, filters);

  const classes = useStyles();

  const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    setRows((state) => {
      const newRows = state.slice();
      for (let i = fromRow; i <= toRow; i++) {
        newRows[i] = { ...newRows[i], ...updated };

      }
      return newRows;
    })
  };

  // function handleChange(event) {
  //   setValue(event.target.value);
  // }

  // function editTitle() {
  //   setShowEditField(true)
  // };

  // function handleBlur() {
  //   if (!value) {
  //     setValue("Table Title")
  //   }
  //   setShowEditField(false);
  // }

  // const onRowsSelected = rows => {
  //   const selected = selectedIndexes.concat(
  //     rows.map(r => r.rowIdx)
  //   )
  //   setSelectedIndexes(selected);
  // };

  // const onRowsDeselected = rows => {
  //   let rowIndexes = rows.map(r => r.rowIdx);
  //   const deSelected = selectedIndexes.filter(
  //     i => rowIndexes.indexOf(i) === -1
  //   )
  //   setSelectedIndexes(deSelected);
  // };

  // const addRow = () => {
  //   setRows([...rows, {
  //   }])
  // };

  React.useEffect(() => {
    if (domainId) {
      getTablesId(domainId, page);
    }
    // eslint-disable-next-line
  }, [domainId, page])

  const [column, setColumn] = React.useState("Enter Column Name");


  const changePage = (page) => {
    setPage(page.selected);
  };
  return (<div>

    <div className="issue-tracker-table">
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" flexDirection="row" width="100%" p={0.88} style={{ backgroundColor: "#1f3a93" }}>
          {/* <Box display="flex" flexWrap="wrap" alignItems="center">
              {showEditField ? <CssTextField color="white" autoFocus defaultValue={value} className={classes.tableTitle}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    handleBlur();
                  }
                }}
                onChange={e => handleChange(e)} onBlur={handleBlur} /> :
                <h3 onClick={() => editTitle()}
                  onFocus={() => { editTitle() }}
                  tabIndex="0" className={classes.tableTitle} >{value}</h3>}
              {selectedIndexes.length > 0 && <Box display="flex" alignItems="center" ml={2}>
                <IconButton edge="start" className={classes.menuButton} aria-label="menu" disabled={loading || customColumns.length < 1} onClick={() => rowAction(selectedIndexes, tableId, domainId, type, { status: "completed" })}>
                  <DoneOutlineIcon className={classes.buttonIcon} /> Completed
                </IconButton>
                <IconButton edge="start" className={classes.menuButton} aria-label="menu" disabled={loading || customColumns.length < 1} onClick={() => rowAction(selectedIndexes, tableId, domainId, type, { archive: true })}>
                  <ArchiveIcon className={classes.buttonIcon} /> Archive
                </IconButton>
              </Box>
              }
            </Box> */}
          {/* <Box display="flex" justifyContent="flex-end" alignItems="center" flexWrap="wrap" flexDirection="row" my={1}  >
            <Button disabled={loading || customColumns.length < 1} mr={2} onClick={() => { window.open(`/issue-tracker/add-new-issue?domain=${selectedDomain}&scanId=${selectedScanId}&tableId=${tableId}&type=${type}`, "_blank"); }} className={classes.addCRButton} ><AddCircleOutlineIcon style={{ color: "#fff" }} /> <h4 style={{ margin: 0, marginLeft: 5, color: "#fff", fontWeight: "bold" }}>Issue</h4></Button>
          </Box> */}
          <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="flex-end" width='100%'>
            {/* <CustomButton
                disabled={loading || customColumns.length < 1}
                onClick={() => deleteTable(tableId, domainId, type)}
                startIcon={<DeleteOutlineOutlinedIcon className={classes.primaryColor} style={{ margin: "0", fontSize: 25 }} />}
                classes={{ startIcon: classes.startIcon }}
              >
              </CustomButton> */}
            <CustomButton
              disabled={loading || customColumns.length < 1}
              onClick={() => saveTable(rows, domainId)}
              startIcon={<SaveOutlinedIcon className={classes.primaryColor} style={{ margin: "0", fontSize: 25 }} />}
              style={{ padding: "6px 12px" }}
            >
              Save
            </CustomButton>
            {/* 
              {showColumnField ?
                <Box display="flex" justifyContent="center" alignItems="center" className={classes.addCRButton} >
                  <CssTextField
                    autoFocus

                    defaultValue={column}
                    id="column"
                    type="text"
                    onChange={(event) => setColumn(event.target.value)}
                  />
                  <SendIcon
                    onClick={() => { addColumn(domainId, type, tableId, column) }}
                    onKeyDown={event => {
                      if (event.key === 'Enter') {
                        addColumn(domainId, type, tableId, column)
                      }
                    }}
                    style={{ color: "white", marginLeft: "1rem" }} tabIndex="0" /> </Box>
                :
                <Box display="flex" onClick={() => { setShowColumnField(true) }} onFocus={() => { setShowColumnField(true) }} justifyContent="center" alignItems="center" className={classes.addCRButton} tabIndex="0"> <AddCircleOutlineIcon onClick={() => { setShowColumnField(true) }} style={{ color: "white" }} /> <h4 style={{ margin: 0, marginLeft: 5, color: "white" }} onClick={() => { setShowColumnField(true) }} > Add Column</h4> </Box>
              } */}
          </Box>

        </Box>
      </Box>
      {rows && customColumns && customColumns.length > 0 ?
        // eslint-disable-next-line
        <ReactDataGrid
          columns={customColumns.map(c => ({ ...c, ...defaultColumnProperties }))}
          rowGetter={i => filteredRows[i]}
          rowsCount={filteredRows.length}
          onGridRowsUpdated={onGridRowsUpdated}
          enableCellSelect={true}
          toolbar={<Toolbar enableFilter={true} />}
          onAddFilter={filter => setFilters(handleFilterChange(filter))}
          onClearFilters={() => setFilters({})}
        // rowSelection={{
        //   // showCheckbox: true,
        //   enableShiftSelect: true,
        //   onRowsSelected: onRowsSelected,
        //   onRowsDeselected: onRowsDeselected,
        //   selectBy: {
        //     indexes: selectedIndexes
        //   }
        // }}
        /> :
        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: "398px" }}>
          <SubLoader />
        </Box>
      }
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        mt={2}
      >
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          onPageChange={changePage}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </Box>
      {/* <Box display="flex" justifyContent="flex-end" alignItems="center" flexWrap="wrap" flexDirection="row" my={1} width="100%"   >
        <Button display="flex" justifyContent="center" alignItems="center" mr={2} onClick={() => { addRow(); }} className={classes.addCRButton} ><AddCircleOutlineIcon style={{ color: "#1f3a93" }} /> <h4 style={{ margin: 0, marginLeft: 5, color: "#1f3a93", fontWeight: "bold" }}>Row</h4></Button>
      </Box> */}
    </div>
  </div>)




  // if (destruct) {
  //   return null;
  // }
  // else
  //   return (
  //     <TrackVisibility once={true} partialVisibility={true}>
  //       <ComponentToTrack setDestruct={setDestruct} />
  //     </TrackVisibility>
  //   );
}



const CustomButton = withStyles((theme) => ({
  root: {
    backgroundColor: "white",
    border: "1px solid black",
    padding: "6px 6px",
    boxShadow: '0px 3px 6px #00000029',
    margin: "0px 10px",
    minWidth: 56,
    '&:hover': {
      backgroundColor: '#c1ceec',
    },
  },
}))(Button);
const CssTextField = withStyles({
  root: {

    maxWidth: "180px",
    '& .MuiInput-input': {
      color: 'white'
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: "white",
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: "white",
    },
    '& .MuiInputBase-root': {
      color: 'white',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
})(TextField);