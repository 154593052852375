import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { toast } from "react-toastify";
import store from "../../store";

export const GET_SCANS = 'GET_SCANS';
export const SCAN_ACTION = 'SCAN_ACTION';

export const getAllScans = (scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }

    const res = await axios.get(`${BASE_URL}/dashboard/get-scans`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_SCANS,
        payload: res.data.scans
      });
      return res.data;
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const performScanAction = (scanId, action) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${BASE_URL}/scan/scan-action`,
      {
        action,
        scanId
      },
      options
    );

    if (res.data) {
      dispatch({
        type: SCAN_ACTION,
      });
      dispatch(getAllScans(store.getState().miscellaneous.selectedeScanId));
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    toast.error(err.response.data.message);
  }
}


export const performSubScanAction = (scanId, action) => async (dispatch) => {
  try {

    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${BASE_URL}/scan/subscan-action`,
      {
        action,
        scanId
      },
      options
    );

    if (res.data) {
      dispatch(getAllScans(store.getState().miscellaneous.selectedeScanId));
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    toast.error(err.response.data.message);
  }
}

