import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    OutlinedInput,
    Typography,
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import Information from './Information';
import { makeStyles } from '@mui/styles';
import marginStyles from '../styles/margin';
import componentStyles from '../styles/stepper';

const useStyles = makeStyles(componentStyles);
const useMarginStyles = makeStyles(marginStyles);

const Index = ({ setData, data }) => {
    const classes = useStyles();
    const margin = useMarginStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    return (
        <>
            <Typography className={classes.heading2}>5. Complete the required identification questions about yourself.</Typography>
            <Box mt={3}>
                <Formik
                    initialValues={{
                        email: '',
                        confirmationEmail: '',
                        firstName: '',
                        lastName: '',
                        phoneNumber: '',
                        submit: null
                    }}
                    validationSchema={Yup.object().shape({
                        firstName: Yup.string().max(255).required('First name is required'),
                        lastName: Yup.string().max(255).required('Last name is required'),
                        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        confirmationEmail: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        phoneNumber: Yup.string().max(11).required('Correct phone number is required')
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            setData({
                                ...data,
                                identification: {
                                    ...values
                                }
                            });
                        } catch (err) { }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit} >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography className={classes.heading4} mb={2}>
                                        First Name
                                    </Typography>
                                    <FormControl fullWidth error={Boolean(touched.firstName && errors.firstName)}>
                                        <OutlinedInput
                                            id="outlined-adornment-email-register"
                                            type="text"
                                            value={values.firstName}
                                            name="firstName"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                            sx={{
                                                '& .css-4pzdr3-MuiInputBase-input-MuiOutlinedInput-input': {
                                                    height: '10px'
                                                }
                                            }}
                                        />
                                        {touched.firstName && errors.firstName && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.firstName}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography className={classes.heading4} mb={2}>
                                        Last Name
                                    </Typography>
                                    <FormControl fullWidth error={Boolean(touched.lastName && errors.lastName)}>
                                        <OutlinedInput
                                            id="outlined-adornment-email-register"
                                            type="text"
                                            value={values.lastName}
                                            name="lastName"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                            sx={{
                                                '& .css-4pzdr3-MuiInputBase-input-MuiOutlinedInput-input': {
                                                    height: '10px'
                                                }
                                            }}
                                        />
                                        {touched.lastName && errors.lastName && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.lastName}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography className={classes.heading4} mb={2}>
                                        Email Address
                                    </Typography>
                                    <FormControl fullWidth error={Boolean(touched.email && errors.email)}>
                                        <OutlinedInput
                                            id="outlined-adornment-email-register"
                                            type="text"
                                            value={values.email}
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                            sx={{
                                                '& .css-4pzdr3-MuiInputBase-input-MuiOutlinedInput-input': {
                                                    height: '10px'
                                                }
                                            }}
                                        />
                                        {touched.email && errors.email && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.email}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography className={classes.heading4} mb={2}>
                                        Confirmation Email
                                    </Typography>
                                    <FormControl fullWidth error={Boolean(touched.confirmationEmail && errors.confirmationEmail)}>
                                        <OutlinedInput
                                            id="outlined-adornment-confirmationEmail-register"
                                            type="text"
                                            value={values.confirmationEmail}
                                            name="confirmationEmail"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                            sx={{
                                                '& .css-4pzdr3-MuiInputBase-input-MuiOutlinedInput-input': {
                                                    height: '10px'
                                                }
                                            }}
                                        />
                                        {touched.confirmationEmail && errors.confirmationEmail && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.confirmationEmail}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Information
                                    text={
                                        'Please ensure you enter the same email address that was provided to us, as we will use your on-file email address for identity verification purposes.'
                                    }
                                />

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={2}>
                                    <Typography className={classes.heading4} mb={2}>
                                        Phone Number
                                    </Typography>
                                    <FormControl fullWidth error={Boolean(touched.phoneNumber && errors.phoneNumber)}>
                                        <OutlinedInput
                                            id="outlined-adornment-phoneNumber-register"
                                            type="text"
                                            value={values.phoneNumber}
                                            name="phoneNumber"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                            sx={{
                                                '& .css-4pzdr3-MuiInputBase-input-MuiOutlinedInput-input': {
                                                    height: '10px'
                                                }
                                            }}
                                        />
                                        {touched.phoneNumber && errors.phoneNumber && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.phoneNumber}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Information
                                    text={
                                        'Your mobile phone number is used only for automated text-based identity verification. Please ensure you enter the same phone number that was provided to us, as we will use your on-file phone number for identity verification purposes.'
                                    }
                                />
                            </Grid>

                            {errors.submit && (
                                <Box sx={{ mt: 3 }}>
                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                </Box>
                            )}
                        </form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default Index;
