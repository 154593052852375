import React from "react";
import TablesCard from "../../commons/TablesCard";
import DescriptionIcon from "@material-ui/icons/Description";
import Box from "@material-ui/core/Box";
import loader from "../../../assets/loader.svg";
import ReactTable from "../../commons/ReactTable";
import ReactTableWithoutPaging from "../../commons/ReactTableWithoutPaging";
import {
  getSpellCheckIgnoredDetails,
  unIgnoreSpellCheckLinks,
} from "../../../store/actions/spellCheckTablesActions";
import LaunchIcon from "@material-ui/icons/Launch";
import { useSelector, useDispatch } from "react-redux";
import Auth0Service from "../../../services/auth0Service/authentikService";
import tablesWrapperStyles from "../../../Styles/jss/tables/tablesWrapperStyles";

const styles = {
  ...tablesWrapperStyles
};

export default function SpellCheckIgnoreIssuesTable({ scanId }) {
  const data = useSelector(
    (state) => state.spellCheckTable.spellCheckIgnoreData
  );
  const isPackagePurchased = useSelector((state) => state.dashboard.isDomainPackagePurchased);

  const [tblData, setTblData] = React.useState([]);
  const dispatch = useDispatch();

  function spellCheckParsedData(data) {
    const tblDataSpellCheck = [];

    for (let i = 0; i < data.length; i++) {
      const link = data[i].link;
      const shortLink = new URL(data[i].link);
      const linkTitle = data[i].linkTitle;
      const offset = data[i].offset;
      const type = data[i].type;
      const suggestions = data[i].suggestions;
      const token = data[i].token;
      tblDataSpellCheck.push({
        link: (
          <div style={styles.textCenter}>
            <LaunchIcon style={{fontSize:'1.2rem'}} onClick={() => window.open(`${link}`, "_blank")} />
            <span
              style={styles.wplinks}
              aria-label={`${link}`}
              className="primarycolor"
              onClick={() =>
                window.open(
                  `/violation-reports/link?${new URLSearchParams({
                    link: link,
                  }).toString()}`
                )
              }
            >
              {shortLink.pathname.length > 40
                ? `...${shortLink.pathname.substring(20, 45)}...`
                : shortLink.pathname}
            </span>
          </div>
        ),
        linkTitle: (
          <div style={{fontWeight:500}} aria-label={`link title is ${linkTitle}`}>{linkTitle}</div>
        ),
        offset: <div style={{fontWeight:500}} aria-label={`offset is ${linkTitle}`}>{offset} </div>,
        type: <div style={{fontWeight:500}} aria-label={`type is ${linkTitle}`}>{type} </div>,
        suggestions: (
          <div aria-label="suggestions" style={{ width: "100%" }}>
            <ReactTableWithoutPaging
              tableColumns={suggestionsColumns}
              data={suggestions}
            />
          </div>
        ),
        token: <div style={{fontWeight:500}}>{token}</div>,
        issue: {
          ...data[i],
        },
      });
    }
    return tblDataSpellCheck;
  }

  React.useEffect(() => {
      if (Auth0Service.isAuthenticated() && isPackagePurchased) {
      dispatch(getSpellCheckIgnoredDetails(scanId));
    }
    // eslint-disable-next-line
  }, [scanId]);

  React.useEffect(() => {
    if (data) {
      const parsedData = spellCheckParsedData(data);
      setTblData(parsedData);
    }
    // eslint-disable-next-line
  }, [data]);

  const spellCheckColumns = [
    {
      width: "150px",
      Header: "Link",
      accessor: "link",
    },
    {
      width: "200px",
      Header: "Link Title",
      accessor: "linkTitle",
    },
    {
      width: "100px",
      Header: "Offset",
      accessor: "offset",
    },
    {
      width: "100px",
      Header: "Type",
      accessor: "type",
    },
    {
      width: "100px",
      Header: "Token",
      accessor: "token",
    },
    {
      width: "150px",
      Header: "Suggestions",
      accessor: "suggestions",
    },
  ];

  const suggestionsColumns = [
    {
      width: "80px",
      Header: "Suggestion",
      accessor: "suggestion",
    },
    {
      width: "80px",
      Header: "Score",
      accessor: "score",
    },
  ];

  function unIgnoreAction(data) {
    const issues = data.map((item) => {
      return item.original.issue;
    });
    const postData = {
      scanId,
      issues,
    };
    dispatch(unIgnoreSpellCheckLinks(postData, scanId));
  }
   if (!Auth0Service.isAuthenticated()  || !isPackagePurchased) {
    return ""
  } else {
    return (
      <TablesCard icon={<DescriptionIcon />} title="Ignored Issues">
        {tblData ? (
          <>
            {tblData.length > 0 ? (
              <ReactTable
                tableColumns={spellCheckColumns}
                unIgnoreAction={unIgnoreAction}
                selection={true}
                data={tblData}
                pageItems={10}
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "200px", maxHeight: "200px" }}
              >
                {" "}
                <h4>
                  {" "}
                  <i>No Ignored Issues</i>{" "}
                </h4>
              </Box>
            )}{" "}
          </>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: "200px", maxHeight: "200px" }}
          >
            {" "}
            <img src={loader} alt="loader" />
          </Box>
        )}
      </TablesCard>
    );
  }
}
