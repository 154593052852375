const accessibilityHistoryStyles = (theme) => ({
  priorities: {
    fontSize: 14,
    maxWidth: 500
  },
  gridPadd: {
    padding: "15px",
    [theme.breakpoints.down('lg')]: {
      padding: "5px",
    },
  },
  legendTitle: {
    marginLeft: "1rem"
  },
  borderRight: {
    borderRight: "1px solid #e3e7eb"
  },
  averageViolations: {
    marginLeft: "0rem",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
    [theme.breakpoints.up('sm')]: {
      marginLeft: "1rem",
      justifyContent: "flex-start",
    },
  },
  averageViolationsChildBox: {
    [theme.breakpoints.down('lg')]: {
      display: "contents"
    },
  }
});

export default accessibilityHistoryStyles;
