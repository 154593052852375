import { GET_ACTIVITY_LOGS } from "../actions/activityLogsActions";

const initialState = {
  logs: [],
  loading: false,
};

const activityLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVITY_LOGS:
      return {
        ...state,
        logs: action.payload.result.actions,
      };
    default:
      return state;
  }
};
export default activityLogsReducer;