 
import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { showAlert,removeAlert } from "./alertActions";
import { toast } from "react-toastify";

export const GET_PACKAGES_DOWNGRADE = 'GET_PACKAGES_DOWNGRADE';
export const GET_STRIPE_CARDS_DOWNGRADE = 'GET_STRIPE_CARDS_DOWNGRADE';
export const GET_TRANSACTIONS_DOWNGRADE = 'GET_TRANSACTIONS_DOWNGRADE';
export const DOWNGRADE_PACKAGE = 'DOWNGRADE_PACKAGE';
export const GET_PRICES_DOWNGRADE = 'GET_PRICES_DOWNGRADE';
export const GET_PRICES_LOADING_DOWNGRADE = 'GET_PRICES_LOADING_DOWNGRADE';

export const getPackages = () => async (dispatch) => {
  const token = auth0Service.getAccessToken();

  try {

const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.get(`${BASE_URL}/dashboard/evaluate-package`, options);

    if (res.data) {
      dispatch({
        type: GET_PACKAGES_DOWNGRADE,
        payload: res.data.stripePackage
      });

    }
  } catch (err) {

    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}

export const getCards = () => async (dispatch) => {
  const token = auth0Service.getAccessToken();

  try {

const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.get(`${BASE_URL}/dashboard/get-stripe-cards`, options);

    if (res.data) {
      dispatch({
        type: GET_STRIPE_CARDS_DOWNGRADE,
        payload: res.data.data
      });
    }
  } catch (err) {

    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}

export const getTransactions = () => async (dispatch) => {
  const token = auth0Service.getAccessToken();
  try {
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.get(`${BASE_URL}/dashboard/get-stripe-subscription`, options);
    if (res.data) {
      dispatch({
        type: GET_TRANSACTIONS_DOWNGRADE,
        payload: res.data.data
      });
    }
  } catch (err) {

    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}

export const downgradePackage = (tenure,customUsers,customWebsites, selectedPaymentMethod,) => async (dispatch) => {
  dispatch({
    type: DOWNGRADE_PACKAGE,
  });
  try {
    const token = auth0Service.getAccessToken();

const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

  
    const res = await axios.post(`${BASE_URL}/dashboard/change-subscription`, {

      change:"downgrade",
      tenure,
      last4: selectedPaymentMethod,
      customUsers,
      customWebsites
    }, options);

    if (res.data.message === "Subscription Updated") {
      dispatch(showAlert("Subscription Updated"))
      const alert = setTimeout(() => {
        window.location.pathname = '/dashboard';
        clearTimeout(alert);
      }, 1000);
    }
    else {
      dispatch(showAlert("An Error Occured"))
      const alert = setTimeout(() => {
        window.location.reload();
        clearTimeout(alert)
      }, 1000);
    }

    dispatch({
      type: DOWNGRADE_PACKAGE,
    });
  } catch (err) {
    dispatch({
      type: DOWNGRADE_PACKAGE,
    });
    dispatch(showAlert(err.response.data.message))
    const alert = setTimeout(() => {
      dispatch(removeAlert());
      clearTimeout(alert)
    }, 1000);
  }
}

export const getPrices = () => async (dispatch) => {
  const token = auth0Service.getAccessToken();
  dispatch({
    type:GET_PRICES_LOADING_DOWNGRADE,
  })
  try {
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.get(`${BASE_URL}/dashboard/prices`, options);
    if (res.data) {
      dispatch({
        type: GET_PRICES_DOWNGRADE,
        payload: res.data.prices
      });
    }
  } catch (err) {
    dispatch({
      type:GET_PRICES_LOADING_DOWNGRADE,
    })
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}