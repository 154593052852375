import React from 'react';
import { Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import componentStyles from '../styles/stepper';
import marginStyles from '../styles/margin';

const useStyles = makeStyles(componentStyles);
const useStylesMargin = makeStyles(marginStyles);

const Header = () => {
    const classes = useStyles();
    const margins = useStylesMargin();

    return (
        <div>
            <Typography variant="h2" className={classes.heading1}>
                1. Please declare your residency.
            </Typography>
            <Box mt={2}>
                <Typography variant="text" className={`${classes.text1}`}>
                   If you are an authorized agent submitting on behalf of a consumer, please respond with the consumer's residency information.
                </Typography>
            </Box>
        </div>
    );
};

export default Header;
