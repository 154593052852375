import React from 'react'
import styled from 'styled-components'
import { useTable } from 'react-table'
import CssBaseline from '@material-ui/core/CssBaseline'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow';
const Styles = styled.div`
  table {
    thead tr:first-child {
      display:none;
    }
  }
`
function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,

  } = useTable(
    {
      columns,
      data,
    },
  )
  // Render the UI for your table
  return (
    <>
      <MaUTable {...getTableProps()}>
        <TableHead style={{ backgroundColor: "#343AA3" }}>
          {
            headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()} tabIndex="0">
                {headerGroup.headers.map(column => (

                  <TableCell {...column.getHeaderProps()} style={{ fontSize: "15px", fontWeight: "bold", color: "white", minWidth: column.width }} >
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))
          }
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow {...row.getRowProps()} tabIndex="0">
                {row.cells.map(cell => {
                  return (
                    <TableCell {...cell.getCellProps()} style={{fontWeight:500}} >

                      {cell.render('Cell')}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </MaUTable>
    </>
  )
}

function App({ tableColumns, data }) {
  const columns = React.useMemo(
    () => [

      {
        Header: 'g',
        columns: tableColumns,
      },
    ],
    // eslint-disable-next-line
    []
  )

  return (

    <Styles>
      <CssBaseline />
      <Table columns={columns} data={data} />
    </Styles>

  )
}

export default App
