const accessibilityReportStyles = (theme) => ({
  root: {
    // height: "100%",
    padding: "0px",
    [theme.breakpoints.up("md")]: {
      padding: "0px",
    },
  },
  formControl: {
    width: 220,
    maxWidth: 300,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
    },
  },
  primaryColor: {
    color: "#1F3A93",
  },
  secondaryColor: {
    color: "#96291c",
  },
  marginZero: {
    margin: 0,
  },
  addSiteButton: {
    margin: 0,
    color: "#96291c",
    fontWeight: "600",
  },
  refreshButton: {
    marginTop: "16px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

export default accessibilityReportStyles;
