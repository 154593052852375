import React from "react";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Collapse from "@material-ui/core/Collapse";
import ShortenNumber from "../../commons/ShortenNumber";

import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
const styles = {
  cardMain: {
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    height: "100%",
    padding: "12px 10px",
    minHeight: 295,
  },
  boxContainer: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: 9,
    justifyContent: "center",
    alignItems: "center",
    padding: "14px",
    minHeight: 275,
  },
  top5: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 20,
    marginRight: "20px",
    marginBottom: 5,
    marginTop: 0,
  },
  pType: {
    marginRight: 10,
    width: 60,
    fontWeight: 500,
  },
  IconButton: {
    marginLeft: 5,
    color: "black",
    padding: 0,
  },
  infoIcon: {
    width: 15,
    height: 15,
  },
};

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
  table: {
    // minWidth: 480,
    overflow: "auto",
    tableLayout: "fixed",
  },
  IconButton: {
    marginLeft: 20,
    color: "black",
    padding: 0,
  },
}));

function PerformanceTable({
  data,
  columns,
  columns2,
  tabs,
  totalViolations,
  noCollapse,
}) {
  const [tab, setTab] = React.useState(0);
  const [tabData, setTabData] = React.useState([]);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setTab(newValue);
    if (newValue === 0 && data.top5Vulnerabilities) {
      setTabData(data?.top5Vulnerabilities);
    } else {
      setTabData(data?.top5Pages);

    }
  };
  function percentageConvert(value) {
    return (value / totalViolations) * 100;
  }

  React.useEffect(() => {
    if (data) {
      if (data.top5Vulnerabilities) {
        setTabData(data?.top5Vulnerabilities);
      } else {
        setTabData(data?.top5Pages);

      }
    }
  }, [data]);

  console.log(tab)
  return (
    // <Card style={styles.cardMain} tabIndex="0">
    <Box style={styles.boxContainer}>
      <Box height="100%">
        <Box display="flex" flexWrap="wrap" alignItems="center" tabIndex="0">
          <h3 style={styles.top5}>Most Frequent</h3>
          <CustomTabs
            value={tab}
            onChange={handleChange}
          // variant="scrollable"
          // scrollButtons="auto"
          >
            {tabs.map((item, index) => {
              return <CustomTab key={index} label={item} tabIndex="0" />;
            })}
          </CustomTabs>
        </Box>
        {tabData && tabData?.length > 0 ? (
          <TableContainer tabIndex="0">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                {tab === 0 ? (
                  <TableRow>
                    {columns.map((td, index) => {
                      return (
                        <THead key={index} style={{ width: td.width }}>
                          {td.column}
                        </THead>
                      );
                    })}
                  </TableRow>
                ) : (
                  <TableRow>
                    {columns2.map((td, index) => {
                      return (
                        <THead key={index} style={{ width: td.width }}>
                          {td.column}
                        </THead>
                      );
                    })}
                  </TableRow>
                )}
              </TableHead>
              {tab === 0 ? (
                <TableBody>
                  {tabData.map((td, index) => {
                    return (
                      <>
                        <Row
                          td={td}
                          key={index}
                          noCollapse={noCollapse}
                          percentageConvert={percentageConvert}
                        />
                      </>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  {tabData.map((td, index) => {
                    return (
                      <Row2
                        key={index}
                        td={td}
                        percentageConvert={percentageConvert}
                      />
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="180px"
          >
            <span>No data available</span>
          </Box>
        )}
      </Box>
    </Box>
    // </Card>
  );
}

export default React.memo(PerformanceTable);

const BorderLinearProgressHigh = withStyles((theme) => ({
  root: {
    height: 10,
    width: 130,
    borderRadius: 4,
    display: "inline-block",
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 4,
    backgroundColor: "#E2211E",
  },
}))(LinearProgress);

const BorderLinearProgressModerate = withStyles((theme) => ({
  root: {
    height: 10,
    width: 130,
    borderRadius: 4,
    display: "inline-block",
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 4,
    backgroundColor: "#FFD800",
  },
}))(LinearProgress);

const BorderLinearProgressLow = withStyles((theme) => ({
  root: {
    height: 10,
    width: 130,
    borderRadius: 4,
    display: "inline-block",
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 4,
    backgroundColor: "#21CA5A",
  },
}))(LinearProgress);

const THead = withStyles((theme) => ({
  head: {
    fontSize: 16,
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: 400,
    letterSpacing: "0em",
    textAlign: "left",
    padding: 5,
  },
}))(TableCell);

const TData = withStyles((theme) => ({
  body: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 13,
    padding: "7px 7px",
    letterSpacing: "0.01em",
    color: "#212225",
    textAlign: "left",
    border: "none",
  },
}))(TableCell);

const TDType = withStyles((theme) => ({
  body: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 13,
    padding: "7px 7px",
    letterSpacing: "0.01em",
    color: "#212225",
    textAlign: "left",
    border: "none",
  },
}))(TableCell);

const TDPriority = withStyles((theme) => ({
  body: {
    fontFamily: "Segoe UI",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 13,
    padding: "7px 10px",
    letterSpacing: "0.01em",
    color: "#212225",
    textAlign: "left",
    border: "none",
    // display: "flex",
    // justifyContent: "flex-start",
    // alignItems: "center",
  },
}))(TableCell);

const CustomTabs = withStyles({
  root: {
    borderBottom: "none",
    alignItems: "center",
  },
  indicator: {
    backgroundColor: "#1f3a93",
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    minWidth: 40,
    fontFamily: "Segoe UI",

    opacity: 1,
    minHeight: "auto",
    borderRadius: "2px",
    padding: "0px 8px",
    border: "1px solid #1f3a93",
    color: "#1f3a93",
    fontWeight: 400,
    fontSize: "18px",
    borderRight: 0,
    "&:last-child": {
      borderRight: "1px solid #1f3a93",
    },
    "&:hover": {
      color: "white",
      backgroundColor: "#1f3a93",
      opacity: 1,
    },
    "&$selected": {
      color: "white",
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: "#1f3a93",
    },
    "&:focus": {
      color: "white",
      backgroundColor: "#1f3a93",
    },
  },
  selected: {
    color: "white",
    backgroundColor: "#1f3a93",
  },
}))((props) => <Tab disableRipple {...props} />);

function Row({ td, percentageConvert, noCollapse }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow>
        {noCollapse ? (
          ""
        ) : (
          <TData component="th">
            <Box display="flex" alignItems="center">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Box>
          </TData>
        )}
        <TData component="th">
          <ShortenNumber>{td.count}</ShortenNumber>
        </TData>

        {/* <TDType>
        <Row data={td} />
      </TDType> */}
        <TDPriority>
          <Box display="flex" alignItems="center">
            <span style={styles.pType}>{td.priority}</span>
            {td.priority === "high" ? (
              <BorderLinearProgressHigh
                variant="determinate"
                value={percentageConvert(td.count)}
              />
            ) : td.priority === "medium" ? (
              <BorderLinearProgressModerate
                variant="determinate"
                value={percentageConvert(td.count)}
              />
            ) : (
              <BorderLinearProgressLow
                variant="determinate"
                value={percentageConvert(td.count)}
              />
            )}
            <p style={{ margin: "0 0 0 10px", fontWeight: 500 }}>
              {percentageConvert(td.count).toFixed(2)}%{" "}
            </p>
          </Box>
        </TDPriority>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, overflow: "auto" }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table
                size="small"
                aria-label="purchases"
                style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 250 }}>Issue Detail</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={td.vulnerabilityType}>
                    <TableCell scope="row">{td.vulnerabilityType}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function Row2({ td, percentageConvert, noCollapse }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow>
        <TData component="th">
          <ShortenNumber>{td.count}</ShortenNumber>
        </TData>
        {!noCollapse && (
          <TDType>
            <Box display="flex" alignItems="center">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Box>
          </TDType>
        )}
        <TDPriority>
          <Box display="flex" alignItems="center">
            <span style={styles.pType}>{td.priority}</span>
            {td.priority === "high" ? (
              <BorderLinearProgressHigh
                variant="determinate"
                value={percentageConvert(td.count)}
              />
            ) : td.priority === "medium" ? (
              <BorderLinearProgressModerate
                variant="determinate"
                value={percentageConvert(td.count)}
              />
            ) : (
              <BorderLinearProgressLow
                variant="determinate"
                value={percentageConvert(td.count)}
              />
            )}
            <p style={{ margin: "0 0 0 10px", fontWeight: 500 }}>
              {percentageConvert(td.count).toFixed(2)}%{" "}
            </p>
          </Box>
        </TDPriority>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, overflow: "auto" }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table
                size="small"
                aria-label="purchases"
                style={{ tableLayout: "fixed" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 250 }}>Page</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={td.page}>
                    <TableCell scope="row">{td.page}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
