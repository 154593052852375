import React from 'react';
import { Box, Typography } from '@mui/material';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { makeStyles } from '@mui/styles';
import marginStyles from './styles/margin';
import componentStyles from './styles/stepper';

const useStyles = makeStyles(componentStyles);
const useMarginStyles = makeStyles(marginStyles);

const Information = ({ text }) => {
    const classes = useStyles();
    const margin = useMarginStyles();
    return (
        <Box mt={2} display="flex" alignItems="center" p={2} sx={{ background: 'rgb(190, 227, 248)', width: '100%' }}>
            <Box>
                <ErrorOutlinedIcon sx={{ color: '#3182ce' }} />
            </Box>
            <Typography className={`${classes.text3} ${margin['ml-10']}`}>{text}</Typography>
        </Box>
    );
};

export default Information;
