import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress, Box, Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import OnBoardingWrapper from "./OnBoardingWrapper";
import { addOnBoardDomain, clearForbiddenError } from "../../store/actions/onBoardingActions";
import { onBoardDashboardReducer } from "../../store/reducers/onBoardDashboardReducer"


export default function AddDomain() {


  const [addDomain, setAddDomain] = React.useState({ domain: "", email: "", demo: window.location.href.includes("demo.allyright.com")});
  let { search } = useLocation();

  const query = new URLSearchParams(search);

  const dispatch = useDispatch();
  const forbiddenError = useSelector((state) => state.onBoardDashboard.forbiddenError);

  const handleClearError = () => {
    dispatch(clearForbiddenError()); // Dispatch action to clear the forbiddenError
  };

  const loading = useSelector((state) => state.onBoardDashboard.addDomainOnBoardLoading);

  const handleDomainChange = (event) => {
    setAddDomain({ ...addDomain, [event.target.name]: event.target.value });
  };

  function onDomainSubmit(event) {
    event.preventDefault();
    dispatch(addOnBoardDomain(addDomain));
  }

  React.useEffect(() => {
    const domainFromURl = query.get("add-domain");
    if (domainFromURl) {
      setAddDomain({ domain: domainFromURl });
      dispatch(addOnBoardDomain({ domain: domainFromURl }));
    } else {
      // eslint-disable-next-line
      window.history.replaceState({}, document.title, "/dashboard" + "");
    }
    // eslint-disable-next-line
  }, [])


  return (
    <OnBoardingWrapper>
      <Box
        px={2}
        style={{ paddingTop: 60 }}

      >
        <Grid container spacing={2}>
          <Grid item md={9}>
            <div >
              <div className="text-content">
                <div className="initial-static-text">
                  <h1>
                    Catch{" "}
                    <span className="hover">
                      <u></u>
                    </span>{" "}
                    on your website
                  </h1>
                </div>
              </div>
              <div className="main-input">
                <h2>Get a free website compliance audit!</h2>
                <form onSubmit={onDomainSubmit}>
                  <input
                    className="website-input"
                    placeholder="Your Email Address"
                    name="email"
                    type="email"
                    onChange={(e) => handleDomainChange(e)}

                    required
                  />
                  <input
                    onChange={(e) => handleDomainChange(e)}
                    name="domain"
                    className="website-input em"
                    placeholder="Your Website URL"

                    required
                  />
                  <br />
                  <div className="btn-main">
                    <div>
                      <button className="free-btn" disabled={loading} type="submit">
                        {loading && (
                          <CircularProgress
                            style={{ color: "white", marginRight: "1rem" }}
                            size={20}
                          />
                        )}
                        Get a Free Report
                      </button>
                    </div>
                    <div className="credit-card">
                      <h5>No credit card required</h5>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {forbiddenError && (
                      <div className="error-container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <button onClick={handleClearError} className="clear-error-button" style={{ marginRight: '8px' }}>
                          &#10005; {/* Unicode for the cross symbol */}
                        </button>
                        <h3 className="error-message">{forbiddenError}</h3>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>

          </Grid>
          {/* <Grid item md={5}>
            <img src={homepage} alt="dashboard img" style={{ width: '100%', height: 'auto', maxHeight: 300, marginTop: 60 }} />
          </Grid> */}
        </Grid>
      </Box>
    </OnBoardingWrapper>
  );
}

