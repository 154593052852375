import {
  GET_PACKAGES,
  GET_STRIPE_CARDS,
  GET_TRANSACTIONS,
  UPGRADE_PACKAGE,
  GET_PRICES,
  GET_PRICES_LOADING,
  CANCEL_SUBSCRIPTION
} from '../actions/changePackageActions';

const initialState = {
  packages: {
    data: null,
    loading: true
  },
  stripeCards: null,
  transactions: null,
  loadingSubscribe: false,
  prices: null,
  rangeOfPages: null,
  pricesLoading: false,
  loadingUnSubscribe: false,
};

const changePackageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PACKAGES:
      return {
        ...state,
        packages: { data: action.payload, loading: false }
      }
    case GET_STRIPE_CARDS:
      return {
        ...state,
        stripeCards: action.payload
      }
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload
      }
    case UPGRADE_PACKAGE:
      return {
        ...state,
        loadingSubscribe: !state.loadingSubscribe
      }
    case GET_PRICES_LOADING:
      return {
        ...state,
        pricesLoading: !state.pricesLoading

      }
    case GET_PRICES:
      return {
        ...state,
        prices: {
          ...action.payload.prices,
          resoutions: action.payload.prices.resolutions.map(reso => {
            return (
              reso.categories.reverse().map(c => {
                return c
              }))
          })
        },
        rangeOfPages: action.payload.prices.rangeOfPages,
        pricesLoading: false
      }
    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        loadingUnSubscribe: !state.loadingUnSubscribe
      }
    default:
      return state;
  }
};
export default changePackageReducer; 