import React from "react";

import { useSelector, useDispatch } from "react-redux";
import { getPerformanceAnalytics } from "../../../../store/actions/performanceActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import PerformanceCards from "../../../dnd/performance/Cards";
import BrowserCards from "../../../dnd/browserTimings/Cards";
import '../structure.css'

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { CircularProgress, Box } from "@material-ui/core";

function Performance() {
  const dispatch = useDispatch();
  const scanId = useSelector((state) => state.miscellaneous.selectedScanId);

  const data = useSelector((state) => state.performance.performance_analytics);
  const status = useSelector(
    (state) => state.performance.performance_analytics_status
  );
  const loading = useSelector(
    (state) => state.performance.performance_analytics_loading
  );

  React.useEffect(() => {
    if (scanId) {
      dispatch(getPerformanceAnalytics(scanId));
    }
    // eslint-disable-next-line
  }, [scanId]);

  React.useEffect(() => {
    if (status === "running" || status === "pending") {
      const interval = setInterval(() => {
        if (scanId) {
          dispatch(getPerformanceAnalytics(scanId));
        }
      }, 15000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [status]);

  // Performance Metrics start here
  const [boxesArray, setBoxesArray] = React.useState([]);

  const [browserBoxesArray, setBrowserBoxesArray] = React.useState([]);

  const [browserBackArray, setBrowserBackArray] = React.useState([]);
  const [performanceMetricsBackArray, setPerformanceMetricsBackArray] =
    React.useState([]);

  React.useEffect(() => {
    const browserBackArrayDefault = [
      "redirectduration",
      "connectionduration",
      "backendduration",
      "timetofirstbyte",
      "DOMinteractivetime",
      "DOMcontentloadedtime",
      "firstpaint",
      "onloadtime",
      "fullyloadedtime",
    ];

    const performanceMatricsBoxes = [
      "firstcontentfulpaint",
      "interactive",
      "speedindex",
      "totalblockingtime",
      "largestcontentfulpaint",
      "cumulativelayoutshift",
    ];

    const array1 = JSON.parse(localStorage.getItem("browserTimingsArray"));
    if (array1) {
      setBrowserBackArray([...array1]);
    } else {
      setBrowserBackArray([...browserBackArrayDefault]);
      localStorage.setItem(
        "browserTimingsArray",
        JSON.stringify(browserBackArrayDefault)
      );
    }

    const array2 = JSON.parse(localStorage.getItem("performanceMetricsArray"));
    if (array2) {
      setPerformanceMetricsBackArray([...array2]);
    } else {
      setPerformanceMetricsBackArray([...performanceMatricsBoxes]);
      localStorage.setItem(
        "browserTimingsArray",
        JSON.stringify(performanceMatricsBoxes)
      );
    }
  }, []);

  React.useEffect(() => {
    if (data && performanceMetricsBackArray.length > 0) {
      const preBoxes = [
        {
          text: (

            <PerformanceMetrics
              customClass="report-perf-box-first-contentful-paint"
              rating={
                data.firstContentfulPaint.rating &&
                data.firstContentfulPaint.rating
              }
              title="First Contentful Paint"
              postContent="How quickly content like text or images are painted onto your page.A good user experience is 0.9s or less."
              message={
                data.firstContentfulPaint.message &&
                data.firstContentfulPaint.message
              }
              result={`${data.firstContentfulPaint.value &&
                data.firstContentfulPaint.value
                }s`}
            />
          ),
          id: "firstcontentfulpaint",
        },
        {
          text: (
            <PerformanceMetrics
              customClass="report-perf-box-interactive"
              rating={
                data.timeToInteractive.rating && data.timeToInteractive.rating
              }
              title="Time to Interactive"
              postContent="How long it takes for your page to become fully interactive.A good user experience is 2.5s or less."
              message={
                data.timeToInteractive.message && data.timeToInteractive.message
              }
              result={`${data.timeToInteractive.value && data.timeToInteractive.value
                }s`}
            />
          ),
          id: "interactive",
        },
        {
          text: (
            <PerformanceMetrics
              customClass="report-perf-box-speed-index"
              rating={data.speedIndex.rating && data.speedIndex.rating}
              title="Speed Index"
              postContent="How quickly the contents of your page are visibly populated.A good user experience is 1.3s or less."
              message={data.speedIndex.message && data.speedIndex.message}
              result={`${data.speedIndex.value && data.speedIndex.value}s`}
            />
          ),

          id: "speedindex",
        },
        {
          text: (
            <PerformanceMetrics
              customClass="report-perf-box-total-blocking-time"
              rating={
                data.totalBlockingTime.rating && data.totalBlockingTime.rating
              }
              title="Total Blocking Time"
              postContent="How much time is blocked by scripts during your page loading process.A good user experience is 150ms or less."
              message={
                data.totalBlockingTime.message && data.totalBlockingTime.message
              }
              result={`${data.totalBlockingTime.value && data.totalBlockingTime.value
                }s`}
            />
          ),

          id: "totalblockingtime",
        },
        {
          text: (
            <PerformanceMetrics
              customClass="report-perf-box-largest-contentful-paint"
              rating={data.totalBlockingTime.rating}
              title="Largest Contentful Paint"
              postContent="How long it takes for the largest element of content (e.g. a hero image) to be painted on your page.A good user experience is 1.2s or less."
              message={data.totalBlockingTime.message}
              result={`${data.totalBlockingTime.value}s`}
            />
          ),
          id: "largestcontentfulpaint",
        },
        {
          text: (
            <PerformanceMetrics
              customClass="report-perf-box-cumulative-layout-shift"
              rating={data.cumulativeLayoutShift.rating}
              title="Cumulative Layout Shift"
              postContent="How much your page's layout shifts as it loads.A good user experience is a score of 0.1 or less."
              message={data.cumulativeLayoutShift.message}
              result={`${data.cumulativeLayoutShift.value}s`}
            />
          ),
          id: "cumulativelayoutshift",
        },
      ];
      // const arrayo = [];

      // for (let i = 0; i < performanceMetricsBackArray.length; i++) {
      //   switch (performanceMetricsBackArray[i]) {
      //     case "firstcontentfulpaint":
      //       arrayo.push(
      //         preBoxes.filter((box) => box.id === "firstcontentfulpaint")[0]
      //       );
      //       break;
      //     case "interactive":
      //       arrayo.push(preBoxes.filter((box) => box.id === "interactive")[0]);
      //       break;
      //     case "speedindex":
      //       arrayo.push(preBoxes.filter((box) => box.id === "speedindex")[0]);
      //       break;
      //     case "totalblockingtime":
      //       arrayo.push(
      //         preBoxes.filter((box) => box.id === "totalblockingtime")[0]
      //       );
      //       break;
      //     case "largestcontentfulpaint":
      //       arrayo.push(
      //         preBoxes.filter((box) => box.id === "largestcontentfulpaint")[0]
      //       );
      //       break;
      //     default:
      //       arrayo.push(
      //         preBoxes.filter((box) => box.id === "cumulativelayoutshift")[0]
      //       );
      //   }
      // }
      setBoxesArray([...preBoxes]);
    }
    // eslint-disable-next-line
  }, [data]);

  // Performance Metrics end here

  // Browser Timings start here

  React.useEffect(() => {
    if (data && browserBackArray.length > 0) {
      const preBoxes = [
        {
          text: (
            <BrowserTimings
              customClass="redirect"
              title={"Redirect Duration"}
              result={`${data.redirectDuration}s`}
            />
          ),
          id: "redirectduration",
        },
        {
          text: (
            <BrowserTimings
              customClass="connect"
              title={"Connection Duration"}
              result={`${data.connectionDuration.toFixed(3)}s`}
            />
          ),
          id: "connectionduration",
        },
        {
          text: (
            <BrowserTimings
              customClass="backend"
              title={"Backend Duration"}
              result={`${(data.backendDuration.toString().split('.')[0].length < 5 ? data.backendDuration: data.backendDuration/1000000).toFixed(3)}s`}
            />
          ),

          id: "backendduration",
        },
        {
          text: (
            <BrowserTimings
              customClass="first-byte"
              title={"Time to First Byte (TTFB)"}
              result={`${(data.timeToFirstByte.toString().split('.')[0].length < 5 ? data.timeToFirstByte: data.timeToFirstByte/1000000).toFixed(3)}s`}
            />
          ),

          id: "timetofirstbyte",
        },
        {
          text: (
            <BrowserTimings
              customClass="dom-interactive"
              title={"DOM Interactive Time"}
              result={`${data.domInteractiveTime.toFixed(3)}s`}
            />
          ),
          id: "DOMinteractivetime",
        },
        {
          text: (
            <BrowserTimings
              customClass="dom-content-loaded"
              title={"DOM Content Loaded Time"}
              result={`${data.domContentLoadedTime.toFixed(2)}s`}
            />
          ),
          id: "DOMcontentloadedtime",
        },
        {
          text: (
            <BrowserTimings
              customClass="first-paint"
              title={"First Paint"}
              result={`${data.firstPaint.toFixed(1)}s`}
            />
          ),
          id: "firstpaint",
        },
        {
          text: (
            <BrowserTimings
              customClass="onload"
              title={"Onload Time"}
              result={`${data.onLoadTime.toFixed(1)}s`}
            />
          ),
          id: "onloadtime",
        },
        {
          text: (
            <BrowserTimings
              customClass="fully-loaded"
              title={"Fully Loaded Time"}
              result={`${data.fullyLoadedTime}s`}
            />
          ),
          id: "fullyloadedtime",
        },
      ];
      // const arrayo = [];
      // if (browserBackArray && data)
      //   for (let i = 0; i < browserBackArray.length; i++) {
      //     switch (browserBackArray[i]) {
      //       case "redirectduration":
      //         arrayo.push(
      //           preBoxes.filter((box) => box.id === "redirectduration")[0]
      //         );
      //         break;
      //       case "connectionduration":
      //         arrayo.push(
      //           preBoxes.filter((box) => box.id === "connectionduration")[0]
      //         );
      //         break;
      //       case "backendduration":
      //         arrayo.push(
      //           preBoxes.filter((box) => box.id === "backendduration")[0]
      //         );
      //         break;
      //       case "timetofirstbyte":
      //         arrayo.push(
      //           preBoxes.filter((box) => box.id === "timetofirstbyte")[0]
      //         );
      //         break;
      //       case "DOMinteractivetime":
      //         arrayo.push(
      //           preBoxes.filter((box) => box.id === "DOMinteractivetime")[0]
      //         );
      //         break;
      //       case "DOMcontentloadedtime":
      //         arrayo.push(
      //           preBoxes.filter((box) => box.id === "DOMcontentloadedtime")[0]
      //         );
      //         break;
      //       case "firstpaint":
      //         arrayo.push(preBoxes.filter((box) => box.id === "firstpaint")[0]);
      //         break;
      //       case "onloadtime":
      //         arrayo.push(preBoxes.filter((box) => box.id === "onloadtime")[0]);
      //         break;
      //       default:
      //         arrayo.push(
      //           preBoxes.filter((box) => box.id === "fullyloadedtime")[0]
      //         );
      //     }
      //   }
      setBrowserBoxesArray([...preBoxes]);
    }
    // eslint-disable-next-line
  }, [data]);

  // Browser Timings end here

  return (
    <React.Fragment>
      <div
        id="performance"
        className="report-performance r-tabs-panel r-tabs-state-active"
        style={{ display: "block" }}
      >
        {data && !loading ? (
          <div className="report-perf-intro">
            <div>
              <h2 style={{
                marginTop: 0,
                fontSize: 20,
                lineHeight: 1,
                fontWeight: 500,
              }}>
                Performance Metrics
              </h2>
              <p className="report-perf-desc">
                The following metrics are generated using Lighthouse Performance
                data.
              </p>
            </div>

            {/* start here */}
            <div className="report-perf-boxes">
              {boxesArray.length > 0 && (
                // <DndProvider backend={HTML5Backend}>
                //   <PerformanceCards boxes={boxesArray} />
                // </DndProvider>

                boxesArray.map((item, index) => {
                  return (
                    <div className="report-perf-box  report-perf-box-performance">
                      {item.text}
                    </div>
                  )
                })
              )}
            </div>
            {/* end here */}

            <h2 style={{

              fontSize: 20,
              lineHeight: 1,
              fontWeight: 500,
            }}>
              Browser Timings
            </h2>
            <p className="report-perf-desc">
              These timings are milestones reported by the browser.
            </p>


            {/* {browserBoxesArray.length > 0 && (
              <DndProvider backend={HTML5Backend}>
                <BrowserCards boxes={browserBoxesArray} />
              </DndProvider>
            )} */}

            <div className="report-perf-boxes report-perf-boxes-smaller">
              {browserBoxesArray.length > 0 && (
                // <DndProvider backend={HTML5Backend}>
                //   <PerformanceCards boxes={browserBoxesArray} />
                // </DndProvider>

                browserBoxesArray.map((item, index) => {
                  return (
                    <div className="report-perf-box">
                      {item.text}
                    </div>
                  )
                })
              )}
            </div>
          </div>
        ) : (
          <Box display="flex" justifyContent="center" py={2}>
            <CircularProgress
              style={{
                width: "65px",
                height: "65px",
              }}
            />
          </Box>
        )}
      </div>
    </React.Fragment >
  );
}
export default React.memo(Performance);

function PerformanceMetrics({
  customClass,
  rating,
  title,
  postContent,
  message,
  result,
}) {
  return (
    <div className="report-perf-box  report-perf-box-performance">
      <div
        className={`report-perf-box-text report-perf-box-expand  ${customClass}`}
      >
        <h4 style={{ fontSize: "20px", fontWeight: 600 }}>{title}</h4>
        <p style={{ fontSize: "18px" }}>{postContent}</p>
      </div>
      <div
        className={`report-perf-box-result report-perf-box-rating-${rating}`}
      >
        <div className="report-perf-box-result-message">{message}</div>
        <div className="report-perf-box-result-numeric">{result}</div>
      </div>
    </div>
  );
}

function BrowserTimings({ customClass, title, result }) {
  return (
    <div className="report-perf-box">
      <div className={`report-perf-box-text report-perf-box-${customClass}`}>
        <h4>{title}</h4>
      </div>
      <div className="report-perf-box-result">
        <div className="report-perf-box-result-numeric">{result}</div>
      </div>
    </div>
  );
}
