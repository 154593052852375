import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MilestoneForm from "./forms/MilestoneForm"

import Container from "@material-ui/core/Container";

import { addNewMilestone } from "../../store/actions/issueTrackerActions";
import addIssueStyles from "../../Styles/jss/addIssueStyles";

export default function NewMilestone() {

  const classes = useStyles();

  return (
    <>
      <Container fixed style={{ padding: 20 }}>
        <div className={classes.mainRow}>
          <h3 className={classes.mainTitle}>New Milestone</h3>
        </div>
        <MilestoneForm fun={addNewMilestone} btnTitle={"Create"} />
      </Container>
    </>
  );
}

const useStyles = makeStyles(addIssueStyles);
