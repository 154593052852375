import history from '../../../@history';
import _ from '../../../@lodash';
// import jwtService from '../../../services/jwtService';
import auth0Service from '../../../services/auth0Service/authentikService';
import authentikService from '../../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../../source/constants';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import { DomainsLink } from "../../../../src/store/actions/adminScansManagementActions"
export const SET_USER_DATA = '[USER] SET DATA';
export const REMOVE_USER_DATA = '[USER] REMOVE DATA';
export const USER_LOGGED_OUT = '[USER] LOGGED OUT';
export const USER_PASS_CHANGED = 'USER_PASS_CHANGED';
export const USER_UPDATED_SUCCESS = 'USER_UPDATED_SUCCESS';
export const SET_PACKAGE_SELECTION = 'SET_PACKAGE_SELECTION';
export const CREATE_PACKAGE = 'CREATE_PACKAGE';
export const REMAINING_WEBSITES = 'REMAINING_WEBSITES';
export const LOADING_UPDATE = 'LOADING_UPDATE';

const cookies = new Cookies();

export const createPackage = (packages, selectedPackage) => async (dispatch) => {
	if (packages) {
		const pagerange = packages.rangeOfPages.filter(item => item.pageRange === selectedPackage.rangeOfPages.pageRange && item.tenure === selectedPackage.tenure)[0];
		const userRange = packages.rangeOfUsers.filter(item => item.userRange === selectedPackage.rangeOfUsers.userRange && item.tenure === selectedPackage.tenure)[0];
		const websiteRange = packages.rangeOfWebsites.filter(item => item.websiteRange === selectedPackage.rangeOfWebsites.websiteRange && item.tenure === selectedPackage.tenure)[0];
		const resolutions = packages.resolutions.filter(e => selectedPackage.resolutions.some(item => item.resolution === e.resolution)).filter(item => item.tenure === selectedPackage.tenure);
		const tenure = selectedPackage.tenure;

		const stripePackage = {

			rangeOfPages: pagerange,
			rangeOfUsers: userRange,
			rangeOfWebsites: websiteRange,
			resolutions: resolutions,
			tenure: tenure

		};

		try {
			const token = authentikService.getAccessToken();

			const options = {
				headers: {
					'Content-Type': 'application/json',
					"Authorization": `Bearer ${token}`
				}
			};
			// eslint-disable-next-line
			const res = await axios.post(`${BASE_URL}/dashboard/select-package`, {
				stripePackage
			}, options);

		} catch (err) {

		}
	}
}

/**
 * Set user data from Auth0 token data
 */
export function setUserDataAuth0(tokenData) {
	return setUserData(tokenData);
}

export function setUserDataFromSavedToken() {
	return async dispatch => {

		try {
			const token = authentikService.getAccessToken();

			const options = {
				headers: {
					'Content-Type': 'application/json',
					"Authorization": `Bearer ${token}`
				}
			};

			const res = await axios.get(`${BASE_URL}/auth/check`, options);

			if (res.status === 200) {
				/*
					Set User Data
				*/
				dispatch({
					type: SET_USER_DATA,
					payload: res.data.data.user
				});
			}
		} catch (err) {
			if (!err.response) {
				toast.warn('Warning ⚠️ bad internet connection.');
				setTimeout(() => {
					window.location.pathname = '/authenticate'
				}, 1000)
			}
			else {
				localStorage.removeItem('access_token');
				localStorage.removeItem('id_token');
				localStorage.removeItem('expires_at');
				localStorage.removeItem('auth0.ssodata');
				localStorage.removeItem('authConfig');
			}
		}
	};
}

/**
 * Set User Data
 */
// export function setUserData(user) {
// 	return async dispatch => {

// 		// alert("Calling");
// 		try {
// 			const token = auth0Service.getAccessToken();

// 			const options = {
// 				headers: {
// 					'Content-Type': 'application/json',
// 					"Authorization": `Bearer ${token}`
// 				}
// 			};
// 			console.log("here222222")
// 			const domain = localStorage.website

// 			const res = await axios.post(`${BASE_URL}/auth/login`, {
// 				auth0User: user,
// 				domain
// 			}, options);

// 			if (res.status === 200) {
// 				dispatch({
// 					type: SET_USER_DATA,
// 					payload: res.data.data.user
// 				});
// 				if (token) {
// 					linkDomains();
// 				}
// 				window.location.pathname = '/dashboard'
// 			}
// 		} catch (err) {
// 			if (!err.response) {
// 				toast.warn('Warning ⚠️ bad internet connection.');
// 			}
// 			else {

// 				localStorage.removeItem('access_token');
// 				localStorage.removeItem('id_token');
// 				localStorage.removeItem('expires_at');
// 				localStorage.removeItem('auth0.ssodata');
// 				localStorage.removeItem('authConfig');
// 				cookies.remove('selectedDomain');
// 				cookies.remove('selectedDomainId');
// 				window.location.href = "/";
// 			}
// 		}
// 	};
// }

export function setUserData(user) {
	return async dispatch => {

		// console.log(user)
		// alert("Calling");
		try {
			console.log("User Data Access Token");
			console.log(user.access_token)
			const token = user.access_token

			const options = {
				headers: {
					'Content-Type': 'application/json',
					"Authorization": `Bearer ${token}`
				}
			};
			// console.log("here222222")
			const domain = localStorage.website
			// console.log("Domain")
			// console.log(domain)

			const res = await axios.post(`${BASE_URL}/auth/login`, {
				auth0User: user.user,
				domain
			}, options);

			if (res.status === 200) {
				// console.log("User Data")
				// console.log(res.data.data.user);
				// alert("calling")
				dispatch({
					type: SET_USER_DATA,
					payload: res.data.data.user
				});
				if (token) {
					// console.log("Linking Domains");
					// console.log(user.user.email);
					linkDomains(user.user.email);
				}
				window.location.pathname = '/dashboard'
			}
		} catch (err) {
			if (!err.response) {
				toast.warn('Warning ⚠️ bad internet connection.');
			}
			else {

				localStorage.removeItem('access_token');
				localStorage.removeItem('id_token');
				localStorage.removeItem('expires_at');
				localStorage.removeItem('auth0.ssodata');
				localStorage.removeItem('authConfig');
				cookies.remove('selectedDomain');
				cookies.remove('selectedDomainId');
				window.location.href = "/";
			}
		}
	};
}

export function setUserDataFromToken(user) {
	return async dispatch => {

		// alert("Calling");
		try {
			const token = authentikService.getAccessToken();

			const options = {
				headers: {
					'Content-Type': 'application/json',
					"Authorization": `Bearer ${token}`
				}
			};
			// const domain = localStorage.domain
			const domain = localStorage.website
			console.log("here11111")
			const res = await axios.post(`${BASE_URL}/auth/login`, {
				auth0User: user,
				domain
			}, options);

			if (res.status === 200) {
				// alert(res.data.data.user);
				/*
					Set User Data
				*/

				dispatch({
					type: SET_USER_DATA,
					payload: res.data.data.user
				});

				// history.push({
				// 	pathname: '/'
				// });
			}
		} catch (err) {
			// dispatch({
			//   type: LOGS_ERROR,
			//   payload: err.response.statusText,
			// });
			if (!err.response) {
				toast.warn('Warning ⚠️ bad internet connection.');
			}
		}
	};
}

/**
 * Update User Settings
 */
export function updateUserSettings(settings) {
	return (dispatch, getState) => {
		const oldUser = getState().auth.user;
		const user = _.merge({}, oldUser, { data: { settings } });
		updateUserData(user, dispatch);
		return dispatch(setUserData(user));
	};
}
/**
 * Remove User Data
 */
export function removeUserData() {
	return {
		type: REMOVE_USER_DATA
	};
}

/**
 * Logout
 */
// export function logoutUser() {
// 	// return (dispatch, getState) => {
// 	// 	const { user } = getState().auth;

// 	// 	if (!user.role || user.role.length === 0) {
// 	// 		// is guest
// 	// 		return null;
// 	// 	}

// 	// 	history.push({
// 	// 		pathname: '/authenticate'
// 	// 	});

// 	// 	auth0Service.logout();

// 	// 	// switch (user.from) {
// 	// 	// 	case 'auth0': {
// 	// 	// 		break;
// 	// 	// 	}
// 	// 	// 	default: {
// 	// 	// 		jwtService.logout();
// 	// 	// 	}
// 	// 	// }

// 	// 	return dispatch({
// 	// 		type: USER_LOGGED_OUT
// 	// 	});
// 	// };
// 	authentikService.logout();
// }

export function logoutUser() {
    return async (dispatch, getState) => {
        const { user } = getState().auth;

        if (!user.role || user.role.length === 0) {
            // Handle case for guests if needed
            return null;
        }

        try {
            // Call the logout method from Authentik service
            await authentikService.logout();

            // Redirect to the authentication page or login page
            // history.push('/authenticate'); // Adjust the redirect path as needed

            // Dispatch the logout action
            dispatch({
                type: USER_LOGGED_OUT
            });

        } catch (error) {
            console.error('Error during logout:', error);
            // Optionally handle logout failure, show an error message, etc.
        }
    };
}

/**
 * Update User Data
 */
export function updateUserData(user) {
	return async dispatch => {
		dispatch({
			type: LOADING_UPDATE,
		})
		try {
			const token = authentikService.getAccessToken();

			const options = {
				headers: {
					'Content-Type': 'application/json',
					"Authorization": `Bearer ${token}`
				}
			};

			const res = await axios.post(`${BASE_URL}/auth/edit-user`, { user }, options);

			if (res.status === 200) {
				history.push('/my-profile')
				dispatch({
					type: LOADING_UPDATE,
				})
			}
		} catch (err) {
			toast.error(err.response.data.message);

			dispatch({
				type: LOADING_UPDATE,
			})
		}
	}
}

/**
 * Update User Password
 */
export function updateUserPassword(email) {
	return dispatch =>
		auth0Service
			.changeUserPassword(email)
			.then(res => {

				// dispatch(UserActions.setUserData(user));

				// history.push({
				// 	pathname: '/setting'
				// });
				toast.success("Email Sent for reset password");
				return dispatch({
					type: USER_PASS_CHANGED
				});
			})
			.catch(error => {
				// return dispatch({
				// 	type: LOGIN_ERROR,
				// 	payload: error
				// });
			});
}

// old
// function linkDomains() {
// 	return async dispatch => {
// 		try {
// 			const userData = await auth0Service.getUserEmail();
// 			console.log(userData); // This will log the user email or resolved data
			
// 			const data = await dispatch(DomainsLink(userData.email));
			
// 			if (data) {
// 				console.log(data.message);
// 				// toast.success("Domains linked successfully");
// 			}
// 		} catch (error) {
// 			console.error('Error occurred:', error);
// 		}
// 	};
// }

// new 
function linkDomains(email) {
	return async dispatch => {
		try {
			
			const data = await dispatch(DomainsLink(email));
			
			if (data) {
				console.log(data.message);
				// toast.success("Domains linked successfully");
			}
		} catch (error) {
			console.error('Error occurred:', error);
		}
	};
}

