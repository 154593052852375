import React, { useEffect } from 'react';

const AnalyticsAndClarity = () => {
  useEffect(() => {
    const hostname = window.location.hostname;

    // Function to add a script to the document head
    const addScript = (src, innerHTML, async = true) => {
      const script = document.createElement('script');
      if (src) script.src = src;
      if (innerHTML) script.innerHTML = innerHTML;
      script.async = async;
      document.head.appendChild(script);
      return script;
    };

    let gtmScript, gtmConfigScript, clarityScript;

    if (hostname.includes('dashboard.allyright.com')) {
      // GTM for dashboard.allyright.com
      gtmScript = addScript("https://www.googletagmanager.com/gtag/js?id=G-TSS4RFCGBK");
      gtmConfigScript = addScript(null, `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-TSS4RFCGBK');
      `);

      // Clarity for dashboard.allyright.com
      clarityScript = addScript(null, `
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "mnmloo7bs7");
      `);

    } else if (hostname.includes('demo.allyright.com')) {
      // GTM for demo.allyright.com
      gtmScript = addScript("https://www.googletagmanager.com/gtag/js?id=G-3MTS24JYED");
      gtmConfigScript = addScript(null, `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-3MTS24JYED');
      `);

      // Clarity for demo.allyright.com
      clarityScript = addScript(null, `
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "mnmm41dzc0");
      `);
    }

    // Cleanup function
    return () => {
      if (gtmScript) document.head.removeChild(gtmScript);
      if (gtmConfigScript) document.head.removeChild(gtmConfigScript);
      if (clarityScript) document.head.removeChild(clarityScript);
    };
  }, []);

  return null;
};

export default AnalyticsAndClarity;
