
import auth0Service from "../../services/auth0Service/authentikService";
import axios from "axios";
import { BASE_URL } from "../../source/constants";
import { toast } from "react-toastify";

export const GET_TECHNOLOGIES = "GET_TECHNOLOGIES";

export const GET_DOMAIN_TECHNOLOGIES = "GET_DOMAIN_TECHNOLOGIES";

export const ADD_TECHNOLOGY_LOADING = "ADD_TECHNOLOGY_LOADING";
export const ADD_TECHNOLOGY_SUCCESS = "ADD_TECHNOLOGY_SUCCESS";

export const GET_CVES_SUCCESS = "GET_CVES_SUCCESS";
export const LOADING_CVESSS = "LOADING_CVESSS";
export const LOADING_CVESSS_FALSE = "LOADING_CVESSS_FALSE";


export const getCveTechnologies = (scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const params = {
      scanId
    }

    const res = await axios.get(`${BASE_URL}/dashboard/cve-technologies`, {
      params,
      ...options,
    });
    if (res.data) {
      dispatch({
        type: GET_TECHNOLOGIES,
        payload: res.data,
      });

    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};


export const getTechnologies = (scanId, cve) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
      cve
    }

    const res = await axios.get(`${BASE_URL}/exploits/techs`, {
      params,
      ...options,
    });
    if (res.data) {
      dispatch({
        type: GET_DOMAIN_TECHNOLOGIES,
        payload: res.data,
      });
      // console.log(res.data)
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};


export const addTechnology = (tech, scanId) => async (dispatch) => {

  dispatch({
    type: ADD_TECHNOLOGY_LOADING,
    payload: true
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(`${BASE_URL}/dashboard/cve-technologies`, {
      tech, scanId
    }, options);
    if (res.data) {
      dispatch(getCveTechnologies(scanId))
      dispatch({
        type: ADD_TECHNOLOGY_SUCCESS,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
      dispatch({
        type: ADD_TECHNOLOGY_LOADING,
        payload: false
      });
    }
    else {
      dispatch({
        type: ADD_TECHNOLOGY_LOADING,
        payload: false
      });
    }

  }
};


export const deleteTechnology = (tech, scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    if (tech.version === null) {
      tech.version = '';
    }

    const res = await axios.delete(`${BASE_URL}/exploits/tech?tech=${tech}&scanId=${scanId}`
      , options);

    if (res.data) {
      dispatch(getTechnologies(scanId, false))
      return res.data

    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    else {
      toast.warn(err.response.data.message);
    }

  }
};


export const getCVES = (technologies, page) => async (dispatch) => {

  dispatch({
    type: LOADING_CVESSS
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    technologies = technologies.map(i => {
      return {
        name: i.name,
        version: i.version
      }
    })

    const res = await axios.post(`${BASE_URL}/exploits/filter-cve`, {
      technologies, limit: 5, page: page ? page : 1
    }, options);
    if (res.data) {
      console.log(res.data);
      dispatch({
        type: GET_CVES_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (err) {
    dispatch({
      type: LOADING_CVESSS_FALSE
    });
  }
};


export const addDomainTechnology = (data) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(`${BASE_URL}/dashboard/domain-technologies`, {
      ...data
    }, options);
    if (res.data) {
      dispatch(getTechnologies(data.scanId, false))
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    else {
      toast.error(err.response.data.message)
    }

  }
};