import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from "@material-ui/core/Snackbar";
import Button from '@material-ui/core/Button';
import mastercard from '../../assets/creditcards/master.png';
import visa from '../../assets/creditcards/visa.jpg';
import american from '../../assets/creditcards/american.jpg';
import jcb from '../../assets/creditcards/jcb.png';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { CardContent } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import { upgradePackage } from "../../store/actions/changePackageActions";
import Box from '@material-ui/core/Box';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NumericInput from 'react-numeric-input';
import CircularProgress from '@material-ui/core/CircularProgress';
import websitesAndUsersDowngradeStyles from '../../Styles/jss/dialogs/websitesAndUsersDowngradeStyles';

export default function DownGradeUsers({ onClose, open }) {

  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(null);
  const [usersToAdd, setUsersToAdd] = React.useState(0);

  const dispatch = useDispatch();

  const stripeCards = useSelector(state => state.changePackage.stripeCards);
  const loading = useSelector(state => state.changePackage.loadingSubscribe);

  const [snackBarOpen, setSnackBarOpen] = React.useState({
    openSnack: false,
    vertical: "bottom",
    horizontal: "left",
  });

  const { vertical, horizontal, openSnack } = snackBarOpen;

  const handleCloseSnackBar = () => {
    setSnackBarOpen({ ...snackBarOpen, openSnack: false });
  };

  function handleMethodChecked(e, item) {
    setSelectedPaymentMethod({ last4: item.last4, id: item.id });
  }

  function submit() {
    dispatch(upgradePackage(usersToAdd, selectedPaymentMethod))
  };

  const iconStyle = {
    fontSize: '1.5em',
    color: "#7B8184"
  };

  const useStyles = makeStyles(websitesAndUsersDowngradeStyles);
  const classes = useStyles();

  React.useEffect(() => {
    if (stripeCards) {
      setSelectedPaymentMethod({ last4: stripeCards[0].last4, id: stripeCards[0].id });
    }
    // eslint-disable-next-line
  }, [stripeCards]);

  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle id="form-dialog-title">Upgrade Users</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" my={2}>   <h4>Want To Add Users</h4>  <NumericInput mobile="auto" strict inputMode="numeric" min={0} max={50} value={usersToAdd} onChange={(valueAsNumber) => setUsersToAdd(valueAsNumber)} style={{
            input: {
              maxWidth: "80px",
              marginLeft: "10px"
            }
          }} /></Box>
          <Grid container >
            <Grid item xs={12} lg={12}>
              <Card style={{ height: "100%", boxShadow: "0px 3px 25px rgba(0 0 0 / 15%)", borderRadius: "2%" }}>
                <CardContent>
                  <Box display="flex" alignItems="center" mb={1.5} p={0.5}>
                    <PhoneIphoneIcon style={iconStyle} />
                    <span>Accounts</span>
                  </Box>
                  <TableContainer >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: 300 }} className={classes.tableHead} >Card/Bank Account No.</TableCell>
                          <TableCell className={classes.tableHead} style={{ width: 120 }} >Expiry</TableCell>
                          <TableCell style={{ width: 80 }} ></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {stripeCards && stripeCards.map((item, index) => (
                          <TableRow key={index} >
                            <TableCell component="th" scope="row" >
                              <Box display="flex" alignItems="center" style={{ width: 300 }}>
                                {item.brand === "Visa" && <img width="64" height="30" src={visa} alt="visa" />}
                                {item.brand === "Mastercard" && <img width="64" height="30" src={mastercard} alt="mastercard" />}
                                {item.brand === "Amex" && <img width="64" height="30" src={american} alt="American Express" />}
                                {item.brand === "Jcb" && <img width="64" height="30" src={jcb} alt="JCB" />}
                                <span style={{ marginLeft: "1rem", fontSize: "1.3em" }} >{`xxxx-xxxx xxxx ${item.last4}`} </span> </Box>
                            </TableCell>
                            <TableCell >{`${item.exp_month}/${item.exp_year}`}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >
                              <label className="containerCheckbox">
                                <input type="radio" name="radio" defaultChecked={index === 0 ? true : false} onChange={(e) => handleMethodChecked(e, item)} />
                                <span className="checkmark"></span>
                              </label>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" alignItems="center" my={2}>
            <Button variant="contained" color="primary" disabled={loading || usersToAdd === 0 || !selectedPaymentMethod} onClick={() => submit(selectedPaymentMethod, usersToAdd)} >
              upgrade {loading && <CircularProgress style={{ color: "black", marginLeft: "1rem" }} size={20} />}
            </Button>
          </Box>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openSnack}
        onClose={handleCloseSnackBar}
        message="Column Name cannot be Empty"
      />
    </>
  );
}