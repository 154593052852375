import {
  CHECKLIST_LOADING,
  GET_CHECKLIST,
  DOMAIN_CHECKLIST_LOADING,
  GET_DOMAIN_CHECKLIST
} from '../actions/checklistActions';

const initialState = {
  lists: [],
  loading: false,
  type: "main",

  domain_lists: [],
  domain_loading: false,
  domain_type: "main"
};

const checklistReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECKLIST_LOADING:
      return {
        ...state,
        loading: !state.loading
      }
    case GET_CHECKLIST:
      return {
        ...state,
        lists: action.payload.checkLists,
        type: action.payload.type,
        loading: false
      }

      case DOMAIN_CHECKLIST_LOADING:
      return {
        ...state,
        loading: !state.loading
      }
    case GET_DOMAIN_CHECKLIST:
      return {
        ...state,
        domain_lists: action.payload.checkLists,
        domain_type: action.payload.type,
        domain_loading: false
      }
    default:
      return state;
  }
};
export default checklistReducer;