import {
  GET_PRIVACY_RESULTS,
} from '../actions/webPrivacyActions';

const initialState = {
  privacies: null,
  status: "pending"
};

const webPrivacyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRIVACY_RESULTS:
      return {
        ...state,
        privacies: action.payload.privacy ? action.payload.privacy : null,
        status: action.payload.status
      }
    default:
      return state;
  }
};
export default webPrivacyReducer;