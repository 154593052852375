function dateFormatter(date){
  let month = date.getMonth()+1;
  let dt = date.getDate();

  let hours = date.getHours();
  let minutes = date.getMinutes();    

  if (dt < 10) {
    dt = '0' + dt;
  }
  if (month < 10) {
    month = '0' + month;
  }

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }

return `${month}-${dt} ${hours}:${minutes}`


}

export {
  dateFormatter
}