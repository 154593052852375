import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import {
  Popover,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import MuiTypography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { CardContent } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import financialStyles from "../../Styles/jss/financialStyles";
import { unsubscribeDomain, unsubscribeReason } from "../../store/actions/onBoardingActions";
import { CircularProgress } from "@material-ui/core";
import { Height } from "@material-ui/icons";

import CancelIcon from "@material-ui/icons/Cancel";
import { toast } from "react-toastify";
import NextPacakge from "./Packages/NextPackage";
import CustomizePackage from "./Packages/CustomizePackage";
import History from "../../@history";

const useStyles = makeStyles(financialStyles);

export default function FinancialsRegisteredUsers() {
  //Dialog control
  const [dialogUnsubscribe, setDialogUnsubcribe] = useState(false);
  //checkbox control
  const [checked, setChecked] = useState(true);
  //Reason Text
  const [reasonText, setReasontext] = useState("Enter Reason Here....");

  const [checkboxIntention, setCheckboxIntention] = useState(false);

  const classes = useStyles();

  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);

  const selectedPackage = useSelector((state) => state.dashboard.domainPackage);

  const domainId = useSelector((state) => state.dashboard.selectedDomainId);
  const domains = useSelector((state) => state.dashboard.domains);

  const isDomainPurchased = useSelector(
    (state) => state.dashboard.isDomainPackagePurchased
  );

  const [showNext, setShowNext] = React.useState(false);
  const [showCustom, setShowCustom] = React.useState(false);

  const freeBilling = domains.filter(dm => dm.domainId === domainId)[0]?.freeBilling

  async function unsubscribe(e) {
    e.preventDefault();
    if (reasonText.length < 100) {
      toast.error("Reason should be of minimum 100 characters.")

    }
    else {
      setLoading(true);
      dispatch(unsubscribeReason({
        domainId, reason: {
          reason: reasonText,
          reSubscribe: checkboxIntention
        }
      })).then(data => {
        if (data) {
          dispatch(unsubscribeDomain(domainId)).then((data) => {
            if (data) {
            } else {
              setDialogUnsubcribe(!dialogUnsubscribe);
              setLoading(false);

            }
          });
        } else {
          setDialogUnsubcribe(!dialogUnsubscribe);
          setLoading(false);


        }
      })
    }
  }
  if (!isDomainPurchased) {
    return History.push('/dashboard')
  } else {
    return (
      <>
        <Box>
          {selectedPackage ? (
            <Box
              style={{
                width: "100%",
                maxWidth: showCustom ? '100%' : 500,
                margin: "auto",
                textAlign: "center",
                marginTop: 50
              }}
            >
              {!showNext && !showCustom && <Card
                className={classes.packageCard}
                style={{ display: dialogUnsubscribe ? "none" : "block" }}
              >
                <CardHeader
                  title={"Current Plan"}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  className={classes.cardHeader}
                  style={{ display: dialogUnsubscribe ? "none" : "block" }}
                />
                <CardContent>
                  <Box>
                    {selectedPackage.price && <div className={classes.cardPricing}>
                      <MuiTypography
                        component="h2"
                        variant="h4"
                        color="textPrimary"
                      >
                        ${selectedPackage.price}
                      </MuiTypography>
                      <MuiTypography variant="h6" >
                        /{selectedPackage.tenure}
                      </MuiTypography>
                    </div>}
                    <Box
                      my={1}
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <p className={classes.listPackageMain}>Number of Pages</p>
                      <p className={classes.listPackageDetail}>
                        {`${selectedPackage.rangeOfPages.pageRange} ($${selectedPackage.rangeOfPages.price})`}{" "}
                      </p>
                    </Box>
                    <Box
                      my={1}
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <p className={classes.listPackageMain}>Number of Users</p>
                      <p className={classes.listPackageDetail}>
                        {`${selectedPackage.rangeOfUsers.userRange} ($${selectedPackage.rangeOfUsers.price})`}{" "}
                      </p>
                    </Box>
                    <p className={classes.listPackageMain}>Resolutions</p>

                    {selectedPackage.resolutions.resolutions.map((reso, i) => {
                      return (
                        <p
                          className={`${classes.listPackageDetail} animate`}
                          key={i}
                        >
                          {" "}
                          {reso.category +
                            `(${reso.resolution}) (+${reso.price}$)`}
                        </p>
                      );
                    })}
                    {/* {freeBilling && <Typography style={{ marginTop: 20 }} variant="h6">This is a Free Domain</Typography>} */}
                    <Box display='flex' alignItems='center' justifyContent='center' pt={3} pb={1}>
                      <Button disabled={freeBilling} style={{ marginLeft: 10, width: 155 }} color='primary' variant='contained' onClick={() => setShowNext(true)}>Upgrade Package</Button>
                      <Button disabled={freeBilling} style={{ marginLeft: 10, width: 155 }} color='primary' variant='contained'
                        onClick={() => setShowCustom(true)}
                      >Customize Package</Button>
                    </Box>
                    <Box display='flex' alignItems='center' justifyContent='center' pt={1} pb={1}>
                      <Button
                        color="secondary"
                        variant="contained"
                        disabled={loading || freeBilling}
                        onClick={() => {
                          setDialogUnsubcribe(!dialogUnsubscribe);
                        }}
                      >
                        Unsubscribe
                        {loading && (
                          <CircularProgress
                            style={{ color: "black", marginLeft: "1rem" }}
                            size={20}
                          />
                        )}
                      </Button>
                    </Box>
                  </Box>
                </CardContent>
              </Card>}
              {showNext && <NextPacakge setShowNext={setShowNext} />}
              {showCustom && <CustomizePackage setShowCustom={setShowCustom} />}

              <Dialog open={dialogUnsubscribe} fullWidth={true} maxWidth='sm'>
                <DialogTitle>
                  <MuiTypography
                    component="h3"
                    variant="h5"
                    color="textPrimary"
                  >
                    Why you want to unsubscribe?
                  </MuiTypography>
                </DialogTitle>
                <Box
                  style={{
                    width: "100%",
                    margin: "auto",
                    textAlign: "center",

                  }}
                  onSubmit={unsubscribe}
                  component='form'
                >
                  <Card className={classes.packageCard}>
                    <CardContent>
                      {/* <div
                      className={classes.cardPricing}
                      style={{ marginTop: 30 }}
                    >
                      <MuiTypography
                        component="h3"
                        variant="h5"
                        color="textPrimary"
                      >
                        Why you want to unsubscribe?
                      </MuiTypography>
                    </div> */}

                      <Box
                        my={1}
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        p={2}
                      >
                        <TextField
                          InputProps={{
                            inputBase: {
                              paddingTop: 15
                            }
                          }}
                          required={true}
                          multiline
                          variant="outlined"
                          rows={4}
                          fullWidth
                          placeholder={"Enter Value here... "}
                          value={reasonText}
                          onChange={(event) => {
                            setReasontext(event.target.value);
                          }}
                        />
                      </Box>
                      <Box
                        my={1}
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        marginBottom="25px"
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={loading}
                                checked={checkboxIntention}
                                onChange={(e) => {
                                  setCheckboxIntention(e.target.checked);
                                }}
                              />
                            }
                            label="If issue is resolved, would you like to subscribe again?"
                          />
                        </FormGroup>
                      </Box>
                      <Button
                        type='submit'
                        color="primary"
                        variant="contained"
                        disabled={loading}
                      >
                        Submit and Unsubscribe
                        {loading && (
                          <CircularProgress
                            style={{ color: "black", marginLeft: "1rem" }}
                            size={20}
                          />
                        )}
                      </Button>
                      <IconButton
                        style={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          color: "#eb4d53",
                        }}
                        disableRipple={true}
                        onClick={() => {
                          setDialogUnsubcribe(false);
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </CardContent>
                  </Card>
                </Box>
              </Dialog>
            </Box>
          ) : (
            <h3>Access Denied</h3>
          )}
        </Box>
      </>
    );
  }
}