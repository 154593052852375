import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Button, Card, CircularProgress, TextField, Typography } from '@material-ui/core';
import { useDispatch } from "react-redux";
import { addFreeDomain, getAllUnsubDomains } from "../../store/actions/adminScansManagementActions";
import { getAllAccounts} from "../../store/actions/adminScansManagementActions";

export default function Accounts() {

	const [loadingAccounts, setLoadingAccounts] = React.useState(true);
	const [data, setData] = React.useState([]);

	const dispatch = useDispatch();

	function getDomains() {
		dispatch(getAllAccounts()).then(data => {
			if (data) {
				setLoadingAccounts(false);
				setData(data.result);
				console.log(data)
			} else {
				setLoadingAccounts(false);
			}
		}).catch(err => {
			setLoadingAccounts(false);
		});
	}

	React.useEffect(() => {
		getDomains();
	}, []);



	return (
		<div style={{ overflowX: "auto" }}>
			<TableContainer component={Paper}>

				<Table aria-label="simple table" style={{ tableLayout: 'fixed' }}>
					<TableHead>
						<TableRow>
							<TableCell>Name</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Verified</TableCell>
							<TableCell>Created At</TableCell>
						</TableRow>
					</TableHead>
					{!loadingAccounts ? (
						<TableBody>
							{data.length > 0 ? data.map((row, index) => (
								<TableRow key={index}>
									<TableCell component="th" scope="row">
										{row.name}
									</TableCell>
									<TableCell style={{ wordBreak: 'break-all' }}>{row.email}</TableCell>
									<TableCell style={{ wordBreak: 'break-all' }}>{row.email_verified.toString()}</TableCell>
									<TableCell style={{ wordBreak: 'break-all' }}>{new Date(row.createdAt).toDateString()}</TableCell>
								</TableRow>
							)) :
								<TableRow><TableCell colSpan={5}>no records found</TableCell></TableRow>
							}
						</TableBody>
					) :
						<TableBody>
							<TableRow><TableCell align='center' colSpan={5}>
								<CircularProgress />
							</TableCell></TableRow>
						</TableBody>
					}
				</Table>

			</TableContainer>
		</div>
	);
}