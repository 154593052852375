import OnBoarding from "./pages/OnBoarding";

import Maintenance from "./pages/Maintenance";
import MyProfile from "./pages/MyProfile";
import CustomScanPageList from "./pages/CustomScanPageList";
import Financials from "./pages/Financials";
import PaymentMethodsList from "./pages/PaymentMethodsList";
import AddPayment from "./pages/AddPayment";
import HelpAndSupport from "./pages/HelpAndSupport";
// import ScanPage from './pages/ScanPage';
import Chat from "./pages/Chat";
import ScrumBoard from "./pages/ScrumBoard";
import IssueTracker from "./pages/IssueTracker";
import CodeSummary from "./pages/CodeSummary";
import AccessibilityReport from "./pages/AccessibilityReport";
import SecurityReport from "./pages/SecurityReport";
import SpellCheckReport from "./pages/SpellCheckReport";
import FileTypesReport from "./pages/FileTypesReport";
import PageSpeedReport from "./pages/PageSpeedReport";

import LinkSelectionViolationReport from "./pages/LinkSelectionViolationReport";
import AddIssue from "./pages/AddIssue";
import UserPage from "./pages/UserPage";
import ActivityLogs from "./pages/ActivityLogs";
import Technologies from "./pages/Technologies";
import AdminPluginInfo from "./pages/AdminPluginInfo"
// import CookieChecker from "./pages/CookieChecker";
import CVE from "./pages/cve/MainApp";
// import Teams from "./pages/Teams";
import AdminScansManagement from "./pages/AdminScansManagement";
import AdminUserManagement from "./pages/AdminUserManagement";
import AdminWebsitesManagementDemo from "./pages/AdminWebsitesManagementDemo";
import AdminWebsitesManagementProduction from "./pages/AdminWebsitesManagementProduction";
import AdminImagesLinks from "./pages/AdminImagesLinks";
import AdminCopyrightYear from "./pages/AdminCopyrightYear";
import AdminPrivacyPage from "./pages/AdminPrivacyPage";
import AdminSkipToContent from "./pages/AdminSkipToContent"
import AccessibilityStatement from "./pages/AdminAccessibilityStatement"
import TechnologiesInfo from "./pages/AdminTechnologies"
import AdminPluginAndTheme from "./pages/AdminPluginAndThemes"
import AdminModifiedLogs from "./pages/AdminModifiedLogs"
import AdminPluginLogs from "./pages/AdminPluginLogs"
import ContactInfo from "./pages/AdminContactInfo";
import AdminFooterInfo from "./pages/AdminFooterInfo"
import AdminSummary from "./pages/AdminSummary"
import AdminRescan from "./pages/AdminRescan"
import AdminAccessibilityElements from "./pages/AdminAccessibilityElements"
import AdminAccessibilityIssues from "./pages/AdminAccessibilityIssues"
import AbracadabraProductSchemas from "./pages/AbracadabraProductSchemas"
import AbracadabraFaqSchemas from "./pages/AbracadabraFaqSchemas"
import AbracadabraNewsSchemas from "./pages/AbracadabraNewsSchemas"
import AbracadabraArticleSchemas from "./pages/AbracadabraArticleSchemas"
import AbracadabraBlogSchemas from "./pages/AbracadabraBlogSchemas"
import AbracadabraReviewSchemas from "./pages/AbracadabraReviewSchemas"
import AbracadabraMapSchemas from "./pages/AbracadabraMapSchemas"
import AbracadabraVideoSchemas from "./pages/AbracadabraVideoSchemas"
import AbracadabraImagesSchemas from "./pages/AbracadabraImagesSchemas"
import AbracadabraWpSecurity from "./pages/AbracadabraWpSecurity"
// import ClickUpTracker from "./pages/ClickUpTracker";
import BasicScan from "./pages/BasicScan";
import AdvanceScan from "./pages/AdvanceScan";
import DomainSettings from "./pages/DomainSettings";
// import LanguageServices from "./pages/LanguageServices";
import ScansDeletion from "./pages/CustomScanComponents/ScansDeletion";
import Scans from "./pages/Scans";

import CheckList from "./pages/CheckLists";
import DomainCheckLists from "./pages/DomainCheckLists";
import AccessibilitySheet from "./pages/sheets/AccessibilitySheet";
import PerformanceSheet from "./pages/sheets/PerformanceSheet";
import PrivacySheet from "./pages/sheets/PrivacySheet";
import SecuritySheet from "./pages/sheets/SecuritySheet";
import Analytics from "./pages/sheets/Analytics";

// Dashboard
import AccessibilityDashboard from "./components/AccessibilityComponents/AccessibilityDashboard";
import SecurityDashboard from "./components/CyberSecurityComponents/SecurityDashboard";
import WebPrivacy from "./pages/WebPrivacy";
import HomePage from "./pages/HomePage";
import SeoReport from "./pages/SeoReport";

// Issue Tracker
import IssuesLists from "./pages/IssueTracker/Lists";
import IssuesBoards from "./pages/IssueTracker/Boards";
import ServiceDesks from "./pages/IssueTracker/ServiceDesks";
import Milestones from "./pages/IssueTracker/Milestones";
import NewIssue from "./pages/IssueTracker/NewIssue";
import IssueView from "./pages/IssueTracker/IssueView/Index";
import NewMilestone from "./pages/IssueTracker/NewMilestone";

import ViewMilestone from "./pages/IssueTracker/ViewMilestone/ViewMilestone";
import AllDomains from "./pages/AllDomains";

import CcpaRequests from "./pages/ccpa-requests/CCPA";
import CookieScript from "./pages/ccpa-requests/CookieScripts";
import AddCcpaRequest from "./pages/ccpa-request-forms/Index"
import PrivacyForm from "./pages/privacy-policy/PrivacyForm"

const dashboardRoutes = [
  {
    path: "/",
    component: HomePage,
    exact: true,
  },
  {
    path: "/dashboard",
    component: HomePage,
    exact: true,
  },
  {
    path: "/dashboard/domains",
    component: AllDomains,
    exact: true,
  },
  // {
  //   path: "/issue-tracker/analytics",
  //   component: Analytics,
  //   exact: true,
  // },
  {
    path: "/issue-tracker/accessibility",
    component: AccessibilitySheet,
    exact: true,
  },
  {
    path: "/issue-tracker/performance",
    component: PerformanceSheet,
    exact: true,
  },
  {
    path: "/issue-tracker/security",
    component: SecuritySheet,
    exact: true,
  },
  {
    path: "/issue-tracker/privacy",
    component: PrivacySheet,
    exact: true,
  },
  {
    path: "/account-setup",
    component: OnBoarding,
    exact: true,
  },
  {
    path: "/my-profile",
    component: MyProfile,
    exact: true,
  },
  {
    path: "/custom-scan-page-list",
    component: CustomScanPageList,
    exact: true,
  },
  {
    path: "/billing",
    component: Financials,
    exact: true,
  },
  {
    path: "/payment-methods",
    component: PaymentMethodsList,
    exact: true,
  },
  {
    path: "/add-payment",
    component: AddPayment,
    exact: true,
  },
  {
    path: "/help-and-support",
    component: HelpAndSupport,
    exact: true,
  },
  {
    path: "/scan-page",
    component: CustomScanPageList,
    exact: true,
  },
  {
    path: "/chat",
    component: Chat,
    exact: true,
  },
  {
    path: "/scrumboard",
    component: ScrumBoard,
    exact: true,
  },
  {
    path: "/dashboard/accessibility",
    component: AccessibilityDashboard,
    exact: true,
  },
  {
    path: "/dashboard/performance",
    component: PageSpeedReport,
    exact: true,
  },
  {
    path: "/dashboard/seo",
    component: SeoReport,
    exact: true,
  },
  {
    path: "/dashboard/security",
    component: SecurityDashboard,
    exact: true,
  },
  {
    path: "/violation-report/accessibility",
    component: AccessibilityReport,
    exact: true,
  },
  {
    path: "/violation-report/security",
    component: SecurityReport,
    exact: true,
  },
  {
    path: "/violation-report/spell-check",
    component: SpellCheckReport,
    exact: true,
  },
  {
    path: "/violation-report/filetypes",
    component: FileTypesReport,
    exact: true,
  },
  {
    path: "/violation-report/pagespeed",
    component: PageSpeedReport,
    exact: true,
  },
  {
    path: "/violation-reports/link",
    component: LinkSelectionViolationReport,
    exact: true,
  },
  {
    path: "/issue-tracker",
    component: IssueTracker,
    exact: true,
  },
  {
    path: "/issue-tracker/add-new-issue",
    component: AddIssue,
    exact: true,
  },
  {
    path: "/under-maintenance",
    component: Maintenance,
    exact: true,
  },
  {
    path: "/code-summary",
    component: CodeSummary,
    exact: true,
  },
  {
    path: "/users",
    component: UserPage,
    exact: true,
  },
  {
    path: "/activity-logs",
    component: ActivityLogs,
    exact: true,
  },
  {
    path: "/technologies",
    component: Technologies,
    exact: true,
  },
  {
    path: "/basic-scan",
    component: BasicScan,
    exact: true,
  },
  {
    path: "/advance-scan",
    component: AdvanceScan,
    exact: true,
  },
  {
    path: "/technologies",
    component: Technologies,
    exact: true,
  },
  {
    path: "/domain-settings",
    component: DomainSettings,
    exact: true,
  },
  {
    path: "/language-services",
    component: DomainSettings,
    exact: true,
  },
  {
    path: "/dashboard/web-privacy",
    component: WebPrivacy,
    exact: true,
  },
  {
    path: "/scans",
    component: Scans,
    exact: true,
  },
  {
    path: "/scans-data",
    component: ScansDeletion,
    exact: true,
  },
  {
    path: "/checklist",
    component: CheckList,
    exact: true,
  },
  {
    path: "/domain-checklists",
    component: DomainCheckLists,
    exact: true,
  },
  {
    path: "/issue-tracker/issues",
    component: IssuesLists,
    exact: true,
  },
  {
    path: "/issue-tracker/new-issue/:listId",
    component: NewIssue,
    exact: true,
  },
  {
    path: "/issue-tracker/new-milestone/:listId",
    component: NewMilestone,
    exact: true,
  },
  {
    path: "/issue-tracker/issue/:issueId",
    component: IssueView,
    exact: true,
  },
  {
    path: "/issue-tracker/boards",
    component: IssuesBoards,
    exact: true,
  },
  {
    path: "/issue-tracker/service-desks",
    component: ServiceDesks,
    exact: true,
  },
  {
    path: "/issue-tracker/milestone/:milestoneId",
    component: ViewMilestone,
    exact: true,
  },
  {
    path: "/issue-tracker/milestones",
    component: Milestones,
    exact: true,
  },
  {
    path: "/cve",
    component: CVE,
    exact: true,
  },
  {
    path: "/admin/manage-scans",
    component: AdminScansManagement,
    exact: true,
  },
  {
    path: "/admin/manage-users",
    component: AdminUserManagement,
    exact: true,
  },
  {
    path: "/admin/manage-websites-production",
    component: AdminWebsitesManagementProduction,
    exact: true,
  },
  {
    path: "/admin/manage-websites-demo",
    component: AdminWebsitesManagementDemo,
    exact: true,
  },
  {
    path: "/allyright-ai/analytics",
    component: AdminCopyrightYear,
    exact: true,
  },
  {
    path: "/admin/privacy-page/:keyCode",
    component: AdminPrivacyPage,
    exact: true,
  },
  {
    path: "/admin/skip-to-content/:keyCode",
    component: AdminSkipToContent,
    exact: true,
  },
  {
    path: "/admin/accessibility-statement/:keyCode",
    component: AccessibilityStatement,
    exact: true,
  },
  {
    path: "/allyright-ai/technologies-info",
    component: TechnologiesInfo,
    exact: true,
  },
  {
    path: "/website-image-links",
    component: AdminImagesLinks,
    exact: true,
  },
  {
    path: "/wordpress/plugin-info",
    component: AdminPluginInfo,
    exact: true,
  },
  {
    path: "/wordpress/plugin-theme-info",
    component: AdminPluginAndTheme,
    exact: true,
  },
  {
    path: "/allyright-ai/modiefied-pages-log",
    component: AdminModifiedLogs,
    exact: true,
  },
  {
    path: "/wordpress/plugin-modified-log",
    component: AdminPluginLogs,
    exact: true,
  },
  {
    path: "/wordpress/contact-info",
    component: ContactInfo,
    exact: true,
  },
  {
    path: "/admin/footer-info/:keyCode",
    component: AdminFooterInfo,
    exact: true,
  },
  // {
  //   path: "/allyright-ai/summary",
  //   component: AdminSummary,
  //   exact: true,
  // },
  {
    path: "/allyright-ai/rescan-website",
    component: AdminRescan,
    exact: true,
  }, 
  {
    path: "/admin/website-element-fixes/:keyCode",
    component: AdminAccessibilityElements,
    exact: true,
  },
  // {
  //   path: "/allyright-ai/accessibility-issues",
  //   component: AdminAccessibilityIssues,
  //   exact: true,
  // }, 
  {
    path: "/schemas/product-schemas-ai",
    component: AbracadabraProductSchemas,
    exact: true,
  },
  {
    path: "/schemas/faq-schemas-ai",
    component: AbracadabraFaqSchemas,
    exact: true,
  },
  {
    path: "/schemas/news-schemas-ai",
    component: AbracadabraNewsSchemas,
    exact: true,
  },
  {
    path: "/schemas/article-schemas-ai",
    component: AbracadabraArticleSchemas,
    exact: true,
  },
  {
    path: "/schemas/blog-schemas-ai",
    component: AbracadabraBlogSchemas,
    exact: true,
  },
  {
    path: "/schemas/review-schemas-ai",
    component: AbracadabraReviewSchemas,
    exact: true,
  },
  {
    path: "/schemas/map-schemas-ai",
    component: AbracadabraMapSchemas,
    exact: true,
  },
  {
    path: "/schemas/video-schemas-ai",
    component: AbracadabraVideoSchemas,
    exact: true,
  },
  {
    path: "/schemas/images-schemas-ai",
    component: AbracadabraImagesSchemas,
    exact: true,
  },
  {
    path: "/security/wp-security",
    component: AbracadabraWpSecurity,
    exact: true,
  },
  // {
  //   path: "/ccpa/compliance-requests",
  //   component: CcpaRequests,
  //   exact: true,
  // },
  {
    path: "/cookie-script",
    component: CookieScript,
    exact: true,
  },
  {
    path: "/ccpa/add-request",
    component: AddCcpaRequest,
    exact: true,
  },
  {
    path: "/privacy-info",
    component: PrivacyForm,
    exact: true,
  }
  // {
  //   path: "/manage-teams",
  //   component: Teams,
  //   exact: true
  // },
  // {
  //   path: "/new-tracker",
  //   component: ClickUpTracker,
  //   exact: true
  // },
];

export default dashboardRoutes;
