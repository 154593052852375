import React from 'react';

export default function BoxIcon({ type, width = 24, height = 24 }) {
	// let color = "#1F3A93";
	function getColor(type) {
		let color = "#1F3A93";
		if (type === "success") {
			color = "#3AAA35"
		}
		if (type === "primary") {
			color = "#1F3A93"
		}
		if (type === "warning") {
			color = "#FFA412"
		}
		if (type === "danger") {
			color = "#E3211F"
		}
		if (type === "maroon") {
			color = "#96281B"
		}
		if (type === "yellow") {
			color = "#FFD800"
		}
		if (type === "brown") {
			color = "#776446"
		}
		if (type === "blue") {
			color = "#1f3a93"
		}
		if (type === "green") {
			color = "#21CA5A"
		}
		if (type === "red") {
			color = "#E2211E"
		}

		return color;
	}
	const color = getColor(type);

	return (

		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={`${width}`}
			height={`${height}`}
			fill={color}
			viewBox="0 0 24 24"
		>
			<path d="M19.2 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4.8c0-.99-.81-1.8-1.8-1.8z" /></svg>
	);
}


