import React from "react";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box } from "@material-ui/core";
import {
  getDomainEvaluation,
} from "../../store/actions/customScanPageListActions";

export default function EvaluatingDomain({ setActiveStep }) {

  const  domainEvalutionStatus  = useSelector(
    (state) => state.customScanList.domainEvalutionStatus
  );

  const scanId = useSelector((state) => state.miscellaneous.selectedScanManagerId);

  const dispatch = useDispatch();

  React.useEffect(() => {

    if (
      domainEvalutionStatus === "pending" ||
      domainEvalutionStatus === "running"
    ) {
      const interval1 = setInterval(() => {
        dispatch(getDomainEvaluation(scanId)).then((data) => {
          if (data) {
            if (data.status === "completed") {
              setActiveStep(1)
            }
          }
        }
        );
      }, 5000);
      return () => clearInterval(interval1);
    }
    // eslint-disable-next-line
  }, [scanId, domainEvalutionStatus]);


  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <CircularProgress size={60} />
      <h4>Evaluation in Progress</h4>
    </Box>
  );
}
