import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const CustomTooltip = ({ placement, title, children }) => {
  return (
    <LightTooltip placement={placement || "bottom"} arrow interactive title={title} >
      <span>
        {children}
      </span>
    </LightTooltip>
  );
};

export default CustomTooltip;

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    padding: '10px 14px',
    backgroundColor: 'rgba(255, 253, 253, 1)',
    opacity: 1, border: '1px solid #585858',
    color: '#000000',
    borderRadius: 10,
    boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
    fontSize: 12,
  },
  arrow: {
    color: '#FFFDFD',
    "&:before": {
      border: "1px solid #585858"
    },
  }
}))(Tooltip);