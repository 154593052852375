 
import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { toast } from "react-toastify";


export const GET_PAGESPEED = 'GET_PAGESPEED';
export const GET_PAGESPEED_LOADING = 'GET_PAGESPEED_LOADING';

export const HTML_SUMMARY = 'HTML_SUMMARY';
export const HTML_SUMMARY_LOADING = 'HTML_SUMMARY_LOADING';

export const HTML_HISTORY = 'HTML_HISTORY';
export const HTML_HISTORY_LOADING = 'HTML_HISTORY_LOADING';

export const CSS_SUMMARY = 'CSS_SUMMARY';
export const CSS_SUMMARY_LOADING = 'CSS_SUMMARY_LOADING';

export const CSS_HISTORY = 'CSS_HISTORY';
export const CSS_HISTORY_LOADING = 'CSS_HISTORY_LOADING';

export const PERFORMANCE_VISUALIZATION = 'PERFORMANCE_VISUALIZATION';
export const PERFORMANCE_VISUALIZATION_LOADING = 'PERFORMANCE_VISUALIZATION_LOADING';

export const PERFORMANCE_ANALYTICS = 'PERFORMANCE_ANALYTICS';
export const PERFORMANCE_ANALYTICS_LOADING = 'PERFORMANCE_ANALYTICS_LOADING';

export const PERFORMANCE_STRUCTURE = 'PERFORMANCE_STRUCTURE';
export const PERFORMANCE_STRUCTURE_LOADING = 'PERFORMANCE_STRUCTURE_LOADING';

export const PERFORMANCE_WATERFALL = 'PERFORMANCE_WATERFALL';
export const PERFORMANCE_WATERFALL_LOADING = 'PERFORMANCE_WATERFALL_LOADING';


export const getPageSpeedCardDetails = (scanId) => async (dispatch) => {
  dispatch({
    type: GET_PAGESPEED_LOADING,
  });

  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }

    const res = await axios.get(`${BASE_URL}/dashboard/pagespeed-result`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_PAGESPEED,
        payload: res.data
      });
    }
  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}


export const getHtmlData = (scanId) => async (dispatch) => {
  dispatch({
    type: HTML_SUMMARY_LOADING,
  });

  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }

    const res = await axios.get(`${BASE_URL}/dashboard/html-summary`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: HTML_SUMMARY,
        payload: res.data
      });
    }
  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}

export const getHtmlHistoryData = (dateNowRange, scanId) => async (dispatch) => {
  dispatch({
    type: HTML_HISTORY_LOADING,
  });

  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId, dateNowRange
    }

    const res = await axios.get(`${BASE_URL}/history/html-history`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: HTML_HISTORY,
        payload: res.data
      });
    }
  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}

export const getCssHistoryData = (dateNowRange, scanId) => async (dispatch) => {
  dispatch({
    type: CSS_HISTORY_LOADING,
  });

  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId, dateNowRange
    }

    const res = await axios.get(`${BASE_URL}/history/css-history`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: CSS_HISTORY,
        payload: res.data
      });
    }
  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}

export const getCssData = (scanId) => async (dispatch) => {
  dispatch({
    type: CSS_SUMMARY_LOADING,
  });

  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }

    const res = await axios.get(`${BASE_URL}/dashboard/css-summary`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: CSS_SUMMARY,
        payload: res.data
      });
    }
  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}

export const getPerformanceAnalytics = (scanId) => async (dispatch) => {
  dispatch({
    type: PERFORMANCE_ANALYTICS_LOADING,
  });

  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }

    const res = await axios.get(`${BASE_URL}/dashboard/performance-analytics`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: PERFORMANCE_ANALYTICS,
        payload: res.data
      });
    }
  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}

export const getPerformanceStructure = (scanId) => async (dispatch) => {
  dispatch({
    type: PERFORMANCE_STRUCTURE_LOADING,
  });

  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }

    const res = await axios.get(`${BASE_URL}/dashboard/performance-audits`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: PERFORMANCE_STRUCTURE,
        payload: res.data
      });
    }
  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}

export const getPerformanceWaterfall = (scanId) => async (dispatch) => {
  dispatch({
    type: PERFORMANCE_WATERFALL_LOADING,
  });

  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }

    const res = await axios.get(`${BASE_URL}/dashboard/performance-waterfall`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: PERFORMANCE_WATERFALL,
        payload: res.data
      });
    }
  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}

export const getPerformanceVisualization = (scanId) => async (dispatch) => {
  dispatch({
    type: PERFORMANCE_VISUALIZATION_LOADING,
  });

  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }

    const res = await axios.get(`${BASE_URL}/dashboard/performance-speed-visualization`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: PERFORMANCE_VISUALIZATION,
        payload: res.data
      });
    }
  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}