
import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { showAlert, removeAlert } from './alertActions';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import store from "../../store";

export const GET_ISSUE_TRACKER_TABLES = 'GET_ISSUE_TRACKER_TABLES';
export const GET_ISSUE_TRACKER_TABLES_IDS = 'GET_ISSUE_TRACKER_TABLES_IDS';

export const GET_ISSUE_TRACKER_TABLE = 'GET_ISSUE_TRACKER_TABLE';

export const SAVING_ISSUE_TRACKER_TABLE = 'SAVING_ISSUE_TRACKER_TABLE';

export const DELETE_ISSUE_TABLE = 'DELETE_ISSUE_TABLE';
export const UPDATE_ISSUE_TABLE = 'UPDATE_ISSUE_TABLE';

export const LOADING_ISSUES_TABLES = 'LOADING_ISSUES_TABLES';

export const OPEN_TRACKER_MODAL = 'OPEN_TRACKER_MODAL';

export const ADD_ISSUE_LOADING = 'ADD_ISSUE_LOADING';
export const ADD_ISSUE_SUCCESS = 'ADD_ISSUE_SUCCESS';

export const ADD_MILESTONE_LOADING = 'ADD_MILESTONE_LOADING';

export const GET_ISSUES_LISTS = 'GET_ISSUES_LISTS';
export const GET_ISSUES = 'GET_ISSUES';
export const GET_ISSUE = 'GET_ISSUE';


export const GET_MILESTONE = 'GET_MILESTONE';

export const GET_MILESTONES_LISTS = 'GET_MILESTONES_LISTS';
export const GET_MILESTONES = 'GET_MILESTONES';

export const GET_MEMBERS = 'GET_MEMBERS';
export const GET_LABELS = 'GET_LABELS';

export const ADD_LABEL_LOADING = 'ADD_LABEL_LOADING';

export const UPDATE_ISSUE_LOADING = 'UPDATE_ISSUE_LOADING';
export const UPDATE_MILESTONE_LOADING = 'UPDATE_MILESTONE_LOADING';


export const ADD_COMMENT_LOADING = 'ADD_COMMENT_LOADING';
export const UPDATE_COMMENT_LOADING = 'UPDATE_COMMENT_LOADING';
export const REMOVE_COMMENT_LOADING = 'REMOVE_COMMENT_LOADING';

export const CLOSE_MILESTONE_LOADING = 'CLOSE_MILESTONE_LOADING';


export const getTables = (domainId, type) => async (dispatch) => {

  const token = auth0Service.getAccessToken();

  dispatch({
    type: LOADING_ISSUES_TABLES,
  });
  const params = {
    domainId,
    type,
  }

  try {
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    // const res = await axios.get(`${BASE_URL}/issues/issue-tracker`, {
    //   params,
    //   ...options
    // });
    let res = await axios.get(`${BASE_URL}/issues/issue-tracker`, {
      params,
      ...options
    });



    if (res.data) {
      let data = res.data.data.map(r => {
        return {
          ...r,
          rows: [],
        };
      });
      dispatch({
        type: GET_ISSUE_TRACKER_TABLES,
        payload: data
      });
      dispatch({
        type: LOADING_ISSUES_TABLES,
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const getTableIds = (domainId, type) => async (dispatch) => {

  const token = auth0Service.getAccessToken();

  const params = {
    domainId,
    type,
  }

  try {
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    let res = await axios.get(`${BASE_URL}/issues/issue-tracker`, {
      params,
      ...options
    });

    if (res.data) {
      let data = res.data.data.map(r => {
        return {
          id: r.id
        };
      });
      dispatch({
        type: GET_ISSUE_TRACKER_TABLES_IDS,
        payload: data
      });

    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const getTablesId = (domainId, type, tableId) => async (dispatch) => {

  const token = auth0Service.getAccessToken();

  const params = {
    domainId,
    type,
    tableId
  }

  try {
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.get(`${BASE_URL}/issues/issue-tracker`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_ISSUE_TRACKER_TABLE,
        payload: res.data.data
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const refreshTable = (id) => async (dispatch) => {
  const token = auth0Service.getAccessToken();

  dispatch({
    type: LOADING_ISSUES_TABLES,
  });
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const params = {
      tableId: id
    }

    const res = await axios.get(`${BASE_URL}/issues/issue-tracker`, {
      params,
      ...options
    });

    if (res.data) {

    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const saveTable = (type, rows, id, tableTitle, domainId) => async (dispatch) => {
  dispatch({
    type: SAVING_ISSUE_TRACKER_TABLE,
  });

  const toastUid = uuidv4();

  toast.info("Saving...", { autoClose: false, closeButton: false, toastId: toastUid })
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const tableData = {
      rows,
      id,
      tableTitle,
      type,
      domainId
    }

    const res = await axios.post(`${BASE_URL}/issues/issue-tracker`, {
      tableData
    },
      options
    );

    if (res.data) {
      toast.update(toastUid, { render: () => "Saved", type: toast.TYPE.SUCCESS, closeButton: true, autoClose: 1000 });
      // dispatch(getTablesId(domainId, type, id));
      dispatch({
        type: SAVING_ISSUE_TRACKER_TABLE,
      });
    }
  } catch (err) {
    toast.update(toastUid, { render: () => "An Error Occurred", type: toast.TYPE.ERROR, closeButton: true, autoClose: 1000 });
    dispatch({
      type: SAVING_ISSUE_TRACKER_TABLE,
    });
  }
}

export const csvTable = (type, rows, id, tableTitle, domainId) => async (dispatch) => {
  dispatch({
    type: LOADING_ISSUES_TABLES,
  });
  dispatch({
    type: SAVING_ISSUE_TRACKER_TABLE,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const tableData = {
      rows,
      id,
      tableTitle,
      type,
      domainId
    }

    const res = await axios.post(`${BASE_URL}/issues/issue-tracker-upload`, {
      tableData
    },
      options
    );

    if (res.data) {
      dispatch({
        type: LOADING_ISSUES_TABLES,
      });
      dispatch({
        type: SAVING_ISSUE_TRACKER_TABLE,
      });
      // dispatch(getTablesId(domainId, type, id));
    }
  } catch (err) {
    dispatch({
      type: SAVING_ISSUE_TRACKER_TABLE,
    });
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const rowAction = (rowNumbers, tableId, domainId, type, action) => async (dispatch) => {
  // dispatch({
  //   type: LOADING_ISSUES_TABLES,
  // });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/issues/issue-tracker-row`, {
      tableId,
      domainId,
      rowNumbers,
      action
    },
      options
    );

    if (res.data) {

      // dispatch({
      //   type: LOADING_ISSUES_TABLES,
      // });

      dispatch(showAlert("Row Updated!"));
      const alert2 = setTimeout(() => {
        dispatch(removeAlert());
        clearTimeout(alert2);
      }, 1500);

      dispatch(getTablesId(domainId, type, tableId));
    }
  } catch (err) {
    // dispatch({
    //   type: LOADING_ISSUES_TABLES,
    // });
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const addColumn = (domainId, type, tableId, column) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const tableData = {
      domainId,
      type,
      tableId,
      column
    }
    const res = await axios.post(`${BASE_URL}/issues/issue-tracker-column`, {
      tableData
    },
      options
    );

    if (res.data) {

      dispatch(showAlert("Column Added!"));
      const alert2 = setTimeout(() => {
        dispatch(removeAlert());
        clearTimeout(alert2);
      }, 3100);
      dispatch(getTablesId(domainId, type, tableId));
    }
  } catch (err) {
  }
}

export const deleteTable = (id, domainId, type) => async (dispatch) => {
  // dispatch({
  //   type: LOADING_ISSUES_TABLES,
  // });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const params = {
      id
    }

    const res = await axios.delete(`${BASE_URL}/issues/issue-tracker`, {
      params,
      ...options
    });

    if (res.data.message === "Table Deleted") {
      // dispatch({
      //   type: LOADING_ISSUES_TABLES,
      // });
      dispatch(getTablesId(domainId, type, id));
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const openTrackerModal = (domainId, type, tableId) => (dispatch) => {
  const tableData = {
    type,
    tableId,
    domainId
  }
  dispatch({
    type: OPEN_TRACKER_MODAL,
    payload: tableData

  })
}

export const getIssuesLists = (domainId) => async (dispatch) => {

  const token = auth0Service.getAccessToken();

  const params = {
    domainId,
    name: 'all'
  }

  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    let res = await axios.get(`${BASE_URL}/issue-tracker/lists`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_ISSUES_LISTS,
        payload: res.data.result
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const getIssues = (data) => async (dispatch) => {

  const token = auth0Service.getAccessToken();

  const params = {
    page: 1,
    limit: 1000,
    ...data
  }

  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    let res = await axios.get(`${BASE_URL}/issue-tracker/issues`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_ISSUES,
        payload: res.data.result
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const getIssue = (domainId, id) => async (dispatch) => {

  const token = auth0Service.getAccessToken();

  const params = {
    domainId,
    id,
  }

  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    let res = await axios.get(`${BASE_URL}/issue-tracker/issue`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_ISSUE,
        payload: res.data.result
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const getMilestone = (domainId, id) => async (dispatch) => {

  const token = auth0Service.getAccessToken();

  const params = {
    id,
    domainId,
  }

  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    let res = await axios.get(`${BASE_URL}/issue-tracker/milestone`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_MILESTONE,
        payload: res.data.result
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const getMilestonesLists = (domainId) => async (dispatch) => {

  const token = auth0Service.getAccessToken();

  const params = {
    domainId,
    name: 'all'
  }

  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    let res = await axios.get(`${BASE_URL}/issue-tracker/milestones-lists`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_MILESTONES_LISTS,
        payload: res.data.result
      });
      return res.data.result
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const getMilestones = (data) => async (dispatch) => {

  const token = auth0Service.getAccessToken();

  const params = {
    page: 1,
    limit: 1000,
    ...data
  }

  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    let res = await axios.get(`${BASE_URL}/issue-tracker/milestones`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_MILESTONES,
        payload: res.data.result
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const addNewIssue = (issueData) => async (dispatch) => {
  dispatch({
    type: ADD_ISSUE_LOADING
  });

  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(`${BASE_URL}/issue-tracker/issue`, {
      ...issueData
    },
      options
    );

    if (res.data) {
      toast.success(res.data.message);
      dispatch({
        type: ADD_ISSUE_LOADING
      });
      return res.data

    }
  } catch (err) {
    toast.error(err.response.data.message)
    dispatch({
      type: ADD_ISSUE_LOADING
    });

  }
}

export const addNewMilestone = (issueData) => async (dispatch) => {
  dispatch({
    type: ADD_MILESTONE_LOADING
  });

  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(`${BASE_URL}/issue-tracker/milestone`, {
      ...issueData
    },
      options
    );

    if (res.data) {
      toast.success(res.data.message);
      dispatch({
        type: ADD_MILESTONE_LOADING
      });
      return res.data
    }
  } catch (err) {
    toast.error(err.response.data.message)
    dispatch({
      type: ADD_MILESTONE_LOADING
    });

  }
}



export const getMembers = () => async (dispatch) => {
  const token = auth0Service.getAccessToken();
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    let res = await axios.get(`${BASE_URL}/issue-tracker/members`,
      options
    );

    if (res.data) {
      dispatch({
        type: GET_MEMBERS,
        payload: res.data.result
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const addNewLabel = (data) => async (dispatch) => {
  dispatch({
    type: ADD_LABEL_LOADING
  });

  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(`${BASE_URL}/issue-tracker/labels`, {
      ...data
    },
      options
    );

    if (res.data) {
      toast.success(res.data.message);
      dispatch(getLabels(data.domainId))
      dispatch({
        type: ADD_LABEL_LOADING
      });
      return res.data
    }
  } catch (err) {
    toast.error(err.response.data.message)
    dispatch({
      type: ADD_LABEL_LOADING
    });

  }
}

export const getLabels = (domainId) => async (dispatch) => {
  const token = auth0Service.getAccessToken();
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const params = {
      domainId
    }

    let res = await axios.get(`${BASE_URL}/issue-tracker/labels`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_LABELS,
        payload: res.data.result
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const updateIssue = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_ISSUE_LOADING
  });

  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.put(`${BASE_URL}/issue-tracker/issue`, {
      ...data
    },
      options
    );

    if (res.data) {
      toast.success(res.data.message);
      dispatch({
        type: UPDATE_ISSUE_LOADING
      });
    }
  } catch (err) {
    toast.error(err.response.data.message)
    dispatch({
      type: UPDATE_ISSUE_LOADING
    });

  }
}

export const updateMilestone = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_MILESTONE_LOADING
  });

  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.put(`${BASE_URL}/issue-tracker/milestone`, {
      ...data
    },
      options
    );

    if (res.data) {
      toast.success(res.data.message);
      dispatch({
        type: UPDATE_MILESTONE_LOADING
      });
      return res.data
    }
  } catch (err) {
    toast.error(err.response.data.message)
    dispatch({
      type: UPDATE_MILESTONE_LOADING
    });

  }
}
export const addNewComment = (data) => async (dispatch) => {
  dispatch({
    type: ADD_COMMENT_LOADING
  });

  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(`${BASE_URL}/issue-tracker/comment`, {
      ...data
    },
      options
    );

    if (res.data) {
      toast.success(res.data.message);
      dispatch({
        type: ADD_COMMENT_LOADING
      });
      dispatch(getIssue(data.domainId, data.issueId));
      return res.data
    }
  } catch (err) {
    dispatch({
      type: ADD_COMMENT_LOADING
    });
  }
}

export const editComment = (data, issueId) => async (dispatch) => {
  dispatch({
    type: UPDATE_COMMENT_LOADING
  });

  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.put(`${BASE_URL}/issue-tracker/comment`, {
      ...data
    },
      options
    );

    if (res.data) {
      toast.success(res.data.message);
      dispatch({
        type: UPDATE_COMMENT_LOADING
      });
      dispatch(getIssue(data.domainId, issueId));

      return res.data
    }
  } catch (err) {
    dispatch({
      type: UPDATE_COMMENT_LOADING
    });
  }
}

export const removeComment = (id, domainId, issueId) => async (dispatch) => {
  dispatch({
    type: REMOVE_COMMENT_LOADING
  });

  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      id
    }

    const res = await axios.delete(`${BASE_URL}/issue-tracker/comment`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: REMOVE_COMMENT_LOADING
      });
      toast.success(res.data.message);

      dispatch(getIssue(domainId, issueId));

    }

  } catch (err) {
    dispatch({
      type: REMOVE_COMMENT_LOADING
    });
  }
}

export const closeMilestone = (data) => async (dispatch) => {

  dispatch({
    type: CLOSE_MILESTONE_LOADING
  });

  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(`${BASE_URL}/issue-tracker/milestone-status`, {
      ...data
    },
      options
    );

    if (res.data) {
      toast.success(res.data.message);
      dispatch({
        type: CLOSE_MILESTONE_LOADING
      });
      return res.data
    }
  } catch (err) {
    toast.error(err.response.data.message)
    dispatch({
      type: CLOSE_MILESTONE_LOADING
    });

  }
}