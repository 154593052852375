import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

import Auth0Service from "../services/auth0Service/authentikService";
import { useParams } from "react-router-dom";
import AccessibilityIssues from './AdminComponents/AccessibilityIssues';



const useStyles = makeStyles({
  selectBox: {
    maxWidth: 300,
    width: 300,
  },
});

const styles = {
  textField: {
    width: '100%',
    margin: 10,
    maxWidth: 300,
  }
}

export default function CollapsibleTable() {

  const [key, setKey] = React.useState('');
  const [domain, setDomain] = React.useState('');


  const [reportType, setReportType] = React.useState({
    accounts: true,
  });

  const dispatch = useDispatch();
  // const { keyCode } = useParams();
  const currentURL = window.location.href;
  // Create a URL object
  const urlObject = new URL(currentURL);
  let domainParam = urlObject.searchParams.get('domain');
  if (domainParam.includes("www")) {
    domainParam = domainParam.split('www.')[1];
  }
// console.log(domainParam)
  React.useEffect(() => {
    // if (keyCode) {
    //   setKey(keyCode);
      setDomain(domainParam);
      // dispatch(getAllImagesLinks(domainParam));

    // }
    // keyCode
  }, [domainParam])

  if (!Auth0Service.isAuthenticated()) {
    return window.location.href = "/authenticate"
  } else {

    return (
      <>
        {reportType.accounts &&
          <AccessibilityIssues domain={domainParam} />
        }
      </>
    );
  }
}