import * as React from "react";
import { styled } from "@material-ui/styles";
import {
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Box,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { setUnified } from "../../store/actions/dashboardActions";

export default function CustomizedSwitches() {
  const switchChange = () => { };
  const unified = useSelector(state => state.dashboard.unified)
  const dispatch = useDispatch()


  const IOSSwitch = styled((props) => (
    <Switch
      onChange={switchChange}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#1f3a93",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  function handleChange(e) {
    dispatch(setUnified(e.target.checked));
  }

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",

      }}
    >
      <FormControlLabel
        style={{ marginLeft: 5, marginRight: 5 }}
        control={<IOSSwitch sx={{ m: 1 }} checked={unified} onChange={handleChange} />}
      // label="Unified Analytics"
      />
      <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
        Unified
      </Typography>
    </Box>
  );
}
