import React from 'react';
import { useDispatch } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { postReports, getAdminReports, deleteReport } from '../store/actions/dashboardActions';
import { Box, Button, Card, CircularProgress, TextField, Typography } from '@material-ui/core';
import { toBase64 } from '../utils/convertBase64';
import { useSelector } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Pagination } from '@material-ui/lab';

export default function AdminReportsUpload() {


    const dispatch = useDispatch();

    const allDomains = useSelector((state) => state.adminScansManagementReducer.allDomains);

    const [domainId, setDomainId] = React.useState(null);
    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(0);


    const [adminReports, setAdminReports] = React.useState([]);
    const [adminReportsLoading, setAdminReportsLoading] = React.useState(false);



    const [addLoading, setAddLoading] = React.useState(false)
    const [deleteLoading, setDeleteLoading] = React.useState(false)


    const [singleReport, setSingleReport] = React.useState({
        title: '',
        description: '',
        fileData: '',
        file: '',
        fileData: '',
        name: '',
        type: '',
        date: new Date()
    })

    const [reports, setReports] = React.useState([]);



    async function addReport(e) {
        e.preventDefault()
        setReports([...reports, singleReport])
    }

    function submitReports() {
        setAddLoading(true)
        const rs = reports.map(r => {
            return {
                title: r.title,
                description: r.description,
                file: r.file,
                date: new Date(r.date),
                fileContentType: r.type,
            }
        })
        dispatch(postReports({
            domainId,
            reports: rs
        })).then(data => {
            setAddLoading(false)
            setReports([])
            setSingleReport({
                title: '',
                description: '',
                fileData: '',
                file: '',
                fileData: '',
                name: '',
                type: '',
                date: new Date()
            })
        }).catch((err) => {
            setAddLoading(false)
        })
    }

    function removeFromAppsArray(i) {
        const filtered = reports.filter((app, index) => index !== i);
        setReports(filtered)
    }

    const handleValues = async (e) => {
        if (e.target.name === 'file') {
            setSingleReport({
                ...singleReport,
                name: e.target.files[0].name,
                type: e.target.files[0].type,
                file: await toBase64(e.target.files[0])
            })
        } else {
            setSingleReport({
                ...singleReport,
                [e.target.name]: e.target.value
            })
        }
    }
    const limit = 5

    React.useEffect(() => {
        setAdminReportsLoading(true)
        dispatch(getAdminReports({page, limit })).then(data => {
            setAdminReports(data.result)
            setPages(data.pages)
            setAdminReportsLoading(false)
        }).catch(err => {
            setAdminReportsLoading(false)
        })
    }, [page])

    const handleChange = (event, value) => {
        setPage(value);
    }

    function handleDelete(id) {
        setDeleteLoading(true)
        dispatch(deleteReport({ reportId: id })).then(data => {
            setDeleteLoading(false)
        }).catch(err => {
            setDeleteLoading(false)
        })
    }

    return (
        <Box>

            <TableContainer component={Paper}>

                <Table aria-label="simple table" style={{ tableLayout: 'fixed' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Domain</TableCell>
                            <TableCell >Title</TableCell>
                            <TableCell style={{ width: 200 }}>Description</TableCell>
                            <TableCell >Date</TableCell>
                            <TableCell >File</TableCell>
                            <TableCell >Action</TableCell>

                        </TableRow>
                    </TableHead>
                    {!adminReportsLoading ? <TableBody>
                        {adminReports.length > 0 ? adminReports.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row.domainName}
                                </TableCell>
                                <TableCell style={{ wordBreak: 'break-all' }} >{row.title}</TableCell>
                                <TableCell style={{ wordBreak: 'break-all' }} >{row.description}</TableCell>
                                <TableCell style={{ wordBreak: 'break-all' }} >{new Date(row.date).toDateString()}</TableCell>
                                <TableCell style={{ wordBreak: 'break-all' }} ><a href={row.reportUrl} target='_blank'>View</a></TableCell>
                                <TableCell style={{ wordBreak: 'break-all' }} ><Button onClick={() => handleDelete(row._id)} disabled={deleteLoading} color='secondary' variant='contained'>Delete</Button></TableCell>

                            </TableRow>
                        )) :
                            <TableRow><TableCell>no records found</TableCell></TableRow>
                        }
                    </TableBody> :
                        <TableBody>
                            <TableRow><TableCell align='center' colSpan={6}>
                                <CircularProgress />
                            </TableCell></TableRow>
                        </TableBody>
                    }
                </Table>
                <Box p={3} display='flex' justifyContent='center'>
                    <Pagination color='primary' count={pages} page={page} onChange={handleChange} />
                </Box>
            </TableContainer>

            <div style={{ width: '100%', maxWidth: 500, marginTop: 20 }}>
                <label htmlFor='select-domain'>Select Domain:</label>
                <br />
                <Select
                    variant="outlined"
                    labelId="select-domain"

                    id="select-domain"
                    style={{ width: '100%', maxWidth: 400, marginTop: 10, marginBottom: 20 }}
                    value={domainId}
                    onChange={(event) => {
                        setDomainId(event.target.value)
                    }}
                >
                    {allDomains.map(domain => {
                        return <MenuItem value={domain._id} style={{ textTransform: 'lowercase' }}>{domain.domain}</MenuItem>
                    })}
                </Select>

                {reports.map((report, index) => {
                    return (
                        <Card key={index} style={{ border: '1px solid #000', marginBottom: 10 }} >
                            <Box p={2} style={{
                                wordBreak: 'break-all',

                            }}>
                                <b>Title:</b>{report.title}<br />
                                <b>Description:</b>{report.description}<br />
                                <b>Report Date:</b>{report.date}<br />
                                <b>File:</b>{report.name}<br />
                                <Box display='flex' justifyContent='center' alignItems='center'>
                                    <Button size='small' color='secondary' variant='contained' onClick={() => removeFromAppsArray(index)}>Delete</Button>
                                </Box>
                            </Box>
                        </Card>
                    )
                })}
                {reports.length > 0 &&
                    <Box display='flex' alignItems='center' justifyContent='center'>
                        <Button onClick={() => submitReports()} disabled={addLoading} variant='contained' color='primary'>
                            {addLoading && <CircularProgress size={18} style={{ marginRight: 10 }} />}
                            Add Reports</Button>
                    </Box>
                }

                <Card>
                    <Box component='form' onSubmit={addReport} p={2}>
                        <label>Title:</label>
                        <br />
                        <TextField variant='outlined' required={true} onChange={handleValues} type='text' name='title' placeholder='Title' value={singleReport.title} style={{ marginBotom: 10, padding: 0 }} /> <br /> <br />
                        <label >Description:</label>
                        <br />
                        <TextField variant='outlined' required={true} onChange={handleValues} type='text' multiline name='description' placeholder='Description' rows={5} value={singleReport.description} style={{ marginBotom: 10, padding: '0px !important' }} /><br /> <br />
                        <label >Report Date:</label>
                        <br />
                        <TextField variant='outlined' required={true} onChange={handleValues} type='date' name='date' placeholder='Report Date' value={singleReport.date} autoFocus style={{ marginBotom: 10 }} /><br /> <br />
                        <label htmlFor='select-domain'>Upload File:</label>
                        <br />
                        <TextField variant='outlined' required={true} onChange={handleValues} type='file' name='file' placeholder='Add File' filename={singleReport?.name} style={{ marginBotom: 10 }} /><br /> <br />
                        <Button variant='contained' color='primary' style={{ marginTop: 20 }} type='submit'>Add</Button>
                    </Box>
                </Card>
            </div>
        </Box>
    )
}