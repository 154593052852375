import {
  ADD_STRIPE_CARD_LOADING,
} from '../actions/addStripeCardActions';

const initialState = {
  loading: false
};

const  addStripeCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_STRIPE_CARD_LOADING:
      return {
        ...state,
       loading:!state.loading
      }
    default:
      return state;
  }
};

export default addStripeCardReducer; 