import React from "react";
import Container from "@material-ui/core/Container";
import { Box, Button } from "@material-ui/core";
import Tabs from "../../components/commons/Tabs/compactTabs/Tabs";
import Tab from "../../components/commons/Tabs/compactTabs/Tab";
import Select from "react-select";
import { components } from "react-select";
import CustomizedInputs from "../../components/commons/BootstrapedInput";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import { changeDate } from "../../utils/changeDate";
import Chip from "@material-ui/core/Chip";
import { useDispatch, useSelector } from "react-redux";
import {
  getIssuesLists,
  getIssues,
  getIssue,
} from "../../store/actions/issueTrackerActions";
import { useHistory } from "react-router-dom";
export default function Lists() {
  let history = useHistory();
  const { selectedDomainId } = useSelector((state) => state.dashboard);
  const { issuesLists } = useSelector((state) => state.issueTracker);
  const getIssuess = useSelector((state) => state.issueTracker.issues);

  const [searchIssues, setSearchIssues] = React.useState("");

  const [tabIndex, setTabIndex] = React.useState(0);
  const [issues, setIssues] = React.useState(0);
  const [selectedIssueList, setSelectedIssueList] = React.useState(null);


  const [selectedOptionFilter, setSelectedOptionFilter] = React.useState("type");

  const dispatch = useDispatch();


  const [filterOptions] = React.useState([
    { value: "type", label: "Type" },
    { value: "due_date", label: "Due date" },
    { value: "created_at", label: "Created date" }
  ]);

  React.useEffect(() => {
    if (issuesLists.length > 0) {
      setSelectedIssueList(issuesLists[0])
    }
  }, [issuesLists]);

  React.useEffect(() => {
    if (selectedIssueList) {
      dispatch(getIssues({ domainId: selectedDomainId, listId: selectedIssueList?._id, sort: selectedOptionFilter }))

    }
  }, [selectedIssueList]);


  React.useEffect(() => {
    if (selectedDomainId) {
      dispatch(getIssuesLists(selectedDomainId));
    }
  }, [selectedDomainId]);

  React.useEffect(() => {
    if (issuesLists.length > 0) {
      dispatch(
        getIssues({ domainId: selectedDomainId, listId: issuesLists[0]?._id, sort: selectedOptionFilter })
      );
    }
  }, [issuesLists]);

  React.useEffect(() => {
    if (getIssuess) {
      setIssues(getIssuess)
    }
  }, [getIssuess]);

  React.useEffect(() => {
    if (searchIssues !== "") {
      const filterlbls = getIssuess.filter(lb => lb.title.startsWith(searchIssues));
      setIssues(filterlbls)
    } else {
      setIssues(getIssuess)
    }
  }, [searchIssues])

  const CustomMenu = (props) => {
    return (
      <components.MenuList {...props}>
        {props.children}
      </components.MenuList>
    );
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      minWidth: 300,
      width: "auto",
      borderBottom: "1px dotted pink",
      color: state.selectProps.menuColor,
    }),
    control: (_, { selectProps: { width } }) => ({
      width: width,
      display: "flex",
      backgroundColor: "white",
    }),
  };



  const handleChangeFilter = (selectedOption) => {
    setSelectedOptionFilter(selectedOption.value);
    dispatch(getIssues({ domainId: selectedDomainId, listId: issuesLists[0]?._id, sort: selectedOption.value }))

  };

  return (
    <Container maxWidth="lg">
      <Box
        width="100%"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        mt={3}
      >
        <Tabs value={tabIndex} onChange={(e, index) => setTabIndex(index)}>
          {issuesLists.map((issueList) => {
            return (
              <Tab
                disableRipple
                label={`${issueList.name}-${issueList.issueCount}`}
                onClick={() =>
                  setSelectedIssueList(issueList)
                }
              />
            );
          })}
        </Tabs>
        <div className="btns">
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              history.push(`/issue-tracker/new-issue/${issuesLists[0]?._id}`)
            }
          >
            New Issue
          </Button>
        </div>
      </Box>
      <Box p={1.5} py={2} display="flex" style={{ backgroundColor: "#d5d6d8" }}>
        <Box ml={1} width="100%">
          <CustomizedInputs placeholder="search by name" width="100%" onChange={(e) => setSearchIssues(e.target.value)} />
        </Box>
        <Box ml={1}>
          <Select
            placeholder="Sort"

            defaultValue={
              filterOptions.filter(option =>
                option.value === 'type')
            }
            onChange={handleChangeFilter}
            options={filterOptions}
            styles={customStyles}
            width="200px"
            components={{ MenuList: CustomMenu }}
          />
        </Box>
      </Box>
      {issues.length > 0 ? (
        issues.map((issue, index) => {
          return (
            <Box
              borderTop={1}
              sx={{ borderColor: "#999898" }}
              px={1}
              py={1.5}
              key={index}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <p
                  style={{ fontWeight: 600, margin: 0, fontSize: 15, textDecoration: 'underline' }}
                  className="title-issue"
                  onClick={() => {
                    history.push(`/issue-tracker/issue/${issue.id}`)
                  }
                  }
                >
                  {issue.title}
                </p>
                <Box display="flex" alignItems="center">
                  <QuestionAnswerOutlinedIcon style={{ color: "#999898" }} />{" "}
                  <small style={{ color: "#666", marginLeft: 5 }}>
                    {issue.comments.length}
                  </small>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" width="100%">
                <p style={{ margin: 0, fontWeight: 400 }}>
                  {" "}
                  #{issue.id} created at {changeDate(issue.created_at)} by{" "}
                  {issue.username}
                </p>
                <Box ml={1}>
                  {" "}
                  {issue.labels.map((label) => (
                    <Chip
                      size="small"
                      label={label.name}
                      style={{
                        backgroundColor: label.color,
                        color: "white",
                        margin: 4,
                      }}
                    />
                  ))}{" "}
                </Box>
              </Box>
            </Box>
          );
        })
      ) : (
        <Box textAlign="center" mt={3}>
          No issues found
        </Box>
      )}
    </Container>
  );
}
