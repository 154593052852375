
function openSideMenu() {
  return {
    type: 'OPEN_SIDE_MENU',
  };
}

function closeSideMenu() {
  return {
    type: 'CLOSE_SIDE_MENU',
  };
}

function toggleListItem() {
  return {
    type: 'TOGGLE_LIST_ITEM',
  };
}

function toggleMenuBar() {
  return {
    type: 'TOGGLE_MENU_BAR',
  };
}

function handleToggleMessages() {
  return {
    type: 'TOGGLE_MESSAGES_MENU',
  };
}

function handleToggleNotifications() {
  return {
    type: 'TOGGLE_NOTIFICATIONS_MENU',
  };
}

function handleToggleChats() {
  return {
    type: 'TOGGLE_CHATS_MENU',
  };
}

function handleToggleSettingList(status) {
  return {
    type: 'TOGGLE_SETTING_LIST',
    payload: status
  };
}

function handleToggleProjectManagementList(status) {
  return {
    type: 'TOGGLE_PROJECT_MANAGEMENT_LIST',
    payload: status
  };
}

function handleToggleAdminManagementList(status) {
  return {
    type: 'TOGGLE_ADMIN_MANAGEMENT_LIST',
    payload: status
  };
}

function handleToggleWebsiteManagementList(status) {
  return {
    type: 'TOGGLE_WEBSITE_MANAGEMENT_LIST',
    payload: status
  };
}

function handleToggleAbrakadabraBox(status) {
  return {
    type: 'TOGGLE_ABRAKADABRA_LIST',
    payload: status
  };
}

function handleToggleSchemaBox(status) {
  return {
    type: 'TOGGLE_SCHEMA_LIST',
    payload: status
  };
}

function handleToggleIssueList(status) {
  return {
    type: 'TOGGLE_ISSUE_LIST',
    payload: status
  };
}
export {
  openSideMenu,
  closeSideMenu,
  toggleListItem,
  toggleMenuBar,
  handleToggleMessages,
  handleToggleNotifications,
  handleToggleChats,
  handleToggleSettingList,
  handleToggleProjectManagementList,
  handleToggleAdminManagementList,
  handleToggleWebsiteManagementList,
  handleToggleAbrakadabraBox,
  handleToggleIssueList,
  handleToggleSchemaBox
};
