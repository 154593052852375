import React from "react";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useSelector, useDispatch } from 'react-redux';
import GraphCard from "../../commons/GraphCard";
import SecurityBarGraph from "../../barchart/AccessibilityBar";
import { getSecurityHistory } from '../../../store/actions/securityActions';
import SubLoader from "../../commons/SubLoader";

export default function CyberSecCardOne({ dateRange, scanId, setLoader }) {

  const graph = useSelector(state => state.security.securityHistory);
  const status = useSelector(state => state.security.securityHistoryStatus);
  const loading = useSelector(state => state.security.securityHistoryLoading);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (dateRange && scanId) {
      dispatch(getSecurityHistory(dateRange, scanId));
    }
    // eslint-disable-next-line
  }, [scanId, dateRange]);

  React.useEffect(() => {
    if (status === "pending" || status === "running") {
      setLoader(true);
      const interval1 = setInterval(() => {
        dispatch(getSecurityHistory(dateRange, scanId));
      }, 15000);
      return () => clearInterval(interval1);
    } else {
      setLoader(false);

    }
    // eslint-disable-next-line
  }, [scanId, status])

  return (
    <GraphCard >
      <Typography variant="body1" align="left" style={{fontFamily:'Segoe UI'}} >Vulnerablities Per Page</Typography>
      { loading ? <Box display="flex" alignItems="center" justifyContent="center" style={{ height: "250px", maxHeight: "250px", boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.15)" }}> <SubLoader /> </Box> :
        <>
          <SecurityBarGraph data={graph} />
        </>
      }
    </GraphCard>
  );
}