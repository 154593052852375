 
import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { toast } from "react-toastify";

export const GET_IMAGES_CARD_DETAILS = 'GET_IMAGES_CARD_DETAILS';
export const LOADING_IMAGES = 'LOADING_IMAGES';

export const GET_IMAGES_HISTORY = 'GET_IMAGES_HISTORY';
export const LOADING_IMAGES_HISTORY = 'LOADING_IMAGES_HISTORY';

export const getImagesCardDetails = ( scanId, tag) => async (dispatch) => {
  dispatch({
    type: LOADING_IMAGES,
  });
  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
      tag
    }
    const res = await axios.get(`${BASE_URL}/dashboard/images-result`, {
      params,
      ...options
    });
    if (res.data) {
      dispatch({
        type: GET_IMAGES_CARD_DETAILS,
        payload: res.data
      });
    }

  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}

export const getImagesHistory = (scanId, dateNowRange, tag) => async (dispatch) => {
  dispatch({
    type: LOADING_IMAGES_HISTORY,
  });
  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const params = {
      dateNowRange,
      scanId,
      tag
    }

    const res = await axios.get(`${BASE_URL}/history/images-history`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_IMAGES_HISTORY,
        payload: res.data
      });

    }

  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}