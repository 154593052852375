import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";

import TextField from "@material-ui/core/TextField";

import { toast } from "react-toastify";

import Grid from "@material-ui/core/Grid";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDropzone } from "react-dropzone";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import MuiTypography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import addIssueStyles from "../../../Styles/jss/addIssueStyles";

import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";


export default function NewMilestone({ fun, btnTitle, milestone }) {
  let { listId, milestoneId } = useParams();
  let history = useHistory();

  const loading = useSelector((state) =>
    btnTitle === "Update"
      ? state.issueTracker.updateMilestoneLoading
      : state.issueTracker.addMilestoneLoading
  );
  const { selectedDomainId } = useSelector((state) => state.dashboard);
  const [file, setFile] = React.useState({
    filename: "",
    fileSize: "",
    fileData: "",
    contentType: "",
  });

  const [issueFields, setIssueFields] = React.useState({
    domainId: "",
    listId: "",
    milestone: {
      username: "",
      username_id: "",
      title: "",
      due_date: "",
      progress: 0,
      start_date: "",
      description: "",
      status: "open",
      issue_ids: [1],
    },
    attachment: {
      filename: "",
      fileSize: "",
      fileData: "",
      contentType: "",
    },
  });

  React.useEffect(() => {
    if (milestone) {
      setIssueFields({
        ...issueFields,
        milestone: {
          username: milestone.username,
          username_id: "",
          title: milestone.title,
          due_date: milestone.due_date,
          progress: milestone.progress,
          start_date: milestone.start_date,
          description: milestone.description,
          status: milestone.status,
          issue_ids: milestone.issue_ids,
        },
      });
    }
  }, [milestone]);

  const dispatch = useDispatch();


  const onDrop = React.useCallback(async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      toast.warning("Max File Size Exceeded");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = () => {
        const binaryStr = reader.result;
        setFile({
          filename: acceptedFiles[0].name,
          fileSize: acceptedFiles[0].size,
          fileData: binaryStr,
          contentType: acceptedFiles[0].type,
        });
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
    }
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      ".doc, .docx, .zip, .xlsx, .xls, .csv, .odt,  .gif,  .pdf, .jpeg, .png",
    minSize: 0,
    maxSize: 5242880,
  });

  function handleSubmit(e) {
    e.preventDefault();
    let id = null;
    if (btnTitle === "Update") {
      delete issueFields["listId"];
      id = milestoneId;
    } else id = listId;
    dispatch(
      fun({
        ...issueFields,
        [btnTitle === "Update" ? "id" : "listId"]: id,
        domainId: selectedDomainId,
      })
    ).then(data => {
      if (data) {
        if (btnTitle !== "Update") {
          history.push('/issue-tracker/milestones')
        }
      }
    })
  }

  return (
    <>
      {issueFields && (
        <form
          style={{ marginTop: "15px", padding: "15px 20px" }}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={5}>
            <CustomGrid item xs={12} sm={2}>
              Title
            </CustomGrid>
            <Grid item xs={10}>
              <TextField
                required={true}
                disabled={loading}
                value={issueFields.milestone.title}
                defaultValue={
                  issueFields.milestone.title && issueFields.milestone.title
                }
                variant="outlined"
                id="title"
                placeholder="Title"
                fullWidth
                onChange={(event) =>
                  setIssueFields({
                    ...issueFields,
                    milestone: {
                      ...issueFields.milestone,
                      title: event.target.value,
                    },
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <CustomGrid item xs={12} sm={2}>
              Start Date
            </CustomGrid>
            <Grid item xs={12} sm={10}>
              <TextField
                required={true}
                variant="outlined"
                disabled={loading}
                value={issueFields.milestone.start_date && issueFields.milestone.start_date}
                onChange={(event) => {
                  setIssueFields({
                    ...issueFields,
                    milestone: {
                      ...issueFields.milestone,
                      start_date: event.target.value,
                    },
                  });
                }}
                type="datetime-local"
              />            </Grid>
          </Grid>
          {/* Due date */}

          <Grid container spacing={5}>
            <CustomGrid item xs={12} sm={2}>
              Due Date
            </CustomGrid>
            <Grid item xs={12} sm={10}>
              <TextField
                required={true}
                variant="outlined"
                disabled={loading}
                value={issueFields.milestone.due_date && issueFields.milestone.due_date}
                onChange={(event) => {
                  setIssueFields({
                    ...issueFields,
                    milestone: {
                      ...issueFields.milestone,
                      due_date: event.target.value,
                    },
                  });
                }}
                type="datetime-local"
              />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <CustomGrid item xs={12} sm={2}>
              Description
            </CustomGrid>
            <Grid item xs={10}>
              {issueFields?.milestone?.description && <CKEditor
                disabled={loading}
                editor={ClassicEditor}
                config={{
                  removePlugins: [
                    "CKFinderUploadAdapter",
                    "CKFinder",
                    "EasyImage",
                    "Image",
                    "ImageCaption",
                    "ImageStyle",
                    "ImageToolbar",
                    "ImageUpload",
                    "MediaEmbed",
                  ],
                }}
                data={issueFields?.milestone?.description}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "250px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setIssueFields({
                    ...issueFields,
                    milestone: {
                      ...issueFields.milestone,
                      description: data,
                    },
                  });
                }}
              />}
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <CustomGrid item xs={12} sm={2}>
              Attachments
            </CustomGrid>
            <Grid item xs={10}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                my={2}
                py={2}
                flexDirection="column"
                style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.42)" }}
              >
                <div {...getRootProps()}>
                  <input {...getInputProps()} disabled={loading} />
                  {!file.filename ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <CloudUploadOutlinedIcon
                        style={{
                          fontSize: "30px",
                          color: "rgb(31, 58, 147)",
                        }}
                      />
                      <MuiTypography>Attach a File</MuiTypography>
                    </Box>
                  ) : (
                    <div>{file.filename}</div>
                  )}
                </div>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box display={"flex"} justifyContent={"center"}>
              <Button
                variant="contained"
                disabled={loading}
                style={{ margin: 15 }}
                type="submit"
                color="primary"
              >
                {btnTitle} Milestone{" "}
                {loading && (
                  <CircularProgress
                    style={{ color: "black", marginLeft: "0.5rem" }}
                    size={20}
                  />
                )}
              </Button>
            </Box>
          </Grid>
        </form>
      )}
    </>
  );
}


const CustomGrid = withStyles((theme) => ({
  root: {
    textAlign: "right",
    fontSize: "0.875rem",
    lineHeight: 2,
    fontWeight: 600,
    [theme.breakpoints.down("lg")]: {
      textAlign: "left",
    },
  },
}))(Grid);
