import {
  GET_PACKAGES_DOWNGRADE,
  GET_STRIPE_CARDS_DOWNGRADE,
  GET_TRANSACTIONS_DOWNGRADE,
  DOWNGRADE_PACKAGE,
  GET_PRICES_DOWNGRADE,
  GET_PRICES_LOADING_DOWNGRADE
} from '../actions/downgradePackageActions';

const initialState = {
  packages: {
    data: null,
    loading: true
  },
  stripeCards: null,
  transactions: null,
  loadingSubscribe: false,
  prices:null,
  pricesLoading: false
};

 const downgradePackageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PACKAGES_DOWNGRADE:
      return {
        ...state,
        packages: { data: action.payload, loading: false }
      }
    case GET_STRIPE_CARDS_DOWNGRADE:
      return {
        ...state,
        stripeCards: action.payload
      }
    case GET_TRANSACTIONS_DOWNGRADE:
      return {
        ...state,
        transactions: action.payload
      }
    case DOWNGRADE_PACKAGE:
      return {
        ...state,
        loadingSubscribe: !state.loadingSubscribe
      }
      case GET_PRICES_LOADING_DOWNGRADE:
        return {
          ...state,
          pricesLoading:!state.pricesLoading

        }
      case GET_PRICES_DOWNGRADE:
        return {
          ...state,
          prices: action.payload,
          pricesLoading:false
        }
    default:
      return state;
  }
};
export default downgradePackageReducer;