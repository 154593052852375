const cveTableStyles = ({
  root: {
    width: '100%',
  },
  chip: {
    color: "white",
    fontFamily: "freesans",
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: '0em',
    textAlign: 'left',
    width: '60%',
  },
  tHead: {
    fontFamily: "Segoe UI",
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '0em',
  },
  container: {
    maxHeight: "100%",

  },
  buttonColor: {
    color: "#00a5b5",
    '&:hover': {
      color: "#005f69",
    }
  },
  techTitle:{
    fontSize:18,
    fontWeight:400
  }
});

export default cveTableStyles;
