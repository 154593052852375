import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  // LabelList
} from 'recharts';

export default function Accessibility({ data }) {
  return (
    <ResponsiveContainer width={"99%"} height={250}>
      <BarChart
        width={600}
        height={250}
        data={data}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis
          dataKey="month"
        />
        <YAxis />
        <Tooltip />
        <Bar dataKey='high' barSize={20} stackId='a' fill='#e3211f' >
        </Bar>
        <Bar dataKey='medium' barSize={20} stackId='a' fill='#FFA412' >
        </Bar>
        <Bar dataKey='low' barSize={20} stackId='a' fill='#6dbb83' >
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};