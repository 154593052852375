import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button, // Import Button
    FormControl,
    FormHelperText,
    Grid,
    OutlinedInput,
    Typography,
} from '@mui/material';
import { useParams } from "react-router-dom";

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import Information from './Information';
import { makeStyles } from '@mui/styles';
import marginStyles from './styles/margin';
import componentStyles from './styles/stepper';
import { toast } from "react-toastify";
import { createPrivacyPolicy } from "../../store/actions/cookieActions";

const useStyles = makeStyles(componentStyles);
const useMarginStyles = makeStyles(marginStyles);

const Index = () => {
    const classes = useStyles();
    const margin = useMarginStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [data, setData] = useState({})
    const [domain, setDomain] = React.useState('');

    const currentURL = window.location.href;
    // Create a URL object
    const urlObject = new URL(currentURL);
    let domainParam = urlObject.searchParams.get('domain');
    if (domainParam.includes("www")) {
      domainParam = domainParam.split('www.')[1];
    }
    const selectedScanId = useSelector(state => state.miscellaneous.selectedScanId);
    console.log(selectedScanId);
  // console.log(domainParam)
    React.useEffect(() => {
      // if (keyCode) {
      //   setKey(keyCode);
        setDomain(domainParam);
        // dispatch(getAllImagesLinks(domainParam));
  
      // }
      // keyCode
    }, [domainParam])

    console.log('domain', domain);

    const handleCreatePolicy = async (domain, data) => {
    try {
        const response = await dispatch(createPrivacyPolicy(domain, data
        ));
        if (response) {
          console.log(response);
          toast.success(response.message)
        } else {
        //   toast.warning("AI Suggestion not available at this time")
        console.log('no response');
        }
      } catch (err) {
        console.log("here 2")
      }
    }

    return (
        <>
            <Typography className={classes.heading2}>
                Complete the required information to generate the privacy policy
            </Typography>
            <Box mt={3}>
                <Formik
                    initialValues={{
                        email: '',
                        companyName: '',
                        state: '',
                        phoneNumber: '',
                        ccpaLink: ''
                    }}
                    validationSchema={Yup.object().shape({
                        companyName: Yup.string().max(255).required('Company name is required'),
                        state: Yup.string().max(255).required('State is required'),
                        email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        phoneNumber: Yup.string().max(11).required('Correct phone number is required'),
                        ccpaLink: Yup.string().max(255).required('CCPA Link is required')

                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            console.log("here")
                            setData({
                                ...data,
                                    ...values
                            });
                            console.log('data', data);
                            if(domain){
                                await handleCreatePolicy(domain, values);
                            }
                        } catch (err) { 
                            console.log(err);
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography className={classes.heading4} mb={2}>
                                        Company Name
                                    </Typography>
                                    <FormControl fullWidth error={Boolean(touched.companyName && errors.companyName)}>
                                        <OutlinedInput
                                            id="outlined-adornment-companyName-register"
                                            type="text"
                                            value={values.companyName}
                                            name="companyName"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                            sx={{
                                                '& .css-4pzdr3-MuiInputBase-input-MuiOutlinedInput-input': {
                                                    height: '10px'
                                                }
                                            }}
                                        />
                                        {touched.companyName && errors.companyName && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.companyName}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography className={classes.heading4} mb={2}>
                                        State
                                    </Typography>
                                    <FormControl fullWidth error={Boolean(touched.state && errors.state)}>
                                        <OutlinedInput
                                            id="outlined-adornment-state-register"
                                            type="text"
                                            value={values.state}
                                            name="state"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                            sx={{
                                                '& .css-4pzdr3-MuiInputBase-input-MuiOutlinedInput-input': {
                                                    height: '10px'
                                                }
                                            }}
                                        />
                                        {touched.state && errors.state && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.state}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography className={classes.heading4} mb={2}>
                                        Email Address
                                    </Typography>
                                    <FormControl fullWidth error={Boolean(touched.email && errors.email)}>
                                        <OutlinedInput
                                            id="outlined-adornment-email-register"
                                            type="text"
                                            value={values.email}
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                            sx={{
                                                '& .css-4pzdr3-MuiInputBase-input-MuiOutlinedInput-input': {
                                                    height: '10px'
                                                }
                                            }}
                                        />
                                        {touched.email && errors.email && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.email}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography className={classes.heading4} mb={2}>
                                        CCPA Link
                                    </Typography>
                                    <FormControl fullWidth error={Boolean(touched.ccpaLink && errors.ccpaLink)}>
                                        <OutlinedInput
                                            id="outlined-adornment-email-register"
                                            type="text"
                                            value={values.ccpaLink}
                                            name="ccpaLink"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                            sx={{
                                                '& .css-4pzdr3-MuiInputBase-input-MuiOutlinedInput-input': {
                                                    height: '10px'
                                                }
                                            }}
                                        />
                                        {touched.ccpaLink && errors.ccpaLink && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.ccpaLink}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Information
                                    text={
                                        'Please ensure you enter the same email address that was provided to us, as we will use your on-file email address for identity verification purposes.'
                                    }
                                />

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} mt={2}>
                                    <Typography className={classes.heading4} mb={2}>
                                        Phone Number
                                    </Typography>
                                    <FormControl fullWidth error={Boolean(touched.phoneNumber && errors.phoneNumber)}>
                                        <OutlinedInput
                                            id="outlined-adornment-phoneNumber-register"
                                            type="text"
                                            value={values.phoneNumber}
                                            name="phoneNumber"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{}}
                                            sx={{
                                                '& .css-4pzdr3-MuiInputBase-input-MuiOutlinedInput-input': {
                                                    height: '10px'
                                                }
                                            }}
                                        />
                                        {touched.phoneNumber && errors.phoneNumber && (
                                            <FormHelperText error id="standard-weight-helper-text--register">
                                                {errors.phoneNumber}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Information
                                    text={
                                        'Your mobile phone number is used only for automated text-based identity verification. Please ensure you enter the same phone number that was provided to us, as we will use your on-file phone number for identity verification purposes.'
                                    }
                                />
                            </Grid>

                            <Box sx={{ mt: 3 }}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Submit
                                </Button>
                                {errors.submit && (
                                    <FormHelperText error sx={{ mt: 2 }}>
                                        {errors.submit}
                                    </FormHelperText>
                                )}
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default Index;
