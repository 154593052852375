import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from "@material-ui/core/Box";
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

export default function Addons({ billingContent, setBillingContent }) {

  function handleAddOns(event) {
    if (event.target.checked) {
      if (event.target.value === "Spell Checker Addon") {
        setBillingContent({ ...billingContent, addOns: { ...billingContent.addOns, spellChecker: { ...billingContent.addOns.spellChecker, selected: true } } })
      } else {
        setBillingContent({ ...billingContent, addOns: { ...billingContent.addOns, contentModerator: { ...billingContent.addOns.contentModerator, selected: true } } })
      }
    } else {
      if (event.target.value === "Spell Checker Addon") {
        setBillingContent({ ...billingContent, addOns: { ...billingContent.addOns, spellChecker: { ...billingContent.addOns.spellChecker, selected: false } } })
      } else {
        setBillingContent({ ...billingContent, addOns: { ...billingContent.addOns, contentModerator: { ...billingContent.addOns.contentModerator, selected: false } } })
      }
    }
  }
  return (
    <Box display="flex">
      <Box textAlign="center" mr={5}>
        <FormControlLabel
          style={{ margin: 0 }}
          control={<CustomCheckbox
            onChange={(event) => handleAddOns(event)}
            value={billingContent.addOns.spellChecker.name} />}
        />
        <h4 style={{ marginBottom: '10px' }}>Spell Checker</h4>
        <p>Price: ${billingContent.addOns.spellChecker.price} </p>
        <p>Words Count: {billingContent.addOns.spellChecker.count.toLocaleString()}</p>
      </Box>
      <Box textAlign="center">
        <FormControlLabel
          style={{ margin: 0 }}
          control={<CustomCheckbox
            onChange={(event) => handleAddOns(event)}
            value={billingContent.addOns.contentModerator.name} />}
        />
        <h4 style={{ marginBottom: '10px' }}>Content Moderator</h4>
        <p>Price: ${billingContent.addOns.contentModerator.price} </p>
        <p>Words Count: {billingContent.addOns.contentModerator.count.toLocaleString()}</p>
      </Box>

    </Box>
  )
}

const CustomCheckbox = withStyles({
  root: {
    '&$checked': {
      color: "#2a31a3",
    },
  },
  checked: {},
})((props) => <Checkbox  {...props} />);