import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { toast } from "react-toastify";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDropzone } from "react-dropzone";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import MuiTypography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  addNewLabel,
  getLabels,
  getMembers,
  getMilestonesLists,
  getMilestones,
} from "../../../store/actions/issueTrackerActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import addIssueStyles from "../../../Styles/jss/addIssueStyles";
import { useHistory } from "react-router-dom";
import Popper from "@material-ui/core/Popper";
import { ClickAwayListener, Paper } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import CheckIcon from "@material-ui/icons/Check";
import Chip from "@material-ui/core/Chip";
import { useParams } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export default function NewIssue({ issue, fun, btnTitle }) {
  let { listId, issueId } = useParams();
  let history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [labels, setLabels] = React.useState([]);
  const [showCreateLabel, setShowCreateLabel] = React.useState(false);

  const [labelData, setLabelData] = React.useState({
    name: "",
    color: "",
    domainId: "",
  });

  const [selectedLabels, setSelectedLabels] = React.useState([]);
  const [searchLabels, setSearchLabels] = React.useState("");
  const loading = useSelector((state) =>
    btnTitle === "Update"
      ? state.issueTracker.updateIssueLoading
      : state.issueTracker.addIssueLoading
  );
  const members = useSelector((state) => state.issueTracker.members);
  const milestones = useSelector((state) => state.issueTracker.milestones);
  const origLabels = useSelector((state) => state.issueTracker.labels);
  const dispatch = useDispatch();

  const addLabelLoading = useSelector((state) => state.issueTracker.addLabelLoading);

  const { selectedDomainId } = useSelector((state) => state.dashboard);

  const [file, setFile] = React.useState({
    filename: "",
    fileSize: "",
    fileData: "",
    contentType: "",
  });

  const [issueFields, setIssueFields] = React.useState({
    domainId: "",
    listId: "",
    issue: {
      title: "",
      type: "",
      assignee: "",
      assignee_id: "",
      due_date: "",
      milestone: "",
      milestone_id: "",
      description: "<p>Write here </p>",
      status: "open",
      confidental: "confidental",
      label_ids: [],
    },
    attachment: {
      filename: "",
      fileSize: "",
      fileData: "",
      contentType: "",
    },
  });

  React.useEffect(() => {
    if (issue) {
      setIssueFields({
        ...issueFields,
        issue: {
          title: issue.title,
          type: issue.type,
          assignee: issue.assignee,
          assignee_id: issue.assignee_id,
          due_date: issue.due_date,
          milestone: issue.milestone,
          milestone_id: issue.milestone_id,
          description: issue.description,
          status: issue.status,
          confidental: issue.confidental,
          label_ids: issue.label_ids,
        },
        attachment: {
          ...issue.attachment,
        },
      });
      setSelectedLabels(issue.labels)
    }
  }, [issue]);

  const classes = useStyles();

  const onDrop = React.useCallback(async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      toast.warning("Max File Size Exceeded");
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = () => {
        const binaryStr = reader.result;
        setFile({
          filename: acceptedFiles[0].name,
          fileSize: acceptedFiles[0].size,
          fileData: binaryStr,
          contentType: acceptedFiles[0].type,
        });
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
    }
    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      ".doc, .docx, .zip, .xlsx, .xls, .csv, .odt,  .gif,  .pdf, .jpeg, .png",
    minSize: 0,
    maxSize: 5242880,
  });

  const openLabels = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  function handleSelectLabels(label) {
    if (selectedLabels.filter((l) => l.id === label.id).length > 0) {
      const filterLs = selectedLabels.filter((l) => l.id !== label.id);
      setSelectedLabels([...filterLs]);
    } else {
      setSelectedLabels([...selectedLabels, label]);
    }
  }

  React.useEffect(() => {
    if (searchLabels !== "") {
      const filterlbls = origLabels.filter((lb) =>
        lb.name.startsWith(searchLabels)
      );
      setLabels(filterlbls);
    } else {
      setLabels(origLabels);
    }
  }, [searchLabels]);

  function handleSubmit(e) {
    e.preventDefault();
    let id = null;
    if (btnTitle === "Update") {
      delete issueFields["listId"];
      id = issueId;
    } else id = listId;
    dispatch(
      fun({
        ...issueFields,
        issue: {
          ...issueFields.issue,
          label_ids: selectedLabels.map((l) => l.id),
        },
        [btnTitle === "Update" ? "id" : "listId"]: id,
        domainId: selectedDomainId,
      })
    ).then(data => {
      if (data) {
        if (btnTitle !== "Update") {
          history.push('/issue-tracker/issues')
        }
      }
    })
  }

  React.useEffect(() => {
    if ((issueId || listId) && selectedDomainId) {
      dispatch(getMembers());
      dispatch(getMilestonesLists(selectedDomainId, "all")).then((res) => {
        if (res) {
          console.log(res);
          dispatch(
            getMilestones({
              domainId: selectedDomainId,
              listId: res.filter((s) => s.name.toLowerCase() === "open")[0]._id,
            })
          );
        }
      });
      dispatch(getLabels(selectedDomainId));
    }
  }, [listId, selectedDomainId]);

  React.useEffect(() => {
    if (origLabels.length > 0) {
      setLabels(origLabels);
    }
  }, [origLabels]);

  function createLabel() {
    dispatch(addNewLabel({ ...labelData, domainId: selectedDomainId })).then(
      (res) => {
        if (res) {
          setShowCreateLabel(false);
        }
      }
    );
  }

  return (
    <>
      {issueFields && (
        <form
          style={{ marginTop: "15px", padding: "15px 20px" }}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={5}>
            <CustomGrid item xs={12} sm={2}>
              Title
            </CustomGrid>
            <Grid item xs={10}>
              <TextField
                disabled={loading}
                value={issueFields.issue.title}
                defaultValue={
                  issueFields.issue.title && issueFields.issue.title
                }
                required={true}
                variant="outlined"
                id="title"
                placeholder="Title"
                fullWidth
                onChange={(event) =>
                  setIssueFields({
                    ...issueFields,
                    issue: {
                      ...issueFields.issue,
                      title: event.target.value,
                    },
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <CustomGrid item xs={12} sm={2}>
              Type
            </CustomGrid>
            <Grid item xs={10}>
              <Select
                defaultValue={issueFields.issue.type && issueFields.issue.type}
                IconComponent={(props) => <ExpandMoreIcon {...props} />}
                disabled={loading}
                variant="outlined"
                labelId="type"
                id="type"
                required={true}

                value={issueFields.issue.type}
                className={classes.selectBox}
                onChange={(event) =>
                  setIssueFields({
                    ...issueFields,
                    issue: { ...issueFields.issue, type: event.target.value },
                  })
                }
              >
                <MenuItem value={"bug"}>Bug</MenuItem>
                <MenuItem value={"vulnerability"}>Vulnerability</MenuItem>
                <MenuItem value={"improvement"}>Improvement</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <CustomGrid item xs={12} sm={2}>
              Description
            </CustomGrid>
            <Grid item xs={10}>
              {issueFields?.issue?.description && <CKEditor
                disabled={loading}
                editor={ClassicEditor}
                config={{
                  removePlugins: [
                    "CKFinderUploadAdapter",
                    "CKFinder",
                    "EasyImage",
                    "Image",
                    "ImageCaption",
                    "ImageStyle",
                    "ImageToolbar",
                    "ImageUpload",
                    "MediaEmbed",
                  ],
                }}
                data={issueFields?.issue?.description}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "250px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setIssueFields({
                    ...issueFields,
                    issue: { ...issueFields.issue, description: data },
                  });
                }}
              />}
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <CustomGrid item xs={12} sm={2}>
              Assignee
            </CustomGrid>
            <Grid item xs={10}>
              <Select
                IconComponent={(props) => <ExpandMoreIcon {...props} />}
                disabled={loading}
                variant="outlined"
                labelId="assignee"
                id="assignee"
                required={true}
                value={issueFields.issue.assignee_id}
                className={classes.selectBox}
                onChange={(event) =>
                  setIssueFields({
                    ...issueFields,
                    issue: {
                      ...issueFields.issue,
                      assignee_id: event.target.value,
                    },
                  })
                }
              >
                {members.length > 0 &&
                  members.map((mem) => {
                    return <MenuItem value={mem.id}>{mem.name}</MenuItem>;
                  })}
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <CustomGrid item xs={12} sm={2}>
              Milestone
            </CustomGrid>
            <Grid item xs={10}>
              <Select
                IconComponent={(props) => <ExpandMoreIcon {...props} />}
                disabled={loading}
                variant="outlined"
                labelId="milestone"
                id="milestone"
                required={true}

                value={issueFields.issue.milestone_id}
                className={classes.selectBox}
                onChange={(event) =>
                  setIssueFields({
                    ...issueFields,
                    issue: {
                      ...issueFields.issue,
                      milestone_id: event.target.value,
                    },
                  })
                }
              >
                {milestones.length > 0 &&
                  milestones.map((mil) => {
                    return <MenuItem value={mil.id}>{mil.title}</MenuItem>;
                  })}
              </Select>
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <CustomGrid item xs={12} sm={2}>
              Due Date
            </CustomGrid>
            <Grid item xs={10}>
              <TextField
                variant="outlined"
                required={true}
                fullWidth
                disabled={loading}
                value={issueFields.issue.due_date}
                onChange={(event) => {
                  setIssueFields({
                    ...issueFields,
                    issue: {
                      ...issueFields.issue,
                      due_date: event.target.value,
                    },
                  });
                }}
                style={{ maxWidth: 300 }}
                type="datetime-local"
              />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <CustomGrid item xs={12} sm={2}>
              Labels
            </CustomGrid>
            <Grid item xs={10}>
              <Box
                border={1}
                onClick={openLabels}
                display="flex"
                justifyContent="space-between"
                borderColor="#babac0"
                borderRadius={4}
                style={{
                  padding: "10.5px 14px",
                  maxWidth: 300,
                }}
              >
                {selectedLabels.length > 0 ? (
                  <Box display="flex" flexWrap="wrap">
                    {selectedLabels.map((l) => {
                      return (
                        <Chip
                          size="small"
                          label={l.name}
                          variant="outlined"
                          style={{ margin: "0px 5px 5px 5px" }}
                          onDelete={() => handleSelectLabels(l)}
                        />
                      );
                    })}
                  </Box>
                ) : (
                  "Labels"
                )}
                <ExpandMoreIcon />
              </Box>
            </Grid>

            <Popper
              open={open}
              anchorEl={anchorEl}
              placement="top"
              style={{ width: "100%", maxWidth: 300, zIndex: 99999 }}
            >
              {!showCreateLabel ? (
                <Box p={1.5} component={Paper}>
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontWeight: 600,
                      textAlign: "center",
                      marginBottom: 10,
                    }}
                  >
                    Select Label
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={searchLabels}
                    onChange={(e) => setSearchLabels(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    labelWidth={0}
                  />
                  <Box px={2} py={1}>
                    {labels.length > 0 ? (
                      <>
                        {labels.map((label) => {
                          const selected =
                            selectedLabels.filter((l) => l.id === label.id)
                              .length > 0;
                          return (
                            <Box
                              display="flex"
                              alignItems="center"
                              width="100%"
                            >
                              {selected && (
                                <CheckIcon
                                  fontSize="small"
                                  style={{ marginLeft: -20 }}
                                />
                              )}
                              <Box
                                display="flex"
                                alignItems="center"
                                width
                                py={0.5}
                                px={0.5}
                                style={{ cursor: "pointer" }}
                                onClick={() => handleSelectLabels(label)}
                              >
                                <Box
                                  borderRadius={4}
                                  style={{ backgroundColor: label.color }}
                                  p={1}
                                  mr={1}
                                />{" "}
                                <span>{label.name}</span>{" "}
                              </Box>
                            </Box>
                          );
                        })}

                        <Typography
                          variant="body2"
                          color="dark"
                          style={{
                            textAlign: "center",
                            border: "1px solid grey",
                            cursor: "pointer",
                            marginTop: 20,
                          }}
                          onClick={() => setShowCreateLabel(true)}
                        >
                          Create Label
                        </Typography>
                      </>
                    ) : (
                      <Typography
                        variant="body2"
                        color="dark"
                        style={{
                          textAlign: "center",
                          border: "1px solid grey",
                          cursor: "pointer",
                          marginTop: 20,
                        }}
                        onClick={() => setShowCreateLabel(true)}
                      >
                        Create Label
                      </Typography>
                    )}
                  </Box>
                </Box>
              ) : (
                <Box p={1.5} component={Paper}>
                  <ArrowBackIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowCreateLabel(false)}
                  />
                  <TextField
                    disabled={addLabelLoading}
                    type="text"
                    value={labelData.name}
                    variant="outlined"
                    id="label-name"
                    placeholder="Label Name"
                    fullWidth
                    required={true}
                    onChange={(e) =>
                      setLabelData({ ...labelData, name: e.target.value })
                    }
                    style={{ margin: "8px 0px" }}
                  />
                  <TextField
                    disabled={addLabelLoading}
                    type="color"
                    required={true}
                    value={labelData.color}
                    variant="outlined"
                    id="label-color"
                    placeholder="color"
                    fullWidth
                    onChange={(e) =>
                      setLabelData({ ...labelData, color: e.target.value })
                    }
                    style={{ margin: "8px 0px" }}
                  />
                  <Box textAlign="center">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => createLabel()}
                      disabled={addLabelLoading}
                    >
                      Add Label
                    </Button>
                  </Box>
                </Box>
              )}
            </Popper>

          </Grid>

          <Grid container spacing={5}>
            <CustomGrid item xs={12} sm={2}>
              Attachments
            </CustomGrid>
            <Grid item xs={10}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                my={2}
                py={2}
                flexDirection="column"
                style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.42)" }}
              >
                <div {...getRootProps()}>
                  <input {...getInputProps()} disabled={loading} />
                  {!file.filename ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <CloudUploadOutlinedIcon
                        style={{
                          fontSize: "30px",
                          color: "rgb(31, 58, 147)",
                        }}
                      />
                      <MuiTypography>Attach a File</MuiTypography>
                    </Box>
                  ) : (
                    <div>{file.filename}</div>
                  )}
                </div>
              </Box>
            </Grid>
          </Grid>

          {/* <div className={classes.buttonRow}> */}

          <Grid item xs={12}>
            <Box display={"flex"} justifyContent={"center"}>
              <Button
                variant="contained"
                disabled={loading}
                style={{ margin: 15 }}
                type="submit"
                color="primary"
              >
                {btnTitle} Issue{" "}
                {loading && (
                  <CircularProgress
                    style={{ color: "black", marginLeft: "0.5rem" }}
                    size={20}
                  />
                )}
              </Button>
            </Box>
          </Grid>

          {/* </div> */}
        </form>
      )}
    </>
  );
}

const useStyles = makeStyles(addIssueStyles);

const CustomGrid = withStyles((theme) => ({
  root: {
    textAlign: "right",
    fontSize: "0.875rem",
    lineHeight: 2,
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
}))(Grid);
