import * as userActions from '../auth/store/actions/';
import auth0Service from '../services/auth0Service';
// import * as Actions from 'app/store/actions';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Loader from '../components/commons/Loader';

function Callback(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    auth0Service.onAuthenticated(() => {
      // dispatch(Actions.showMessage({ message: 'Logging in with Auth0' }));

			/**
			 * Retrieve user data from Auth0
			 */
      auth0Service.getUserData().then(tokenData => {
        dispatch(userActions.setUserData(tokenData));
        // dispatch(Actions.showMessage({ message: 'Logged in with Auth0' }));
      });
    });

  }, [dispatch]);

  return <Loader />;
}

export default Callback;

