 
import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { toast } from 'react-toastify';

export const HELP_AND_SUPPORT = 'HELP_AND_SUPPORT';
export const HELP_AND_SUPPORT_LOADING = 'HELP_AND_SUPPORT_LOADING';

export const sendRequest = (file, inquiryType, urgencyType, mainSubject, description) => async (dispatch) => {

	dispatch({
		type: HELP_AND_SUPPORT_LOADING
	});

	try {
		const token = auth0Service.getAccessToken();

		const options = {
			headers: {
				'Content-Type': 'application/json',
				"Authorization": `Bearer ${token}`
			}
		};

		const res = await axios.post(`${BASE_URL}/dashboard/help-and-support`, {
			file,
			inquiryType: inquiryType,
			urgencyLevel: urgencyType,
			subject: mainSubject,
			description: description,
		}, options);

		if (res.data.message === "Inquiry Submitted") {
			dispatch({
				type: HELP_AND_SUPPORT_LOADING
			});
			toast.success("Inquiry Submitted");
		}
		else {
			dispatch({
				type: HELP_AND_SUPPORT_LOADING
			});
			toast.error("An Error Occured");
		}
	} catch (err) {
		dispatch({
			type: HELP_AND_SUPPORT_LOADING
		});
		toast.error("An Error Occured");
		if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
	}
}