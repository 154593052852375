import axios from 'axios';
import jwtDecode from 'jwt-decode';
/* eslint-disable camelcase */
// eslint-disable-next-line max-classes-per-file
// import _ from '../../@lodash';
import { BASE_URL } from '../../source/constants';

class EventEmitter {
	constructor() {
		this.events = {};
	}

	_getEventListByName(eventName) {
		if (typeof this.events[eventName] === 'undefined') {
			this.events[eventName] = new Set();
		}
		return this.events[eventName];
	}

	on(eventName, fn) {
		this._getEventListByName(eventName).add(fn);
	}

	once(eventName, fn) {
		const self = this;

		const onceFn = (...args) => {
			self.removeListener(eventName, onceFn);
			fn.apply(self, args);
		};
		this.on(eventName, onceFn);
	}

	emit(eventName, ...args) {
		this._getEventListByName(eventName).forEach(
			// eslint-disable-next-line func-names
			function (fn) {
				fn.apply(this, args);
			}.bind(this)
		);
	}

	removeListener(eventName, fn) {
		this._getEventListByName(eventName).delete(fn);
	}
}

class JwtService extends EventEmitter {
	init() {
		this.setInterceptors();
		this.handleAuthentication();
	}

	setInterceptors = () => {
		axios.interceptors.response.use(
			response => {
				return response;
			},
			err => {
				return new Promise((resolve, reject) => {
					console.error(err)
					if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
						// if you ever get an unauthorized response, logout the user
						this.emit('onAutoLogout', 'Invalid access_token');
						this.setSession(null);
					}
					throw err;
				});
			}
		);
	};

	handleAuthentication = () => {
		const access_token = this.getAccessToken();

		if (!access_token) {
			this.emit('onNoAccessToken');

			return;
		}

		if (this.isAuthTokenValid(access_token)) {
			this.setSession(access_token);
			this.emit('onAutoLogin', true);
		} else {
			this.setSession(null);
			this.emit('onAutoLogout', 'access_token expired');
		}
	};

	createUser = data => {
		return new Promise((resolve, reject) => {
			axios.post(`${BASE_URL}/auth/register`, data).then(response => {
				if (response.data.data.user) {
					this.setSession(response.data.data.token);
					resolve(response.data.data.user);
				} else {
					reject(response.data.error);
				}
			});
		});
	};

	signInWithEmailAndPassword = (email, password) => {
		return new Promise((resolve, reject) => {
			axios
				.post(`${BASE_URL}/auth/login`, {
					email,
					password
				})
				.then(response => {
					if (response.data.data.user) {
						this.setSession(response.data.data.token);
						resolve(response.data.data.user);
					} else {
						reject(response.data.data.error);
					}
				});
		});
	};

	signInWithToken = () => {
		return new Promise((resolve, reject) => {
			axios
				.get(`${BASE_URL}/auth/check-login`, {
					data: {
						token: this.getAccessToken()
					}
				})
				.then(response => {
					if (response.data.data.user) {
						this.setSession(response.data.data.token);
						resolve(response.data.data.user);
					} else {
						this.logout();
						Promise.reject(new Error('Failed to login with token.'));
					}
				})
				.catch(error => {
					this.logout();
					Promise.reject(new Error('Failed to login with token.'));
				});
		});
	};

	updateUserData = ({ name, email, image, isImageChanged }) => {
		return axios.post(`${BASE_URL}/auth/edit-user`, {
			name, email, image, isImageChanged
		});
	};

	updateUserPassword = ({ password, cPassword }) => {
		return axios.post(`${BASE_URL}/auth/reset-password`, {
			password, cPassword
		});
	};

	setSession = access_token => {
		if (access_token) {
			localStorage.setItem('jwt_access_token', access_token);
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
		} else {
			localStorage.removeItem('jwt_access_token');
			delete axios.defaults.headers.common.Authorization;
		}
	};

	logout = () => {
		this.setSession(null);
	};

	isAuthTokenValid = access_token => {
		if (!access_token) {
			return false;
		}
		const decoded = jwtDecode(access_token);
		const currentTime = Date.now() / 1000;
		if (decoded.exp < currentTime) {
			console.warn('access token expired');
			return false;
		}
		return true;
	};

	getAccessToken = () => {
		return window.localStorage.getItem('jwt_access_token');
	};
}

const instance = new JwtService();

export default instance;
