import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import { checklistItem } from '../../store/actions/checklistActions';

export default function CheckListItem({ item, index, checkListId, mainCheckItems, setMainCheckItems }) {

  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleToggle = (item, event) => {
    item = { checkItemId: item._id, checked: event.target.checked }
    dispatch(checklistItem(item))
  };

  const type = useSelector((state) => state.checklists.type);

  const handleMainItems = (item, event) => {
    const getList = mainCheckItems.filter(checklist => checklist._id === checkListId)[0];
    const filteredItems = getList.checkItems.map(checkItem => {
      const match = checkItem.name === item.name;
      if (match) {
        return ({ ...checkItem, checked: event.target.checked })
      } else {
        return checkItem
      }
    })
    const modifiedList = mainCheckItems.map(listo => {
      const ifModifiedList = listo._id === checkListId;
      if (ifModifiedList) {
        return { ...listo, checkItems: filteredItems }
      } else {
        return listo
      }
    })
    setMainCheckItems(modifiedList);
  }

  return (
    <>
      <ListItem key={item} role={undefined} dense>
        <ListItemIcon>
          <Checkbox
            defaultChecked={item.checked}
            edge="start"
            disableRipple
            onChange={(event) => {
              if (type === "list") {
                handleToggle(item, event)
              } else {
                handleMainItems(item, event)
              }
            }}
            inputProps={{ 'aria-labelledby': `checkbox-list-label-${index}` }}
          />
        </ListItemIcon>
        <ListItemText id={`checkbox-list-label-${index}`} primary={item.name} />
        <ListItemSecondaryAction onClick={() => setOpen(!open)}>
          <IconButton edge="end" aria-label="comments">
            <ErrorOutlineIcon style={{ fontSize: '1.3rem' }} />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box p={2}>{item.name}</Box>
      </Collapse>
    </>
  )
}

