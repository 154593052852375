import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

export default function Pagination({ totalPages, currentPage, onPageChange }) {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "10px" }}>
      {currentPage > 1 && (
        <IconButton onClick={() => onPageChange(currentPage - 1)}>
          <ArrowBackIcon />
        </IconButton>
      )}
      {pageNumbers.map((page) => (
        <Button
          key={page}
          onClick={() => onPageChange(page)}
          style={{
            borderRadius: "20px",
            margin: "5px",
            backgroundColor: currentPage === page ? "#007bff" : "#fff",
            color: currentPage === page ? "#fff" : "#007bff",
          }}
        >
          {page}
        </Button>
      ))}
      {currentPage < totalPages && (
        <IconButton onClick={() => onPageChange(currentPage + 1)}>
          <ArrowForwardIcon />
        </IconButton>
      )}
    </div>
  );
}
