import React from 'react';
import {
  alpha,
  withStyles,
} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';


const BootstrapInput = withStyles((theme)=> ({

  root: {
    width:(props) => props.width ? props.width : 'auto',
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width:'100%',
    padding: '8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);




export default function CustomizedInputs(props) {

  return (
        <BootstrapInput {...props} />
    

  );
}
