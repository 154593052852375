import React, { Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import loaderwave from "../assets/loader.svg";
import AddDomain from "../components/commons/AddDomain";
import { CircularProgress } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import {
  getSingleScanProgress,
  sendReport,
} from "../store/actions/onBoardingActions";
import Button from "@material-ui/core/Button";
import Auth0Service from "../services/auth0Service/authentikService";
import homepageStyles from "../Styles/jss/homepageStyles";
import ScanSelector from "../components/commons/ScanSelector";
import "../Styles/css/homepage.css"
import { useHistory } from 'react-router-dom';

const Boxes = React.lazy(() => import("../components/commons/Boxes"));

const useStyles = makeStyles(homepageStyles);

export default function HomePage() {
  const classes = useStyles();
  const history = useHistory();

  // const [isTourOpen, setIsTourOpen] = React.useState(false);
  const [emailReport, setEmailReport] = React.useState({ email: "", name: "" });

  const selectedScanId = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );

  const selectedDomain = useSelector(
    (state) => state.miscellaneous.selectedDomain
  );

  const scanProgress = useSelector(
    (state) => state.onBoardDashboard.scanProgress
  );
  const reportLoading = useSelector(
    (state) => state.onBoardDashboard.sendReportLoading
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (selectedScanId) {
      dispatch(getSingleScanProgress(selectedScanId));
    }
    // eslint-disable-next-line
  }, [selectedScanId]);

  React.useEffect(() => {
    if (
      scanProgress.status === "running" ||
      scanProgress.status === "pending"
    ) {
      const interval = setInterval(() => {
        if (selectedScanId) {
          dispatch(getSingleScanProgress(selectedScanId));
        }
      }, 5000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [scanProgress]);

  function sendResults(event) {
    event.preventDefault();
    dispatch(sendReport(selectedScanId, emailReport));
  }

  const [delayed, setDelayed] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setDelayed(false)
    }, 1500);
  }, [])

  // React.useEffect(() => {
  //   console.log(selectedDomain);
  //   if (selectedDomain) {
  //     const currentUrl = window.location.href;
  //     let hostname = window.location.hostname;
  //     const pathname = window.location.pathname;

  //     // Logic based on URL content
  //     let checkenvironment = 'unknown';
  //     if (hostname.includes('dashboard.allyright.com')) {
  //       hostname = 'demo.allyright.com';
  //     } else if (hostname.includes('demo.allyright.com')) {
  //       hostname = `https://dashboard.allyright.com`;
  //     } else if (hostname.includes('localhost')) {
  //       hostname = 'demo.allyright.com';
  //       const newUrl = currentUrl.replace(window.location.hostname, hostname);
  //       // Redirect to the new URL
  //       window.location.href = newUrl.split('/')[0];
  //     }
  //   }
  // }, [])

  const saveSelectedDomainToLocalStorage = () => {
    localStorage.setItem('selectedDomain', selectedDomain);
    history.push(`/authenticate?website=${selectedDomain}`);
    window.location.reload();
  };

  const handleButtonClick = (selectedDomain) => {
    history.push(`/authenticate?website=${selectedDomain}&user=new`);
    window.location.reload();
  };

  const handleButtonClickOld = (selectedDomain) => {
    history.push(`/authenticate?website=${selectedDomain}&user=old`);
    window.location.reload();
  };

  return (
    <>
      <Suspense
        fallback={
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            tabIndex="0"
          >
            <img src={loaderwave} alt="loader" />
          </Box>
        }
      >
        {!delayed ?
          <>

            {selectedScanId ? (
              <div className={classes.root}>
                {selectedScanId ? (
                  <>
                    <ScanSelector />

                    {scanProgress.status === "pending" && (
                      <Box p={1.5} className={classes.scanProgress} textAlign="center">
                        <Box width="100%">
                          {" "}
                          <h1 className={classes.formText}>
                            {selectedDomain}  {scanProgress.percentage < 11 ? " is being crawled" : scanProgress.percentage < 100 ? "is being scanned" : 'scan is completed'}
                          </h1>
                        </Box>
                        <Box minWidth={100} mr={1} mt={1}>

                          <Typography variant="subtitle2">
                            Total Links: {scanProgress.totalLinks === 0 ? "fetching" : scanProgress.totalLinks}
                          </Typography>

                        </Box>
                        {scanProgress.status === "pending" && <LinearProgressWithLabel
                          message={scanProgress.message}
                          value={scanProgress.percentage}
                        />
                        }
                      </Box>
                    )}
                    <div data-tut="Domain_Summary" style={{ marginTop: 25 }}>
                      <Boxes scanId={selectedScanId} />

                    </div>
                    {!Auth0Service.isAuthenticated() && <>
                      {scanProgress.status === "completed" && (
                        <>
                          <Box textAlign="center" mt={5} mb={4}>
                            <Typography variant="h6" >
                              Get Detailed Report
                            </Typography>
                            <Box my={3}>
                            </Box>
                          </Box>
                          <Paper component="form" onSubmit={sendResults} className={classes.root3}>
                            <Input
                              onChange={(event) => {
                                setEmailReport({ ...emailReport, name: event.target.value });
                              }}
                              placeholder="Name"
                              inputProps={{
                                "aria-label": "Name",
                                type: "text",
                                required: true,
                              }}
                              style={{ width: 200, margin: 10 }}
                            />
                            <Input
                              onChange={(event) => {
                                setEmailReport({ ...emailReport, email: event.target.value });
                              }}
                              placeholder="Email"
                              inputProps={{
                                "aria-label": "Email",
                                type: "email",
                                required: true,
                              }}
                              style={{ width: 200, margin: 10 }}
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                              style={{ borderRadius: "20px" }}
                              disabled={reportLoading}
                            >
                              Send Report
                              {reportLoading && (
                                <CircularProgress
                                  style={{ color: "black", marginLeft: "1rem" }}
                                  size={20}
                                />
                              )}
                            </Button>
                            <h4>OR</h4>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                              <Button color="primary" style={{ margin: "5px" }} variant="contained" onClick={() => {
                                handleButtonClickOld(selectedDomain);
                              }} >Buy Now As an Existing User</Button>
                              <Button color="primary" style={{ margin: "5px" }} variant="contained" onClick={() => {
                                handleButtonClick(selectedDomain);
                              }}>Buy Now As a New User</Button>
                            </div>
                          </Paper>
                        </>
                      )}
                    </>
                    }
                  </>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                    tabIndex="0"
                  >
                    {" "}
                    <img src={loaderwave} alt="loader" />
                  </Box>
                )}
              </div>
            ) : (
              <AddDomain />
            )}</> :
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            tabIndex="0"
          >
            <img src={loaderwave} alt="loader" />
          </Box>
        }

        {/* <IssueTrac /> */}
      </Suspense>
    </>
  );
}

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center" mt={1}>
      <Box width="100%" mr={1}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 10,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[100],
  },
  bar: {
    borderRadius: 10,
    backgroundColor: "#4BB543",
  },
}))(LinearProgress);
