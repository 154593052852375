import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
// import TablePagination from '@material-ui/core/TablePagination';
import CveModal from './CveModal';
import { useDispatch } from "react-redux";

import Chip from '@material-ui/core/Chip';
import cveTableStyles from '../../Styles/jss/cveTableStyles';

import { getCVES } from "../../store/actions/technologiesActions";
import Pagination from './Pagination';


const useStyles = makeStyles(cveTableStyles);

export default function CveTable({ data, techs }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [cveData, setCveData] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  function cveClick(cve) {
    handleClickOpen();
    setCveData(cve)
  }

  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getCVES(techs,newPage))


  };



  return (
    <>
      <Paper className={classes.root}>
        {data && data?.result.length > 0 ?
          <>
            <TableContainer className={classes.container}>
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tHead} style={{ width: "200px" }}>
                      ID
                    </TableCell>
                    <TableCell className={classes.tHead} style={{ width: "600px" }}>
                      Description
                    </TableCell>
                    <TableCell className={classes.tHead} style={{ width: "200px" }}>
                      Severity
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.result.map((row, index) => {
                    return (
                      <TableRow hover key={index}>
                        <TableCell
                          role="checkbox" key={row.code}>
                          <span onClick={() => {
                            cveClick(row)
                          }} className={classes.buttonColor} style={{ cursor: "pointer" }}>
                            {row?.cve?.CVE_data_meta?.ID}
                          </span>
                        </TableCell>
                        <TableCell>
                          {row.cve.description.description_data[0].value}
                        </TableCell>
                        <TableCell>
                          {(row.impact.baseMetricV3) ?
                            <>
                              <Chip
                                className={classes.chip}

                                style={{
                                  color: "#ffffff",
                                  backgroundColor: (row.impact.baseMetricV3.cvssV3.baseSeverity && row.impact.baseMetricV3.cvssV3.baseSeverity === "HIGH") ? "#f87b1e" : (row.impact.baseMetricV3.cvssV3.baseSeverity && row.impact.baseMetricV3.cvssV3.baseSeverity === "MEDIUM") ? "rgb(255 160 18 / 75%)" : (row.impact.baseMetricV3.cvssV3.baseSeverity && row.impact.baseMetricV3.cvssV3.baseSeverity === "LOW") ? "#21CA5A" : (row.impact.baseMetricV3.cvssV3.baseSeverity && row.impact.baseMetricV3.cvssV3.baseSeverity === "CRITICAL") ? "#E2211E" : (row.impact.baseMetricV3.cvssV3.baseSeverity === undefined && row.impact.baseMetricV3.cvssV3.baseSeverity === '') ? "#777779" : "white"
                                }}
                                size="small"
                                label={row.impact.baseMetricV3.cvssV3.baseSeverity}
                              />
                            </>
                            :
                            <>
                              <Chip
                                className={classes.chip}
                                style={{
                                  color: "#ffffff",
                                  backgroundColor: "#777779"
                                }}
                                size="small"
                                label={"NO SCORE"}
                              />
                            </>
                          }
                        </TableCell>

                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Pagination /> */}
            <Pagination
              rowsPerPageOptions={[5, 10, 15]}
              count={data && data.pages}
              page={data ? data.page : 0}
              handleChangePage={handleChangePage}
              setRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
            />
          </> : <Box width="100%" display="flex" justifyContent="center" alignItems="center" p={2}> <span style={{ fontFamily: "Segoe UI", fontSize: 18 }} >No CVE's Found</span></Box>
        }
      </Paper>

      <CveModal cveData={cveData} open={open} handleClose={handleClose} />
    </>

  );
}
