import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { getSection508CardDetails } from "../../../store/actions/accessibilityCardActions";
import loader from "../../../assets/loader.svg";
import { CardNote } from "../../accessibility-main/components/CardNote";
import LinearProgress from "@material-ui/core/LinearProgress";
import allIcon from "../../../assets/new-accessibility-icons/all.png";
import hearingIcon from "../../../assets/new-accessibility-icons/hearing.svg";
import visualIcon from "../../../assets/new-accessibility-icons/visual.svg";
import motorIcon from "../../../assets/new-accessibility-icons/motor.svg";
import cognitiveIcon from "../../../assets/new-accessibility-icons/cognitive.svg";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { IconCircle } from "../../commons/icons/accessibilitymainIcons/icons/IconCircle";
import PerformanceTable from "../../PerformanceComponents/performanceCard/PerformanceTable";
import ShortenNumber from "../../commons/ShortenNumber";
import { nFormatter } from "../../../utils/shortNumber";
import { accessibilityAnalyticsStyles, accessibilityAnalyticsInlineStyles } from "../../../Styles/jss/accessibilityComponents/accessibilityAnalyticsStyles";

import scanStatus from "../../../scanStatuses";
import Arrow from "../../commons/Arrow";

const useStyles = makeStyles(accessibilityAnalyticsStyles);

const styles = {
  ...accessibilityAnalyticsInlineStyles,
};

// function parseDonutData(data) {
//   const parsedData = [];
//   const keys = Object.keys(data.violationsByPriority);
//   for (let i = 0; i < keys.length; i++) {
//     if (keys[i] === "low") {
//       parsedData.push({
//         name: "low",
//         value: data.violationsByPriority.low,
//       });
//     } else if (keys[i] === "medium") {
//       parsedData.push({
//         name: "medium",
//         value: data.violationsByPriority.medium,
//       });
//     } else {
//       parsedData.push({
//         name: "high",
//         value: data.violationsByPriority.high,
//       });
//     }
//   }
//   return parsedData;
// }

const COLORS = ["#21CA5A", "#FFA412", "#E2211E"];

function Section508Card({ scanId, setLoader }) {
  const [tab, setTab] = React.useState(0);
  const [donutData, setDonutData] = React.useState([]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const classes = useStyles();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.accessibilityCard.section508Data);
  const loading = useSelector((state) => state.accessibilityCard.section508Loading);
  const status = useSelector((state) => state.accessibilityCard.section508Status);
  const scanProgress = useSelector(
    (state) => state.onBoardDashboard.scanProgress
  );


  const allAccessibility = "all";
  React.useEffect(() => {
    if (scanId) {
      dispatch(getSection508CardDetails(scanId));
    }
    // eslint-disable-next-line
  }, [scanId]);

  React.useEffect(() => {
    if (status === scanStatus.RUNNING || status === scanStatus.PENDING) {
      // setLoader(true);
      const interval = setInterval(() => {
        if (scanId) {
          setTab(0);
          dispatch(getSection508CardDetails(scanId));
        }
      }, 15000);
      return () => clearInterval(interval);
    } else {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [status]);

  // React.useEffect(() => {
  //   if (data) {
  //     const parsedData = parseDonutData(data);
  //     setDonutData(parsedData);
  //   }
  // }, [data]);

  const columns = [

    {
      column: "Total",
      width: 64,
    },
    {
      column: "Priority",
      width: 280,
    },
  ];



  const pagesColumns = [
    {
      column: "No. of Violations",
      width: 165,
    },
    {
      column: "Pages",
      width: 100,
    },
    {
      column: "Priority",
      width: 280,
    },
  ];

  const tabs = ["Pages"];
  return (
    <React.Fragment>
      {loading ? (
        <>
          {setLoader(false)}
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            style={{ minHeight: 371 }}
          >
            <img src={loader} alt='loader' />
          </Box>
        </>
      ) : (
        <>
          {data ? (
            <>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} tabIndex='0'>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} tabIndex='0'>
                      <Box className={`${classes.boxContainer} box-container `}>
                        {tab === 0 && (
                          <img
                            src={allIcon}
                            width='59px'
                            height='59px'
                            alt='accessibility-icon'
                          />
                        )}
                        {tab === 1 && (
                          <img
                            src={cognitiveIcon}
                            width='59px'
                            height='59px'
                            alt='cognitive-icon'
                          />
                        )}
                        {tab === 2 && (
                          <img
                            src={hearingIcon}
                            width='59px'
                            height='59px'
                            alt='hearing-icon'
                          />
                        )}
                        {tab === 3 && (
                          <img
                            src={visualIcon}
                            width='59px'
                            height='59px'
                            alt='visual-icon'
                          />
                        )}
                        {tab === 4 && (
                          <img
                            src={motorIcon}
                            width='59px'
                            height='59px'
                            alt='motor-icon'
                          />
                        )}
                        <Box>
                          <Box display='flex' alignItems='center' justifyContent='center'>
                            <CardNote
                              size='big'
                              className={clsx(
                                data.scoreType === "high"
                                  ? "circle-critical"
                                  : data.scoreType === "medium"
                                    ? "circle-moderate2"
                                    : "circle-safe"
                              )}
                            >
                              {data.score}
                            </CardNote>
                            <Arrow value={data.scoreChangePercentage} change={data.scoreChange} />

                          </Box>
                          {tab === 0 && (
                            <div style={styles.scoreTitle}>
                              Accessibility Score
                            </div>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} tabIndex='0'>
                      <Box style={styles.boxContainer2}>
                        <Box width='100%'>
                          <Box
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'

                          >
                            <h3 style={styles.vulPagesTitle}>
                              Pages With Errors
                            </h3>
                            <span style={styles.vulPages}>
                              <Arrow value={data.pagesWithErrorsChangePercentage} change={data.pagesWithErrorsChange} />
                              <ShortenNumber>
                                {data.pagesWithErrors}
                              </ShortenNumber>
                            </span>
                          </Box>
                          <Box
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            mb={2.5}
                          >
                            <h3 style={styles.vulPagesTitle}>
                              Total Number of Pages
                            </h3>
                            <span style={styles.vulPages}>
                              <ShortenNumber>
                                {scanProgress.totalLinks ? scanProgress.totalLinks : 0}
                              </ShortenNumber>
                            </span>
                          </Box>
                          <Box
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                          >
                            <span style={styles.barPercentage}>
                              {data.pageWithErrorsPercentage}%
                            </span>
                            <Box width='100%'>
                              <BorderLinearProgress
                                variant='determinate'
                                value={data.pageWithErrorsPercentage}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3} tabIndex='0'>
                      <Box style={styles.boxContainer}>
                        <Box
                          display='flex'
                          justifyContent='center'
                          flexDirection='column'
                          alignItems='center'
                          height='100%'
                        >
                          <h3 style={styles.avgperPageTitle}>

                            Average <br /> Per Page
                          </h3>
                          <Box
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                          >
                            <span
                              style={styles.avgperPageValue}
                              className={clsx(
                                data.totalAverageType === "high"
                                  ? "color-critical"
                                  : data.totalAverageType === "medium"
                                    ? "color-moderate2"
                                    : "color-safe"
                              )}
                            >
                              {data.averagePerPage}
                            </span>
                            <Arrow value={data.averagePerPageChangePercentage} change={data.averagePerPageChange} />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} tabIndex='0'>
                      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%"
                        style={{
                          background: 'rgb(255, 255, 255)',
                          boxShadow: 'rgb(0 0 0 / 12%) 0px 0px 10px',
                          boxSizing: 'border-box',
                          borderRadius: 9,
                          padding: 14
                        }}
                      >
                        <h3 style={styles.avgperPageTitle}>

                          Total <br /> Violations
                        </h3>
                        <Box
                          display='flex'
                          justifyContent='center'
                          flexDirection='column'
                          alignItems='center'

                        >
                          <span
                            style={styles.avgperPageValue}
                            className={clsx(
                              data.totalAverageType === "high"
                                ? "color-critical"
                                : data.totalAverageType === "medium"
                                  ? "color-moderate2"
                                  : "color-safe"
                            )}
                          >
                            {data.totalViolations}
                          </span>

                          <Arrow value={data.totalViolationsChangePercentage} change={data.totalViolationsChange} />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} tabIndex='0'>
                      <PerformanceTable
                        noCollapse={true}
                        columns={columns}
                        columns2={pagesColumns}
                        data={data}
                        tabs={tabs}
                        totalViolations={data.totalViolations}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              className={classes.boxload}
            >
              <h3 align="center"> {scanProgress.status !== "completed" && "Currently, the scan is running. If an issue is discovered, the data will automatically populate here."}</h3>

            </Box>
          )}
        </>
      )}
    </React.Fragment>
  );
}
export default React.memo(Section508Card);

const CustomTabs = withStyles({
  root: {
    borderBottom: "none",
  },
  indicator: {
    backgroundColor: "white",
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    minWidth: 40,
    opacity: 1,
    minHeight: "auto",
    borderRadius: "2px",
    padding: "0px 8px",
    border: "1px solid #1f3a93",
    color: "#1f3a93",
    fontWeight: 560,
    fontSize: "17px",
    fontFamily: "Segoe UI",
    margin: "0px 10px",
    "&:hover": {
      color: "white",
      backgroundColor: "#1f3a93",
      opacity: 1,
    },
    "&$selected": {
      color: "white",
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: "#1f3a93",
    },
    "&:focus": {
      color: "white",
      backgroundColor: "#1f3a93",
    },
  },
  selected: {
    color: "white",
    backgroundColor: "#1f3a93",
  },
}))((props) => <Tab disableRipple {...props} />);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 10,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 10,
    backgroundColor: "#1f3a93",
  },
}))(LinearProgress);
