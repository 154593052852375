import {
  LOADING_TAGS,
  GET_NUMBER_OF_PAGES_WITH_DETAILS,
  GET_HEADINGS_HISTORY,
  LOADING_HEADINGS_HISTORY,
  RESET_LOADING_HEADINGS_HISTORY,
} from '../actions/numberOfPagesWithCardActions';

const initialState = {

  data: null,
  loading: false,
  status: null,

  headingHistory:null,
  headingHistoryLoading:false,
  headingHistoryStatus:null,


};

 const numberOfPagesWithReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NUMBER_OF_PAGES_WITH_DETAILS:
      return {
        ...state,
        data: action.payload.result,
        status: action.payload.status,
        loading: false
      }
    case LOADING_TAGS:
      return {
        ...state,
        loading: true
      }

    case GET_HEADINGS_HISTORY:
      return {
        ...state,
        headingHistory: action.payload.result.graph,
        headingHistoryStatus: action.payload.status,
        headingHistoryLoading: false
      }
    case LOADING_HEADINGS_HISTORY:
      return {
        ...state,
        headingHistoryLoading: true
      }
      case RESET_LOADING_HEADINGS_HISTORY:
      return {
        ...state,
        headingHistory: null,
        headingHistoryStatus:null,
      };
    default:
      return state;
  }
};

export default numberOfPagesWithReducer;