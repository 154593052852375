import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Paper,
} from '@material-ui/core';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import WarningIcon from '@material-ui/icons/Warning';
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { startRescan, DeleteScan } from "../../store/actions/abracadabraActions";
import CustomCardContainerHead from "../../components/commons/CustomCardContainerHead";
import { toast } from "react-toastify";

const buttonStyle = {
  display: 'block',
  margin: 'auto',
  fontSize: '18px',
  color: 'white',
  backgroundColor: 'rgb(31, 58, 147)',
  height: '8vh', // Adjusted height using viewport height units
  width: '100%', // Adjusted width to fill the container
};



export default function Rescan({ domain }) {
  const [loadingLinks, setLoadingLinks] = useState(true);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    // getContactDetails();
  }, [])

  const rescanWebsite = async () => {
    try {
      const response = await dispatch(startRescan(domain));
      console.log(response.response);
      if (response.response.message) {
        setLoadingLinks(false);
        toast.success(response.response.message);
      } else {
        setLoadingLinks(false);
        // toast.error("Failed to retrieve contact details.");
      }
    } catch (error) {
      setLoadingLinks(false);
      // console.log(error);
      toast.error("Failed to retrieve details.");
    }
    // toast.warning(`Not implemented yet`)
  }

  const deleteWebsiteScan = async () => {
    try {
      const response = await dispatch(DeleteScan(domain));
      console.log(response.response);
      if (response.response.message) {
        setLoadingLinks(false);
        toast.success(response.response.message);
      } else {
        setLoadingLinks(false);
        // toast.error("Failed to retrieve contact details.");
      }
    } catch (error) {
      setLoadingLinks(false);
    }
    // toast.warning(`Not implemented yet`)
  }

  return (
    <>
      {/* {scanStatus === "completed" ? (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: 20, backgroundColor: 'white' }} >
          <Typography variant="h4">
            Scan is Completed!
          </Typography>
        </div>
      ) : scanStatus === "pending" ? (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: 20, backgroundColor: 'white' }} >
          <Typography variant="h4">
            Scan is running...
          </Typography>
        </div>
      ) : null} */}
      <CustomCardContainerHead title="Scan Management" titleTag="h1">
        {/* <Paper elevation={5}> */}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 20 }} >
          {/* <WarningIcon style={{ fontSize: '36px', color: "gray" }} /> */}
          <Typography variant="h2" style={{ fontSize: '24px' }}>
            {"Press the below buttons to allow Abracadabra Box to rescan and delete the website."}
            {/* Total Number of Issues: {unfixedImagesCount === 0 ? (issuesInfo.length - 1) : (issuesInfo.length)} */}
          </Typography>
        </div>
        <Grid container style={{ width: '100%', marginRight: '30%', marginLeft: '30%' }}>
          <Box p={2} style={{ margin: '20px 20px' }}>

            <Box mb={0}>
              <Grid container spacing={3} justify="center">
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={3} style={{ minWidth: "500px", maxWidth: "500px" }}>
                      <Typography variant="h3" style={{ fontSize: '20px' }}>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>



        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={2}>
            <Button
              style={buttonStyle}
              variant="contained"
              color="primary"
              onClick={() => rescanWebsite()}
            >
              Rescan Website
            </Button>
          </Grid>
          {/* <Grid item xs={2}>
            <Button
              style={{
                display: 'block',
                margin: 'auto',
                fontSize: '18px',
                color: 'white',
                backgroundColor: 'rgb(31, 58, 147)',
              }}
              variant="contained"
              color="primary"
              onClick={() => rescanWebsite()}
            >
              Add Domain
            </Button>
          </Grid> */}
          <Grid item xs={2}>
            <Button
              style={buttonStyle}
              variant="contained"
              color="primary"
              onClick={() => deleteWebsiteScan()}
            >
              Delete Website Scan
            </Button>
          </Grid>
        </Grid>
      </CustomCardContainerHead>
    </>
  );
}
