import React, { Suspense, useState } from "react";
import SecurityStatCard from "./SecurityStatCard";
import SubDomainTakeOverCard from "./SubDomainTakeOverCard";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { useSelector, useDispatch } from "react-redux";
import {
  xssResults,
  openDBChecker,
  sslChecker,
  xssChecker,
  sqliChecker,
  subDomainTakeOverChecker,
  ddosChecker,
  corsChecker,
  cveChecker,
  breachedChecker,
  lfiChecker,
  securityMain,
  securityMainTop5,
} from "../../../../store/actions/securityActions";
import SecurityCardLoader from "./SecurityCardLoader";
import PerformanceSummary from "../../../PerformanceComponents/performanceCard/PerformanceSummary";
import loaderwave from "../../../../assets/loader.svg";
import { Block } from "@material-ui/icons";

function SecurityBoxes({ scanId, setLoader }) {
  const sqli = useSelector((state) => state.security.sqli);
  const sqliLoading = useSelector((state) => state.security.sqliLoading);
  const sqliStatus = useSelector((state) => state.security.sqliStatus);
  const [displaySqli, setDisplaySqli] = useState(1);

  const xss = useSelector((state) => state.security.xss);
  const xssLoading = useSelector((state) => state.security.xssLoading);
  const xssStatus = useSelector((state) => state.security.xssStatus);
  const [displayXss, setDisplayXss] = useState(1);

  const ssl = useSelector((state) => state.security.ssl);
  const sslLoading = useSelector((state) => state.security.sslLoading);
  const sslStatus = useSelector((state) => state.security.sslStatus);
  const [displaySsl, setDisplaySsl] = useState(1);

  const openDB = useSelector((state) => state.security.openDB);
  const openDBLoading = useSelector((state) => state.security.openDBLoading);
  const openDBStatus = useSelector((state) => state.security.openDBStatus);
  const [displayOpenDb, setDisplayOpenDb] = useState(1);

  const subDomainTakeOver = useSelector(
    (state) => state.security.subDomainTakeOver
  );
  const subDomainTakeOverLoading = useSelector(
    (state) => state.security.subDomainTakeOverLoading
  );
  const subDomainTakeOverStatus = useSelector(
    (state) => state.security.subDomainTakeOverStatus
  );
  const [displaySTS, setDisplaySTS] = useState(1);

  const cors = useSelector((state) => state.security.cors);
  const corsLoading = useSelector((state) => state.security.corsLoading);
  const corsStatus = useSelector((state) => state.security.corsStatus);
  const [displayCors, setDisplayCors] = useState(1);

  const cve = useSelector((state) => state.security.cve);
  const cveLoading = useSelector((state) => state.security.cveLoading);
  const cveStatus = useSelector((state) => state.security.cveStatus);
  const [displayCve, setDisplayCve] = useState(1);

  const breached = useSelector((state) => state.security.breached);
  const [displayBreach, setDisplayBreach] = useState(1);
  const breachedLoading = useSelector(
    (state) => state.security.breachedLoading
  );
  const breachedStatus = useSelector((state) => state.security.breachedStatus);

  const ddos = useSelector((state) => state.security.ddos);
  const ddosLoading = useSelector((state) => state.security.ddosLoading);
  const ddosStatus = useSelector((state) => state.security.ddosStatus);
  const [displayDdos, setDisplayDdos] = useState(1);

  const lfi = useSelector((state) => state.security.lfi);
  const lfiLoading = useSelector((state) => state.security.lfiLoading);
  const lfiStatus = useSelector((state) => state.security.lfiStatus);
  const [displayLfi, setDisplayLfi] = useState(1);

  const securityMainData = useSelector((state) => state.security.securityMain);
  const securityMainLoading = useSelector(
    (state) => state.security.securityMainLoading
  );
  const securityMainStatus = useSelector(
    (state) => state.security.securityMainStatus
  );
  const [displaySMS, setDisplaySMS] = useState(1);

  const securityMainTop5Data = useSelector(
    (state) => state.security.securityMainTop5
  );
  const securityMainTop5Loading = useSelector(
    (state) => state.security.securityMainTop5Loading
  );
  const securityMainTop5Status = useSelector(
    (state) => state.security.securityMainTop5Status
  );

  const dispatch = useDispatch();

  // React.useEffect(() => {
  //   set_display_sqli(sqli.vulnerabilitiesFound);
  //   console.log(display_sqli);
  //   set_display_xss(xss);
  //   console.log(xss.vulnerabilitiesFound);
  // }, []);

  React.useEffect(() => {
    if (scanId) {
      dispatch(securityMain(scanId));
      dispatch(xssResults(scanId));
      dispatch(sqliChecker(scanId));

      dispatch(xssChecker(scanId));

      dispatch(securityMain(scanId));
      dispatch(securityMainTop5(scanId));
      dispatch(openDBChecker(scanId));
      dispatch(subDomainTakeOverChecker(scanId));
      dispatch(corsChecker(scanId));
      dispatch(ddosChecker(scanId));
      dispatch(lfiChecker(scanId));
      dispatch(sslChecker(scanId));
      dispatch(cveChecker(scanId));
      dispatch(breachedChecker(scanId));
      // console.log(`xss value ${xss.vulnerabilitiesFound}`);
    }
    // eslint-disable-next-line
  }, [scanId]);

  React.useEffect(() => {
    if (securityMainStatus === "pending" || securityMainStatus === "running") {
      const interval1 = setInterval(() => {
        dispatch(securityMain(scanId));
      }, 15000);
      return () => clearInterval(interval1);
    }
    // eslint-disable-next-line
  }, [scanId, securityMainStatus]);

  React.useEffect(() => {
    if (sqliStatus === "pending" || sqliStatus === "running") {
      const interval1 = setInterval(() => {
        dispatch(sqliChecker(scanId));
      }, 15000);

      return () => clearInterval(interval1);
    }
    // eslint-disable-next-line
  }, [scanId, sqliStatus]);

  React.useEffect(() => {
    if (xssStatus === "pending" || xssStatus === "running") {
      const interval2 = setInterval(() => {
        //console.log(xssChecker(scanId));

        dispatch(xssChecker(scanId));
      }, 15000);

      return () => clearInterval(interval2);
    }
    // eslint-disable-next-line
  }, [scanId, xssStatus]);

  React.useEffect(() => {
    if (sslStatus === "pending" || sslStatus === "running") {
      const interval3 = setInterval(() => {
        dispatch(sslChecker(scanId));
      }, 15000);
      return () => clearInterval(interval3);
    }
    // eslint-disable-next-line
  }, [scanId, sslStatus]);

  React.useEffect(() => {
    if (openDBStatus === "pending" || openDBStatus === "running") {
      const interval4 = setInterval(() => {
        dispatch(openDBChecker(scanId));
      }, 15000);
      return () => clearInterval(interval4);
    }
    // eslint-disable-next-line
  }, [scanId, openDBStatus]);

  React.useEffect(() => {
    if (
      subDomainTakeOverStatus === "pending" ||
      subDomainTakeOverStatus === "running"
    ) {
      const interval4 = setInterval(() => {
        dispatch(subDomainTakeOverChecker(scanId));
      }, 15000);
      return () => clearInterval(interval4);
    }
    // eslint-disable-next-line
  }, [scanId, subDomainTakeOverStatus]);

  React.useEffect(() => {
    if (corsStatus === "pending" || corsStatus === "running") {
      const interval4 = setInterval(() => {
        dispatch(corsChecker(scanId));
      }, 15000);
      return () => clearInterval(interval4);
    }
    // eslint-disable-next-line
  }, [scanId, corsStatus]);

  React.useEffect(() => {
    if (ddosStatus === "pending" || ddosStatus === "running") {
      const interval4 = setInterval(() => {
        dispatch(ddosChecker(scanId));
      }, 15000);
      return () => clearInterval(interval4);
    }
    // eslint-disable-next-line
  }, [scanId, ddosStatus]);

  React.useEffect(() => {
    if (lfiStatus === "pending" || lfiStatus === "running") {
      const interval4 = setInterval(() => {
        dispatch(lfiChecker(scanId));
      }, 15000);
      return () => clearInterval(interval4);
    }
    // eslint-disable-next-line
  }, [scanId, lfiStatus]);

  React.useEffect(() => {
    if (
      securityMainTop5Status === "pending" ||
      securityMainTop5Status === "running"
    ) {
      setLoader(true);

      const interval4 = setInterval(() => {
        dispatch(securityMainTop5(scanId));
      }, 15000);
      return () => clearInterval(interval4);
    } else {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [scanId, securityMainTop5Status]);

  const tabs = ["Vulnerablities", "Pages"];
  return (
    <Suspense
      fallback={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="70vh"
          tabIndex="0"
        >
          {" "}
          <img src={loaderwave} alt="loader" />
        </Box>
      }
    >
      <div style={{ paddingTop: "45px" }}>
        <PerformanceSummary
          tabs={tabs}
          data={securityMainData}
          loading={securityMainLoading}
          tableData={securityMainTop5Data}
          status={securityMainTop5Status}
          tableLoading={securityMainTop5Loading}
          securityMainStatus={securityMainStatus}
        />

        <Box mt={5} mb={0}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              style={{ display: displaySqli === 0 ? "none" : "block" }}
            >
              {!sqliLoading &&
              sqliStatus !== "pending" &&
              sqliStatus !== "running" ? (
                <SecurityStatCard
                  detail={
                    "SQL injection is a web security vulnerability that allows an attacker to interfere with the queries that an application makes to its database. The attacker can modify or delete any data on the database, causing persistent changes to the application's content or behavior."
                  }
                  title={"SQLi Detection"}
                  vuls={
                    sqli.vulnerabilitiesFound ? sqli.vulnerabilitiesFound : 0
                  }
                  change={setDisplaySqli}
                  vulsPages={sqli.vulnerablePages}
                  percentage={sqli.vulnerablePagesPercentage}
                  status={sqli.status}
                  found={sqli.status}
                  riskLevel={sqli.riskLevel}
                />
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <SecurityCardLoader />
                </Box>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              style={{ display: displayXss === 0 ? "none" : "block" }}
            >
              {!xssLoading &&
              xssStatus !== "pending" &&
              xssStatus !== "running" ? (
                <SecurityStatCard
                  detail={
                    "Cross-site Scripting (XSS) is a security vulnerability usually found in web applications that accept user input and returns malicious JavaScript which executes inside a victim's browser and user interaction with the application is compromised."
                  }
                  title={"XSS Detection"}
                  vuls={xss.vulnerabilitiesFound ? xss.vulnerabilitiesFound : 0}
                  vulsPages={xss.vulnerablePages}
                  percentage={xss.vulnerablePagesPercentage}
                  status={xss.status}
                  found={xss.status}
                  riskLevel={xss.riskLevel}
                  change={setDisplayXss}
                />
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <SecurityCardLoader />
                </Box>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              style={{ display: displayOpenDb === 0 ? "none" : "block" }}
            >
              {!openDBLoading &&
              openDBStatus !== "pending" &&
              openDBStatus !== "running" ? (
                <SecurityStatCard
                  detail={
                    "Open Databases shows if there is no authentication present on the mongo or mysql databases of the website, from here any attacker can easily gain access to the web application database."
                  }
                  title={"Open Databases"}
                  vuls={
                    openDB.vulnerabilitiesFound
                      ? openDB.vulnerabilitiesFound
                      : 0
                  }
                  vulsPages={openDB.vulnerablePages}
                  percentage={openDB.vulnerablePagesPercentage}
                  status={openDB.status}
                  found={openDB.status}
                  riskLevel={openDB.riskLevel}
                  change={setDisplayOpenDb}
                />
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <SecurityCardLoader />
                </Box>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              style={{ display: displayLfi === 0 ? "none" : "block" }}
            >
              {!lfiLoading &&
              lfiStatus !== "pending" &&
              lfiStatus !== "running" ? (
                <SecurityStatCard
                  title={"LFI"}
                  vuls={lfi.vulnerabilitiesFound ? lfi.vulnerabilitiesFound : 0}
                  vulsPages={lfi.vulnerablePages}
                  percentage={lfi.vulnerablePagesPercentage}
                  status={lfi.status}
                  found={lfi.status}
                  riskLevel={lfi.riskLevel}
                  detail={
                    "An LFI attack can lead to Cross-site Scripting and remote code execution (RFI) vulnerabilities because LFIs help an attacker trick a web application into running or exposing files on a web server."
                  }
                  change={setDisplayLfi}
                />
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <SecurityCardLoader />
                </Box>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              style={{ display: displayLfi === 0 ? "none" : "block" }}
            >
              {!lfiLoading &&
              lfiStatus !== "pending" &&
              lfiStatus !== "running" ? (
                <SecurityStatCard
                  title={"RFI"}
                  detail={
                    "Remote File Inclusion (RFI) is a vulnerability that is most commonly seen on PHP-based websites. It allows an intruder to include a file hosted on a remote server, typically through a web server script. This can result in anything as simple as printing the contents of the file, or it can lead to arbitrary code execution depending on the severity."
                  }
                  vuls={lfi.vulnerabilitiesFound ? lfi.vulnerabilitiesFound : 0}
                  // vuls={1}
                  vulsPages={lfi.vulnerablePages}
                  percentage={lfi.vulnerablePagesPercentage}
                  status={lfi.status}
                  found={lfi.status}
                  riskLevel={lfi.riskLevel}
                  change={setDisplayLfi}
                />
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <SecurityCardLoader />
                </Box>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              style={{ display: displaySTS === 0 ? "none" : "block" }}
            >
              {!subDomainTakeOverLoading &&
              subDomainTakeOverStatus !== "pending" &&
              subDomainTakeOverStatus !== "running" ? (
                <SubDomainTakeOverCard
                  title={"Subdomain Takeover"}
                  vuls={
                    subDomainTakeOver.vulnerabilitiesFound
                      ? subDomainTakeOver.vulnerabilitiesFound
                      : 0
                  }
                  vulsPages={subDomainTakeOver.vulnerablePages}
                  status={subDomainTakeOver.status}
                  found={subDomainTakeOver.status}
                  riskLevel={subDomainTakeOver.riskLevel}
                  subdomainDetected={subDomainTakeOver.subdomainDetected}
                  detail={
                    "Subdomain takeover attacks are a type of security problem in which an attacker uses cloud services like AWS or Azure to take control of an organization's subdomain. They often occur when web projects are finished but the subdomain DNS entries are not fully deleted."
                  }
                  change={setDisplaySTS}
                />
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <SecurityCardLoader />
                </Box>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              style={{ display: displayDdos === 0 ? "none" : "block" }}
            >
              {!ddosLoading &&
              ddosStatus !== "pending" &&
              ddosStatus !== "running" ? (
                <SubDomainTakeOverCard
                  title={"DDoS testing"}
                  detail={
                    "A distributed denial-of-service (DDoS) attack is a malicious attempt to interrupt a targeted server's, service's, or network's regular traffic by flooding the target or its surrounding networks with Internet traffic."
                  }
                  vuls={
                    ddos.vulnerabilitiesFound ? ddos.vulnerabilitiesFound : 0
                  }
                  status={ddos.status}
                  found={ddos.status}
                  riskLevel={ddos.riskLevel}
                  detected={"Normal"}
                  change={setDisplayDdos}
                />
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <SecurityCardLoader />
                </Box>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              style={{ display: displaySsl === 0 ? "none" : "block" }}
            >
              {/* {!sslLoading &&
              sslStatus !== "pending" &&
              sslStatus !== "running" ? (
                <SecurityStatCard
                  detail={
                    "No SSL certificate is installed on the web application meaning that the content provided by it is termed as insecure. Another problem is it is not redirecting to ssl enabled link https."
                  }
                  title={"SSL Detection"}
                  vuls={ssl.vulnerabilitiesFound ? ssl.vulnerabilitiesFound : 0}
                  vulsPages={"All"}
                  percentage={100}
                  status={ssl.status}
                  found={ssl.status === "Found" ? "None Found" : "Found"}
                  riskLevel={ssl.riskLevel}
                  isRedirect={ssl.isRedirect}
                  change={setDisplaySsl}
                />
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <SecurityCardLoader />
                </Box>
              )} */}
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              style={{ display: displayCors === 0 ? "none" : "block" }}
            >
              {!corsLoading &&
              corsStatus !== "pending" &&
              corsStatus !== "running" ? (
                <SubDomainTakeOverCard
                  title={"CORS"}
                  detail={
                    "When CORS is configured incorrectly, it allows a domain managed by a malicious party to send requests to your domain. Some misconfigurations allow malicious domains to access API endpoints, while others allow untrusted sources to send credentials like cookies. The business consequences of this can range from data theft to the complete compromise of your application."
                  }
                  vuls={
                    cors.vulnerabilitiesFound ? cors.vulnerabilitiesFound : 0
                  }
                  status={cors.status}
                  found={cors.status}
                  riskLevel={cors.riskLevel}
                  change={setDisplayCors}
                />
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <SecurityCardLoader />
                </Box>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              style={{ display: displayCve === 0 ? "none" : "block" }}
            >
              {!cveLoading &&
              cveStatus !== "pending" &&
              cveStatus !== "running" ? (
                <SubDomainTakeOverCard
                  title={"cves"}
                  detail={
                    "When CORS is configured incorrectly, it allows a domain managed by a malicious party to send requests to your domain. Some misconfigurations allow malicious domains to access API endpoints, while others allow untrusted sources to send credentials like cookies. The business consequences of this can range from data theft to the complete compromise of your application."
                  }
                  vuls={cve.vulnerabilitiesFound ? cve.vulnerabilitiesFound : 0}
                  status={cve.status}
                  found={cve.status}
                  riskLevel={cve.riskLevel}
                  change={setDisplayCve}
                />
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <SecurityCardLoader />
                </Box>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              xl={3}
              style={{ display: displayBreach === 0 ? "none" : "block" }}
            >
              {!breachedLoading &&
              breachedStatus !== "pending" &&
              breachedStatus !== "running" ? (
                <SubDomainTakeOverCard
                  title={"Breached Information"}
                  detail={
                    "When CORS is configured incorrectly, it allows a domain managed by a malicious party to send requests to your domain. Some misconfigurations allow malicious domains to access API endpoints, while others allow untrusted sources to send credentials like cookies. The business consequences of this can range from data theft to the complete compromise of your application."
                  }
                  vuls={
                    breached.vulnerabilitiesFound
                      ? breached.vulnerabilitiesFound
                      : 0
                  }
                  status={breached.status}
                  found={breached.status}
                  riskLevel={breached.riskLevel}
                  change={setDisplayBreach}
                />
              ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <SecurityCardLoader />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </div>
    </Suspense>
  );
}

export default React.memo(SecurityBoxes);
