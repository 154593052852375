import React from 'react';

export default function Circle({ type, width = 24, height = 24 }) {
	let color = "#1F3A93";
	if (type === "success") {
		color = "#3AAA35"
	}
	if (type === "primary") {
		color = "#1F3A93"
	}
	if (type === "warning") {
		color = "#FFA412"
	}
	if (type === "danger") {
		color = "#E3211F"
	}
	if (type === "maroon") {
		color = "#96281B"
	}
	if (type === "orange") {
		color = "orange"
	}
	return (
		<svg xmlns="http://www.w3.org/2000/svg" 
			width={`${width}`}
			height={`${height}`}
		  fill={color} viewBox="0 0 24 24"><path d="M12 2C6.49 2 2 6.49 2 12s4.49 10 10 10 10-4.49 10-10S17.51 2 12 2z" /></svg>
	);
}


