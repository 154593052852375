import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import MuiTypography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MuiButton from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { CardContent } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import loaderwave from "../../assets/loader.svg";

import { getPackages, getPrices } from "../../store/actions/changePackageActions";

import CardHeader from '@material-ui/core/CardHeader';


import financialStyles from '../../Styles/jss/financialStyles';

const useStyles = makeStyles(financialStyles);

export default function BillingPageFreeUSers() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tab, setTabValue] = React.useState(null);
  const [evaluatedPrice, setEvaluatedPrice] = React.useState("");
  const packages = useSelector(state => state.changePackage.packages.data);
  const loadingPackages = useSelector(state => state.changePackage.packages.loading);
  const loadingBuyNow = useSelector(state => state.packageSelection.loadingSubscribe);
  const pricesLoading = useSelector(state => state.changePackage.pricesLoading);

  React.useEffect(() => {

    dispatch(getPackages());
    dispatch(getPrices());
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
   if (packages) {

      setTabValue(packages.supportedTenures[0]);

      let selectedPackagePrice = {
        rangeOfPages: packages.rangeOfPages.filter(item => item.tenure === packages.supportedTenures[0])[0],
        rangeOfUsers: packages.rangeOfUsers.filter(item => item.tenure === packages.supportedTenures[0])[0],
        rangeOfWebsites: packages.rangeOfWebsites.filter(item => item.tenure === packages.supportedTenures[0])[0],
        resolutions: packages.resolutions.filter(item => item.tenure === packages.supportedTenures[0])
      }
      let priceEvaluated = (parseInt(selectedPackagePrice.rangeOfPages.price, 10) +
        parseInt(selectedPackagePrice.rangeOfUsers.price, 10) +
        parseInt(selectedPackagePrice.rangeOfWebsites.price, 10)) +
        parseInt(selectedPackagePrice.resolutions.reduce((total, item) => parseInt(item.price) + total, 0))
      setEvaluatedPrice(priceEvaluated)
    }
    // eslint-disable-next-line
  }, [packages]);



  const packagesTab = (event, newValue) => {
    setTabValue(newValue);

    let selectedPackagePrice = {
      rangeOfPages: packages.rangeOfPages.filter(item => item.tenure === newValue)[0],
      rangeOfUsers: packages.rangeOfUsers.filter(item => item.tenure === newValue)[0],
      rangeOfWebsites: packages.rangeOfWebsites.filter(item => item.tenure === newValue)[0],
      resolutions: packages.resolutions.filter(item => item.tenure === newValue)
    }
    let priceEvaluated = (parseInt(selectedPackagePrice.rangeOfPages.price, 10) +
      parseInt(selectedPackagePrice.rangeOfUsers.price, 10) +
      parseInt(selectedPackagePrice.rangeOfWebsites.price, 10)) +
      parseInt(selectedPackagePrice.resolutions.reduce((total, item) => parseInt(item.price) + total, 0))
    setEvaluatedPrice(priceEvaluated)
  };


  return (

    <>
      {!loadingPackages && !pricesLoading ?
        <React.Fragment>
          <Box pt={5}>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" width="100%" >
              <MuiTypography variant="h2" className={classes.heading} gutterBottom >
                Billing
              </MuiTypography>
            </Box>
            <Box display="flex" justifyContent="center" mb={3}>
              <Box className={classes.root}>
                {packages && tab &&
                  // eslint-disable-next-line
                  <Tabs
                    data-tut="Select_tenure"
                    value={tab}
                    onChange={packagesTab}
                    indicatorColor="transparent"
                    textColor="primary"
                    centered
                  >
                    {packages.supportedTenures.map((tenure, index) => (
                      <CustomTab key={index} label={tenure} value={tenure} />
                    ))}
                  </Tabs>
                  // eslint-disable-next-line
                }
              </Box>
            </Box>
            <Grid container spacing={3} justifyContent="center">


              <Grid item xs={12} md={5} justifyContent="center">
                {
                  // eslint-disable-next-line
                  (packages) &&
                  packages.supportedTenures.map((tenure, index) => (
                    <TabPanelMain value={tab || ""} index={tenure} key={index}>
                      <Box>
                        <TabPanelMain value={tab || ""} index={tenure}>
                          <Card className={classes.packageCard}>
                            <CardHeader
                              title={"Selected Plan"}
                              titleTypographyProps={{ align: 'center' }}
                              subheaderTypographyProps={{ align: 'center' }}

                              className={classes.cardHeader}
                            />
                            <CardContent>
                              <div className={classes.cardPricing}>
                                <MuiTypography component="h2" variant="h3" className={`animate`} key={evaluatedPrice - "12321312"} color="textPrimary">
                                  ${evaluatedPrice}
                                </MuiTypography>
                                <MuiTypography variant="h6" color="textSecondary">
                                  /{tab}
                                </MuiTypography>
                              </div>
                              <Box my={1} display="flex" alignItems="center" flexDirection="column" >
                                <p className={classes.listPackageMain}>Number of Pages</p>
                                <p className={classes.listPackageDetail} >{packages.rangeOfPages.map((pack, index) => (
                                  pack.tenure === tenure && <p className={`${classes.listPackageDetail} animate`} key={index}>{`${pack.pageRange} (+$${pack.price})`} </p>
                                )
                                )} </p>
                              </Box>
                              <Box my={1} display="flex" alignItems="center" flexDirection="column" >
                                <p className={classes.listPackageMain}>Number of Users</p>
                                <p className={classes.listPackageDetail} >{packages.rangeOfUsers.map((pack, index) => (
                                  pack.tenure === tenure && <p className={`${classes.listPackageDetail} animate`} key={index}>{`${pack.userRange} (+$${pack.price})`} </p>

                                )
                                )} </p>
                              </Box>

                              <Box my={1} display="flex" alignItems="center" flexDirection="column">
                                <p className={classes.listPackageMain}>Number of Websites</p>
                                <p className={classes.listPackageDetail}> {packages.rangeOfWebsites.map((pack, index) => (
                                  pack.tenure === tenure && <p className={`${classes.listPackageDetail} animate`} key={index}>{`${pack.websiteRange} (+$${pack.price})`} </p>
                                )
                                )} </p>
                              </Box>
                              <Box my={1} display="flex" alignItems="center" flexDirection="column" >
                                <p className={classes.listPackageMain}>Resolutions</p>
                                <p style={{ margin: 0 }}>  {packages.resolutions.map((pack, index) => (
                                  pack.tenure === tenure && <p className={`${classes.listPackageDetail} animate`} key={index}> {pack.category + `(${pack.resolution}) (+${pack.price}$)`}</p>
                                )
                                )}</p>
                              </Box>
                              <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="center" mt={2} >
                                <MuiButton
                                  onClick={() => window.location.href = "/authenticate"}
                                  variant="contained" color="primary" style={{ borderRadius: "20px" }} >Buy Now {loadingBuyNow && <CircularProgress style={{ color: "black", marginLeft: "1rem", }} size={20} />}</MuiButton>
                              </Box>
                            </CardContent>
                          </Card>
                        </TabPanelMain>
                      </Box>
                    </TabPanelMain>
                  ))
                }
              </Grid>

            </Grid>
          </Box>
        </React.Fragment> : <Box display="flex" justifyContent="center" alignItems="center" height="100%"> <img src={loaderwave} alt="loader" /></Box>}
    </>
  );
}

function TabPanelMain(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanelMain.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const CustomTab = withStyles((theme) => ({
  root: {
    opacity: 1,
    color: "#1f3a93",
    borderRadius: 9,
    '&:hover': {
      color: '#1f3a93',
      opacity: 1,
    },
    '&$selected': {
      backgroundColor: "#1f3a93",
      color: 'white',
      fontWeight: theme.typography.fontWeightMedium,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "0.75rem"
    },
  },
  selected: {
    color: '#FFA412',
  },
}))((props) => <Tab {...props} />);
