import React from "react";
import Box from "@material-ui/core/Box";
import { useSelector, useDispatch } from "react-redux";

import { generateSitemapProgress } from "../../store/actions/customScanPageListActions";
import { CircularProgressbar } from 'react-circular-progressbar';
export default function GeneratingSitemap({ setActiveStep }) {
  const dispatch = useDispatch();
  const scanId = useSelector((state) => state.miscellaneous.selectedScanManagerId);
  const { sitemapProgress } = useSelector((state) => state.customScanList);

  const [progress, setProgress] = React.useState(0);
  const [message, setMessage] = React.useState("");

  React.useEffect(() => {
    if ((sitemapProgress === "pending" || sitemapProgress === "running") && scanId) {
      const interval1 = setInterval(() => {
        dispatch(generateSitemapProgress(scanId)).then((data) => {
          setMessage(data.message);
          setProgress(data.percentage)
        });
      }, 15000);
      return () => clearInterval(interval1);
    } else {
      setActiveStep(3);
    }
    // eslint-disable-next-line
  }, [scanId, sitemapProgress]);


  React.useEffect(() => {
    if (scanId) {
      dispatch(generateSitemapProgress(scanId)).then((data) => {
        setMessage(data.message);
        setProgress(data.percentage)
      });
    }
    // eslint-disable-next-line
  }, [scanId]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {" "}
      <div style={{ width: 120, height: 120 }}>
      <CircularProgressbar value={progress} text={`${progress}%`} styles={{
        path: {
          // Path color
          stroke: `#1f3a93`,
        },
        text: {
          // Text color
          fill: '#1f3a93',
        },
       }} />;
      </div>
      <span style={{ fontWeight: 600, fontSize: 18, margin: "10px 0px" }}>
        {message}
      </span>
    </Box>
  );
}
