import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";

const styles = {
  title: {
    margin: "0px 0px 0px 0.5rem",
    fontSize: "20px",
    fontWeight: 600,
  },
  body: {
    fontSize: "3em",
    margin: "0px 0.5rem",
    color: "#1F3A93",
  },
  pickdDateArrows: {
    fontWeight: "bold",
    cursor: "pointer",
  },
  pickDate: {
    color: "rgb(31, 58, 147)",
    width: 140,
    display: "flex",
    border: "1px solid rgb(31, 58, 147)",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2px 0",
    borderRadius: 3,
    boxShadow: "black 0px 0px",
  },
};

export default function TablesCard({ title, children, icon, status, tabs }) {
  return (
    <Card
      style={{
        boxShadow: 'none',
        borderRadius: 9,
        width: "100%",
      }}
    >
      <CardContent style={{ padding: 17 }}>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          p={0.5}
        >
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <span style={{ color: "#646464" }}>{icon}</span>
            <h2 tabIndex="0" aria-label={`${title} Table`} style={styles.title}>
              {title}
            </h2>
          </Box>
          {tabs && tabs}
          {/* Removing loader from top right corner */}
          {/* {(status === "pending" || status === "running") && (
            <CircularProgress
              style={{
                width: "20px",
                height: "20px",
                color: "rgb(31, 58, 147)",
              }}
            />
          )} */}
        </Box>
        <Box>{children}</Box>
      </CardContent>
    </Card>
  );
}
