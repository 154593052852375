import React, { Suspense } from 'react';
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import Authenticate from "./components/views/Authenticate";
import history from './@history';
import { Auth } from './auth';
import Authorization from './auth/Authorization';
import Callback from "./pages/Callback";
import { Provider } from 'react-redux';
import store from "./store";
import AppContext from './AppContext';
import routes from './rootRoutes';
import Loader from "./components/commons/Loader";
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Layout/Layout';
import DomainsWrapper from './DomainCheck/DomainsWrapper';
import ThankYou from './pages/ThankYou';

const Maintenance = React.lazy(() => import('./pages/Maintenance'));

export default function MainApp() {
  return (
    <BrowserRouter history={history} >
      <AppContext.Provider value={{ routes }}>
        <Provider store={store}>
          <Auth>
            <Authorization>
              <DomainsWrapper>
                <Suspense fallback={<Loader />}>
                  <Switch>
                    <Route exact path='/authenticate' component={Authenticate} />
                    <Route path='/callback' component={Callback} />
                    <Route path='/register'>
                      <Redirect push to="/authenticate" />
                    </Route>
                    <Route path='/login' >
                      <Redirect push to="/authenticate" />
                    </Route>
                    <Route exact path='/auth/alternate-sign-in' component={Authenticate} />
                    <Route exact path='/under-maintenance' component={Maintenance} />
                    <Route path='/subscription-successful' component={ThankYou} />
                    <Route path='/' component={Layout} />
                    <Redirect from="/" to="/dashboard" />
                  </Switch>
                </Suspense>
              </DomainsWrapper>
            </Authorization>
          </Auth>
        </Provider>
      </AppContext.Provider>
    </BrowserRouter>
  )
}
