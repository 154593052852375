import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import loaderwave from "../assets/loader.svg";
import accessibilityReportStyles from "../Styles/jss/accessibilityReportStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

const AccessibilityTable = React.lazy(() =>
  import("../components/tables/accessibilitytables/AccessibilityTable")
);
const CriteriaTable = React.lazy(() =>
  import("../components/tables/accessibilitytables/CriteriaTable")
);
const ViolationTable = React.lazy(() =>
  import("../components/tables/accessibilitytables/ViolationTable")
);
const AccessibilityArchivedIssuesTable = React.lazy(() =>
  import(
    "../components/tables/accessibilitytables/AccessibilityArchivedIssuesTable"
  )
);
const AccessibilityCompletedIssuesTable = React.lazy(() =>
  import(
    "../components/tables/accessibilitytables/AccessibilityCompletedIssuesTable"
  )
);
const AccessibilityIgnoredIssuesTable = React.lazy(() =>
  import(
    "../components/tables/accessibilitytables/AccessibilityIgnoredIssuesTable"
  )
);
const Section508Table = React.lazy(() =>
  import("../components/tables/accessibilitytables/Section508Table")
);

const useStyles = makeStyles(accessibilityReportStyles);

export default function AccessibilityReport() {
  const selectedScanId = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );
  const selectedScan = useSelector((state) => state.miscellaneous.selectedScan);
  const isPackagePurchased = useSelector(
    (state) => state.dashboard.isDomainPackagePurchased
  );

  const accessibilityTabs = useSelector(
    (state) => state.dashboard.tabsInfo?.accessibility
  );
  const tabsLoading = useSelector((state) => state.dashboard.tabsInfoLoading);
  const scanProgress = useSelector((state) => state.onBoardDashboard.scanProgress);

  const classes = useStyles();

  const [tableBackArray, setTableBackArray] = React.useState([]);

  const ref = React.createRef(null);

  const [reportType, setReportType] = React.useState({
    all: true,
    top10Criteria: false,
    mostViolations: false,
    section508: false,
    others: false,
  });

  const [tables, setTables] = React.useState({
    all: true,
    top10Criteria: true,
    mostViolations: true,
    section508: true,
    newIssues: true,
    archivedIssues: true,
    completedIssues: true,
    ignoredIssues: true,
  });

  React.useEffect(() => {
    if (selectedScanId) {
      setTables({
        all: true,
        top10Criteria: true,
        mostViolations: true,
        section508: true,
        newIssues: true,
        archivedIssues: true,
        completedIssues: true,
        ignoredIssues: true,
      });
    }
  }, [selectedScanId, reportType.others]);

  React.useEffect(() => {
    const tablesBackArray = [
      "accessibility",
      "criteria",
      "violation",
      "archived",
      "completed",
      "ignored",
    ];
    const array1 = JSON.parse(localStorage.getItem("accessibilityTableArray"));
    if (array1) {
      setTableBackArray([...array1]);
    } else {
      setTableBackArray([...tablesBackArray]);
      localStorage.setItem(
        "accessibilityTableArray",
        JSON.stringify(tablesBackArray)
      );
    }
  }, []);

  React.useEffect(() => {
    if (selectedScanId && tableBackArray.length > 0) {
      const preTables = [
        {
          text: <AccessibilityTable link={null} scanId={selectedScanId} />,
          id: "accessibility",
        },
        {
          text: <CriteriaTable link={null} scanId={selectedScanId} />,
          id: "criteria",
        },
        {
          text: <ViolationTable link={null} scanId={selectedScanId} />,
          id: "violation",
        },
        {
          text: <Section508Table scanId={selectedScanId} />,
          id: "section508",
        },
        {
          text: (
            <AccessibilityArchivedIssuesTable
              link={null}
              scanId={selectedScanId}
            />
          ),
          id: "archived",
        },
        {
          text: (
            <AccessibilityCompletedIssuesTable
              link={null}
              scanId={selectedScanId}
            />
          ),
          id: "completed",
        },
        {
          text: (
            <AccessibilityIgnoredIssuesTable
              link={null}
              scanId={selectedScanId}
            />
          ),
          id: "ignored",
        },
      ];
      const arrayo = [];
      for (let i = 0; i < tableBackArray.length; i++) {
        switch (tableBackArray[i]) {
          case "accessibility":
            arrayo.push(
              preTables.filter((box) => box.id === "accessibility")[0]
            );
            break;
          case "criteria":
            arrayo.push(preTables.filter((box) => box.id === "criteria")[0]);
            break;
          case "violation":
            arrayo.push(preTables.filter((box) => box.id === "violation")[0]);
            break;
          case "section508":
            arrayo.push(preTables.filter((box) => box.id === "section508")[0]);
            break;
          case "archived":
            arrayo.push(preTables.filter((box) => box.id === "archived")[0]);
            break;
          case "completed":
            arrayo.push(preTables.filter((box) => box.id === "completed")[0]);
            break;
          default:
            arrayo.push(preTables.filter((box) => box.id === "ignored")[0]);
        }
      }
      // setTablesArray([...arrayo]);
    }
    // eslint-disable-next-line
  }, [selectedScanId, tableBackArray]);

  const [delayed, setDelayed] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setDelayed(false);
    }, 1500);
  }, []);
  const CustomTabs = withStyles({
    root: {
      borderBottom: "none",
    },
    indicator: {
      // backgroundColor: 'white',
      display: "none",
    },
  })(Tabs);

  const CustomTab = withStyles((theme) => ({
    root: {
      minWidth: 40,
      opacity: 1,
      minHeight: "auto",
      borderRadius: "2px",
      padding: "0px 8px",
      border: "1px solid #1f3a93",
      color: "#1f3a93",
      fontWeight: 400,
      fontSize: "18px",
      borderRight: 0,
      "&:last-child": {
        borderRight: "1px solid #1f3a93",
      },
      "&:hover": {
        color: "#ffff!important",
        backgroundColor: "#1f3a93",
        opacity: 1,
      },
      "&$selected": {
        color: "white",
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: "#1f3a93",
      },
      "&:focus": {
        color: "white",
        backgroundColor: "#1f3a93",
      },
    },
    selected: {
      color: "white",
      backgroundColor: "#1f3a93",
      // display: 'none'
    },
  }))((props) => <Tab disableRipple {...props} />);

  function TableTabs() {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        style={{ background: '#fff', padding: 17 }}
        // width="100%"
        marginTop="8px"

      >
        {accessibilityTabs.all && (
          <CustomTab
            label="WCAG 2.2"
            onClick={() => setReportType({ all: true })}
            tabIndex="0"
            style={{
              backgroundColor: reportType.all ? "#1f3a93" : "none",
              color: reportType.all ? "#ffff" : "#1f3a93",
            }}
          />
        )}

        {accessibilityTabs.top10Criteria && (
          <CustomTab
            label="Top 10 Criteria"
            onClick={() => setReportType({ top10Criteria: true })}
            tabIndex="0"
            style={{
              backgroundColor: reportType.top10Criteria ? "#1f3a93" : "none",
              color: reportType.top10Criteria ? "#ffff" : "#1f3a93",
            }}
          />
        )}

        {accessibilityTabs.top10Vilations && (
          <CustomTab
            label="Top 10 Violations"
            onClick={() => setReportType({ mostViolations: true })}
            tabIndex="0"
            style={{
              backgroundColor: reportType.mostViolations ? "#1f3a93" : "none",
              color: reportType.mostViolations ? "#ffff" : "#1f3a93",
            }}
          />
        )}

        {/* {accessibilityTabs.section508 && (
          <CustomTab
            label="Section 508"
            onClick={() => setReportType({ section508: true })}
            tabIndex="0"
            style={{
              backgroundColor: reportType.section508 ? "#1f3a93" : "none",
              color: reportType.section508 ? "#ffff" : "#1f3a93",
            }}
          />
        )} */}
        {accessibilityTabs.others && isPackagePurchased && (
          <CustomTab
            label="Others"
            onClick={() => setReportType({ others: true })}
            tabIndex="0"
            style={{
              backgroundColor: reportType.others ? "#1f3a93" : "none",
              color: reportType.others ? "#ffff" : "#1f3a93",
            }}
          />
        )}
      </Box>
    );
  }

  return (
    <>
      <Suspense
        fallback={
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="70vh"
            tabIndex="0"
          >
            {" "}
            <img src={loaderwave} alt="loader" />
          </Box>
        }
      >
        {tabsLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="70vh"
            tabIndex="0"
          >
            <img src={loaderwave} alt="loader" />
          </Box>
        ) : (
          <div className={classes.root}>
            {selectedScan ? (
              <>
                {Object.values(accessibilityTabs).includes(true) && <TableTabs />}
                <Box mb={3} data-tut="Accessibility_Tables" ref={ref}>
                  {accessibilityTabs.all && reportType.all && (
                    <AccessibilityTable

                      link={null}
                      scanId={selectedScanId}
                    />
                  )}
                  {accessibilityTabs.top10Criteria &&
                    reportType.top10Criteria && (
                      <CriteriaTable

                        link={null}
                        scanId={selectedScanId}
                      />
                    )}
                  {accessibilityTabs.top10Vilations &&
                    reportType.mostViolations && (
                      <ViolationTable

                        link={null}
                        scanId={selectedScanId}
                      />
                    )}
                  {accessibilityTabs.section508 && reportType.section508 && (
                    <Section508Table

                      scanId={selectedScanId}
                    />
                  )}
                  {accessibilityTabs?.others && reportType.others && (
                    <>
                      <>
                        {/* <NewAccessibilityTable scanId={selectedScanId} setTables={setTables} tables={tables} /> */}
                        <AccessibilityArchivedIssuesTable

                          link={null}
                          scanId={selectedScanId}
                          setTables={setTables}
                          tables={tables}
                        />
                        <AccessibilityCompletedIssuesTable
                          link={null}
                          scanId={selectedScanId}
                          setTables={setTables}
                          tables={tables}
                        />
                        <AccessibilityIgnoredIssuesTable
                          link={null}
                          scanId={selectedScanId}
                          setTables={setTables}
                          tables={tables}
                        />
                      </>
                    </>
                  )}
                </Box>
                {!Object.values(accessibilityTabs).includes(true) && <Box display='flex' width='100%' justifyContent='center' p={3} margin='auto'>
                  <h3 align="center"> {scanProgress.status !== "completed" && "Currently, the scan is running. If an issue is discovered, the data will automatically populate here."}</h3>
                </Box>}
              </>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="70vh"
                tabIndex="0"
              >
                {" "}
                <img src={loaderwave} alt="loader" />
              </Box>
            )}
          </div>
        )}
      </Suspense>
    </>
  );
}
