const financialStyles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 500,
  },
  heading: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: '100%',
    textAlign: "center",
    fontSize: "4rem",
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      fontSize: "2rem"
    },
  },
  newTotalPrice: {
    fontSize: "2rem",
    [theme.breakpoints.down('md')]: {
      fontSize: "1.8rem"
    },
  },
  listPackage: {
    [theme.breakpoints.down('md')]: {
      fontSize: "0.9rem"
    },
  },
  packageCard: {
    boxShadow: 'rgb(0 0 0 / 15%) 0px 3px 25px',
    borderRadius: 9,
  },
  listPackageMain: {
    margin: '0 0 10px 0',
    fontWeight: "bold",
    fontSize: 16,
    fontFamily: "Segoe UI",
    [theme.breakpoints.down('md')]: {
      fontSize: "0.8rem"
    },
  },
  listPackageDetail: {
    margin: "0 0 5px 0",
  },
  cardHeader: {
    color: 'white',
    backgroundColor: '#1f3a93',
    fontFamily: "Segoe UI",
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),

  },
  addSiteButton: {
    margin: 0,
    color: "#96291c",
    fontWeight: "600"
  },
  wesbitesTable: {
    maxWidth: 400,
  },
  mainBox: {
    padding: "0px 24px",
    [theme.breakpoints.down('md')]: {
      padding: "0px",
    },
  },
});

export default financialStyles;
