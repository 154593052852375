import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Box, Button, Typography, IconButton,
  withStyles,
  Grid,
  Divider
} from "@material-ui/core";
import {
  CloudUpload,
  Edit,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  page: {
    width: "calc(100% - 300px)",
    padding: "10px"

  },
  btn: {
    border: "2px solid black",
    borderStyle: "dashed",
    justifyContent: "center",
    margin: "auto",
  },

  iconBtn: {
    marginLeft: "10px",
    border: "2px solid gray",
    borderRadius: "6px",
    padding: "5px",
    width: "60px",


  },
  drawer: {
    width: 240,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 240,
      boxSizing: "border-box",
    },
  },

  barhaed: {
    display: "flex",
    justifyContent: "space-between"
  }
  ,
  btnf: {
    fontSize: ' 14px',
  }

}));
const Index = ({ issue, title, open, setOpen }) => {
  const classes = useStyles();


  return (
    <Box py={2.5}>
      <Box display={"flex"} justifyContent={"space-between"} alignItems="center">
        <Typography style={{ fontWeight: 'bold' }}><span style={{ fontSize: 14 }}>Title:</span> {title}</Typography>
        <IconButton onClick={() => {
          setOpen(!open)
        }}>
          <Edit />
        </IconButton>
      </Box>
      <Divider style={{ marginTop: "5px" }} />
      <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>Description:</Typography>
      <Typography className={`${classes.font} ${classes.mt}`}>
        <div dangerouslySetInnerHTML={{ __html: issue.description }} />
      </Typography>
    </Box>
  );
};

export default Index;

