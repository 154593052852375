import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { toast } from 'react-toastify';
import { sqliResults, xssResults, lfiResults } from '../actions/securityActions'
import { getTechnologies } from './technologiesActions';

export const GET_ARCHIVED_SECURITY_ISSUES = 'GET_ARCHIVED_SECURITY_ISSUES';
export const GET_ARCHIVED_SECURITY_ISSUES_LOADING = 'GET_ARCHIVED_SECURITY_ISSUES_LOADING';

export const GET_COMPLETED_SECURITY_ISSUES = 'GET_COMPLETED_SECURITY_ISSUES';
export const GET_COMPLETED_SECURITY_ISSUES_LOADING = 'GET_COMPLETED_SECURITY_ISSUES_LOADING';

export const GET_IGNORED_SECURITY_ISSUES = 'GET_IGNORED_SECURITY_ISSUES';
export const GET_IGNORED_SECURITY_ISSUES_LOADING = 'GET_IGNORED_SECURITY_ISSUES_LOADING';

export const GET_CVE_EXPLOITS_TABLE = "GET_CVE_EXPLOITS_TABLE";

export const GET_CVE_EXPLOITS_TABLE_ARCHIVED = "GET_CVE_EXPLOITS_TABLE_ARCHIVED";
export const GET_CVE_EXPLOITS_TABLE_DISMISSED = "GET_CVE_EXPLOITS_TABLE_DISMISSED";

export const GET_BREACHED_INFO = "GET_BREACHED_INFO";
export const GET_BREACHED_INFO_LOADING = "GET_BREACHED_INFO_LOADING";

export const GET_BREACHED_INFO_ISSUES = 'GET_BREACHED_INFO_ISSUES';
export const GET_BREACHED_INFO_ISSUES_LOADING = 'GET_BREACHED_INFO_ISSUES_LOADING';

export const GET_ARCHIVED_BREACHED_INFO = 'GET_ARCHIVED_BREACHED_INFO';
export const GET_ARCHIVED_BREACHED_INFO_LOADING = 'GET_ARCHIVED_BREACHED_INFO_LOADING';



export const getCveExploitsTable = (scanId, page, tech, archive, dismiss) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const params = {
      scanId,
      page,
      limit: 5,
      archive,
      dismiss,
      tech
    }

    const res = await axios.get(`${BASE_URL}/exploits/tech-cves`, {
      params,
      ...options,
    });

    if (res.data) {

      dispatch({
        type: GET_CVE_EXPLOITS_TABLE,
        payload: res.data,
      });
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};

export const getCveExploitsTable2 = (scanId, page, archive, dismiss) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const params = {
      scanId,
      page,
      limit: 5,
      archive,
      dismiss
    }

    const res = await axios.get(`${BASE_URL}/exploits/tech-cves`, {
      params,
      ...options,
    });

    if (res.data) {
      if (!archive && dismiss) {
        dispatch({
          type: GET_CVE_EXPLOITS_TABLE_DISMISSED,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_CVE_EXPLOITS_TABLE_ARCHIVED,
          payload: res.data,
        });
      }

      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};

export const exploitsTableAction = (scanId, cveIds, archive, dismiss, action) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(`${BASE_URL}/exploits/${action}`, {
      scanId, cveIds, archive, dismiss
    },
      options
    );
    if (res.data) {
      dispatch(getCveExploitsTable(scanId, 1, false, false));
      if (action === "dismissal-tech-cves") {
        dispatch(getCveExploitsTable2(scanId, 1, false, true))
      } else {
        dispatch(getCveExploitsTable2(scanId, 1, true, false))
      }
      // dispatch(getTechnologies(scanId, true))
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};


//Archive
export const getSecurityArchivedIssues = (scanId) => async (dispatch) => {
  dispatch({
    type: GET_ARCHIVED_SECURITY_ISSUES_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    }

    const res = await axios.get(`${BASE_URL}/dashboard/archived-security-issues`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_ARCHIVED_SECURITY_ISSUES,
        payload: res.data
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}
export const archiveSqliLinks = (archiveOptions, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(`${BASE_URL}/dashboard/archive-sqli-issues`, {
      ...archiveOptions
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSecurityArchivedIssues(scanId));
      dispatch(sqliResults(scanId));

    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}
export const archiveXssLinks = (archiveOptions, scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const res = await axios.post(`${BASE_URL}/dashboard/archive-Xss-issues`, {
      ...archiveOptions
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSecurityArchivedIssues(scanId));
      dispatch(xssResults(scanId));

    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}
export const archiveLfiLinks = (archiveOptions, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(`${BASE_URL}/dashboard/archive-lfi-issues`, {
      ...archiveOptions
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSecurityArchivedIssues(scanId));
      dispatch(lfiResults(scanId));

    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}
export const unArchiveSecurityLinks = (postData, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/unarchive-security-issues`, {
      ...postData
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSecurityArchivedIssues(scanId));
      dispatch(sqliResults(scanId));
      dispatch(xssResults(scanId));
      dispatch(lfiResults(scanId));
    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}

//Complete
export const getSecurityCompletedIssues = (scanId) => async (dispatch) => {
  dispatch({
    type: GET_COMPLETED_SECURITY_ISSUES_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    }

    const res = await axios.get(`${BASE_URL}/dashboard/completed-security-issues`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_COMPLETED_SECURITY_ISSUES,
        payload: res.data
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}
export const completeSqliLinks = (completeOptions, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/complete-sqli-issues`, {
      ...completeOptions
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSecurityCompletedIssues(scanId));
      dispatch(sqliResults(scanId));

    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}
export const completeXssLinks = (completeOptions, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/complete-Xss-issues`, {
      ...completeOptions
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSecurityCompletedIssues(scanId));
      dispatch(xssResults(scanId));

    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}
export const completeLfiLinks = (completeOptions, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/complete-lfi-issues`, {
      ...completeOptions
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSecurityCompletedIssues(scanId));
      dispatch(lfiResults(scanId));

    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}
export const unCompleteSecurityLinks = (postData, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/uncomplete-security-issues`, {
      ...postData
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSecurityCompletedIssues(scanId));
      dispatch(sqliResults(scanId));
      dispatch(xssResults(scanId));
      dispatch(lfiResults(scanId));
    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}

//Ignore
export const getSecurityIgnoredIssues = (scanId) => async (dispatch) => {
  dispatch({
    type: GET_IGNORED_SECURITY_ISSUES_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    }

    const res = await axios.get(`${BASE_URL}/dashboard/ignored-security-issues`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_IGNORED_SECURITY_ISSUES,
        payload: res.data
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}
export const ignoreSqliLinks = (ignoreOptions, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/ignore-sqli-issues`, {
      ...ignoreOptions
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSecurityIgnoredIssues(scanId));
      dispatch(sqliResults(scanId));

    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}
export const ignoreXssLinks = (ignoreOptions, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/ignore-Xss-issues`, {
      ...ignoreOptions
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSecurityIgnoredIssues(scanId));
      dispatch(xssResults(scanId));

    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}
export const ignoreLfiLinks = (ignoreOptions, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/ignore-lfi-issues`, {
      ...ignoreOptions
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSecurityIgnoredIssues(scanId));
      dispatch(lfiResults(scanId));

    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}

export const unIgnoreSecurityLinks = (postData, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/unignore-security-issues`, {
      ...postData
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSecurityIgnoredIssues(scanId));
      dispatch(sqliResults(scanId));
      dispatch(xssResults(scanId));
      dispatch(lfiResults(scanId));
    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}


export const getBreachedInfo = (domainId, page) => async (dispatch) => {

  dispatch({
    type: GET_BREACHED_INFO_LOADING,
  });
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const params = {
      domainId,
      page,
      limit: 7
    }
    const res = await axios.get(
      `${BASE_URL}/breached/breached-info`,
      {
        params, ...options
      }
    );

    if (res.status === 200) {
      dispatch({
        type: GET_BREACHED_INFO,
        payload: res.data
      });
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    // toast.error(err.response.data.message);
  }
};

export const archiveBreachedInfo = (archiveOptions, domainId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(`${BASE_URL}/breached/archive-breached-info`, {
      breachedData: archiveOptions, domainId
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getBreachedInfo(domainId, 1));
      dispatch(getArchivedBreachedInfo(domainId, 1))
    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}

export const unArchiveBreachedInfo = (postData, domainId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(`${BASE_URL}/breached/unarchive-breached-info`, {
      breachedData: postData, domainId
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getBreachedInfo(domainId, 1));
      dispatch(getArchivedBreachedInfo(domainId, 1))
    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}


export const getArchivedBreachedInfo = (domainId, page) => async (dispatch) => {
  dispatch({
    type: GET_ARCHIVED_BREACHED_INFO_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      domainId, page, limit: 7
    }

    const res = await axios.get(`${BASE_URL}/breached/archived-breached-info`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_ARCHIVED_BREACHED_INFO,
        payload: res.data
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}