import React from "react";
import cognitive from "../../../../assets/disabilityicons/cognitive.svg";
import Box from "@material-ui/core/Box";
export default function Cognitive() {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      mr={1.5}
      style={{ fontSize: 13, fontWeight: 500, marginRight: 12, width: 65 }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          border: "2px solid black",
          borderRadius: "50%",
          padding: 6,
        }}
      >
        <img
          src={cognitive}
          height="22px"
          width="22px"
          alt="Cognitive Disability"
        />
      </Box>
      <span style={{ fontSize: "17.5px", fontWeight: 500 }}>Cognitive</span>
    </Box>
  );
}
