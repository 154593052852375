import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTable, usePagination, useRowSelect } from "react-table";
import Box from "@material-ui/core/Box";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ReactPaginate from "react-paginate";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import Auth0Service from "../../services/auth0Service/authentikService";
import { Link } from "react-router-dom";

const Styles = styled.div`
  table {
    thead tr:first-child {
      display: none;
    }
  }
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox
          // className={classes.root}
          color="primary"
          // checkedIcon={
          //   <span className={clsx(classes.icon, classes.checkedIcon)} />
          // }
          // icon={<span className={classes.icon} />}
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);

const IndeterminateHeaderCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox
          // className={classes.root}
          color="default"
          // classes={{ indeterminate: classes.indeterminate }}
          // checkedIcon={
          //   <span className={clsx(classes.icon, classes.checkedIcon)} />
          // }
          // icon={<span className={classes.icon} />}
          ref={resolvedRef}
          indeterminate={indeterminate}
          {...rest}
        />
      </>
    );
  }
);

function Table({
  columns,
  data,
  selection,
  completeAction,
  fixActions,
  unCompleteAction,
  ignoreAction,
  unIgnoreAction,
  unarchivedAction,
  archivedActions,
  optionType,
  setPage,
  count,
  pages,
  breachTable,
}) {
  const {
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    allColumns,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 6 },
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      selection &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <>
                <IndeterminateHeaderCheckbox
                  {...getToggleAllRowsSelectedProps()}
                />
              </>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </>
            ),
          },
          ...columns,
        ]);
    }
  );

  const { isDomainPackagePurchased } = useSelector((state) => state.dashboard);
  const { selectedScanId, selectedDomain } = useSelector(
    (state) => state.miscellaneous
  );

  const changeActivePage = (page) => {
    setPage(page.selected + 1);
  };

  const changeActivePage2 = (page) => {
    setPage(page.selected);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [archiveEl, setArchiveEl] = React.useState(null);

  const handleArchive = (event) => {
    setArchiveEl(event.currentTarget);
  };

  const handleArchiveClose = () => {
    setArchiveEl(null);
  };

  const tableStyle = {
    backgroundImage: `linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(0,0,0,.25), rgba(255,255,255,0)),
    linear-gradient(to left, rgba(0,0,0,.25), rgba(255,255,255,0))`,

    backgroundPosition: `left center, right center, left center, right center`,
    backgroundRepeat: `no-repeat`,
    backgroundColor: `white`,
    backgroundSize: `20px 100%, 20px 100%, 10px 100%, 10px 100%`,
    backgroundAttachment: `local, local, scroll, scroll`,
    overflowX: "auto",
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-start" mb={1}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ border: "1px solid #1f3a93", borderRadius: "20px" }}
        >
          Toggle Columns
        </Button>
      </Box>

      {Object.keys(selectedRowIds).length > 0 && unarchivedAction && (
        <Box display="flex" justifyContent="flex-start" flexWrap="wrap" mb={1}>
          <CustomButton
            variant="contained"
            color="primary"
            startIcon={<UnarchiveIcon />}
            onClick={() => unarchivedAction(selectedFlatRows)}
          >
            Unarchive
          </CustomButton>
        </Box>
      )}
      {!breachTable ? (
        <>
          {Object.keys(selectedRowIds).length > 0 && unCompleteAction && (
            <Box
              display="flex"
              justifyContent="flex-start"
              flexWrap="wrap"
              mb={1}
            >
              <CustomButton
                variant="contained"
                color="primary"
                startIcon={<UnarchiveIcon />}
                onClick={() => unCompleteAction(selectedFlatRows)}
              >
                Uncomplete
              </CustomButton>
            </Box>
          )}

          {Object.keys(selectedRowIds).length > 0 && unIgnoreAction && (
            <Box
              display="flex"
              justifyContent="flex-start"
              flexWrap="wrap"
              mb={1}
            >
              <CustomButton
                variant="contained"
                color="primary"
                startIcon={<UnarchiveIcon />}
                onClick={() => unIgnoreAction(selectedFlatRows)}
              >
                Unignore
              </CustomButton>
            </Box>
          )}
          {Object.keys(selectedRowIds).length > 0 && archivedActions && (
            <Box
              display="flex"
              justifyContent="flex-start"
              flexWrap="wrap"
              mb={1}
            >
              <CustomButton
                variant="contained"
                color="primary"
                startIcon={<VisibilityOffIcon />}
                onClick={() => ignoreAction(selectedFlatRows)}
              >
                Ignore
              </CustomButton>

              <CustomButton
                variant="contained"
                color="primary"
                startIcon={<ArchiveIcon />}
                onClick={handleArchive}
              >
                Archive
              </CustomButton>
              <CustomButton
                variant="contained"
                color="primary"
                startIcon={<DoneOutlineIcon />}
                onClick={() => completeAction(selectedFlatRows)}
              >
                Mark as completed
              </CustomButton>
              <CustomButton
                variant="contained"
                color="primary"
                startIcon={<DoneOutlineIcon />}
                onClick={() => fixActions(selectedFlatRows)}
              >
                Fix Selected
              </CustomButton>
            </Box>
          )}
          <Menu
            id="toggle-columns-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {allColumns.map(
              (column, i) =>
                i !== 0 && (
                  <MenuItem key={i} {...column.getToggleHiddenProps()}>
                    <FormControlLabel
                      style={{ width: "100%" }}
                      key={column.id}
                      control={
                        <CustomCheckbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          {...column.getToggleHiddenProps()}
                        />
                      }
                      label={column.Header}
                    />
                  </MenuItem>
                )
            )}
          </Menu>
          <Menu
            id="archive-actions-menu"
            anchorEl={archiveEl}
            keepMounted
            open={Boolean(archiveEl)}
            onClose={handleArchiveClose}
          >
            <MenuItem
              onClick={() =>
                archivedActions(selectedFlatRows, {
                  archiveThisInstance: true,
                  archiveIndividualInstance: false,
                  archiveAllfromCriteria: false,
                })
              }
            >
              Archive this instance
            </MenuItem>
            <MenuItem
              onClick={() =>
                archivedActions(selectedFlatRows, {
                  archiveThisInstance: false,
                  archiveIndividualInstance: true,
                  archiveAllfromCriteria: false,
                })
              }
            >
              One individual issue
            </MenuItem>
            {optionType ? (
              <MenuItem
                onClick={() =>
                  archivedActions(selectedFlatRows, {
                    archiveThisInstance: false,
                    archiveIndividualInstance: false,
                    archiveAllfromType: true,
                  })
                }
              >
                Ignore All Errors from this Type
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() =>
                  archivedActions(selectedFlatRows, {
                    archiveThisInstance: false,
                    archiveIndividualInstance: false,
                    archiveAllfromCriteria: true,
                  })
                }
              >
                Ignore All Errors from this Criteria
              </MenuItem>
            )}
          </Menu>
        </>
      ) : (
        <>
          {Object.keys(selectedRowIds).length > 0 && archivedActions && (
            <CustomButton
              variant="contained"
              color="primary"
              startIcon={<VisibilityOffIcon />}
              onClick={() => archivedActions(selectedFlatRows)}
            >
              Archive
            </CustomButton>
          )}
        </>
      )}
      <div style={tableStyle}>
        <MaUTable size="small">
          {selection ? (
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) => (
                    <TableCell
                      {...column.getHeaderProps()}
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#1f3a93",
                        minWidth: i !== 0 ? column.width : "30px",
                      }}
                      aria-colindex={i}
                    >
                      {column.render("Header")}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
          ) : (
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) => (
                    <TableCell
                      {...column.getHeaderProps()}
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#1f3a93",
                        minWidth: column.width,
                      }}
                      aria-colindex={i}
                    >
                      {column.render("Header")}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
          )}
          <TableBody role="rowgroup">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} aria-rowindex={i} role="row">
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        // style={{ verticalAlign: "top" }}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {/* //overlay start */}
            {/* <div style={{width:"100%"}}> */}
            {!isDomainPackagePurchased && (
              <TableRow>
                <TableCell
                  colspan={headerGroups[1].headers.length}
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    textAlign: "center",
                    padding: 0,
                    // color: "white",
                  }}
                >
                  <div
                    style={{
                      margin: "auto",
                      width: "100%",
                      height: 200,
                      backgroundColor: "#1f3a93",
                      padding: 10,
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    <div className="ps-stic">
                      <h4>
                        To see complete data, please subscribe to our Package.
                      </h4>
                      <Button
                        component={Link}
                        style={{ borderRadius: "20px" }}
                        to={`${
                          Auth0Service.isAuthenticated()
                            ? `/account-setup?domain=${selectedDomain}&scanId=${selectedScanId}`
                            : "/authenticate"
                        }`}
                        color="#FFFF"
                        variant="contained"
                      >
                        Subscribe
                      </Button>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MaUTable>
      </div>
      {isDomainPackagePurchased && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          mt={2}
        >
          {count > 6 && pages ? (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              initialPage={0}
              pageCount={pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={4}
              onPageChange={changeActivePage}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          ) : (
            count > 6 && (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.round(data.length / 6)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                onPageChange={changeActivePage2}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )
          )}
        </Box>
      )}
    </>
  );
}

function App({
  tableColumns,
  data,
  archivedActions,
  completeAction,
  unCompleteAction,
  fixActions,
  ignoreAction,
  unIgnoreAction,
  unarchivedAction,
  selection,
  optionType,
  setPage,
  pages,
  count,
  breachTable,
}) {
  const columns = React.useMemo(
    () => [
      {
        Header: "g",
        columns: tableColumns,
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <Styles>
      <Table
        columns={columns}
        data={data}
        completeAction={completeAction}
        fixActions={fixActions}
        unCompleteAction={unCompleteAction}
        ignoreAction={ignoreAction}
        unIgnoreAction={unIgnoreAction}
        archivedActions={archivedActions}
        selection={selection}
        unarchivedAction={unarchivedAction}
        optionType={optionType}
        setPage={setPage}
        pages={pages}
        count={count}
        breachTable={breachTable}
      />
    </Styles>
  );
}

export default App;

const CustomCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#1f3a93",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CustomButton = withStyles((theme) => ({
  root: {
    margin: "0px 8px 8px 0px",
    borderRadius: 20,
  },
  startIcon: {
    marginRight: 9,
  },
}))(Button);
