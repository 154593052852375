import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCveExploitsTable2 } from "../../../store/actions/securityTablesActions";
import TablesCard from "../../commons/TablesCard";
import DescriptionIcon from "@material-ui/icons/Description";
import Box from "@material-ui/core/Box";
import loader from "../../../assets/loader.svg";
import CVEExploitTable from "../../../pages/cve/CVEExploitTable";

export default function ArchivedCveExploits({ scanId, changer }) {
  const [tblData, setTblData] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.securityTable.cveExploitsTableArchived
  );

  React.useEffect(() => {
    if (scanId) {
      dispatch(getCveExploitsTable2(scanId, page, true, false));
    }
    // eslint-disable-next-line
  }, [scanId]);

  React.useEffect(() => {
    if (data) {
      setTblData(data);
    }
  }, [data, scanId]);

  return (
    <TablesCard
      icon={<DescriptionIcon />}
      title="Archived CVE's"
      status={"completed"}
    >
      {tblData ? (
        <>
          {tblData.result && tblData.result.length > 0 ? (
            <CVEExploitTable
              data={tblData.result}
              pages={data.pages}
              scanId={scanId}
              setPage={setPage}
              page={page}
              archive={true}
              dismiss={false}
            />
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ minHeight: "200px", maxHeight: "200px" }}
            >
              {changer(false)}
              <h4>No Archived CVE's</h4>
            </Box>
          )}
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "200px", maxHeight: "200px" }}
        >
          <img src={loader} alt="loader" />
        </Box>
      )}
    </TablesCard>
  );
}
