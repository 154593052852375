import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import MuiTypography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MuiButton from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { Button, CardContent } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import loaderwave from "../../../assets/loader.svg";

import { getNextPackage, updateToNextPackage } from "../../../store/actions/changePackageActions";

import CardHeader from '@material-ui/core/CardHeader';


import financialStyles from '../../../Styles/jss/financialStyles';

const useStyles = makeStyles(financialStyles);

export default function NextPacakge({ setShowNext }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [tab, setTabValue] = React.useState('monthly');
    const [loading, setLoading] = React.useState(false);
    const [packages, setPackages] = React.useState(null);
    const [packagesLoading, setPackagesLoading] = React.useState(true);

    const domainId = useSelector((state) => state.dashboard.selectedDomainId);

    const selectedDomain = useSelector(state => state.miscellaneous.selectedDomain);
    const selectedScanId = useSelector(state => state.miscellaneous.selectedScanId);

    React.useEffect(() => {
        setPackagesLoading(true)
        dispatch(getNextPackage(domainId)).then(data => {
            if (data) {
                setPackages(data.evaluation);
                setPackagesLoading(false)
            }
        });
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (packages) {
            setTabValue(packages.stripePackage.supportedTenures[0]);
        }
        // eslint-disable-next-line
    }, [packages]);

    const packagesTab = (event, newValue) => {
        setTabValue(newValue);
    };

    function upgradePackage() {
        setLoading(true)
        dispatch(updateToNextPackage(
            {
                domainId, stripePackage: {
                    resolutions: { tenure: tab, resolutions: packages.stripePackage.resolutions.filter(item => item.tenure === tab)[0].resolutions },
                    rangeOfPages: packages.stripePackage.rangeOfPages.filter(item => item.tenure === tab)[0],
                    rangeOfAddons: [],
                    rangeOfUsers: packages.stripePackage.rangeOfUsers[0],
                    tenure: tab
                },
            }, selectedDomain, selectedScanId
        )).then(data => {
            if (data) {
                setLoading(false)
            } else {
                setLoading(false)
            }
        })
    }

    return (

        <>
            {!packagesLoading ?
                <React.Fragment>
                    <Box pt={5}>

                        <Box display="flex" justifyContent="center" alignItems='center' mb={3}>
                            <Button color='primary' variant='contained' onClick={() => setShowNext(false)}>Back</Button>
                            <Box className={classes.root}>
                                {packages && tab &&
                                    // eslint-disable-next-line
                                    <Tabs
                                        data-tut="Select_tenure"
                                        value={tab}
                                        onChange={packagesTab}
                                        indicatorColor="transparent"
                                        textColor="primary"
                                        centered
                                    >
                                        {packages.stripePackage.supportedTenures.map((tenure, index) => (
                                            <CustomTab key={index} label={tenure} value={tenure} />
                                        ))}
                                    </Tabs>
                                    // eslint-disable-next-line
                                }
                            </Box>
                        </Box>
                        <Grid container spacing={3} justifyContent="center">


                            <Grid item xs={12} justifyContent="center">
                                {
                                    // eslint-disable-next-line
                                    (packages) &&
                                    packages.stripePackage.supportedTenures.map((tenure, index) => (
                                        <TabPanelMain value={tab || ""} index={tenure} key={index}>
                                            <Box>
                                                <TabPanelMain value={tab || ""} index={tenure}>
                                                    <Card className={classes.packageCard}>
                                                        <CardHeader
                                                            title={"Selected Plan"}
                                                            titleTypographyProps={{ align: 'center' }}
                                                            subheaderTypographyProps={{ align: 'center' }}

                                                            className={classes.cardHeader}
                                                        />
                                                        <CardContent>
                                                            <div className={classes.cardPricing}>
                                                                <MuiTypography component="h2" variant="h3" className={`animate`} key={index - "12321312"} color="textPrimary">
                                                                    ${tab === "monthly" ? packages.monthlyTotal : packages.yearlyTotal}
                                                                </MuiTypography>
                                                                <MuiTypography variant="h6" color="textSecondary">
                                                                    /{tab}
                                                                </MuiTypography>
                                                            </div>
                                                            <Box my={1} display="flex" alignItems="center" flexDirection="column" >
                                                                <p className={classes.listPackageMain}>Number of Pages</p>
                                                                <p className={classes.listPackageDetail} >{packages.stripePackage.rangeOfPages.map((pack, index) => (
                                                                    pack.tenure === tenure && <p className={`${classes.listPackageDetail} animate`} key={index}>{`${pack.pageRange} (+$${pack.price})`} </p>
                                                                )
                                                                )} </p>
                                                            </Box>
                                                            <Box my={1} display="flex" alignItems="center" flexDirection="column" >
                                                                <p className={classes.listPackageMain}>Number of Users</p>
                                                                <p className={classes.listPackageDetail} >{packages.stripePackage?.rangeOfUsers.map((pack, index) => (
                                                                    pack.tenure === tenure && <p className={`${classes.listPackageDetail} animate`} key={index}>{`${pack.userRange} (+$${pack.price})`} </p>

                                                                )
                                                                )} </p>
                                                            </Box>

                                                            <Box my={1} display="flex" alignItems="center" flexDirection="column" >
                                                                <p className={classes.listPackageMain}>Resolutions</p>
                                                                <p style={{ margin: 0 }}>  {packages.stripePackage.resolutions.map((pack, index) => {
                                                                    return (pack.tenure === tenure &&
                                                                        <>
                                                                            {pack.resolutions.map(((reso, i) => {
                                                                                return (
                                                                                    <p className={`${classes.listPackageDetail} animate`} key={i}> {reso.category + `(${reso.resolution}) (+${reso.price}$)`}</p>
                                                                                )
                                                                            }))}

                                                                        </>
                                                                    )
                                                                }
                                                                )}</p>
                                                            </Box>
                                                            <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="center" mt={2} >
                                                                <MuiButton
                                                                    disabled={loading}
                                                                    onClick={() => upgradePackage()}
                                                                    variant="contained" color="primary" style={{ borderRadius: "20px" }} >Buy Now {loading && <CircularProgress style={{ color: "black", marginLeft: "1rem", }} size={20} />}</MuiButton>
                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </TabPanelMain>
                                            </Box>
                                        </TabPanelMain>
                                    ))
                                }

                            </Grid>

                        </Grid>

                    </Box>

                </React.Fragment> : <Box display="flex" justifyContent="center" alignItems="center" height="100%"> <img src={loaderwave} alt="loader" /></Box>}
        </>
    );
}

function TabPanelMain(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            {...other}
        >
            {value === index && (
                <div>
                    {children}
                </div>
            )}
        </div>
    );
}

TabPanelMain.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const CustomTab = withStyles((theme) => ({
    root: {
        opacity: 1,
        color: "#1f3a93",
        borderRadius: 9,
        '&:hover': {
            color: '#1f3a93',
            opacity: 1,
        },
        '&$selected': {
            backgroundColor: "#1f3a93",
            color: 'white',
            fontWeight: theme.typography.fontWeightMedium,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "0.75rem"
        },
    },
    selected: {
        color: '#FFA412',
    },
}))((props) => <Tab {...props} />);
