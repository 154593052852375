import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiTab from '@material-ui/core/Tab';


const AntTab = withStyles((theme) => ({
  root: {
    padding: '15px 12px',
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color:'black',
    opacity:1,
    
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {},
}))((props) => <MuiTab disableRipple {...props} />);


export default function Tabs(props) {


  return (
    <AntTab {...props} />
  );
}
