import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { getAllImagesLinks, FixImagesLinksWP, getPluginStatus, getAllAccessibilityIssues, FixAccessibilityLinksWP, getCrawlerStatus, getAllSchemas, updateJsonLd ,updateJsonLdWP, getAllSchemasWp} from "../../store/actions/abracadabraActions";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Loader from "../../components/commons/Loader";
import { toast } from "react-toastify";
import CustomCardContainerHead from "../../components/commons/CustomCardContainerHead";
import JsonViewer from "./JsonViewer";
import { LinearProgress } from '@material-ui/core';
import ReactJson from 'react-json-view';
import locale from 'react-json-editor-ajrm/locale/en';

export default function Schemas({ domain, selectedScanId }) {
  const [loadingLinks, setLoadingLinks] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pluginInstalled, setPluginInstalled] = useState(true);
  const [resolvedIssues, setResolvedIssues] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [scanStatus, setScanStatus] = useState('');
  const [showAcessibilityIssues, setShowAccessibilityIssues] = useState(false);
  const totalIssues = selectedRows.length
  const itemsPerPage = rowsPerPage;

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [jsonContent, setJsonContent] = useState('');
  const [jsonContentEdited, setJsonContentEdited] = useState('');
  const [editingSchema, setEditingSchema] = useState(false);

  const jsonData = {
    id: 1,
    name: 'Alice',
    age: 25,
    address: {
      street: '123 Main St',
      city: 'Exampleville',
      country: 'Exampleland'
    }
  };


  // Calculate progress percentage
  const progress = totalIssues > 0 ? (resolvedIssues / totalIssues) * 100 : 0;

  // Simulating a process with setTimeout
  React.useEffect(() => {
    // Simulate a process taking 3 seconds to complete
    const process = setTimeout(() => {
      // Once the process is completed
      setLoading(false); // Hide the loader
      // Additional actions or state changes upon success can go here
    }, 4000);

    // Clear the timeout if component unmounts or if process succeeds before 3 seconds
    return () => clearTimeout(process);
  }, []);



  // Function to update the "Fix All" button text based on selection
  const getFixButtonText = () => {
    if (selectedRows.length === 0) {
      // No rows selected, hide the button
      return "Fix";
    } else if (selectedRows.length === data.length) {
      // All rows selected, set text to "Fix All"
      return "Fix All";
    } else {
      // Multiple rows selected, set text to "Fix Selected"
      return "Fix Selected";
    }
  };
  // const itemsPerPage = 5; // Number of items to display per page

  // Step 2: Function to toggle selection for a specific row
  const toggleRowSelection = (image) => {
    if (selectedRows.includes(image)) {
      setSelectedRows(selectedRows.filter((row) => row !== image));

    } else {
      setSelectedRows([...selectedRows, image]);
      console.log(selectedRows)
    }
  };


  const getImageDataForSelectedRows = async () => {
    const selectedImagesData = data.filter((image) =>
      selectedRows.includes(image)
    );
    console.log(selectedImagesData)
    if (selectedImagesData.length > 0) {
      // setShowProgress(true);
      // for (let i = 0; i < selectedImagesData.length; i++) {
      //   const image = selectedImagesData[i];

      //   const updatedImage = {
      //     db_issue_id: image._id,
      //     domain: image.domain,
      //     post_id: image.post_id,
      //     issue: {
      //       id: image.id,
      //       any: image.any,
      //       all: image.all,
      //       none: image.none,
      //       html: image.html,
      //       target: image.target,
      //       failureSummary: image.failureSummary,
      //       impact: image.impact,
      //       tags: image.tags,
      //       description: image.description,
      //       help: image.help,
      //       helpUrl: image.helpUrl,
      //       suggested: image.suggested,
      //       fixes: image.fixes,
      //       suggested_description: image.suggested_description,
      //       suggested_fixed_html: image.suggested_fixed_html,
      //       domain: image.domain,
      //       createdAt: image.createdAt,
      //       updatedAt: image.updatedAt,
      //       __v: image.__v
      //     },
      //   };
      //   try {
      //     const response = await dispatch(FixAccessibilityLinksWP(updatedImage));
      //     if (response) {
      //       setLoadingLinks(false);
      //       setResolvedIssues(prevResolvedIssues => prevResolvedIssues + 1);
      //       // toast.success(response.response.message)
      //     } else {
      //       setLoadingLinks(false);
      //       // toast.warning("AI Suggestion not available at this time")
      //     }
      //   } catch (err) {
      //     setLoadingLinks(false);
      //     // toast.warning("AI Suggestion not available at this time")
      //   }

      //   if (resolvedIssues === selectedRows.length) {
      //     setShowProgress(false);
      //   }
      // }
    } else {
      toast.warning("Select at least one row to perform action", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const handleShowSchema = (image) => {
    // Logic to extract JSON content from image.minimalFaqs and store it in jsonContent
    console.log(typeof image.recommendedFaqs)
    const extractedJson = image.recommendedFaqs
    // && typeof image.recommendedFaqs[0] === "string"
    //   ? parseJSONSafely(image.recommendedFaqs)
    //   : image.recommendedFaqs;
    setJsonContent(JSON.stringify(extractedJson, null, 2)); // Convert JSON to string for display
    setShowModal(true);
    setShowEditModal(false)
  };

  const handleShowEditSchema = (image) => {
    // Logic to extract JSON content from image.minimalFaqs and store it in jsonContent
    console.log(typeof image.recommendedFaqs)
    const extractedJson = image.recommendedFaqs
    // && typeof image.recommendedFaqs[0] === "string"
    //   ? parseJSONSafely(image.recommendedFaqs)
    //   : image.recommendedFaqs;
    setJsonContent(JSON.stringify(extractedJson, null, 2)); // Convert JSON to string for display
    setShowEditModal(true);
    setShowModal(false);
  };

  // Function to handle opening/closing JSON viewer modal
  const handleUpdateSchema = async (image) => {
    // Logic to extract JSON content from image.minimalFaqs and store it in jsonContent
    // const extractedJson = Array.isArray(image.recommendedFaqs) && typeof image.recommendedFaqs[0] === "string"
    //   ? parseJSONSafely(image.recommendedFaqs[0])
    //   : image.recommendedFaqs;
    const extractedJson = image.recommendedFaqs

    setJsonContent(JSON.stringify(extractedJson, null, 2)); // Convert JSON to string for display
    console.log("here json" + JSON.stringify(extractedJson, null, 2) + image.link + image.domain);
    try {
      const response = await dispatch(updateJsonLdWP(selectedScanId, image.link, JSON.stringify(extractedJson, null, 2)));
      if (response) {
        setLoadingLinks(false);
        console.log(response);
        toast.success(response.message)
      } else {
        setLoadingLinks(false);
        // toast.warning("AI Suggestion not available at this time")
      }
    } catch (err) {
      setLoadingLinks(false);
      // toast.warning("AI Suggestion not available at this time")
    }
  };

  // Function to handle select all checkbox
  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(currentItems);
    }
    setSelectAll(!selectAll);
  };


  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to the first page when rows per page changes
    getDomains(); // Call the API with the new rows per page value
  };

  const dispatch = useDispatch();

  // Function to remove duplicates based on image_url property
  function removeDuplicates(arr, prop) {
    return arr.filter((obj, index, self) =>
      index === self.findIndex((el) => (
        el[prop] === obj[prop]
      ))
    );
  }

  function getDomains() {
    dispatch(getAllSchemasWp(selectedScanId))
      .then((data) => {
        console.log("schemas")
        console.log(data);
        if (data && data.schemas.length > 0) {
          setLoadingLinks(false);

          // Filter objects where the type is "product"
          const filteredData = data.schemas.filter(item => item.type === "image");
          console.log(filteredData);

          // Remove duplicates based on a specific property (e.g., 'type')
          const uniqueFilteredData = [];
          const uniqueTracker = {};

          filteredData.forEach(item => {
            if (!uniqueTracker[item.type]) {
              uniqueTracker[item.type] = true;
              uniqueFilteredData.push(item);
            }
          });

          console.log(uniqueFilteredData); // Check unique filtered data in console

          setData(uniqueFilteredData);
          setLoadingLinks(false);
        }
      })
      .catch((err) => {
        setLoadingLinks(false);
      });
  }


  async function getPluginStatus2() {
    try {
      const response = await dispatch(getPluginStatus(domain));
      console.log(response.response.response);
      if (response && response.response.response === 200) {
        setPluginInstalled(true);
        setLoadingLinks(false);
        // console.log(response.response.result);
      } else {
        setPluginInstalled(false);
        setLoadingLinks(false);
        // toast.error("Failed to retrieve contact details.");
      }
    } catch (error) {
      setPluginInstalled(false);
      setLoadingLinks(false);
    }
  }

  async function getCrawlStatus() {
    try {
      const response = await dispatch(getCrawlerStatus(domain));
      console.log(response.response.result);
      if (response && response.response.result === "completed") {
        setLoadingLinks(false);
        setShowAccessibilityIssues(true);
        setScanStatus(response.response.result);
        // console.log(response.response.result);
      } else {
        setShowAccessibilityIssues(true);
        setLoadingLinks(false);
        setScanStatus(response.response.result);
        // toast.error("Failed to retrieve contact details.");
      }
    } catch (error) {
      setLoadingLinks(false);
      setShowAccessibilityIssues(false);
      // toast.error("No task found or still running");
    }
  }

  // React.useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     getCrawlStatus();
  //   }, 5000); // 5 seconds in milliseconds

  //   // Clear interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, [showAcessibilityIssues]);

  // React.useEffect(() => {
  //   getPluginStatus2();
  // }, [pluginInstalled]);

  React.useEffect(() => {
    setLoadingLinks(true);
    getDomains();
  }, [currentPage]);

  let currentItems = 0;
  let totalPages = 0;
  if (data.length > 0) {
    totalPages = Math.ceil(data.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  }


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


  const downloadFileInNewTab = () => {
    // Set the relative path to the file in the public folder
    const fileURL = '/plugins/AllyRight.zip';

    // Open a new tab and start the download
    window.open(fileURL);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleJsonChange = (newJson) => {
    // console.log(newJson.updated_src);
    setJsonContent(JSON.stringify(newJson.updated_src));
    console.log(jsonContent);
  };


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
            <>
              <CustomCardContainerHead title={"Images Schema"} titleTag="h1">
                  <>
                    <div>
                      {resolvedIssues < totalIssues && showProgress ? (
                        <>
                          <div>Total Issues: {totalIssues}</div>
                          <div>Resolved Issues: {resolvedIssues}</div>
                          <LinearProgress variant="determinate" value={progress} />
                          <div>Progress: {progress.toFixed(2)}%</div>
                        </>
                      ) : (
                        null
                        // <div>All issues have been resolved!</div>
                      )}
                    </div>
                    <div style={{ overflowX: "auto", maxWidth: "100%" }}>
                      <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              {/* <TableCell>
                                <input
                                  type="checkbox"
                                  checked={selectAll}
                                  onChange={toggleSelectAll}
                                />
                              </TableCell> */}
                              {/* <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}><button onClick={() => getImageDataForSelectedRows()}>{getFixButtonText()}</button></TableCell> */}
                              {/* <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>_id</TableCell> */}
                              {/* <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Domain</TableCell> */}
                              <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Link</TableCell>
                              <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Type</TableCell>
                              <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Fixed</TableCell>
                              <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Suggested</TableCell>
                              <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Actions</TableCell>
                              {/* <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Minimal Schema</TableCell>
                              <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Recommended Schema</TableCell> */}
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          {/* <TableBody>
                            <TableRow>
                              <TableCell style={{ minWidth: "500px", maxWidth: "500px", wordWrap: "break-word", overflow: "hidden", textOverflow: "ellipsis" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <JsonViewer jsonData={jsonData} />
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableBody> */}
                          {!loadingLinks ? (
                            <TableBody>
                              {currentItems.length > 0 ? (
                                currentItems.map((image, index) => (
                                  <React.Fragment key={index}>
                                    <TableRow>
                                      {/* <TableCell>
                                        <input
                                          type="checkbox"
                                          checked={selectedRows.includes(image)}
                                          onChange={() => toggleRowSelection(image)}
                                        />
                                      </TableCell> */}
                                      {/* <TableCell>

                                      </TableCell> */}

                                      {/* <TableCell style={{ whiteSpace: "nowrap", minWidth: "300px", maxWidth: "300px" }}>
                                  <div>
                                    {image._id}
                                  </div>
                                </TableCell> */}
                                      {/* <TableCell style={{ whiteSpace: "nowrap", minWidth: "300px", maxWidth: "300px" }}>
                                        <div>
                                          {image.domain}
                                        </div>
                                      </TableCell> */}

                                      <TableCell style={{ whiteSpace: "nowrap", minWidth: "300px", maxWidth: "300px" }}>
                                        <div>
                                          {image.link}
                                        </div>
                                      </TableCell>


                                      <TableCell style={{ whiteSpace: "nowrap", minWidth: "300px", maxWidth: "300px" }}>
                                        <div>
                                          {image.type}
                                        </div>
                                      </TableCell>

                                      {image.isFixed ? (
                                        <TableCell style={{ whiteSpace: "nowrap", minWidth: "200px", maxWidth: "200px" }}>
                                          <div>
                                            {"Yes"}
                                          </div>
                                        </TableCell>
                                      ) : (
                                        <TableCell style={{ whiteSpace: "nowrap", minWidth: "200px", maxWidth: "200px" }}>
                                          <div style={{ display: "flex", alignItems: "center" }}>
                                            {/* <span style={{ fontSize: "15px", marginRight: "5px" }} title="Warning: AI Suggestion not available for this">⚠️</span> */}
                                            {"No"}
                                          </div>
                                        </TableCell>
                                      )
                                      }

                                      {image.isSuggested ? (
                                        <TableCell style={{ whiteSpace: "nowrap", minWidth: "200px", maxWidth: "200px" }}>
                                          <div>
                                            {"Yes"}
                                          </div>
                                        </TableCell>
                                      ) : (
                                        <TableCell style={{ whiteSpace: "nowrap", minWidth: "200px", maxWidth: "200px" }}>
                                          <div style={{ display: "flex", alignItems: "center" }}>
                                            <span style={{ fontSize: "15px", marginRight: "5px" }} title="Warning: AI Suggestion not available for this">⚠️</span>
                                            {"No"}
                                          </div>
                                        </TableCell>
                                      )
                                      }
                                      <TableCell style={{ whiteSpace: "nowrap", minWidth: "500px", maxWidth: "500px" }}>
                                        <div style={{ display: "flex", gap: "8px" }}>
                                          {/* onClick={() => handleShowSchema(image)} */}
                                          {/* onClick={() => handleEditSchema(image)} */}
                                          <button style={{ padding: "8px 16px", backgroundColor: "#4CAF50", color: "white", border: "none", borderRadius: "4px", cursor: "pointer" }} onClick={() => handleShowSchema(image)}>Show Schema</button>
                                          <button style={{ padding: "8px 16px", backgroundColor: "#4CAF50", color: "white", border: "none", borderRadius: "4px", cursor: "pointer" }} onClick={() => handleShowEditSchema(image)}>Edit Schema</button>
                                          <button style={{ padding: "8px 16px", backgroundColor: "#2196F3", color: "white", border: "none", borderRadius: "4px", cursor: "pointer" }} onClick={() => handleUpdateSchema(image)}>Update Schema</button>
                                        </div>
                                      </TableCell>
                                      {/* <TableCell style={{ minWidth: "500px", maxWidth: "500px", wordWrap: "break-word", overflow: "hidden", textOverflow: "ellipsis" }}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                          {Array.isArray(image.minimalFaqs) && typeof image.minimalFaqs[0] === "string" ? (
                                            <JsonViewer jsonData={parseJSONSafely(image.minimalFaqs[0])} />
                                          ) : (
                                            <JsonViewer jsonData={image.minimalFaqs} />
                                          )}
                                        </div>
                                      </TableCell> */}

                                      {/* <TableCell style={{ minWidth: "500px", maxWidth: "500px", wordWrap: "break-word", overflow: "hidden", textOverflow: "ellipsis" }}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                          {Array.isArray(image.minimalFaqs) && image.minimalFaqs.map((faq, index) => (
                                            <JsonViewer key={index} jsonData={Array.isArray(faq) && typeof faq[0] === "string" ? parseJSONSafely(faq[0]) : faq} />
                                          ))}
                                        </div>
                                      </TableCell> */}
                                    </TableRow>
                                  </React.Fragment>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell colSpan={5}>no records found</TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          ) : (
                            <TableBody>
                              <TableRow>
                                <TableCell align="center" colSpan={5}>
                                  <CircularProgress />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "10px" }}>
                      <Typography variant="body1" style={{ marginRight: "10px" }}>Number of entries:</Typography>
                      <TextField
                        type="number"
                        variant="outlined"
                        color="primary"
                        size="small"
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        InputProps={{
                          inputProps: { min: 1, max: 1000 }, // Minimum and maximum value allowed
                          style: { width: "5em" }, // Minimum value allowed
                        }}
                      />
                      <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                    </div>
                  </>
              </CustomCardContainerHead>
              {showModal && (
                <CustomCardContainerHead title={"JSON Viewer"} titleTag="h1">

                  {/* <div className="modal">
                    <div className="modal-content">
                      <span className="close" onClick={closeModal}>&times;</span>
                      <pre>{jsonContent}</pre>
                    </div>
                  </div> */}
                  <div className="modal">
                    <div className="modal-content">
                      <span className="close" onClick={closeModal}>&times;</span>
                      {/* <pre>{jsonContent}</pre> */}
                      <ReactJson src={JSON.parse(jsonContent)}/>
                    </div>
                  </div>
                </CustomCardContainerHead>
              )}

              {showEditModal && (
                <CustomCardContainerHead title={"JSON Viewer"} titleTag="h1">

                  {/* <div className="modal">
                    <div className="modal-content">
                      <span className="close" onClick={closeModal}>&times;</span>
                      <pre>{jsonContent}</pre>
                    </div>
                  </div> */}
                  <div className="modal">
                    <div className="modal-content">
                      <span className="close" onClick={closeModal}>&times;</span>
                      {/* <pre>{jsonContent}</pre> */}

                      <ReactJson src={JSON.parse(jsonContent)}
                        onEdit={handleJsonChange}
                        onDelete={handleJsonChange}
                        onAdd={handleJsonChange}
                        displayObjectSize={false}
                        displayDataTypes={false}
                        enableClipboard={false}
                        displayArrayKey={false}
                        iconStyle="square"
                        style={{ padding: '20px', fontSize: '14px' }}
                        locale={locale} />
                    </div>
                  </div>
                </CustomCardContainerHead>
              )}
            </>
      )}
    </>
  );
}

// Function to safely parse JSON strings
const parseJSONSafely = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return null; // or handle the error in a way that suits your application
  }
};

function Pagination({ totalPages, currentPage, onPageChange }) {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "10px" }}>
      {currentPage > 1 && (
        <IconButton onClick={() => onPageChange(currentPage - 1)}>
          <ArrowBackIcon />
        </IconButton>
      )}
      {pageNumbers.map((page) => (
        <Button
          key={page}
          onClick={() => onPageChange(page)}
          style={{
            borderRadius: "20px",
            margin: "5px",
            backgroundColor: currentPage === page ? "#007bff" : "#fff",
            color: currentPage === page ? "#fff" : "#007bff",
          }}
        >
          {page}
        </Button>
      ))}
      {currentPage < totalPages && (
        <IconButton onClick={() => onPageChange(currentPage + 1)}>
          <ArrowForwardIcon />
        </IconButton>
      )}
    </div>
  );
}
