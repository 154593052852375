import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'rgba(31, 58, 147, 0.1) 0px 3px 25px',
    margin: "10px 0px",
    borderRadius: '9px',
    padding: 20
  },
  cardHeader: {
    backgroundColor: 'rgb(31, 58, 147)',
    color: 'white',
    borderRadius: 10,
    padding: '12px 16px'
  }

}));

const CardContainer = ({ children, title }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader className={classes.cardHeader} title={<Typography style={{ fontWeight: 'bold', fontSize: 20 }}>{title}</Typography>} />
      <CardContent>
        {children}
      </CardContent>
    </Card>
  );

}

export default CardContainer;