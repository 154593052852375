import React from 'react';
import Box from '@material-ui/core/Box';
import loaderwave from '../../assets/loader.svg';

function SubLoader() {
  return (
    <Box display="flex" justifyContent='center' alignItems='center'>
      <img alt="loader" src={loaderwave} />
    </Box >
  )
}

export default SubLoader;
