const issueTrackerTypesTableStyles = (theme) => ({
  startIcon: {
    marginRight: 0
  },
  muiToolbar: {
    backgroundColor: "#1F3A93",
    minHeight: "50px"
  },
  menuButton: {
    fontSize: 14,
    fontWeight: "bold",
    color: "white",
    padding: "0px 12px"
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  primaryColor: {
    color: "#1F3A93"
  },
  CustomButton: {
    margin: 0,
    color: "#96291c",
    fontWeight: "600",
    fontSize: "13px"
  },
  textWhite: {
    color: "white",
  },
  tableTitle: {
    margin: "0rem 1rem",
    color: "white"
  },
  addCRButton: {
    userSelect: 'none',
    cursor: 'pointer',
    marginRight: 10
  }
});

export default issueTrackerTypesTableStyles;
