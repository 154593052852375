import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { toast } from "react-toastify";

export const GET_PRIVACY_RESULTS = 'GET_PRIVACY_RESULTS';

export const getPrivacyResults = (scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    }


    const res = await axios.get(`${BASE_URL}/privacy/privacy-result`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_PRIVACY_RESULTS,
        payload: res.data
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}