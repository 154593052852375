import React from "react";
import Container from "@material-ui/core/Container";
import { Box, Button } from "@material-ui/core";
import Tabs from "../../components/commons/Tabs/compactTabs/Tabs";
import Tab from "../../components/commons/Tabs/compactTabs/Tab";
import Select from "react-select";
import { components } from "react-select";
import CustomizedInputs from "../../components/commons/BootstrapedInput";
import { useHistory } from "react-router-dom";

import { getDate } from "../../source/functions";
import Chip from "@material-ui/core/Chip";
import { useDispatch, useSelector } from "react-redux";
import {
  getMilestonesLists,
  getMilestones,
  getMilestone,
  closeMilestone,
} from "../../store/actions/issueTrackerActions";
import LinearProgress from "../../components/commons/LinearProgress";

export default function Milstones() {
  const { selectedDomainId } = useSelector((state) => state.dashboard);
  const { milestonesLists } = useSelector((state) => state.issueTracker);
  const getMilestoness = useSelector((state) => state.issueTracker.milestones);

  const [searchMilestones, setSearchMilestones] = React.useState("");
  const [milestones, setMilestones] = React.useState(0);
  const [selectedMilestoneList, setSelectedMilestoneList] = React.useState(null);

  const [tabIndex, setTabIndex] = React.useState(0);
  const [selectedOptionFilter, setSelectedOptionFilter] =
    React.useState("start_date");

  const dispatch = useDispatch();

  let history = useHistory();

  const [filterOptions] = React.useState([
    { value: "start_date", label: "Start date" },
    { value: "due_date", label: "Due date" },
    { value: "created_at", label: "Created date" },
  ]);

  React.useEffect(() => {
    if (selectedDomainId) {
      dispatch(getMilestonesLists(selectedDomainId));
    }
  }, [selectedDomainId]);

  React.useEffect(() => {
    if (milestonesLists.length > 0) {
      setSelectedMilestoneList(milestonesLists[0])
    }
  }, [milestonesLists]);


  React.useEffect(() => {
    if (selectedMilestoneList) {
      dispatch(
        getMilestones({
          domainId: selectedDomainId,
          listId: selectedMilestoneList._id,
          sort: selectedOptionFilter,
        })
      );
    }
  }, [selectedMilestoneList]);

  React.useEffect(() => {
    if (getMilestoness) {
      setMilestones(getMilestoness);
    }
  }, [getMilestoness]);

  React.useEffect(() => {
    if (searchMilestones !== "") {
      const filterlbls = getMilestoness.filter((lb) =>
        lb.title.startsWith(searchMilestones)
      );
      setMilestones(filterlbls);
    } else {
      setMilestones(getMilestoness);
    }
  }, [searchMilestones]);

  const CustomMenu = (props) => {
    return (
      <components.MenuList {...props}>{props.children}</components.MenuList>
    );
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      minWidth: 300,
      width: "auto",
      borderBottom: "1px dotted pink",
      color: state.selectProps.menuColor,
    }),
    control: (_, { selectProps: { width } }) => ({
      width: width,
      display: "flex",
      backgroundColor: "white",
    }),
  };

  const handleChangeFilter = (selectedOption) => {
    setSelectedOptionFilter(selectedOption.value);
    dispatch(
      getMilestones({
        domainId: selectedDomainId,
        listId: selectedMilestoneList._id,
        sort: selectedOption.value,
      })
    );
  };

  function changeStatus(milestone) {
    dispatch(
      closeMilestone({
        domainId: selectedDomainId,
        id: milestone.id,
        status: "closed",
      })
    ).then((data) => {
      if (data) {
        dispatch(
          getMilestones({
            domainId: selectedDomainId,
            listId: milestone.list_id,
            sort: "start_date",
          })
        );
      }
    });
  }

  return (
    <Container maxWidth="lg">
      <Box
        width="100%"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        mt={3}
      >
        <Tabs value={tabIndex} onChange={(e, index) => setTabIndex(index)}>
          {milestonesLists.map((milestoneList) => {
            return (
              <Tab
                label={`${milestoneList.name}-${milestoneList.milestonesCount}`}
                onClick={() =>
                  setSelectedMilestoneList(milestoneList)
                }
              />
            );
          })}
        </Tabs>
        <Box display="flex" alignItems="center">
          <Box mr={1} width="100%">
            <CustomizedInputs
              onChange={(e) => setSearchMilestones(e.target.value)}
              placeholder="Filter by name"
              style={{ width: "100%" }}
            />
          </Box>
          <Box mr={1}>
            <Select
              placeholder="Filter"
              defaultValue={filterOptions.filter(
                (option) => option.value === "start_date"
              )}
              onChange={handleChangeFilter}
              options={filterOptions}
              styles={customStyles}
              width="200px"
              components={{ MenuList: CustomMenu }}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "100%" }}
            onClick={() =>
              history.push(
                `/issue-tracker/new-milestone/${milestonesLists[0]?._id}`
              )
            }
          >
            New Milestone
          </Button>
        </Box>
      </Box>

      {milestones.length > 0 ? (
        milestones.map((milestone, index) => {

          return (
            <Box p={1} display="flex" style={{ backgroundColor: "#d5d6d8" }}>
              <Box
                borderTop={1}
                sx={{ borderColor: "#999898", backgroundColor: "white" }}
                px={2}
                py={1.5}
                width="100%"
                key={index}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box width="50%">
                    <p
                      style={{ fontWeight: 600, margin: 0, fontSize: 15, cursor: "pointer", textDecoration: 'underline' }}
                      // className="title-milestone"
                      onClick={() => {
                        history.push(
                          `/issue-tracker/milestone/${milestone.id}`
                        );
                      }}
                    >
                      {milestone.title}
                    </p>
                    <LinearProgress progress={milestone.progress} />
                  </Box>
                  {milestone.status !== "closed" && (
                    <Button
                      variant="outlined"
                      onClick={() => changeStatus(milestone)}
                    >
                      Close Milestone
                    </Button>
                  )}
                </Box>
                <Box width="100%">
                  <p
                    style={{
                      margin: 0,
                      fontWeight: 500,
                      marginBottom: 10,
                      color: "grey",
                    }}
                  >
                    {" "}
                    {getDate(milestone.start_date)} - {getDate(milestone.due_date)}
                  </p>
                  <Chip label={milestone.status} size="small" />
                </Box>
              </Box>
            </Box>
          );
        })
      ) : (
        <Box textAlign="center" mt={3}>
          No Milestones found
        </Box>
      )}
    </Container>
  );
}
