import React from 'react';
import { Box, Typography, FormControl, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import marginStyles from '../styles/margin';

import componentStyles from '../styles/stepper';

const useStyles = makeStyles(componentStyles);
const useMarginStyles = makeStyles(marginStyles);

const Index = ({ data, setData }) => {
    const classes = useStyles();
    const margin = useMarginStyles();
    const [interaction, setInteraction] = React.useState([]);

    const handleChange = (e) => {
        const { value } = e.target;
        const val = interaction.includes(value);
        if (val) {
            let temp = interaction;
            temp = temp.filter((t) => t !== value);
            setInteraction(temp);
        } else {
            setInteraction([...interaction, val]);
        }
    };

    function checked(value) {}

    React.useEffect(() => {
        setData({
            ...data,
            interactions: interaction
        });
    }, [interaction]);

    return (
        <Box>
            <Typography className={classes.heading2}>4. Select the type of interaction(s) encountered.</Typography>
            <Typography className={`${classes.text2} ${margin['mt-20']}`}>
                To help expedite and personalize this request, please select each of the following ways that you have interacted with our
                dealership in the past 12 months. Check all that apply.
            </Typography>
            <Box mt={3}>
                <FormGroup>
                    <FormControlLabel
                        className={classes.text3}
                        control={<Checkbox onChange={handleChange} value="current employee" inputProps={{ 'aria-label': 'controlled' }} />}
                        label="I am a current or past employee"
                    />
                    <FormControlLabel
                        className={classes.text3}
                        control={<Checkbox onChange={handleChange} value="applied for job" inputProps={{ 'aria-label': 'controlled' }} />}
                        label="I applied for a job within the last 12 month"
                    />
                </FormGroup>
            </Box>
        </Box>
    );
};

export default Index;
