import {
  SHOW_ALERT,
  REMOVE_ALERT
} from '../actions/alertActions';

const initialState = {
  message: '',
  openSnack: false
};

 const alertsReducer =(state = initialState, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return {
        ...state,
        message: action.payload,
        openSnack: !state.openSnack
      }
    case REMOVE_ALERT:
      return {
        ...state,
        message: '',
        openSnack: false
      }
    default:
      return state;
  }
};

export default alertsReducer;