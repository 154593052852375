const cveMainStyles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 300,
    margin: 10
  },
  chips: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    alignItems: 'center',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: "10px -8px",
  },
  noTechnology: {
    margin: "20px 0px",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
});

export default cveMainStyles;
