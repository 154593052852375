import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import BoxIcon from "../../commons/icons/BoxIcon";
import CircleIcon from "../../commons/icons/CircleIcon";
import Tricon from "../../commons/icons/Tricon";
import AccessibilityBar from "../../barchart/AccessibilityBar";
import { useSelector, useDispatch } from "react-redux";
import { getAccessibilityHistory } from "../../../store/actions/accessibilityCardActions";
import allIcon from "../../../assets/new-accessibility-icons/all.png";
import hearingIcon from "../../../assets/new-accessibility-icons/hearing.svg";
import visualIcon from "../../../assets/new-accessibility-icons/visual.svg";
import motorIcon from "../../../assets/new-accessibility-icons/motor.svg";
import cognitiveIcon from "../../../assets/new-accessibility-icons/cognitive.svg";
import SubLoader from "../../commons/SubLoader";
import accessibilityHistoryStyles from "../../../Styles/jss/accessibilityComponents/accessibilityHistoryStyles";

const useStyles = makeStyles(accessibilityHistoryStyles);

export default function AccessibilityCard({ setLoader, dateRange, scanId }) {

  const unnified = useSelector(
    (state) => state.dashboard.unified
  );
  const [value, setValue] = React.useState(0);

  const dispatch = useDispatch();
  const classes = useStyles();

  const data = useSelector((state) => state.accessibilityCard.historyData);
  const status = useSelector(
    (state) => state.accessibilityCard.accessibilityHistoryStatus
  );
  const loading = useSelector(
    (state) => state.accessibilityCard.accessibilityHistoryLoading
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (dateRange && scanId) {
      dispatch(getAccessibilityHistory(dateRange, scanId, "all"));
    }
    // eslint-disable-next-line
  }, [scanId, dateRange]);

  React.useEffect(() => {
    if (status === "pending" || status === "running") {
      setLoader(true);
      const interval1 = setInterval(() => {
        dispatch(getAccessibilityHistory(dateRange, scanId, "all"));
      }, 15000);

      return () => clearInterval(interval1);
    } else {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [scanId, status]);
  // const CustomTab = withStyles((theme) => ({
  //   root: {
  //     minWidth: 40,
  //     opacity: 1,
  //     minHeight: "auto",
  //     borderRadius: "2px",
  //     padding: "0px 8px",
  //     border: "1px solid #1f3a93",
  //     color: "#1f3a93",
  //     fontWeight: 400,
  //     fontSize: "18px",
  //     borderRight: 0,
  //     "&:last-child": {
  //       borderRight: "1px solid #1f3a93",
  //     },
  //     "&:hover": {
  //       color: "#ffff!important",
  //       backgroundColor: "#1f3a93",
  //       opacity: 1,
  //     },
  //     "&$selected": {
  //       color: "white",
  //       fontWeight: theme.typography.fontWeightMedium,
  //       backgroundColor: "#1f3a93",
  //     },
  //     "&:focus": {
  //       color: "white",
  //       backgroundColor: "#1f3a93",
  //     },
  //   },
  //   selected: {
  //     color: "white",
  //     backgroundColor: "#1f3a93",
  //     // display: 'none'
  //   },
  // }))((props) => <Tab disableRipple {...props} />);

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            height: "474px",
            maxHeight: "474px",
            boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.15)",
          }}
        >
          {" "}
          <SubLoader />{" "}
        </Box>
      ) : (
        <>
          <CustomTab
            label="All"
            onClick={() =>
              dispatch(getAccessibilityHistory(dateRange, scanId, "all"))
            }
          />
          <CustomTab
            label="Cognitive"
            onClick={() =>
              dispatch(getAccessibilityHistory(dateRange, scanId, "cognitive"))
            }
          />
          <CustomTab
            label="Hearing"
            onClick={() =>
              dispatch(getAccessibilityHistory(dateRange, scanId, "hearing"))
            }
          />
          <CustomTab
            label="Visual"
            onClick={() =>
              dispatch(getAccessibilityHistory(dateRange, scanId, "visual"))
            }
          />
          <CustomTab
            label="Motor"
            onClick={() =>
              dispatch(getAccessibilityHistory(dateRange, scanId, "motor"))
            }
          />

          {data ? (
            <React.Fragment>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={3}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {value === 0 && (
                      <img src={allIcon} alt="accessibility-icon" />
                    )}
                    {value === 1 && (
                      <img src={cognitiveIcon} alt="cognitive-icon" />
                    )}
                    {value === 2 && (
                      <img src={hearingIcon} alt="hearing-icon" />
                    )}
                    {value === 3 && <img src={visualIcon} alt="visual-icon" />}
                    {value === 4 && <img src={motorIcon} alt="motor-icon" />}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={9}>
                  <Box display="flex" flexDirection="column" ml={-1}>
                    <Typography
                      variant="body1"
                      align="left"
                      style={{ fontFamily: "Segoe UI" }}
                    >
                      Number of Violations
                    </Typography>
                    <Box mb={2}>
                      <Grid
                        container
                        className={classes.priorities}
                        spacing={4}
                      >
                        <Grid item xs={12} sm={4}>
                          <Box display="flex" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                              <CircleIcon
                                type="success"
                                height={20}
                                width={20}
                              />
                              <span className={classes.legendTitle}>Low</span>
                            </Box>
                            <div style={{ color: "#6DBB83", fontSize: "18px" }}>
                              {data.totalViolations.low}
                            </div>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          className={clsx(classes.gridPadd)}
                        >
                          <Box display="flex" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                              <BoxIcon type="warning" height={20} width={20} />
                              <span className={classes.legendTitle}>
                                Medium
                              </span>
                            </Box>
                            <div style={{ color: "#FFA412", fontSize: "18px" }}>
                              {data.totalViolations.medium}
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Box display="flex" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                              <Tricon type="danger" height={20} width={20} />
                              <span className={classes.legendTitle}>High</span>
                            </Box>
                            <div style={{ color: "#E3211F", fontSize: "18px" }}>
                              {data.totalViolations.high}
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Typography
                variant="body1"
                align="left"
                style={{ fontFamily: "Segoe UI" }}
              >
                Average Violations Per Page
              </Typography>
              <Box className={classes.averageViolations}>
                <Box className={classes.averageViolationsChildBox}>
                  <Grid container className={classes.priorities} spacing={4}>
                    <Grid item xs={12} sm={4}>
                      <Box display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                          <CircleIcon type="success" height={20} width={20} />
                          <span className={classes.legendTitle}>Low</span>
                        </Box>
                        <div style={{ color: "#6DBB83", fontSize: "18px" }}>
                          {data.averageViolations.low}
                        </div>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      className={clsx(classes.gridPadd)}
                    >
                      <Box display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                          <CircleIcon type="warning" height={20} width={20} />
                          <span className={classes.legendTitle}>Medium</span>
                        </Box>
                        <div
                          style={{
                            color: "#FFA412",
                            fontSize: "18px",
                            marginLeft: "0.5rem",
                          }}
                        >
                          {data.averageViolations.medium}
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Box display="flex" justifyContent="space-between">
                        <Box display="flex" alignItems="center">
                          <CircleIcon type="danger" height={20} width={20} />
                          <span className={classes.legendTitle}>High</span>
                        </Box>
                        <div style={{ color: "#E3211F", fontSize: "18px" }}>
                          {data.averageViolations.high}
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <AccessibilityBar data={data.graph} />
            </React.Fragment>
          ) : (
            <div>found no data</div>
          )}
        </>
      )}
    </>
  );
}

const CustomTabs = withStyles({
  root: {
    borderBottom: "none",
  },
  indicator: {
    backgroundColor: "#FFA412",
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 40,
    opacity: 1,
    color: "#2a31a3",
    fontWeight: 400,
    "&:hover": {
      color: "#FFA412",
      opacity: 1,
    },
    "&$selected": {
      color: "#FFA412",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#FFA412",
    },
  },
  selected: {
    color: "#FFA412",
  },
}))((props) => <Tab disableRipple {...props} />);
