import {
  GET_FILES_ISSUES,
  GET_FILES_ISSUES_LOADING,
  GET_ARCHIVED_FILES_ISSUES,
  GET_ARCHIVED_FILES_ISSUES_LOADING,
  GET_COMPLETED_FILES_ISSUES,
  GET_COMPLETED_FILES_ISSUES_LOADING,
  GET_IGNORED_FILES_ISSUES,
  GET_IGNORED_FILES_ISSUES_LOADING,

  GET_NEW_FILES_ISSUES,
  GET_NEW_FILES_ISSUES_LOADING,

  GET_PDF_CHECKER,
  GET_PDF_CHECKER_LOADING,
} from '../actions/fileOptimizationTablesActions';

const initialState = {
  filesIssues: null,
  filesIssuesStatus: "pending",
  filesIssuesLoading: false,

  filesNewIssues: null,
  filesNewIssuesStatus: "pending",
  filesNewIssuesLoading: false,

  filesArchivedData: [],
  filesArchivedDataLoading: false,

  filesCompletedData: [],
  filesCompletedDataLoading: false,

  filesIgnoredData: [],
  filesIgnoredDataLoading: false,

    pdfChecker: [],
  pdfCheckStatus:null,
  pdfCheckerLoading: false,
};

 const filesTablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FILES_ISSUES:
      return {
        ...state,
        filesIssues: action.payload.result.issues,
        filesIssuesStatus: action.payload.status,
        filesIssuesLoading: false
      }
    case GET_FILES_ISSUES_LOADING:
      return {
        ...state,
        filesIssuesLoading: true
      }

    case GET_NEW_FILES_ISSUES:
      return {
        ...state,
        filesNewIssues: action.payload.result.issues,
        filesNewIssuesStatus: action.payload.status,
        filesNewIssuesLoading: false
      }
    case GET_NEW_FILES_ISSUES_LOADING:
      return {
        ...state,
        filesNewIssuesLoading: true
      }

    case GET_ARCHIVED_FILES_ISSUES:
      return {
        ...state,
        filesArchivedData: action.payload.result.issues,
        filesArchivedDataLoading: false
      }
    case GET_ARCHIVED_FILES_ISSUES_LOADING:
      return {
        ...state,
        filesArchivedDataLoading: true
      }

    case GET_COMPLETED_FILES_ISSUES:
      return {
        ...state,
        filesCompletedData: action.payload.result.issues,
        filesCompletedDataLoading: false
      }
    case GET_COMPLETED_FILES_ISSUES_LOADING:
      return {
        ...state,
        filesCompletedDataLoading: true
      }

    case GET_IGNORED_FILES_ISSUES:
      return {
        ...state,
        filesIgnoredData: action.payload.result.issues,
        filesIgnoredDataLoading: false
      }
    case GET_IGNORED_FILES_ISSUES_LOADING:
      return {
        ...state,
        filesIgnoredDataLoading: true
      }

      case GET_PDF_CHECKER:
        return {
          ...state,
          pdfChecker: action.payload.files,
          pdfCheckerStatus: action.payload.status,
          pdfCheckerLoading: false
        }
      case GET_PDF_CHECKER_LOADING:
        return {
          ...state,
          pdfCheckerLoading: !state.pdfCheckerLoading
        }
    default:
      return state;
  }
};

export default filesTablesReducer;