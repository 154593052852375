import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Stepper from './Stepper';
import componentStyles from './styles/stepper';
import componentStyles2 from './styles/margin';

const useStyles = makeStyles(componentStyles);
const margins = makeStyles(componentStyles2);

const Index = () => {
    const classes = useStyles();
    const marginClasses = margins();

    return (
        <Box p={2}>
            <Typography className={classes.heading1}>CCPA Request</Typography>
            <Paper elevation={2} sx={{ marginTop: '10px', padding: '20px' }}>
                <Typography className={`${classes.heading2} ${marginClasses['mt-5']} ${classes.centerItem}`}>Request portal</Typography>
                <div className={`${marginClasses['mt-20']}`} sx={{ width: '100%', height: '100%' }}>
                    <Stepper />
                </div>
            </Paper>
        </Box>
    );
};

export default Index;
