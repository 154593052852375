import axios from "axios";
import { BASE_URL } from "../../source/constants";
import { toast } from "react-toastify";
import auth0Service from "../../services/auth0Service/authentikService";

export const GET_ALL_SCANS = "GET_ALL_SCANS";
export const GET_ALL_SCANS_LOADING = "GET_ALL_SCANS_LOADING";
export const GET_ALL_DOMAINS = "GET_ALL_DOMAINS";


export const createPrivacyPolicy = (domain, data) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const res = await axios.post(
        `${BASE_URL}/cookie/upload-privacy-json-config`,
        {
          domain: domain,
          data: data
        },
        options
      );
  
      if (res.data) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        console.warn('Warning ⚠️ bad internet connection.');
      } else {
        console.log(err.response.data.message);
        toast.warning(err.response.data.message);
      }
    }
 };

 export const getDomainScripts = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
  
      const res = await axios.get(
        `${BASE_URL}/cookie/domain-script`,
        {
          params: {
            domain: domain,
          },
          ...options
        }
      );
  
      if (res.data) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        console.warn('Warning ⚠️ bad internet connection.');
      } else {
        console.log(err.response.data.message);
        toast.warning(err.response.data.message);
      }
    }
  };
  

  export const generateCookieScript = (domain) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
  
      };
      const res = await axios.post(
        `${BASE_URL}/cookie/generate-script`,
        {
          domain: domain,
        },
        options
      );
  
      if (res.data) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        console.warn('Warning ⚠️ bad internet connection.');
      } else {
        console.log(err.response.data.message);
        toast.warning(err.response.data.message);
      }
    }
 };