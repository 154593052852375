import React from 'react';

import Auth0Service from "../services/auth0Service/authentikService";
import BillingPageFreeUSers from './financialComponents/BillingPageFreeUSers';
import FinancialsRegisteredUsers from './financialComponents/FinancialsRegisteredUsers';


export default function Financials() {


  if (!Auth0Service.isAuthenticated()) {
    return <BillingPageFreeUSers />
  } else {
    return (
      <FinancialsRegisteredUsers />
    );
  }
}
