import auth0Service from "../../services/auth0Service/authentikService";
import axios from "axios";
import { BASE_URL } from "../../source/constants";
// import history from '../../@history';
import { getDomains } from "./dashboardActions";
import { toast } from "react-toastify";

import store from "../../store";

export const GET_WEBSITES = "GET_WEBSITES";
export const LOADING_WEBSITES = "LOADING_WEBSITES";
export const SET_REMAINING_WEBSITES = "SET_REMAINING_WEBSITES";
export const REMAINING_WEBSITES_AFTER_DELETED =
  "REMAINING_WEBSITES_AFTER_DELETED";

export const INVITE_USER = "INVITE_USER";
export const GET_USERS = "GET_USERS";
export const LOADING_USERS = "LOADING_USERS";
export const CURRENT_USER_ACTION_LOADING = "CURRENT_USER_ACTION_LOADING";
export const LOADING_INVITED_USERS = "LOADING_INVITED_USERS";
export const LOADING_INVITED_USERS_DELETED = "LOADING_INVITED_USERS_DELETED";

export const GET_INVITED_USERS = "GET_INVITED_USERS";
export const SET_REMAINING_USERS = "SET_REMAINING_USERS";
export const REMAINING_USERS_AFTER_ADDED = "REMAINING_USERS_AFTER_ADDED";
export const REMAINING_USERS_AFTER_DELETED = "REMAINING_USERS_AFTER_DELETED";
export const LOADING_INVITE = "LOADING_INVITE";

export const inviteUser = (email, role, orgName) => async (dispatch) => {
  dispatch({
    type: LOADING_INVITE,
  });

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(
      `${BASE_URL}/auth/invite`,
      {
        orgName,
        email,
        role,
      },
      options
    );

    if (res.data.message) {
      dispatch({
        type: LOADING_INVITE,
      });
      if (res.data.message === "User Invite Sent") {
        dispatch({
          type: REMAINING_USERS_AFTER_ADDED,
        });
      }
      toast.success(res.data.message);
      dispatch(getInvitedUsers());
    }
  } catch (err) {
    if (err) {
      dispatch({
        type: LOADING_INVITE,
      });
    }
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  dispatch({
    type: LOADING_USERS,
  });

  dispatch({
    type: CURRENT_USER_ACTION_LOADING,
  });

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      userId,
    };

    const res = await axios.delete(`${BASE_URL}/auth/users`, {
      params,
      ...options,
    });
    if (res.data.message === "User Deleted") {
      dispatch({
        type: REMAINING_USERS_AFTER_DELETED,
      });
      dispatch({
        type: LOADING_USERS,
      });
      dispatch({
        type: CURRENT_USER_ACTION_LOADING,
      });
      dispatch(getUsers());
      toast.success("User Deleted");
    }
  } catch (err) {
    toast.error("An Error Occurred");
    dispatch({
      type: LOADING_USERS,
    });
    dispatch({
      type: CURRENT_USER_ACTION_LOADING,
    });
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
};

export const deleteInvitedUser = (userId) => async (dispatch) => {
  dispatch({
    type: LOADING_INVITED_USERS_DELETED,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      userId,
    };

    const res = await axios.delete(`${BASE_URL}/auth/users`, {
      params,
      ...options,
    });
    if (res.data.message === "User Deleted") {
      dispatch({
        type: LOADING_INVITED_USERS_DELETED,
      });
      dispatch({
        type: REMAINING_USERS_AFTER_DELETED,
      });
      dispatch(getInvitedUsers());
      toast.success("User Deleted");
    }
  } catch (err) {
    toast.error("An Error Occurred");
    dispatch({
      type: LOADING_INVITED_USERS_DELETED,
    });
    toast.success(err.response.data.message);
  }
};

export const deleteWebsite = (website) => async (dispatch) => {
  dispatch({
    type: LOADING_WEBSITES,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      website,
    };

    const res = await axios.delete(`${BASE_URL}/dashboard/websites`, {
      params,
      ...options,
    });
    if (res.data.message === "Website Deleted") {
      dispatch({
        type: REMAINING_WEBSITES_AFTER_DELETED,
      });
      dispatch({
        type: LOADING_WEBSITES,
      });
      dispatch(getWebsites());
      dispatch(getDomains());
      toast.success("Website Deleted");
    }
  } catch (err) {
    dispatch({
      type: LOADING_WEBSITES,
    });
    toast.error(err.response.data.message);
  }
};

export const blockUser = (id, block) => async (dispatch) => {
  dispatch({
    type: LOADING_USERS,
  });
  dispatch({
    type: CURRENT_USER_ACTION_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(
      `${BASE_URL}/auth/block`,
      {
        userId: id,
        blocked: block,
      },
      options
    );

    if (
      res.data.message === "User Blocked" ||
      res.data.message === "User Unblocked"
    ) {
      dispatch({
        type: LOADING_USERS,
      });
      dispatch({
        type: CURRENT_USER_ACTION_LOADING,
      });
      dispatch(getUsers());
      toast.success(res.data.message);
    }
  } catch (err) {
    dispatch({
      type: LOADING_USERS,
    });
    dispatch({
      type: CURRENT_USER_ACTION_LOADING,
    });
    toast.error(err.response.data.message);
  }
};

export const changeRole = (userId, role, permissions) => async (dispatch) => {
  dispatch({
    type: LOADING_USERS,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    if (role !== "custom") {
      var res = await axios.post(
        `${BASE_URL}/auth/edit-user-role`,
        {
          userId,
          role,
        },
        options
      );
    } else {
      // eslint-disable-next-line 
      var res = await axios.post(
        `${BASE_URL}/auth/edit-user-role`,
        {
          userId,
          role,
          permissions,
        },
        options
      );
    }
    if (res.data) {
      dispatch({
        type: LOADING_USERS,
      });
      dispatch(getUsers());
      toast.success(res.data.message);
    }
  } catch (err) {
    dispatch({
      type: LOADING_USERS,
    });
    toast.success(err.response.data.message);
  }
};

export const getUsers = () => async (dispatch) => {
  dispatch({
    type: LOADING_USERS,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.get(`${BASE_URL}/auth/users`, options);
    if (res.data.users) {
      dispatch({
        type: GET_USERS,
        payload: res.data.users,
      });
      dispatch({
        type: LOADING_USERS,
      });
    }
  } catch (err) {
    if (err) {
      dispatch({
        type: LOADING_USERS,
      });
    }
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
};

export const getWebsites = () => async (dispatch) => {
  dispatch({
    type: LOADING_WEBSITES,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.get(`${BASE_URL}/dashboard/websites`, options);
    if (res.data.websites) {
      dispatch({
        type: GET_WEBSITES,
        payload: res.data.websites,
      });
      dispatch({
        type: LOADING_WEBSITES,
      });
    }
  } catch (err) {
    dispatch({
      type: LOADING_WEBSITES,
    });

    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
};

export const getInvitedUsers = () => async (dispatch) => {
  dispatch({
    type: LOADING_INVITED_USERS,
  });

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.get(`${BASE_URL}/auth/invited-users`, options);
    if (res.data.users) {
      dispatch({
        type: LOADING_INVITED_USERS,
      });
      dispatch({
        type: GET_INVITED_USERS,
        payload: res.data.users,
      });
    }
  } catch (err) {
    if (err) {
      dispatch({
        type: LOADING_INVITED_USERS,
      });
    }
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
};

export const getRemainingWebsitesUsers = () => async (dispatch) => {
  let remainingWebsites = store.getState().auth.user.remainingWebsites;
  let remainingUsers = store.getState().auth.user.remainingUsers;

  dispatch({
    type: SET_REMAINING_WEBSITES,
    payload: remainingWebsites,
  });

  dispatch({
    type: SET_REMAINING_USERS,
    payload: remainingUsers,
  });
};
