import auth0Service from "../../services/auth0Service/authentikService";
import axios from "axios";
import { BASE_URL } from "../../source/constants";
import { toast } from "react-toastify";
import history from "../../@history";
import objectWithoutKey from '../../utils/objectWithoutKey';

export const ADD_DOMAIN_ONBOARD_LOADING = "ADD_DOMAIN_ONBOARD_LOADING";
export const ADD_DOMAIN_ONBOARD_SUCCESS = "ADD_DOMAIN_ONBOARD_SUCCESS";
export const GET_ONBOARD_DOMAIN_DATA = "GET_ONBOARD_DOMAIN_DATA";
export const GET_SCAN_PROGRESS = "GET_SCAN_PROGRESS";
export const SEND_REPORT_LOADING = "SEND_REPORT_LOADING";
export const ADD_DOMAIN_FORBIDDEN = 'ADD_DOMAIN_FORBIDDEN';
export const CLEAR_FORBIDDEN_ERROR = 'CLEAR_FORBIDDEN_ERROR';

export const clearForbiddenError = () => {
  return {
    type: CLEAR_FORBIDDEN_ERROR,
  };
};

export const addOnBoardDomain = (domain) => async (dispatch) => {
  dispatch({
    type: ADD_DOMAIN_ONBOARD_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const res = await axios.post(
      `${BASE_URL}/sitemap/init`,
      {
        ...domain,
      },
      options
    );

    if (res.status === 200) {
      dispatch({
        type: ADD_DOMAIN_ONBOARD_LOADING,
      });
      toast.success("Your Domain was Added Successfully");
      if (res.data.firewall) {
        toast.warn("Firewall Detected");
      }
      dispatch({
        type: ADD_DOMAIN_ONBOARD_SUCCESS,
        payload: res.data.domain,
      });

      localStorage.setItem("scanManagerId", res.data.scanId);
      localStorage.setItem("domain", res.data.domain);
      localStorage.setItem("website", res.data.domain);
      setTimeout(() => {
        history.push({
          pathname: "/dashboard",
          search: `?domain=${res.data.domain}&scanId=${res.data.scanId}`,
        });
      }, 5000);
    }
  } catch (err) {
    console.log(err)
    // if (!err.response) {
    //   toast.warn("Warning ⚠️ bad internet connection.");
    // }
    dispatch({
      type: ADD_DOMAIN_ONBOARD_LOADING,
    });
    if (err.response && err.response.status === 403) {
      // Set the error message in the component state for a 403 error
      const errorMessage = err.response.data.message;
      dispatch({
        type: ADD_DOMAIN_FORBIDDEN,
        payload: errorMessage,
      });
    } 
    toast.warn(err.response.data.message)
  }
};

export const getSingleScanProgress = (scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    };

    const res = await axios.get(`${BASE_URL}/dashboard/scan-progress`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_SCAN_PROGRESS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
  }
};

export const getDomainData = () => async (dispatch) => {
  dispatch({
    type: ADD_DOMAIN_ONBOARD_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios.get(`${BASE_URL}/scan/paywall-scan`, options);

    if (res.data) {
      dispatch({
        type: GET_ONBOARD_DOMAIN_DATA,
        payload: res.data,
      });
      dispatch({
        type: ADD_DOMAIN_ONBOARD_SUCCESS,
        payload: res.data.domain,
      });
      dispatch({
        type: ADD_DOMAIN_ONBOARD_LOADING,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    dispatch({
      type: ADD_DOMAIN_ONBOARD_LOADING,
    });
  }
};

export const getBillingStage = (domainId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const params = {
      domainId,
    };
    const res = await axios.get(`${BASE_URL}/dashboard/billing`, {
      params,
      ...options,
    });

    if (res.data) {
      return res.data;
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    dispatch({
      type: ADD_DOMAIN_ONBOARD_LOADING,
    });
  }
};

export const postBillingStage =
  (domainId, billing, stage) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();

      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `${BASE_URL}/dashboard/billing`,
        {
          evaluation: billing,
          domainId,
          stage,
        },
        options
      );

      if (res.data) {
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        toast.warn("Warning ⚠️ bad internet connection.");
      }
      dispatch({
        type: ADD_DOMAIN_ONBOARD_LOADING,
      });
    }
  };

export const sendReport = (scanId, emailReport) => async (dispatch) => {
  dispatch({
    type: SEND_REPORT_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios.post(
      `${BASE_URL}/scan/paywall-email`,
      {
        ...emailReport,
        scanId,
      },
      options
    );

    if (res.data) {
      dispatch({
        type: SEND_REPORT_LOADING,
      });
      toast.success("Report Sent Successfully!");
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    toast.error(err.response.data.message);

    dispatch({
      type: SEND_REPORT_LOADING,
    });
  }
};

export const buyNowPackage =
  (buyPackage, selectedDomain, selectedScanId) => async (dispatch) => {

    // if (!buyPackage.stripePackage.rangeOfUsers) {
    //   // let packageBuy = objectWithoutKey(buyPackage.stripePackage, ["rangeOfUsers"]);
    //   buyPackage.stripePackage = {
    //     ...packageBuy,
    //     tenure: packageBuy.rangeOfPages.tenure,
    //   };
    // } else {
    buyPackage.stripePackage = {
      ...buyPackage.stripePackage,
      tenure: buyPackage?.stripePackage?.rangeOfPages?.tenure,
    };
    // }

    try {
      const token = auth0Service.getAccessToken();

      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `${BASE_URL}/dashboard/billing/subscribe`,
        {
          ...buyPackage,
        },
        options
      );

      if (res.data) {
        history.push(
          `/subscription-successful?domain=${selectedDomain}&scanId=${selectedScanId}`
        );
        toast.success(res.data.message);
      }
    } catch (err) {
      if (!err.response) {
        toast.warn("Warning ⚠️ bad internet connection.");
      }
      toast.error(err.response.data.message);

      dispatch({
        type: SEND_REPORT_LOADING,
      });
    }
  };

export const unsubscribeDomain = (domainId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const params = {
      domainId,
    };
    const res = await axios.delete(`${BASE_URL}/dashboard/billing/subscribe`, {
      params,
      ...options,
    });

    if (res.data) {
      toast.success(res.data.message);
      setTimeout(() => {
        history.push(`/dashboard`);
      }, 1500);
      return res.data;
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    toast.error(err.response.data.message);
  }
};


export const unsubscribeReason = (data) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const params = {
      ...data,
    };
    const res = await axios.post(`${BASE_URL}/dashboard/billing/reason`, data, options);

    if (res.data) {
      return res.data;
    }
  } catch (err) {

    toast.error(err.response.data.message);

  }
};


