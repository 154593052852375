import {
  CVE_DATABASE,
  CVE_DATABASE_ID,

} from '../actions/cveDatabaseActions';

const initialState = {
  data: null,
  loading: false,
  status: null,
  maxPages: 0,
  singleCVE: null,
};

 const cveDatabaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case CVE_DATABASE:
      return {
        ...state,
        data: action.payload.result,
        maxPages: action.payload.maxPages
      }
      case CVE_DATABASE_ID:
        return {
          ...state,
          singleCVE: action.payload.result
        }
    default:
      return state;
  }
};

export default cveDatabaseReducer;