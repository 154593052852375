
import auth0Service from "../../services/auth0Service/authentikService";

import axios from "axios";
import { BASE_URL } from "../../source/constants";
import { toast } from "react-toastify";

export const ADD_STRIPE_CARD_LOADING = "ADD_STRIPE_CARD_LOADING";
export const CARD_ADDED_SUCCESSFUL = "CARD_ADDED_SUCCESSFUL";

export const addStripeCard = (stripeCardToken, domainId) => async (dispatch) => {
  dispatch({
    type: ADD_STRIPE_CARD_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.post(
      `${BASE_URL}/dashboard/add-stripe-card`,
      {
        stripeCardToken,
        domainId
      },
      options
    );

    if (res.data.message === "OK") {
      dispatch({
        type: ADD_STRIPE_CARD_LOADING,
      });
      toast.success("Successfully Card Added");
      const alert = setTimeout(() => {
        window.location.reload();
        // history.push('/billing');
        clearTimeout(alert);
      }, 1000);
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    dispatch({
      type: ADD_STRIPE_CARD_LOADING,
    });
    // toast.error("An Error Occured");
  }
};
