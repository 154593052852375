import axios from "axios";
import { BASE_URL } from "../../source/constants";
import auth0Service from '../../services/auth0Service/authentikService';
import { toast } from "react-toastify";

export const MODULE_SUMMARY = "MODULE_SUMMARY";

export const getModuleSummary = (scanId, module, unified) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const params = {
      scanId,
      module,
      unified
    }

    const res = await axios.get(`${BASE_URL}/dashboard/domain-module-summary`, {
      params,
      ...options,
    });

    if (res.data) {
      return res.data
    }
  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
};