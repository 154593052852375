import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import {withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import loaderwave from "../../assets/loader.svg";
import CardContainer from '../../components/commons/CardContainer';
import { getWebsites, deleteWebsite, getRemainingWebsitesUsers } from "../../store/actions/inviteUsersActions.js";
import { getCards } from "../../store/actions/changePackageActions";
// import DownGradeWebsites from "../../components/dialogs/DownGradeWebsites";

function CurrentWebsites() {

  // const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    dispatch(getRemainingWebsitesUsers());
    dispatch(getWebsites());
    dispatch(getCards());

    // eslint-disable-next-line
  }, []);
  // const { isPackagePurchased } = useSelector(state => state.auth.user);

  const websites = useSelector(state => state.inviteUsers.websites);
  // const remainingWebsites = useSelector(state => state.auth.user.remainingWebsites);
  // const totalWebsites = useSelector(state => state.auth.user);
  // const userRole = useSelector(state => state.auth.user.role[0]);

  const loadingWebsites = useSelector(state => state.inviteUsers.loadingWebsites);

  const dispatch = useDispatch();

  function deleteWebsites(id) {
    dispatch(deleteWebsite(id))
  }

  // function handleClose() {
  //   setOpen(false);
  // }

  return (
    <Box style={{ marginTop: "4rem" }}>
      <CardContainer>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap" >
          <CustomTypography variant="h5" component="h5"  >
            Current Websites
        </CustomTypography>
          {/* {userRole === "admin" && isPackagePurchased && <Box display="flex" alignItems="center" flexWrap="wrap" >
            <h4 style={{ marginRight: 10 }}>
              Slots Available: {remainingWebsites} out of {totalWebsites.selectedPackage.rangeOfWebsites.websiteRange}
            </h4>
            <Button variant="contained" color="primary"  style={{borderRadius:"20px" }} onClick={() => setOpen(true)}>Remove Websites</Button>
          </Box>
          } */}
        </Box>
        {websites &&
          !loadingWebsites ?
          <TableContainer component={Paper} >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow >
                  <CustomTableHead align="left" style={{ minWidth: "150px", fontWeight: "bold", fontFamily: "Lato" }}>Domain</CustomTableHead>
                  <CustomTableHead align="left" style={{ minWidth: "100px", fontWeight: "bold", fontFamily: "Lato" }}>Actions</CustomTableHead>
                </TableRow>
              </TableHead>
              <TableBody>
                {websites.map((row) => (
                  <TableRow key={row.website} >
                    <TableCell align="left">{row.website}</TableCell>
                    <TableCell align="left"><Button onClick={() => deleteWebsites(row.website)} disabled={websites.length < 2} ><DeleteIcon /></Button></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          :
          <Box display="flex" justifyContent="center" alignItems="center" height="100%"> <img src={loaderwave} alt="loader" /></Box>
        }
        {/* {isPackagePurchased && <DownGradeWebsites remainingWebsites={remainingWebsites} totalWebsites={totalWebsites.selectedPackage.rangeOfWebsites.websiteRange} open={open} onClose={handleClose} /> } */}
      </CardContainer>
    </Box >
  );
}

export default React.memo(CurrentWebsites)

const CustomTableHead = withStyles(theme => ({
  head: {
    // backgroundColor: "#1F3A93",
    color: theme.palette.common.black,
  },
}))(TableCell);

const CustomTypography = withStyles(theme => ({
  h5: {
    fontWeight: 600,fontSize:"19px"
  },
}))(Typography);