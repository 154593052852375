import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Button, TextField } from '@material-ui/core';

import Auth0Service from "../services/auth0Service/authentikService";

import { getAllDomains, DomainModules, enableDisableModules, updateDomainLinks } from "../store/actions/adminScansManagementActions";
import { addDomain } from "../store/actions/dashboardActions"

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AdminCybersec from './AdminComponents/AdminCybersec'
import { getAllAccounts } from "../store/actions/adminScansManagementActions";
import CustomCardContainerHead from "../components/commons/CustomCardContainerHead"
import { Alert } from "@material-ui/lab";


const useStyles = makeStyles({
  selectBox: {
    maxWidth: 300,
    width: 300,
  },
});

const styles = {
  textField: {
    width: '100%',
    margin: 10,
    maxWidth: 300,
  }
}

export default function CollapsibleTable() {
  const classes = useStyles();
  const allDomains = useSelector((state) => state.adminScansManagementReducer.allDomains);
  const [domain, setDomain] = React.useState("");
  const [domainName, setDomainName] = React.useState("");
  const [user, setUser] = React.useState("");
  const [numericValue, setNumericValue] = useState("");
  const [prodNumericValue, setProdNumericValue] = useState(500);
  const [demo, setDemo] = useState(true);
  const [environment, setEnvironment] = useState("demo");
  const [loadingAccounts, setLoadingAccounts] = React.useState(true)
  const [data, setData] = React.useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllDomains());
  }, [dispatch]);

  useEffect(() => {
    if (user && allDomains.length > 0) {
      const filteredDomains = allDomains.filter(d => d.userEmail === user && d.environment === "demo");
      if (filteredDomains.length > 0) {
        console.log(filteredDomains)
        setDomain(filteredDomains[0]._id);
        setDomainName(filteredDomains[0].domain);
      }
    }
  }, [user, allDomains]);


  React.useEffect(() => {
    getAccounts()
  }, [])

  const handleSubmit = () => {
    // This function will be called when the submit button is clicked
    // You can use the numericValue state here to perform any action with the entered numeric value
    console.log("Submitted numeric value:", numericValue);
    dispatch(addDomain(domainName, user, demo));


    // Add your logic here to handle the submitted numeric value
  }


  const handleUpdate = () => {
    // This function will be called when the submit button is clicked
    // You can use the numericValue state here to perform any action with the entered numeric value
    console.log("Submitted numeric value:", numericValue);
    dispatch(updateDomainLinks(domainName, numericValue, demo, environment));


    // Add your logic here to handle the submitted numeric value
  }

  const handleUpdatetoProd = () => {
    // This function will be called when the submit button is clicked
    // You can use the numericValue state here to perform any action with the entered numeric value
    let changeProd = false
    let prodEnvironment = "production"
    dispatch(updateDomainLinks(domainName, prodNumericValue, changeProd, prodEnvironment));


    // Add your logic here to handle the submitted numeric value
  }

  function getAccounts() {
    dispatch(getAllAccounts()).then(data => {
      if (data) {
        setLoadingAccounts(false);
        setData(data.result)
        console.log(data.resul)
      } else {
        setLoadingAccounts(false)

      }
    }).catch(err => {
      setLoadingAccounts(false)
    })
  }



  if (!Auth0Service.isAuthenticated()) {
    return window.location.href = "/authenticate"
  } else {

    return (
      <>
        {/* <CustomCardContainerHead title={"Add Demo Website"} titleTag="h1">
          <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent='flex-start' mb={2} mx={1} style={{ backgroundColor: "#FFF", padding: 20 }}>
            <label htmlFor='select-domain' style={{ marginRight: '10px' }}>Select User:</label>
            <br />
            <Select
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} />
              )}
              variant="outlined"
              labelId="select-domain"
              id="select-domain"

              value={user}
              className={classes.selectBox}
              onChange={(event) => {
                const selectedUser = event.target.value;
                setUser(selectedUser);
                console.log("her slected user" + selectedUser); // Ensure that the correct domain is logged
              }}
              style={{ marginRight: '10px' }}
            >
              {data.map(domain => {
                return <MenuItem value={domain.email}>{`${domain.name}-${domain.email}`}</MenuItem>
              })}
            </Select>
            <br />
            <br />
            <label htmlFor='select-domain' style={{ marginRight: '10px' }}>Select Domain:</label>
            <br />
            <Select
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} />
              )}
              variant="outlined"
              labelId="select-domain"
              id="select-domain"

              value={domain}
              className={classes.selectBox}
              onChange={(event) => {
                const selectedDomainName = allDomains.find(d => d._id === event.target.value).domain; // Retrieve the domain name using the ID
                setDomainName(selectedDomainName);
                console.log("Selected domain name:", selectedDomainName);
                const selectedDomain = event.target.value;
                setDomain(selectedDomain);
                console.log("this is domain" + selectedDomain); // Ensure that the correct domain is logged
              }}
              style={{ marginRight: '20px' }}
            >
              {allDomains
                .filter(domain => domain.userEmail === user)
                .map(filteredDomain => (
                  <MenuItem key={filteredDomain._id} value={filteredDomain._id}>{filteredDomain.domain}</MenuItem>
                ))
              }
            </Select>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Add
            </Button>
          </Box>
        </CustomCardContainerHead> */}

        <CustomCardContainerHead title={"Add Demo Website"} titleTag="h1">
          <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent='flex-start' mb={2} mx={1} style={{ backgroundColor: "#FFF", padding: 20 }}>
            <label htmlFor='user-email' style={{ marginRight: '10px' }}>User Email:</label>
            <br />
            <input
              type="email"
              id="user-email"
              value={user}
              className={classes.inputBox}
              onChange={(event) => {
                const userEmail = event.target.value;
                setUser(userEmail);
                console.log("Selected user email: " + userEmail);
              }}
              style={{ marginRight: '10px', padding: '10px', outline: 'none', borderColor: '#ccc' }}
            />
            <br />
            <br />
            <label htmlFor='domain-input' style={{ marginRight: '10px' }}>Domain:</label>
            <br />
            <input
              type="text"
              id="domain-input"
              value={domainName}
              className={classes.inputBox}
              onChange={(event) => {
                const domainInput = event.target.value;
                setDomainName(domainInput);
                console.log("Input domain name: " + domainInput);
              }}
              style={{ marginRight: '20px', padding: '10px', outline: 'none', borderColor: '#ccc' }}
            />
            <br />
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Add
            </Button>
          </Box>
        </CustomCardContainerHead>

        <CustomCardContainerHead title={"Update Demo Website"} titleTag="h1">
          <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent='flex-start' mb={2} mx={1} style={{ backgroundColor: "#FFF", padding: 20 }}>
            <label htmlFor='select-domain' style={{ marginRight: '10px' }}>Select User:</label>
            <br />
            <Select
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} />
              )}
              variant="outlined"
              labelId="select-domain"
              id="select-domain"

              value={user}
              className={classes.selectBox}
              onChange={(event) => {
                const selectedUser = event.target.value;
                setUser(selectedUser);
                console.log("her slected user" + selectedUser); // Ensure that the correct domain is logged
              }}
              style={{ marginRight: '10px' }}
            >
              {data.map(domain => {
                return <MenuItem value={domain.email}>{`${domain.name}-${domain.email}`}</MenuItem>
              })}
            </Select>
            <br />
            <br />
            <label htmlFor='select-domain' style={{ marginRight: '10px' }}>Select Domain:</label>
            <br />
            <Select
              IconComponent={(props) => (
                <ExpandMoreIcon {...props} />
              )}
              variant="outlined"
              labelId="select-domain"
              id="select-domain"

              value={domain}
              className={classes.selectBox}
              onChange={(event) => {
                const selectedDomain = event.target.value;
                setDomain(selectedDomain);
                console.log(selectedDomain); // Ensure that the correct domain is logged
              }}
              style={{ marginRight: '20px' }}
            >
              {allDomains
                .filter(domain => domain.userEmail === user && domain.environment === "demo")
                .map(filteredDomain => (
                  <MenuItem key={filteredDomain._id} value={filteredDomain._id}>{filteredDomain.domain}</MenuItem>
                ))
              }
            </Select>
            <TextField
              id="numeric-input"
              label="Enter Number of Links"
              type="number"
              InputProps={{ inputProps: { min: 1 } }} // Ensure only non-negative numbers are allowed
              value={numericValue}
              onChange={(event) => setNumericValue(event.target.value)}
              style={{ marginRight: '20px' }}
            />

            {/* Button to submit */}
            <Button variant="contained" color="primary" onClick={handleUpdate}>
              Update
            </Button>


            {demo && environment && domainName && allDomains.filter(domain => domain.userEmail === user && domain.environment === "demo").length > 0 ? (
              <>
                {/* <Alert severity="info">
                {scan && <span style={{ fontSize: 17 }}>Currently It is a demo scan to move to production click the button move to production </span>}
              </Alert> */}
                <Button color='primary' variant="contained" onClick={() => handleUpdatetoProd()} style={{ marginLeft: 10 }}>Move to Production
                  {/* {regenLoading && <CircularProgress size={20} style={{ marginLeft: 10 }} />} */}
                </Button>
              </>
            ) : null
            }

          </Box>
        </CustomCardContainerHead>
      </>
    );
  }
}