import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { Typography } from '@material-ui/core';
import Loader from "../../components/commons/Loader";
import CustomCardContainerHead from "../../components/commons/CustomCardContainerHead";
import { useDispatch } from "react-redux";
import { getContactInfo, updateContent, getPluginStatusWp, getCrawlerStatus, getContactInfoWp, updateContentWp } from "../../store/actions/abracadabraActions";
import Pagination from "../../../src/components/pagination/Pagination"; // Import the Pagination component
import { toast } from "react-toastify";

export default function ContactDetails({ domain, selectedScanId }) {
  const [loadingLinks, setLoadingLinks] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [currentPage1, setCurrentPage1] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [currentPage3, setCurrentPage3] = useState(1);
  const [showProgress, setShowProgress] = useState(false);
  const [contactTexts, setContactTexts] = useState('');
  const [contactTextHref, setContactTextHref] = useState('');
  const [emailTexts, setEmailTexts] = useState('');
  const [emailTextHref, setEmailTextHref] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [pluginInstalled, setPluginInstalled] = useState(true);
  const [post_id, setPostId] = useState(null);
  const [scanStatus, setScanStatus] = useState('');
  const [showContactInfo, setShowContactInfo] = useState(false);


  const dispatch = useDispatch();

  // Simulating a process with setTimeout
  React.useEffect(() => {
    // Simulate a process taking 3 seconds to complete
    const process = setTimeout(() => {
      // Once the process is completed
      setLoading(false); // Hide the loader
      // Additional actions or state changes upon success can go here
    }, 4000);

    // Clear the timeout if component unmounts or if process succeeds before 3 seconds
    return () => clearTimeout(process);
  }, []);


  React.useEffect(() => {
    getPluginStatus2();
  }, []);

  // React.useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     getCrawlStatus();
  //   }, 5000); // 5 seconds in milliseconds

  //   // Clear interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, [showContactInfo]);



  useEffect(() => {
    setLoadingLinks(true);
    getContactDetails();
  }, []);

  // Handle checkbox change
  const handleCheckboxChange = (event, index) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedRows([...selectedRows, index]);
    } else {
      setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== index));
    }
  };

  function getContactDetails() {
    dispatch(getContactInfoWp(selectedScanId))
      .then((response) => {
        console.log(response.message)
        if (response && response.message === "Task Results") {
          setLoadingLinks(false);
          setData(response.result);
          setPostId((response.result.contactPage.pageId[0]).split('page-id-')[1])
        } else {
          setLoadingLinks(false);
          // toast.error("No contact details found.");
        }
      })
      .catch((error) => {
        setLoadingLinks(false);
        // toast.error("No contact details found.");
      });
  }

  async function getPluginStatus2() {
    try {
        const response = await dispatch(getPluginStatusWp(selectedScanId));
        console.log(response);
        if(response.error) {
            setPluginInstalled(true);
            setLoadingLinks(false);
        }
        if (response && response.response === 200) {
            setPluginInstalled(true);
            setLoadingLinks(false);
            // console.log(response.response.result);
        } else {
            setPluginInstalled(false);
            setLoadingLinks(false);
            // toast.error("Failed to retrieve contact details.");
        }
    } catch (error) {
        setPluginInstalled(false);
        setLoadingLinks(false);
    }
}

  async function getCrawlStatus() {
    try {
      const response = await dispatch(getCrawlerStatus(domain));
      console.log(response.response.result);
      if (response && response.response.result === "completed") {
        setLoadingLinks(false);
        setShowContactInfo(true);
        setScanStatus(response.response.result);
        // console.log(response.response.result);
      } else {
        setShowContactInfo(true);
        setLoadingLinks(false);
        setScanStatus(response.response.result);
        // toast.error("Failed to retrieve contact details.");
      }
    } catch (error) {
      setLoadingLinks(false);
      setShowContactInfo(false);
      // toast.error("No task found or still running");
    }
  }

  // function updateWpContent() {
  //   dispatch(updateContent(domain, ))
  //     .then((response) => {
  //       if (response && response.message === "Task Results") {
  //         setLoadingLinks(false);
  //         setData(response.result);
  //       } else {
  //         setLoadingLinks(false);
  //         toast.error("Failed to retrieve contact details.");
  //       }
  //     })
  //     .catch((error) => {
  //       setLoadingLinks(false);
  //       toast.error("Failed to retrieve contact details.");
  //     });
  // }

  function updateWpContent(post_id, search_text, replace_text, contact, contactHref, emailaddress, emailaddresshref, footerText, footerTextHref) {
    // handleSkipToContentToggle()
    dispatch(updateContentWp(selectedScanId, post_id, search_text, replace_text, contact, contactHref, emailaddress, emailaddresshref, footerText, footerTextHref))
      .then(data => {
        if (data) {
          setLoadingLinks(false);
          toast.success(data.response)
          // setData(data.result);
        } else {
          setLoadingLinks(false);
        }
      })
      .catch(err => {
        setLoadingLinks(false);
      });
  }

  // Extract contactPage data from the result
  const contactPage = data.contactPage || {};

  // Define rows based on contactNumbers, emailAddresses, and Addresses arrays
  const contactNumbers = contactPage.contactNumbers || [];
  const emailAddresses = contactPage.emailAddresses || [];
  const addresses = contactPage.Addresses || [];

  // Pagination for the three tables
  const itemsPerPage = 10;
  const totalPages1 = Math.ceil(contactNumbers.length / itemsPerPage);
  const totalPages2 = Math.ceil(emailAddresses.length / itemsPerPage);
  const totalPages3 = Math.ceil(addresses.length / itemsPerPage);

  const currentContactNumbers = contactNumbers.slice((currentPage1 - 1) * itemsPerPage, currentPage1 * itemsPerPage);
  const currentEmailAddresses = emailAddresses.slice((currentPage2 - 1) * itemsPerPage, currentPage2 * itemsPerPage);
  const currentAddresses = addresses.slice((currentPage3 - 1) * itemsPerPage, currentPage3 * itemsPerPage);

  const handlePageChange1 = (newPage) => {
    setCurrentPage1(newPage);
  };

  const handlePageChange2 = (newPage) => {
    setCurrentPage2(newPage);
  };

  const handlePageChange3 = (newPage) => {
    setCurrentPage3(newPage);
  };
  const handleUpdateContactText = (index, numberValue) => {
    // Get the new contactText value for the specific row
    const updatedContactText = contactTexts[index];
    console.log(post_id)
    if (numberValue) {
      let contact = true
      let contactHref = false
      let emailaddress = false
      let emailaddresshref = false
      let footerText = false
      let footerTextHref = false
      updateWpContent(post_id, numberValue, `tel:${numberValue}`, contact, contactHref, emailaddress, emailaddresshref, footerText, footerTextHref)
    }
    // Log both the index and the numberValue
    console.log(`Updating contactText for row ${index} with number value: ${numberValue}`);
    console.log(`Updated contactText: ${updatedContactText}`);



    // Implement the logic to update the contactText for the selected row
    // You can use index, numberValue, and updatedContactText as needed
    // ...

    // Optionally, you can reset the input field value to an empty string
    setTimeout(() => {
      // Hide the progress indicator after 3 seconds
      const newTexts = [...contactTexts];
      newTexts[index] = ''; // Reset the input field value
      setContactTexts(newTexts);
    }, 2000);

  };

  const handleUpdateContactHrefText = (index, numberValue) => {
    // Get the new contactText value for the specific rowupdateContent
    // const updatedContactText = contactTextHref[index];
    console.log(post_id)
    if (numberValue) {
      let contact = false
      let contactHref = true
      let emailaddress = false
      let emailaddresshref = false
      let footerText = false
      let footerTextHref = false
      updateWpContent(post_id, numberValue, `tel:${numberValue}`, contact, contactHref, emailaddress, emailaddresshref, footerText, footerTextHref)
    }
    // Log both the index and the numberValue
    // console.log(`Updating contactText href for row ${index} with number value: ${numberValue}`);
    // console.log(`Updated contactText: ${updatedContactText}`);



    // Implement the logic to update the contactText for the selected row
    // You can use index, numberValue, and updatedContactText as needed
    // ...

    // Optionally, you can reset the input field value to an empty string
    // setTimeout(() => {
    //   // Hide the progress indicator after 3 seconds
    //   const newTextHref = [...contactTextHref];
    //   newTextHref[index] = ''; // Reset the input field value
    //   setContactTextHref(newTextHref);
    // }, 2000);

  };

  const handleUpdateEmailText = (index, emailValue) => {
    // Get the new contactText value for the specific row
    const updatedContactText = emailTexts[index];
    console.log(post_id)
    if (emailValue) {
      let contact = false
      let contactHref = false
      let emailaddress = true
      let emailaddresshref = false
      let footerText = false
      let footerTextHref = false
      updateWpContent(post_id, emailValue, updatedContactText, contact, contactHref, emailaddress, emailaddresshref, footerText, footerTextHref)
    }
    // Log both the index and the numberValue
    console.log(`Updating contactText href for row ${index} with number value: ${emailValue}`);
    console.log(`Updated contactText: ${updatedContactText}`);



    // Implement the logic to update the contactText for the selected row
    // You can use index, numberValue, and updatedContactText as needed
    // ...

    // Optionally, you can reset the input field value to an empty string
    setTimeout(() => {
      // Hide the progress indicator after 3 seconds
      const newTextEmail = [...emailTexts];
      newTextEmail[index] = ''; // Reset the input field value
      setEmailTexts(newTextEmail);
    }, 2000);

  };

  const handleUpdateEmailHref = (index, emailValue) => {
    // Get the new contactText value for the specific row
    // const updatedContactText = emailTextHref[index];
    console.log(post_id)
    if (emailValue) {
      let contact = false
      let contactHref = false
      let emailaddress = false
      let emailaddresshref = true
      let footerText = false
      let footerTextHref = false
      updateWpContent(post_id, emailValue, `mailto:${emailValue}`, contact, contactHref, emailaddress, emailaddresshref, footerText, footerTextHref)
    }
    // Log both the index and the numberValue
    // console.log(`Updating contactText href for row ${index} with number value: ${emailValue}`);
    // console.log(`Updated contactText: ${updatedContactText}`);



    // Implement the logic to update the contactText for the selected row
    // You can use index, numberValue, and updatedContactText as needed
    // ...

    // Optionally, you can reset the input field value to an empty string
    // setTimeout(() => {
    //   // Hide the progress indicator after 3 seconds
    //   const newTextEmailHref = [...emailTextHref];
    //   newTextEmailHref[index] = ''; // Reset the input field value
    //   setEmailTextHref(newTextEmailHref);
    // }, 2000);

  };

  // Handle contactText change for the selected row
  // const handleContactTextChange = (event, index) => {
  //   const newTexts = [...contactTexts];
  //   newTexts[index] = event.target.value;
  //   setContactTexts(newTexts);
  //   console.log(contactTexts)
  // };


  const downloadFileInNewTab = () => {
    // Set the relative path to the file in the public folder
    const fileURL = '/plugins/AllyRight.zip';

    // Open a new tab and start the download
    window.open(fileURL);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {pluginInstalled ? (
            <>
              {/* {showContactInfo && scanStatus === "completed" ? ( */}
                <>
                  {/* Contact Numbers Table */}
                  <CustomCardContainerHead title="Contact Numbers" titleTag="h1">
                    <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                      <Table aria-label="contact numbers table">
                        <TableHead>
                          <TableRow>
                            {/* <TableCell>Select</TableCell> */}
                            <TableCell>Number Text</TableCell>
                            <TableCell>Fix Number Text</TableCell>
                            <TableCell>Number href</TableCell>
                            <TableCell>Fix Number href</TableCell>
                            <TableCell>href With Tel</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {currentContactNumbers.map((number, index) => (
                            <TableRow key={index}>
                              {/* <TableCell>
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(index)}
                      onChange={(event) => handleCheckboxChange(event, index)}
                    />
                  </TableCell> */}
                              <TableCell>
                                {number.number}
                              </TableCell>
                              <TableCell>
                                <div>
                                  {number.number.includes("tel:") ? (
                                    <button disabled>Fix</button>
                                  ) : (
                                    <button onClick={() => handleUpdateContactText(index, number.number)}>Fix</button>
                                  )}
                                </div>
                              </TableCell>
                              {number.contactHref !== true ? (
                                <TableCell>
                                  {number.htmlElement}
                                </TableCell>
                              ) : (
                                <TableCell>
                                  {number.contacthref}
                                </TableCell>
                              )

                              }
                              <TableCell>
                                <div>
                                  {number.numberWithTel || number.htmlElement.toString().includes("tel:") ? (
                                    <button disabled>Fix</button>
                                  ) : (
                                    <button onClick={() => handleUpdateContactHrefText(index, number.phoneNumberCleaned)}>Fix</button>
                                  )}
                                </div>
                              </TableCell>
                              <TableCell>{number.numberWithTel || number.htmlElement.toString().includes("tel:") ? "True" : "False"}</TableCell>
                            </TableRow>
                          ))}
                          {showProgress ? (
                            <TableRow>
                              <TableCell colSpan={2}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                                  <CircularProgress />
                                </div>
                              </TableCell>
                            </TableRow>
                          ) : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* Pagination controls for the Contact Numbers table */}
                    {!loadingLinks && data ? (
                      <Pagination totalPages={totalPages1} currentPage={currentPage1} onPageChange={handlePageChange1} />
                    ) : null}
                  </CustomCardContainerHead>

                  {/* Email Addresses Table */}
                  <CustomCardContainerHead title="Email Addresses" titleTag="h1">
                    <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                      <Table aria-label="email addresses table">
                        <TableHead>
                          <TableRow>
                            {/* <TableCell>Email Address</TableCell>
                <TableCell>Email Address Replace With</TableCell> */}
                            <TableCell>Email href</TableCell>
                            <TableCell>Email href Replace With</TableCell>
                            <TableCell>MailTo</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {currentEmailAddresses.map((email, index) => (
                            <TableRow key={index}>
                              {/* <TableCell>
                    {email.emailAddress}
                  </TableCell>
                  <TableCell>
                    <div>
                      <input
                        type="text"
                        value={emailTexts[index]}
                        onChange={(event) => {
                          const newTextEmail = [...emailTexts];
                          newTextEmail[index] = event.target.value;
                          setEmailTexts(newTextEmail);
                        }}
                      />
                      <button onClick={() => handleUpdateEmailText(index, email.emailAddress)}>Update</button>
                    </div>
                  </TableCell> */}
                              <TableCell>
                                {email.emailhref}
                              </TableCell>
                              <TableCell>

                                <div>
                                  {email.emailhref.includes("mailto:") ? (
                                    <button disabled>Fix</button>
                                  ) : (
                                    <button onClick={() => handleUpdateEmailHref(index, email.emailhref)}>Fix</button>
                                  )}
                                </div>
                              </TableCell>
                              <TableCell>{email.emailAddressMailTo ? "True" : "False"}</TableCell>
                            </TableRow>
                          ))}
                          {showProgress ? (
                            <TableRow>
                              <TableCell colSpan={2}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                                  <CircularProgress />
                                </div>
                              </TableCell>
                            </TableRow>
                          ) : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* Pagination controls for the Email Addresses table */}
                    {!loadingLinks && data ? (
                      <Pagination totalPages={totalPages2} currentPage={currentPage2} onPageChange={handlePageChange2} />
                    ) : null}
                  </CustomCardContainerHead>

                  {/* Addresses Table */}
                  <CustomCardContainerHead title="Addresses" titleTag="h1">
                    <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                      <Table aria-label="addresses table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell>Has Map Prefix</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {currentAddresses.map((address, index) => (
                            <TableRow key={index}>
                              <TableCell>{address.address}</TableCell>
                              <TableCell>{address.hasMapPrefix ? "True" : "False"}</TableCell>
                            </TableRow>
                          ))}
                          {showProgress ? (
                            <TableRow>
                              <TableCell colSpan={2}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                                  <CircularProgress />
                                </div>
                              </TableCell>
                            </TableRow>
                          ) : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* Pagination controls for the Addresses table */}
                    {!loadingLinks && data ? (
                      <Pagination totalPages={totalPages3} currentPage={currentPage3} onPageChange={handlePageChange3} />
                    ) : null}
                  </CustomCardContainerHead>
                </>
              {/* ) : (scanStatus === "pending" ? (
                <CustomCardContainerHead title="Contact Info" titleTag="h1">
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 20, marginBottom: 20 }} >
                    <Typography variant="h4" style={{ fontSize: '24px' }}>
                      Scan is running you can view the Contact Info after the scan is finished
                    </Typography>
                  </div>
                </CustomCardContainerHead>
              ) : <Loader />)} */}

            </>
          ) : (
            <CustomCardContainerHead title="Install Plugin" titleTag="h1">
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 20, marginBottom: 20 }} >
                <Typography variant="h4" style={{ fontSize: '24px' }}>
                  Plugin not installed please first install plugin on website
                </Typography>
              </div>
              <Button
                style={{ display: 'block', margin: 'auto', fontSize: '18px', backgroundColor: 'rgb(31, 58, 147)' }}
                variant="contained"
                color="primary"
                onClick={() => getPluginStatus2()}
              >
                Recheck
              </Button>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50, marginBottom: 20 }} >
                <Typography variant="h4" style={{ fontSize: '24px' }}>
                  Follow the following steps to install plugin on website:
                </Typography>
              </div>
              <div style={{ marginTop: 20, textAlign: 'center' }}>
                <Typography variant="body1">
                  {/* Step 1 */}
                  1. Download the plugin file from the download button below
                </Typography>
                <Typography variant="body1">
                  {/* Step 2 */}
                  2. Go to the wordpress dashboard and go to the plugin section
                </Typography>
                <Typography variant="body1">
                  {/* Step 3 */}
                  3. Click on the button upload plugin
                </Typography>
                <Typography variant="body1">
                  {/* Step 4 */}
                  4. Select the downloaded file from your download folder
                </Typography>
                <Typography variant="body1">
                  {/* Step 5 */}
                  5. Click on install plugin
                </Typography>
                <Typography variant="body1">
                  {/* Step 6 */}
                  6. Click on activate plugin
                </Typography>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50, marginBottom: 20 }} >
                <Typography variant="h4" style={{ fontSize: '24px' }}>
                  Click below button to download plugin
                </Typography>
              </div>
              <Button
                style={{ display: 'block', margin: 'auto', fontSize: '18px', backgroundColor: 'rgb(31, 58, 147)' }}
                variant="contained"
                color="primary"
                onClick={() => downloadFileInNewTab()}
              >
                Download
              </Button>
            </CustomCardContainerHead>
          )}
        </>
      )}
    </>
  );
}