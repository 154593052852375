import React from 'react';
import { useSelector, useDispatch } from "react-redux";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import headerStyles from "../../Styles/jss/headerStyles";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import Cookies from "universal-cookie";
import { getLinks } from "../../store/actions/customScanPageListActions";
import { getSingleScanProgress } from "../../store/actions/onBoardingActions";
import Auth0Service from "../../services/auth0Service/index";
import { DomainsLink } from "../../../src/store/actions/adminScansManagementActions"
import { toast } from "react-toastify";


import { setScanData, setScans } from "../../store/actions/miscellaneousActions";
import {
  setDomain,
  openDomainModal,
  getScanProgress,
} from "../../store/actions/dashboardActions";
import History from '../../@history';

const useStyles = makeStyles(headerStyles);
const useStyles2 = makeStyles(headerStyles);

export default function DomainSelector() {
  const cookies = new Cookies();

  const classes = useStyles();
  const classes2 = useStyles2();
  const dispatch = useDispatch();
  const [scanStatus, setScanStatus] = React.useState(null);
  const [environment, setEnvironment] = React.useState(null);

  const selectedScanId2 = useSelector((state) => state.miscellaneous.selectedScanId);
  const domains = useSelector((state) => state.dashboard.domains);
  const selectedDomain = useSelector((state) => state.miscellaneous.selectedDomain);
  const [checkDomains, setCheckDomains] = React.useState(null);

  function change(stories) {

    var ids = [];
    stories.forEach((v) => ids.push(v.dateNow));
    var sortedArr = [...new Set(Array.from(ids))];
    var newOne = [];
    var times = 0;
    var obj = {};
    var dateNow = "";
    var scansArray = [];

    for (var i = 0; i < sortedArr.length; i++) {
      for (var j = 0; j < stories.length; j++) {
        if (stories[j].dateNow === sortedArr[i]) {
          times++;
          dateNow = stories[j].dateNow;
          scansArray.push(stories[j]);
        }
      }
      obj.dateNow = dateNow;
      obj.times = times;
      obj.scans = scansArray;

      newOne.push(obj);
      obj = {};
      times = 0;
      scansArray = [];
    }
    return newOne;
  }

  React.useEffect(() => {
    console.log(domains);
    console.log(selectedDomain);

    const currentUrl = window.location.href;
    const hostname = window.location.hostname;
    const pathname = window.location.pathname;

    // Logic based on URL content
    let checkenvironment = 'unknown';
    if (hostname.includes('dashboard.allyright.com')) {
      checkenvironment = 'production';
      setEnvironment(checkenvironment)
      console.log(checkenvironment)
    } else if (hostname.includes('demo.allyright.com')) {
      checkenvironment = 'demo';
      setEnvironment(checkenvironment)
      console.log(checkenvironment)
    } else if (hostname.includes('localhost')) {
      checkenvironment = 'local';
      setEnvironment(checkenvironment)
      console.log(checkenvironment)
    }
  }, [environment])


  React.useEffect(() => {
    if ((scanStatus && scanStatus === "pending") && selectedScanId2) {
      const interval = setInterval(() => {
        dispatch(getSingleScanProgress(selectedScanId2)).then((data) => {
          if (data) {
            setScanStatus(data.status);
          }
        });
      }, 5000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line 
  }, [scanStatus, selectedScanId2]);

  const handleDomainChange = (event) => {
    let time = new Date();
    time.setTime(time.getTime() + 120 * 60 * 1000);
    if (event.target.value === "add-site") {
      if (domains && domains.length > 0) {
        // cookies.set("selectedDomain", domains[0].domain, {
        //   path: "/",
        //   expires: time,
        // });
        if (domains[0].scans.length > 0) {
          window.history.replaceState(
            null,
            null,
            `?domain=${domains[0].domain}&scanId=${domains[0]?.scans[0]?.scanId}`
          );
          dispatch(getSingleScanProgress(domains[0]?.scans[0]?.scanId)).then((data) => {
            if (data) {
              setScanStatus(data.status)
            }
          });
          dispatch(
            setScanData(
              domains[0].domain,
              domains[0]?.scans[0]?.scanId,
              domains[0]?.scans[0]?.scanId,
              domains[0].scanManagerId
            )

          );
          dispatch(setScans(change(domains[0]?.scans)));
          dispatch(setDomain(domains[0].domain, domains[0].domainId, domains[0].isPackagePurchased, domains[0].selectedPackage));
          dispatch(getLinks(domains[0].domainId));
          dispatch(getScanProgress(domains[0].domainId));
        } else {
          window.history.replaceState(
            null,
            null,
            `?domain=${domains[0].domain}&scanId=${domains[0].scanManagerId}`
          );
          dispatch(
            setScanData(
              domains[0].domain,
              null,
              null,
              domains[0].scanManagerId
            )
          );
          dispatch(setDomain(domains[0].domain, domains[0].domainId, domains[0].isPackagePurchased, domains[0].selectedPackage));
          dispatch(getLinks(domains[0].domainId));
          dispatch(getScanProgress(domains[0].domainId));
        }
      }
      dispatch(openDomainModal());
    } else {
      cookies.set("selectedDomain", event.target.value, {
        path: "/",
        expires: time,
      });
      const item = domains.filter((item) => item.domain === event.target.value);

      // dispatch(getLinks(item[0].domainId));
      // dispatch(getScanProgress(item[0].domainId));
      // dispatch(setDomain(event.target.value, item[0].domainId, item[0].isPackagePurchased, item[0].selectedPackage));
      if (item[0]?.scans.length > 0) {
        window.history.replaceState(
          null,
          null,
          `?domain=${event.target.value}&scanId=${item[0]?.scans[0]?.scanId}`
        );
        History.go(0)
        // dispatch(
        //   setScanData(
        //     item[0].domain,
        //     item[0]?.scans[0]?.scanId,
        //     item[0]?.scans[0]?.scanId,
        //     item[0].scanManagerId
        //   )
        // );
        // dispatch(setScans(change(item[0]?.scans)));

        // dispatch(getSingleScanProgress(item[0]?.scans[0]?.scanId)).then((data) => {
        //   if (data) {
        //     setScanStatus(data.status)
        //   }
        // });
      } else {
        dispatch(setScans([]));

        window.history.replaceState(
          null,
          null,
          `?domain=${event.target.value}&scanId=${item[0].scanManagerId}`
        );
        dispatch(
          setScanData(
            item[0].domain,
            null,
            null,
            item[0].scanManagerId
          )
        );
      }
    }
    cookies.set("selectedDomain", event.target.value, {
      path: "/",
      expires: time,
    });
  };

  function linkDomains() {
    Auth0Service.getUserEmail()
      .then(userData => {
        console.log(userData); // This will log the user email or resolved data
        dispatch(DomainsLink(userData.email))
          .then((data) => {
            if (data) {
              console.log(data.message);
              toast.success("domains link successfully")
              window.location.pathname = `/dashboard`
            } else {
              toast.error("No free domains found for the provided email")
            }
          })
          .catch((err) => {
            console.error('Error occurred:', err);
          });
      })
      .catch(error => {
        console.error('Error occurred:', error);
      });
  }

  React.useEffect(() => {
    if(!checkDomains) {
      if(domains.length > 0) {
        setCheckDomains(domains)
      }  
    }
    if(checkDomains) {
      if(checkDomains.length < 1) {
        const hostname = window.location.hostname;
        let checkenvironment = 'unknown';
        if (hostname.includes('dashboard.allyright.com')) {
          checkenvironment = 'production';
        } else if (hostname.includes('demo.allyright.com')) {
          checkenvironment = 'demo';
        } else if (hostname.includes('localhost')) {
          checkenvironment = 'local';
        }
        console.log("Environment" + checkenvironment)
        console.log("Selected Domain: " + selectedDomain)
        console.log(typeof(domains))
        if (domains.length < 1){
          if (checkenvironment === "production") {
            // const demoDomain = domains.find(domain => domain.environment === "demo");
            // if (demoDomain) {
            //   // Change hostname to demo.allyright.com
            window.location.href = 'http://demo.allyright.com/dashboard';
            // }
          } else if (checkenvironment === "demo") {
            // const prodDomain = domains.find(domain => domain.environment === "production");
            // if (prodDomain) {
            // Change hostname to demo.allyright.com
            window.location.href = 'http://dashboard.allyright.com/dashboard';
            }
          } else if (checkenvironment === "local") {
            // const prodDomain = domains.find(domain => domain.environment === "production");
            // if (prodDomain) {
            // Change hostname to demo.allyright.com
            console.log("hderer")
            console.log("checking select domain" + selectedDomain)
            window.location.href = 'http://dashboard.allyright.com/dashboard';
            // }
          // }
        }
      }
    }

  }, [domains, checkDomains]);
  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      data-tut="Select_Domain"
    >
      <Select
        className={classes.selectDomain}
        value={selectedDomain}
        onChange={(event) => handleDomainChange(event)}
        displayEmpty={false}
        IconComponent={(props) => <ExpandMoreIcon {...props} />}
      >
        {/* {domains.length > 0 && */}
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <MenuItem
              className={classes2.segoeFont}
              value="Select Domain"
              style={{ textTransform: "upercase", fontWeight: 'bold' }}
            >
              Select a Website Here
            </MenuItem>
          </div>
          {/* <MenuItem>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                p={1}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "rgb(15, 36, 113)",
                  }}
                  onClick={() => linkDomains()}
                >
                  Relink Free Scan
                </Button>
              </Box>
            </MenuItem> */}
          <MenuItem>
            <AddSiteButton
              data-tut="relink_Domain" onClick={() => linkDomains()}

            >
              <p className={classes.addSiteButton} tabIndex="0">
                {" "}
                Relink Free Scan{" "}
              </p>
            </AddSiteButton>
          </MenuItem>
        </>
        {/* } */}
        <MenuItem key="add-site" value="add-site" >
          <AddSiteButton
            data-tut="add_Domain"

          >
            <p className={classes.addSiteButton} tabIndex="0">
              {" "}
              Add Site{" "}
            </p>
          </AddSiteButton>
        </MenuItem>
        {domains.length > 0 &&
          domains.filter(domain => domain.environment === environment).map((domain, index) => (
            <MenuItem
              key={index}
              className={classes.segoeFont}
              value={domain.domain}
              componnent="a"
              href={domain.domain}
              style={{ textTransform: "lowercase" }}
            >
              {
                domain.domain
                  .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
                  .split("/")[0]
              }
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}


const AddSiteButton = withStyles((theme) => ({
  root: {
    backgroundColor: "white",
    width: "100%",
    padding: "8px 22px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}))(Button);