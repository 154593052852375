import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import { useLocation } from "react-router-dom";
import MenuList from "@material-ui/core/MenuList";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import MenuIcon from "@material-ui/icons/Menu";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { Alert } from "@material-ui/lab";
import { Link } from "react-router-dom";
import LockIcon from "@material-ui/icons/Lock";

import {
  getNotifications,
  hideNotification,
  deleteAllNotifications,
} from "../../store/actions/notificationActions";
import { setScanData } from "../../store/actions/miscellaneousActions";
import Hidden from "@material-ui/core/Hidden";
import { getContacts } from "../../store/actions/contactsActions";
import { getSingleScanProgress } from "../../store/actions/onBoardingActions";

import OfflineIcon from "./icons/statusIcons/OfflineIcon";
import OnlineIcon from "./icons/statusIcons/OnlineIcon";
import history from "../../@history";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import { DomainModulesAdmin } from "../../store/actions/adminScansManagementActions";
import {
  setDomain,
  getScanProgress,
  getTabsInfo,
} from "../../store/actions/dashboardActions";

import { getLinks } from "../../store/actions/customScanPageListActions";
import Cookies from "universal-cookie";
import logo from "../../assets/logo.svg";
import MessagesIcon from "../../assets/headerIcons/messages.svg";
import NotificationsIcon from "../../assets/headerIcons/notification.svg";
import ScansIcon from "../../assets/headerIcons/scans.svg";

import {
  handleToggleMessages,
  handleToggleNotifications,
  handleToggleChats,
} from "../../store/actions";
import { setScans } from "../../store/actions/miscellaneousActions";
import Auth0Service from "../../services/auth0Service/authentikService";
import headerStyles from "../../Styles/jss/headerStyles";
import ScanProgressBox from "../scanProgress/ScanProgressBox";
import CustomTooltip from "./Tooltip";

const useStyles = makeStyles(headerStyles);

const width = window.innerWidth;

function Header({ ham, generalTabs }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const domainId = useSelector((state) => state.dashboard.selectedDomainId);
  const [domainValue, setDomainValue] = React.useState(false);

  const logoutDomain = useSelector(
    (state) => state.miscellaneous.selectedDomain
  );
  const logoutScanId = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );

  const fetchNotifications = useSelector(
    (state) => state.notifications.notifications.data
  );

  const filterLoading = useSelector(
    (state) => state.notifications.notifications.filter_loading
  );

  const domains = useSelector((state) => state.dashboard.domains);
  const selectedDomain = useSelector((state) => state.dashboard.selectedDomain);

  const selectedDomainId = useSelector(
    (state) => state.dashboard.selectedDomainId
  );

  const selectedScanId2 = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );

  const scanProgress = useSelector((state) => state.dashboard.scanProgress);

  let notificationsCount = useSelector(
    (state) => state.notifications.notifications.count
  );

  const hasMoreData = useSelector(
    (state) => state.notifications.notifications.hasMoreData
  );

  const [mobileMenu, setMobileMenu] = React.useState(false);
  const [mobileMenuIcons, setMobileMenuIcons] = React.useState(false);
  const [page, setPage] = React.useState(1);

  const anchorRefNotifications = React.useRef(null);
  const anchorRefMessages = React.useRef(null);
  const anchorRefChats = React.useRef(null);

  const [modules, setModules] = React.useState({ result: { general: true, accessibility: true, performance: true, webPrivacy: true, cybersec: true } });

  const openMessages = useSelector(
    (state) => state.navigationReducer.openMessages
  );
  const openNotifications = useSelector(
    (state) => state.navigationReducer.openNotifications
  );
  const openChats = useSelector((state) => state.navigationReducer.openChats);
  const contacts = useSelector((state) => state.contacts.data);

  const emailVerified = useSelector(
    (state) => state.auth.user.data.email_verified
  );

  const location = useLocation();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [side]: open });
  };
  const list = (anchor) => (
    <div className={clsx(classes.rightDrawer)} role="presentation">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <p style={{ margin: 0, padding: 10, paddingTop: 20, fontSize: 18 }}>
          Scans in Progress
        </p>
        <IconButton
          color="inherit"
          aria-label="Close drawer"
          onClick={toggleDrawer(anchor, false)}
          edge="start"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {scanProgress.length > 0 ? (
        <>
          <ScanProgressBox />
        </>
      ) : (
        <Box
          mt={3}
          display="flex"
          height="100%"
          justifyContent="center"
          alignItems="flex-start"
        >
          <i style={{ fontSize: 21 }}>There is no scan running</i>
        </Box>
      )}
    </div>
  );

  let [notifications, setNotifications] = React.useState([]);

  const cookies = new Cookies();

  function hideNotifications(id) {
    const filteredNotifications = notifications.filter(
      (item) => item.id !== id
    );
    setNotifications(filteredNotifications);
    dispatch(hideNotification(id));
    notificationsCount = notificationsCount - 1;
  }

  function loadMore() {
    dispatch(getNotifications(page + 1));
    setPage(page + 1);
  }

  function toggleMenu() {
    setMobileMenu(!mobileMenu);
  }

  let { search } = useLocation();

  const query = new URLSearchParams(search);

  const singleScanProgress = useSelector((state) => state.onBoardDashboard.scanProgress);

  const getCurrentURL = () => {
    return window.location.href;
  };

  // const getUserQueryParams = () => {
  //   // Extracting the domain from the URL
  //   const urlParams = new URLSearchParams(window.location.search);
  //   return urlParams.get('domain');
  // };

  const redirectToAuthentication = (domain, user) => {
    window.location.href = `/authenticate?website=${domain}&user=${user}`;
  };


  const handleButtonClick = () => {
    const currentURL = getCurrentURL();
    const domain = getUserQueryParams();
    const user = Auth0Service.isAuthenticated() ? 'old' : 'new';

    if (currentURL.includes('domain=')) {
      redirectToAuthentication(domain, user);
    } else if (!Auth0Service.isAuthenticated()) {
      // If domain parameter is not present, and user is not authenticated, redirect with old user
      redirectToAuthentication(getUserQueryParams(), 'old');
    }
  };

  React.useEffect(() => {
    if (scanProgress.length > 0 && Auth0Service.isAuthenticated()) {
      const interval = setInterval(() => {
        if (domainId) {
          dispatch(getScanProgress(domainId));
        }
      }, 5000);
      return () => clearInterval(interval);
      // eslint-disable-next-line
    }
  }, [domainId, scanProgress, dispatch]);



  React.useEffect(() => {
    if (singleScanProgress.status === 'completed') {
      if (selectedScanId2) {
        dispatch(getTabsInfo(selectedScanId2));
      }
      // eslint-disable-next-line
    }
  }, [singleScanProgress, dispatch]);


  React.useEffect(() => {
    if (width < 1280) {
      setMobileMenu(false);
      setMobileMenuIcons(true);
    } else {
      setMobileMenu(true);
      setMobileMenuIcons(false);
    }
  }, []);

  React.useEffect(() => {
    if (
      Auth0Service.isAuthenticated() &&
      window.location.pathname !== "/account-setup"
    ) {
      dispatch(getNotifications(page));
      // dispatch(getDomains());
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (
      Auth0Service.isAuthenticated() &&
      domains.length > 0 &&
      selectedDomainId
    ) {
      dispatch(getContacts(selectedDomainId));
    }
    // eslint-disable-next-line
  }, [domains]);

  React.useEffect(() => {
    if (
      !filterLoading &&
      Auth0Service.isAuthenticated() &&
      window.location.pathname !== "/account-setup"
    ) {
      const interval = setInterval(() => {
        dispatch(getNotifications(page));
      }, 5000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [filterLoading]);

  React.useEffect(() => {
    let domainName = query.get("domain");
    if(domainName) {
      setDomainValue(domainName);
    } else {
      domainName = localStorage.getItem("domain");
      if(domainName) {
        setDomainValue(domainName);
      }
    }
    console.log(domainName);
    if (domains && domains.length > 0) {
      if (cookies.get("selectedDomain") !== undefined) {
        let domain = cookies.get("selectedDomain");
        if (domains.filter((item) => item.domain === domain).length > 0) {
          const domainId = domains.filter((item) => item.domain === domain);
          dispatch(
            setDomain(
              domain,
              domainId[0].domainId,
              domainId[0].isPackagePurchased,
              domainId[0].selectedPackage
            )
          );
          if (domainName) {
            let domainID = domains.filter((item) => item.domain === domainName);
            if (domainID.length > 0) {
              dispatch(getLinks(domainID[0].domainId));
            } else {
              dispatch(getLinks(domainId[0].domainId));
            }
          } else {
            dispatch(getLinks(domainId[0].domainId));
          }
          dispatch(getScanProgress(domainId[0].domainId));
        } else {
          let time = new Date();
          time.setTime(time.getTime() + 120 * 60 * 1000);
          cookies.set("selectedDomain", domains[0].domain, {
            path: "/",
            expires: time,
          });
          dispatch(
            setDomain(
              domains[0].domain,
              domains[0].domainId,
              domains[0].isPackagePurchased,
              domains[0].selectedPackage
            )
          );

          if (domainName) {
            let domainID = domains.filter((item) => item.domain === domainName);
            dispatch(getLinks(domainID[0]?.domainId));
          } else {
            dispatch(getLinks(domains[0]?.domainId));
          }
          dispatch(getScanProgress(domains[0]?.domainId));
        }
      } else {
        let time = new Date();
        time.setTime(time.getTime() + 120 * 60 * 1000);
        cookies.set("selectedDomain", domains[0].domain, {
          path: "/",
          expires: time,
        });
        dispatch(
          setDomain(
            domains[0].domain,
            domains[0].domainId,
            domains[0].isPackagePurchased,
            domains[0].selectedPackage
          )
        );
        if (domainName) {
          let domainID = domains.filter((item) => item.domain === domainName);
          dispatch(getLinks(domainID[0].domainId));
        } else {
          dispatch(getLinks(domains[0].domainId));
        }
        dispatch(getScanProgress(domains[0].domainId));
      }
    }
    // eslint-disable-next-line
  }, [domains]);

  React.useEffect(() => {
    if (fetchNotifications && Auth0Service.isAuthenticated()) {
      if (fetchNotifications.length > 0) {
        let notificationo = [...notifications, ...fetchNotifications];
        notificationo = Array.from(new Set(notificationo.map((a) => a.id))).map(
          (id) => {
            return notificationo.find((a) => a.id === id);
          }
        );
        setNotifications(notificationo);
      }
    }
    // eslint-disable-next-line
  }, [fetchNotifications]);

  React.useEffect(() => {
    const domain = query.get("domain");
    const scanId = query.get("scanId");

    if (domains && domains.length > 0 && Auth0Service.isAuthenticated()) {
      const checkDomain = domains.filter((item) => item.domain === domain);
      if (checkDomain.length > 0) {
        const checkScanId = checkDomain[0].scans.filter(
          (item) => item.scanId === scanId
        );
        if (checkScanId.length > 0) {
          dispatch(setScans(change(checkDomain[0].scans)));
          dispatch(
            setScanData(
              checkDomain[0].domain,
              scanId,
              scanId,
              checkDomain[0].scanManagerId
            )
          );
          dispatch(
            getSingleScanProgress(checkDomain[0]?.scans[0]?.scanId)
          ).then((data) => {
            if (data) {
              setScanStatus(data.status);
            }
          });
        } else {
          dispatch(
            setDomain(
              checkDomain[0].domain,
              checkDomain[0].domainId,
              checkDomain[0].isPackagePurchased,
              checkDomain[0].selectedPackage
            )
          );
          const item = domains.filter((item) => item.domain === domain);
          if (item[0].scans.length > 0) {
            dispatch(setScans(change(item[0].scans)));

            dispatch(
              setScanData(
                checkDomain[0].domain,
                item[0]?.scans[0]?.scanId,
                item[0]?.scans[0]?.scanId,
                item[0].scanManagerId
              )
            );
            window.history.replaceState(
              null,
              null,
              `?domain=${domain}&scanId=${item[0].scans[0].scanId}`
            );
            dispatch(getSingleScanProgress(item[0].scans[0].scanId)).then(
              (data) => {
                if (data) {
                  setScanStatus(data.status);
                }
              }
            );
          } else {
            dispatch(
              setScanData(
                checkDomain[0].domain,
                null,
                null,
                item[0].scanManagerId
              )
            );
            window.history.replaceState(
              null,
              null,
              `?domain=${domain}&scanId=${item[0].scanManagerId}`
            );
          }
        }
      } else if (selectedDomain) {
        if (selectedDomain === "add-site") {
          dispatch(setScans(change(domains[0].scans)));

          dispatch(
            setScanData(
              domains[0].domain,
              domains[0]?.scans[0]?.scanId,
              domains[0]?.scans[0]?.scanId,
              domains[0].scanManagerId
            )
          );
          window.history.replaceState(
            null,
            null,
            `?domain=${domains[0].domain}&scanId=${domains[0].scans[0].scanId}`
          );
        } else {
          const item = domains.filter((item) => item.domain === selectedDomain);
          if (item[0].scans.length > 0) {
            dispatch(setScans(change(item[0].scans)));
            dispatch(
              setScanData(
                item[0].domain,
                item[0]?.scans[0]?.scanId,
                item[0]?.scans[0]?.scanId,
                item[0].scanManagerId
              )
            );
            window.history.replaceState(
              null,
              null,
              `?domain=${selectedDomain}&scanId=${item[0].scans[0].scanId}`
            );
          } else {
            dispatch(
              setScanData(item[0].domain, null, null, item[0].scanManagerId)
            );
          }
        }
      } else {
        const domain = domains[0];
        const domainScans = domains[0].scans;
        if (domains[0].scans.length > 0) {
          dispatch(
            setDomain(
              domain.domain,
              domain.domainId,
              domain.isPackagePurchased,
              domain.selectedPackage
            )
          );
          dispatch(setScans(change(domainScans)));
          dispatch(
            setScanData(
              domain.domain,
              domainScans[0].scanId,
              domainScans[0].scanId,
              domain.scanManagerId
            )
          );
          window.history.replaceState(
            null,
            null,
            `?domain=${domain.domain}&scanId=${domainScans[0].scanId}`
          );
        } else {
          dispatch(
            setScanData(domain.domain, null, null, domain.scanManagerId)
          );
          window.history.replaceState(
            null,
            null,
            `?domain=${domain.domain}&scanId=${domain.scanManagerId}`
          );
        }
      }
    } else {
      if (logoutDomain && logoutScanId) {
        window.history.replaceState(
          null,
          null,
          `?domain=${logoutDomain}&scanId=${logoutScanId}`
        );
      }
    }
    // eslint-disable-next-line
  }, [domains]);

  React.useEffect(() => {
    let scanId = "";
    let domainName = "";

    let domainNameLocal = localStorage.getItem("domain");
    let domainNameParam = query.get("domain");

    let scanIdLocal = localStorage.getItem("scanId");
    let scanIdParam = query.get("scanId");

    if (domainNameParam && scanIdParam) {
      scanId = scanIdParam;
      domainName = domainNameParam;
    } else if (domainNameLocal && scanIdLocal) {
      scanId = scanIdLocal;
      domainName = domainNameLocal;
    }

    if (scanId && !Auth0Service.isAuthenticated()) {
      dispatch(setScanData(domainName, scanId, "null"));
    }
    // eslint-disable-next-line
  }, []);

  function clearAllNotifications() {
    setNotifications([]);
    dispatch(deleteAllNotifications());
  }

  const [scanStatus, setScanStatus] = React.useState(null);

  React.useEffect(() => {
    if (scanStatus && scanStatus === "pending" && selectedScanId2) {
      const interval = setInterval(() => {
        dispatch(getSingleScanProgress(selectedScanId2)).then((data) => {
          if (data) {
            setScanStatus(data.status);
          }
        });
      }, 5000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [scanStatus, selectedScanId2]);

  function change(stories) {
    var ids = [];
    stories.forEach((v) => ids.push(v.dateNow));
    var sortedArr = [...new Set(Array.from(ids))];
    var newOne = [];
    var times = 0;
    var obj = {};
    var dateNow = "";
    var scansArray = [];

    for (var i = 0; i < sortedArr.length; i++) {
      for (var j = 0; j < stories.length; j++) {
        if (stories[j].dateNow === sortedArr[i]) {
          times++;
          dateNow = stories[j].dateNow;
          scansArray.push(stories[j]);
        }
      }
      obj.dateNow = dateNow;
      obj.times = times;
      obj.scans = scansArray;

      newOne.push(obj);
      obj = {};
      times = 0;
      scansArray = [];
    }
    return newOne;
  }

  function getUserQueryParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get("domain");
    console.log(paramValue);
    return paramValue;
  };

  const config = localStorage.getItem("authConfig");

  React.useEffect(() => {
    if (domainId) {
      console.log("DOMAIN ID: " + domainId)
      getModules(domainId);
    }
  }, [domainId]);

  function getModules(selectedDomainId) {
    dispatch(DomainModulesAdmin({
      selectedDomainId,
    })).then(data => {
      if (data) {
        console.log("Data from getModules:", data.result); // Debugging
        setModules(data);
        console.log(modules);
      }
    });
  }

  return (
    <AppBar color={"inherit"} className={clsx(classes.appBar)} elevation={0}>
      <Toolbar
        disableGutters={false}
        className={classes.toolbar}
        style={{
          justifyContent: "space-between",
          minHeight: "60px",
          flexWrap: "wrap",
        }}
      >
        {!generalTabs.onBoarding && (
          <>
            <Box
              display="flex"
              alignItems="center"
              className="responsive-header"
              tabIndex="0"
              style={{ minHeight: "60px" }}
            >
              {ham}
              <Box style={{ width: 190, marginTop: 6 }}>
                <img
                  width="120px"
                  height="30px"
                  src={logo}
                  alt="Ally-Right"
                  tabIndex="0"
                  style={{ marginLeft: "-6px" }}
                />
              </Box>
              {selectedScanId2 && (
                <ul className={classes.generalTabs}>
                  {/* <li className={generalTabs.general ? classes.activeTab : ""}>
                    <Box
                      component={Link}
                      to={`/dashboard?domain=${selectedDomain}&scanId=${selectedScanId2}`}
                    >
                      {" "}
                      General{" "}
                    </Box> 
                  </li> */}
                  <li className={generalTabs.general ? classes.activeTab : ""}>
                    {modules.result.general ? (
                      <CustomTooltip title="">
                        <Box
                          component={Link}
                          to={`/dashboard?domain=${domainValue}&scanId=${selectedScanId2}`}
                          sx={{
                            color: 'black',
                            textDecoration: 'none',
                            '&:hover': {
                              textDecoration: 'none', // Ensure no underline on hover
                            },
                          }}
                        >
                          General      
                        </Box>
                      </CustomTooltip>
                    ) : (
                      <CustomTooltip title="Upgrade to access General Tab.">
                        <Box
                          // display="flex"
                          // alignItems="center"
                          style={{ cursor: "default" }}
                          // component={Link}
                          disabled={true}
                          to={`/dashboard?domain=${domainValue}&scanId=${selectedScanId2}`}
                        >
                          General
                          <LockIcon style={{ color: "gray", marginLeft: 10 , marginTop: 10}} />
                        </Box>
                      </CustomTooltip>
                    )}
                  </li>
                  <li
                    className={
                      generalTabs.accessibilityDashboard
                        ? classes.activeTab
                        : ""
                    }
                  >
                    {" "}
                    {modules.result.accessibility ? (
                      <Box
                        component={Link}
                        to={`/dashboard/accessibility?domain=${domainValue}&scanId=${selectedScanId2}`}
                      >
                        {" "}
                        Accessibility{" "}
                      </Box>
                    ) : (
                      <CustomTooltip title="Upgrade to access Accessibility Tab.">
                        <Box
                          // display="flex"
                          // alignItems="center"
                          style={{ cursor: "default" }}
                          // component={Link}
                          disabled={true}
                          to={`/dashboard/accessibility?domain=${domainValue}&scanId=${selectedScanId2}`}
                        >
                          Accessibility
                          <LockIcon style={{ color: "gray", marginLeft: 10,  marginTop: 10 }} />
                        </Box>
                      </CustomTooltip>
                    )}
                  </li>
                  <li
                    className={
                      generalTabs.performanceDashboard ? classes.activeTab : ""
                    }
                  >
                    {modules.result.performance ? (
                      <Box
                        component={Link}
                        to={`/dashboard/performance?domain=${domainValue}&scanId=${selectedScanId2}`}
                      >
                        {" "}
                        Performance{" "}
                      </Box>
                    ) : (
                      <CustomTooltip title="Upgrade to access Performance Tab.">
                        <Box
                          // display="flex"
                          // alignItems="center"
                          style={{ cursor: "default" }}
                          // component={Link}
                          disabled={true}
                          to={`/dashboard/performance?domain=${domainValue}&scanId=${selectedScanId2}`}
                        >
                          Performance
                          <LockIcon style={{ color: "gray", marginLeft: 10, marginTop: 10 }} />
                        </Box>
                      </CustomTooltip>
                    )}
                  </li>
                  {/* <li className={generalTabs.seo ? classes.activeTab : ""}>
                    component={Link}  removed from SEO
                    <CustomTooltip title="Upgrade to access Spell Check and Content Moderator. ">
                      <Box
                        to={`/dashboard/seo?domain=${selectedDomain}&scanId=${selectedScanId2}`}
                        style={{ cursor: "default" }}
                        disabled={true}
                        display="flex"
                        alignItems="center"
                      >
                        SEO
                        <LockIcon style={{ color: "gray", marginLeft: 10 }} />
                      </Box>
                    </CustomTooltip>
                  </li> */}
                  <li
                    className={generalTabs.webPrivacy ? classes.activeTab : ""}
                  >
                    {modules.result.webPrivacy  ? (
                      <Box
                        component={Link}
                        to={`/dashboard/web-privacy?domain=${domainValue}&scanId=${selectedScanId2}`}
                      >
                        {" "}
                        Web Privacy{" "}
                      </Box>
                    ) : (
                      <CustomTooltip title="Upgrade to access Web Privacy Tab.">
                        <Box
                          // display="flex"
                          // alignItems="center"
                          style={{ cursor: "default" }}
                          // component={Link}
                          disabled={true}
                          to={`/dashboard/web-privacy?domain=${domainValue}&scanId=${selectedScanId2}`}
                        >
                          Web Privacy
                          <LockIcon style={{ color: "gray", marginLeft: 10, marginTop: 10 }} />
                        </Box>
                      </CustomTooltip>
                    )}
                  </li>
                  <li
                    className={
                      generalTabs.securityDashboard ? classes.activeTab : ""
                    }
                  >
                    {modules.result.cybersec   ? (
                      config?.cyberSecurityModule ? <CustomTooltip title="Upgrade to access Cybersec ">
                        <Box
                          display="flex"
                          alignItems="center"
                          style={{ cursor: "default" }}
                          disabled={true}
                          to={`/dashboard/security?domain=${domainValue}&scanId=${selectedScanId2}`}
                        >
                          {" "}
                          CyberSec{" "}
                          {<LockIcon style={{ color: "gray", marginLeft: 10, marginRight: 10 }} />}
                        </Box>
                      </CustomTooltip> :
                        <Box
                          component={Link}
                          to={`/dashboard/security?domain=${domainValue}&scanId=${selectedScanId2}`}
                        >
                          CyberSec
                        </Box>
                    ) : (
                    <CustomTooltip title="Upgrade to access CyberSec Tab.">
                      <Box
                        // display="flex"
                        // alignItems="center"
                        style={{ cursor: "default" }}
                        // component={Link}
                        disabled={true}
                        to={`/dashboard/security?domain=${selectedDomain}&scanId=${selectedScanId2}`}
                      >
                       CyberSec
                        <LockIcon style={{ color: "gray", marginLeft: 10, marginTop: 10 }} />
                      </Box>
                    </CustomTooltip>
                                        )}
                  </li>
                  <li
                    className={
                      generalTabs.languageServices ? classes.activeTab : ""
                    }
                  >
                    {" "}
                    {/* <CustomTooltip title="Upgrade to access Spell Check and Content Moderator. ">
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ cursor: "default" }}
                        disabled={true}
                        to={`/dashboard/language-services?domain=${selectedDomain}&scanId=${selectedScanId2}`}
                      >
                        {" "}
                        Language & Grammar{" "}
                        <LockIcon style={{ color: "gray", marginLeft: 10 }} />
                      </Box>
                    </CustomTooltip> */}
                  </li>
                </ul>
              )}
              {mobileMenuIcons && Auth0Service.isAuthenticated() && (
                <div
                  style={{
                    marginTop: "5px",
                    marginLeft: "auto",
                    padding: "0 10px",
                  }}
                >
                  {mobileMenu ? (
                    <CloseIcon onClick={() => toggleMenu()} />
                  ) : (
                    <MenuIcon onClick={() => toggleMenu()} />
                  )}
                </div>
              )}
            </Box>
            {mobileMenu && Auth0Service.isAuthenticated() ? (
              <div className={classes.sectionDesktop}>
                <Box
                  ref={anchorRefChats}
                  onClick={() => dispatch(handleToggleChats())}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      dispatch(handleToggleChats());
                    }
                  }}
                  tabIndex="0"
                  style={{ marginRight: 15 }}
                >
                  <StyledBadge2
                    badgeContent={contacts.length}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={MessagesIcon}
                      alt="Messages Icon"
                      height="36px"
                      width="30px"
                    />
                  </StyledBadge2>
                </Box>
                <Box
                  className={classes.borderRight}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      dispatch(handleToggleNotifications());
                    }
                  }}
                  onClick={() => dispatch(handleToggleNotifications())}
                  tabIndex="0"
                >
                  <StyledBadge
                    badgeContent={
                      notificationsCount > 0 ? notificationsCount : 0
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={NotificationsIcon}
                      alt="Notifications Icon"
                      className={classes.img}
                      ref={anchorRefNotifications}
                      height="36px"
                      width="30px"
                    />
                  </StyledBadge>
                </Box>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer("right", true)}
                  edge="start"
                  style={{ marginLeft: 0 }}
                  tabIndex="0"
                >
                  <img
                    src={ScansIcon}
                    alt="Scans Icon"
                    height="36px"
                    width="30px"
                  />
                </IconButton>
              </div>
            ) : (
              <Box display="flex" alignItems="center" justifyContent="center">
                {/* <Button
                variant="outlined"
                color="primary"
                component="a"
                href="https://allyright.com/contact-us/"
                style={{ margin: "0px 10px", borderRadius: 20 }}
              >
                Consulting
              </Button> */}
                <Hidden smDown implementation="css">
                  {" "}
                  <Button
                    variant="outlined"
                    component="a"
                    href="https://allyright.com/pricing/"
                    color="primary"
                    style={{ margin: "0px 10px", borderRadius: 20 }}
                  >
                    Plans/Pricing
                  </Button>
                </Hidden>
                {!Auth0Service.isAuthenticated() && (
                  <Button
                    onClick={() => (handleButtonClick())}
                    variant="contained"
                    color="primary"
                    style={{ margin: "0px 10px", borderRadius: 20 }}
                  >
                    Sign In
                  </Button>
                )}
              </Box>
              // </Hidden>
            )}
            <Popper
              open={openNotifications}
              anchorEl={anchorRefNotifications.current}
              transition
              disablePortal
              placement="bottom"
            >
              {({ TransitionProps, placement }) => (
                <Paper>
                  <ClickAwayListener
                    onClickAway={() => dispatch(handleToggleNotifications())}
                  >
                    <MenuList
                      autoFocusItem={openNotifications}
                      id="menu-list-grow"
                      style={{ padding: 0 }}
                    >
                      <List
                        component="nav"
                        className={classes.dropDownList}
                        aria-label="mailbox folders"
                      >
                        {notifications.length > 0 ? (
                          <>
                            <ListItem
                              style={{ borderBottom: "2px solid #1f3a93" }}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                width="100%"
                                justifyContent="space-between"
                              >
                                {" "}
                                <span>Notifications</span>
                                <Button
                                  disabled={notifications.length < 1}
                                  onClick={() => clearAllNotifications()}
                                  size="small"
                                  style={{ fontSize: 14 }}
                                >
                                  Clear All
                                </Button>
                              </Box>
                            </ListItem>
                            {notifications.map((item, index) => {
                              return (
                                <ListItem
                                  divider
                                  className={classes.listItem}
                                  key={index}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    maxWidth: "250px",
                                    whiteSpace: "pre-wrap",
                                    wordBreak: "break-all",
                                  }}
                                  tabIndex="0"
                                >
                                  <ChatOutlinedIcon
                                    style={{
                                      fontSize: "24px",
                                      color: "#1F3A93",
                                    }}
                                    tabIndex="0"
                                  />
                                  <Box
                                    ml={2}
                                    mr={0.5}
                                    width="100%"
                                    tabIndex="0"
                                  >
                                    <div
                                      style={{
                                        fontSize: "15px",
                                        color: "#000",
                                      }}
                                      tabIndex="0"
                                    >
                                      {item.message}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "15px",
                                        color: "#000",
                                      }}
                                      tabIndex="0"
                                    >
                                      Scan Type: {item.scanType}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "15px",
                                        color: "#000",
                                      }}
                                      tabIndex="0"
                                    >
                                      Domain:{" "}
                                      {
                                        item.domain
                                          .replace(
                                            /^(?:https?:\/\/)?(?:www\.)?/i,
                                            ""
                                          )
                                          .split("/")[0]
                                      }
                                    </div>
                                  </Box>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <HighlightOffIcon
                                      onClick={() => hideNotifications(item.id)}
                                      style={{ fontSize: "larger" }}
                                    />
                                  </div>
                                </ListItem>
                              );
                            })}
                            {hasMoreData && (
                              <ListItem
                                divider
                                onClick={() => loadMore()}
                                className={classes.listItem}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  maxWidth: "250px",
                                  color: "#1F3A93",
                                  cursor: "pointer",
                                }}
                              >
                                Load more
                              </ListItem>
                            )}
                          </>
                        ) : (
                          <ListItem
                            divider
                            className={classes.listItem}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              maxWidth: "250px",
                              whiteSpace: "pre-wrap",
                              wordBreak: "break-all",
                            }}
                          >
                            <ChatOutlinedIcon
                              style={{ fontSize: "20px", color: "#1F3A93" }}
                            />
                            <Box ml={2} mr={0.5} width="100%">
                              <div
                                style={{ fontSize: "13px", color: "	#787878" }}
                              >
                                No Notifications
                              </div>
                            </Box>
                          </ListItem>
                        )}
                      </List>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              )}
            </Popper>
            <Popper
              open={openMessages}
              anchorEl={anchorRefMessages.current}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Paper>
                  <ClickAwayListener
                    onClickAway={() => dispatch(handleToggleMessages())}
                    touchEvent="onTouchStart"
                  >
                    <MenuList autoFocusItem={openMessages} id="menu-list-grow">
                      <List
                        component="nav"
                        className={classes.dropDownList}
                        aria-label="mailbox folders"
                      >
                        <ListItem button>
                          <Box>
                            <NotificationsBadge badgeContent={4}>
                              <ListItemText primary="Messages" />
                            </NotificationsBadge>
                          </Box>
                        </ListItem>
                        <Divider />
                      </List>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              )}
            </Popper>
            <Popper
              open={openChats}
              anchorEl={anchorRefChats.current}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Paper>
                  <ClickAwayListener
                    onClickAway={() => dispatch(handleToggleChats())}
                  >
                    <MenuList
                      autoFocusItem={openChats}
                      id="menu-list-grow"
                      style={{ padding: 0 }}
                    >
                      <List
                        component="nav"
                        className={classes.dropDownList}
                        aria-label="mailbox folders"
                      >
                        {contacts.length > 0 ? (
                          contacts.map((item, index) => {
                            return (
                              <ListItem
                                key={index}
                                style={{ cursor: "pointer" }}
                                button
                                divider
                                className={classes.listItem}
                                onClick={() => history.push("/chat")}
                              >
                                <Avatar
                                  className={classes.avatar}
                                  alt="user Avatar"
                                  src={item.image}
                                />
                                <Box
                                  display="flex"
                                  alignItems="start"
                                  flexDirection="column"
                                  ml={2}
                                  mr={0.5}
                                  width="100%"
                                >
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    width="100%"
                                  >
                                    <div className={classes.listItemTitle}>
                                      {item.name}
                                    </div>
                                    {item.status === "online" ? (
                                      <OnlineIcon />
                                    ) : (
                                      <OfflineIcon />
                                    )}
                                  </Box>
                                </Box>
                              </ListItem>
                            );
                          })
                        ) : (
                          <ListItem>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              width="100%"
                            >
                              <div
                                className={classes.primaryColor}
                                style={{ fontSize: "13px" }}
                              >
                                No Chats
                              </div>
                            </Box>
                          </ListItem>
                        )}
                      </List>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              )}
            </Popper>
          </>
        )}
      </Toolbar>

      <div>
        {["right"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Drawer
              anchor={anchor}
              style={{ zIndex: 9999999999999 }}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
      {Auth0Service.isAuthenticated() &&
        !emailVerified &&
        (location.pathname === "/" ||
          location.pathname === "/dashboard" ||
          location.pathname === "/billing") && (
          <Box className="emailVerifyBar">
            <Alert severity="error">Your Email is Not Verified</Alert>
          </Box>
        )}
    </AppBar>
  );
}
export default React.memo(Header);

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: 5,
    right: 10,
    backgroundColor: "#FF4157 !important",
    color: "white",
    marginRight: 5,
    padding: "0px 4px",
  },
}))(Badge);

const StyledBadge2 = withStyles((theme) => ({
  badge: {
    top: 30,
    right: 0,
    backgroundColor: "white !important",
    border: "1px solid #1F3A93 !important",
    marginRight: 5,
  },
}))(Badge);

const NotificationsBadge = withStyles((theme) => ({
  badge: {
    top: 15,
    right: -15,
    backgroundColor: "white !important",
    color: "red !important",
    border: "1px solid #1F3A93 !important",
  },
}))(Badge);
