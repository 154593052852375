import React from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";
import auth0Service from "../services/auth0Service/authentikService";
import { BASE_URL, BASE_URL_OLD_DASHBOARD } from "../source/constants";
import { useLocation } from "react-router-dom";
import NoDomain from "../components/commons/NoDomain";

export default function IssueTracker() {

  const { selectedDomainId } = useSelector((state) => state.dashboard);
  const domains = useSelector((state) => state.dashboard.domains);

  const [domainId, setDomainId] = React.useState("");
  const [boardId, setBoardId] = React.useState("");
  const [boardUri, setBoardUri] = React.useState("");

  const isDomainPurchased = useSelector((state) => state.dashboard.isDomainPackagePurchased);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();

  function checkDomain() {
    const domain = query.get("domainId");
    let domainID = "";
    if (domain) {
      const domaino = domains.filter((domain) => domain.domain === domain);
      if (domaino.length > 0) {
        domainID = domaino[0].domainId;
        window.history.replaceState(null, null, `?domainId=${domainID}`);
      } else {
        domainID = selectedDomainId;
        window.history.replaceState(null, null, `?domainId=${domainID}`);
      }
    } else {
      domainID = selectedDomainId;

      window.history.replaceState(null, null, `?domainId=${domainID}`);
    }

    return domainID;
  }

  React.useEffect(() => {
    if (domains && domains.length > 0 && selectedDomainId) {
      let domId = checkDomain();
      const getDomainBoardInfo = domains.filter(domain => domain.domainId === domId)[0];
      setBoardId(getDomainBoardInfo.boardId)
      setBoardUri(getDomainBoardInfo.boardUri)
      setDomainId(domId);
    }
    // eslint-disable-next-line
  }, [domains, selectedDomainId]);

  function setIframe() {
    return {
      __html: iframe,
    };
  }

  const token = auth0Service.getAccessToken();

  const iframe = `<iframe src="${BASE_URL_OLD_DASHBOARD}/callback#access_token=${token}&route=/apps/issue-tracker/board/${boardId}/${boardUri}/${domainId}&burl=${encodeURI(BASE_URL)}&domainId=${domainId}&isPackagePurchased=true" width="100%" height="100%"></iframe>`;

  if (!isDomainPurchased) {
    return (window.location.pathname = "/dashboard");
  } else {
    return (
      <>
        <Box style={{ height: 'calc(100vh - 60px' }} width='100%'>
          {auth0Service.isAuthenticated() && domains.length > 0 ? (
            <Box height='100%' width='100%'>
              <div
                dangerouslySetInnerHTML={setIframe()}
                style={{ height: "100%", width: "100%" }}
              ></div>
            </Box>
          ) : (
            <NoDomain text='Please add scan to see the results.' />
          )}
        </Box>
      </>
    );
  }
}
