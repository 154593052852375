import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "../auth/store/actions";
import MuiCard from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import MuiTypography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import BusinessIcon from "@material-ui/icons/Business";
import LockIcon from "@material-ui/icons/Lock";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { useDropzone } from "react-dropzone";
import Compressor from "compressorjs";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import loaderwave from "../assets/loader.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import myProfileStyles from "../Styles/jss/myProfileStyles";

const useStyles = makeStyles(myProfileStyles);

export default function MyProfile() {
  const user = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.auth.user.loading);
  const getUserRole = useSelector((state) => state.auth.user.role[0]);
  const email_verified = useSelector(
    (state) => state.auth.user.data.email_verified
  );
  const isDomainPurchased = useSelector(
    (state) => state.dashboard.isDomainPackagePurchased
  );
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [files, setFiles] = React.useState([]);
  const [image, setImage] = React.useState(user.data.image);
  const [imageFileName, setImageFileName] = React.useState(null);
  const [imageString, setImageString] = React.useState("");
  const [isImageChanged, setIsImageChanged] = React.useState(false);

  const [openSuccess, setOpenSuccess] = React.useState(false);

  const [snackBarMisMatch, setSnackBarMisMatch] = React.useState({
    openSnackMisMatch: false,
    vertical: "bottom",
    horizontal: "left",
  });
  const [snackBarEmpty, setSnackBarEmpty] = React.useState({
    openSnackEmpty: false,
    vertical: "bottom",
    horizontal: "left",
  });
  const [userName, setUserName] = React.useState(user.data.name);
  const [userOrgName, setUserOrgName] = React.useState(null);
  // eslint-disable-next-line
  const [userRole, setUserRole] = React.useState(user.role[0]);
  // eslint-disable-next-line

  const [resetPassword, setResetPassword] = React.useState({
    password: "",
    cPassword: "",
  });

  const classes = useStyles();

  const onHandleResetPassword = (event) => {
    const name = event.target.getAttribute("name");
    setResetPassword({ ...resetPassword, [name]: event.target.value });
  };

  const handleNameChange = (event) => {
    setUserName(event.target.value);
  };

  const handleOrgChange = (event) => {
    setUserOrgName(event.target.value);
  };

  // function handleRoleChange(event) {
  //   setUserRole(event.target.value);
  // }

  const handleCloseSnackBar = () => {
    setSnackBarMisMatch({ ...snackBarMisMatch, openSnackMisMatch: false });
  };

  const handleCloseSnackBarEmpty = () => {
    setSnackBarEmpty({ ...snackBarEmpty, openSnackEmpty: false });
  };

  const { vertical, horizontal, openSnackMisMatch } = snackBarMisMatch;

  function submitData(event) {
    dispatch(authActions.updateUserPassword(user.data.email));
  }

  function setProfile() {
    if (userOrgName === "" && userName === "") {
      toast.warn("Organization and Name is Empty");
    } else if (userOrgName === "") {
      toast.warn("Organization is Empty");
    } else if (userName === "") {
      toast.warn("Name is Empty");
    } else {
      const body = {
        name: userName,
        role: userRole,
        orgName: userOrgName,
        image: {
          fileName: imageFileName,
          string: imageString,
        },
        isImageChanged: isImageChanged,
      };
      dispatch(authActions.updateUserData(body));
    }
  }

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );

    acceptedFiles.forEach((file) => {
      new Compressor(file, {
        maxWidth: 200,
        maxHeight: 200,
        success(result) {
          reader.readAsDataURL(result);
          reader.onload = () => {
            const binaryStr = reader.result;
            setImageString(binaryStr);
            setIsImageChanged(true);
          };
        },
      });

      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      setImageFileName(file.name);
      setImage(file.preview);
    });
  }, []);

  const thumbs = (
    <div>
      <img width="200px" height="200px" alt="user" src={image} />
    </div>
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png, image/jpg",
    minSize: 0,
  });

  const handleCloseSuccess = (event, reason) => {
    setOpenSuccess(false);
  };

  React.useEffect(() => {
    if (user) {
      user.orgName.match(
        "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
      )
        ? setUserOrgName("")
        : setUserOrgName(user.orgName);
    }
    // eslint-disable-next-line
  }, [user]);

  // if (!isDomainPurchased) {
  //   return (window.location.pathname = "/dashboard");
  // } else {
    return (
      <>
        <div className={classes.root}>
          {user && userOrgName !== null ? (
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                lg={10}
                style={{
                  boxShadow: "0px 3px 13px rgb(31, 58, 147)",
                  margin: "0 auto",
                }}
              >
                <MuiCard>
                  <CardContent>
                    <Box p={1}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                        width="100%"
                      >
                        <MuiTypography className={classes.heading} gutterBottom>
                          Edit Your Profile
                        </MuiTypography>
                      </Box>
                      <div className={classes.inputsBox}>
                        <Grid
                          container
                          spacing={3}
                          display="flex"
                          justifycontent="center"
                        >
                          <Grid item xs={12} lg={6}>
                            <div className={classes.margin}>
                              <Grid container spacing={1} alignItems="flex-end">
                                <Grid
                                  item
                                  xs={2}
                                  lg={1}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <AccountCircle />
                                </Grid>
                                <Grid item xs={10} lg={10}>
                                  <TextField
                                    margin="dense"
                                    id="name"
                                    label="Name"
                                    defaultValue={user.data.name}
                                    type="text"
                                    fullWidth
                                    onChange={handleNameChange}
                                  />
                                </Grid>
                                <Grid item xs={1}></Grid>
                              </Grid>
                            </div>
                            <div className={classes.margin}>
                              <Grid container spacing={1} alignItems="flex-end">
                                <Grid
                                  item
                                  xs={2}
                                  lg={1}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <EmailOutlinedIcon />
                                </Grid>
                                <Grid item xs={10} lg={10}>
                                  <TextField
                                    margin="dense"
                                    id="email"
                                    defaultValue={user.data.email}
                                    label="Email"
                                    type="text"
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  {email_verified ? (
                                    <Tooltip
                                      title={
                                        <span style={{ fontSize: 14 }}>
                                          Email Verified!
                                        </span>
                                      }
                                      placement="top"
                                    >
                                      <IconButton
                                        style={{ padding: 0 }}
                                        aria-label="Email Verified!"
                                      >
                                        <CheckCircleOutlineIcon
                                          style={{ color: "#28a745" }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      title={
                                        <span style={{ fontSize: 14 }}>
                                          Your email is not verified!
                                        </span>
                                      }
                                      placement="top"
                                    >
                                      <IconButton
                                        style={{ padding: 0 }}
                                        aria-label="email is not verified"
                                      >
                                        <ErrorOutlineIcon
                                          style={{ fontSize: "1.3rem" }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.margin}>
                              <Grid container spacing={1} alignItems="flex-end">
                                <Grid
                                  item
                                  xs={2}
                                  lg={1}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <BusinessIcon />
                                </Grid>
                                <Grid item xs={10} lg={10}>
                                  <TextField
                                    defaultValue={userOrgName}
                                    margin="dense"
                                    onChange={handleOrgChange}
                                    id="organization"
                                    label="Organization"
                                    type="text"
                                    fullWidth
                                    disabled={getUserRole !== "admin"}
                                  />
                                </Grid>
                                <Grid item xs={1}></Grid>
                              </Grid>
                            </div>
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <div className={classes.margin}>
                              <div
                                {...getRootProps()}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <input {...getInputProps()} />
                                {!user ? (
                                  <p>
                                    Drag 'n' drop some files here, or click to
                                    select files
                                  </p>
                                ) : (
                                  <div>{thumbs}</div>
                                )}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <Box
                        display="flex"
                        justifyContent="center"
                        my={3}
                        alignItems="center"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ borderRadius: "20px" }}
                          disabled={loading}
                          onClick={() => setProfile()}
                        >
                          Save
                          {loading && (
                            <CircularProgress
                              style={{ color: "black", marginLeft: "1rem" }}
                              size={20}
                            />
                          )}
                        </Button>
                      </Box>
                    </Box>
                    {!user.data.socialLogin && (
                      <Box p={1}>
                        <div className={classes.inputsBox}>
                          <Box
                            display="flex"
                            justifyContent="flex-start"
                            mt={3}
                            alignItems="center"
                            width="100%"
                          >
                            <MuiTypography variant="h6" gutterBottom>
                              Change Password
                            </MuiTypography>
                          </Box>
                          <Grid
                            container
                            spacing={3}
                            display="flex"
                            justifycontent="center"
                          >
                            <Grid item xs={12} lg={6}>
                              <div className={classes.margin}>
                                <Grid
                                  container
                                  spacing={1}
                                  alignItems="flex-end"
                                >
                                  <Grid
                                    item
                                    xs={2}
                                    lg={1}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <LockIcon />
                                  </Grid>
                                  <Grid item xs={10} lg={11}>
                                    <TextField
                                      disabled
                                      margin="dense"
                                      name="email"
                                      label=""
                                      value={user.data.email}
                                      type="email"
                                      fullWidth
                                      onChange={onHandleResetPassword}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                        <Box
                          display="flex"
                          justifyContent="center"
                          my={3}
                          alignItems="center"
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => submitData()}
                          >
                            Reset Password
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </CardContent>
                </MuiCard>
              </Grid>
            </Grid>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100"
            >
              {" "}
              <img src={loaderwave} alt="loader" />
            </Box>
          )}
          {/* {isPackagePurchased && <UserPage  />} */}
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={openSnackMisMatch}
            onClose={handleCloseSnackBar}
            message="Pasword Mismatch!"
          />
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={snackBarEmpty.openSnackEmpty}
            onClose={handleCloseSnackBarEmpty}
            message="Fields cannot be Empty to Reset Password"
          />
          <Snackbar
            open={openSuccess}
            autoHideDuration={3000}
            onClose={handleCloseSuccess}
          >
            <MuiAlert severity="success" onClose={handleCloseSuccess}>
              Your Password is Successfully Reset!
            </MuiAlert>
          </Snackbar>
        </div>
      </>
    );
  }
// }
