import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import StopIcon from '../../components/commons/icons/StopIcon';
import PlayIcon from '../../components/commons/icons/PlayIcon';
import PauseIcon from '../../components/commons/icons/PauseIcon';
import CompletedIcon from '../../components/commons/icons/CompletedIcon';
import { CircularProgress } from '@material-ui/core';

import { dateFormatter } from "../../utils/dateFormatter";
import { getAllScans, performScanAction, performSubScanAction } from "../../store/actions/scanActions";
import history from '../../@history';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {

  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <TableRow className={classes.root} hover>
        <TableCell component="th" scope="row" style={{ width: "100%", }}>
          <Box display="flex" alignItems="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Typography style={{ cursor: "pointer" }} onClick={() => history.push(`/dashboard?domain=${row.domain}&scanId=${row.scanId}`)}>{row.domain} - {dateFormatter(new Date(row.dateNow))}</Typography>
          </Box>
        </TableCell>
        <TableCell>
          {(row.status === "running" || row.status === "pending") && <IconButton onClick={() => dispatch(performScanAction(row.scanManagerId, "stop"))}>
            <StopIcon title="Stop Scan" />
          </IconButton>
          }
          {(row.status === "paused") && <IconButton onClick={() => dispatch(performScanAction(row.scanManagerId, "resume"))}>
            <PlayIcon title="Resume Scan" />
          </IconButton>
          }
          {(row.status === "running" || row.status === "pending") && <IconButton onClick={() => dispatch(performScanAction(row.scanManagerId, "pause"))}>
            <PauseIcon title="Pause Scan" />
          </IconButton>
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, overflow: "auto" }} colSpan={6} >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} >
              <Table size="small" aria-label="purchases" style={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 250 }}>Scan Id</TableCell>
                    <TableCell style={{ width: 150 }}>Date</TableCell>
                    <TableCell style={{ width: 120 }}>Browser</TableCell>
                    <TableCell style={{ width: 100 }}>Device</TableCell>
                    <TableCell style={{ width: 120 }}>Resolution</TableCell>
                    <TableCell style={{ width: 150 }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.scans.map((scan) => (
                    <TableRow key={scan.scanId}>
                      <TableCell scope="row">
                        {scan.subScanId}
                      </TableCell>
                      <TableCell scope="row">
                        {dateFormatter(new Date(scan.dateNow))}
                      </TableCell>
                      <TableCell>{scan.browser}</TableCell>
                      <TableCell>{scan.device}</TableCell>
                      <TableCell>
                        {scan.resolution}
                      </TableCell>
                      <TableCell>
                        {(scan.status === "running" || scan.status === "pending") && <IconButton onClick={() => dispatch(performSubScanAction(scan.subScanId, "stop"))}  >
                          <StopIcon title="Stop Scan" />
                        </IconButton>}

                        {(scan.status === "paused") && <IconButton onClick={() => dispatch(performSubScanAction(scan.subScanId, "resume"))}>
                          <PlayIcon title="Resume Scan" />
                        </IconButton>
                        }
                        {(scan.status === "running" || scan.status === "pending") &&
                          <IconButton onClick={() => dispatch(performSubScanAction(scan.subScanId, "pause"))}>
                            <PauseIcon title="Pause Scan" />
                          </IconButton>
                        }
                        {(scan.status === "running" || scan.status === "pending") && <IconButton onClick={() => dispatch(performSubScanAction(scan.subScanId, "complete"))}  >
                          <CompletedIcon title="Complete Scan" />
                        </IconButton>}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ScanActions() {

  const dispatch = useDispatch();

  const scans = useSelector((state) => state.scansActions.scans);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    dispatch(getAllScans()).then((data) => {
      if (data) {
        setLoading(false);
      }
    });
    // eslint-disable-next-line
  }, [])

  return (
    <Table aria-label="collapsible table" style={{ tableLayout: "fixed" }} size="small" >
      <TableHead>
        <TableRow>
          <TableCell
            style={{
              width: "70%",
            }}
          >Scans</TableCell>
          <TableCell style={{ width: '30%' }}>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!loading ? <>
          {scans && scans.length > 0 ? <> {scans.map((row) => (
            <Row key={row.scanManagerId} row={row} />
          ))}</> :
            <TableRow>
              <TableCell align="center" colSpan="2"><i>No Scans</i></TableCell>
            </TableRow>
          }
        </> :
          <TableRow>
            <TableCell align="center" colSpan="2">
              <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            </TableCell>
          </TableRow>
        }
      </TableBody>
    </Table>
  );
}
