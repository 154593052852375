import Auth0Lock from 'auth0-lock';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
// import AUTH_CONFIG from './auth0ServiceConfig';
import logo from '../../assets/logo.svg';


const getConfig = localStorage.getItem("authConfig");
const configValue = JSON.parse(getConfig)
const AUTH_CONFIG = configValue;
const getDomain = localStorage.getItem("domain");
console.log(localStorage.domain);
class Auth0Service {
  getQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get("website");
    // console.log(paramValue);
    if(paramValue) {
      localStorage.setItem("domain", paramValue);
    }
    // console.log("Value in localStorage:", localStorage.getItem("domain"));
    return paramValue;
  };
  getUserQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get("user");
    // console.log(paramValue);
    return paramValue;
  };
  sdk = { auth0Manage: null };
  init(success) {
    console.log("Here auth")
    if (Object.entries(AUTH_CONFIG).length === 0 && AUTH_CONFIG.value.auth0_constructor === Object) {
      if (process.env.NODE_ENV === 'development') {
        console.warn('Missing Auth0 configuration at src/app/services/auth0Service/auth0ServiceConfig.js');
      }
      success(false);
      return;
    }
    // if (!AUTH_CONFIG || !AUTH_CONFIG.value || Object.entries(AUTH_CONFIG).length === 0 || typeof AUTH_CONFIG.value.auth0_constructor !== 'object') {
    //   if (process.env.NODE_ENV === 'development') {
    //     console.warn('Missing Auth0 configuration at src/app/services/auth0Service/auth0ServiceConfig.js');
    //   }
    //   success(false);
    //   return;
    // }
    const options = {
      autoclose: true,
      closable: false,
      socialButtonStyle: 'big',
      container: 'hiw-login-container',
      // allowSignUp:(localStorage.domain && localStorage.domain !== undefined) ? true : false,
      allowSignUp:(this.getUserQueryParams() === "new") ? true : false,
      // allowSignUp: localStorage.scanManagerId ? true : false,
      auth: {
        // redirect: false,
        redirectUrl: AUTH_CONFIG.value.callbackUrl,
        // redirectUrl: "http://localhost:3001/callback",
        sso: false,
        responseType: 'token id_token',
        audience: `https://${AUTH_CONFIG.value.auth0_domain}/api/v2/`,
        params: {
          scope:
            'openid profile email user_metadata app_metadata picture update:current_user_metadata create:current_user_metadata read:current_user'
        }
      },
      theme: {
        logo: logo,
        primaryColor: '#31324F'
      },
      allowShowPassword: true,
      additionalSignUpFields: [
        {
          name: "name",
          placeholder: "your name",
          icon: "https://icon-library.com/images/person-icon-png/person-icon-png-13.jpg"
        },
        {
          name: "organization",
          placeholder: "your organization",
          icon: "https://airlines-airports.com/wp-content/uploads/2016/09/Offices.png"
        }
      ],
      languageDictionary: {
        title: "Log In"
      },
    };

    // this.lock = new Auth0Lock(AUTH_CONFIG.value.auth0_client_id, AUTH_CONFIG.value.auth0_domain, options);

    this.handleAuthentication();
    success(true);
  }

  login = () => {
    if (!this.isAuthenticated()) {
      if (!this.lock) {
        console.warn("Auth0 Service didn't initialize, check your configuration");

        return false;
      }
      // Call the show method to display the widget.
      return this.lock.show();
    } else {
      window.location.pathname = '/'
    }
  };

  register = () => {
    if (!this.lock) {
      console.warn("Auth0 Service didn't initialize, check your configuration");

      return false;
    }

    return this.lock.show({
      initialScreen: 'signUp'
    });
  };

  handleAuthentication = () => {
    if (!this.lock) {
      return false;
    }
    // Add a callback for Lock's `authenticated` event
    this.lock.on('authenticated', this.setSession);
    // Add a callback for Lock's `authorization_error` event
    this.lock.on('authorization_error', err => {
      console.warn(`Error: ${err.error}. Check the console for further details.`);
      window.location.pathname =`/authenticate`
    });

    return true;
  };

  onAuthenticated = callback => {
    if (!this.lock) {
      return false;
    }
    return this.lock.on('authenticated', callback);
  };

  setSession = authResult => {
    if (authResult && authResult.accessToken && authResult.idToken) {
      // Set the time that the access token will expire at

      const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());


      localStorage.setItem('access_token', authResult.accessToken);
      localStorage.setItem('id_token', authResult.idToken);
      localStorage.setItem('expires_at', expiresAt);

      // Remove domain from localStorage after successful signup
      // localStorage.removeItem('domain');

      // history.push({
      //   pathname: '/dashboard'
      // });
    }
  };

  logout = () => {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('auth0.ssodata');
    localStorage.removeItem('domain');
    localStorage.removeItem('selectedDomain');
    localStorage.removeItem('scanManagerId');

    // history.push({
    //   pathname: '/'
    // });
  };

  isAuthenticated = () => {
    if (!this.lock) {
      return false;
    }
    // alert("Authenticated");
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const isNotExpired = new Date().getTime() < expiresAt;
    if (isNotExpired) {
      return true;
    }

    this.logout();
    return false;
  };

  getUserData = () => {
    return new Promise((resolve, reject) => {
      const tokenData = this.getTokenData();
      const { sub: userId } = tokenData;

      const auth0UserUrl = `https://${AUTH_CONFIG.value.auth0_domain}/api/v2/users/${userId}`;

      axios
        .get(auth0UserUrl, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getAccessToken()}`
          }
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          // handle error
          console.warn('Cannot retrieve user data', error);
          reject(error);
        });
    });
  };

  getUserEmail = () => {
    return new Promise((resolve, reject) => {
      const tokenData = this.getTokenData();
      const { sub: userId } = tokenData;

      const auth0UserUrl = `https://${AUTH_CONFIG.value.auth0_domain}/api/v2/users/${userId}`;

      axios
        .get(auth0UserUrl, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getAccessToken()}`
          }
        })
        .then(response => {
          resolve(response.data);
          return response.data
        })
        .catch(error => {
          // handle error
          console.warn('Cannot retrieve user data', error);
          reject(error);
        });
    });
  };



  changeUserPassword = (email) => {
    return new Promise((resolve, reject) => {
      // const tokenData = this.getTokenData();
      // const { sub: userId } = tokenData;

      const auth0UserUrl = `https://${AUTH_CONFIG.value.auth0_domain}/dbconnections/change_password`;
      const dataObj = JSON.stringify({
        client_id: AUTH_CONFIG.value.auth0_clientId,
        email: email, connection: `${AUTH_CONFIG.value.auth0_db_connection}`
      });

      axios
        .post(auth0UserUrl, dataObj, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getAccessToken()}`
          }
        })
        .then(response => {
          // alert(response.data);
          resolve(response.data);
        })
        .catch(error => {
          // handle error
          console.warn('Cannot retrieve user data', error);
          reject(error);
        });
    });
  };

  updateUserData = userMetadata => {
    const tokenData = this.getTokenData();
    const { sub: userId } = tokenData;

    const auth0UserUrl = `https://${AUTH_CONFIG.value.auth0_domain}/api/v2/users/${userId}`;
    const dataObj = JSON.stringify({ user_metadata: userMetadata });

    return axios.patch(auth0UserUrl, dataObj, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getAccessToken()}`
      }
    });
  };

  getAccessToken = () => {
    return localStorage.getItem('access_token');
  };

  getIdToken = () => {
    return window.localStorage.getItem('id_token');
  };

  getTokenData = () => {
    const token = this.getIdToken();
    const decoded = jwtDecode(token);
    if (!decoded) {
      return null;
    }
    return decoded;
  };
}

const instance = new Auth0Service();

export default instance;
