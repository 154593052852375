import {
  LOADING_VIDEO,
  GET_VIDEOS_CARD_DETAILS,
  GET_VIDEOS_HISTORY,
  LOADING_VIDEOS_HISTORY
} from '../actions/videosCardActions';

const initialState = {
  data: null,
  loading: false,
  status: null,

  videosHistory: null,
  videosHistoryLoading: false,
  videosHistoryStatus: null,
};

const videosCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIDEOS_CARD_DETAILS:
      return {
        ...state,
        data: action.payload.result,
        status: action.payload.status
      }
    case LOADING_VIDEO:
      return {
        ...state,
        loading: !state.loading
      }

    case GET_VIDEOS_HISTORY:
      return {
        ...state,
        videosHistory: action.payload.result.graph,
        videosHistoryStatus: action.payload.status,
        videosHistoryLoading: false
      }
    case LOADING_VIDEOS_HISTORY:
      return {
        ...state,
        videosHistoryLoading: true
      }
    default:
      return state;
  }
};

export default videosCardReducer;