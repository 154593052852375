import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
// import CardContainer from '../../components/commons/CardContainer';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import MaterialTable, { MTableToolbar } from 'material-table';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

import ArchiveIcon from '@material-ui/icons/Archive';
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  addCustomLink,
  deleteLinks,
  archiveLinks,
  getUniqueLinks,
} from "../../../store/actions/customScanPageListActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  customLink: {
    width: "100%", maxWidth: "600px", margin: "20px 0px",
  },
  customLinkInput: {
    width: "100%", maxWidth: "400px", margin: "10px 0px"
  },
  menuButton: {
    fontSize: 14,
    fontWeight: "bold",
    color: "white",
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },

}));

export default function UniqueSitemap({ setScanValues, scanValues }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [links, setLinks] = React.useState(null);

  const [domainLinks, setDomainLinks] = React.useState(null);
  const [selectedDomainLinks, setSelectedDomainLinks] = React.useState('');
  const [alphabetsArray, setAlphabetsArray] = React.useState(false);
  const [activePage, setActivePage] = React.useState('all');
  const [customLink, setCustomLink] = React.useState("");
  const [customLinkError, setCustomLinkError] = React.useState(null);

  const [openAccord, setOpenAccord] = React.useState(false);

  const [linkSelect, setLinkSelect] = React.useState('page');

  const selectedDomain = useSelector(state => state.dashboard.selectedDomain);
  const selectedDomainId = useSelector(state => state.dashboard.selectedDomainId);

  const scanId = useSelector(state => state.miscellaneous.selectedScanId);
  const selectedScanManagerId = useSelector(state => state.miscellaneous.selectedScanManagerId);



  React.useEffect(() => {
    if (selectedScanManagerId) {
      dispatch(getUniqueLinks(selectedScanManagerId)).then((data) => {
        if (data) {
          setLinks(data.links);
        }
      });
    }
    // eslint-disable-next-line
  }, [selectedScanManagerId]);

  React.useEffect(() => {
    if (links) {
      const linksArray = links.map(itm => {
        const shortLink = new URL(itm)
        return { link: itm, linkShort: shortLink.pathname }
      });
      setDomainLinks(linksArray);
    }
  }, [links]);

  React.useEffect(() => {
    if (links) {
      const getAlphas = getAlphabets();
      // eslint-disable-next-line
      const filterAlphabets = getAlphas.map(alpha => {
        var filterLinks = links?.filter(
          (link) => new URL(link).pathname[1] === alpha.toLowerCase()
        );
        if (filterLinks.length > 0) {
          return alpha
        }
      })
      setAlphabetsArray(filterAlphabets.filter(alpha => alpha !== undefined));
    }
    // eslint-disable-next-line
  }, [links]);


  function onDomainLinkSelect(rows) {
    rows = rows.map(row => row.link);
    setScanValues({ ...scanValues, links: rows });
  }

  const changeActivePage = (page) => {
    setActivePage(page);

    if (page === "all") {
      let linksArray = null;
      linksArray = links.map((itm) => {
        return { link: itm, linkShort: itm };
      });
      setDomainLinks(linksArray);
    } else {
      let linksArray = null;
      linksArray = links.map((itm) => {

        return { link: itm, linkShort: itm };
      });
      let linksToFilter = linksArray;
      var filterLinks = linksToFilter.filter(
        (link) => new URL(link.link).pathname[1] === page.toLowerCase()
      );
      setDomainLinks(filterLinks);
    }
  };

  const onKeyPressPageChange = (alpha, event) => {
    if (event.keyCode === 13) {
      changeActivePage(alpha)
    }
  };

  const isUrl = string => {
    try { return Boolean(new URL(string)); }
    catch (e) { return false; }
  }

  function handleCustomLink(event) {
    event.preventDefault();
    dispatch(addCustomLink(scanId, customLink, selectedDomainId)).then(data => {
      if (data) {
        dispatch(getUniqueLinks(selectedScanManagerId)).then((data) => {
          if (data) {
            setLinks(data.links);
          }
        });
      }
    })
  }

  function handleDeleteLinks() {
    dispatch(deleteLinks(scanId, selectedDomainId, selectedDomainLinks.join(",")))
    setSelectedDomainLinks('')
  }

  function handleArchiveLinks() {
    dispatch(archiveLinks(scanId, selectedDomainId, selectedDomainLinks))
    setSelectedDomainLinks('')
  }

  const handleChangeAccord = () => {
    setOpenAccord(!openAccord);
  };

  function getAlphabets() {
    const alphabets = [];
    for (let i = 65; i < 91; i++) {
      alphabets.push(String.fromCharCode(i));
    }
    return alphabets;
  }

  const handleChangeLinkSelect = (event) => {
    setLinkSelect(event.target.value);
  };


  return (
    <Grid container spacing={3}>
      <Grid item xs={12} data-tut="Link_List">
        <Box display="flex" alignItems="center" flexWrap="wrap" my={1.5}>
          <h3 tabIndex="0" style={{ margin: "0 0 0.5rem 1rem" }}>Total Numbers of Pages: {domainLinks && domainLinks.length}</h3> <h3 tabIndex="0" style={{ margin: "0 0 0.5rem 1rem" }}>Selected Pages: {selectedDomainLinks.length}</h3></Box>

        <RadioGroup row aria-label="link insertion" value={linkSelect} onChange={handleChangeLinkSelect}>
          <FormControlLabel value="page" control={<Radio />} label="Link" />
          <FormControlLabel value="directory" control={<Radio />} label="Directory" />
        </RadioGroup>
        <Accordion
          defaultExpanded={false}
          onChange={handleChangeAccord}
          expanded={openAccord}
          className={classes.customLink}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h4 style={{ margin: 0 }}>Add New {linkSelect === "page" ? 'Page' : 'Directory'}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="space-between">
              <form onSubmit={handleCustomLink} noValidate>
                <FormControl
                  classes={{
                    root: classes.customLinkInput
                  }}
                >
                  <TextField
                    label={`Custom ${linkSelect === "page" ? 'Page' : 'Directory'}`}
                    error={customLinkError ? true : false}
                    helperText={customLinkError}
                    value={customLink}
                    onChange={(event) => {
                      if (isUrl(event.target.value)) {
                        setCustomLink(event.target.value);
                        setCustomLinkError(null)
                      } else {
                        setCustomLink(event.target.value)
                        setCustomLinkError("URL is not valid!")
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LibraryAddIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <Button variant="contained" disabled={!customLink || customLinkError} type="submit" data-tut="Start_Scan" color="primary" >
                  Add {linkSelect === "page" ? 'Page' : 'Directory'}
                </Button>
              </form>
            </Box>
          </AccordionDetails>
        </Accordion>
        {domainLinks ?
          <MaterialTable
            onSelectionChange={onDomainLinkSelect}
            title="Unique Links"
            columns={[
              { title: 'Select All', field: 'linkShort' },
            ]}
            components={{
              Toolbar: props => (
                <div  >
                  <MTableToolbar {...props} />
                  {(selectedDomainLinks && selectedDomainLinks.length > 0) && <Box style={{ backgroundColor: "#1F3A93" }} pl={1}>
                    <IconButton edge="start" className={classes.menuButton} disabled={selectedDomainLinks.length < 1} onClick={() => handleDeleteLinks()}>
                      <DeleteForeverOutlinedIcon className={classes.buttonIcon} /> Delete
                    </IconButton>
                    <IconButton edge="start" className={classes.menuButton} disabled={selectedDomainLinks.length < 1} onClick={() => handleArchiveLinks()}>
                      <ArchiveIcon className={classes.buttonIcon} /> Archive
                    </IconButton>
                  </Box>}
                </div>
              )
            }}
            data={domainLinks}
            options={{
              search: true,
              selection: true,
              paging: false,
              sorting: false,
              maxBodyHeight: "50vh",
              headerStyle: {
                padding: 0
              },
              rowStyle: {
                padding: 0
              },
              cellStyle: {
                padding: 0,
                textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 100
              }
            }}
          />
          :
          <div className={classes.root}>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </div>
        }
        <Box my={2} display="flex" justifyContent="center" alignItems="center" flexWrap="wrap">
          <span key={"all"} disabled={!selectedDomain} onClick={() => changeActivePage("all")} className={(activePage === "all") ? "activeButton" : "notActiveButton"} >All</span>
          {alphabetsArray && alphabetsArray.map(alpha => {
            return (
              <span key={alpha} tabIndex="0" onKeyDown={(event) => onKeyPressPageChange(alpha, event)} disabled={!selectedDomain} onClick={() => changeActivePage(alpha)} className={(activePage === alpha) ? "activeButton" : "notActiveButton"} >{alpha}</span>
            );
          })}
        </Box>
      </Grid>
    </Grid>
  )
}

const AccordionSummary = withStyles({
  root: {
    padding: "5px 8px",
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    margin: 0,

    minHeight: "auto",
    "&$expanded": {
      minHeight: "auto",
    },
  },
  expandIcon: {
    padding: 2,
  },
  content: {
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    fontSize: 12,
    padding: 10,
  },
}))(MuiAccordionDetails);

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    width: "100%",
    boxShadow: "none",
    borderRadius: 10,
    marginBottom: 10,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      marginBottom: 20,
    },
  },
  expanded: {},
})(MuiAccordion);