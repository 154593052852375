const componentStyles = (theme) => ({
    'ml-10': {
        marginLeft: '10px'
    },
    'ml-5': {
        marginLeft: '5px'
    },
    'mr-10': {
        marginRight: '10px'
    },
    'mr-5': {
        marginRight: '5px'
    },
    'mt-10': {
        marginTop: '10px'
    },
    'mt-5': {
        marginTop: '5px'
    },
    'mt-20': {
        marginTop: '20px'
    },
    'mt-20': {
        marginTop: '20px'
    },
    'p-20': {
        padding: '20px'
    },
    'p-10': {
        padding: '10px'
    },
    'p-5': {
        padding: '5px'
    }
});

export default componentStyles;
