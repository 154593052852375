import React from "react";
import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  makeStyles,
  Button,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { Delete, Edit, RateReview } from "@material-ui/icons";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useDispatch, useSelector } from "react-redux";
import { addNewComment, editComment, removeComment } from "../../../store/actions/issueTrackerActions";
import { changeDate } from '../../../utils/changeDate';

const useStyles = makeStyles((theme) => ({
  page: {
    width: "calc(100% - 300px)",
    padding: "10px"

  },
  btn: {
    border: "2px solid black",
    borderStyle: "dashed",
    justifyContent: "center",
    margin: "auto",
  },

  iconBtn: {
    marginLeft: "10px",
    border: "2px solid gray",
    borderRadius: "0px",
    padding: "5px",
    width: "60px",

  },
  drawer: {
    width: 240,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 240,
      boxSizing: "border-box",
    },
  },

  barhaed: {
    display: "flex",
    justifyContent: "space-between"
  }
  ,
  btnf: {
    fontSize: ' 14px',
  }

}));

const Comments = ({ comments, domainId, issueId }) => {

  const { addCommentLoading, editCommentLoading, removeCommentLoading } = useSelector(state => state.issueTracker);

  const classes = useStyles();

  const dispatch = useDispatch();

  const [showEdit, setShowEdit] = React.useState(false);
  const [showNew, setShowNew] = React.useState(false);

  const [newComment, setNewComment] = React.useState({
    domainId: domainId,
    issueId: issueId,
    comment: {
      message: ""
    }
  });


  return (
    <>
      {comments.length > 0 ?
        comments.map((comment) => {
          return (
            <CommentChange comment={comment} domainId={domainId} issueId={issueId} />
          )
        }) :
        <Box textAlign="center">
          <Typography ><i>no comments</i></Typography>
        </Box>
      }
      <Box textAlign="center" mt={2}>
        {!showNew && !showEdit && <Button variant="contained" style={{ marginTop: 10 }} disabled={addCommentLoading} onClick={() => setShowNew(true)}>Add a Comment</Button>}
      </Box>
      {showNew && <Box my={2.5}>
        <CKEditor
          editor={ClassicEditor}
          config={{
            removePlugins: ['CKFinderUploadAdapter', 'CKFinder', 'EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed'],
          }}
          data={newComment.comment.message}
          onReady={editor => {
            editor.editing.view.change(writer => {
              writer.setStyle(
                "height",
                "250px",
                editor.editing.view.document.getRoot()
              );
            });
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setNewComment({ ...newComment, comment: { message: data } })
          }}
        />
        <Box display="flex" justifyContent="flex-end" width="100%" py={2}><Button variant="contained" color="primary" disabled={addCommentLoading} onClick={() =>
          dispatch(addNewComment(newComment)).then(data => {
            if (data) {
              setShowNew(false);
              setNewComment({ ...newComment, comment: { message: '' } })
            }
          })} >Add
          {addCommentLoading && <CircularProgress style={{ color: "black", marginLeft: "1rem" }} size={20} />}
        </Button>
          <Button variant="outlined" color="primary" style={{ marginLeft: 10 }} onClick={() =>
            setShowNew(false)} >Close</Button>
        </Box>
      </Box>}

    </>
  );
};

export default Comments;


function CommentChange({ comment, domainId, issueId }) {

  const { editCommentLoading, removeCommentLoading } = useSelector(state => state.issueTracker);

  const classes = useStyles();

  const dispatch = useDispatch();

  const [showEdit, setShowEdit] = React.useState(false);


  const [updateComment, setUpdateComment] = React.useState({
    domainId: domainId,
    id: 0,
    message: ""
  });
  return (
    <>
      {!showEdit ? <Card style={{
        marginTop: "7px",
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: 10,
      }}>
        <CardContent style={{ padding: '5px 16px' }} >
          <Grid container spacing={2}>
            <Grid item xs={2} lg={1}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                style={{ height: "100%" }}
              >
                <RateReview />
              </Box>
            </Grid>
            <Grid item xs={10} lg={11}>
              <Box className={classes.barhaed}>
                <Box display="flex" alignItems="center">
                  <Typography style={{ fontWeight: "bold", marginRight: 10 }}>{comment.user}</Typography>
                  <Typography>{changeDate(comment.created_at)}</Typography>
                </Box>
                <Box display="flex" alignItems="center" >
                  <IconButton onClick={() => {
                    setShowEdit(true);
                    setUpdateComment({ ...updateComment, id: comment.id, message: comment.message })
                  }}>
                    <Edit />
                  </IconButton>
                  <IconButton disabled={removeCommentLoading} onClick={() => {
                    dispatch(removeComment(comment.id, domainId, issueId))
                  }}>
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
              <div dangerouslySetInnerHTML={{ __html: comment.message }} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

        :
        <Box my={2.5}>
          <CKEditor
            editor={ClassicEditor}
            config={{
              removePlugins: ['CKFinderUploadAdapter', 'CKFinder', 'EasyImage', 'Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed'],
            }}
            data={updateComment.message}
            onReady={editor => {
              editor.editing.view.change(writer => {
                writer.setStyle(
                  "height",
                  "250px",
                  editor.editing.view.document.getRoot()
                );
              });
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setUpdateComment({ ...updateComment, message: data })
            }}
          />
          <Box display="flex" justifyContent="flex-end" width="100%" py={2}><Button variant="contained" color="primary" disabled={editCommentLoading} onClick={() =>
            dispatch(editComment(updateComment, issueId))} >Save Comment
            {editCommentLoading && <CircularProgress style={{ color: "black", marginLeft: "1rem" }} size={20} />}
          </Button>
            <Button variant="outlined" color="primary" style={{ marginLeft: 10 }} onClick={() =>
              setShowEdit(false)} >Close</Button>
          </Box>
        </Box>
      }
    </>
  )
}


