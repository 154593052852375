import AppContext from '../../AppContext';
// eslint-disable-next-line
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


class Authorization extends Component {
  constructor(props, context) {
    super(props);
    const { routes } = context;
    this.state = {
      accessGranted: true,
      routes
    };
  }

  componentDidMount() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.accessGranted !== this.state.accessGranted;
  }

  componentDidUpdate() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }
  
  

  static getDerivedStateFromProps(props, state) {

    // return {
    //   accessGranted: matched ? hasPermission(matched.route.auth, userRole) : true
    // };

    return {
      accessGranted: true
    };
  }

  redirectRoute() {
    // eslint-disable-next-line

    /*
        User is guest
        Redirect to Login Page
        */
    // alert(userRole);
    // if (!userRole || userRole.length === 0) {

      // alert("No User Role");

      // history.push({
      //   pathname: '/authenticate',
      //   state: { redirectUrl: pathname }
      // });
    // } else {
    //   /*
    //     User is member
    //     User must be on unAuthorized page or just logged in
    //     Redirect to dashboard or redirectUrl
    //     */
    //   history.push({
    //     pathname: redirectUrl
    //   });
    // }
  }

  render() {
    // console.info('Authorization rendered', accessGranted);
    return this.state.accessGranted ? <>{this.props.children}</> : null;
  }
}

function mapStateToProps({ auth }) {
  return {
    userRole: auth.user.role
  };
}

Authorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(Authorization));