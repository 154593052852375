import React from 'react';
import {  useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";

import {
  getUsers,
} from "../store/actions/inviteUsersActions.js";
 
import Auth0Service from "../services/auth0Service/authentikService";
import loaderwave from "../assets/loader.svg";

export default function Teams() {

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getUsers());
    // eslint-disable-next-line
  }, [])


  const [delayed, setDelayed] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setDelayed(false)
    }, 1500);
  }, [])


  if (!Auth0Service.isAuthenticated()) {
    return window.location.replace("/authenticate");
  } else {

    return (
      <>
        {!delayed ? <>

        </>
          :
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            tabIndex="0"
          >
            {" "}
            <img src={loaderwave} alt="loader" />
          </Box>}
      </>
    )
  }
}