const helpAndSupportStyles = (theme) => ({
  cardContent: {
    paddingTop: "35px",
    paddingLeft: "80px",
    paddingRight: "80px",
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: "44px",
    },
  },
  mainHeading: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
  },
  overlayLoader: {
    content: "",
    display: "block",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    zIndex: 999,
  },
});

export default helpAndSupportStyles;
