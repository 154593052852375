import React from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import CardContainer from "../components/commons/CardContainer";
// import ScanInputsCard from "../components/commons/ScanInputsCard";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { toast } from "react-toastify";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  inviteUser,
  getUsers,
  deleteUser,
  deleteInvitedUser,
  blockUser,
  getInvitedUsers,
  getRemainingWebsitesUsers,
  changeRole,
} from "../store/actions/inviteUsersActions.js";
import { tour } from "../store/actions/dashboardActions";
import CurrentWebsites from "./financialComponents/CurrentWebsites";
import DownGradeUsers from "../components/dialogs/DownGradeUsers";
import Auth0Service from "../services/auth0Service/authentikService";
import userPageStyles from "../Styles/jss/userPageStyles";

const useStyles = makeStyles(userPageStyles);

export default function UserPage() {
  // const { isPackagePurchased } = useSelector((state) => state.dashboard.isDomainPackagePurchased);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [role, setRole] = React.useState("viewer");
  const [changeUserRole, setChangeUserRole] = React.useState("viewer");
  const [dialog, setDialog] = React.useState(false);
  const [userId, setUserId] = React.useState("");
  const [showKey, setShowKey] = React.useState(false);
  const [rolePermissions, setRolePermissions] = React.useState({
    addScan: false,
    cancelScan: false,
    inviteUser: false,
    removeUser: false,
    blockUser: false,
    userRole: false,
    editUser: false,
    addDomain: false,
    deleteDomain: false,
    changeSubscription: false,
    addIssueTable: false,
    editIssueTable: false,
    deleteIssueTable: false,
    addBoard: false,
    removeBoard: false,
    addList: false,
    removeList: false,
    addCard: false,
    editCard: false,
    removeCard: false,
    chat: false,
    helpAndSupport: false,
  });

  const [email, setEmail] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const user = useSelector((state) => state.dashboard.isDomainPackagePurchased);
  const apiKey = useSelector((state) => state.auth.user.zapierApiKey);
  const matcher = (data) => {
    let matching = "";
    console.log(data);

    if (matching != "") {
      return true;
    } else {
      return false;
    }
  };
  const permissions = useSelector((state) => state.auth.user.data.permissions);

  const orgName = useSelector((state) => state.auth.user.orgName);
  const users = useSelector((state) => state.inviteUsers.users);
  const invitedUsers = useSelector((state) => state.inviteUsers.invitedUsers);
  const loadingInvite = useSelector((state) => state.inviteUsers.loadingInvite);
  const remainingUsers = useSelector(
    (state) => state.inviteUsers.remainingUsers
  );
  // const totalUsers = useSelector((state) => state.dashboard.isDomainPackagePurchased);
  const userRole = useSelector((state) => state.auth.user.role[0]);

  const loadingInvitedUsersDeleted = useSelector(
    (state) => state.inviteUsers.loadingInvitedUsersDeleted
  );
  const currentUserActionLoading = useSelector(
    (state) => state.inviteUsers.currentUserActionLoading
  );

  function handleRoleChange(event) {
    setRole(event.target.value);
  }

  function handleEmail(event) {
    setEmail(event.target.value);
  }

  function handleInvite(event) {
    event.preventDefault();
    if (!permissions.userRole) {
      toast.warn("Access Denied! Check Roles & Permissions");
    } else {
      if (
        orgName.match(
          "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
        )
        // matcher(orgName)
      ) {
        toast.warn("Please update your Organization Name");
      } else {
        dispatch(inviteUser(email, role, orgName));
      }
    }
  }

  React.useEffect(() => {
    dispatch(getUsers());
    dispatch(getInvitedUsers());
    dispatch(getRemainingWebsitesUsers());
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (invitedUsers && invitedUsers.length > 0) {
      const interval = setInterval(() => {
        dispatch(getInvitedUsers());
        dispatch(getUsers());
      }, 3000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [invitedUsers]);

  function deleteUsers(id) {
    dispatch(deleteUser(id));
  }

  function blockUsers(id, block) {
    dispatch(blockUser(id, block));
  }

  function handleClose() {
    setOpen(false);
  }

  function editUser(id) {
    setUserId(id);
    setDialog(true);
  }

  function changeRoleSubmit() {
    dispatch(changeRole(userId, changeUserRole));
    setDialog(false);
  }

  const handleClickShowKey = () => {
    setShowKey(!showKey);
  };
  const permissionsChecks = [
    {
      name: "addScan",
      key: "addScan",
      label: "Add Scan",
    },
    {
      name: "cancelScan",
      key: "cancelScan",
      label: "Cancel Scan",
    },
    {
      name: "inviteUser",
      key: "inviteUser",
      label: "Invite User",
    },
    {
      name: "removeUser",
      key: "removeUser",
      label: "Remove User",
    },
    {
      name: "blockUser",
      key: "blockUser",
      label: "Block User",
    },
    {
      name: "userRole",
      key: "userRole",
      label: "Role Change",
    },
    {
      name: "editUser",
      key: "editUser",
      label: "Edit User",
    },
    {
      name: "addDomain",
      key: "addDomain",
      label: "Add Domain",
    },
    {
      name: "deleteDomain",
      key: "deleteDomain",
      label: "Add Domain",
    },
    {
      name: "changeSubscription",
      key: "changeSubscription",
      label: "Change Subscription",
    },
    {
      name: "addIssueTable",
      key: "addIssueTable",
      label: "Add Issue Table",
    },
    {
      name: "editIssueTable",
      key: "editIssueTable",
      label: "Edit Issue Table",
    },
    {
      name: "deleteIssueTable",
      key: "deleteIssueTable",
      label: "Delete Issue Table",
    },
    {
      name: "addBoard",
      key: "addBoard",
      label: "Add Board (Srumboard)",
    },
    {
      name: "removeBoard",
      key: "removeBoard",
      label: "Remove Board (Srumboard)",
    },
    {
      name: "addList",
      key: "addList",
      label: "Add List (Srumboard)",
    },
    {
      name: "removeList",
      key: "removeList",
      label: "Remove List (Srumboard)",
    },
    {
      name: "addCard",
      key: "addCard",
      label: "Add Card (Srumboard)",
    },
    {
      name: "editCard",
      key: "editCard",
      label: "Edit Card (Srumboard)",
    },
    {
      name: "removeCard",
      key: "removeCard",
      label: "Remove Card (Srumboard)",
    },
    {
      name: "chat",
      key: "chat",
      label: "Chat",
    },
    {
      name: "helpAndSupport",
      key: "helpAndSupport",
      label: "Help & Support",
    },
  ];

  function handleRolesPermissionsCheck(e) {
    const isChecked = e.target.checked;
    setRolePermissions({ ...rolePermissions, [e.target.name]: isChecked });
  }

  if (!Auth0Service.isAuthenticated()) {
    return window.location.replace("/authenticate");
  } else {
    return (
      <>
        <Box
          classes={{
            root: classes.mainBox,
          }}
        >
          <CardContainer>
            <CustomTypography variant="h5" component="h5">
              Invite a New User
            </CustomTypography>
            <form onSubmit={handleInvite}>
              <Box my={1} display="flex" flexDirection="row" flexWrap="wrap">
                <TextField
                  className={classes.formControl}
                  id="email"
                  label="Enter Email"
                  type="email"
                  // required
                  onChange={(event) => handleEmail(event)}
                  disabled={loadingInvite || remainingUsers < 1}
                />
                <FormControl className={classes.formControl}>
                  <InputLabel style={{ fontSize: 18, fontWeight: 500 }}>
                    Select Role
                  </InputLabel>
                  <Select
                    IconComponent={(props) => <ExpandMoreIcon {...props} />}
                    value={role}
                    onChange={(event) => handleRoleChange(event)}
                    displayEmpty={false}
                    disabled={loadingInvite || remainingUsers < 1}
                  >
                    <MenuItem key="selectAdmin" value="admin">
                      Admin
                    </MenuItem>
                    <MenuItem key="selectTeamLead" value="team lead">
                      Team Lead
                    </MenuItem>
                    <MenuItem key="selectEditor" value="editor">
                      Editor
                    </MenuItem>
                    <MenuItem key="selectViewer" value="viewer">
                      Viewer
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <h4 style={{ marginLeft: "13px" }}>
                Remaining Users : {remainingUsers < 0 ? 0 : remainingUsers}
              </h4>
              <Box display="flex">
                <Button
                  disabled={remainingUsers < 1}
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ margin: "0px 13px ", borderRadius: "20px" }}
                >
                  Invite
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpen(true)}
                  style={{ borderRadius: "20px", }}
                >
                  Add More
                </Button>
              </Box>
            </form>
          </CardContainer>
          <Box style={{ marginTop: "4rem" }}>
            <CardContainer>
              <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
              >
                <CustomTypography variant="h5" component="h5">
                  Current Users
                </CustomTypography>
                {/* {userRole === "admin" && isPackagePurchased && (
                  <Box display="flex" alignItems="center" flexWrap="wrap">
                    <h4 style={{ marginRight: 10 }}>
                      Slots Available: {remainingUsers < 0 ? 0 : remainingUsers}{" "}
                      out of {totalUsers.selectedPackage.rangeOfUsers.userRange}
                    </h4> */}

                {/* </Box>
                )} */}
              </Box>
              {users && (
                <>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow role="row">
                          <CustomTableHead
                            align="left"
                            style={{
                              minWidth: "150px",
                              fontWeight: "bold",
                              fontFamily: "Lato",
                            }}
                            role="cell"
                            aria-colindex={1}
                          >
                            Name
                          </CustomTableHead>
                          <CustomTableHead
                            align="left"
                            style={{
                              minWidth: "200px",
                              fontWeight: "bold",
                              fontFamily: "Lato",
                            }}
                            role="cell"
                            aria-colindex={2}
                          >
                            Email
                          </CustomTableHead>
                          <CustomTableHead
                            align="left"
                            style={{
                              minWidth: "150px",
                              fontWeight: "bold",
                              fontFamily: "Lato",
                            }}
                            role="cell"
                            aria-colindex={3}
                          >
                            Role
                          </CustomTableHead>
                          <CustomTableHead
                            align="left"
                            style={{
                              minWidth: "200px",
                              fontWeight: "bold",
                              fontFamily: "Lato",
                            }}
                            role="cell"
                            aria-colindex={4}
                          >
                            Actions
                          </CustomTableHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users.map((row, i) => (
                          <TableRow key={row.name} aria-rowindex={i}>
                            <TableCell role="cell" align="left">
                              {row.name}
                            </TableCell>
                            <TableCell role="cell" align="left">
                              {row.email}
                            </TableCell>
                            <TableCell role="cell" align="left">
                              {row.role}
                            </TableCell>
                            <TableCell role="cell" disabled={true} align="left">
                              <Button
                                role="button"
                                label="delete this current user"
                                disabled={
                                  row.role === "admin" ||
                                  currentUserActionLoading
                                }
                                onClick={() => {
                                  if (permissions.removeUser) {
                                    dispatch(deleteUsers(row._id));
                                  } else {
                                    toast.warn(
                                      "Access Denied! Check Roles & Permissions"
                                    );
                                  }
                                }}
                              >
                                <DeleteIcon />
                              </Button>{" "}
                              {row.blocked ? (
                                <Button
                                  disabled={currentUserActionLoading}
                                  onClick={() => {
                                    if (permissions.blockUser) {
                                      blockUsers(row._id, false);
                                    } else {
                                      toast.warn(
                                        "Access Denied! Check Roles & Permissions"
                                      );
                                    }
                                  }}
                                >
                                  Unblock
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    if (permissions.blockUser) {
                                      blockUsers(row._id, true);
                                    } else {
                                      toast.warn(
                                        "Access Denied! Check Roles & Permissions"
                                      );
                                    }
                                  }}
                                  disabled={
                                    row.role === "admin" ||
                                    currentUserActionLoading
                                  }
                                >
                                  Block
                                </Button>
                              )}{" "}
                              {userRole === "admin" && (
                                <Button
                                  disabled={currentUserActionLoading}
                                  onClick={() => editUser(row._id)}
                                >
                                  Change Role
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <DownGradeUsers
                    totalUsers={4}
                    open={open}
                    onClose={handleClose}
                  />
                </>
              )}
            </CardContainer>
          </Box>
          <Box style={{ marginTop: "4rem" }}>
            <CardContainer>
              <CustomTypography
                variant="h5"
                component="h5"
                style={{ marginBottom: "1.5rem" }}
              >
                Invited Users
              </CustomTypography>
              <>
                {invitedUsers && (
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.invitedTable}
                      aria-label="Invited users table"
                    >
                      <TableHead>
                        <TableRow>
                          <CustomTableHead
                            role="cell"
                            align="left"
                            style={{
                              minWidth: "150px",
                              fontWeight: "bold",
                              fontFamily: "Lato",
                            }}
                          >
                            Email
                          </CustomTableHead>
                          <CustomTableHead
                            role="cell"
                            align="left"
                            style={{
                              minWidth: "80px",
                              fontWeight: "bold",
                              fontFamily: "Lato",
                            }}
                          >
                            Role
                          </CustomTableHead>
                          <CustomTableHead
                            role="cell"
                            align="left"
                            style={{
                              minWidth: "80px",
                              fontWeight: "bold",
                              fontFamily: "Lato",
                            }}
                          >
                            Actions
                          </CustomTableHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {invitedUsers.length > 0 ? (
                          invitedUsers.map((row) => (
                            <TableRow key={row.email}>
                              <TableCell role="cell" align="left">
                                {row.email}
                              </TableCell>
                              <TableCell role="cell" align="left">
                                {row.role}
                              </TableCell>
                              <TableCell role="cell" align="left">
                                <Button
                                  role="button"
                                  disabled={loadingInvitedUsersDeleted}
                                  label="delete this invited user"
                                  onClick={() => {
                                    if (permissions.removeUser) {
                                      dispatch(deleteInvitedUser(row._id));
                                    } else {
                                      toast.warn(
                                        "Access Denied! Check Roles & Permissions"
                                      );
                                    }
                                  }}
                                >
                                  <DeleteIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableCell
                            colspan="3"
                            style={{ textAlign: "center" }}
                          >
                            <i>No Invited User</i>
                          </TableCell>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </>
            </CardContainer>
          </Box>
          <Box style={{ marginTop: "4rem" }}>
            <CurrentWebsites />
          </Box>

          <Dialog
            open={dialog}
            onClose={() => setDialog(false)}
            aria-labelledby="Change Role"
            fullWidth={true}
            maxWidth={changeUserRole === "custom" ? "md" : "sm"}
          >
            <DialogContent>
              <DialogContentText>Change Role</DialogContentText>
              <FormControl style={{ width: "100%", maxWidth: "300px" }}>
                <InputLabel>Select Role</InputLabel>
                <Select
                  IconComponent={(props) => <ExpandMoreIcon {...props} />}
                  value={changeUserRole}
                  onChange={(event) => setChangeUserRole(event.target.value)}
                  displayEmpty={false}
                >
                  <MenuItem key="selectOwner" value="owner">
                    Owner
                  </MenuItem>
                  <MenuItem key="selectAdmin" value="admin">
                    Admin
                  </MenuItem>
                  <MenuItem key="selectTeamLead" value="team lead">
                    Team Lead
                  </MenuItem>
                  <MenuItem key="selectEditor" value="editor">
                    Editor
                  </MenuItem>
                  <MenuItem key="selectViewer" value="viewer">
                    Viewer
                  </MenuItem>
                  <MenuItem key="selectCustom" value="custom">
                    Custom Roles
                  </MenuItem>
                </Select>
              </FormControl>
              {changeUserRole === "custom" && (
                <fieldset style={{ borderRadius: "20px", margin: "20px" }}>
                  <legend
                    align="center"
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                  >
                    Select Permissions
                  </legend>
                  <Grid container>
                    {permissionsChecks.map((item) => {
                      return (
                        <Grid item xs={6} lg={4} key={item.key}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.checked}
                                onChange={(event) =>
                                  handleRolesPermissionsCheck(event)
                                }
                                name={item.name}
                                color="primary"
                              />
                            }
                            label={item.label}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </fieldset>
              )}
              <DialogActions>
                <Button color="primary" onClick={() => changeRoleSubmit()}>
                  Change
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Box style={{ marginTop: "2rem" }}>
                <CardContainer>
                  <CustomTypography variant="h5" component="h5">
                    Integrations
                  </CustomTypography>
                  <FormControl className={classes.keysFormControl}>
                    <InputLabel htmlFor="standard-adornment-password">
                      Zapier API key
                    </InputLabel>
                    <Input
                      id="standard-adornment-password"
                      type={showKey ? "text" : "password"}
                      defaultValue={apiKey}
                      value={apiKey}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            style={{ padding: 6 }}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowKey}
                          >
                            {showKey ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                          {showKey && (
                            <CopyToClipboard text={apiKey}>
                              <IconButton style={{ padding: 6 }}>
                                <FileCopyOutlinedIcon />
                              </IconButton>
                            </CopyToClipboard>
                          )}
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </CardContainer>
              </Box>
            </Grid>
            {/* <Grid item sm={6}>
              <Box style={{ marginTop: "2rem" }}>
                <CardContainer>
                  <CustomTypography variant="h5" component="h5">
                    Others
                  </CustomTypography>
                  <Box mt={4}>
                    <span style={{ fontSize: "16px" }}>
                      Wish to View the Tour Again?
                    </span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        dispatch(
                          tour({
                            dashboardPage: false,
                            customScanPage: false,
                            financialPage: false,
                            violationReportPage: false,
                          })
                        )
                      }
                      style={{ marginLeft: "13px", borderRadius: 20 }}
                    >
                      Click Here
                    </Button>
                  </Box>
                </CardContainer>
              </Box>
            </Grid> */}
          </Grid>
        </Box>
      </>
    );
  }
}

const CustomTableHead = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
  },
}))(TableCell);

const CustomTypography = withStyles((theme) => ({
  h5: {
    fontWeight: 600,
    fontSize: "19px",
  },
}))(Typography);
