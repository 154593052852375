import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { useDispatch } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import animationData from "../../assets/lottiefiles/logout.json";
import Lottie from 'react-lottie';

import { logoutUser } from '../../auth/store/actions';



export default function LogoutModal({ open }) {
  const dispatch = useDispatch();
  const cookies = new Cookies();

  function logout() {
    cookies.remove('selectedDomain');
    cookies.remove('selectedDomainId');
    dispatch(logoutUser());

  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <StyledDialog open={open} aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogContent>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={3}>
          <h1 style={{ marginTop: 0 }}>You are logged out</h1>
          <div className="lotties">
            <Lottie options={defaultOptions}
              height={200}
              width={200}
              isStopped={false}
              isPaused={false}
            />
          </div>
          <Button variant="contained" color="primary" style={{ marginBottom: "1rem" }} onClick={() => logout()}>Sign In</Button>
          <small>*You will be redirected to the login page</small>
        </Box>
      </DialogContent>

    </StyledDialog>
  );
}

const StyledDialog = withStyles({
  root: {
    zIndex: 9999999,
  }
})(Dialog);