import React from "react";
import { Container } from "@material-ui/core"
import addIssueStyles from "../../Styles/jss/addIssueStyles";
import { makeStyles, withStyles } from "@material-ui/styles";

import {
  addNewIssue,
} from "../../store/actions/issueTrackerActions";
import IssueForm from "./forms/IssueForm"
export default function NewIssue() {
  const classes = useStyles();

  return (
    <>
      <Container fixed style={{ padding: 20 }}>
        <div className={classes.mainRow}>
          <h3 className={classes.mainTitle}>New Issue</h3>
        </div>
        <IssueForm fun={addNewIssue} btnTitle={"Submit"} />
      </Container>
    </>
  );
}


const useStyles = makeStyles(addIssueStyles);

