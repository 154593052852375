import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSeoTable } from "../store/actions/seoTablesActions";
import TablesCard from "../components/commons/TablesCard";
import DescriptionIcon from "@material-ui/icons/Description";
import Box from "@material-ui/core/Box";
import ReactTable from "../components/commons/ReactTable";

import loader from "../assets/loader.svg";
import ScanSelector from "../components/commons/ScanSelector";
import ModuleSummary from "../components/commons/ModuleSummary";


const section508Columns = [
  {
    width: "250px",
    Header: "Link",
    accessor: "link",
  },
  {
    width: "120px",
    Header: "Title",
    accessor: "title",
  },
  // {
  //   width: "150px",
  //   Header: "Score",
  //   accessor: "score",
  // },
  {
    width: "300px",
    Header: "Description",
    accessor: "description",
  },

];

export default function SeoReport() {
  const [tblData, setTblData] = React.useState(null);
  const [page, setPage] = React.useState(1);

  const scanId = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );

  const dispatch = useDispatch();
  const data = useSelector((state) => state.seoTables.seoMain);
  const pages = useSelector((state) => state.seoTables.seoMainPages);
  const count = useSelector((state) => state.seoTables.seoMainCount);

  const status = useSelector((state) => state.seoTables.seoMainStatus);
  const loading = useSelector((state) => state.seoTables.seoMainLoading);

  React.useEffect(() => {
    if (scanId) {
      dispatch(
        getSeoTable(scanId, page)
      );
    } else {
      dispatch(
        getSeoTable(scanId, page)
      );
    }
    // eslint-disable-next-line
  }, [scanId, page]);

  React.useEffect(() => {
    if (status === "pending" || status === "running") {
      const interval = setInterval(() => {
        if (scanId) {
          dispatch(
            getSeoTable(scanId, page)
          );
        } else {
          dispatch(
            getSeoTable(scanId, page)
          );
        }
      }, 15000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [status, scanId]);

  React.useEffect(() => {
    function getSection508Data(dataFortbl1) {
      const tblSection508 = [];

      for (let i = 0; i < dataFortbl1.length; i++) {
        const link = dataFortbl1[i].link;
        const title = dataFortbl1[i].title
        const score = dataFortbl1[i].score;
        const description = dataFortbl1[i].description;
        tblSection508.push({
          key: i,
          link: <div style={{ fontWeight: 600 }}>{link}</div>,
          title: <div style={{ fontWeight: 600 }}>{title}</div>,
          score: <div style={{ fontWeight: 600 }}>{score}</div>,
          description: <div style={{ fontWeight: 600 }}>{description}</div>,
        });
      }
      return tblSection508;
    }

    let getParsedData = null;

    if (data) {
      getParsedData = getSection508Data(data);
      setTblData(getParsedData);
    }
  }, [data, scanId]);

  return (
    <>
      <ScanSelector />
      <ModuleSummary module="seo" />
      <TablesCard
        icon={<DescriptionIcon />}
        title="SEO Issues"
        status={status}
      >
        {tblData && !loading ? (
          <>
            {tblData.length > 0 ? (
              <ReactTable
                tableColumns={section508Columns}
                data={tblData}
                pages={pages}
                page={page}
                count={count}
                setPage={setPage}
                selection={false}
              />
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ minHeight: "200px", maxHeight: "200px" }}
              >
                <h4>We found no Section 508 Issues on your site</h4>
              </Box>
            )}{" "}
          </>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: "200px", maxHeight: "200px" }}
          >
            <img src={loader} alt="loader" />
          </Box>
        )}
      </TablesCard>
    </>
  );
}
