import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as authActions from '../../auth/store/actions';
import AuthentikService from '../../services/auth0Service/authentikService';
import logo from '../../assets/logo.svg'; // Adjust the path to your logo
import './App.css'; // Make sure to create and import a CSS file for styling
import { toast } from 'react-toastify';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [showSignUp, setShowSignUp] = useState(false);
  const [registrationData, setRegistrationData] = useState({
    username: '',
    password: '',
    email: '',
    organization: ''
  });

  const AUTH_CONFIG = {
    client_id: 'fhOmcVTodFHuGIC9g6hJFbZGl0ncDXBsve9vfMxs',
    redirect_uri: `https://dashboard.allyright.com/authenticate`,
    authUrl: 'https://authentik.allyright.com/application/o/authorize/'
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const initAuth = async () => {
      AuthentikService.init((isInitialized) => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code');
        if (code) {
          AuthentikService.handleCallback(code).then((result, tokenData) => {
            if (result) {
              console.log("Authenticate Result:");
              console.log(result);
              dispatch(authActions.setUserDataAuth0(result.tokenData));
            }
          });
        }
      });

      if (AuthentikService.getUserQueryParams() === 'new') {
        setShowSignUp(true);
      }
    };
    initAuth();
  }, [dispatch]);

  const handleLogin = () => {
    AuthentikService.login();
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const updatedRegistrationData = {
      ...registrationData,
      username: registrationData.email.split('@')[0] // Set username from email
    };
    try {
      const response = await AuthentikService.register(updatedRegistrationData);
      if (response) { // Assuming 201 status code for successful registration
        // console.log("hererererere")
        // console.log(response);
        toast.success(response.message);
        const clientId = AUTH_CONFIG.client_id;
        const redirectUri = AUTH_CONFIG.redirect_uri;
        window.location.href = `${AUTH_CONFIG.authUrl}/?client_id=${clientId}&response_type=code&scope=openid email profile&redirect_uri=${redirectUri}`;

      }
      // Handle registration response
    } catch (error) {
      // Handle registration error
    }
  };


  const handleLogout = () => {
    AuthentikService.logout();
    setIsAuthenticated(false);
    setUser(null);
  };

  const handleChange = (e) => {
    setRegistrationData({
      ...registrationData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="App-auth">
      <div className="card-auth">
        <img src={logo} className="App-logo-auth" alt="logo" />
        {!isAuthenticated ? (
          <>
            {!showSignUp ? (
              <>
                <button onClick={handleLogin} className="sign-in-button-auth">Signin</button>
                {AuthentikService.getUserQueryParams() === "new" ? (
                  <button onClick={() => setShowSignUp(true)} className="sign-up-button-auth">Signup</button>

                ) : (null)

                }
                <a href="https://authentik.allyright.com/if/flow/recovery/?next=%2F" className="forgot-password-link">Forgot Password?</a>
                <div id="hiw-login-container"></div>
                {AuthentikService.getUserQueryParams() !== "new" && <p style={{ textAlign: 'center', fontSize: 18, background: '#fff', marginTop: 20 }}>Please First <a style={{ fontSize: 22, fontWeight: 'bold' }} href="/">Add Domain</a> to Sign Up</p>}
              </>
            ) : (
              <form onSubmit={handleRegister} className="register-form-auth">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  className="form-input-auth"
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  className="form-input-auth"
                />
                <input
                  type="text"
                  name="organization"
                  placeholder="organization"
                  onChange={handleChange}
                  className="form-input-auth"
                />
                <button type="submit" className="register-button-auth">Signup</button>
                {/* <button onClick={() => setShowSignUp(false)} className="cancel-button-auth">Login</button> */}
                <button onClick={() => setShowSignUp(false)} className="cancel-button-auth">
    <span className="back-arrow">←</span> Back to Login
  </button>
              </form>
            )}
          </>
        ) : (null)}
      </div>
    </div>
  );
}

export default App;
