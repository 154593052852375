import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import GraphCard from "../commons/GraphCard";
import DateRangePicker from "../commons/DateRangePicker";
import CircularProgressRing from "../commons/CircularProgressRing";
import { useSelector } from "react-redux";
import Section508 from "./analyticsCard/Section508Card";
import Auth0Service from "../../services/auth0Service/authentikService";
import Section508History from "./historyCards/Section508Card";

function Section508CurrentAndHistory({ scanId }) {
  const [time, setTime] = React.useState({
    current: true,
    overtime: false,
  });

  const domains = useSelector((state) => state.dashboard.domains);
  const selectedDomain = useSelector(
    (state) => state.miscellaneous.selectedDomain
  );
  const { isPackagePurchased } = useSelector((state) => state.auth.user);

  const [dateRange, setDateRange] = React.useState(null);
  const [loader, setLoader] = React.useState(false);

  return (
    <React.Fragment>
      <GraphCard height="auto">
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Box display="flex" lineHeight="1">
            <h3
              style={{
                margin: 0,
                fontSize: 18,
                fontWeight: "500",
              }}
            >
              {time.current ? "" : "History"}
            </h3>
            {loader && <CircularProgressRing />}
          </Box>
          <div>
            <ButtonGroup
              size="small"
              aria-label="Button group to switch between current and overtime"
            >
              {/* <Button
                disableRipple={true}
                style={{
                  backgroundColor: time.current ? "#21CA5A" : "#7E7E7E",
                  color: "#ffffff",
                }}
                onClick={() => setTime({ current: true })}
              >
                Current
              </Button> */}
              {/* <Button
                disableRipple={true}
                disabled={
                  (domains.length > 0 &&
                    selectedDomain &&
                    domains.filter(
                      (domain) => domain.domain === selectedDomain
                    )[0].scans.length < 2) ||
                  !Auth0Service.isAuthenticated() || !isPackagePurchased
                }
                style={{
                  backgroundColor: time.overtime ? "#21CA5A" : "#7E7E7E",
                  color: "#ffffff",
                }}
                onClick={() => setTime({ overtime: true })}
              >
                Historical
              </Button> */}
            </ButtonGroup>
            {time.overtime && <DateRangePicker setDateRange={setDateRange} />}
          </div>
        </Box>
        {time.current ? (
          <Section508 setLoader={setLoader} scanId={scanId} />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <GraphCard>
                <Section508History
                  setLoader={setLoader}
                  scanId={scanId}
                  dateRange={dateRange}
                />
              </GraphCard>
            </Grid>
          </Grid>
        )}
      </GraphCard>
    </React.Fragment>
  );
}
export default React.memo(Section508CurrentAndHistory);
