import React from 'react';
import { Typography, Box, List, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import InfoBox from './InfoBox';
import { makeStyles } from '@mui/styles';
import componentStyles from '../styles/stepper';

const useStyles = makeStyles(componentStyles);
const items = [
    {
        id: 1,
        title: 'Categories',
        icon: <ListIcon sx={{ color: 'black' }} />,
        description:
            "Select this option if you'd like to know the categories of personal information we have collected about you, the sources from which we collected it, the third parties to whom it was disclosed, and the business purposes for such disclosure"
    },
    {
        id: 2,
        title: 'Specific Pieces',
        icon: <DescriptionOutlinedIcon sx={{ color: 'black' }} />,
        description:
            "Select this option if you'd like to obtain access to the specific pieces of personal information we've collected about you."
    },
    {
        id: 3,
        title: 'Deletion',
        icon: <DeleteOutlineIcon sx={{ color: 'black' }} />,
        description:
            "Select this option if you'd like to have us and our service providers delete the personal information we've collected about you."
    },
    {
        id: 4,
        title: 'Do Not Sell My Info',
        icon: <CancelOutlinedIcon sx={{ color: 'black' }} />,
        description: "Select this option if you'd like to prevent the sharing of your information to third parties for value",
        subDescription:
            'This is not an opt out of email communications or telemarketing, which is handled separately, such as by clicking on an “unsubscribe” button in our marketing emails'
    }
];
const Index = ({ data, setData }) => {
    const classes = useStyles();
    const [selectedItem, setSelectedItem] = React.useState({
        id: 0,
        title: 'Request Type',
        description:
            "Please select the type of privacy request you'd like to submit. If you'd like to submit more than one type of request you may submit this form twice"
    });

    React.useEffect(() => {
        if (selectedItem) {
            setData({
                ...data,
                requestType: selectedItem.title
            });
        }
    }, [selectedItem]);

    return (
        <Box mt={2} display="flex">
            <Box width="50%">
                <Typography className={classes.heading3}> 3. Select the type of request you'd like to make.</Typography>
                <Box mt={2}>
                    <List>
                        {items.map((item) => {
                            return (
                                <ListItemButton
                                    className={classes.listItem}
                                    key={item.id}
                                    onClick={() => {
                                        setSelectedItem(item);
                                    }}
                                >
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText sx={{ ml: 2 }} className={classes.text2} primary={item.title} />
                                </ListItemButton>
                            );
                        })}
                    </List>
                </Box>
            </Box>
            <Box mt={5} width="50%" display="flex" justifyContent="center" alignItems="center">
                <InfoBox item={selectedItem} />
            </Box>
        </Box>
    );
};

export default Index;
