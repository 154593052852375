import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Loader from "../../components/commons/Loader";
import CustomCardContainerHead from "../../components/commons/CustomCardContainerHead";
import { useDispatch } from "react-redux";
import { getAccessibilityElements } from "../../store/actions/abracadabraActions";
import Pagination from "../../../src/components/pagination/Pagination"; // Import the Pagination component
import { toast } from "react-toastify";

export default function AccessibilityElements({ domain }) {
    const [loadingLinks, setLoadingLinks] = useState(true);
    const [data, setData] = useState([]);
    const [currentPage1, setCurrentPage1] = useState(1);
    const [showProgress, setShowProgress] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoadingLinks(true);
        getAccessibilityInfo();
    }, []);

    function getAccessibilityInfo() {
        dispatch(getAccessibilityElements(domain))
            .then(data => {
                if (data) {
                    if (data.response.response === "Request failed with status code 404") {
                        // Simulate a request
                        setTimeout(() => {
                            setShowProgress(true);
                            // Hide the progress indicator after 3 seconds
                            setTimeout(() => {
                                setShowProgress(false);
                            }, 3000);
                        }, 1000);
                    } else {
                        setLoadingLinks(false);
                        // console.log(data.response.result.issues);
                        setData(data.response.result.issues);
                    }
                } else {
                    setLoadingLinks(false);
                }
            })
            .catch(err => {
                setLoadingLinks(false);
            });
    }

    // Pagination for the first table (pluginLogs)
    const itemsPerPage1 = 10; // Number of items to display per page
    const totalPages1 = Math.ceil(data.length / itemsPerPage1);
    const indexOfLastItem1 = currentPage1 * itemsPerPage1;
    const indexOfFirstItem1 = indexOfLastItem1 - itemsPerPage1;
    const currentItems1 = data.slice(indexOfFirstItem1, indexOfLastItem1);

    const handlePageChange1 = (newPage) => {
        setCurrentPage1(newPage);
    };

    return (
        <>
            {/* Pagination for the first table (themes) */}
            <CustomCardContainerHead title="Website Elements" titleTag="h1">
                {loadingLinks && !showProgress ? (
                    <Loader />
                ) : (
                    <>
                        <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>

                                        <TableCell>Element</TableCell>
                                        <TableCell>Fixed Element</TableCell>
                                        <TableCell>Issue Description</TableCell>
                                        <TableCell>Fix Need</TableCell>
                                        <TableCell>WCAG Criteria</TableCell> 
                                        <TableCell>Type</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.isArray(currentItems1) && currentItems1.length > 0 ? (
                                        currentItems1.map((item, index) => (
                                            <TableRow key={index}>
                                                {Object.values(item).map((value, index) => (
                                                    <TableCell key={index}>{value.toString()}</TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            {showProgress ? (
                                                <TableCell colSpan={4}>
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                                                        <CircularProgress />
                                                    </div>
                                                </TableCell>
                                            ) : (
                                                <TableCell colSpan={4}>No data available</TableCell>
                                            )}
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid item xs={12}>
                            <Grid container alignItems="center">
                                <Grid item xs={3}>
                                    <h4>To fetch the latest results from the website, click the update button</h4>
                                </Grid>
                                <Grid item xs={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={getAccessibilityInfo}
                                    >
                                        Refresh
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Pagination controls for the first table (themes) */}
                        {!loadingLinks && data ? (
                            <Pagination totalPages={totalPages1} currentPage={currentPage1} onPageChange={handlePageChange1} />
                        ) : null}
                    </>
                )}
            </CustomCardContainerHead>
        </>
    );
}
