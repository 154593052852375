import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
// import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from "@material-ui/core/styles";
import ShortenNumber from "../../../commons/ShortenNumber";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import securityStatCardStyles from "../../../../Styles/jss/securityComponents/securityAnalytics/securityStatCardStyles";
// import CustomTooltip from '../../../commons/Tooltip';
const styles = {
  ...securityStatCardStyles,
};

const useStyles = makeStyles({
  root: {
    color: "white",
    fontFamily: "Segoe UI",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 700,
    letterSpacing: "0em",
    textAlign: "left",
  },
  IconButton: {
    marginLeft: 10,
    color: "black",
    padding: 0,
    marginBottom: 2,
  },
  title: {
    fontSize: 20,
    color: "#000",
    fontWeight: 700,
    margin: 0,
  },
});

export default function SecurityStatCard({
  title,
  detail,
  found,
  riskLevel,
  status,
  vuls,
  percentage,
  vulsPages,
  isRedirect,
  change,
}) {
  const settingDisplay = () => {
    change(vuls);
  };
  const classes = useStyles();
  return (
    <span
      style={{
        transform: "translate3d(0, 0, 0)",
      }}
    >
      {settingDisplay()}
      <Card style={styles.cardMain}>
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-start"
          width={"100%"}
          className="stat-card-dash"
        >
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
            width={"100%"}
          >
            <h2 className={classes.title}>{title}</h2>
            {/* <CustomTooltip title={detail}>
              <ErrorOutlineIcon style={{ fontSize: '1.3rem',color:'#585858' }} />
            </CustomTooltip> */}
          </Box>
        </Box>
        <CardContent style={styles.p0}>
          <Box style={styles.cardSub}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <h3 style={styles.subTitle}>Status</h3>
              <span
                style={{
                  color: status === "vulnerable" ? "#E2211E" : "#065B14",
                  fontWeight: "bold",
                }}
              >
                {status}
              </span>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              my={0.5}
            >
              {/* <h3 style={styles.subTitle}>Vulnerabilities</h3>
              <span style={styles.vulFound}>
                <ShortenNumber>{vuls}</ShortenNumber>
              </span> */}
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <h3 style={styles.subTitle}>Risk Level</h3>
              <Chip
                className={classes.root}
                style={{
                  backgroundColor:
                    riskLevel === "CRITICAL"
                      ? "#FFE4E8"
                      : riskLevel === "MODERATE"
                      ? "FFF6A4"
                      : "#CAFFD2",
                  color:
                    riskLevel === "MODERATE"
                      ? "black"
                      : riskLevel === "CRITICAL"
                      ? "#EA001B"
                      : "#065B14",
                }}
                size="small"
                label={riskLevel.toUpperCase()}
              />
            </Box>
          </Box>
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={title === "SSL Detection" ? 0 : 1.5}
            >
              <h3 style={styles.subTitle}>
                {title === "SSL Detection"
                  ? "HTTPS Redirect"
                  : "Vulnerable Pages"}
              </h3>
              <span style={styles.vulPages}>
                {title === "SSL Detection"
                  ? isRedirect
                    ? "Found"
                    : "Not Found"
                  : vulsPages}
              </span>
            </Box>
            {title !== "SSL Detection" ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box style={{ width: 90, height: 90 }}>
                  <CircularProgressbar
                    maxValue={vuls}
                    value={vuls}
                    text={`${vuls}`}
                    strokeWidth={13}
                    counterClockwise
                    styles={buildStyles({
                      pathColor: "#FF5C00",
                      textColor: "#000",
                      textSize: "21px",
                    })}
                  />
                  <h3
                    style={{
                      fontFamily: "Segoe UI",
                      fontSize: "20px",
                      fontWeight: 700,
                      letterSpacing: "0em",
                      lineHeight: 2,
                      marginTop: "-8px",
                      marginLeft: "-20px",
                    }}
                  >
                    Vulnerabilities
                    {/* <span style={styles.vulFound}>
                      <ShortenNumber>{vuls}</ShortenNumber>
                    </span> */}
                  </h3>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </CardContent>
      </Card>
    </span>
  );
}
