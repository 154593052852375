import jwtService from '../../../services/jwtService';
import * as UserActions from './user.actions';
import history from '../../../@history';

export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

export function submitRegister({ name, email, organization, password, confirmPassword }) {
	return dispatch =>
		jwtService
			.createUser({
				name,
				email,
				organization,
				password,
				confirmPassword
			})
			.then(user => {
				dispatch(UserActions.setUserData(user));

				history.push({
					pathname: '/dashboard'
				});

				return dispatch({
					type: REGISTER_SUCCESS
				});
			})
			.catch(error => {
				return dispatch({
					type: REGISTER_ERROR,
					payload: error
				});
			});
}
