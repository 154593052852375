import Loader from "../components/commons/Loader";
import * as userActions from "./store/actions";
import auth0Service from "../services/auth0Service/authentikService";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class Auth extends Component {
  state = {
    waitAuthCheck: true,
  };

  componentDidMount() {
    return Promise.all([this.auth0Check()]).then(() => {
      this.setState({ waitAuthCheck: false });
    });
  }

  auth0Check = () =>
    new Promise((resolve) => {
      auth0Service.init((success) => {
        if (!success) {
          resolve();
        }
      });

      if (auth0Service.isAuthenticated()) {
        this.props.setUserDataFromSavedToken().then(() => {
          resolve();
        });
      } else {
        localStorage.removeItem("access_token");
        localStorage.removeItem("id_token");
        localStorage.removeItem("expires_at");
        localStorage.removeItem("auth0.ssodata");
        localStorage.removeItem("authConfig");
        resolve();
      }

      return Promise.resolve();
    });

  render() {
    return this.state.waitAuthCheck ? <Loader /> : <>{this.props.children}</>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: userActions.logoutUser,
      setUserDataFromSavedToken: userActions.setUserDataFromSavedToken,
      setUserDataAuth0: userActions.setUserDataAuth0,
      // showMessage: Actions.showMessage,
      // hideMessage: Actions.hideMessage
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Auth);
