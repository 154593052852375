import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { Typography } from '@material-ui/core';
import Loader from "../../components/commons/Loader";
import CustomCardContainerHead from "../../components/commons/CustomCardContainerHead";
import { getPluginStatus } from "../../store/actions/abracadabraActions";
import { useDispatch } from "react-redux";
import { getModifiedPageLog, getSitemapInfo, getCrawlerStatus } from "../../store/actions/abracadabraActions";
import Pagination from "../../../src/components/pagination/Pagination"; // Import the Pagination component

export default function DomainImages({ domain }) {
    const [loadingLinks, setLoadingLinks] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = useState(true);
    const [sitemapData, setSitemapData] = React.useState([]);
    const [currentPage1, setCurrentPage1] = React.useState(1);
    const [currentPage2, setCurrentPage2] = React.useState(1);
    const [showProgress, setShowProgress] = useState(false);
    const [showProgress2, setShowProgress2] = useState(false);
    const [pluginInstalled, setPluginInstalled] = useState(true);
    const [scanStatus, setScanStatus] = useState('');
    const [showLogs, setShowLogs] = useState(false);
    const dispatch = useDispatch();

    // Simulating a process with setTimeout
    React.useEffect(() => {
        // Simulate a process taking 3 seconds to complete
        const process = setTimeout(() => {
            // Once the process is completed
            setLoading(false); // Hide the loader
            // Additional actions or state changes upon success can go here
        }, 4000);

        // Clear the timeout if component unmounts or if process succeeds before 3 seconds
        return () => clearTimeout(process);
    }, []);

    React.useEffect(() => {
        getPluginStatus2();
    }, []);


    React.useEffect(() => {
        const intervalId = setInterval(() => {
            getCrawlStatus();
        }, 5000); // 5 seconds in milliseconds

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [showLogs]);


    async function getPluginStatus2() {
        try {
            const response = await dispatch(getPluginStatus(domain));
            console.log(response.response.response);
            if (response && response.response.response === 200) {
                setPluginInstalled(true);
                setLoadingLinks(false);
                // console.log(response.response.result);
            } else {
                setPluginInstalled(false);
                setLoadingLinks(false);
                // toast.error("Failed to retrieve contact details.");
            }
        } catch (error) {
            setPluginInstalled(false);
            setLoadingLinks(false);
        }
    }


    async function getCrawlStatus() {
        try {
            const response = await dispatch(getCrawlerStatus(domain));
            console.log(response.response.result);
            if (response && response.response.result === "completed") {
                setLoadingLinks(false);
                setShowLogs(true);
                setScanStatus(response.response.result);
                // console.log(response.response.result);
            } else {
                setShowLogs(true);
                setLoadingLinks(false);
                setScanStatus(response.response.result);
                // toast.error("Failed to retrieve contact details.");
            }
        } catch (error) {
            setLoadingLinks(false);
            setShowLogs(false);
            // toast.error("No task found or still running");
        }
    }

    function getLogDetails() {
        dispatch(getModifiedPageLog(domain))
            .then(data => {
                if (data) {
                    // console.log(data.response.result.wpPageChanges)
                    setData(data.response.result.wpPageChanges);
                    setLoadingLinks(false);
                } else {
                    setLoadingLinks(false);
                }
            })
            .catch(err => {
                setLoadingLinks(false);
            });
    }


    function getSitemapDetails() {
        dispatch(getSitemapInfo(domain))
            .then(data => {
                if (data) {
                    // console.log(data.response.response)
                    setSitemapData(data.response.response);
                    setLoadingLinks(false);
                } else {
                    setLoadingLinks(false);
                }
            })
            .catch(err => {
                setLoadingLinks(false);
            });
    }

    React.useEffect(() => {
        setLoadingLinks(true);
        getLogDetails();
        getSitemapDetails();
    }, []);

    // Pagination for the first table (Modified Logs)
    const itemsPerPage1 = 10; // Number of items to display per page
    const totalPages1 = Math.ceil(data.length / itemsPerPage1);
    const indexOfLastItem1 = currentPage1 * itemsPerPage1;
    const indexOfFirstItem1 = indexOfLastItem1 - itemsPerPage1;
    const currentItems1 = data.slice(indexOfFirstItem1, indexOfLastItem1);

    let itemsPerPage2 = null
    let totalPages2 = null
    let indexOfLastItem2 = null
    let indexOfFirstItem2 = null
    let currentItems2 = null

    // Pagination for the second table (sitemaps)
    if (sitemapData) {
        itemsPerPage2 = 10; // Number of items to display per page
        totalPages2 = Math.ceil(sitemapData.length / itemsPerPage2);
        indexOfLastItem2 = currentPage2 * itemsPerPage2;
        indexOfFirstItem2 = indexOfLastItem2 - itemsPerPage2;
        currentItems2 = sitemapData.slice(indexOfFirstItem2, indexOfLastItem2);
    }



    const handlePageChange1 = (newPage) => {
        setCurrentPage1(newPage);
    };

    const handlePageChange2 = (newPage) => {
        setCurrentPage2(newPage);
    };

    const downloadFileInNewTab = () => {
        // Set the relative path to the file in the public folder
        const fileURL = '/plugins/AllyRight.zip';

        // Open a new tab and start the download
        window.open(fileURL);
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    {pluginInstalled ? (
                        <>
                            {showLogs && scanStatus === "completed" ? (
                                <>
                                    <CustomCardContainerHead title={"Sitemap Details"} titleTag="h1">
                                        {loadingLinks && !sitemapData ? (
                                            <Loader />
                                        ) : (
                                            <>
                                                <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                                                    <Table aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Title</TableCell>
                                                                <TableCell>Permalink</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {Array.isArray(currentItems2) && currentItems2.length > 0 ? (
                                                                currentItems2.map((item, index) => (
                                                                    <TableRow key={index}>
                                                                        {Object.values(item).map((value, index) => (
                                                                            <TableCell key={index}>{value.toString()}</TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                ))
                                                            ) : (
                                                                <TableRow>
                                                                    {showProgress2 ? (
                                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                                                                            <CircularProgress />
                                                                        </div>
                                                                    ) : (
                                                                        <TableCell colSpan={7}>No data available</TableCell>
                                                                    )}
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <Grid item xs={12}>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={3}>
                                                            <h4>To fetch the latest Sitemap from the website, click the update button</h4>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={getSitemapDetails}
                                                            >
                                                                Refresh
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {/* Pagination controls for the second table (plugins) */}
                                                {!loadingLinks && Array.isArray(sitemapData) && sitemapData.length > 0 ? (
                                                    <Pagination totalPages={totalPages2} currentPage={currentPage2} onPageChange={handlePageChange2} />
                                                ) : null}
                                            </>
                                        )}

                                    </CustomCardContainerHead>

                                    {/* Pagination for the first table (Modified Logs) */}
                                    <CustomCardContainerHead title="Modified Pages Logs" titleTag="h1">
                                        {loadingLinks && !data ? (
                                            <Loader />
                                        ) : (
                                            <>
                                                <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                                                    <Table aria-label="simple table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Post ID</TableCell>
                                                                <TableCell>Post URL</TableCell>
                                                                <TableCell>Time Modified</TableCell>
                                                                <TableCell>Date Modified</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {Array.isArray(currentItems1) && currentItems1.length > 0 ? (
                                                                currentItems1.map((item, index) => (
                                                                    <TableRow key={index}>
                                                                        {Object.values(item).map((value, index) => (
                                                                            <TableCell key={index}>{value.toString()}</TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                ))
                                                            ) : (
                                                                <TableRow>
                                                                    {showProgress ? (
                                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                                                                            <CircularProgress />
                                                                        </div>
                                                                    ) : (
                                                                        <TableCell colSpan={7}>No data available</TableCell>
                                                                    )}
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <Grid item xs={12}>
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={3}>
                                                            <h4>These logs are fetching after plugin installation.</h4>
                                                        </Grid>
                                                        {/* <Grid item xs={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={getLogDetails}
                                >
                                    Update
                                </Button>
                            </Grid> */}
                                                    </Grid>
                                                </Grid>
                                                {/* Pagination controls for the first table (themes) */}
                                                {!loadingLinks && data ? (
                                                    <Pagination totalPages={totalPages1} currentPage={currentPage1} onPageChange={handlePageChange1} />
                                                ) : null}
                                            </>
                                        )}
                                    </CustomCardContainerHead>
                                </>
                            ) : (scanStatus === "pending" ? (
                                <CustomCardContainerHead title="Modified Pages Logs" titleTag="h1">
                                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 20, marginBottom: 20 }} >
                                    <Typography variant="h4" style={{ fontSize: '24px' }}>
                                      Scan is running you can view the Modified Logs after the scan is finished
                                    </Typography>
                                  </div>
                                </CustomCardContainerHead>
                              ) : <Loader />)}
                        </>

                    ) : (<CustomCardContainerHead title="Install Plugin" titleTag="h1">
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 20, marginBottom: 20 }} >
                            <Typography variant="h4" style={{ fontSize: '24px' }}>
                                Plugin not installed please first install plugin on website
                            </Typography>
                        </div>
                        <Button
                            style={{ display: 'block', margin: 'auto', fontSize: '18px', backgroundColor: 'rgb(31, 58, 147)' }}
                            variant="contained"
                            color="primary"
                            onClick={() => getPluginStatus2()}
                        >
                            Recheck
                        </Button>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50, marginBottom: 20 }} >
                            <Typography variant="h4" style={{ fontSize: '24px' }}>
                                Follow the following steps to install plugin on website:
                            </Typography>
                        </div>
                        <div style={{ marginTop: 20, textAlign: 'center' }}>
                            <Typography variant="body1">
                                {/* Step 1 */}
                                1. Download the plugin file from the download button below
                            </Typography>
                            <Typography variant="body1">
                                {/* Step 2 */}
                                2. Go to the wordpress dashboard and go to the plugin section
                            </Typography>
                            <Typography variant="body1">
                                {/* Step 3 */}
                                3. Click on the button upload plugin
                            </Typography>
                            <Typography variant="body1">
                                {/* Step 4 */}
                                4. Select the downloaded file from your download folder
                            </Typography>
                            <Typography variant="body1">
                                {/* Step 5 */}
                                5. Click on install plugin
                            </Typography>
                            <Typography variant="body1">
                                {/* Step 6 */}
                                6. Click on activate plugin
                            </Typography>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50, marginBottom: 20 }} >
                            <Typography variant="h4" style={{ fontSize: '24px' }}>
                                Click below button to download plugin
                            </Typography>
                        </div>
                        <Button
                            style={{ display: 'block', margin: 'auto', fontSize: '18px', backgroundColor: 'rgb(31, 58, 147)' }}
                            variant="contained"
                            color="primary"
                            onClick={() => downloadFileInNewTab()}
                        >
                            Download
                        </Button>
                    </CustomCardContainerHead>)}
                </>
            )}
        </>
    );
}
