import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { toast } from "react-toastify";

export const SSL = 'SSL';
export const SSL_LOADING = 'SSL_LOADING';

export const HTTPS = 'HTTPS';
export const HTTPS_LOADING = 'HTTPS_LOADING';

export const XSS = 'XSS';
export const XSS_LOADING = 'XSS_LOADING';
export const XSS_RESET = 'XSS_RESET';

export const SQLI = 'SQLI';
export const SQLI_LOADING = 'SQLI_LOADING';
export const SQLI_RESET = 'SQLI_RESET';

export const OPEN_DB = 'OPEN_DB';
export const OPEN_DB_LOADING = 'OPEN_DB_LOADING';

export const SUBDOMAIN_TAKEOVER = 'SUBDOMAIN_TAKEOVER';
export const SUBDOMAIN_TAKEOVER_LOADING = 'SUBDOMAIN_TAKEOVER_LOADING';

export const DDOS = 'DDOS';
export const DDOS_LOADING = 'DDOS_LOADING';

export const CORS = 'CORS';
export const CORS_LOADING = 'CORS_LOADING';

export const CVES = 'CVES';
export const CVES_LOADING = 'CVES_LOADING';

export const BREACHED = 'BREACHED';
export const BREACHED_LOADING = 'BREACHED_LOADING';

export const LFI = 'LFI';
export const LFI_LOADING = 'LFI_LOADING';

export const SECURITY_MAIN = 'SECURITY_MAIN';
export const SECURITY_MAIN_LOADING = 'SECURITY_MAIN_LOADING';

export const SECURITY_MAIN_TOP5 = 'SECURITY_MAIN_TOP5';
export const SECURITY_MAIN_TOP5_LOADING = 'SECURITY_MAIN_TOP5_LOADING';

export const XSS_RESULTS = 'XSS_RESULTS';
export const SQLI_RESULTS = 'SQLI_RESULTS';
export const LFI_RESULTS = 'LFI_RESULTS';
export const XSS_RESULTS_LOADING = 'XSS_RESULTS_LOADING';
export const SQLI_RESULTS_LOADING = 'SQLI_RESULTS_LOADING';
export const LFI_RESULTS_LOADING = 'LFI_RESULTS_LOADING';


export const SQLI_NEW_RESULTS = 'SQLI_NEW_RESULTS';
export const XSS_NEW_RESULTS = 'XSS_NEW_RESULTS';
export const LFI_NEW_RESULTS = 'LFI_NEW_RESULTS';
export const SQLI_NEW_RESULTS_LOADING = 'SQLI_NEW_RESULTS_LOADING';
export const XSS_NEW_RESULTS_LOADING = 'XSS_NEW_RESULTS_LOADING';
export const LFI_NEW_RESULTS_LOADING = 'LFI_NEW_RESULTS_LOADING';


export const GET_SECURITY_HISTORY = 'GET_SECURITY_HISTORY';
export const LOADING_SECURITY_HISTORY = 'LOADING_SECURITY_HISTORY';


export const securityMain = (scanId) => async (dispatch) => {
  dispatch({
    type: SECURITY_MAIN_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }
    const res = await axios.get(`${BASE_URL}/dashboard/security-main`, {
      params,
      ...options
    });

    if (res.data) {
      //  ;
      dispatch({
        type: SECURITY_MAIN,
        payload: res.data
      })
    }
  } catch (err) {
  }
}
export const securityMainTop5 = (scanId) => async (dispatch) => {
  dispatch({
    type: SECURITY_MAIN_TOP5_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }
    const res = await axios.get(`${BASE_URL}/dashboard/security-main-top5`, {
      params,
      ...options
    });

    if (res.data) {
      //  ;
      dispatch({
        type: SECURITY_MAIN_TOP5,
        payload: res.data
      })
    }
  } catch (err) {
  }
}

export const sslChecker = (scanId, domain, dateNow) => async (dispatch) => {
  dispatch({
    type: SSL_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
      domain,
      dateNow
    }
    const res = await axios.get(`${BASE_URL}/dashboard/ssl-scan`, {
      params,
      ...options
    });

    if (res.data) {
      //  ;
      dispatch({
        type: SSL,
        payload: res.data
      })
    }
  } catch (err) {
  }
}


export const xssChecker = (scanId) => async (dispatch) => {
  dispatch({
    type: XSS_RESET,
  })
  dispatch({
    type: XSS_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }
    const res = await axios.get(`${BASE_URL}/cards/xss-card`, {
      params,
      ...options
    });

    if (res.data) {
      //  ;
      dispatch({
        type: XSS,
        payload: res.data
      })
    }
  } catch (err) {
  }
}

export const sqliChecker = (scanId) => async (dispatch) => {
  dispatch({
    type: SQLI_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }
    const res = await axios.get(`${BASE_URL}/cards/sqli-card`, {
      params,
      ...options
    });

    if (res.data) {
      //  ;
      dispatch({
        type: SQLI,
        payload: res.data
      })

    }
  } catch (err) {
  }
}

export const subDomainTakeOverChecker = (scanId) => async (dispatch) => {
  dispatch({
    type: SUBDOMAIN_TAKEOVER_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }
    const res = await axios.get(`${BASE_URL}/cards/subdomain-takeover-card`, {
      params,
      ...options
    });

    if (res.data) {
      //  ;
      dispatch({
        type: SUBDOMAIN_TAKEOVER,
        payload: res.data
      })

    }
  } catch (err) {
  }
}

export const corsChecker = (scanId) => async (dispatch) => {
  dispatch({
    type: CORS_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }
    const res = await axios.get(`${BASE_URL}/cards/cors-card`, {
      params,
      ...options
    });

    if (res.data) {
      //  ;
      dispatch({
        type: CORS,
        payload: res.data
      })

    }
  } catch (err) {
  }
}


export const cveChecker = (scanId) => async (dispatch) => {
  dispatch({
    type: CVES_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }
    const res = await axios.get(`${BASE_URL}/cards/cve-card`, {
      params,
      ...options
    });

    if (res.data) {
      //  ;
      dispatch({
        type: CVES,
        payload: res.data
      })

    }
  } catch (err) {
  }
}

export const breachedChecker = (scanId) => async (dispatch) => {
  dispatch({
    type: BREACHED_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }
    const res = await axios.get(`${BASE_URL}/cards/breached-card`, {
      params,
      ...options
    });

    if (res.data) {
      //  ;
      dispatch({
        type: BREACHED,
        payload: res.data
      })

    }
  } catch (err) {
  }
}


export const ddosChecker = (scanId) => async (dispatch) => {
  dispatch({
    type: DDOS_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }
    const res = await axios.get(`${BASE_URL}/cards/ddos-card`, {
      params,
      ...options
    });

    if (res.data) {
      //  ;
      dispatch({
        type: DDOS,
        payload: res.data
      })

    }
  } catch (err) {
  }
}

export const lfiChecker = (scanId) => async (dispatch) => {
  dispatch({
    type: LFI_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }
    const res = await axios.get(`${BASE_URL}/cards/lfi-card`, {
      params,
      ...options
    });

    if (res.data) {
      //  ;
      dispatch({
        type: LFI,
        payload: res.data
      })

    }
  } catch (err) {
  }
}

export const openDBChecker = (scanId) => async (dispatch) => {
  dispatch({
    type: OPEN_DB_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId
    }
    const res = await axios.get(`${BASE_URL}/cards/opendb-card`, {
      params,
      ...options
    });

    if (res.data) {
      //  ;
      dispatch({
        type: OPEN_DB,
        payload: res.data
      })

    }
  } catch (err) {
  }
}


export const sqliResults = (scanId) => async (dispatch) => {
  dispatch({
    type: SQLI_RESULTS_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {

      scanId,

    }
    const res = await axios.get(`${BASE_URL}/dashboard/sqli-result`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: SQLI_RESULTS,
        payload: res.data
      })
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}
export const xssResults = (scanId) => async (dispatch) => {
  dispatch({
    type: XSS_RESULTS_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {

      scanId,
    }
    const res = await axios.get(`${BASE_URL}/dashboard/xss-result`, {
      params,
      ...options
    });

    if (res.data) {
      //  ;
      dispatch({
        type: XSS_RESULTS,
        payload: res.data
      })
    }
  } catch (err) {
  }
}
export const lfiResults = (scanId) => async (dispatch) => {
  dispatch({
    type: LFI_RESULTS_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {

      scanId,

    }
    const res = await axios.get(`${BASE_URL}/dashboard/lfi-result`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: LFI_RESULTS,
        payload: res.data
      })
    }
  } catch (err) {
  }
}

export const sqliNewResults = (scanId) => async (dispatch) => {
  dispatch({
    type: SQLI_NEW_RESULTS_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {

      scanId,

    }
    const res = await axios.get(`${BASE_URL}/dashboard/new-sqli-issues`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: SQLI_NEW_RESULTS,
        payload: res.data
      })
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const xssNewResults = (scanId) => async (dispatch) => {
  dispatch({
    type: XSS_NEW_RESULTS_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {

      scanId,
    }
    const res = await axios.get(`${BASE_URL}/dashboard/new-xss-issues`, {
      params,
      ...options
    });

    if (res.data) {
      //  ;
      dispatch({
        type: XSS_NEW_RESULTS,
        payload: res.data
      })
    }
  } catch (err) {
  }
}

export const lfiNewResults = (scanId) => async (dispatch) => {
  dispatch({
    type: LFI_NEW_RESULTS_LOADING,
  })
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {

      scanId,

    }
    const res = await axios.get(`${BASE_URL}/dashboard/new-lfi-issues`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: LFI_NEW_RESULTS,
        payload: res.data
      })
    }
  } catch (err) {
  }
}



export const getSecurityHistory = (dateNowRange, scanId) => async (dispatch) => {
  dispatch({
    type: LOADING_SECURITY_HISTORY,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const params = {
      dateNowRange,
      scanId,
    }

    const res = await axios.get(`${BASE_URL}/history/security-history`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_SECURITY_HISTORY,
        payload: res.data
      });

    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}