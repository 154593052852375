import React from 'react';
import { useSelector } from 'react-redux';
import Snackbar from "@material-ui/core/Snackbar";
const SnackBarAlert = () => {

  const openSnack = useSelector(state => state.alert.openSnack);
  const message = useSelector(state => state.alert.message);

  const vertical = "bottom";
  const horizontal = "left";

  return (
    <Snackbar
      style={{ marginLeft: "4rem" }}
      anchorOrigin={{ vertical, horizontal }}
      open={openSnack}
      message={message}
    />
  )
}

export default SnackBarAlert;
