import * as Actions from "../actions/sheets.action";

const initialState = {
  sheetStatics: {
    score: 0,
    grade: "F",
    gradeType: "high",
    totalIssues: 1,
    resolvedIssues: 0,
    severity: {
      low: 0,
      medium: 0,
      high: 0,
    },
    totalPages: 0,
    pagesWithIssues: 0,
    issuesAveragePerPage: 0,
    section508: 0,
    priority: {
      a: 0,
      aa: 0,
      aaa: 0,
    },
  },

  allSheetStatics: {
    score: 0,
    grade: "F",
    gradeType: "high",
    totalIssues: 1,
    resolvedIssues: 0,
    severity: {
      low: 0,
      medium: 0,
      high: 0,
    },
    totalPages: 0,
    pagesWithIssues: 0,
    issuesAveragePerPage: 0,
    section508: 0,
    priority: {
      a: 0,
      aa: 0,
      aaa: 0,
    },
  },
  sheetStaticsLoading: false,
  allSheetStaticsLoading: false,
  accessibilitySheet: null,
  privacySheet: null,
  performanceSheet: null,
  securitySheet: null,
  addAccessibilitySheetLoading: false,
  getAccessibilitySheetLoading: false,
  addPerformanceSheetLoading: false,
  getPerformanceSheetLoading: false,
  addPrivacySheetLoading: false,
  getPrivacySheetLoading: false,
  addSecuritySheetLoading: false,
  getSecuritySheetLoading: false,
};

const sheetReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.GET_ALL_SHEET_STATISTICS_LOADING:
      return {
        ...state,
        allSheetStaticsLoading: !state.allSheetStaticsLoading,
      };
    case Actions.GET_ALL_SHEET_STATISTICS:
      return {
        ...state,
        allSheetStats: payload,
        allSheetStaticsLoading: false,
      };
    case Actions.GET_SHEET_STATISTICS:
      return {
        ...state,
        sheetStatics: payload,
        sheetStaticsLoading: false,
      };
    case Actions.GET_SHEET_STATISTICS_LOADING:
      return {
        ...state,
        sheetStaticsLoading: !state.sheetStaticsLoading,
      };

    case Actions.ADD_ACCESSIBILITY_SHEET_DATA:
      return {
        ...state,
        accessibilitySheet: [...state.accessibilitySheet, payload],
        addAccessibilitySheetLoading: false,
      };
    case Actions.ADD_ACCESSIBILITY_SHEET_DATA_LOADING:
      return {
        ...state,
        addAccessibilitySheetLoading: !state.addAccessibilitySheetLoading,
      };
    case Actions.ADD_PERFORMANCE_SHEET_DATA:
      return {
        ...state,
        performanceSheet: [...state.performanceSheet, payload],
        addPerformanceSheetLoading: false,
      };
    case Actions.ADD_PERFORMANCE_SHEET_DATA_LOADING:
      return {
        ...state,
        addPerformanceSheetLoading: !state.addPerformanceSheetLoading,
      };
    case Actions.ADD_SECURITY_SHEET_DATA:
      return {
        ...state,
        securitySheet: [...state.securitySheet, payload],
        addSecuritySheetLoading: false,
      };
    case Actions.ADD_SECURITY_SHEET_DATA_LOADING:
      return {
        ...state,
        addSecuritySheetLoading: !state.addSecuritySheetLoading,
      };
    case Actions.ADD_PRIVACY_SHEET_DATA:
      return {
        ...state,
        privacySheet: [...state.privacySheet, payload],
        addPrivacySheetLoading: false,
      };
    case Actions.ADD_PRIVACY_SHEET_DATA_LOADING:
      return {
        ...state,
        addPrivacySheetLoading: !state.addPrivacySheetLoading,
      };
    case Actions.GET_ACCESSIBILITY_SHEET:
      return {
        ...state,
        accessibilitySheet: payload,
        getAccessibilitySheetLoading: false,
      };
    case Actions.GET_ACCESSIBILITY_SHEET_LOADING:
      return {
        ...state,
        getAccessibilitySheetLoading: !state.getAccessibilitySheetLoading,
      };
    case Actions.GET_PERFORMANCE_SHEET:
      return {
        ...state,
        performanceSheet: payload,
        getPerformanceSheetLoading: false,
      };
    case Actions.GET_PERFORMANCE_SHEET_LOADING:
      return {
        ...state,
        getPerformanceSheetLoading: !state.getPerformanceSheetLoading,
      };
    case Actions.GET_PRIVACY_SHEET:
      return {
        ...state,
        privacySheet: payload,
        getPrivacySheetLoading: false,
      };
    case Actions.GET_PRIVACY_SHEET_LOADING:
      return {
        ...state,
        getPrivacySheetLoading: !state.getPrivacySheetLoading,
      };
    case Actions.GET_SECURITY_SHEET:
      return {
        ...state,
        securitySheet: payload,
        getSecuritySheetLoading: false,
      };
    case Actions.GET_SECURITY_SHEET_LOADING:
      return {
        ...state,
        getSecuritySheetLoading: !state.getSecuritySheetLoading,
      };

    default:
      return state;
  }
};

export default sheetReducer;
