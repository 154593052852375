import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { getAllImagesLinks, getCopyrightLinksLatest, purposeFixImagesLinksAI, getCopyrightLinks, FixImagesLinksWP, getPluginStatus, getCrawlerStatus } from "../../store/actions/abracadabraActions";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Loader from "../../components/commons/Loader";
import { toast } from "react-toastify";
import CustomCardContainerHead from "../../components/commons/CustomCardContainerHead";
import { LinearProgress } from '@material-ui/core';

export default function DomainImages({ domain, selectedScanId }) {
  const [loadingLinks, setLoadingLinks] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [editedValues, setEditedValues] = useState({});
  const [editedCaptions, setEditedCaptions] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(25); // Default value
  const [showProgress2, setShowProgress2] = useState(false);
  const [currentPage2, setCurrentPage2] = React.useState(1);
  const [plugindata, setPluginData] = React.useState([]);
  const [pluginInstalled, setPluginInstalled] = useState(false);
  const [resolvedIssues, setResolvedIssues] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [suggestedCaptionCheckedRows, setSuggestedCaptionCheckedRows] = useState([]);
  const totalIssues = selectedRows.length
  const itemsPerPage = rowsPerPage;


  // Calculate progress percentage
  const progress = totalIssues > 0 ? (resolvedIssues / totalIssues) * 100 : 0;

  // Simulating a process with setTimeout
  React.useEffect(() => {
    // Simulate a process taking 3 seconds to complete
    const process = setTimeout(() => {
      // Once the process is completed
      setLoading(false); // Hide the loader
      // Additional actions or state changes upon success can go here
    }, 4000);

    // Clear the timeout if component unmounts or if process succeeds before 3 seconds
    return () => clearTimeout(process);
  }, []);



  // Function to update the "Fix All" button text based on selection
  const getFixButtonText = () => {
    if (selectedRows.length === 0) {
      // No rows selected, hide the button
      return "Fix";
    } else if (selectedRows.length === data.length) {
      // All rows selected, set text to "Fix All"
      return "Fix All";
    } else {
      // Multiple rows selected, set text to "Fix Selected"
      return "Fix Selected";
    }
  };
  // const itemsPerPage = 5; // Number of items to display per page

  // Step 2: Function to toggle selection for a specific row
  const toggleRowSelection = (image) => {
    if (selectedRows.includes(image)) {
      setSelectedRows(selectedRows.filter((row) => row !== image));

    } else {
      setSelectedRows([...selectedRows, image]);
    }
  };

  const handleSuggestedCaptionCheckboxChange = (image) => {
    const isChecked = suggestedCaptionCheckedRows.includes(image);
    if (isChecked) {
      setSuggestedCaptionCheckedRows(suggestedCaptionCheckedRows.filter((row) => row !== image));
    } else {
      setSuggestedCaptionCheckedRows([...suggestedCaptionCheckedRows, image]);
    }
  };

  const getImageDataForSelectedRows = async () => {
    const selectedImagesData = data.filter((image) =>
      selectedRows.includes(image)
    );

    if (selectedImagesData.length > 0) {
      setShowProgress(true);
      for (let i = 0; i < selectedImagesData.length; i++) {
        const image = selectedImagesData[i];

        const isSuggestedCaptionChecked = suggestedCaptionCheckedRows.includes(image);

        const updatedImage = {
          suggested_alt_tag: image.current_alt_tag,
          domain_url: domain,
          image_url: image.image_url,
          suggested_caption: `${image.current_caption}`,
          suggested_caption_enable: isSuggestedCaptionChecked,
          link_page: image.link_page
        };
        try {
          const response = await dispatch(FixImagesLinksWP(selectedScanId, updatedImage));
          if (response) {
            setLoadingLinks(false);
            setResolvedIssues(prevResolvedIssues => prevResolvedIssues + 1);
            // toast.success(response.response.message)
          } else {
            setLoadingLinks(false);
            toast.warning("AI Suggestion not available at this time")
          }
        } catch (err) {
          setLoadingLinks(false);
          toast.warning("AI Suggestion not available at this time")
        }

        if (resolvedIssues === selectedRows.length) {
          setShowProgress(false);
        }

        //using ai images suggestions

        // const updatedImage = {
        //   current_alt_tag: image.current_alt_tag,
        //   domain_url: domain,
        //   link_page: image.link_page,
        //   image_url: image.image_url,
        //   current_caption: `${image.current_caption}`
        // };
        // try {
        //   const response = await dispatch(purposeFixImagesLinksAI(updatedImage));
        //   if (response) {
        //     setLoadingLinks(false);
        //     toast.success(response.response.response.message)
        //   } else {
        //     setLoadingLinks(false);
        //     toast.warning("AI Suggestion not available at this time")
        //   }
        // } catch (err) {
        //   setLoadingLinks(false);
        //   toast.warning("AI Suggestion not available at this time")
        // }

        // console.log("Selected Image Data:", updatedImage);
      }
    } else {
      toast.warning("Select at least one row to perform action", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  // Function to handle select all checkbox
  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(currentItems);
    }
    setSelectAll(!selectAll);
  };

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to the first page when rows per page changes
    getDomains(); // Call the API with the new rows per page value
  };

  const dispatch = useDispatch();

  // Function to remove duplicates based on image_url property
  function removeDuplicates(arr, prop) {
    return arr.filter((obj, index, self) =>
      index === self.findIndex((el) => (
        el[prop] === obj[prop]
      ))
    );
  }

  function getDomains() {
    console.log("here");
    console.log(selectedScanId);
    dispatch(getAllImagesLinks(selectedScanId))
      .then((data) => {
        if (data) {
          setLoadingLinks(false);
          console.log(data.links)
          const filteredLinks = data.links.filter(link => link !== null);
          // let uniqueObjects = removeDuplicates(data.links, 'image_url');
          // const allImages = uniqueObjects.flatMap(
          //   (link) => link || []
          // );
          setData(filteredLinks);
        } else {
          setLoadingLinks(false);
        }
      })
      .catch((err) => {
        setLoadingLinks(false);
      });
  }

  async function getPluginStatus2() {
    try {
      const response = await dispatch(getPluginStatus(domain));
      console.log(response.response.response);
      if (response && response.response.response === 200) {
        setPluginInstalled(true);
        setLoadingLinks(false);
        // console.log(response.response.result);
      } else {
        setPluginInstalled(false);
        setLoadingLinks(false);
        // toast.error("Failed to retrieve contact details.");
      }
    } catch (error) {
      setPluginInstalled(false);
      setLoadingLinks(false);
    }
  }

  // async function getCrawlStatus() {
  //   try {
  //     const response = await dispatch(getCrawlerStatus(domain));
  //     console.log(response.response.result);
  //     if (response && response.response.result === "completed") {
  //       setLoadingLinks(false);
  //       setShowImagesInfo(true);
  //       setScanStatus(response.response.result);
  //       // console.log(response.response.result);
  //     } else {
  //       setShowImagesInfo(true);
  //       setLoadingLinks(false);
  //       setScanStatus(response.response.result);
  //       // toast.error("Failed to retrieve contact details.");
  //     }
  //   } catch (error) {
  //     setLoadingLinks(false);
  //     setShowImagesInfo(false);
  //     // toast.error("No task found or still running");
  //   }
  // }

  // React.useEffect(() => {
  //   getPluginStatus2();
  // }, [pluginInstalled]);


  // React.useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     getCrawlStatus();
  //   }, 5000); // 5 seconds in milliseconds

  //   // Clear interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, [showImagesInfo]);

  React.useEffect(() => {
    setLoadingLinks(true);
    getDomains();
  }, [currentPage]);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  let itemsPerPage2 = null
  let totalPages2 = null
  let indexOfLastItem2 = null
  let indexOfFirstItem2 = null
  let currentItems2 = null


  // Pagination for the second table (plugins)
  if (plugindata) {
    itemsPerPage2 = 10; // Number of items to display per page
    totalPages2 = Math.ceil(plugindata.length / itemsPerPage2);
    indexOfLastItem2 = currentPage2 * itemsPerPage2;
    indexOfFirstItem2 = indexOfLastItem2 - itemsPerPage2;
    currentItems2 = plugindata.slice(indexOfFirstItem2, indexOfLastItem2);
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageChange2 = (newPage) => {
    setCurrentPage2(newPage);
  };

  const downloadFileInNewTab = () => {
    // Set the relative path to the file in the public folder
    const fileURL = '/plugins/AllyRight.zip';

    // Open a new tab and start the downloadFollow
  };


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {!pluginInstalled ? (
            <>
              <CustomCardContainerHead title={"Images Info"} titleTag="h1">
                <div>
                  {resolvedIssues < totalIssues && showProgress ? (
                    <>
                      <div>Total Issues: {totalIssues}</div>
                      <div>Resolved Issues: {resolvedIssues}</div>
                      <LinearProgress variant="determinate" value={progress} />
                      <div>Progress: {progress.toFixed(2)}%</div>
                    </>
                  ) : (
                    null
                    // <div>All issues have been resolved!</div>
                  )}
                </div>
                <div style={{ overflowX: "auto", maxWidth: "100%" }}>
                  <TableContainer component={Paper} style={{ overflowX: "auto" }}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <input
                              type="checkbox"
                              checked={selectAll}
                              onChange={toggleSelectAll}
                            />
                          </TableCell>
                          <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}><button onClick={() => getImageDataForSelectedRows()}>{getFixButtonText()}</button></TableCell>
                          <TableCell style={{ whiteSpace: "nowrap", maxWidth: "200px" }}>Set Suggested Caption</TableCell>
                          <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Image URL</TableCell>
                          <TableCell style={{ whiteSpace: "nowrap", maxWidth: "200px" }}>Fixed</TableCell>
                          <TableCell style={{ whiteSpace: "nowrap", maxWidth: "200px" }}>Suggested</TableCell>
                          <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Current Alt Tag</TableCell>
                          <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Suggested Alt Tag</TableCell>
                          <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Current Caption</TableCell>
                          <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Suggested Caption</TableCell>
                          {/* <TableCell style={{ whiteSpace: "nowrap", maxWidth: "500px" }}>Copyright Images</TableCell> */}
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      {!loadingLinks ? (
                        <TableBody>
                          {currentItems.length > 0 ? (
                            currentItems.map((image, index) => (
                              <React.Fragment key={index}>
                                <TableRow>
                                  <TableCell>
                                    <input
                                      type="checkbox"
                                      checked={selectedRows.includes(image)}
                                      onChange={() => toggleRowSelection(image)}
                                    />
                                  </TableCell>
                                  <TableCell>

                                  </TableCell>
                                  <TableCell>
                                    <input
                                      type="checkbox"
                                      checked={suggestedCaptionCheckedRows.includes(image)}
                                      onChange={() => handleSuggestedCaptionCheckboxChange(image)}
                                    />
                                  </TableCell>
                                  <TableCell style={{ whiteSpace: "wrap", maxWidth: "500px" }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      <div style={{ overflow: "hidden", textOverflow: "ellipsis", flex: 1 }}>
                                        {image.image_url}
                                      </div>
                                      <IconButton
                                        onClick={() => {
                                          navigator.clipboard.writeText(image.image_url);
                                          toast.success("Link copied to clipboard!", {
                                            position: "top-right",
                                            autoClose: 2000,
                                          });
                                        }}
                                        style={{ borderRadius: "50%", color: "black", marginLeft: "10px", padding: "8px" }}
                                      >
                                        <FileCopyIcon fontSize="small" />
                                      </IconButton>
                                    </div>
                                  </TableCell>
                                  <TableCell style={{ whiteSpace: "nowrap", minWidth: "200px", maxWidth: "200px" }}>
                                    <div>
                                      {image.fixed.toString()}
                                    </div>
                                  </TableCell>
                                  {image.suggested ? (
                                    <TableCell style={{ whiteSpace: "nowrap", minWidth: "200px", maxWidth: "200px" }}>
                                      <div>
                                        {image.suggested.toString()}
                                      </div>
                                    </TableCell>
                                  ) : (
                                    <TableCell style={{ whiteSpace: "nowrap", minWidth: "200px", maxWidth: "200px" }}>
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <span style={{ fontSize: "15px", marginRight: "5px" }} title="Warning: AI Suggestion not available for this">⚠️</span>
                                        {image.suggested.toString()}
                                      </div>
                                    </TableCell>
                                  )
                                  }





                                  {/* <TableCell style={{ whiteSpace: "wrap", maxWidth: "500px" }}>
                                                  <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis", flex: 1 }}>
                                                      {image.suggested.toString()}
                                                    </div>
                                                    <IconButton
                                                      style={{ borderRadius: "50%", color: "black", marginLeft: "10px", padding: "8px" }}
                                                    >
                                                      <WarningIcon fontSize="small" />
                                                    </IconButton>
                                                  </div>
                                                </TableCell> */}
                                  {/* <TableCell>
                                      <div>
                                        <div>Current</div>
                                        <div>Suggested</div>
                                        <div>Edited</div>
                                      </div>
                                    </TableCell> */}
                                  <TableCell style={{ whiteSpace: "nowrap", minWidth: "500px", maxWidth: "500px" }}>
                                    {image.current_alt_tag}
                                    {/* <div>
                                                    {image.current_alt_tag !== "Unable to generate alt tag, image resizing needed or image format issue." && image.current_alt_tag ? <div>{image.current_alt_tag}</div> : <div>None</div>}
                                                  </div> */}
                                  </TableCell>
                                  <TableCell style={{ whiteSpace: "nowrap", minWidth: "500px", maxWidth: "500px" }}>
                                    {image.suggested_alt_tag}
                                    {/* <div>
                                                    {image.suggested_alt_tag !== "Unable to generate alt tag, image resizing needed or image format issue." && image.suggested_alt_tag ? <div>{image.suggested_alt_tag}</div> : <div>None</div>}
                                                  </div> */}
                                  </TableCell>


                                  <TableCell style={{ whiteSpace: "nowrap", minWidth: "400px", maxWidth: "400px" }}>
                                    <div>
                                      {image.current_caption ? <div>{image.current_caption}</div> : <div>None</div>}
                                    </div>
                                  </TableCell>

                                  <TableCell style={{ whiteSpace: "nowrap", minWidth: "400px", maxWidth: "400px" }}>
                                    <div>
                                      {image.suggested_caption !== "Unable to generate alt tag, image resizing needed or image format issue." && image.suggested_caption ? <div>{image.suggested_caption}</div> : <div>None</div>}
                                    </div>
                                  </TableCell>

                                  {/* <TableCell style={{ whiteSpace: "nowrap", minWidth: "300px", maxWidth: "300px" }}>
                                          {image.copyrightImage === "No" ? (
                                            <div>
                                              <div>No Copyright found on image</div>
                                            </div>
                                          ) : (
                                            <div>
                                              <div>Copyright found on image</div>
                                            </div>
                                          )}
                                        </TableCell> */}


                                  <TableCell></TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={5}>no records found</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      ) : (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={5}>
                              <CircularProgress />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "10px" }}>
                  <Typography variant="body1" style={{ marginRight: "10px" }}>Number of entries:</Typography>
                  <TextField
                    type="number"
                    variant="outlined"
                    color="primary"
                    size="small"
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    InputProps={{
                      inputProps: { min: 1, max: 1000 }, // Minimum and maximum value allowed
                      style: { width: "5em" }, // Minimum value allowed
                    }}
                  />
                  <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                </div>
              </CustomCardContainerHead>
            </>
          ) : (
            <CustomCardContainerHead title="Install Plugin" titleTag="h1">
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 20, marginBottom: 20 }} >
                <Typography variant="h4" style={{ fontSize: '24px' }}>
                  Plugin not installed please first install plugin on website
                </Typography>
              </div>
              <Button
                style={{ display: 'block', margin: 'auto', fontSize: '18px', backgroundColor: 'rgb(31, 58, 147)' }}
                variant="contained"
                color="primary"
                onClick={() => getPluginStatus2()}
              >
                Recheck
              </Button>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50, marginBottom: 20 }} >
                <Typography variant="h4" style={{ fontSize: '24px' }}>
                  Follow the following steps to install plugin on website:
                </Typography>
              </div>
              <div style={{ marginTop: 20, textAlign: 'center' }}>
                <Typography variant="body1">
                  {/* Step 1 */}
                  1. Download the plugin file from the download button below
                </Typography>
                <Typography variant="body1">
                  {/* Step 2 */}
                  2. Go to the wordpress dashboard and go to the plugin section
                </Typography>
                <Typography variant="body1">
                  {/* Step 3 */}
                  3. Click on the button upload plugin
                </Typography>
                <Typography variant="body1">
                  {/* Step 4 */}
                  4. Select the downloaded file from your download folder
                </Typography>
                <Typography variant="body1">
                  {/* Step 5 */}
                  5. Click on install plugin
                </Typography>
                <Typography variant="body1">
                  {/* Step 6 */}
                  6. Click on activate plugin
                </Typography>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: 50, marginBottom: 20 }} >
                <Typography variant="h4" style={{ fontSize: '24px' }}>
                  Click below button to download plugin
                </Typography>
              </div>
              <Button
                style={{ display: 'block', margin: 'auto', fontSize: '18px', backgroundColor: 'rgb(31, 58, 147)' }}
                variant="contained"
                color="primary"
                onClick={() => downloadFileInNewTab()}
              >
                Download
              </Button>
            </CustomCardContainerHead>
          )}
        </>
      )}
    </>
  );
}

function Pagination({ totalPages, currentPage, onPageChange }) {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", margin: "10px" }}>
      {currentPage > 1 && (
        <IconButton onClick={() => onPageChange(currentPage - 1)}>
          <ArrowBackIcon />
        </IconButton>
      )}
      {pageNumbers.map((page) => (
        <Button
          key={page}
          onClick={() => onPageChange(page)}
          style={{
            borderRadius: "20px",
            margin: "5px",
            backgroundColor: currentPage === page ? "#007bff" : "#fff",
            color: currentPage === page ? "#fff" : "#007bff",
          }}
        >
          {page}
        </Button>
      ))}
      {currentPage < totalPages && (
        <IconButton onClick={() => onPageChange(currentPage + 1)}>
          <ArrowForwardIcon />
        </IconButton>
      )}
    </div>
  );
}
