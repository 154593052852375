import auth0Service from "../../services/auth0Service/authentikService";
import axios from "axios";
import { BASE_URL } from "../../source/constants";
import { getDomains } from "../actions/dashboardActions";
import { toast } from "react-toastify";
import history from "../../@history";
import History from "../../@history";

export const GET_LINKS = "GET_LINKS";
export const CUSTOM_LIST_RESACAN = "CUSTOM_LIST_RESACAN";

export const GET_CANCELLED_SCANS = "GET_CANCELLED_SCANS";

export const GET_ARCHIVED_LINKS = "GET_ARCHIVED_LINKS";

export const GET_ARCHIVED_DIRECTORIES = "GET_ARCHIVED_DIRECTORIES";

export const GET_DIRECTORIES = "GET_DIRECTORIES";

export const GET_COMPLETED_SCANS = "GET_COMPLETED_SCANS";
export const GET_COMPLETED_SCANS_LOADING = "GET_COMPLETED_SCANS_LOADING";

export const GET_DOMAIN_EVALUATION = "GET_DOMAIN_EVALUATION";
export const GET_SITEMAP_STAGE = "GET_SITEMAP_STAGE";

export const GET_SITEMAP_PROGRESS = "GET_SITEMAP_PROGRESS";

export const getLinks = (domainId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      domainId,
    };
    const res = await axios.get(`${BASE_URL}/dashboard/get-links`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_LINKS,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
  }
};

export const getDirectories = (domainId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      domainId,
    };

    const res = await axios.get(`${BASE_URL}/dashboard/get-directories`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_DIRECTORIES,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
  }
};

export const formSubmit = (domain, urls, scanType) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    if (urls !== "") {
      dispatch({
        type: CUSTOM_LIST_RESACAN,
      });
      // eslint-disable-next-line
      const res = await axios.post(
        `${BASE_URL}/scan/custom-list-rescan`,
        {
          domain,
          urls,
          scanType,
        },
        options
      );
      dispatch({
        type: CUSTOM_LIST_RESACAN,
      });
      toast.success("Your Custom Scan has Started");
      const alert = setTimeout(() => {
        window.location.reload();
        clearTimeout(alert);
      }, 1000);
    } else {
      toast.warn("Please Select the Links");
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }

    toast.error(err.response.data.message);
  }
  dispatch({
    type: CUSTOM_LIST_RESACAN,
  });
};

export const fullScan =
  (domain, scanType, fullScanType) => async (dispatch) => {
    let previousSiteMap = false;
    let fastCrawl = false;
    if (fullScanType) {
      if (fullScanType === "previousSiteMap") previousSiteMap = true;
      else {
        fastCrawl = true;
      }
    } else {
      previousSiteMap = false;
      fastCrawl = false;
    }
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      dispatch({
        type: CUSTOM_LIST_RESACAN,
      });
      // eslint-disable-next-line
      const res = await axios.post(
        `${BASE_URL}/scan/custom-list-rescan`,
        {
          domain,
          scanType,
          previousSiteMap,
          fastCrawl,
        },
        options
      );
      dispatch({
        type: CUSTOM_LIST_RESACAN,
      });
      toast.success("Your Full Scan has Started");

      const alert = setTimeout(() => {
        window.location.reload();
        clearTimeout(alert);
      }, 1000);
    } catch (err) {
      if (!err.response) {
        toast.warn("Warning ⚠️ bad internet connection.");
      }
      toast.error(err.response.data.message);
    }
    dispatch({
      type: CUSTOM_LIST_RESACAN,
    });
  };

export const deleteScan = (scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    };

    const res = await axios.delete(`${BASE_URL}/dashboard/delete-scan`, {
      params,
      ...options,
    });
    if (res.data) {
      toast.success(res.data.message);
      dispatch(getDomains());
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    toast.error(err.response.data.message);
  }
};

export const cancelScan = (scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(
      `${BASE_URL}/dashboard/cancel-scan`,
      {
        scanId,
      },
      options
    );
    if (res.data) {
      dispatch(getDomains());
      toast.success(res.data.message);
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    toast.error(err.response.data.message);
  }
};

export const addCustomLink =
  (scanId, link, selectedDomainId) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();

      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      const res = await axios.post(
        `${BASE_URL}/dashboard/add-custom-link`,
        {
          scanId,
          link,
        },
        options
      );
      if (res.data) {
        toast.success(res.data.message);
        dispatch(getLinks(selectedDomainId));
      }
    } catch (err) {
      if (!err.response) {
        toast.warn("Warning ⚠️ bad internet connection.");
      }
      toast.error(err.response.data.message);
    }
  };

export const deleteLinks =
  (scanId, selectedDomainId, links) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();

      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      const res = await axios.delete(
        `${BASE_URL}/dashboard/delete-links/?scanId=${scanId}&links=${links}`,
        options
      );
      if (res.data) {
        toast.success(res.data.message);
        dispatch(getLinks(selectedDomainId));
      }
    } catch (err) {
      if (!err.response) {
        toast.warn("Warning ⚠️ bad internet connection.");
      }
      toast.error(err.response.data.message);
    }
  };

export const deleteDirectories =
  (scanId, selectedDomainId, links) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();

      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      const res = await axios.delete(
        `${BASE_URL}/dashboard/delete-directories/?scanId=${scanId}&directories=${links}`,
        options
      );
      if (res.data) {
        toast.success(res.data.message);
        dispatch(getDirectories(selectedDomainId));
      }
    } catch (err) {
      if (!err.response) {
        toast.warn("Warning ⚠️ bad internet connection.");
      }
      toast.error(err.response.data.message);
    }
  };

export const archiveLinks =
  (scanId, selectedDomainId, links) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();

      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      const res = await axios.post(
        `${BASE_URL}/dashboard/archive-links`,
        {
          scanId,
          links,
        },
        options
      );
      if (res.data) {
        toast.success(res.data.message);
        dispatch(getLinks(selectedDomainId));
      }
    } catch (err) {
      if (!err.response) {
        toast.warn("Warning ⚠️ bad internet connection.");
      }
      toast.error(err.response.data.message);
    }
  };

export const archiveDirectories =
  (scanId, selectedDomainId, directories) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();

      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      const res = await axios.post(
        `${BASE_URL}/dashboard/archive-directories`,
        {
          scanId,
          directories,
        },
        options
      );
      if (res.data) {
        toast.success(res.data.message);
        dispatch(getDirectories(selectedDomainId));
      }
    } catch (err) {
      if (!err.response) {
        toast.warn("Warning ⚠️ bad internet connection.");
      }
      toast.error(err.response.data.message);
    }
  };

export const getArchivedLinks = (scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    };

    const res = await axios.get(`${BASE_URL}/dashboard/archived-links`, {
      params,
      ...options,
    });
    if (res.data) {
      dispatch({
        type: GET_ARCHIVED_LINKS,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
  }
};

export const getArchivedDirectories = (scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    };

    const res = await axios.get(`${BASE_URL}/dashboard/archived-directories`, {
      params,
      ...options,
    });
    if (res.data) {
      dispatch({
        type: GET_ARCHIVED_DIRECTORIES,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
  }
};

export const unArchivedLinks =
  (scanId, selectedDomainId, links) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();

      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      const res = await axios.post(
        `${BASE_URL}/dashboard/unarchive-links`,
        {
          scanId,
          links,
        },
        options
      );
      if (res.data) {
        toast.success(res.data.message);
        dispatch(getArchivedLinks(scanId));
        dispatch(getLinks(selectedDomainId));
      }
    } catch (err) {
      if (!err.response) {
        toast.warn("Warning ⚠️ bad internet connection.");
      }
    }
  };

export const unArchivedDirectories =
  (scanId, selectedDomainId, directories) => async (dispatch) => {
    try {
      const token = auth0Service.getAccessToken();

      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      const res = await axios.post(
        `${BASE_URL}/dashboard/unarchive-directories`,
        {
          scanId,
          directories,
        },
        options
      );
      if (res.data) {
        toast.success(res.data.message);
        dispatch(getArchivedDirectories(scanId));
        dispatch(getDirectories(selectedDomainId));
        dispatch(getArchivedLinks(scanId));
        dispatch(getLinks(selectedDomainId));
      }
    } catch (err) {
      if (!err.response) {
        toast.warn("Warning ⚠️ bad internet connection.");
      }
    }
  };

export const cancelledScansList = (domainId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      domainId,
    };
    const res = await axios.get(`${BASE_URL}/dashboard/cancelled-scans`, {
      params,
      ...options,
    });
    if (res.data) {
      dispatch({
        type: GET_CANCELLED_SCANS,
        payload: res.data,
      });
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    toast.error(err.response.data.message);
  }
};

export const getCompletedScans = (domainId) => async (dispatch) => {
  dispatch({
    type: GET_COMPLETED_SCANS_LOADING,
  });
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const params = {
      domainId,
    };
    const res = await axios.get(
      `${BASE_URL}/admin/completed-scans`,
      { params },
      options
    );
    if (res.data) {
      dispatch({
        type: GET_COMPLETED_SCANS,
        payload: res.data.result.scans,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_COMPLETED_SCANS_LOADING,
    });
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    } else {
      toast.warn(err.response.data.message);
    }
  }
};

export const getDomainEvaluation = (scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    };

    const res = await axios.get(`${BASE_URL}/sitemap/domain-evaluation`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_DOMAIN_EVALUATION,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    // if (!err.response) {
    //   toast.warn('Warning ⚠️ bad internet connection.');
    // }
  }
};

export const getSitemapStage = (scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    };

    const res = await axios.get(`${BASE_URL}/sitemap/stage`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_SITEMAP_STAGE,
        payload: res.data,
      });
      return res.data.stage;
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
  }
};

export const generateSitemap = (addSitemapOptions) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const res = await axios.post(
      `${BASE_URL}/sitemap/start`,
      {
        ...addSitemapOptions,
      },
      options
    );
    if (res.data) {
      return res.data;
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    toast.error(err.response.data.message);
  }
};

export const generateSitemapProgress = (scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    };

    const res = await axios.get(`${BASE_URL}/sitemap/progress`, {
      params,
      ...options,
    });

    if (res.data) {
      dispatch({
        type: GET_SITEMAP_PROGRESS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
  }
};

export const getUniqueLinks = (scanManagerId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanManagerId,
    };

    const res = await axios.get(`${BASE_URL}/sitemap/unique-sitemap`, {
      params,
      ...options,
    });

    if (res.data) {
      return res.data;
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
  }
};

export const getAllLinks = (scanManagerId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanManagerId,
    };

    const res = await axios.get(`${BASE_URL}/sitemap/sitemap`, {
      params,
      ...options,
    });

    if (res.data) {
      return res.data;
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
  }
};

export const startScan = (domainName, addScanOptions) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const res = await axios.post(
      `${BASE_URL}/scan/init-scan`,
      {
        ...addScanOptions,
      },
      options
    );
    if (res.data) {
      // localStorage.setItem("scanId", res.data.scanId);
      // history.push({
      //   pathname: `/dashboard`,
      //   search: `?domain=${res.data.domain}&scanId=${res.data.scanId}`,
      // });
      return res.data;
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    toast.error(err.response.data.message);
  }
};

export const startBasicScan = (data) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const res = await axios.post(
      `${BASE_URL}/scan/basic-scan-options`,
      {
        ...data,
      },
      options
    );
    if (res.data) {
      localStorage.setItem("scanId", res.data.scanId);
      toast.success("Your scan has started");
      //   console.log(res.data);
      //   setTimeout(() => {
      //     history.push({
      //       pathname: `/dashboard`,
      //       search: `?domain=${res.data.domain}&scanId=${res.data.scanId}`,
      //     });
      // }, 2000);
      dispatch(getDomains())

      return res.data;
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    toast.error(err.response.data.message);
  }
};

export const getDomainSettings = (domainId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      domainId,
    };

    const res = await axios.get(`${BASE_URL}/domain/settings`, {
      params,
      ...options,
    });

    if (res.data) {
      return res.data;
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
  }
};

export const setDomainSettings =
  (domainSettings, domainId) => async (dispatch, getState) => {
    try {
      const token = auth0Service.getAccessToken();
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      const res = await axios.post(
        `${BASE_URL}/domain/update-settings`,
        {
          domainId,
          ...domainSettings,
        },
        options
      );
      if (res.data) {
        localStorage.setItem("scanId", res.data.scanId);
        dispatch(getDomainSettings(domainId));
        return res.data;
      }
    } catch (err) {
      if (!err.response) {
        toast.warn("Warning ⚠️ bad internet connection.");
      }
      toast.error(err.response.data.message);
    }
  };


export const reGenerateScanSummary =
  (scanId, unified) => async (dispatch, getState) => {
    try {
      const token = auth0Service.getAccessToken();

      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      if (token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      const params = {
        scanId, unified
      };

      const res = await axios.get(`${BASE_URL}/dashboard/domain-summary-regenerate`, {
        params,
        ...options,
      });

      if (res.data) {
        setTimeout(() => {
          History.go(0);
        }, 2000);
        return res.data
      }
    } catch (err) {
      if (!err.response) {
        toast.warn("Warning ⚠️ bad internet connection.");
      }
    }
  };