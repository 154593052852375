import React from "react";
import 'react-dates/initialize'
import { DateRangePicker } from "react-dates";
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

export default function DatePicker({ setDateRange }) {

  const [focused, setFocused] = React.useState(null)
  const [startDateId, setStartDateId] = React.useState("")
  const [endDateId, setEndDateId] = React.useState("")

  const [date, setDate] = React.useState({
    startDate: moment(new Date()).subtract(5, 'months'),
    endDate: moment(new Date())
  })

  const dateChange = ({ startDate, endDate }) => {
    setDate({ startDate, endDate });
  };

  const focusChange = (focusedInput) => {
    setFocused(focusedInput);
  };

  React.useEffect(() => {
    const sdId = uuidv4();
    setStartDateId(sdId);
    const edId = uuidv4();
    setEndDateId(edId);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (date.startDate && date.endDate && setDateRange) {
      const date_range = `${new Date(date.startDate).getTime()}-${new Date(date.endDate).getTime()}`;
      setDateRange(date_range);
    }
    // eslint-disable-next-line
  }, [date]);

  const smallDevice = window.matchMedia('(max-width: 920px)').matches;
  const orientation = smallDevice ? "vertical" : "horizontal";

  return (
    <>
      {(startDateId && endDateId) &&
        <DateRangePicker
          small={true}
          numberOfMonths={1}
          orientation={orientation}
          withPortal={smallDevice}
          isOutsideRange={(day) => {
            const isAfterToday = day.isAfter(moment().add(0, 'days'))
            return isAfterToday
          }}
          startDate={date.startDate} // momentPropTypes.momentObj or null,
          startDateId={uuidv4()} // PropTypes.string.isRequired,
          endDate={date.endDate} // momentPropTypes.momentObj or null,
          endDateId={uuidv4()} // PropTypes.string.isRequired,
          onDatesChange={dateChange} // PropTypes.func.isRequired,
          focusedInput={focused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusChange} // PropTypes.func.isRequired,
        />
      }
    </>
  );

}
