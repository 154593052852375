import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { getPrivacyResults } from "../store/actions/webPrivacyActions";
import loaderwave from "../assets/loader.svg";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import ScanSelector from "../components/commons/ScanSelector";
import cx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useFadedShadowStyles } from "@mui-treasury/styles/shadow/faded";
import { useGutterBorderedGridStyles } from "@mui-treasury/styles/grid/gutterBordered";
import ReadMore from "../components/commons/ReadMore";
import ModuleSummary from "../components/commons/ModuleSummary";
import { InfoOutlined } from "@material-ui/icons";
import CustomTooltip from "../components/commons/Tooltip";
import { Tab } from "@material-ui/core";
import IssueTrackerTablePrivacy from "../components/commons/IssueTrackerTablePrivacy";
import Auth0Service from "../services/auth0Service/authentikService";

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: 12,
    textAlign: "center",
    height: "100%",
  },
  cardContent: {
    paddingBottom: 0,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  heading: {
    fontSize: 20,
    fontWeight: "bold",
    letterSpacing: "0.5px",
    margin: 0,
  },
  subheader: {
    fontSize: 18,
    color: "#000",
    // marginBottom: "0.875em",
    // minHeight: 36,
    fontWeight: 400,
  },
  statLabel: {
    fontSize: 12,
    color: palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  statValue: {
    fontSize: 20,
    fontWeight: "bold",
    margin: 0,
    letterSpacing: "1px",
  },
}));

export default function WebPrivacy() {
  const dispatch = useDispatch();

  const webPrivacy = useSelector((state) => state.webPrivacy.privacies);
  const status = useSelector((state) => state.webPrivacy.status);
  const selectedScanId = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );
  const selectedDomainId = useSelector(state => state.dashboard.selectedDomainId);

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (selectedScanId) {
      dispatch(getPrivacyResults(selectedScanId));
    }
    // eslint-disable-next-line
  }, [selectedScanId]);

  React.useEffect(() => {
    if (status === "pending" || status === "running") {
      const interval = setInterval(() => {
        if (selectedScanId) {
          dispatch(getPrivacyResults(selectedScanId));
        }
      }, 15000);
      return () => clearInterval(interval);
    }

    // eslint-disable-next-line
  }, [status]);

  const [delayed, setDelayed] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setDelayed(false);
    }, 1500);
  }, []);

  function parseInnerData(data) {
    const values = [];
    var titleObj = {};
    for (let i = 0; i < data.length; i++) {
      if (i === 0) {
        titleObj = data[i];
      } else {
        values.push(data[i]);
      }
    }
    return {
      values,
      titleObj,
    };
  }

  function parseData(data) {
    const categories = [];
    for (let i = 0; i < data.length; i++) {
      let data2 = parseInnerData(data[i]);
      categories.push(data2);
    }
    return categories;
  }

  React.useEffect(() => {
    if (webPrivacy && webPrivacy.length > 0) {
      // const prasedData =  parseData(webPrivacy);
      setData(parseData(webPrivacy));
    }
    // eslint-disable-next-line
  }, [webPrivacy]);

  const [reportType, setReportType] = React.useState({
    overview: true,
    spreadSheet: false,

  });

  const CustomTab = withStyles((theme) => ({
    root: {
      minWidth: 40,
      opacity: 1,
      minHeight: "auto",
      borderRadius: "2px",
      padding: "0px 8px",
      border: "1px solid #1f3a93",
      borderRight: 0,

      color: "#1f3a93",
      fontWeight: 400,
      fontSize: "18px",
      "&:last-child": {
        borderRight: "1px solid #1f3a93",
      },
      "&:hover": {
        color: "#ffff!important",
        backgroundColor: "#1f3a93",
        opacity: 1,
      },
      "&$selected": {
        color: "white",
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: "#1f3a93",
      },
      "&:focus": {
        color: "white",
        backgroundColor: "#1f3a93",
      },
    },
    selected: {
      color: "white",
      backgroundColor: "#1f3a93",
      // display: 'none'
    },
  }))((props) => <Tab disableRipple {...props} />);

  return (
    <>
      {!delayed ? (
        <>
          {selectedScanId ? (
            <>
              <ScanSelector />


              <>
                {status && status === "pending" ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                    width="100%"
                    tabIndex="0"
                  >
                    <img src={loaderwave} alt="loader" />
                    <h4>Fetching Data</h4>
                  </Box>
                ) : (
                  <Box>
                    <ModuleSummary
                      module="privacy"
                      title="Overall Web Privacy Score"
                    />
                    <Box display="flex" flexWrap="wrap" alignItems="center" mb={2}>
                      <CustomTab
                        label="Overview"
                        onClick={() => setReportType({ overview: true })}
                        style={{
                          backgroundColor: reportType.overview ? "#1f3a93" : "none",
                          color: reportType.overview ? "#ffff" : "#1f3a93",
                        }}
                        tabIndex="0"
                      />

                      {Auth0Service.isAuthenticated() ? (<CustomTab
                        label={`Spread Sheet`}
                        onClick={() => setReportType({ spreadSheet: true })}
                        tabIndex="0"
                        style={{
                          backgroundColor: reportType.spreadSheet ? "#1f3a93" : "none",
                          color: reportType.spreadSheet ? "#ffff" : "#1f3a93",
                        }}
                      />) : (null)
                      }
                    </Box>


                    {reportType.overview && (<TableContainer component={Paper}>
                      <Box p={2}>
                        {data.length > 0 &&
                          data.map((category) => {
                            return (
                              <Box mb={5}>
                                <h2
                                  style={{ margin: "0px 0px 10px", fontSize: 28 }}
                                >
                                  {category.titleObj.title}
                                </h2>
                                <p
                                  style={{ margin: "0px 0px 10px", fontSize: 18 }}
                                >
                                  {category.titleObj.description}
                                </p>
                                <Grid container spacing={4}>
                                  {category.values.map((catValue) => {
                                    return (
                                      <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <CatValueCard
                                          values={catValue}
                                          title={category.titleObj.title}
                                        />
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                              </Box>
                            );
                          })}
                      </Box>
                    </TableContainer>)}

                    {reportType.spreadSheet && (
                      <IssueTrackerTablePrivacy title="Privacy Issue Sheet" domainId={selectedDomainId} />
                    )}
                  </Box>
                )}
              </>



            </>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
              tabIndex="0"
            >
              <img src={loaderwave} alt="loader" />
            </Box>
          )}
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          tabIndex="0"
        >
          <img src={loaderwave} alt="loader" />
        </Box>
      )}

    </>
  );
}

function CatValueCard({ values, title }) {
  //This function put right description
  const descriptionSorter = () => {
    if (title === "Cookies") {
      if (values.value) {
        return "Present in cookie dialog";
      } else {
        return "Not present in cookie dialog";
      }
    } else if (title === "Policies") {
      if (values.name === "Privacy Policy") {
        if (values.value) {
          return "Present in cookie dialog";
        } else {
          return "Not present in cookie dialog";
        }
      } else {
        if (values.value) {
          return " Website is compliant with this policy";
        } else {
          return " Website is not compliant with this policy";
        }
      }
    } else {
      if (values.name === "Footer") {
        if (values.value) {
          return "Found in the website";
        } else {
          return "Not found in the website";
        }
      } else if (values.name === "Footer Version") {
        if (values.value) {
          return "Version up-to-date ";
        } else {
          return "Version not up-to-date";
        }
      } else {
        if (values.value) {
          return "Present in the footer";
        } else {
          return "Not present in the footer";
        }
      }
    }
  };
  const styles = useStyles();
  const shadowStyles = useFadedShadowStyles();
  const borderedGridStyles = useGutterBorderedGridStyles({
    borderColor: "rgba(0, 0, 0, 0.08)",
    height: "50%",
  });
  return (
    <Card className={cx(styles.card, shadowStyles.root)}>
      <CardContent className={cx(styles.cardContent)}>
        {values.info && (
          <Box display="flex" justifyContent="flex-end">
            <CustomTooltip title={values.info}>
              {" "}
              <InfoOutlined style={{ width: "0.9em", height: "0.9em" }} />{" "}
            </CustomTooltip>
          </Box>
        )}
        <Box display='contents'>
          <h3 className={styles.heading} style={{ flex: "1", height: "40px" }}>
            {values.name}
          </h3>
        </Box>

        <Box display={"flex"}>
          <Box p={2} flex="1" className={borderedGridStyles.item}>
            <p className={styles.statValue}>
              {values.value ? (
                <CheckCircleIcon
                  style={{ color: "#21ca5a", width: 70, height: 70 }}
                />
              ) : (
                <CancelIcon
                  style={{ color: "#E2211E", width: 70, height: 70 }}
                />
              )}
            </p>
          </Box>
        </Box>
        <Box className={styles.subheader}>
          <ReadMore lines={2}>{descriptionSorter()}</ReadMore>
          {/* {values.value ? (
            <p>{values.description}</p>
          ) : (
            // <ReadMore lines={2}>Not present in the cookie dialog</ReadMore>
            <p>{values.description}</p>
          )} */}
        </Box>
      </CardContent>
    </Card>
  );
}
