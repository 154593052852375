
import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { toast } from 'react-toastify';

export const GET_PACKAGES = 'GET_PACKAGES';
export const GET_STRIPE_CARDS = 'GET_STRIPE_CARDS';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const SUBSCRIBE_PACKAGE = 'SUBSCRIBE_PACKAGE';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';


export const getPacakages = () => async (dispatch) => {
  const token = auth0Service.getAccessToken();

  try {

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.get(`${BASE_URL}/dashboard/evaluate-package`, options);

    if (res.data) {
      dispatch({
        type: GET_PACKAGES,
        payload: res.data.stripePackage
      });

    }
  } catch (err) {

    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const getCards = () => async (dispatch) => {
  const token = auth0Service.getAccessToken();

  try {

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.get(`${BASE_URL}/dashboard/get-stripe-cards`, options);

    if (res.data) {
      dispatch({
        type: GET_STRIPE_CARDS,
        payload: res.data.data
      });
    }
  } catch (err) {

    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const getTransactions = () => async (dispatch) => {
  const token = auth0Service.getAccessToken();
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.get(`${BASE_URL}/dashboard/get-stripe-subscription`, options);
    if (res.data) {
      dispatch({
        type: GET_TRANSACTIONS,
        payload: res.data.data
      });
    }
  } catch (err) {

    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const subscribePackage = (pagerange, userRange, websiteRange, resolutions, tenure, selectedPaymentMethod) => async (dispatch) => {
  dispatch({
    type: SUBSCRIBE_PACKAGE,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/stripe-subscribe`, {

      stripePackage: {
        rangeOfPages: pagerange,
        rangeOfUsers: userRange,
        rangeOfWebsites: websiteRange,
        resolutions: resolutions,
        tenure: tenure
      },
      last4: selectedPaymentMethod.last4,
      cardId: selectedPaymentMethod.id

    }, options);

    if (res.data.message === "OK") {
      toast.success("Successfully Subscribed");
      const alert = setTimeout(() => {
        window.location.pathname = '/dashboard';
        clearTimeout(alert);
      }, 1000);
    }
    dispatch({
      type: SUBSCRIBE_PACKAGE,
    });
  } catch (err) {
    dispatch({
      type: SUBSCRIBE_PACKAGE,
    });
    toast.error(err.response.data.message);
  }
}

export const cancelSubscription = () => async (dispatch) => {
  dispatch({
    type: CANCEL_SUBSCRIPTION,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.delete(`${BASE_URL}/dashboard/delete-subscription`,
      options);

    if (res.data.message === "Subscription Deleted") {
      toast.success(res.data.message);
      const alert = setTimeout(() => {
        auth0Service.logout();
        window.location.pathname = "/authenticate";
        clearTimeout(alert);
      }, 1000);
    }
    dispatch({
      type: CANCEL_SUBSCRIPTION,
    });
  } catch (err) {
    dispatch({
      type: CANCEL_SUBSCRIPTION,
    });
    toast.error(err.response.data.message);
  }
}


export const deleteStripeCard = (cardId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      cardId,
    };

    const res = await axios.delete(`${BASE_URL}/dashboard/remove-stripe-card`, {
      params,
      ...options,
    });
    if (res.data) {
      dispatch(getCards());
      toast.success(res.data.mesage);
    }
  } catch (err) {

    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    } else {
      toast.error(err.response.data.message);
    }
  }
};

export const defaultCard = (data) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const params = {
      ...data,
    };
    const res = await axios.post(`${BASE_URL}/dashboard/set-default-stripe-cards`, data, options);

    if (res.data) {
      toast.success(res.data.message)
      return res.data;
    }
  } catch (err) {

    toast.error(err.response.data.message);

  }
};