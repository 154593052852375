import {
  GET_LINKS,
  GET_DIRECTORIES,
  CUSTOM_LIST_RESACAN,
  GET_CANCELLED_SCANS,
  GET_ARCHIVED_LINKS,
  GET_ARCHIVED_DIRECTORIES,
  GET_COMPLETED_SCANS,
  GET_COMPLETED_SCANS_LOADING,
  GET_DOMAIN_EVALUATION,
  GET_SITEMAP_STAGE,
  GET_SITEMAP_PROGRESS
} from "../actions/customScanPageListActions";

const initialState = {
  links: null,
  directories: null,
  loading: false,
  cancelledScans: {
    scans: [],
  },
  archivedLinks: [],
  archivedDirectories: [],

  completedScans: [],
  completedScansLoading: false,

  sitemapStage: 0,
  domainEvalutionStatus: "pending",
  isPwa: false,
  sitemapProgress: "pending",

};

const customScanListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LINKS:
      return {
        ...state,
        links: action.payload,
      };
    case GET_DIRECTORIES:
      return {
        ...state,
        directories: action.payload,
      };
    case GET_CANCELLED_SCANS:
      return {
        ...state,
        cancelledScans: { scans: action.payload.scans },
      };
    case CUSTOM_LIST_RESACAN:
      return {
        ...state,
        loading: !state.loading,
      };
    case GET_ARCHIVED_LINKS:
      return {
        ...state,
        archivedLinks: action.payload.result.links,
      };
    case GET_ARCHIVED_DIRECTORIES:
      return {
        ...state,
        archivedDirectories: action.payload.result.links,
      };

    case GET_COMPLETED_SCANS:
      return {
        ...state,
        completedScans: action.payload,
        completedScansLoading: false,
      };
    case GET_COMPLETED_SCANS_LOADING:
      return {
        ...state,
        completedScansLoading: !state.completedScansLoading,
      };
    case GET_SITEMAP_STAGE:
      return {
        ...state,
        sitemapStage: action.payload.stage
      };
    case GET_DOMAIN_EVALUATION:
      return {
        ...state,
        domainEvalutionStatus: action.payload.status,
        isPwa: action.payload.evaluation.pwa
      };
    case GET_SITEMAP_PROGRESS:
      return {
        ...state,
        sitemapProgress: action.payload.status,
      };
    default:
      return state;
  }
};
export default customScanListReducer;