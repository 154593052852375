import auth0Service from '../../services/auth0Service/authentikService';
import { getSpellCheckTable } from './spellCheckActions'
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { toast } from 'react-toastify';

export const GET_NEW_SPELLCHECK_ISSUES = 'GET_NEW_SPELLCHECK_ISSUES';
export const GET_NEW_SPELLCHECK_ISSUES_LOADING = 'GET_NEW_SPELLCHECK_ISSUES_LOADING';

export const GET_ARCHIVED_SPELLCHECK_ISSUES = 'GET_ARCHIVED_SPELLCHECK_ISSUES';
export const GET_ARCHIVED_SPELLCHECK_ISSUES_LOADING = 'GET_ARCHIVED_SPELLCHECK_ISSUES_LOADING';

export const GET_IGNORED_SPELLCHECK_ISSUES = 'GET_IGNORED_SPELLCHECK_ISSUES';
export const GET_IGNORED_SPELLCHECK_ISSUES_LOADING = 'GET_IGNORED_SPELLCHECK_ISSUES_LOADING';

export const GET_COMPLETED_SPELLCHECK_ISSUES = 'GET_COMPLETED_SPELLCHECK_ISSUES';
export const GET_COMPLETED_SPELLCHECK_ISSUES_LOADING = 'GET_COMPLETED_SPELLCHECK_ISSUES_LOADING';


export const getSpellCheckNewDetails = (scanId) => async (dispatch) => {
  dispatch({
    type: GET_NEW_SPELLCHECK_ISSUES_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    }

    const res = await axios.get(`${BASE_URL}/dashboard/new-spellchecker-issues`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_NEW_SPELLCHECK_ISSUES,
        payload: res.data
      });
    }

  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}


export const getSpellCheckArchivedDetails = (scanId) => async (dispatch) => {
  dispatch({
    type: GET_ARCHIVED_SPELLCHECK_ISSUES_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    }

    const res = await axios.get(`${BASE_URL}/dashboard/archived-spellchecker-issues`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_ARCHIVED_SPELLCHECK_ISSUES,
        payload: res.data
      });
    }

  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}
export const archiveSpellCheckLinks = (archiveOptions, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/archive-spellchecker-issues`, {
      ...archiveOptions
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSpellCheckTable(scanId));
      dispatch(getSpellCheckArchivedDetails(scanId));
    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}
export const unArchiveSpellCheckLinks = (postData, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/unarchive-spellchecker-issues`, {
      ...postData
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSpellCheckArchivedDetails(scanId))
      dispatch(getSpellCheckTable(scanId))
    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}


export const getSpellCheckIgnoredDetails = (scanId) => async (dispatch) => {
  dispatch({
    type: GET_IGNORED_SPELLCHECK_ISSUES_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    }

    const res = await axios.get(`${BASE_URL}/dashboard/ignored-spellchecker-issues`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_IGNORED_SPELLCHECK_ISSUES,
        payload: res.data
      });
    }

  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}
export const ignoreSpellCheckLinks = (issues, scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/ignore-spellchecker-issues`, {
      issues
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSpellCheckTable(scanId));
      dispatch(getSpellCheckIgnoredDetails(scanId));
    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}
export const unIgnoreSpellCheckLinks = (postData, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/unignore-spellchecker-issues`, {
      ...postData
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSpellCheckIgnoredDetails(scanId))
      dispatch(getSpellCheckTable(scanId))
    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}


export const getSpellCheckCompletedDetails = (scanId) => async (dispatch) => {
  dispatch({
    type: GET_COMPLETED_SPELLCHECK_ISSUES_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const params = {
      scanId,
    }

    const res = await axios.get(`${BASE_URL}/dashboard/completed-spellchecker-issues`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_COMPLETED_SPELLCHECK_ISSUES,
        payload: res.data
      });
    }

  } catch (err) {
    if (!err.response) {
        toast.warn('Warning ⚠️ bad internet connection.' );
      }
  }
}
export const completeSpellCheckLinks = (issues, scanId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/complete-spellchecker-issues`, {
      issues
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSpellCheckTable(scanId));
      dispatch(getSpellCheckCompletedDetails(scanId));
    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}
export const unCompleteSpellCheckLinks = (postData, scanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();

const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/unignore-spellchecker-issues`, {
      ...postData
    }, options);

    if (res.status === 200) {
      toast.success(res.data.message)
      dispatch(getSpellCheckIgnoredDetails(scanId))
      dispatch(getSpellCheckTable(scanId))
    }
  } catch (err) {
    toast.error(err.response.data.message)
  }
}