
import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { toast } from "react-toastify";

export const GET_FILES_ELEMENTS_CARD_DETAILS = 'GET_FILES_ELEMENTS_CARD_DETAILS';
export const LOADING_FILES = 'LOADING_FILES';

export const GET_FILES_HISTORY = 'GET_FILES_HISTORY';
export const LOADING_FILES_HISTORY = 'LOADING_FILES_HISTORY';

export const getTotalFilesElementsCardDetails = (scanId) => async (dispatch) => {
  dispatch({
    type: LOADING_FILES,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const params = {

      scanId,

    }

    const res = await axios.get(`${BASE_URL}/dashboard/files-result`, {
      params,
      ...options
    });

    if (res.data) {

      dispatch({
        type: GET_FILES_ELEMENTS_CARD_DETAILS,
        payload: res.data
      });

    }

  } catch (err) {
    dispatch({
      type: LOADING_FILES,
    });
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}


export const getFilesHistory = (scanId, dateNowRange, tag) => async (dispatch) => {

  dispatch({
    type: LOADING_FILES_HISTORY,
  });

  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const params = {
      dateNowRange,
      scanId,
      tag
    }

    const res = await axios.get(`${BASE_URL}/history/files-history`, {
      params,
      ...options
    });

    if (res.data) {
      dispatch({
        type: GET_FILES_HISTORY,
        payload: res.data
      });

    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}