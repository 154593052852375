import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import CustomCardContainerHead from "../../components/commons/CustomCardContainerHead";
import { useDispatch } from "react-redux";
import { getFooterInfo, updateContent } from "../../store/actions/abracadabraActions";
import Pagination from "../../components/pagination/Pagination"; // Update the import path
import { toast } from "react-toastify";

export default function FooterInfo({ domain }) {
  const [loadingLinks, setLoadingLinks] = useState(true);
  const [data, setData] = useState({});
  const [currentPage1, setCurrentPage1] = useState(1);
  const [showProgress, setShowProgress] = useState(false);
  const [contactTexts, setContactTexts] = useState(''); 
  const [footerHref, setFooterHref] = useState(''); 
  const [selectedRows, setSelectedRows] = useState([]);
  const [post_id, setPostId] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoadingLinks(true);
    getFooter();
  }, []);

  async function getFooter() {
    try {
      const response = await dispatch(getFooterInfo(domain));
      if (response && response.response.message === "Task Results") {
        setLoadingLinks(false);
        setData(response.response.result);
        setPostId(response.response.result.footerInfo.data.pageId[0].split('page-id-')[1]);
      } else {
        setLoadingLinks(false);
        toast.error("No footer details found.");
      }
    } catch (error) {
      setLoadingLinks(false);
      toast.error("No footer details found.");
    }
  }

  async function updateWpContent(post_id, search_text, replace_text, contact, contactHref, emailaddress, emailaddresshref, footerText, footerTextHref) {
    try {
      const data = await dispatch(updateContent(domain, post_id, search_text, replace_text, contact, contactHref, emailaddress, emailaddresshref, footerText, footerTextHref));
      if (data) {
        toast.success(data.response);
      }
    } catch (error) {
      toast.error("Failed to update content.");
    }
  }

  const FooterLinks = data.footerInfo && data.footerInfo.data ? data.footerInfo.data : {};
  const footerLinks = FooterLinks.links || [];

  const itemsPerPage = 10;
  const totalPages1 = Math.ceil(footerLinks.length || 0/ itemsPerPage);
  const currentFooterLink = footerLinks.slice((currentPage1 - 1) * itemsPerPage, currentPage1 * itemsPerPage);

  const handlePageChange1 = (newPage) => {
    setCurrentPage1(newPage);
  };

  const handleUpdateFooterText = (index, numberValue) => {
    if (numberValue) {
      let contact = false;
      let contactHref = false;
      let emailaddress = false;
      let emailaddresshref = false;
      let footerText = true
      let footerTextHref = false
      updateWpContent(post_id, numberValue, contactTexts[index], contact, contactHref, emailaddress, emailaddresshref, footerText, footerTextHref);
    }

    setTimeout(() => {
      const newTexts = [...contactTexts];
      newTexts[index] = ''; // Reset the input field value
      setContactTexts(newTexts);
    }, 2000);
  };

  const handleUpdateFooterHref = (index, numberValue) => {
    if (numberValue) {
      let contact = false;
      let contactHref = false;
      let emailaddress = false;
      let emailaddresshref = false;
      let footerText = false
      let footerTextHref = true
      updateWpContent(post_id, numberValue, contactTexts[index], contact, contactHref, emailaddress, emailaddresshref, footerText, footerTextHref);
    }

    setTimeout(() => {
      const newTextHref = [...footerHref];
      newTextHref[index] = ''; // Reset the input field value
      setFooterHref(newTextHref);
    }, 2000);
  };

  return (
    <>
      {/* Contact Numbers Table */}
      <CustomCardContainerHead title="Footer Info" titleTag="h1">
      <TableContainer component={Paper} style={{ overflowX: "auto" }}>
        <Table aria-label="contact numbers table">
          <TableHead>
            <TableRow>
              <TableCell>Visual Text</TableCell>
              <TableCell>Text Replace With</TableCell>
              <TableCell>Link href</TableCell>
              <TableCell>Replace with href</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentFooterLink.map((footerlink, index) => (
              <TableRow key={index}>
                <TableCell>{footerlink.text}</TableCell>
                <TableCell>
                  <div>
                    <input
                      type="text"
                      value={contactTexts[index]}
                      onChange={(event) => {
                        const newTexts = [...contactTexts];
                        newTexts[index] = event.target.value;
                        setContactTexts(newTexts);
                      }}
                    />
                   <button onClick={() => handleUpdateFooterText(index, footerlink.text)}>Fix</button>
                  </div>
                </TableCell>
                <TableCell>{footerlink.href}</TableCell>
                <TableCell>
                  <div>
                    <input
                      type="text"
                      value={footerHref[index]}
                      onChange={(event) => {
                        const newTextHref = [...footerHref];
                        newTextHref[index] = event.target.value;
                        setFooterHref(newTextHref);
                      }}
                    />
                   <button onClick={() => handleUpdateFooterText(index, footerlink.href)}>Fix</button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {showProgress && (
              <TableRow>
                <TableCell colSpan={2}>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
                    <CircularProgress />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      </CustomCardContainerHead>
      {/* Pagination controls for the Contact Numbers table */}
      {!loadingLinks && data ? (
        <Pagination totalPages={totalPages1} currentPage={currentPage1} onPageChange={handlePageChange1} />
      ) : null}
    </>
  );
}
