import {
  GET_ARCHIVED_SPELLCHECK_ISSUES,
  GET_ARCHIVED_SPELLCHECK_ISSUES_LOADING,
  GET_IGNORED_SPELLCHECK_ISSUES,
  GET_IGNORED_SPELLCHECK_ISSUES_LOADING,
  GET_COMPLETED_SPELLCHECK_ISSUES,
  GET_COMPLETED_SPELLCHECK_ISSUES_LOADING,

  GET_NEW_SPELLCHECK_ISSUES,
  GET_NEW_SPELLCHECK_ISSUES_LOADING

} from '../actions/spellCheckTablesActions';

const initialState = {
  spellCheckArchivedData: [],
  spellCheckArchivedDataLoading: false,

  spellCheckNewData: [],
  spellCheckNewDataLoading: false,
};

const spellCheckTableReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_NEW_SPELLCHECK_ISSUES:
      return {
        ...state,
        spellCheckArchivedData: action.payload.result.issues,
        loading: false
      }
    case GET_NEW_SPELLCHECK_ISSUES_LOADING:
      return {
        ...state,
        spellCheckArchivedDataLoading: true
      }

    case GET_ARCHIVED_SPELLCHECK_ISSUES:
      return {
        ...state,
        spellCheckArchivedData: action.payload.result.issues,
        loading: false
      }
    case GET_ARCHIVED_SPELLCHECK_ISSUES_LOADING:
      return {
        ...state,
        spellCheckArchivedDataLoading: true
      }
    case GET_COMPLETED_SPELLCHECK_ISSUES:
      return {
        ...state,
        spellCheckCompletedData: action.payload.result.issues,
        loading: false
      }
    case GET_COMPLETED_SPELLCHECK_ISSUES_LOADING:
      return {
        ...state,
        spellCheckCompletedDataLoading: true
      }
    case GET_IGNORED_SPELLCHECK_ISSUES:
      return {
        ...state,
        spellCheckIgnoredData: action.payload.result.issues,
        loading: false
      }
    case GET_IGNORED_SPELLCHECK_ISSUES_LOADING:
      return {
        ...state,
        spellCheckIgnoredDataLoading: true
      }
    default:
      return state;
  }
};

export default spellCheckTableReducer;