
import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { toast } from "react-toastify";

export const CHECKLIST_LOADING = 'CHECKLIST_LOADING';
export const GET_CHECKLIST = 'GET_CHECKLIST';

export const DOMAIN_CHECKLIST_LOADING = 'DOMAIN_CHECKLIST_LOADING';
export const GET_DOMAIN_CHECKLIST = 'GET_DOMAIN_CHECKLIST';

export const getChecklists = () => async (dispatch) => {
  dispatch({
    type: CHECKLIST_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.get(`${BASE_URL}/generic/checklist`, {
      ...options
    });
    if (res.data) {
      dispatch({
        type: GET_CHECKLIST,
        payload: res.data
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    dispatch({
      type: CHECKLIST_LOADING,
    });
  }
}


export const submitChecklist = (mainCheckItems) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(
      `${BASE_URL}/generic/checklist-selection`,
      {
        checkItems: mainCheckItems[0].checkItems,
        checkListId: mainCheckItems[0]._id
      },
      options
    );
    if (res.data) {
      toast.success(res.data.message);
      dispatch(getChecklists());
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    toast.error(err.response.data.message);
  }
};

export const resetChecklist = () => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(
      `${BASE_URL}/generic/checklist-reset`, {}, options
    );
    if (res.data) {
      toast.success(res.data.message);
      dispatch(getChecklists());
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    toast.error(err.response.data.message);
  }
};

export const checklistItem = (item) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(
      `${BASE_URL}/generic/checklist`,
      {
        ...item,
      },
      options
    );
    if (res.data) {
      toast.success(res.data.message);
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    toast.error(err.response.data.message);
  }
};


export const getDomainChecklists = (domainId) => async (dispatch) => {
  dispatch({
    type: DOMAIN_CHECKLIST_LOADING,
  });
  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const params = {
      domainId
    }

    const res = await axios.get(`${BASE_URL}/generic/domain-checklists`, {
      params,
      ...options
    });
    if (res.data) {
      dispatch({
        type: GET_DOMAIN_CHECKLIST,
        payload: res.data
      });
    }

  } catch (err) {
    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
    dispatch({
      type: DOMAIN_CHECKLIST_LOADING,
    });
  }
}

export const submitDomainChecklist = (mainCheckItems, domainId) => async (dispatch) => {
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(
      `${BASE_URL}/generic/domain-checklists`,
      {
        checkItems: mainCheckItems[0].checkItems,
        checkListId: mainCheckItems[0]._id,
        domainId
      },
      options
    );
    if (res.data) {
      toast.success(res.data.message);
      setTimeout(() => {
        window.location.pathname = "/dashboard";
      }, 1000);
      // dispatch(getDomainChecklists());
    }
  } catch (err) {
    if (!err.response) {
      toast.warn("Warning ⚠️ bad internet connection.");
    }
    toast.error(err.response.data.message);
  }
};