import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import clsx from "clsx";
import loaderwave from "../../assets/loader.svg";
import { nFormatter } from "../../utils/shortNumber";
import { CardNote } from "../../components/accessibility-main/components/CardNote";
import { makeStyles } from "@material-ui/core/styles";

const cardNoteColor = (grade) => {
  return grade === "A"
    ? "#FFF"
    : grade === "B"
    ? "#000"
    : grade === "C"
    ? "#000"
    : grade === "D"
    ? "#000"
    : grade === "F"
    ? "#FFF"
    : "#FFFFFF";
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 30,
    fontFamily: "Segoe UI",
    fontWeight: "bold",
    margin: 0,
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
    },
  },
  IconButton: {
    color: "black",
    padding: 0,
    marginLeft: 5,
  },
  bgRed: {
    backgroundColor: "#B50202",
  },
  bgGreen: {
    backgroundColor: "#005D35",
  },
  bgWhite: {
    backgroundColor: "#FFFFFF",
  },
  colorRed: {
    color: "#B50202",
  },
  colorGreen: {
    color: "#005D35",
  },
  cardBackGround: {
    backgroundColor: (props) =>
      props.grade === "A"
        ? "#005D35"
        : props.grade === "B"
        ? "#51D3D3"
        : props.grade === "C"
        ? "#F7CA05"
        : props.grade === "D"
        ? "#FF8000"
        : props.grade === "F"
        ? "#B50202"
        : "#FFFFFF",
  },
  cardNote: {
    color: (props) => cardNoteColor(props.grade),
    border: (props) => `3px solid ${cardNoteColor(props.grade)}}`,
  },
  cardContentColor: {
    "& > *": {
      color: "black !important",
    },
  },
  percentageText: {
    marginLeft: "2px",
    fontSize: 12,
  },
}));

const StatisticsCard = ({ title, loading, sheetStatics, type }) => {
  const classes = useStyles({ grade: sheetStatics ? sheetStatics.grade : "" });

  return (
    <Card
      tabIndex="0"
      style={{
        minHeight: 172,
        maxWidth: type === "accessibility" ? 650 : 600,
        borderRadius: 10,
        boxShadow: "2px 4px 9px rgba(0, 0, 0, 0.04)",
        padding: "20px 23px",
        color: cardNoteColor(sheetStatics ? sheetStatics.grade : ""),
      }}
      className={clsx(classes.cardBackGround)}
    >
      <CardContent style={{ padding: 5 }}>
        <Box
          display="flex"
          flexWrap="wrap"
          position="relative"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          className="stat-card-dash"
        >
          <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
            width={"100%"}
          >
            <Box display="flex" alignItems="center">
              <h3 className={classes.title} style={{ marginRight: 10 }}>
                {title}
              </h3>
            </Box>
          </Box>
          <Box
            width="100%"
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              my={2}
              style={{
                minWidth:
                  title !== "AllyRight Score" && title !== "Web Privacy"
                    ? "153px"
                    : "50px",
              }}
            >
              <>
                {" "}
                {sheetStatics && sheetStatics.grade && !loading ? (
                  <CardNote className={clsx(classes.cardNoteColor)}>
                    {sheetStatics.grade}
                  </CardNote>
                ) : (
                  <CircularProgress
                    style={{
                      width: "50px",
                      height: "50px",
                      margin: "19px auto",
                      color: "black",
                    }}
                  />
                )}
              </>
            </Box>
            {!loading && sheetStatics && (
              <>
                <Grid container spacing={1}>
                  <Grid item lg={4} md={4} xl={4} sm={4} xs={4}>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      style={{ lineHeight: 1.1 }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: 30 }}>
                        {sheetStatics.score}
                        <span style={{ fontWeight: 500, fontSize: 20 }}>%</span>
                      </span>
                      <span style={{ fontSize: 15 }}>Score</span>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} xl={4} sm={4} xs={4}>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      mt={0}
                      style={{ lineHeight: 1.1 }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: 30 }}>
                        {sheetStatics.totalIssues}
                      </span>
                      <span style={{ fontSize: 15 }}>Total issues</span>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} xl={4} sm={4} xs={4}>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      style={{ lineHeight: 1.1 }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: 30 }}>
                        {sheetStatics.resolvedIssues}
                      </span>
                      <span style={{ fontSize: 15 }}>Resolved issues</span>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} xl={4} sm={4} xs={4}>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      style={{ lineHeight: 1.1 }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: 30 }}>
                        {sheetStatics.severity.low}
                      </span>
                      <span style={{ fontSize: 15 }}>Low severity </span>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} xl={4} sm={4} xs={4}>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      mt={0}
                      style={{ lineHeight: 1.1 }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: 30 }}>
                        {sheetStatics.severity.medium}
                      </span>
                      <span style={{ fontSize: 15 }}>Medium severity</span>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} xl={4} sm={4} xs={4}>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      style={{ lineHeight: 1.1 }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: 30 }}>
                        {sheetStatics.severity.high}
                      </span>
                      <span style={{ fontSize: 15 }}>High severity </span>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} xl={4} sm={4} xs={4}>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      style={{ lineHeight: 1.1 }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: 30 }}>
                        {sheetStatics["issuesAveragePerPage"]}
                      </span>
                      <span style={{ fontSize: 15 }}>Avg. Per page</span>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} xl={4} sm={4} xs={4}>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      mt={0}
                      style={{ lineHeight: 1.1 }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: 30 }}>
                        {sheetStatics.pagesWithIssues}
                      </span>
                      <span style={{ fontSize: 15 }}>Total Pages</span>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} xl={4} sm={4} xs={4}>
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                      mt={0}
                      style={{ lineHeight: 1.1 }}
                    >
                      <span style={{ fontWeight: "bold", fontSize: 30 }}>
                        {sheetStatics.pagesWithIssues}
                      </span>
                      <span style={{ fontSize: 15 }}>Pages with errors</span>
                    </Box>
                  </Grid>
                  {type === "accessibility" && (
                    <>
                      <Grid item lg={4} md={4} xl={4} sm={4} xs={4}>
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="column"
                          style={{ lineHeight: 1.1 }}
                        >
                          <span style={{ fontWeight: "bold", fontSize: 30 }}>
                            {sheetStatics.priority.a?sheetStatics.priority.a:0}
                          </span>
                          <span style={{ fontSize: 15 }}>Priority A</span>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} sm={4} xs={4}>
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="column"
                          mt={0}
                          style={{ lineHeight: 1.1 }}
                        >
                          <span style={{ fontWeight: "bold", fontSize: 30 }}>
                            {sheetStatics.priority.aa?sheetStatics.priority.aa:0}
                          </span>
                          <span style={{ fontSize: 15 }}>Priority AA</span>
                        </Box>
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} sm={4} xs={4}>
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="column"
                          style={{ lineHeight: 1.1 }}
                        >
                          <span style={{ fontWeight: "bold", fontSize: 30 }}>
                            {sheetStatics.priority.aaa
                              ? sheetStatics.priority.aaa
                              : 0}
                          </span>
                          <span style={{ fontSize: 15 }}>Priority AAA</span>
                        </Box>
                      </Grid>
                      {/* <Grid item lg={4} md={4} xl={4} sm={4} xs={4}>
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="column"
                          style={{ lineHeight: 1.1 }}
                        >
                          <span style={{ fontWeight: "bold", fontSize: 30 }}>
                            {sheetStatics.section508}
                          </span>
                          <span style={{ fontSize: 15 }}>Section 508</span>
                        </Box>
                      </Grid> */}
                    </>
                  )}
                </Grid>
                {/* <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  mt={-2}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    style={{ lineHeight: 1.1 }}
                  >
                    <span style={{ fontWeight: "bold", fontSize: 30 }}>
                      {sheetStatics.score}
                    </span>
                    <span style={{ fontSize: 20 }}>score %</span>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    mt={0}
                    style={{ lineHeight: 1.1 }}
                  >
                    <span style={{ fontWeight: "bold", fontSize: 30 }}>
                      {sheetStatics.totalIssues}
                    </span>
                    <span style={{ fontSize: 20 }}>Total Issues</span>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    style={{ lineHeight: 1.1 }}
                  >
                    <span style={{ fontWeight: "bold", fontSize: 30 }}>
                      {sheetStatics.resolvedIssues}
                    </span>
                    <span style={{ fontSize: 20 }}>resolved Issues</span>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  mt={1}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    style={{ lineHeight: 1.1 }}
                  >
                    <span style={{ fontWeight: "bold", fontSize: 30 }}>
                      {sheetStatics.lowPriorityIssue}
                    </span>
                    <span style={{ fontSize: 15 }}>low priority issue</span>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    mt={0}
                    style={{ lineHeight: 1.1 }}
                  >
                    <span style={{ fontWeight: "bold", fontSize: 30 }}>
                      {sheetStatics.mediumPriorityIssue}
                    </span>
                    <span style={{ fontSize: 15 }}>medium priority issue</span>
                  </Box>

                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    style={{ lineHeight: 1.1 }}
                  >
                    <span style={{ fontWeight: "bold", fontSize: 30 }}>
                      {sheetStatics.highPriorityIssue}
                    </span>
                    <span style={{ fontSize: 15 }}>high priority issue</span>
                  </Box>
                </Box> */}
              </>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default StatisticsCard;
