import React from "react";
import { useSelector } from "react-redux";
import Security from "./SecurityCurrentAndHistory";
import ScanSelector from "../commons/ScanSelector";

import Box from "@material-ui/core/Box";
import SecurityReport from "../../pages/SecurityReport";
import ModuleSummary from "../commons/ModuleSummary";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button, Card, CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { getUserReports } from "../../store/actions/dashboardActions";
import { Pagination } from "@material-ui/lab";
import DatePicker from "../commons/DateRangePicker";
import DatePickerSimple from "../commons/DateRangePickerSimple";
import IssueTrackerTableSecurity from "../commons/IssueTrackerTableSecurity";
import Auth0Service from "../../services/auth0Service/authentikService";

export default function SecurityDashboard() {
  const dispatch = useDispatch();
  const selectedScanId = useSelector(
    (state) => state.miscellaneous.selectedScanId
  );
  const domainId = useSelector((state) => state.dashboard.selectedDomainId);
  const securityTabs = useSelector((state) => state.dashboard.tabsInfo?.cyber);
  const tabsLoading = useSelector((state) => state.dashboard.tabsInfoLoading);
  const [reps, setReps] = React.useState([])

  const [reportType, setReportType] = React.useState({
    report: false,
    analytics: true,
    reports: false,
    spreadSheet: false
  });

  const [values, setValues] = React.useState({
    dateNowRange: '',
    domainId
  })
  React.useEffect(() => {
    if (domainId) {
      dispatch(getUserReports(values)).then(data => {
        setReps(data.result);
      }).catch(err => {
      })
    }
  }, [domainId])


  const CustomTab = withStyles((theme) => ({
    root: {
      minWidth: 40,
      opacity: 1,
      minHeight: "auto",
      borderRadius: "2px",
      padding: "0px 8px",
      border: "1px solid #1f3a93",
      color: "#1f3a93",
      fontWeight: 400,
      fontSize: "18px",
      maxWidth: '100%',
      borderRight: 0,
      "&:last-child": {
        borderRight: "1px solid #1f3a93",

      },
      "&:hover": {
        color: "#fff!important",
        backgroundColor: "#1f3a93",
        opacity: 1,
      },
      "&$selected": {
        color: "white",
        fontWeight: theme.typography.fontWeightMedium,
        backgroundColor: "#1f3a93",
      },
      "&:focus": {
        color: "white",
        backgroundColor: "#1f3a93",
      },
    },
    selected: {
      color: "white",
      backgroundColor: "#1f3a93",
      // display: 'none'
    },
  }))((props) => <Tab disableRipple {...props} />);

  return (
    <>
      <ScanSelector />
      {/* <ModuleSummary module="security" title="Overall Cyber Security Score" /> */}
      <Box display="flex" flexWrap="wrap" alignItems="center" mb={2}>

        {reps.length > 0 ? (
          < CustomTab
            label="Reports"
            onClick={() => setReportType({ reports: true })}
            tabIndex="0"
            style={{
              backgroundColor: reportType.reports ? "#1f3a93" : "none",
              color: reportType.reports ? "#ffff" : "#1f3a93",
            }}
          />
        ) : (null)
        }

        <CustomTab
          label="Analytics"
          onClick={() => setReportType({ analytics: true })}
          tabIndex="0"
          style={{
            backgroundColor: reportType.analytics ? "#1f3a93" : "none",
            color: reportType.analytics ? "#ffff" : "#1f3a93",
          }}
        />
        {Auth0Service.isAuthenticated() && <>
          <CustomTab
            label="Security Sheet"
            onClick={() => setReportType({ spreadSheet: true })}
            tabIndex="0"
            style={{
              backgroundColor: reportType.spreadSheet ? "#1f3a93" : "none",
              color: reportType.spreadSheet ? "#ffff" : "#1f3a93",
            }}
          />
          <CustomTab
            label={`SQLI Sheet`}
            onClick={() => setReportType({ sqli: true })}
            tabIndex="0"
            style={{
              backgroundColor: reportType.sqli ? "#1f3a93" : "none",
              color: reportType.sqli ? "#ffff" : "#1f3a93",
            }}
          />
          <CustomTab
            label="XSS Sheet"
            onClick={() => setReportType({ xss: true })}
            tabIndex="0"
            style={{
              backgroundColor: reportType.xss ? "#1f3a93" : "none",
              color: reportType.xss ? "#ffff" : "#1f3a93",
            }}
          />
          <CustomTab
            label="LFI Sheet"
            onClick={() => setReportType({ lfi: true })}
            tabIndex="0"
            style={{
              backgroundColor: reportType.lfi ? "#1f3a93" : "none",
              color: reportType.lfi ? "#ffff" : "#1f3a93",
            }}
          />
          <CustomTab
            label="Subdomain Takeover Sheet"
            onClick={() => setReportType({ subdomainTakeover: true })}
            tabIndex="0"
            style={{
              backgroundColor: reportType.subdomainTakeover ? "#1f3a93" : "none",
              color: reportType.subdomainTakeover ? "#ffff" : "#1f3a93",
            }}
          />
          <CustomTab
            label="Global Sheet"
            onClick={() => setReportType({ global: true })}
            tabIndex="0"
            style={{
              backgroundColor: reportType.global ? "#1f3a93" : "none",
              color: reportType.global ? "#ffff" : "#1f3a93",
            }}
          />
        </>}

        {tabsLoading ? <CircularProgress style={{ marginLeft: 10 }} size={20} /> : <>

          {Object.values(securityTabs).some(item => item === true) && <CustomTab
            label="Data tables"
            onClick={() => setReportType({ report: true })}
            tabIndex="0"
            style={{
              backgroundColor: reportType.report ? "#1f3a93" : "none",
              color: reportType.report ? "#ffff" : "#1f3a93",
            }}
          />}
        </>}
      </Box>
      {reportType.analytics && (
        <Security scanId={selectedScanId} overTime={true} />
      )}
      {reportType.report && <SecurityReport />}
      {reportType.reports && <Reports />}
      {reportType.spreadSheet && <IssueTrackerTableSecurity title="CyberSec Issue Sheet" vulnType='security' domainId={domainId} />}
      {reportType.sqli && <IssueTrackerTableSecurity title="SQLi Issue Sheet" vulnType='security-sqli' domainId={domainId} />}
      {reportType.xss && <IssueTrackerTableSecurity title="XSS Issue Sheet" vulnType='security-xss' domainId={domainId} />}
      {reportType.lfi && <IssueTrackerTableSecurity title="LFI Issue Sheet" vulnType='security-lif' domainId={domainId} />}
      {reportType.subdomainTakeover && <IssueTrackerTableSecurity title="Subdomain Takeover Issue Sheet" vulnType='security-subdomain-takeover' domainId={domainId} />}
      {reportType.global && <IssueTrackerTableSecurity title="Global Issue Sheet" vulnType='global' domainId={domainId} />}

    </>
  );
}



function Reports() {
  const dispatch = useDispatch();
  const domainId = useSelector((state) => state.dashboard.selectedDomainId);

  const [reps, setReps] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [pages, setPages] = React.useState(0)
  const [loading, setLoading] = React.useState(false)

  const [dateRange, setDateRange] = React.useState(false)


  const limit = 5;
  const [values, setValues] = React.useState({
    dateNowRange: '',
    page: page,
    limit,
    domainId
  })

  React.useEffect(() => {
    if (domainId) {
      setLoading(true)
      dispatch(getUserReports(values)).then(data => {
        setReps(data.result);
        setPages(data.pages)
        setLoading(false)

      }).catch(err => {
        setLoading(false)
      })
    }
  }, [domainId, page])

  const handleChange = (event, value) => {
    setPage(value);
  }

  function applyDateRange() {
    setLoading(true)
    dispatch(getUserReports({ ...values, dateNowRange: dateRange })).then(data => {
      setReps(data.result);
      setPages(data.pages)
      setLoading(false)

    }).catch(err => {
      setLoading(false)
    })
  }

  function resetDateRange() {
    setLoading(true)
    dispatch(getUserReports({ ...values, dateNowRange: dateRange })).then(data => {
      setReps(data.result);
      setPages(data.pages)
      setLoading(false)

    }).catch(err => {
      setLoading(false)
    })
  }

  return (
    <Box>

      <Box display='flex' alignItems='center' justifyContent='flex-end' mb={3} >
        <DatePickerSimple setDateRange={setDateRange} />
        <Button style={{ marginLeft: 10 }} variant='contained' disabled={!dateRange} color='primary' onClick={() => {
          applyDateRange()
        }} >Apply</Button>
        <Button style={{ marginLeft: 10 }} variant='contained' disabled={!dateRange} color='secondary' onClick={() => {
          resetDateRange()
        }} >Reset</Button>

      </Box>
      {loading ?

        <Box display='flex' alignItems='center' justifyContent='center' width='100%' height={300}>
          <CircularProgress />
        </Box> :

        reps.length > 0 ? reps.map(data => {
          return (
            <Card>
              <Box p={3}>
                <Box display='flex' flexWrap='wrap'> <Box mr={2} style={{ fontSize: 18 }}> <b >Title: </b> {data.title}</Box> <Box mr={2} style={{ fontSize: 18 }}> <b >Description: </b> {data.description}</Box>
                  <Box mr={2} style={{ fontSize: 18 }}> <b >Date: </b> {new Date(data.date).toDateString()}</Box>

                  {!data.reportUrl.includes('html') && <Box mr={2}> <b>File: </b>  <a href={data.reportUrl} target='_blank'>View</a></Box>}
                </Box>
                {data.reportUrl.includes('html') &&
                  <iframe src={data.reportUrl} width='100%' height={350} />}
              </Box>
            </Card>
          )
        }) :
          <Box textAlign='center'> No reports found </Box>
      }

      <Box p={3} display='flex' justifyContent='center'>
        <Pagination color='primary' count={pages} page={page} onChange={handleChange} />
      </Box>
    </Box>)
}