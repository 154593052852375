
import auth0Service from '../../services/auth0Service/authentikService';
import axios from 'axios';
import { BASE_URL } from '../../source/constants';
import { toast } from 'react-toastify';
import History from '../../@history';

export const GET_PACKAGES = 'GET_PACKAGES';
export const GET_STRIPE_CARDS = 'GET_STRIPE_CARDS';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const UPGRADE_PACKAGE = 'UPGRADE_PACKAGE';
export const GET_PRICES = 'GET_PRICES';
export const GET_PRICES_LOADING = 'GET_PRICES_LOADING';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';


export const getPackages = () => async (dispatch) => {
  const token = auth0Service.getAccessToken();

  try {

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.get(`${BASE_URL}/dashboard/evaluate-package`, options);

    if (res.data) {
      dispatch({
        type: GET_PACKAGES,
        payload: res.data.stripePackage
      });

    }
  } catch (err) {

    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const getCards = () => async (dispatch) => {
  const token = auth0Service.getAccessToken();

  try {

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.get(`${BASE_URL}/dashboard/get-stripe-cards`, options);

    if (res.data) {
      dispatch({
        type: GET_STRIPE_CARDS,
        payload: res.data.data
      });
    }
  } catch (err) {

    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const getTransactions = () => async (dispatch) => {
  const token = auth0Service.getAccessToken();
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.get(`${BASE_URL}/dashboard/get-stripe-subscription`, options);
    if (res.data) {
      dispatch({
        type: GET_TRANSACTIONS,
        payload: res.data.data
      });
    }
  } catch (err) {

    if (!err.response) {
      toast.warn('Warning ⚠️ bad internet connection.');
    }
  }
}

export const upgradePackage = (customUsers, selectedPaymentMethod) => async (dispatch) => {
  dispatch({
    type: UPGRADE_PACKAGE,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.post(`${BASE_URL}/dashboard/billing/users`, {

      cardId: selectedPaymentMethod.id,
      numOfUsers: customUsers,
    }, options);

    if (res.data) {
      toast.success("Subscription Updated")
      const alert = setTimeout(() => {
        window.location.reload();
        clearTimeout(alert);
      }, 1000);
    }
  } catch (err) {
    dispatch({
      type: UPGRADE_PACKAGE,
    });

    toast.error(err.response.data.message)
  }
}

export const getPrices = () => async (dispatch) => {
  const token = auth0Service.getAccessToken();
  dispatch({
    type: GET_PRICES_LOADING,
  })
  try {
    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.get(`${BASE_URL}/dashboard/prices`, options);
    if (res.data) {
      dispatch({
        type: GET_PRICES,
        payload: res.data
      });
    }
  } catch (err) {
    dispatch({
      type: GET_PRICES_LOADING,
    })
  }
}

export const cancelSubscription = () => async (dispatch) => {
  dispatch({
    type: CANCEL_SUBSCRIPTION,
  });
  try {
    const token = auth0Service.getAccessToken();

    const options = {
      headers: {
        "Content-Type": "application/json",

      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }


    const res = await axios.delete(`${BASE_URL}/dashboard/delete-subscription`,
      options);

    if (res.data.message === "Subscription Deleted") {
      toast.success(res.data.message);
      const alert = setTimeout(() => {
        auth0Service.logout();
        window.location.pathname = "/authenticate";
        clearTimeout(alert);
      }, 1000);
    }
    dispatch({
      type: CANCEL_SUBSCRIPTION,
    });
  } catch (err) {
    dispatch({
      type: CANCEL_SUBSCRIPTION,
    });
    toast.error(err.response.data.message);
  }
}

export const getNextPackage = (domainId) => async (dispatch) => {
  const token = auth0Service.getAccessToken();

  try {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.get(`${BASE_URL}/dashboard/billing/upgrade?domainId=${domainId}`, options);
    if (res.data) {
      return res.data
    }
  } catch (err) {

  }
}

export const updateToNextPackage = (data, selectedDomain, selectedScanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.put(`${BASE_URL}/dashboard/billing/subscribe`, {
      ...data
    },
      options
    );

    if (res.data) {
      History.push(
        `/subscription-successful?domain=${selectedDomain}&scanId=${selectedScanId}`
      );
      toast.success(res.data.message);
    }
  } catch (err) {
    toast.error(err.response.data.message)


  }
}


export const getCustomPackages = () => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.get(`${BASE_URL}/dashboard/packages`,
      options
    );

    if (res.data) {
      return res.data
    }
  } catch (err) {
    toast.error(err.response.data.message)


  }
}

export const applyCustomPackage = (data, selectedDomain, selectedScanId) => async (dispatch) => {

  try {
    const token = auth0Service.getAccessToken();
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const res = await axios.post(`${BASE_URL}/dashboard/billing/upgrade/options`, {
      ...data
    },
      options
    );

    if (res.data) {
      if (res.data) {
        History.push(
          `/subscription-successful?domain=${selectedDomain}&scanId=${selectedScanId}`
        );
        toast.success(res.data.message);
      }
      return res.data
    }
  } catch (err) {
    toast.error(err.response.data.message)


  }
}