const addIssueStyles = (theme) => ({
  mainTitle: {
    margin: '1rem 0',
    lineHeight: 1.3,
    fontSize: '1.25em',
    fontWeight: 600,
  },
  mainRow: {
    borderBottom: '1px solid #dbdbdb',
    display: 'flex',
    background: 'rgb(31, 58, 147)',
    borderRadius: 10,
    color: 'white',
    padding: '0px 20px',
  },
  buttonRow: {
    borderTop: '1px solid #dbdbdb',
    display: 'flex',
    borderRadius: 10,
    color: 'white',
    padding: '0px 10px',
  },
  root: {
    width: "100%",
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  selectBox: {
    maxWidth: 300,
    width: 300,
  },
  radioButton: {
    padding: "0px 9px"
  },
  bugId: {
    maxWidth: 300
  },
  datePicker: {
    maxWidth: 300,
    width: 300,
    height: 43,
    maxHeight: 43,
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'text',
    display: 'inline-flex',
    fontSize: '1rem',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontFamily: 'Segoe UI", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    padding: 12
  },
  uploadSection: {
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
    },
  },
  reactCsvInput: {
    display: "none",
  }
});

export default addIssueStyles;
